import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './document-table-cell.scss';

const cn = classNames.bind(styles);

type DocumentTableCellProps = {
    flexBasis: number | string;
    isHeader?: boolean;
    value: React.ReactNode;
    isStub?: boolean;
    isLeftBorderHidden?: boolean;
    isLast?: boolean;
}

export function DocumentTableCell({
    flexBasis = 'auto',
    isHeader = false,
    value,
    isStub = false,
    isLeftBorderHidden = false,
    isLast = false,
}: DocumentTableCellProps) {
    return (
        <div
            style={{flexBasis}}
            className={cn('table-cell',
                {
                    'table-cell--header': isHeader,
                    'table-cell--last': isLast,
                    'table-cell--stub': isStub,
                    'table-cell--hidden-left-border': isLeftBorderHidden,
                })}
        >
            {value}
        </div>
    );
}
