import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {mismatchDataSelector} from '@modules/modals';
import {isLkBlockedSelector} from '@modules/navigation';

import {NavigationErrorBeforeContract} from './navigation-error-before-contract';

const mapStateToProps = createStructuredSelector({
    isLkBlocked: isLkBlockedSelector,
    mismatchData: mismatchDataSelector,
});

export const NavigationErrorBeforeContractContainer = connect(mapStateToProps)(NavigationErrorBeforeContract);
