import * as React from 'react';

import {CalendarsManager} from '../calendars-manager';

export interface SingleDatePickerMenuProps {
    selectedDate?: Date;
    onChange: (date: string, closeCalendar: boolean) => void;
    onKeyDown: (keyCode: number) => void;
    onDisplayChange: (height: number) => void;
    availableDates?: [Date, Date?];
    showFirstAvailableDate?: boolean;
}

export class SingleDatePickerMenu extends React.PureComponent<SingleDatePickerMenuProps> {
    calendarsContainerRef: HTMLElement = null;

    componentDidMount() {
        const frameId = requestAnimationFrame(() => {
            cancelAnimationFrame(frameId);
            this.calendarsContainerRef.focus();
        });
    }

    handleDateChange = (date?: Date) => {
        const {onChange} = this.props;

        onChange(date && date.toISOString(), !!date);
    };

    handleCalendarsManagerRef = (ref: HTMLElement) => {
        this.calendarsContainerRef = ref;
    };

    render() {
        const {
            selectedDate,
            onKeyDown,
            onDisplayChange,
            availableDates,
            showFirstAvailableDate,
        } = this.props;

        return (
            <CalendarsManager
                firstSelectedDate={selectedDate}
                onChange={this.handleDateChange}
                onKeyDown={onKeyDown}
                onDisplayChange={onDisplayChange}
                onClearSelectedDates={this.handleDateChange}
                withRef={this.handleCalendarsManagerRef}
                availableDates={availableDates}
                showFirstAvailableDate={showFirstAvailableDate}
            />
        );
    }
}
