import * as React from 'react';

import {createTranslator} from '@utils/i18n';
import {MatchParams} from '@utils/match-params';

import {AboutPropsInterface, AgreementBlockInterface} from './agreement-page-types';
import {About} from './components/about';
import {AgreementBlock} from './components/agreement-block';

import * as dictionary from './agreement-page.dic.json';

const t = createTranslator(dictionary);

const LIST_ITEMS_MOCK: AboutPropsInterface = {
    title: t('about-page-accordion-title'),
    items: [
        {
            key: 1,
            title: t('about-page-accordion-terms'),
            body: <div dangerouslySetInnerHTML={{__html: t('about-page-accordion-terms-text')}} />,
        },
        {
            key: 2,
            title: t('about-page-accordion-rules'),
            body: <div dangerouslySetInnerHTML={{__html: t('about-page-accordion-rules-text')}} />,
        },
        {
            key: 3,
            title: t('about-page-accordion-you-need'),
            body: <div dangerouslySetInnerHTML={{__html: t('about-page-accordion-you-need-text')}} />,
        },
        {
            key: 4,
            title: t('about-page-accordion-contract-terms'),
            body: <div dangerouslySetInnerHTML={{__html: t('about-page-accordion-contract-terms-text')}} />,
        },
        {
            key: 5,
            title: t('about-page-accordion-limitations'),
            body: <div dangerouslySetInnerHTML={{__html: t('about-page-accordion-limitations-text')}} />,
        },
        {
            key: 6,
            title: t('about-page-accordion-temporary-restrictions'),
            body: <div dangerouslySetInnerHTML={{__html: t('about-page-accordion-temporary-restrictions-text')}} />,
        },
        {
            key: 7,
            title: t('about-page-accordion-conditions-for-blocking'),
            body: <div dangerouslySetInnerHTML={{__html: t('about-page-accordion-conditions-for-blocking-text')}} />,
        },
    ],
};

const AGREEMENT_BLOCK_MOCK: AgreementBlockInterface = {
    title: t('agreement-block-title'),
    text: t('agreement-block-text'),
    checkboxText: t('agreement-block-checkbox'),
};

interface AgreementPageInterface {
    setContractId(value: string): void;
    match: MatchParams;
    loadCaptchaPublicKey: () => void;
    loadFirstPageOpened: (contractId: string) => void;
    feEgarantCapthcaEnable: boolean;
}

export function AgreementPage({
    setContractId,
    match,
    loadCaptchaPublicKey,
    loadFirstPageOpened,
    feEgarantCapthcaEnable,
}: AgreementPageInterface) {
    React.useEffect(() => {
        setContractId(match.params.contractId);

        if (feEgarantCapthcaEnable) {
            loadCaptchaPublicKey();
        }

        loadFirstPageOpened(match.params.contractId);
    }, []);

    return (
        <div>
            <div>
                <About {...LIST_ITEMS_MOCK} />
                <AgreementBlock {...AGREEMENT_BLOCK_MOCK} />
            </div>
        </div>
    );
}
