import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {
    Document,
    DocumentFile as DocumentFileType,
    UploadedDocumentFile,
    getUploadedFile,
    DocumentFileStatuses,
} from '@modules/contract';
import {Hint} from '@ui-kit';
import {AppStages} from '@utils/constants';
import {createTranslator} from '@utils/i18n';

import {
    DocumentTableCell,
    DocumentFile,
    DocumentFileStatus,
} from './components';

import * as styles from './documents-table.scss';

const t = createTranslator(dictionary);
const cn = classNames.bind(styles);

export interface DocumentsFormProps {
    documents: Document[];
    uploadedDocumentFiles: UploadedDocumentFile[];
    onFileLoad: (formData: FormData, document: Document, file: DocumentFileType, fileName: string) => void;
    updateUploadedFilesStatuses?: () => void;
    isScanSendingInitiated?: boolean;
    currentStage?: string;
    isLkBlocked?: boolean;
    toggleUploadedFilesBtn: () => void;
}

interface Column {
    name: string;
    renderCell: (row: Document) => React.ReactNode;
    isStub?: boolean;
    header?: string;
    width?: number;
    isLeftBorderHidden?: boolean;
}

const getColumns = (
    uploadedDocumentFiles: UploadedDocumentFile[],
    onFileLoad: (formData: FormData, document: Document, file: DocumentFileType, fileName: string) => void,
    isScanSendingInitiated: boolean,
    currentStage: string,
    isLkBlocked: boolean
) => [
    {
        name: 'stub-1',
        renderCell: (): Document => null,
        isStub: true,
    },
    {
        header: t('documents-table.column.title'),
        name: 'title',
        width: 500,
        isLeftBorderHidden: true,
        renderCell: (row: Document) => (
            <div>
                <span>{row.title}</span>
                {row.hint && <span className={cn('table__hint')}><Hint text={row.hint} /></span>}
            </div>
        ),
    },
    {
        header: t('documents-table.column.file'),
        name: 'file',
        width: 400,
        renderCell: (row: Document) => (
            <div className={cn('table__file-wrapper')}>
                {row.label && <div className={cn('table__file-value')}>{row.label}</div>}
                {row.files.map((file: DocumentFileType, index: number) => {
                    const uploadedFile = getUploadedFile(
                        uploadedDocumentFiles,
                        file.fileOrderInGroup,
                        row.type,
                        row.documentIndex
                    );
                    const NO_DISABLE_STATUS = [
                        DocumentFileStatuses.SUCCESS,
                        DocumentFileStatuses.WRONG_FORMAT,
                        DocumentFileStatuses.FAIL,
                    ];

                    return (
                        <DocumentFile
                            file={file}
                            initialFileName={uploadedFile && uploadedFile.fileName}
                            onFileLoad={(formData, fileName) => onFileLoad(formData, row, file, fileName)}
                            key={+index}
                            disabled={
                                currentStage !== AppStages.SCAN_ATTACHMENT ||
                                isLkBlocked ||
                                (
                                    Boolean(uploadedFile?.fileName) &&
                                    !(NO_DISABLE_STATUS.includes(uploadedFile?.status))
                                )
                            }
                        />
                    );
                })}
            </div>
        ),
    },
    {
        header: t('documents-table.column.status'),
        name: 'status',
        width: 310,
        renderCell: (row: Document) => (
            <div className={cn('table__file-wrapper')}>
                {row.label && <div className={cn('table__file-value')} />}
                {row.files.map((file: DocumentFileType, index: number) => {
                    const uploadedFile = getUploadedFile(
                        uploadedDocumentFiles,
                        file.fileOrderInGroup,
                        row.type,
                        row.documentIndex
                    );
                    return (
                        <DocumentFileStatus
                            status={uploadedFile && uploadedFile.status}
                            key={file.fileOrderInGroup}
                        />
                    );
                })}
            </div>
        ),
    },
    {
        name: 'stub-2',
        isStub: true,
        renderCell: (): Document => null,
        isLeftBorderHidden: true,
    },
];
/*
* TODO: объединить с data-table из ui-kit - RAMIEPT-43139
*  добавить необходимые пропы в data-table:
* растяжение боковых колонок на всю доступную ширину контейнера (isStub)
* настройка для отображения/скрытия вертикальных/горизонтальных разделителей (аналог для isLeftBorderHidden)
* проп для прокидывания кастомных стилей в таблицу (фон и цвет границ шапки, размер/цвет шрифтов для ячеек, отступы)
*   (по типу getRowStyle из data-table)
* */
export function DocumentsTable({
    uploadedDocumentFiles = [],
    onFileLoad,
    documents,
    updateUploadedFilesStatuses,
    isScanSendingInitiated,
    currentStage,
    isLkBlocked,
    toggleUploadedFilesBtn,
}: DocumentsFormProps) {
    React.useEffect(() => {
        if (currentStage === AppStages.AUTO_ACCEPT_TIMEOUT && !isLkBlocked) {
            updateUploadedFilesStatuses();
        }

        if (currentStage === AppStages.SCAN_ATTACHMENT) {
            toggleUploadedFilesBtn();
        }
    }, []);

    const renderHeaderRow = (columns: Column[]) => (
        <div className={cn('table__row')}>
            {columns.map(({header, width, ...rest}, index) => (
                <DocumentTableCell {...rest} flexBasis={width} value={header} isHeader key={+index} />
            ))}
        </div>
    );

    const renderRows = (columns: Column[], rows: Document[]) => (
        <>
            {rows.map((row, rowIndex) => (
                <div className={cn('table__row')} key={+rowIndex}>
                    {columns.map(({renderCell, width, ...rest}, index) => (
                        <DocumentTableCell
                            {...rest}
                            flexBasis={width}
                            value={renderCell(row)}
                            isLast={rowIndex === rows.length - 1}
                            key={+index}
                        />
                    ))}
                </div>
            ))}
        </>
    );

    const columns = React.useMemo(
        () => getColumns(
            uploadedDocumentFiles,
            onFileLoad,
            isScanSendingInitiated,
            currentStage,
            isLkBlocked,
        ),
        [uploadedDocumentFiles, currentStage, isLkBlocked]
    );

    return (
        <div className={cn('table-container')}>
            <div className={cn('header')}>{t('documents-table.title')}</div>
            <div className={cn('table')}>
                {renderHeaderRow(columns)}
                {renderRows(columns, documents)}
            </div>
        </div>
    );
}
