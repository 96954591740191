import * as React from 'react';

import {withErrorIndicator} from '@app/components/fields/with-error-indicator';
import {Vehicle} from '@app/types';
import {MappedMismatchField} from '@modules/validation';
import {Checkbox, TextField} from '@ui-kit';
import {SelectOption} from '@utils/formatters/select-option';
import {shouldBeDisabled} from '@utils/should-be-disabled';

const MAX_DISABLED_CHECK_COUNT = 3;

export interface CheckboxFieldInterface {
    cn: (fn: string) => string;
    vehicle: Vehicle;
    name: 'vin' | 'bodyNumber' | 'schassisNumber' | 'numberPlate';
    textFieldLabel: string;
    checkboxLabel: string;
    mutator: (field: string, value: string | SelectOption<number>) => void;
    warningData: MappedMismatchField[];
    scansEditFields: string[];
    checkedCount?: number;
    onChange?: (disabled: boolean) => void;
    isLkBlocked: boolean;
}

export function CheckedField({
    cn,
    vehicle,
    name,
    textFieldLabel,
    checkboxLabel,
    mutator,
    warningData,
    scansEditFields,
    checkedCount,
    onChange,
    isLkBlocked,
}: CheckboxFieldInterface) {
    const value = vehicle[name];
    const [textFieldValue, changeTextFieldValue] = React.useState(value);
    const [disableField, changeDisabling] = React.useState(!value);

    React.useEffect(() => {
        changeTextFieldValue(value);
        changeDisabling(!value);
        if (onChange && !value && value !== undefined) {
            onChange(true);
        }
    }, [changeTextFieldValue, changeDisabling, value]);

    const handleTextFieldChange = (value: string) => {
        changeTextFieldValue(value);
    };

    const handleCheckboxChange = () => {
        mutator(name, disableField ? textFieldValue : null);
        changeDisabling(!disableField);
        if (onChange) {
            onChange(!disableField);
        }
    };
    const checkboxDisabledDueToLimit = !disableField && checkedCount >= MAX_DISABLED_CHECK_COUNT;
    const warning = warningData?.find((mismatch: MappedMismatchField) => mismatch.fieldName === name);

    const CheckboxWithErrorIndicator = withErrorIndicator(() => (
        <Checkbox
            label={checkboxLabel}
            id={name}
            checked={disableField}
            onChange={handleCheckboxChange}
            isDisabled={shouldBeDisabled({
                name,
                listFieldError: scansEditFields,
                isLkBlocked,
                forceDisabled: checkboxDisabledDueToLimit,
            })}
        />
    ));

    return (
        <div className={cn('right-part__form-field-wrapper')}>
            <div className={cn('right-part__form-field')}>
                <TextField
                    name={name}
                    label={textFieldLabel}
                    disabled={shouldBeDisabled({
                        name,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                        forceDisabled: disableField,
                    })}
                    onChange={handleTextFieldChange}
                    required={!disableField}
                />
            </div>
            <div className={cn('right-part__form-field')}>
                <CheckboxWithErrorIndicator
                    isValid={!warning || warning.fieldValue || !disableField}
                    disabled={shouldBeDisabled({
                        name,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                        forceDisabled: checkboxDisabledDueToLimit,
                    })}
                    errorMessage={[warning?.fieldMessages]}
                />
            </div>
        </div>
    );
}
