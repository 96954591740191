import {AxiosResponse} from 'axios';

import {Mismatch} from '@app/types';

export enum RequestCode {
    SUCCESS = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    REQUEST_TIMEOUT = 408,
    GONE = 410,
    UNPROCESSABLE_ENTITY = 422,
    INTERNAL_SERVER_ERROR = 500,
    GATEWAY_TIMEOUT = 504
}

export const SYSTEM_STATUSES = [
    RequestCode.INTERNAL_SERVER_ERROR,
    RequestCode.GATEWAY_TIMEOUT,
    RequestCode.FORBIDDEN,
];

export const DEFAULT_MESSAGES: {[key: number]: string} = {
    [RequestCode.NOT_FOUND]: 'Ресурс не найден',
    [RequestCode.INTERNAL_SERVER_ERROR]: 'Сервис временно недоступен',
    [RequestCode.FORBIDDEN]: 'Неправильный логин или пароль. Попробуйте еще раз.',
};

export class RequestError extends Error {
    readonly status: number;

    readonly codes: string[];

    readonly isLogicError: boolean;

    readonly mismatches: Mismatch[];

    readonly response: AxiosResponse;

    constructor(status: number, data?: Mismatch[] | Error, response?: AxiosResponse) {
        const message = Array.isArray(data) ?
            data.map((el: Mismatch) => el.message).join('\n') :
            data.message;
        const actualMessage = message ||
            DEFAULT_MESSAGES[status] ||
            DEFAULT_MESSAGES[RequestCode.INTERNAL_SERVER_ERROR];
        super(actualMessage);

        this.mismatches = Array.isArray(data) ? data : [];
        this.status = status;
        this.codes = Array.isArray(data) ?
            data.map((el: Mismatch) => el.code) :
            null;
        this.response = response;

        this.isLogicError = this.status !== RequestCode.UNAUTHORIZED;
    }
}
