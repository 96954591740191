import * as React from 'react';

import {clickOutside} from '@utils/dom';

import {ValidationErrorIndicator} from './validation-error-indicator';
import {MAX_SCROLLBARS_HEIGHT} from './validation-error-indicator-constants';

export interface ValidationErrorIndicatorProps {
    messages: string[];
}

export interface ValidationErrorIndicatorState {
    errorsVisible: boolean,
    calculatedErrorsHeight: number
}

export class ValidationErrorIndicatorContainer extends React.Component<ValidationErrorIndicatorProps,
    ValidationErrorIndicatorState> {
    state = {
        errorsVisible: false,
        calculatedErrorsHeight: 0,
    };

    wrapperRef = React.createRef<HTMLDivElement>();

    bannerWrapperRef: HTMLElement;

    unregisterOutsideClickHandler: Function;

    componentWillUnmount() {
        if (this.unregisterOutsideClickHandler) {
            this.unregisterOutsideClickHandler();
        }
    }

    openBanner = (): void => {
        this.setState(() => ({errorsVisible: true}), () => {
            this.unregisterOutsideClickHandler = clickOutside(
                this.bannerWrapperRef,
                this.closeBanner
            );
        });
    };

    closeBanner = (): void => {
        this.setState(() => ({errorsVisible: false}), () => {
            if (this.unregisterOutsideClickHandler) {
                this.unregisterOutsideClickHandler();
            }
        });
    };

    handleMessagesRef = (ref: HTMLElement) => {
        if (ref) {
            const calculatedErrorsHeight = Array.from(ref.childNodes).reduce(
                (height: number, message: HTMLElement) => height + message.offsetHeight, 0
            );

            this.setState({
                calculatedErrorsHeight: calculatedErrorsHeight > MAX_SCROLLBARS_HEIGHT ?
                    MAX_SCROLLBARS_HEIGHT : calculatedErrorsHeight,
            });
        }
    };

    handleBannerWrapperRef = (ref: HTMLElement) => {
        this.bannerWrapperRef = ref;
    };

    render(): React.ReactNode {
        const {
            state: {
                errorsVisible,
                calculatedErrorsHeight,
            },
            props: {
                messages,
            },
            wrapperRef,
            openBanner,
            closeBanner,
            handleMessagesRef,
            handleBannerWrapperRef,
        } = this;

        return (
            <ValidationErrorIndicator
                messages={messages}
                wrapperRef={wrapperRef}
                bannerWrapperRef={this.bannerWrapperRef}
                errorsVisible={errorsVisible}
                openBanner={openBanner}
                closeBanner={closeBanner}
                calculatedErrorsHeight={calculatedErrorsHeight}
                handleMessagesRef={handleMessagesRef}
                handleBannerWrapperRef={handleBannerWrapperRef}
            />
        );
    }
}
