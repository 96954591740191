import {debounce, noop} from 'lodash-es';
import * as React from 'react';

import {SelectOption} from '@utils/formatters/select-option';

import {LoadOptionsFuncType, LoadOptionsResultType} from '../field-types';
import {SelectWithFieldAdapterProps} from '../select-field/select-with-field-adapter';

interface Props<T> {
    loadOptions?: LoadOptionsFuncType<T>
}

interface AutocompleteState<T> {
    startWith: string;
    pageIndex: number;
    options: SelectOption<T>[];
    hasMoreData: boolean;
    isLoading: boolean;
}

type WithAutocompleteAdapterElementFn<T> = (props: Props<T>) => React.ReactElement;

const defaultState = <T, >(options: SelectOption<T>[]): AutocompleteState<T> => ({
    startWith: '',
    pageIndex: 0,
    options,
    hasMoreData: true,
    isLoading: false,
});

interface WrapLoadOptionsInput {
    startWith: string;
    pageIndex: number;
    pageSize: number;
    reqId: number;
}

type RequestKeeper<T> = React.MutableRefObject<{
    requestId: number;
    wrapLoadOptions: (input: WrapLoadOptionsInput) => Promise<LoadOptionsResultType<T>>
}>;

export function withAutocompleteAdapter<T>(
    WrapperComponent: React.ComponentType<SelectWithFieldAdapterProps<T>>
): WithAutocompleteAdapterElementFn<T> {
    function WithAutocompleteAdapter({
        options: defaultOptions,
        loadOptions,
        pageSize = 10,
        delay = 200,
        ...restProps
    }: any) {
        const requestKeeper: RequestKeeper<T> = React.useRef({
            requestId: Date.now(),
            wrapLoadOptions: noop,
        }) as RequestKeeper<T>;
        const [state, setState] = React.useState<AutocompleteState<T>>(defaultState(defaultOptions));

        React.useEffect(() => {
            requestKeeper.current.wrapLoadOptions =
                (input: WrapLoadOptionsInput): Promise<LoadOptionsResultType<T>> => {
                    const {
                        startWith, pageIndex, pageSize, reqId,
                    } = input;

                    return new Promise<LoadOptionsResultType<T>>((resolve, reject) => {
                        loadOptions(startWith, pageIndex, pageSize)
                            .then((data: LoadOptionsResultType<T>) => {
                                if (requestKeeper.current.requestId === reqId) {
                                    resolve(data);
                                } else {
                                    reject(reqId);
                                }
                            }, reject);
                    });
                };
        }, [loadOptions]);

        const handleFilterChange = React.useCallback(debounce(async (startWith: string) => {
            requestKeeper.current.requestId = Date.now();

            setState((prevState: AutocompleteState<T>) => ({
                ...prevState,
                options: defaultOptions,
                isLoading: true,
                hasMoreData: true,
            }));
            try {
                const {requestId, wrapLoadOptions} = requestKeeper.current;
                const data: LoadOptionsResultType<T> = await wrapLoadOptions({
                    startWith, pageIndex: 0, pageSize, reqId: requestId,
                });
                setState((prevState: AutocompleteState<T>) => ({
                    ...prevState,
                    startWith,
                    pageIndex: 1,
                    options: [...defaultOptions, ...data.options],
                    hasMoreData: data.hasMoreData,
                    isLoading: false,
                }));
            } catch (err) {
                // cancel request
                setState((prevState: AutocompleteState<T>) => ({
                    ...prevState,
                    isLoading: false,
                }));
            }
        }, delay), [pageSize, delay, defaultOptions, requestKeeper.current]);

        const handleScrollToBottom = React.useCallback(async () => {
            const {
                startWith,
                pageIndex,
                hasMoreData,
                isLoading,
            } = state;
            if (!isLoading && hasMoreData) {
                setState((prevState: AutocompleteState<T>) => ({
                    ...prevState,
                    isLoading: true,
                }));
                try {
                    const {requestId, wrapLoadOptions} = requestKeeper.current;
                    const data: LoadOptionsResultType<T> = await wrapLoadOptions({
                        startWith, pageIndex, pageSize, reqId: requestId,
                    });
                    setState((prevState: AutocompleteState<T>) => ({
                        ...prevState,
                        pageIndex: pageIndex + 1,
                        options: [...prevState.options, ...data.options],
                        hasMoreData: data.hasMoreData,
                        isLoading: false,
                    }));
                } catch (err) {
                    // cancel request
                    setState((prevState: AutocompleteState<T>) => ({
                        ...prevState,
                        isLoading: false,
                    }));
                }
            }
        }, [requestKeeper.current, pageSize, state]);

        const resetAutocompleteState = React.useCallback(() => {
            const {isChangeKeyboard} = restProps;
            requestKeeper.current.requestId = Date.now();
            if (!isChangeKeyboard) {
                setState(defaultState(defaultOptions));
            }
        }, []);

        return (
            <WrapperComponent
                {...restProps}
                isFilterable
                options={state.options}
                onFilterChange={handleFilterChange}
                onScrollToBottom={handleScrollToBottom}
                resetAutocompleteState={resetAutocompleteState}
                isLoading={state.isLoading}
                onFocus={handleFilterChange}
                startWith={state.startWith}
            />
        );
    }

    WithAutocompleteAdapter.displayName = `WithAutocompleteAdapter(${WrapperComponent && (
        WrapperComponent.displayName || WrapperComponent.name
    )})`;

    return WithAutocompleteAdapter;
}
