import classNames from 'classnames/bind';
import * as React from 'react';

import {PortalRoot} from '@ui-kit';
import {SelectOption} from '@utils/formatters/select-option';
import {createTranslator} from '@utils/i18n';

import {LinkSelect} from '../select';

import * as dictionary from './paginator.dic.json';
import * as styles from './paginator.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface RowsPerPageSelectorProps {
    onRowsPerPageChange?: (value: SelectOption<number>) => void;
    rowsPerPage: number;
    options?: SelectOption<number>[];
}

export function RowsPerPageSelector({onRowsPerPageChange, rowsPerPage, options}: RowsPerPageSelectorProps) {
    const selectedOption: SelectOption<number> = {label: String(rowsPerPage), value: rowsPerPage};

    return (
        <div className={cn('rows-per-page')}>
            <span className={cn('rows-per-page__text')}>
                {t('paginator.rows-per-page')}
            </span>
            <span className={cn('rows-per-page__select')}>
                <PortalRoot />
                <LinkSelect
                    options={options}
                    selectedOption={selectedOption}
                    onChange={onRowsPerPageChange}
                />
            </span>
        </div>
    );
}
