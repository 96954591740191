import classNames from 'classnames/bind';
import * as React from 'react';

import {Hint, TextField, CheckboxField} from '@app/components';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {Step1FormInterface} from '@app/pages/contract-page/pages/step-1/step1-types';
import {Person, PersonType} from '@app/types';
import {createTranslator} from '@app/utils/i18n';
import {formatPhoneNumber} from '@utils/formatters';
import {shouldBeDisabled} from '@utils/should-be-disabled';

import {AddressData} from '../address-data';
import {DocumentData} from '../document-data';
import {PersonData} from '../person-data';

import * as styles from './policyholder.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface PolicyholderInterface {
    mutator: (field: string, value: string) => void;
    person: Person;
    values: Step1FormInterface;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function Policyholder({
    mutator,
    person,
    values,
    scansEditFields,
    isLkBlocked,
}: PolicyholderInterface) {
    return (
        <div className={cn('policyholder')}>
            <h2>{t('policyholder')}</h2>
            <div className={cn('policyholder__checkboxes')}>
                <CheckboxField
                    label={t('the-policyholder-is-the-owner-of-the-vehicle')}
                    name="insurer-isOwner"
                    isDisabled={shouldBeDisabled({
                        name: 'insurer-isOwner',
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                />
                <div className={cn('policyholder__hinted')}>
                    <CheckboxField
                        label={t('the-policyholder-is-a-driver')}
                        name="insurer-isDriver"
                        isDisabled={shouldBeDisabled({
                            name: 'insurer-isDriver',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                    />
                    <div className={cn('policyholder__hint-wrapper')}>
                        <div className={cn('policyholder__hint')}>
                            <Hint text={t('hint-text')} />
                        </div>
                    </div>
                </div>
            </div>
            <PersonData
                personType={PersonType.INSURER}
                person={person}
                mutator={mutator}
            />
            <div className={cn('policyholder__row')}>
                <TextField
                    name="insurer-person-phoneNumber"
                    label={t('mobile-phone')}
                    parse={formatPhoneNumber}
                    disabled={shouldBeDisabled({
                        name: 'insurer-person-phoneNumber',
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required
                />
            </div>
            <DocumentData personType={PersonType.INSURER} />
            <AddressData
                personType={PersonType.INSURER}
                mutator={mutator}
                values={values}
            />
        </div>
    );
}
