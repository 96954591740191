import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {createTranslator} from '@app/utils/i18n';

import {StepComponent} from './components/step-component';

import * as styles from './stepper-navigator.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const parseSteps = (currentStep: number, stepTitles: string[]) => (stepTitles.map((index, key, array) => (
    <StepComponent
        key={index}
        step={key + 1}
        title={t(index)}
        current={currentStep - 1 === key}
        success={currentStep - 1 > key}
        last={key === array.length - 1}
    />
)));

type StepperNavigatorType = {
    currentStep: number;
}

export function StepperNavigator({currentStep}: StepperNavigatorType) {
    const stepTitles = [
        'contract-title-policyholder',
        'contract-title-vehicle',
        'contract-title-drivers',
        'contract-title-registration',
    ];

    return (
        <div className={cn('stepper-wrapper')}>
            {parseSteps(
                currentStep,
                stepTitles
            )}
        </div>
    );
}
