import {MakerDto, ModelDto, TypeCategoryDto} from '@app/types';
import {SelectOption} from '@utils/formatters/select-option';

export enum Step2FormInterfaceKeys {
    INSURANCE_START_TIME = 'insuranceStartTime',
    INSURANCE_START_DATE = 'insuranceStartDate',
    INSURANCE_END_TIME = 'insuranceEndTime',
    INSURANCE_END_DATE = 'insuranceEndDate',
    IS_ON_THE_WAY_TO_REGISTRATION = 'isOnTheWayToRegistration',
    IS_USED_WITH_TRAILER = 'isUsedWithTrailer',
    USE_PERIOD_1 = 'usePeriod1',
    USE_PERIOD_1_START_DATE = 'usePeriod1StartDate',
    USE_PERIOD_1_END_DATE = 'usePeriod1EndDate',
    USE_PERIOD_2 = 'usePeriod2',
    USE_PERIOD_2_START_DATE = 'usePeriod2StartDate',
    USE_PERIOD_2_END_DATE = 'usePeriod2EndDate',
    USE_PERIOD_3 = 'usePeriod3',
    USE_PERIOD_3_START_DATE = 'usePeriod3StartDate',
    USE_PERIOD_3_END_DATE = 'usePeriod3EndDate',
    IS_SHORT_INSURANCE_POLICY = 'isShortInsurancePolicy',
}

export interface Step2FormInterface {
    maker: SelectOption<MakerDto>;
    otherMaker: string;
    model: SelectOption<ModelDto>;
    otherModel: string;
    typeCategory: SelectOption<TypeCategoryDto>;
    seatsAmount: string;
    maxWeight: string;
    year: SelectOption<number>;
    power: string;
    'document-documentType': SelectOption<string>;
    'document-series': string;
    'document-number': string;
    'document-dateGiven': string;
    'diagnosticCard-dateEnd': string;
    'diagnosticCard-number': string;

    [Step2FormInterfaceKeys.INSURANCE_START_TIME]: string;
    [Step2FormInterfaceKeys.INSURANCE_START_DATE]: string;
    [Step2FormInterfaceKeys.INSURANCE_END_TIME]: string;
    [Step2FormInterfaceKeys.INSURANCE_END_DATE]: string;

    [Step2FormInterfaceKeys.USE_PERIOD_1]: SelectOption<number>;
    [Step2FormInterfaceKeys.USE_PERIOD_1_START_DATE]: string;
    [Step2FormInterfaceKeys.USE_PERIOD_1_END_DATE]: string;
    [Step2FormInterfaceKeys.USE_PERIOD_2]?: SelectOption<number>;
    [Step2FormInterfaceKeys.USE_PERIOD_2_START_DATE]?: string;
    [Step2FormInterfaceKeys.USE_PERIOD_2_END_DATE]?: string;
    [Step2FormInterfaceKeys.USE_PERIOD_3]?: SelectOption<number>;
    [Step2FormInterfaceKeys.USE_PERIOD_3_START_DATE]?: string;
    [Step2FormInterfaceKeys.USE_PERIOD_3_END_DATE]?: string;
    vin: string;
    bodyNumber: string;
    schassisNumber: string;
    numberPlate: string;
    usePurpose: SelectOption<string>;
    registrationCountry: string;
    [Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION]: string;
    [Step2FormInterfaceKeys.IS_USED_WITH_TRAILER]: string;
    [Step2FormInterfaceKeys.IS_SHORT_INSURANCE_POLICY]: string;
}
