import classNames from 'classnames/bind';
import * as React from 'react';

import {createTranslator} from '@utils/i18n';

import {IconButton} from '../icon-button';
import {Icons} from '../icons';
import {RowsPerPageSelector, RowsPerPageSelectorProps} from './rows-per-page-selector';

import * as dictionary from './paginator.dic.json';
import * as styles from './paginator.scss';

export interface PaginatorProps extends RowsPerPageSelectorProps{
    page: number,
    onPageChange?: (page: number) => void,
    rowsCount: number,
    rowsPerPage: number
}

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function Paginator({
    page,
    onPageChange,
    rowsCount,
    rowsPerPage,
    onRowsPerPageChange,
    options,
}: PaginatorProps) {
    const firstRecord = rowsCount ? ((page - 1) * rowsPerPage) + 1 : 0;
    const nextRecord = firstRecord + rowsPerPage - 1;
    const lastRecord = nextRecord > rowsCount ? rowsCount : nextRecord;

    return (
        <div className={cn('paginator')}>
            {onRowsPerPageChange && (
                <RowsPerPageSelector
                    onRowsPerPageChange={onRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    options={options}
                />
            )}
            <span className={cn('paginator__info')}>
                {t('paginator.info', firstRecord, lastRecord, rowsCount)}
            </span>
            <IconButton
                icon={Icons.PREV}
                disabled={page === 1}
                onClick={() => onPageChange(page - 1)}
            />
            <IconButton
                icon={Icons.NEXT}
                disabled={page * rowsPerPage >= rowsCount}
                onClick={() => onPageChange(page + 1)}
            />
        </div>
    );
}
