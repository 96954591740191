import * as React from 'react';

import {FontIcon, FontIconTypes, FontIconProps} from './font-icon';
import {FillRule, SvgIcon, SvgIconProps} from './svg-icon';

type SvgIconOptions = {
    fillRule?: FillRule;
    height?: number;
    viewBoxHeight?: number;
    viewBoxWidth?: number;
    transform?: string;
    width?: number;
}

export const makeFontIcon = (
    iconType: FontIconTypes,
    name: string,
    size = 16,
    rotateDegree?: number
) => {
    const Icon = (props: FontIconProps) => (
        <FontIcon size={size} {...props} name={iconType} rotateDegree={rotateDegree} />
    );
    Icon.displayName = `Icons.${name}`;
    return Icon;
};

export const makeSvgIcon = (
    path: string,
    name: string,
    originalSize: number = 16,
    {
        transform,
        fillRule,
        height,
        viewBoxHeight,
        viewBoxWidth,
        width,
    }: SvgIconOptions = {}
) => {
    const Icon = (props: SvgIconProps) => (
        <SvgIcon
            height={height}
            fillRule={fillRule}
            originalSize={originalSize}
            viewBoxHeight={viewBoxHeight}
            viewBoxWidth={viewBoxWidth}
            transform={transform}
            width={width}
            {...props}
            path={path}
        />
    );
    Icon.displayName = `Icons.${name}`;
    return Icon;
};
