import classNames from 'classnames/bind';
import * as React from 'react';

import {TextField} from '@ui-kit';

import * as styles from './form-field.scss';

const cn = classNames.bind(styles);

interface FormFieldInterface {
    name: string;
    label: string;
}

export function FormField({name, label}: FormFieldInterface) {
    return (
        <div className={cn('form-container__form-field')}>
            <TextField
                name={name}
                label={label}
                disabled
            />
        </div>
    );
}
