export {default as noop} from 'lodash-es/noop';
export {default as get} from 'lodash-es/get';
export {default as set} from 'lodash-es/set';
export {default as findIndex} from 'lodash-es/findIndex';
export {default as includes} from 'lodash-es/includes';
export {default as isFunction} from 'lodash-es/isFunction';
export {default as cloneDeep} from 'lodash-es/cloneDeep';
export {default as pickBy} from 'lodash-es/pickBy';
export {default as intersection} from 'lodash-es/intersection';
export {default as isNil} from 'lodash-es/isNil';
export {default as groupBy} from 'lodash-es/groupBy';
export {default as curry} from 'lodash-es/curry';
export {default as isEmpty} from 'lodash-es/isEmpty';
export {default as compact} from 'lodash-es/compact';

export const createObjectSorter = (field: string, asc: boolean = true) => (a: any, b: any): number => {
    if (a[field] === b[field]) {
        return 0;
    }
    return a > b && asc ? 1 : -1;
};

export const arrayMove = (items: any[], from: number, to: number) => {
    const array = items.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);

    return array;
};

export const isLongerThanLimit = (text: string = '', limit: number) => text.length > limit;

export const shortenText = (
    text: string = '',
    limit: number,
    trail: string = '...'
) => text.slice(0, limit).concat(trail);

export const joinWithSpace = (arr: string[]) => arr.reduce(
    (acc: [], i: string) => (i ? [...acc, i.trim()] : acc),
    []
).join(' ');
