import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {currentStepSelector} from '@modules/navigation';

import {StepperNavigator} from './stepper-navigator';

const mapStateToProps = createStructuredSelector({
    currentStep: currentStepSelector,
});

export const StepperNavigatorContainer = connect(mapStateToProps)(StepperNavigator);
