import classNames from 'classnames/bind';
import * as React from 'react';

import {LinkDataInterface} from '@modules/contract';
import {Anchor} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as dictionary from '../../registration-page.dic.json';
import * as styles from './text-information.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function TextInformation({linkData}: {linkData: LinkDataInterface}) {
    return (
        <div className={cn('text-information')}>
            <div className={cn('text-information__wrapper-center')}>
                <h2 className={cn('text-information__header')}>
                    {t('registration-page-header')}
                </h2>
                <ul className={cn('text-information__list')}>
                    <li className={cn('text-information__list-item')}>
                        {t('registration-page-text-information-pay-attention')}
                    </li>
                    <li className={cn('text-information__list-item')}>
                        <div>
                            {t('registration-page-text-information-list-assignee')}
                            <Anchor
                                link={linkData?.representativesLink}
                                label={t('registration-page-text-information-link')}
                                target="_blank"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}
