import classNames from 'classnames/bind';
import * as React from 'react';
import {compose} from 'redux';

import {
    Column,
    DataTable,
    SortCriteria,
} from '../data-table-types';
import {withColumnsStatuses} from './HOCs/with-columns-statuses';
import {withTableSorting} from './HOCs/with-table-sorting';
import {HeaderRow} from './header-row';
import {RowsContainer} from './rows-container';

import * as styles from './table.scss';

export interface TableProps extends DataTable {
    height: number;
    width: number;
    sortCriteria: SortCriteria;
    onHeaderCellClick: (field: string) => void;
    clickable?: boolean;
}

interface TableState {
    columns: Column[];
    offsetX: number;
    rowWidth: number;
    tableWidth: number;
    draggableRowIndex?: number;
}

const cn = classNames.bind(styles);

const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 60;

class TableComponent extends React.PureComponent<TableProps, TableState> {
    state: TableState = {
        columns: [] as Column[],
        offsetX: 0,
        rowWidth: 0,
        tableWidth: 0,
        draggableRowIndex: null,
    };

    static getDerivedStateFromProps({width, columns}: TableProps, {tableWidth}: TableState) {
        const rowWidth = columns.reduce((fullWidth: number, {width}: Column) => fullWidth + width, 0);
        let widthIncrement = 0;
        if (rowWidth < width) {
            const freeWidth = width - rowWidth;
            const stretchableColumns = columns.filter(({allowStretch}: Column) => allowStretch);
            widthIncrement = stretchableColumns.length ? freeWidth / stretchableColumns.length : 0;
        }

        return {
            columns: columns.map((col: Column) => ({
                ...col,
                width: col.allowStretch ? col.width + widthIncrement : col.width,
            })),
            rowWidth: rowWidth > width ? rowWidth : width,
            tableWidth: width,
        };
    }

    handleOrderChangeStart = (draggableRowIndex?: number) => {
        this.setState({
            draggableRowIndex,
        });
    };

    handleRowsScroll = ({scrollLeft}: any) => {
        const {offsetX} = this.state;
        if (scrollLeft !== offsetX) {
            this.setState({offsetX: scrollLeft});
        }
    };

    handleRowOrderChange = (oldIndex: number, newIndex: number) => {
        const {onRowOrderChange} = this.props;

        this.setState({
            draggableRowIndex: null,
        });

        onRowOrderChange(oldIndex, newIndex);
    };

    render() {
        const {
            handleOrderChangeStart,
            handleRowOrderChange,
            state: {
                draggableRowIndex,
            },
            props: {
                getRowId,
                getRowStyle,
                rows,
                width,
                height,
                headerHeight,
                hasHeightByContent = false,
                highlightRows,
                onRowClick,
                draggable,
                selectedRow,
                sortCriteria,
                onHeaderCellClick,
                isSortableColumns,
                hasColumnsButton,
                updateColumnsStatuses,
                configurableColumns,
                clickable,
                disabledRows,
                isScrollbarHided,
                checkIfRowHasNestedRows,
                getColumnsWithToggler,
            },
        } = this;
        const {columns, rowWidth, offsetX} = this.state;
        return (
            <div className={cn('table')}>
                {headerHeight !== 0 && (
                    <HeaderRow
                        columns={columns}
                        height={headerHeight || HEADER_HEIGHT}
                        offsetX={offsetX}
                        rowWidth={rowWidth}
                        width={width}
                        sortCriteria={sortCriteria}
                        onClick={onHeaderCellClick}
                        isSortableColumns={isSortableColumns}
                        hasColumnsButton={hasColumnsButton}
                        updateColumnsStatuses={updateColumnsStatuses}
                        configurableColumns={configurableColumns}
                    />
                )}
                <RowsContainer
                    highlightRows={highlightRows}
                    columns={columns}
                    getRowId={getRowId}
                    getRowStyle={getRowStyle}
                    height={height - HEADER_HEIGHT}
                    onRowClick={onRowClick}
                    onScroll={this.handleRowsScroll}
                    hasHeightByContent={hasHeightByContent}
                    rowHeight={ROW_HEIGHT}
                    rows={rows}
                    selectedRow={selectedRow}
                    rowWidth={rowWidth}
                    visibleRowWidth={width}
                    offsetX={offsetX}
                    draggable={draggable}
                    draggableRowIndex={draggableRowIndex}
                    onSortStart={handleOrderChangeStart}
                    onSortEnd={handleRowOrderChange}
                    isSortableColumns={isSortableColumns}
                    clickable={clickable}
                    disabledRows={disabledRows}
                    isScrollbarHided={isScrollbarHided}
                    checkIfRowHasNestedRows={checkIfRowHasNestedRows}
                    getColumnsWithToggler={getColumnsWithToggler}
                />
            </div>
        );
    }
}

export const Table = compose(withColumnsStatuses, withTableSorting)(TableComponent);
