import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {sendAuthorizationAction} from '@app/pages/login-page/components/form-login/form-login-actions';
import {
    setForgotPassword,
    setIsLkBlockedTrue,
} from '@app/store/actions';
import {isTimerLoginExpiredSelector} from '@modules/stage';
import {isLkBlockedSelector} from '@modules/navigation';
import {DispatchType} from '@utils/redux';

import {FormLogin, UserLoginData} from './form-login';

const mapStateToProps = createStructuredSelector({
    isLkBlocked: isLkBlockedSelector,
    isTimerLoginExpired: isTimerLoginExpiredSelector,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
    sendAuthorizationAction: ({
        contractId,
        login,
        password,
    }: UserLoginData) => dispatch(sendAuthorizationAction(contractId, login, password)),
    putForgotPassword: () => dispatch(setForgotPassword(true)),
    setIsLkBlockedTrue: () => dispatch(setIsLkBlockedTrue()),
});

export const FormLoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormLogin);
