import classNames from 'classnames/bind';
import * as React from 'react';

import {Timer} from '@app/pages/shared-pages/layout-page/components/header/components';
import {AppStages} from '@utils/constants';
import {SHOWN_TIMERS} from '@utils/constants/stages';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './wrapper-timer.dic.json';
import * as styles from './wrapper-timer.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface WrapperTimerInterface {
    contractId: string;
    expirationTime: number;
    secondsLeft: number;
    isLkBlocked: boolean;
    currentStage: string;
    setIsLkBlockedTrue: () => void;
    setIsTimerLoginExpiredTrue: () => void;
    loadTimerData: (contractId: string) => void;
    updateUploadedFilesStatuses?: () => void;
    flkCode?: string;
    timerType?: AppStages;
    isAmountInLimits?: boolean;
}

export const WrapperTimer = ({
    contractId,
    expirationTime,
    secondsLeft,
    currentStage,
    setIsLkBlockedTrue,
    setIsTimerLoginExpiredTrue,
    loadTimerData,
    isLkBlocked,
    updateUploadedFilesStatuses,
    flkCode,
    timerType,
    isAmountInLimits,
}: WrapperTimerInterface): JSX.Element => {
    const handleEndTimer = () => {
        if (currentStage === AppStages.AUTO_ACCEPT_TIMEOUT) {
            if ((timerType !== AppStages.AUTO_ACCEPT_TIMEOUT || secondsLeft > 0) &&
                  !isLkBlocked) { // BE error carpet sweeping
                updateUploadedFilesStatuses();
            }
        } else if (
            currentStage !== AppStages.LOGIN &&
            currentStage !== null &&
            Date.now() >= expirationTime &&
            timerType !== AppStages.AUTO_ACCEPT_TIMEOUT
        ) {
            setIsLkBlockedTrue();
        }
        if (currentStage === AppStages.LOGIN) {
            setIsTimerLoginExpiredTrue();
        }
    };

    React.useEffect(() => {
        if (contractId && !isLkBlocked) {
            loadTimerData(contractId);
        }
    }, [currentStage]);
    return (
        <>
            {
                (flkCode || timerType === AppStages.AUTO_ACCEPT_TIMEOUT) &&
                expirationTime &&
                !isLkBlocked &&
                SHOWN_TIMERS.includes(timerType) && (
                    currentStage === AppStages.LOGIN ||
                    currentStage === AppStages.APPLICATION_DIRECTION ||
                    currentStage === AppStages.CHANGE_APPLICATION_DATA ||
                    currentStage === AppStages.SCAN_ATTACHMENT ||
                    currentStage === AppStages.AUTO_ACCEPT_TIMEOUT ||
                    currentStage === AppStages.AUTO_ACCEPT_TIMEOUT__REJECTED ||
                    (currentStage === AppStages.PAYMENT && isAmountInLimits)
                ) &&
                (
                    <div className={cn('timer-wrapper')}>
                        <Timer
                            title={t('header-text-timer')}
                            expirationTime={expirationTime}
                            handleEndTimer={handleEndTimer}
                        />
                    </div>
                )
            }
        </>

    );
};
