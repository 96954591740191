import * as React from 'react';

import {TextInput} from '@ui-kit';
import {noop} from '@utils/functions';

import {TogglerCommonProps} from '../../select-types';
import {Icon, IconType} from '../icon';

interface TogglerProps extends TogglerCommonProps {
    label?: string,
    isValid: boolean,
    errorMessage?: string
    required: boolean
    allowKeyboardClean?: boolean;
}

export function InputSelectToggler({
    isDisabled,
    label,
    isValid,
    isFilterable,
    onFilterChange,
    allowKeyboardClean,
    onFocus,
    errorMessage,
    required,
    onClick,
    onBlur,
    onKeyDown,
    isOpen,
    value,
}: TogglerProps) {
    const [inputValue, setInputValue] = React.useState<string>(value);

    const handleChange = React.useCallback((value: string) => {
        if (isFilterable && value !== inputValue) {
            onFilterChange(value);
        }
        if (isFilterable && !allowKeyboardClean) {
            setInputValue(value);
        }
    }, [isFilterable, onFilterChange]);

    const handleFocus = React.useCallback(() => {
        onFocus();
        if (isFilterable) {
            handleChange('');
        }
    }, [onFocus, isFilterable]);

    const handleBlur = React.useCallback(() => {
        onBlur();
        if (isFilterable) {
            setInputValue(value);
        }
    }, [onBlur, isFilterable, value]);

    React.useEffect(() => {
        setInputValue(value);
    }, [value]);

    const textInput = React.useRef(null);
    const handleIconClick = () => {
        if (!isOpen) {
            textInput.current.inputRef.focus();
            onClick();
        }
    };

    return (
        <TextInput
            ref={textInput}
            onChange={handleChange}
            disabled={isDisabled}
            label={label}
            placeholder={value}
            value={inputValue}
            onClick={onClick}
            onBlur={handleBlur}
            onKeyDown={onKeyDown}
            onFocus={handleFocus}
            required={required}
            trailingIcon={(
                <div
                    role="button"
                    onClick={isDisabled ? noop : handleIconClick}
                >
                    <Icon
                        isOpen={isOpen}
                        iconType={IconType.INPUT_ICON}
                    />
                </div>
            )}
            isValid={isValid}
            errorMessage={errorMessage}
        />
    );
}
