import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './error-messages.scss';

const cn = classNames.bind(styles);

interface ErrorMessagesProps {
    messages: string[],
    forwardedRef: (ref: HTMLElement) => void
}

export function ErrorMessages({messages, forwardedRef}: ErrorMessagesProps) {
    const uniqueMessages = new Set(messages);

    return (
        <div
            ref={forwardedRef}
            className={cn('error-messages')}
        >
            {
                Array.from(uniqueMessages).map((message: string) => (
                    <p
                        key={message}
                        className={cn('error-messages__item')}
                    >
                        {message}
                    </p>
                ))
            }
        </div>
    );
}
