import {debounce} from 'lodash-es';
import * as React from 'react';
import {Redirect} from 'react-router';
import {Route, Switch} from 'react-router-dom';

import {interval} from '@utils/interval';
import {
    NavigationState,
    NavigationStep,
    UserAuthStatus,
} from '@app/types';
import {
    CONTRACT_PAGE,
    FAIL_PAGE,
    LOGIN_PAGE,
    RESULT_PAGE,
    STEP_1,
    STEP_2,
    STEP_3,
    STEP_4,
    NO_MATCH,
} from '@app/urls';
import {ContractPage} from '@app/pages/contract-page';
import {PaymentResultPage} from '@app/pages/payment-result-page';
import {FailPaymentPage} from '@app/pages/fail-payment-page';
import {NoMatchPage} from '@app/pages/no-match-page';
import {MatchParams} from '@utils/match-params';
import {URL_BEFORE_404, EventName, RequestIntervals} from '@utils/constants';

export interface AuthenticatedAreaProps {
    authenticated: UserAuthStatus;
    authInitialized: boolean;
    contractId: string;
    initializeAuth: (contractId: string) => void;
    deleteSession: () => void;
    sendHeartbeat: () => void;
    updateNavigationState: (contractId: string) => void;
    navigationResult: NavigationState;
    match: MatchParams;
    skDomain: string;
    isNotFound: boolean;
}

export class AuthenticatedArea extends React.Component<AuthenticatedAreaProps> {
    interval: {clear: Function};

    componentDidMount(): void {
        window.addEventListener(EventName.BEFOREUNLOAD, this.onUnload);
        const {
            initializeAuth,
            sendHeartbeat,
            authInitialized,
            match,
            updateNavigationState,
            navigationResult,
        } = this.props;
        const {
            contractId,
        } = match.params;
        if (!authInitialized) {
            initializeAuth(match.params.contractId);
        }
        this.interval = interval(
            debounce(sendHeartbeat, RequestIntervals.DEBOUNCE_INTERVAL),
            RequestIntervals.HEARTBEAT_INTERVAL
        );
        if (!navigationResult) {
            updateNavigationState(contractId);
        }
    }

    componentWillUnmount() {
        this.interval.clear();
        window.removeEventListener(EventName.BEFOREUNLOAD, this.onUnload);
    }

    onUnload = (e: Event) => {
        e.preventDefault();
        const {deleteSession} = this.props;
        deleteSession();
    };

    render() {
        const {
            authenticated,
            authInitialized,
            isNotFound,
            navigationResult,
            match: {
                url,
                params: {
                    contractId,
                    step,
                },
            },
            skDomain,
        } = this.props;

        let redirectUrl;

        if (isNotFound) {
            sessionStorage.setItem(URL_BEFORE_404, url);
            return <Redirect to={`/${NO_MATCH}`} />;
        }

        if (navigationResult) {
            const {step, paymentUrl} = navigationResult;
            const path = `/${url.split('/')[2]}`;
            switch (step) {
                case NavigationStep.ALFA:
                    if (paymentUrl && !paymentUrl.includes(path)) {
                        window.location.assign(paymentUrl);
                    } else {
                        redirectUrl = url;
                    }
                    break;
                case NavigationStep.STEP_1:
                    redirectUrl = `/${skDomain}/${CONTRACT_PAGE}/${contractId}/${STEP_1}`;
                    break;
                case NavigationStep.STEP_2:
                    redirectUrl = `/${skDomain}/${CONTRACT_PAGE}/${contractId}/${STEP_2}`;
                    break;
                case NavigationStep.STEP_3:
                    redirectUrl = `/${skDomain}/${CONTRACT_PAGE}/${contractId}/${STEP_3}`;
                    break;
                case NavigationStep.STEP_4:
                    redirectUrl = `/${skDomain}/${CONTRACT_PAGE}/${contractId}/${STEP_4}`;
                    break;
                case NavigationStep.CONCLUDE_PAYMENT:
                    redirectUrl = `/${skDomain}/${RESULT_PAGE}/${contractId}`;
                    break;
                case NavigationStep.FAIL_PAYMENT:
                    redirectUrl = `/${skDomain}/${FAIL_PAGE}/${contractId}`;
                    break;
                default:
                    break;
            }
        }

        const redirectStep = redirectUrl && redirectUrl.split('/')[4];
        const redirectRoute = redirectUrl && redirectUrl.split('/')[2];

        const isUrlIncludesRedirect = redirectStep ? url.includes(redirectStep) : url.includes(redirectRoute);

        if (!!authenticated && isUrlIncludesRedirect && (step || !url.includes(CONTRACT_PAGE))) {
            return (
                <Switch>
                    <Route
                        path={`/:skDomain?/${CONTRACT_PAGE}/:contractId?/:step?`}
                        component={ContractPage}
                    />
                    <Route
                        path={`/:skDomain?/${RESULT_PAGE}/:contractId?`}
                        component={PaymentResultPage}
                    />
                    <Route
                        path={`/:skDomain?/${FAIL_PAGE}/:contractId?`}
                        component={FailPaymentPage}
                    />
                    <Route component={NoMatchPage} />
                </Switch>
            );
        }

        if (!authenticated || (
            navigationResult &&
            !redirectUrl &&
            navigationResult.step === 'ALPHA'
        )) {
            return <Redirect to={`/${skDomain}/${LOGIN_PAGE}/${contractId}`} />;
        }

        if (!authInitialized || !navigationResult) {
            return null;
        }

        return <Redirect to={redirectUrl} />;
    }
}
