import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from '../table-layout.scss';

const cn = classNames.bind(styles);

interface ButtonsProps {
    showLastButton?: boolean
    children: React.ReactNode | React.ReactNode[]
}

export function Buttons({children}: ButtonsProps) {
    const getKey = (index: number) => `button-${index}`;

    return (
        <div className={cn('table-layout__buttons')}>
            {React.Children.toArray(children).map((button, index) => (
                <div
                    className={cn('table-layout__button')}
                    key={getKey(index)}
                >
                    {button}
                </div>
            ))}
        </div>
    );
}
