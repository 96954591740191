import MaterialTextField, {Input, HelperText} from '@material/react-text-field';
import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import {Counter} from './counter';

import * as styles from './text-input.scss';

export enum TextInputType {
    TEXT = 'text',
    PASSWORD = 'password'
}

export interface TextInputProps {
    disabled?: boolean,
    errorMessage?: string,
    id?: string,
    isValid?: boolean,
    label?: string,
    leadingIcon?: JSX.Element,
    minLength?: number,
    maxLength?: number,
    name?: string,
    onBlur?: () => void,
    onChange?: (value: string) => void,
    onClick?: () => void,
    onKeyDown?: (key: number) => void,
    onFocus?: () => void;
    required?: boolean,
    title?: string
    placeholder?: string;
    trailingIcon?: JSX.Element,
    type?: TextInputType,
    value?: string,
    width?: string | number
}

const cn = classNames.bind(styles);

export class TextInput extends React.Component<TextInputProps> {
    static defaultProps: TextInputProps = {
        disabled: false,
        errorMessage: null,
        id: null,
        isValid: true,
        label: '',
        leadingIcon: null,
        maxLength: null,
        minLength: null,
        name: '',
        onBlur: noop,
        onFocus: noop,
        onChange: noop,
        onKeyDown: noop,
        onClick: noop,
        required: false,
        title: '',
        trailingIcon: null,
        value: '',
        type: TextInputType.TEXT,
        width: '100%',
    };

    inputRef: any = null;

    handleKeyDown = ({keyCode}: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const {onKeyDown} = this.props;
        onKeyDown(keyCode);
    };

    handleChange = (e: React.FormEvent) => {
        const {onChange} = this.props;
        onChange((e.target as HTMLInputElement).value);
    };

    render() {
        const {
            props: {
                disabled,
                errorMessage,
                id,
                isValid,
                label,
                leadingIcon,
                maxLength,
                minLength,
                name,
                onBlur,
                onFocus,
                onClick,
                required,
                title,
                placeholder,
                trailingIcon,
                value,
                type,
                width,
            },
            handleKeyDown,
            handleChange,
        } = this;

        const icon = (<span className={cn('text-input__icon')}>{trailingIcon}</span>);
        const limitCounter = maxLength !== null && !disabled && (<Counter value={value} maxLength={maxLength} />);

        const actualTrailingIcon = limitCounter || icon || null;

        return (
            <MaterialTextField
                className={cn('text-input', disabled && 'text-input--disabled')}
                floatingLabelClassName={cn('text-input__label')}
                helperText={errorMessage ?
                    (
                        <HelperText
                            isValid={isValid}
                            isValidationMessage
                            persistent
                        >
                            {errorMessage}
                        </HelperText>
                    ) : null}
                label={label}
                leadingIcon={leadingIcon}
                trailingIcon={actualTrailingIcon}
                style={{width}}
            >
                <Input
                    ref={(input: any) => {
                        this.inputRef = input?.inputElement_?.current;
                    }}
                    className={cn('text-input__input')}
                    disabled={disabled}
                    id={id}
                    isValid={isValid}
                    maxLength={maxLength}
                    minLength={minLength}
                    name={name}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onClick={onClick}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    required={required}
                    title={title}
                    placeholder={placeholder}
                    spellCheck={false}
                    type={type}
                    value={value}
                    width={width}
                />
            </MaterialTextField>
        );
    }
}
