import classNames from 'classnames/bind';
import * as React from 'react';

import {RelativePortal} from '@ui-kit';
import {KeyCode} from '@utils/constants';
import {isDateValid} from '@utils/date';
import {clickOutside} from '@utils/dom';
import {noop} from '@utils/functions';

import {
    SingleDatePickerMenu,
    DateRangePickerMenu,
    DateInput,
} from './components';

import * as styles from './date-picker.scss';

const cn = classNames.bind(styles);

export interface DatePickerProps {
    rangeSelectingMode: boolean;
    selectedDate?: string;
    selectedStartDate?: string;
    selectedEndDate?: string;
    onChange?: (firstDate: string, secondDate?: string) => void;
    isDisabled?: boolean;
    errorMessage?: string;
    id?: string;
    isValid?: boolean;
    label?: string;
    name?: string;
    required?: boolean;
    width?: string | number;
    availableDates?: [Date, Date?],
    showFirstAvailableDate?: boolean,
}

interface DatePickerState {
    selectedDate: Date;
    prevSelectedDate?: string;
    selectedStartDate: Date;
    selectedEndDate: Date;
    prevSelectedStartDate?: string;
    prevSelectedEndDate?: string;
    isOpen: boolean;
    calendarHeight: number;
}

export class DatePicker extends React.PureComponent<DatePickerProps, DatePickerState> {
    // eslint-disable-next-line react/sort-comp
    static defaultProps: DatePickerProps = {
        rangeSelectingMode: false,
        onChange: noop,
        width: 'auto',
    };

    datePickerInnerRef = React.createRef<HTMLDivElement>();

    datePickerMenuRef = React.createRef<HTMLDivElement>();

    unregisterOutsideClickHandler: Function = null;

    state: DatePickerState = {
        selectedDate: null,
        selectedStartDate: null,
        selectedEndDate: null,
        isOpen: false,
        calendarHeight: 0,
    };

    static getDerivedStateFromProps({
        rangeSelectingMode,
        selectedDate,
        selectedStartDate,
        selectedEndDate,
    }: DatePickerProps, {
        prevSelectedDate,
        prevSelectedStartDate,
        prevSelectedEndDate,
    }: DatePickerState) {
        const getValidDate = (date?: Date | string) => (
            (date && isDateValid(new Date(date)) && new Date(date)) || null
        );

        if (!rangeSelectingMode && selectedDate !== prevSelectedDate) {
            return {
                selectedDate: getValidDate(selectedDate),
                prevSelectedDate: selectedDate,
            };
        }

        if (rangeSelectingMode &&
            (selectedStartDate !== prevSelectedStartDate || selectedEndDate !== prevSelectedEndDate)) {
            return {
                selectedStartDate: getValidDate(selectedStartDate),
                selectedEndDate: getValidDate(selectedEndDate),
                prevSelectedStartDate: selectedStartDate,
                prevSelectedEndDate: selectedEndDate,
            };
        }
        return null;
    }

    openCalendar = () => {
        this.setState({isOpen: true}, () => {
            this.unregisterOutsideClickHandler = clickOutside(
                this.datePickerMenuRef.current,
                this.closeCalendar,
                true,
                [this.datePickerInnerRef.current]
            );
        });
    };

    closeCalendar = () => {
        this.setState({isOpen: false}, () => {
            if (this.unregisterOutsideClickHandler) {
                this.unregisterOutsideClickHandler();
            }
        });
    };

    handleInputKeyDown = (keyCode: number) => {
        const {isOpen} = this.state;
        const {
            selectedDate,
            selectedStartDate,
            onChange,
        } = this.props;

        switch (keyCode) {
            case KeyCode.BACKSPACE:
            case KeyCode.DELETE:
            case KeyCode.ESCAPE: {
                if (selectedDate) {
                    onChange(null);
                } else if (selectedStartDate) {
                    onChange(null, null);
                } else if (isOpen) {
                    this.closeCalendar();
                }
                break;
            }
            case KeyCode.WHITESPACE: {
                if (!isOpen) {
                    this.openCalendar();
                }
                break;
            }
            default:
                break;
        }
    };

    handleDatePickerMenuKeyDown = (keyCode: number) => {
        const {isOpen} = this.state;

        if (keyCode === KeyCode.ESCAPE && isOpen) {
            this.closeCalendar();
        }
    };

    handleChangeCalendarHeight = (height: number) => {
        this.setState({calendarHeight: height});
    };

    handleSingleDatePickerMenuChange = (
        date: string,
        closeCalendar: boolean
    ) => {
        const {onChange} = this.props;

        onChange(date);

        if (closeCalendar) {
            this.closeCalendar();
        }
    };

    handleDateRangePickerMenuChange = (
        startDate: string,
        endDate: string,
        closeCalendar: boolean = false
    ) => {
        const {onChange} = this.props;

        onChange(startDate, endDate);

        if (closeCalendar) {
            this.closeCalendar();
        }
    };

    renderDatePickerMenu() {
        const {
            selectedDate,
            selectedStartDate,
            selectedEndDate,
        } = this.state;
        const {rangeSelectingMode, availableDates, showFirstAvailableDate} = this.props;

        if (!rangeSelectingMode) {
            return (
                <SingleDatePickerMenu
                    selectedDate={selectedDate}
                    onChange={this.handleSingleDatePickerMenuChange}
                    onKeyDown={this.handleDatePickerMenuKeyDown}
                    onDisplayChange={this.handleChangeCalendarHeight}
                    availableDates={availableDates}
                    showFirstAvailableDate={showFirstAvailableDate}
                />
            );
        }

        return (
            <DateRangePickerMenu
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                onChange={this.handleDateRangePickerMenuChange}
                onKeyDown={this.handleDatePickerMenuKeyDown}
                onDisplayChange={this.handleChangeCalendarHeight}
            />
        );
    }

    render() {
        const {
            selectedDate,
            selectedStartDate,
            selectedEndDate,
            isOpen,
            calendarHeight,
        } = this.state;
        const {
            width,
            isDisabled,
            label,
            isValid,
            errorMessage,
            required,
        } = this.props;

        return (
            <div
                role="button"
                tabIndex={0}
                className={cn('date-picker')}
                style={{width}}
            >
                <div ref={this.datePickerInnerRef}>
                    <DateInput
                        isDisabled={isDisabled}
                        label={label}
                        firstSelectedDate={selectedDate || selectedStartDate}
                        secondSelectedDate={selectedEndDate}
                        onClick={this.openCalendar}
                        onKeyDown={this.handleInputKeyDown}
                        required={required}
                        isValid={isValid}
                        errorMessage={errorMessage}
                        isIconActive={isOpen}
                    />

                    {isOpen && (
                        <RelativePortal
                            parentElement={this.datePickerInnerRef.current}
                            height={calendarHeight}
                        >
                            <div
                                className={cn('date-picker__menu')}
                                tabIndex={-1}
                                ref={this.datePickerMenuRef}
                            >
                                {this.renderDatePickerMenu()}
                            </div>
                        </RelativePortal>
                    )}
                </div>
            </div>
        );
    }
}
