import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {DocumentFileStatuses} from '@modules/contract';
import {createTranslator} from '@utils/i18n';

const t = createTranslator(dictionary);

interface DocumentFileStatusesNamesType {
    [key: string]: string;
}

export const DOCUMENT_FILE_STATUSES_NAMES: DocumentFileStatusesNamesType = {
    [DocumentFileStatuses.LOADING]: t('file-loader.status.in-progress'),
    [DocumentFileStatuses.SUCCESS]: t('file-loader.status.success'),
    [DocumentFileStatuses.FAIL]: t('file-loader.status.fail'),
    [DocumentFileStatuses.WRONG_FORMAT]: t('file-loader.status.fail'),
    [DocumentFileStatuses.INSURANCE_COMPANY_CHECKING]: t('insurance-company-file-loader.status.in-progress'),
    [DocumentFileStatuses.INSURANCE_COMPANY_SUCCESS]: t('insurance-company-file-loader.status.success'),
    [DocumentFileStatuses.INSURANCE_COMPANY_FAIL]: t('insurance-company-file-loader.status.fail'),
};
