import {
    ConfigsInterface,
    SystemInterface,
} from '@app/store/types';

export const webSiteRsaMock = 'https://autoins.ru/';
export const serverTimeMock = 'Tue, 18 Feb 2020 14:40:04 GMT';

export const propertiesMock: ConfigsInterface = {
    feEgarantCapthcaEnable: false,
    feEgarantCheckResultsInterval: 10,
    webSiteRSA: webSiteRsaMock,
};

export const systemStateMock: SystemInterface = {
    configs: propertiesMock,
    captcha: {
        isAgreementCaptchaVisible: false,
        isCompaniesCaptchaVisible: false,
        isRestoreCaptchaVisible: false,
        captchaPublicKey: '',
        captchaVerifyKey: '',
    },
    serverTime: null,
};
