import classNames from 'classnames/bind';
import * as React from 'react';
import {Form} from 'react-final-form';

import {
    Button,
    FormBody,
    FormLayout,
    CheckboxField,
    FormButtons,
    Column,
} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import {PopupComponentProps} from './columns-popup-types';
import {getLabel} from './columns-popup-utils';

import * as dictionary from './columns-popup.dic.json';
import * as styles from './columns-popup.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export const PopupComponent = ({
    columns,
    updateColumns,
    initialValues,
    closePanel,
}: PopupComponentProps) => {
    const handleSubmit = (columnsStatuses: object) => updateColumns(columnsStatuses);

    const handlePopupCancel = () => closePanel();

    const countSelectedColumns = (values: object) => Object
        .values(values)
        .filter(column => column)
        .length;

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            render={({handleSubmit, values}) => (
                <div className={cn('columns-popup')}>
                    <FormLayout withCustomMargins title={t('columns-popup.title')}>
                        <FormBody>
                            {columns.map((column: Column) => (
                                <CheckboxField
                                    name={column.name}
                                    label={getLabel(column.header)}
                                    value={Boolean(!column.hidden)}
                                />
                            ))}
                        </FormBody>
                        <FormButtons right>
                            {`${t('columns-popup.count')}: ${countSelectedColumns(values)}`}
                            <Button label={t('columns-popup.cancel')} onClick={handlePopupCancel} />
                            <Button label={t('columns-popup.apply')} primary onClick={handleSubmit} />
                        </FormButtons>
                    </FormLayout>
                </div>
            )}
        />
    );
};

PopupComponent.displayName = 'PopupComponent';
