import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {createTranslator} from '@app/utils/i18n';

import * as styles from '../../form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function FormFieldBlock({field, label}: {field: string, label: string}) {
    return (
        <>
            <div className={cn('form-container__text-label')}>
                {t(label)}
            </div>
            <div className={cn('form-container__text')}>
                {field}
            </div>
        </>
    );
}
