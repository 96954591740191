import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {DocumentTypes, ValidationEntities} from '@app/types';
import {
    LinkDataInterface,
    UploadedDocumentFile,
} from '@modules/contract';
import {Spinner} from '@ui-kit';
import {AppStages, Steps} from '@utils/constants';
import {convertToTimeHHMM} from '@utils/formatters/date';
import {createTranslator} from '@utils/i18n';

import * as styles from './additional-info.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface MessageInterface {
    messages: string;
    documentType: DocumentTypes;
}

export interface AdditionalInfoInterface {
    currentStep: number;
    currentStage: AppStages;
    isScanSendingInitiated: boolean;
    validationResultEntities: ValidationEntities[];
    validationResultScans: DocumentTypes[];
    expirationTime: number;
    isAmountInLimits: boolean;
    uploadedDocumentFiles: UploadedDocumentFile[];
    isDataEdited: boolean;
    isLkBlocked: boolean;
    isAllScansApproved: boolean;
    linkData: LinkDataInterface;
}

export const AdditionalInfo = ({
    currentStep,
    currentStage,
    isScanSendingInitiated,
    validationResultEntities,
    validationResultScans,
    expirationTime,
    isAmountInLimits,
    uploadedDocumentFiles,
    isDataEdited,
    isLkBlocked,
    isAllScansApproved,
    linkData,
}: AdditionalInfoInterface) => {
    interface validationTranslateType {
        [key: string]: string;
    }
    interface validationOrderType {
        [key: string]: number;
    }

    const dictionaryEntities: validationTranslateType = {
        [ValidationEntities.OWNER]: t('contract-info-error-owner'),
        [ValidationEntities.INSURER]: t('contract-info-error-insurer'),
        [ValidationEntities.VEHICLE]: t('contract-info-error-vehicle'),
        [ValidationEntities.DRIVERS]: t('contract-info-error-drivers'),
    };
    const dictionaryDocumentTypes: validationTranslateType = {
        [DocumentTypes.INSURER_DOCUMENT]: t('contract-info-error-scan-insurer-document'),
        [DocumentTypes.OWNER_DOCUMENT]: t('contract-info-error-scan-owner-document'),
        [DocumentTypes.VEHICLE_DOCUMENT]: t('contract-info-error-scan-vehicle-document'),
        [DocumentTypes.DIAGNOSTIC_CARD]: t('contract-info-error-scan-diagnostic-card'),
        [DocumentTypes.DRIVERS_DOCUMENTS]: t('contract-info-error-scan-drivers-documents'),
    };
    const dictionaryEntitiesOrder: validationOrderType = {
        [ValidationEntities.INSURER]: 0,
        [ValidationEntities.OWNER]: 1,
        [ValidationEntities.VEHICLE]: 2,
        [ValidationEntities.DRIVERS]: 3,
    };
    const dictionaryDocumentTypesOrder: validationOrderType = {
        [DocumentTypes.INSURER_DOCUMENT]: 0,
        [DocumentTypes.OWNER_DOCUMENT]: 1,
        [DocumentTypes.VEHICLE_DOCUMENT]: 2,
        [DocumentTypes.DRIVERS_DOCUMENTS]: 3,
        [DocumentTypes.DIAGNOSTIC_CARD]: 4,
    };

    const rejectDocument: Set<DocumentTypes> = new Set();
    const rejectedDocumentsMessages: MessageInterface[] = [];

    uploadedDocumentFiles
        .filter(file => file.fileOrderInGroup === 1)
        .forEach((file: UploadedDocumentFile) => {
            const {
                documentType,
                rejectReasonSum,
                rejectReasons,
            } = file;
            let messages: string;
            const messagesArray: string[] = [];

            if (rejectReasonSum) {
                rejectDocument.add(documentType);

                if (rejectReasonSum === '3') {
                    messages = rejectReasons.join(', ');
                } else {
                    messagesArray.push(t(`contract-info-reject-${rejectReasonSum}`));
                    messages = messagesArray.join(', ');
                }
            }

            rejectedDocumentsMessages.push({
                messages,
                documentType,
            });
        });

    function printScansError(rejectDocument: string[], documentMessages: MessageInterface[]) {
        rejectDocument.sort((el1, el2) => (
            dictionaryDocumentTypesOrder[el1] - dictionaryDocumentTypesOrder[el2]
        ));

        return (
            <ul className={cn('additional-info__list')}>
                {
                    rejectDocument.map((element, i) => {
                        const documents = documentMessages.filter(el => el.documentType === element);
                        const messages: string[] = [];

                        documents.forEach(el => {
                            messages.push(el.messages);
                        });

                        return (
                            <li key={i.toString()}>
                                {
                                    `${dictionaryDocumentTypes[element]}: ${messages.join(', ')};`
                                }
                            </li>
                        );
                    })
                }
            </ul>
        );
    }

    const renderInformationBlock = (message: string) => (
        <p className={cn('additional-info__text')}>
            {message}
        </p>
    );

    return (
        <div className={cn('additional-info')}>
            {
                currentStage === AppStages.CHANGE_APPLICATION_DATA &&
                    !isScanSendingInitiated &&
                    currentStep === Steps.FINAL_STEP &&
                    !isDataEdited && (
                    <>
                        {renderInformationBlock(t('contract-info-text-1-1', linkData?.insCompanyName))}
                        <ul className={cn('additional-info__list')}>
                            {[...validationResultEntities]
                                .sort((el1, el2) => (
                                    dictionaryEntitiesOrder[el1] - dictionaryEntitiesOrder[el2]
                                ))
                                .map((element: string) => (
                                    <li key={element}>
                                        {dictionaryEntities[element]}
                                    </li>
                                ))}
                        </ul>
                        {renderInformationBlock(t('contract-info-text-1-2'))}
                    </>
                )
            }
            {
                currentStage === AppStages.SCAN_ATTACHMENT &&
                    !isScanSendingInitiated &&
                    currentStep === Steps.FINAL_STEP &&
                    expirationTime && (
                    <>
                        {renderInformationBlock(t('contract-info-text-2-1'))}
                        <ul className={cn('additional-info__list')}>
                            {[...validationResultScans]
                                .sort((el1, el2) => (
                                    dictionaryDocumentTypesOrder[el1] - dictionaryDocumentTypesOrder[el2]
                                ))
                                .map((element: string) => (
                                    <li key={element}>
                                        {dictionaryDocumentTypes[element]}
                                    </li>
                                ))}
                        </ul>
                        {renderInformationBlock(t(
                            'contract-info-text-2-2',
                            convertToTimeHHMM(new Date(expirationTime)),
                            linkData?.insCompanyName
                        ))}
                    </>
                )
            }
            {
                currentStage === AppStages.AUTO_ACCEPT_TIMEOUT &&
                    renderInformationBlock(t('contract-info-text-3'))
            }
            {
                (currentStage === AppStages.AUTO_ACCEPT_TIMEOUT__REJECTED ||
                    (currentStage === AppStages.CHANGE_APPLICATION_DATA &&
                        !isDataEdited &&
                        isScanSendingInitiated
                    )) && (
                    <>
                        {renderInformationBlock(t('contract-info-text-scan-error-1'))}
                        {printScansError(Array.from(rejectDocument), rejectedDocumentsMessages)}
                        {renderInformationBlock(t('contract-info-text-scan-error-2'))}
                    </>
                )
            }
            {
                currentStage === AppStages.PAYMENT &&
                    currentStep === Steps.FINAL_STEP &&
                    isAmountInLimits &&
                    expirationTime && (
                    <>
                        {renderInformationBlock(t('contract-info-text-4', convertToTimeHHMM(new Date(expirationTime))))}
                        {renderInformationBlock(t('contract-info-text-4-1'))}
                    </>
                )
            }
            {
                currentStage === AppStages.VALIDATION_IN_PROGRESS && !isLkBlocked && (
                    <>
                        <h2 className={cn('additional-info__polling')}>
                            {isAllScansApproved ?
                                t('scan-polling-in-progress') :
                                t('validation-in-progress')}
                        </h2>
                        <div className={cn('additional-info__spinner')}>
                            <Spinner />
                        </div>
                    </>
                )
            }
            {renderInformationBlock(t('time-limits-text'))}
        </div>
    );
};
