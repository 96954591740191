import {loadScanEditingRequest} from '@app/pages/contract-page/contract-page-actions';
import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/components/navigation/navigation.dic.json';
import {countStepsToValidateScans} from '@modules/validation';
import {Button} from '@ui-kit';
import {MAX_COUNT_FIELDS_FOR_EDITING} from '@utils/constants/egarant-settings';
import {createTranslator} from '@utils/i18n';

const t = createTranslator(dictionary);

export interface ButtonBackToEditInterface {
    contractId: string;
    isScanSendingInitiated: boolean;
    isScanEditingInitiated: boolean;
    scansToEdit: string[];
    stepsToValidate: number[];
    confirmEditData: (
        contractId: string,
        howMuchEditFields: number,
        firstStepToValidate: number
    ) => void;
    loadScanEditingRequest: (contractId: string, callback: () => void) => void;
    setStepsToValidate: (stepsToValidate: number[]) => void;
    isLkBlocked: boolean;
}

export function ButtonBackToEdit({
    contractId,
    isScanSendingInitiated,
    isScanEditingInitiated,
    scansToEdit,
    stepsToValidate,
    confirmEditData,
    loadScanEditingRequest,
    setStepsToValidate,
    isLkBlocked,
}: ButtonBackToEditInterface) {
    const editForceUpdate = () => {
        confirmEditData(contractId, MAX_COUNT_FIELDS_FOR_EDITING, stepsToValidate[0]);
    };

    const editForceUpdateScanSendingInitiated = () => {
        const callback = () => {
            const stepsToEditScans = countStepsToValidateScans(scansToEdit);
            setStepsToValidate(stepsToEditScans);
            confirmEditData(contractId, MAX_COUNT_FIELDS_FOR_EDITING, stepsToEditScans[0]);
        };
        loadScanEditingRequest(contractId, callback);
    };

    return (
        <Button
            onClick={
                (isScanSendingInitiated && !isScanEditingInitiated) ?
                    editForceUpdateScanSendingInitiated :
                    editForceUpdate
            }
            disabled={isLkBlocked}
            label={t('navigation.button.back.to-edit')}
        />
    );
}
