import classNames from 'classnames/bind';
import * as React from 'react';

import {
    KeyCode,
    EventName,
} from '@utils/constants';
import {
    addMonths,
    getMonths,
    getNowDate,
} from '@utils/date';
import {noop} from '@utils/functions';

import {Month} from './month';

import * as styles from './month-view.scss';

const cn = classNames.bind(styles);
const FIRST_MONTH_INDEX = 0;
const LAST_MONTH_INDEX = 11;
const X_AXIS_STEP = 1;
const Y_AXIS_STEP = 3;

interface MonthViewProps {
    onChange: (index: number) => void;
    onFocus: (date: Date, isNeededToUpdateViewDate: boolean) => void;
    viewDate: Date;
    focusedDate: Date;
    isActive: boolean;
}

export class MonthView extends React.PureComponent<MonthViewProps> {
    static defaultProps = {
        onChange: noop,
        onFocus: noop,
        isActive: false,
    };

    componentDidMount() {
        document.addEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    handleKeyDown = ({keyCode}: KeyboardEvent) => {
        const {
            onChange,
            onFocus,
            viewDate,
            focusedDate,
            isActive,
        } = this.props;

        if (!isActive) {
            return;
        }

        switch (keyCode) {
            case KeyCode.RIGHT_ARROW: {
                if ((viewDate.getMonth() + X_AXIS_STEP) <= LAST_MONTH_INDEX) {
                    const newDate = addMonths(focusedDate, X_AXIS_STEP);

                    onFocus(newDate, true);
                }
                break;
            }
            case KeyCode.LEFT_ARROW: {
                if ((viewDate.getMonth() - X_AXIS_STEP) >= FIRST_MONTH_INDEX) {
                    const newDate = addMonths(focusedDate, -X_AXIS_STEP);

                    onFocus(newDate, true);
                }
                break;
            }
            case KeyCode.DOWN_ARROW: {
                if ((viewDate.getMonth() + Y_AXIS_STEP) <= LAST_MONTH_INDEX) {
                    const newDate = addMonths(focusedDate, Y_AXIS_STEP);

                    onFocus(newDate, true);
                }
                break;
            }
            case KeyCode.UP_ARROW: {
                if ((viewDate.getMonth() - Y_AXIS_STEP) >= FIRST_MONTH_INDEX) {
                    const newDate = addMonths(focusedDate, -Y_AXIS_STEP);

                    onFocus(newDate, true);
                }
                break;
            }
            case KeyCode.ENTER: {
                if (focusedDate) {
                    onChange(focusedDate.getMonth());
                }
                break;
            }
            default:
                break;
        }
    };

    render() {
        const {
            onChange,
            viewDate,
            focusedDate,
        } = this.props;

        return (
            <div className={cn('month-view')}>
                {
                    getMonths().map((month, index) => (
                        <Month
                            key={month}
                            month={month}
                            onClick={() => onChange(index)}
                            isCurrent={index === getNowDate().getMonth()}
                            isSelected={viewDate ? (index === viewDate.getMonth()) : false}
                            isFocused={focusedDate ? (index === focusedDate.getMonth()) : false}
                        />
                    ))
                }
            </div>
        );
    }
}
