import * as React from 'react';

import {SvgSize} from '@utils/constants/svg-size';

import * as logoPath from './logo.path';

export function LogoImage(): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SvgSize.SMALL}
            height={SvgSize.SMALL}
            viewBox={`0 0 ${SvgSize.SMALL} ${SvgSize.SMALL}`}
        >
            <defs>
                <linearGradient id="a" x1="50.938%" x2="124.888%" y1="126.064%" y2="49.174%">
                    <stop offset="0%" stopColor="#3023AE" />
                    <stop offset="47.52%" stopColor="#53A0FD" />
                    <stop offset="100%" stopColor="#B4EC51" />
                </linearGradient>
            </defs>
            <path fill="url(#a)" fillRule="evenodd" d={logoPath} />
        </svg>
    );
}
