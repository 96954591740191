import * as React from 'react';

import {EventName} from '@utils/constants';

export type Size = {
    height: number,
    width: number
};

export type Position = {
    top: number,
    left: number,
    bottom: number,
    right: number
}

export type ScrollPosition = {
    scrollTop: number,
    scrollLeft: number
};

export const EMPTY_SIZE: Size = {
    width: 0, height: 0,
};

export function getElementSize(el: HTMLElement): Size {
    if (!el) {
        return EMPTY_SIZE;
    }

    const {
        paddingLeft, paddingRight, paddingTop, paddingBottom,
    } = window.getComputedStyle(el);

    const height = el.offsetHeight || 0;
    const width = el.offsetWidth || 0;

    const indentLeft = parseInt(paddingLeft, 10) || 0;
    const indentRight = parseInt(paddingRight, 10) || 0;
    const indentTop = parseInt(paddingTop, 10) || 0;
    const indentBottom = parseInt(paddingBottom, 10) || 0;

    const actualHeight = Math.round(height) - indentTop - indentBottom;
    const actualWidth = Math.round(width) - indentLeft - indentRight;

    return {
        height: actualHeight,
        width: actualWidth,
    };
}

export function clickOutside(
    element: HTMLElement,
    callback: Function,
    useCapture: boolean = true,
    ignoreNodes?: HTMLElement[]
) {
    const handleClickOutside = (e: Event) => {
        const target = <HTMLElement>e.target;
        const isIgnoreNodes = ignoreNodes && ignoreNodes.includes(target);
        const isClickInside = element.contains(target);
        if (isClickInside || isIgnoreNodes) {
            return;
        }
        callback(target);
    };
    const removeEvent = () => {
        document.removeEventListener(EventName.CLICK, handleClickOutside, useCapture);
    };
    document.addEventListener(EventName.CLICK, handleClickOutside, useCapture);

    return removeEvent;
}

export function getDocumentScrollPosition(): ScrollPosition {
    const {body, documentElement} = document;

    const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

    return {
        scrollTop,
        scrollLeft,
    };
}

export function getElementPosition(element: HTMLElement): Position {
    const box = element.getBoundingClientRect();
    const {body, documentElement} = document;

    const {scrollTop, scrollLeft} = getDocumentScrollPosition();
    const clientTop = documentElement.clientTop || body.clientTop || 0;
    const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

    const top = Math.round(box.top + scrollTop - clientTop);
    const left = Math.round(box.left + scrollLeft - clientLeft);

    return {
        top,
        left,
        right: Math.round(left + box.width),
        bottom: Math.round(top + box.height),
    };
}

export function stopPropagationBeforeCall(fn: () => void) {
    return (e: React.MouseEvent): void => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        fn();
    };
}
