import {addDays, addMonths} from '@utils/date';
import {SelectOption} from '@utils/formatters/select-option';

export interface Period {
    interval: SelectOption<number>;
    startDate: Date;
    endDate: Date;
}

export enum MONTHS {
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
    NINE = 9,
    TEN = 10,
    ELEVEN = 11,
    TWELVE = 12
}

export const calculateStartOfNextPeriod = (prevEndDate: Date): Date => {
    const PERIOD_GAP = 2;

    return addDays(prevEndDate, PERIOD_GAP);
};

export const calculateEndOfPeriod = (interval: number, date: Date, isFollowRegistration: boolean): Date => {
    const REGISTRATION_DAYS = 19;

    if (isFollowRegistration) {
        return addDays(date, REGISTRATION_DAYS);
    }

    const endOfPeriod = addMonths(date, interval);
    return endOfPeriod.getDate() === date.getDate() ? addDays(endOfPeriod, -1) : endOfPeriod;
};

export const calculateAvailableStartDate = (
    interval: SelectOption<number>,
    insuranceStartDate: Date,
    isFollowRegistration: boolean
): Date => {
    const date = calculateEndOfPeriod(
        MONTHS.TWELVE - interval.value,
        insuranceStartDate,
        isFollowRegistration
    );

    return addDays(date, 1);
};
