import * as React from 'react';

import {anchorLinkFormatter} from '@utils/formatters/url-formatter';

interface AnchorInterface {
    link: string;
    label: string;
    target?: string;
}

export const Anchor = ({
    link,
    label,
    target = '_self',
}: AnchorInterface) => (
    <a
        href={anchorLinkFormatter(link)}
        target={target}
        rel={target === ('_blank' || 'blank') ? 'noopener noreferrer' : null}
    >
        {label}
    </a>
);
