import classNames from 'classnames/bind';
import * as React from 'react';

import {formatPhoneNumber} from '@utils/formatters';

import {SignOut} from '../sign-out';

import * as styles from './user-info.scss';

const cn = classNames.bind(styles);

export function UserInfo({phoneNumber}: {phoneNumber: string}) {
    return (
        <div className={cn('user-info')}>
            <span className={cn('user-info__name')}>
                {formatPhoneNumber(phoneNumber)}
            </span>
            <SignOut />
        </div>
    );
}
