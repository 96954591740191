import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {sendStep} from '@app/pages/contract-page/contract-page-actions';
import {
    dictionariesSelector,
    ownerTypeSelector,
    vehicleSelector,
    contractIdSelector,
} from '@modules/contract';
import {overlayVisibilitySelector} from '@modules/overlay';
import {
    mappedValidationKitStep2Selector,
    stepsToValidateSelector,
} from '@modules/validation';
import {
    currentStepSelector,
    stepAfterSubmitSelector,
} from '@modules/navigation';

import {Step2} from './step2';

const mapStateToProps = createStructuredSelector({
    dictionaries: dictionariesSelector,
    contractId: contractIdSelector,
    isLoadingContract: overlayVisibilitySelector,
    vehicle: vehicleSelector,
    warningData: mappedValidationKitStep2Selector,
    currentStep: currentStepSelector,
    stepAfterSubmit: stepAfterSubmitSelector,
    stepsToValidate: stepsToValidateSelector,
    ownerType: ownerTypeSelector,
});

const mapDispatchToProps = {
    sendStep,
};

export const Step2Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2);
