import classNames from 'classnames/bind';
import * as React from 'react';
import {positionValues} from 'react-custom-scrollbars';

import {RelativePortal, Scrollbars, Spinner} from '@ui-kit';
import {findIndex} from '@utils/functions';

import {OPTION_HEIGHT} from '../../constants';
import {SelectListProps} from '../../select-types';
import {Options, OptionsType} from '../options';

import * as styles from './autocomplete-select-list.scss';

const cn = classNames.bind(styles);

export interface InputSelectListProps extends SelectListProps {
    countVisibleOption?: number;
    onScroll?: (values: positionValues) => void;
    isLoading: boolean;
}

export function AutocompleteSelectList(props: InputSelectListProps) {
    const {
        emptyOptionMessage,
        options,
        countVisibleOption,
        getValue,
        getLabel,
        selectedOption,
        focusedOption,
        onClick,
        selectRef,
        isLoading,
        onScroll,
    } = props;

    let visibleOptions;
    if (!options.length) {
        visibleOptions = 1;
    } else if (options.length < countVisibleOption) {
        visibleOptions = options.length;
    } else {
        visibleOptions = countVisibleOption;
    }
    if (isLoading && options.length) {
        visibleOptions += 1;
    }

    return (
        <RelativePortal
            parentElement={selectRef.current}
            className={cn('autocomplete-select-list')}
            width={selectRef.current.getBoundingClientRect().width}
        >
            <Scrollbars
                maxHeight={OPTION_HEIGHT * visibleOptions}
                scrollTo={OPTION_HEIGHT * findIndex(options, focusedOption)}
                onScroll={onScroll}
            >
                <Options
                    getValue={getValue}
                    getLabel={getLabel}
                    emptyOptionMessage={emptyOptionMessage}
                    selectedOption={selectedOption}
                    focusedOption={focusedOption}
                    onClick={onClick}
                    options={options}
                    optionsType={OptionsType.INPUT_OPTIONS}
                    isLoading={isLoading}
                    showIcon
                />
                {isLoading && (
                    <div className={cn('spinner')}>
                        <Spinner size={OPTION_HEIGHT} />
                    </div>
                )}
            </Scrollbars>
        </RelativePortal>
    );
}
