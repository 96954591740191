import * as React from 'react';

import {Field} from '../field';
import {
    CheckboxWithFieldAdapter,
    CheckboxWithFieldAdapterProps as CheckboxFieldProps,
} from './checkbox-with-field-adapter';

export function CheckboxField(props: CheckboxFieldProps) {
    const {name, ...restProps} = props;

    return (
        <Field
            {...restProps}
            name={name}
            component={CheckboxWithFieldAdapter}
        />
    );
}
