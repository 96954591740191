import formatStringByPattern from 'format-string-by-pattern';

const phoneNumberMask = '+7 (XXX) XXX-XX-XX';

const replacer = (match: string, firstDigit: string, restDigits: string) => {
    if (['7', '8'].includes(firstDigit)) {
        return `7${restDigits}`;
    }
    return `7${firstDigit}${restDigits}`;
};

export const formatPhoneNumber = (value?: string) => {
    if (value) {
        const onlyNumbers = value.replace(/[^\d]/g, '');
        const firstNumberToSeven = onlyNumbers.replace(/^(\d)(.*)/, replacer);
        return formatStringByPattern(phoneNumberMask, firstNumberToSeven);
    }
    return value;
};

export const clearPhoneNumber = (phone: string) => (
    phone ? phone.replace(/\(|\)|\s|-|\+/g, '') : null
);
