import * as React from 'react';
import {Field as FinalField} from 'react-final-form';

import {requiredFieldValidator} from '@utils/validators';
import {compact} from '@utils/functions';

interface FieldProps {
    component: any;
    disabled?: boolean;
    label: string;
    name: string;
    required?: boolean;
    allowNull?: boolean;
    validators?: Function[];
}

export class Field extends React.PureComponent<FieldProps> {
    handleValidation = (value: any): string[]|undefined => {
        const {
            validators,
            required,
            disabled,
            label,
        } = this.props;

        let composedErrors = [];

        if (validators) {
            composedErrors = validators.map(validator => validator(value));
        }

        if (required) {
            composedErrors = [...composedErrors, requiredFieldValidator(value, label)];
        }
        if (disabled) {
            composedErrors = [];
        }

        composedErrors = compact(composedErrors);

        return composedErrors.length ? composedErrors : undefined;
    };

    render() {
        const {
            name,
            allowNull,
            component,
            ...restProps
        } = this.props;

        return (
            <FinalField
                {...restProps}
                key={`${restProps.disabled}-${restProps.required}-${restProps.label}-${name}`}
                name={name}
                validate={this.handleValidation}
                allowNull={allowNull}
                component={component}
            />
        );
    }
}
