import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {
    LegalOwnerFormInterface,
    Step4FormInterface,
} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {createTranslator} from '@app/utils/i18n';

import {FormField} from '../form-field';
import {FormFieldBlock} from '../form-field-block';

import * as styles from '../../form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function LegalOwner({items}: {items: Step4FormInterface}) {
    const fields = new Map<keyof LegalOwnerFormInterface, string>();
    fields.set('legalOwnerTypeDisplayName', 'form-container-legal-owner-type-label');
    fields.set('legalOwnerINN', 'form-container-legal-owner-INN');

    return (
        <div className={cn('form-container__form-container')}>
            <h3 className={cn('form-container__sub-header')}>
                {t('form-container-legal-owner-header')}
            </h3>

            <div className={cn('form-container__form')}>
                {
                    Array.from(fields.entries()).map(([name, label]: [keyof LegalOwnerFormInterface, string]) => (
                        <FormField
                            key={name}
                            name={name}
                            label={t(label)}
                        />
                    ))
                }
                <FormFieldBlock
                    field={items.legalOwnerFullName}
                    label="form-container-legal-owner-fullName"
                />
                <FormFieldBlock
                    field={items.legalOwnerDocType}
                    label="form-container-legal-owner-documentType-label"
                />
                {items.legalOwnerAccountingDate ? (
                    <FormField
                        key="legalOwnerAccountingDate"
                        name="legalOwnerAccountingDate"
                        label={t('document-accounting-date')}
                    />
                ) : null}
            </div>
        </div>
    );
}
