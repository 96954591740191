import * as React from 'react';

import {Step2FormInterface} from '@app/pages/contract-page/pages/step-2/step2-types';
import {
    objectsToSelectOptions,
    SelectOption,
} from '@utils/formatters/select-option';
import {
    getMakers,
    getModels,
} from '@modules/contract/contract-api';
import {shouldBeDisabled} from '@utils/should-be-disabled';
import {
    TYPE_CATEGORY,
    OTHER_MAKER,
    OTHER_MODEL,
    MAKER,
    MODEL,
    NAME,
} from '@utils/constants';
import {
    MakerDto,
    ModelDto,
} from '@app/types';
import {
    AutocompleteSelectField,
    TextField,
} from '@ui-kit';

import {
    OTHER_MAKER_LABEL,
    OTHER_MODEL_LABEL,
} from '../../../../utils';

export interface MakerModelFieldsInterface {
    otherMakerFieldLabel: string;
    otherModelFieldLabel: string;
    scansEditFields: string[];
    makerFieldLabel: string;
    modelFieldLabel: string;
    leftDescription: string;
    isLkBlocked: boolean;
    values: Step2FormInterface;
    mutator: (field: string, value: string) => void;
    cn: (fn: string) => string;
}

export const MakerModelFields = ({
    otherMakerFieldLabel,
    otherModelFieldLabel,
    scansEditFields,
    makerFieldLabel,
    modelFieldLabel,
    leftDescription,
    isLkBlocked,
    values,
    mutator,
    cn,
}: MakerModelFieldsInterface) => {
    const [isMakerRequired, changeIsMakerRequired] = React.useState(false);
    const [isModelRequired, changeIsModelRequired] = React.useState(false);

    React.useEffect(() => {
        if (!values?.maker?.value?.makerKey) {
            changeIsMakerRequired(true);
        }

        if (!values?.model?.value?.modelKey) {
            changeIsModelRequired(true);
        }
    }, []);

    const handleMakerSelectChange = (option: SelectOption<MakerDto>) => {
        mutator(MODEL, null);
        mutator(TYPE_CATEGORY, null);
        changeIsModelRequired(false);

        if (option?.value?.makerKey) {
            changeIsMakerRequired(false);
        } else {
            changeIsMakerRequired(true);
        }
    };

    const handleModelSelectChange = (option: SelectOption<ModelDto>) => {
        mutator(TYPE_CATEGORY, null);

        if (option?.value?.modelKey) {
            changeIsModelRequired(false);
        } else {
            changeIsModelRequired(true);
        }
    };

    let downloadedModelsCount = 0;
    let downloadedMakersCount = 0;

    return (
        <>
            <div className={cn('left-part__form-field-weight')}>
                <AutocompleteSelectField
                    name={MAKER}
                    label={makerFieldLabel}
                    options={[]}
                    loadOptions={async (value: string, pageIndex: number, pageSize: number) => {
                        const result = await getMakers(value, pageIndex, pageSize);

                        downloadedMakersCount += (result.objects.length);

                        if (downloadedMakersCount >= result.totalCount) {
                            result.objects.push({name: OTHER_MAKER_LABEL, makerKey: null});
                        }

                        return new Promise(resolve => resolve({
                            options: objectsToSelectOptions<MakerDto>(result.objects, NAME),
                            hasMoreData: result.objects.length === pageSize,
                        }));
                    }}
                    onChange={handleMakerSelectChange}
                    disabled={shouldBeDisabled({
                        name: MAKER,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required
                />
            </div>
            <div className={cn('left-part__form-field-weight')}>
                <TextField
                    name={OTHER_MAKER}
                    label={otherMakerFieldLabel}
                    disabled={shouldBeDisabled({
                        name: MAKER,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required={isMakerRequired}
                />
                <span className={cn('left-part__description')}>{leftDescription}</span>
            </div>
            <div className={cn('left-part__form-field-weight')}>
                <AutocompleteSelectField
                    name={MODEL}
                    label={modelFieldLabel}
                    options={[]}
                    loadOptions={async (value: string, pageIndex: number, pageSize: number) => {
                        const result = await getModels(
                            values?.maker?.value?.makerKey,
                            value,
                            pageIndex,
                            pageSize
                        );

                        downloadedModelsCount += (result.objects.length);

                        if (downloadedModelsCount >= result.totalCount) {
                            result.objects.push({name: OTHER_MODEL_LABEL, modelKey: null});
                        }

                        return new Promise(resolve => resolve({
                            options: objectsToSelectOptions<ModelDto>(result.objects, NAME),
                            hasMoreData: result.objects.length === pageSize,
                        }));
                    }}
                    onChange={handleModelSelectChange}
                    disabled={shouldBeDisabled({
                        name: MODEL,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required
                />
            </div>
            <div className={cn('left-part__form-field-weight')}>
                <TextField
                    name={OTHER_MODEL}
                    label={otherModelFieldLabel}
                    disabled={shouldBeDisabled({
                        name: MODEL,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required={isModelRequired}
                />
                <span className={cn('left-part__description')}>{leftDescription}</span>
            </div>
        </>
    );
};
