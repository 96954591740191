import classNames from 'classnames/bind';
import * as React from 'react';

import {RadioButton} from '@app/components';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {Step1FormInterface} from '@app/pages/contract-page/pages/step-1/step1-types';
import {Owner as ContractOwnerInterface, OwnerLawTypes, PersonType} from '@app/types';
import {createTranslator} from '@app/utils/i18n';

import {AddressData} from '../address-data';
import {DocumentData} from '../document-data';
import {LegalDocumentData} from '../legal-document-data';
import {PersonData} from '../person-data';

import * as styles from './owner.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface OwnerInterface {
    setOwnerType(value: string): void;
    mutator: (field: string, value: string) => void;
    owner: ContractOwnerInterface;
    values: Step1FormInterface;
}

export function Owner({
    setOwnerType,
    mutator,
    owner,
    values,
}: OwnerInterface) {
    const isOwnerPhysical = owner?.ownerType === OwnerLawTypes.PHYSICAL;
    const isOwnerLegal = owner?.ownerType === OwnerLawTypes.LEGAL;
    return (
        <div className={cn('owner')}>
            <h2>{t('owner')}</h2>
            <div className={cn('owner__type')}>
                <RadioButton
                    id="physical-person"
                    value="physical"
                    name="physical"
                    label={t('owner-physical-person')}
                    checked={isOwnerPhysical}
                    onChange={() => setOwnerType(OwnerLawTypes.PHYSICAL)}
                    disabled
                />
                <RadioButton
                    id="legal-person"
                    value="legal"
                    name="legal"
                    label={t('owner-legal-person')}
                    checked={isOwnerLegal}
                    onChange={() => setOwnerType(OwnerLawTypes.LEGAL)}
                    disabled
                />
            </div>
            <div className={cn('owner__personal')}>
                {
                    isOwnerPhysical ?
                        (
                            <PersonData
                                personType={PersonType.OWNER}
                                person={owner.person}
                                mutator={mutator}
                            />
                        ) : <LegalDocumentData />
                }
            </div>
            <DocumentData
                isOwnerLegal={isOwnerLegal}
                personType={PersonType.OWNER}
                legalDocCode={owner?.legalPersonDocument?.documentType}
            />
            <AddressData
                isOwnerLegal={isOwnerLegal}
                personType={PersonType.OWNER}
                mutator={mutator}
                values={values}
            />
        </div>
    );
}
