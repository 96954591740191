import * as React from 'react';

import * as dictionaryFlk from '@app/pages/shared-pages/layout-page/flk.dic.json';
import * as dictionaryLayout from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import {
    setIsCheckDataBtnHidden,
    hideModal,
} from '@app/store/actions';
import {STAGE} from '@app/store/model-constants';
import {TimerInterface} from '@app/store/types';
import {ValidationData} from '@app/types';
import {AppStages} from '@app/utils/constants';
import {confirm} from '@modules/common-modals';
import {MODAL_WIDTH} from '@utils/constants/modal-width';
import {createTranslator} from '@utils/i18n';
import {createActionFactory, DispatchType} from '@utils/redux';
import {ActionTypes} from '@utils/types';
import {DisplayTextWithUrl} from '@ui-kit';

const l = createTranslator(dictionaryLayout);
const f = createTranslator(dictionaryFlk);

const stageActionCreator = createActionFactory(STAGE);

export const setCurrentStage = stageActionCreator<string>(ActionTypes.SET_CURRENT_STAGE);
export const setIsTimerLoginExpiredTrue = stageActionCreator(ActionTypes.SET_IS_TIMER_LOGIN_EXPIRED_TRUE);
export const setAutoAcceptHeartbeatStopped = stageActionCreator<Boolean>(ActionTypes.SET_AUTO_ACCEPT_HEARTBEAT_STOPPED);
export const setTimer = stageActionCreator<TimerInterface>(ActionTypes.SET_TIMER);

export const updateContractStage = (validationSnapshot: ValidationData): DispatchType => (
    (dispatch: DispatchType) => {
        const {
            isScanSendingInitiated,
            isValidationInitiated,
            isValidationInProgress,
            validationResultEntities,
            validationResultScans,
            isScanEditingInitiated,
        } = validationSnapshot;

        if (isValidationInProgress) {
            dispatch(setIsCheckDataBtnHidden(true));
            return dispatch(setCurrentStage(AppStages.VALIDATION_IN_PROGRESS));
        }
        if (!isValidationInitiated) {
            return dispatch(setCurrentStage(AppStages.APPLICATION_DIRECTION));
        }
        if ((validationResultEntities && validationResultEntities.length > 0) || isScanEditingInitiated) {
            return dispatch(setCurrentStage(AppStages.CHANGE_APPLICATION_DATA));
        }
        if (isScanSendingInitiated) {
            return dispatch(setCurrentStage(AppStages.AUTO_ACCEPT_TIMEOUT));
        }
        if (validationResultScans && validationResultScans.length > 0) {
            return dispatch(setCurrentStage(AppStages.SCAN_ATTACHMENT));
        }
        return dispatch(setCurrentStage(''));
    }
);

export function confirmExpiredTimer(flkCode: string): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(hideModal());
        dispatch(confirm({
            title: l('confirm-modal-title'),
            message: <DisplayTextWithUrl text={f(flkCode)} />,
            width: MODAL_WIDTH,
            resultHandler: result => {
            },
            hasCancelOption: false,
        }));
    };
}
