import {setLinkData} from '@app/store/actions/companies-actions';
import {LinkDataInterface} from '@modules/contract';
import {getFirstPageOpened} from '@modules/contract/contract-api';
import {executeAsyncAction} from '@modules/model/actions';
import {setAgreement} from '@modules/user';
import {captchaVerifyKeySelector} from '@modules/system';
import {DispatchType} from '@utils/redux';

export const confirmAgreement = (
    contractId: string,
    linkData: LinkDataInterface
) => (dispatch: DispatchType, getState: Function) => {
    const state = getState();
    const captchaVerifyKey = captchaVerifyKeySelector(state);

    executeAsyncAction(dispatch, () => setAgreement(contractId, true, captchaVerifyKey)
        .then(() => dispatch(setLinkData({
            ...linkData,
            hasAgreement: true,
        }))));
};

export function loadFirstPageOpened(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        getFirstPageOpened(contractId);
    };
}
