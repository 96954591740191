import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {createTranslator} from '@app/utils/i18n';

import {
    InsurerFormInterface,
    Step4FormInterface,
} from '../../form-container-types';
import {SnilsFieldBlock} from '../snils-field-block';
import {FormFieldBlock} from '../form-field-block';
import {FormField} from '../form-field';

import * as styles from '../../form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function Insurer({items}: {items: Step4FormInterface}) {
    const fields = new Map<keyof InsurerFormInterface, string>();
    fields.set('insurerFullName', 'form-container-policyholder-full-name-label');
    fields.set('insurerBirthdate', 'form-container-policyholder-birthdate-label');

    return (
        <div className={cn('form-container__form-container')}>
            <h3 className={cn('form-container__sub-header')}>
                {t('form-container-policyholder-header')}
            </h3>

            <div className={cn('form-container__form')}>
                {
                    Array.from(fields.entries()).map(([name, label]: [keyof InsurerFormInterface, string]) => (
                        <FormField
                            key={name}
                            name={name}
                            label={t(label)}
                        />
                    ))
                }
                <FormFieldBlock
                    field={items.insurerDocType}
                    label="form-container-document"
                />
                <SnilsFieldBlock snils={items.insurerSnils} />
                <FormFieldBlock
                    field={items.insurerFullAddress}
                    label="form-container-full-address"
                />
                <FormFieldBlock
                    field={items.insurerPhoneNumber}
                    label="form-container-phone-number"
                />
            </div>
        </div>
    );
}
