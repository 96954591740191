import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';
import {createTranslator} from '@utils/i18n';

import * as dictionary from '../calendar.dic.json';
import * as styles from './current-date-button.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface CurrentDateButtonProps {
    label?: string;
    onClick: () => void;
}

export const CurrentDateButton = ({
    label,
    onClick,
}: CurrentDateButtonProps) => (
    <span
        className={cn('current-date-button')}
        role="button"
        onKeyDown={() => {}}
        onClick={onClick}
    >
        {label}
    </span>
);

CurrentDateButton.defaultProps = {
    label: t('current-date-button.label'),
    onClick: noop,
};
