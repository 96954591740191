import {createSelector, OutputSelector, Selector} from 'reselect';

import {SYSTEM} from '@app/store/model-constants';
import {
    AppStateInterface,
    SystemInterface,
} from '@app/store/types';

const rootSelector: Selector<any, SystemInterface> = (state: AppStateInterface) => state[SYSTEM];

export const feEgarantCapthcaEnableSelector: OutputSelector<
    SystemInterface,
    boolean,
    (state: SystemInterface) => boolean
    > = createSelector(
        rootSelector,
        ({configs}: SystemInterface) => configs.feEgarantCapthcaEnable
    );

export const feEgarantCheckResultsIntervalSelector: OutputSelector<
    SystemInterface,
    number,
    (state: SystemInterface) => number
    > = createSelector(
        rootSelector,
        ({configs}: SystemInterface) => configs.feEgarantCheckResultsInterval
    );

export const webSiteRSASelector: OutputSelector<
    SystemInterface,
    string,
    (state: SystemInterface) => string
    > = createSelector(
        rootSelector,
        ({configs}: SystemInterface) => configs.webSiteRSA
    );

export const isAgreementCaptchaVisibleSelector: OutputSelector<
    SystemInterface,
    boolean,
    (state: SystemInterface) => boolean
    > = createSelector(
        rootSelector,
        ({captcha}: SystemInterface) => captcha.isAgreementCaptchaVisible
    );

export const isCompaniesCaptchaVisibleSelector: OutputSelector<
    SystemInterface,
    boolean,
    (state: SystemInterface) => boolean
    > = createSelector(
        rootSelector,
        ({captcha}: SystemInterface) => captcha.isCompaniesCaptchaVisible
    );

export const isRestoreCaptchaVisibleSelector: OutputSelector<
    SystemInterface,
    boolean,
    (state: SystemInterface) => boolean
    > = createSelector(
        rootSelector,
        ({captcha}: SystemInterface) => captcha.isRestoreCaptchaVisible
    );

export const captchaPublicKeySelector: OutputSelector<
    SystemInterface,
    string,
    (state: SystemInterface) => string
    > = createSelector(
        rootSelector,
        ({captcha}: SystemInterface) => captcha.captchaPublicKey
    );

export const captchaVerifyKeySelector: OutputSelector<
    SystemInterface,
    string,
    (state: SystemInterface) => string
    > = createSelector(
        rootSelector,
        ({captcha}: SystemInterface) => captcha.captchaVerifyKey
    );

export const serverTimeSelector: OutputSelector<
    SystemInterface,
    string,
    (state: SystemInterface) => string
    > = createSelector(
        rootSelector,
        system => system.serverTime
    );
