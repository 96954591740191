import {createSelector, Selector} from 'reselect';

import {MODULE_NAMESPACE} from './notifications-constants';
import {AppState, NotificationsState, NotificationData} from './notifications-types';

export const rootSelector: Selector<any, NotificationsState> =
    (state: AppState) => state[MODULE_NAMESPACE];

export const selectNotifications = (notifications: NotificationsState) => notifications.notificationsStack;

export const notificationsSelector: Selector<NotificationsState, NotificationData[]> =
    createSelector(
        rootSelector,
        selectNotifications
    );
