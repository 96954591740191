import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './cell.scss';

interface CellProps {
    isHeader?: boolean;
    hasNoPadding?: boolean;
    isPaddingLeft?: boolean;
    isCentered?: boolean;
    isTextBold?: boolean;
    value: any;
    width: number;
}

const cn = classNames.bind(styles);

export function Cell({
    isHeader = false,
    hasNoPadding = false,
    isPaddingLeft = false,
    isCentered = false,
    isTextBold = false,
    value = '',
    width,
}: CellProps) {
    const classes = cn('cell',
        isHeader && 'cell--header',
        hasNoPadding && 'cell--no-padding',
        isPaddingLeft && 'cell--padding-left',
        isCentered && 'cell--centered',
        isTextBold && 'cell--bold-text');

    return (
        <div className={classes} style={{width}}>
            <div className={cn('cell__value')}>
                {value}
            </div>
        </div>
    );
}
