import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {loadCompanies} from '@app/pages/companies-list-page/companies-list-actions';
import {
    processInternalServerError,
    setFilterableCompanies,
    setSelectedCompany,
    setIsCompaniesCaptcha,
} from '@app/store/actions';
import {
    companiesListSelector,
    filterableCompaniesListSelector,
    insCompanySelector,
} from '@modules/companies';
import {
    feEgarantCapthcaEnableSelector,
    isCompaniesCaptchaVisibleSelector,
    captchaPublicKeySelector,
} from '@modules/system';
import {mismatchDataSelector} from '@modules/modals';
import {isLkBlockedSelector} from '@modules/navigation';
import {contractIdSelector} from '@modules/contract';

import {CompaniesList} from './companies-list';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    companies: companiesListSelector,
    insCompany: insCompanySelector,
    filterableCompanies: filterableCompaniesListSelector,
    isCompaniesCaptchaVisible: isCompaniesCaptchaVisibleSelector,
    captchaPublicKey: captchaPublicKeySelector,
    isLkBlocked: isLkBlockedSelector,
    mismatchData: mismatchDataSelector,
    feEgarantCapthcaEnable: feEgarantCapthcaEnableSelector,
});

const mapDispatchToProps = {
    loadCompanies,
    setIsCompaniesCaptcha,
    selectCompany: setSelectedCompany,
    toggleFilterableCompanies: setFilterableCompanies,
    handleErrorCaptcha: processInternalServerError,
};

export const CompaniesListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompaniesList);
