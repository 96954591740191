import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    confirmExpiredTimer,
    loadCaptchaPublicKey,
    setContractId,
    setForgotPassword,
} from '@app/store/actions';
import {feEgarantCapthcaEnableSelector} from '@modules/system';
import {mismatchDataSelector} from '@modules/modals';
import {flkCodeSelector} from '@modules/stage';
import {forgotPasswordSelector} from '@modules/user';
import {DispatchType} from '@utils/redux';
import {isLkBlockedSelector} from '@modules/navigation';

import {RestorePage} from './restore-page';

const mapStateToProps = createStructuredSelector({
    forgotPassword: forgotPasswordSelector,
    flkCode: flkCodeSelector,
    isLkBlocked: isLkBlockedSelector,
    mismatchData: mismatchDataSelector,
    feEgarantCapthcaEnable: feEgarantCapthcaEnableSelector,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
    setContractId: (value: string) => dispatch(setContractId(value)),
    loadCaptchaPublicKey: () => dispatch(loadCaptchaPublicKey()),
    confirmExpiredTimer: (flkCode: string) => dispatch(confirmExpiredTimer(flkCode)),
    putForgotPassword: () => dispatch(setForgotPassword(true)),
});

export const RestorePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RestorePage);
