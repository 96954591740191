import {createSelector, OutputSelector, Selector} from 'reselect';

import {sortDriversList} from '@app/pages/contract-page/pages/step-3/step3-utils';
import {CONTRACT} from '@app/store/model-constants';
import {
    AppStateInterface,
    ContractDictionaries,
    ContractInterface,
} from '@app/store/types';
import {
    DocumentTypes,
    Drivers,
    Insurer,
    LegalPerson,
    Owner,
    OwnerLawTypes,
    Vehicle,
    CalculationData,
    OwnerInsurerAddresses,
} from '@app/types';
import {
    Document,
    DriverPerson,
    getDocumentsList,
    getFullName,
    UploadedDocumentFile,
} from '@modules/contract';
import {
    validationResultScansSelector,
    requiredDriverDocumentsIndexesSelector,
} from '@modules/validation';

const PASSPORT_RF_CODE = '7';
const PASSPORT_FOREIGN_CODE = '12';
export const E_PTC_CODE = '41'; // «Электронный ПТС/Электронный ПСМ»

const PASSPORT_CODES = [PASSPORT_RF_CODE, PASSPORT_FOREIGN_CODE];

const rootSelector: Selector<any, ContractInterface> = (state: AppStateInterface) => state[CONTRACT];

export const contractIdSelector: OutputSelector<ContractInterface, string, (state: ContractInterface) => string> =
    createSelector(rootSelector, (contract: ContractInterface) => contract.contractId);

export const ownerTypeSelector: OutputSelector<ContractInterface, string, (state: ContractInterface) => string> =
    createSelector(rootSelector, (contract: ContractInterface) => contract?.stepsFormData?.owner?.ownerType);

export const driverMeetsSelector: OutputSelector<ContractInterface, number, (state: ContractInterface) => number> =
    createSelector(rootSelector, (contract: ContractInterface) => contract?.driverMeets);

export const calculatedTableDataSelector: OutputSelector<
    ContractInterface,
    CalculationData,
    (state: ContractInterface) => CalculationData
    > = createSelector(rootSelector, (contract: ContractInterface) => contract.calculatedTableData);

export const insurerSelector: OutputSelector<
    ContractInterface,
    Insurer,
    (state: ContractInterface) => Insurer
    > = createSelector(rootSelector, (contract: ContractInterface) => contract?.stepsFormData &&
        contract?.stepsFormData.insurer);

export const insurerPhoneNumberSelector: OutputSelector<
    ContractInterface,
    string,
    (state: ContractInterface) => string
    > = createSelector(rootSelector, (contract: ContractInterface) => contract?.stepsFormData &&
    contract?.stepsFormData?.insurer?.person?.phoneNumber);

export const ownerSelector: OutputSelector<
    ContractInterface,
    Owner,
    (state: ContractInterface) => Owner
    > = createSelector(rootSelector, (contract: ContractInterface) => contract?.stepsFormData &&
        contract?.stepsFormData.owner);

export const driversSelector: OutputSelector<
    ContractInterface,
    Drivers,
    (state: ContractInterface) => Drivers
    > = createSelector(rootSelector, (contract: ContractInterface) => contract?.stepsFormData &&
        contract?.stepsFormData.drivers);

export const vehicleSelector: OutputSelector<
    ContractInterface,
    Vehicle,
    (state: ContractInterface) => Vehicle
    > = createSelector(rootSelector, (contract: ContractInterface) => contract?.stepsFormData &&
        contract?.stepsFormData.vehicle);

export const addressesSelector: OutputSelector<
    ContractInterface,
    OwnerInsurerAddresses,
    (state: ContractInterface) => OwnerInsurerAddresses
    > = createSelector(rootSelector, (contract: ContractInterface) => contract?.stepsFormData &&
        contract?.stepsFormData.addresses);

export const uploadedDocumentFilesSelector: OutputSelector<
    ContractInterface,
    UploadedDocumentFile[],
    (state: ContractInterface) => UploadedDocumentFile[]
    > = createSelector(
        rootSelector,
        (contract: ContractInterface) => contract?.uploadedDocumentFiles
    );

export const isScanFileUploadingSelector: OutputSelector<
    ContractInterface,
    boolean,
    (state: ContractInterface) => boolean
    > = createSelector(
        rootSelector,
        (contract: ContractInterface) => contract.isScanFileUploading
    );

export const getOwnerFullName: OutputSelector<
    ContractInterface,
    string | null,
    (owner: Owner) => string | null
    > = createSelector(
        ownerSelector,
        owner => (owner?.person ? getFullName(owner.person) : '')
    );


export const getInsurerFullName: OutputSelector<
    ContractInterface,
    string | null,
    (insurer: Insurer) => string | null
    > = createSelector(
        insurerSelector,
        insurer => (insurer?.person ? getFullName(insurer.person) : '')
    );

const getVehicleMaker: Selector<ContractInterface, string> = createSelector(
    vehicleSelector,
    vehicle => vehicle?.otherMaker || vehicle?.maker?.name
);

const getVehicleModel: Selector<ContractInterface, string> = createSelector(
    vehicleSelector,
    vehicle => vehicle?.otherModel || vehicle?.model?.name
);

export const driverPersonsListSelector: OutputSelector<
    ContractInterface,
    DriverPerson[],
    (drivers: Drivers) => DriverPerson[]> = createSelector(
        driversSelector,
        data => data.driversList.map(driver => driver.person)
    );

export const legalPersonSelector: OutputSelector<
    ContractInterface,
    LegalPerson,
    (state: ContractInterface) => LegalPerson> = createSelector(
        rootSelector,
        (contract: ContractInterface) => contract?.stepsFormData?.owner?.legalPerson
    );

export function hasVehicleDocumentRequired(vehicle: Vehicle) {
    return vehicle.document?.documentType !== E_PTC_CODE;
}

export const documentsTableDataSelector: Selector<ContractInterface, Document[]> = createSelector(
    [
        getOwnerFullName,
        getInsurerFullName,
        getVehicleMaker,
        getVehicleModel,
        insurerSelector,
        ownerSelector,
        vehicleSelector,
        driversSelector,
        validationResultScansSelector,
        requiredDriverDocumentsIndexesSelector,
        ownerTypeSelector,
        legalPersonSelector,
    ],
    (
        ownerFullName,
        insurerFullName,
        vehicleMaker,
        vehicleModel,
        insurer,
        owner,
        vehicle,
        drivers,
        requiredDocumentsTypes,
        driverDocumentsIndexes,
        ownerType,
        legalPerson,
    ) => {
        if (!drivers?.driversList) {
            return [];
        }
        const driversFiltered = drivers.driversList.filter(element => (
            driverDocumentsIndexes.includes(element.driverIndex)
        ));
        const isOwnerRegistrationRequired = owner.ownerType === OwnerLawTypes.PHYSICAL &&
            PASSPORT_CODES.includes(owner.personDocument?.docType);
        const isInsurerRegistrationRequired = PASSPORT_CODES.includes(insurer.document?.docType);
        const isVehicleDocumentRequired = vehicle.document?.documentType !== E_PTC_CODE;
        const documentList = getDocumentsList(
            ownerFullName,
            insurerFullName,
            vehicleMaker,
            vehicleModel,
            sortDriversList(driversFiltered),
            ownerType,
            isOwnerRegistrationRequired,
            isInsurerRegistrationRequired,
            isVehicleDocumentRequired,
            legalPerson?.fullName,
        );

        return documentList.filter(document => requiredDocumentsTypes.find((d: DocumentTypes) => d === document.type));
    }
);

export const isAmountInLimitsSelector: OutputSelector<
    ContractInterface,
    boolean,
    (state: ContractInterface) => boolean
    > = createSelector(
        rootSelector,
        (contract: ContractInterface) => contract.isAmountInLimits
    );

export const dictionariesSelector: OutputSelector<
    ContractInterface,
    ContractDictionaries,
    (state: ContractInterface) => ContractDictionaries
    > = createSelector(
        rootSelector,
        (contract: ContractInterface) => contract.dictionaries
    );

export const isNotFirstLoadDataSelector: OutputSelector<
    ContractInterface,
    boolean,
    (state: ContractInterface) => boolean
    > = createSelector(
        rootSelector,
        (contract: ContractInterface) => contract.isNotFirstLoadData
    );
