import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {confirmSelectedCompany} from '@app/pages/companies-list-page/companies-list-actions';
import {
    insCompanySelector,
    linkDataSelector,
} from '@modules/companies';
import {isLkBlockedSelector} from '@modules/navigation';
import {captchaPublicKeySelector} from '@modules/system';
import {contractIdSelector} from '@modules/contract';

import {CompaniesNavigation} from './companies-navigation';

const mapStateToProps = createStructuredSelector({
    insCompany: insCompanySelector,
    contractId: contractIdSelector,
    linkData: linkDataSelector,
    isLkBlocked: isLkBlockedSelector,
    captchaPublicKey: captchaPublicKeySelector,
});

const mapDispatchToProps = {
    confirmSelectedCompany,
};

export const CompaniesNavigationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompaniesNavigation);
