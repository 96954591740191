import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionaryFlk from '@app/pages/shared-pages/layout-page/flk.dic.json';
import {getMismatchesMessages} from '@app/store/actions';
import {Mismatch} from '@app/types';
import {createTranslator} from '@utils/i18n';

import * as styles from './navigation-error.scss';

const cn = classNames.bind(styles);
const f = createTranslator(dictionaryFlk);

interface NavigationErrorInterface {
    flkCode: string;
    isLkBlocked: boolean;
    mismatchData: Mismatch[];
}

export function NavigationError({
    flkCode,
    mismatchData,
    isLkBlocked,
}: NavigationErrorInterface) {
    return (
        <div className={cn('navigation-error')}>
            {
                isLkBlocked &&
                !mismatchData &&
                f(flkCode)
            }
            {
                isLkBlocked && getMismatchesMessages(mismatchData)
            }
        </div>
    );
}
