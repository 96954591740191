import {createSelector, OutputSelector, Selector} from 'reselect';

import {NAVIGATION} from '@app/store/model-constants';
import {AppStateInterface, NavigationInterface} from '@app/store/types';
import {NavigationState} from '@app/types';

const rootSelector: Selector<any, NavigationInterface> = (state: AppStateInterface) => state[NAVIGATION];

export const currentStepSelector: OutputSelector<NavigationInterface, number, (state: NavigationInterface) => number> =
    createSelector(rootSelector, (navigation: NavigationInterface) => navigation?.currentStep);

export const isActiveRegisterBtnAgreeSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isActiveRegisterBtnAgree
    );

export const isActiveRegisterBtnAwareSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isActiveRegisterBtnAware
    );

export const isAgreementBtnEnabledSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isAgreementBtnEnabled
    );

export const isValidationCheckedSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isValidationChecked
    );

export const isUpdateScansStatusDisabledSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isUpdateScansStatusDisabled
    );

export const isCheckDataBtnHiddenSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isCheckDataBtnHidden
    );

export const isNavigationShownSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isNavigationShown
    );

export const isNotFoundSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.isNotFound
    );

export const navigationResultSelector: OutputSelector<
    NavigationInterface,
    NavigationState,
    (state: NavigationInterface) => NavigationState
    > = createSelector(
        rootSelector,
        (navigation: NavigationInterface) => navigation.navigationResult
    );

export const stepAfterSubmitSelector: OutputSelector<
    NavigationInterface,
    number,
    (state: NavigationInterface) => number
    > = createSelector(rootSelector, (navigation: NavigationInterface) => navigation.stepAfterSubmit);

export const isLkBlockedSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(rootSelector, (navigation: NavigationInterface) => navigation.isLkBlocked);

export const isUploadedFilesBtnEnabledSelector: OutputSelector<
    NavigationInterface,
    boolean,
    (state: NavigationInterface) => boolean
    > = createSelector(rootSelector, (navigation: NavigationInterface) => navigation.isUploadedFilesBtnEnabled);
