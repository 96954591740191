import {currentStageSelector} from '@modules/stage';
import {AppStages} from '@utils/constants';
import {DispatchType} from '@utils/redux';

export interface isDisabledInterface {
    isAmountInLimits?: boolean,
    isScansButtonDisabled?: boolean,
    isUploadedFilesReadyToCheck?: boolean,
    isLkBlocked?: boolean,
}

export const isDisabled = (props: isDisabledInterface) => (dispatch: DispatchType, getState: Function) => {
    const state = getState();
    const {
        isAmountInLimits = false,
        isScansButtonDisabled = false,
        isLkBlocked = false,
    } = props;
    const currentStage = currentStageSelector(state);

    if (isLkBlocked) return true;

    if (currentStage === AppStages.PAYMENT && !isAmountInLimits) return true;

    if ((currentStage === AppStages.SCAN_ATTACHMENT ||
        currentStage === AppStages.AUTO_ACCEPT_TIMEOUT) &&
        isScansButtonDisabled) {
        return true;
    }

    return false;
};
