import {FontIconTypes} from './font-icon';
import {makeFontIcon, makeSvgIcon} from './make-icon';
import * as cubePath from './paths/cube.path';
import * as cubesPath from './paths/cubes.path';
import * as searchOk from './paths/search-ok.path';
import * as upload from './paths/upload.path';

export const Icons: {[type: string]: React.FunctionComponent<any>} = {
    ADD: makeFontIcon(FontIconTypes.ADD, 'ADD', 24),
    ARROW_BACK: makeFontIcon(FontIconTypes.ARROW_BACK, 'ARROW_BACK', 24),
    ARROW_DOWN: makeFontIcon(FontIconTypes.ARROW_DOWN, 'ARROW_DOWN', 24),
    ARROW_LEFT: makeFontIcon(FontIconTypes.ARROW_DOWN, 'ARROW_LEFT', 24, 90),
    ARROW_RIGHT: makeFontIcon(FontIconTypes.ARROW_DOWN, 'ARROW_RIGHT', 24, -90),
    ARROW_RIGHT_IOS: makeFontIcon(FontIconTypes.ARROW_RIGHT_IOS, 'ARROW_RIGHT_IOS', 9),
    ARROW_LEFT_IOS: makeFontIcon(FontIconTypes.ARROW_RIGHT_IOS, 'ARROW_LEFT_IOS', 24, -180),
    ARROW_UP: makeFontIcon(FontIconTypes.ARROW_UP, 'ARROW_UP', 24),
    CHECK: makeFontIcon(FontIconTypes.CHECK, 'CHECK'),
    CIRCLE_DOT: makeFontIcon(FontIconTypes.CIRCLE_DOT, 'CIRCLE_DOT', 24),
    CLOSE: makeFontIcon(FontIconTypes.CLOSE, 'CLOSE', 24),
    CLEAR: makeFontIcon(FontIconTypes.CLEAR, 'CLEAR', 18),
    CLOUD_UPLOAD: makeFontIcon(FontIconTypes.CLOUD_UPLOAD, 'CLOUD_UPLOAD', 24),
    CUBE: makeSvgIcon(cubePath.default, 'CUBE'),
    CUBES: makeSvgIcon(cubesPath.default, 'CUBES'),
    DATE_RANGE: makeFontIcon(FontIconTypes.DATE_RANGE, 'DATE_RANGE', 20),
    DELETE: makeFontIcon(FontIconTypes.DELETE, 'DELETE', 24),
    DRAG_INDICATOR: makeFontIcon(FontIconTypes.DRAG_INDICATOR, 'DRAG_INDICATOR'),
    EDIT: makeFontIcon(FontIconTypes.EDIT, 'EDIT', 18),
    ERROR: makeFontIcon(FontIconTypes.ERROR, 'ERROR', 22),
    EXIT: makeFontIcon(FontIconTypes.EXIT, 'EXIT', 24),
    EXPAND_LESS: makeFontIcon(FontIconTypes.EXPAND_LESS, 'EXPAND_LESS', 24),
    EXPAND_MORE: makeFontIcon(FontIconTypes.EXPAND_MORE, 'EXPAND_MORE', 24),
    EXPORT: makeSvgIcon(upload.default, 'EXPORT', 24, {width: 24, height: 24}),
    IMPORT: makeFontIcon(FontIconTypes.IMPORT, 'IMPORT', 24),
    FILTER_LIST: makeFontIcon(FontIconTypes.FILTER_LIST, 'FILTER_LIST', 24),
    INFO: makeFontIcon(FontIconTypes.INFO, 'INFO', 24),
    KEY: makeFontIcon(FontIconTypes.KEY, 'KEY', 24),
    LINK: makeFontIcon(FontIconTypes.LINK, 'LINK', 24),
    LIST: makeFontIcon(FontIconTypes.LIST, 'LIST', 18),
    MENU: makeFontIcon(FontIconTypes.MENU, 'MENU', 24),
    MORE_VERT: makeFontIcon(FontIconTypes.MORE_VERT, 'MORE_VERT', 24),
    NEXT: makeFontIcon(FontIconTypes.NEXT, 'NEXT', 24),
    PREV: makeFontIcon(FontIconTypes.PREV, 'PREV', 24),
    REMOVE: makeFontIcon(FontIconTypes.REMOVE, 'REMOVE', 24),
    SEARCH_OK: makeSvgIcon(searchOk.default, 'SEARCH_OK'),
    SEARCH: makeFontIcon(FontIconTypes.SEARCH, 'SEARCH', 24),
    SORT: makeFontIcon(FontIconTypes.SORT, 'SORT', 24),
    UNDO: makeFontIcon(FontIconTypes.UNDO, 'UNDO', 24),
    VISIBILITY: makeFontIcon(FontIconTypes.VISIBILITY, 'VISIBILITY'),
    WARNING: makeFontIcon(FontIconTypes.WARNING, 'WARNING', 24),
};
