import * as React from 'react';

import {SortDirections} from '@utils/constants';

import {useSorting} from './with-table-sorting-hooks';
import {getInitialSortedColumn} from './with-table-sorting-utils';

export function withTableSorting(WrappedComponent: React.ComponentType<any>) {
    function EnhancedComponent({
        rows,
        columns = [],
        ...restProps
    }: any) {
        const initialSortedColumn = getInitialSortedColumn(columns);
        const initialSortCriteria = {
            name: initialSortedColumn ? initialSortedColumn.name : '',
            direction: SortDirections.ASC,
        };
        const {sortedRows, sortCriteria, changeSortCriteria} = useSorting(rows, initialSortCriteria);

        return (
            <WrappedComponent
                {...restProps}
                rows={sortedRows}
                columns={columns}
                sortCriteria={sortCriteria}
                onHeaderCellClick={changeSortCriteria}
            />
        );
    }

    EnhancedComponent.displayName = `withTableSorting(${WrappedComponent.displayName || WrappedComponent.name})`;

    return EnhancedComponent;
}
