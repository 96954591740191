import {Action, handleActions} from '@utils/redux';

import {MODULE_NAMESPACE} from './notifications-constants';
import {
    NotificationsState,
    NotificationData,
    ACTIONS,
} from './notifications-types';

export const initialState: NotificationsState = {
    notificationsStack: [],
};

const notificationsHandler = handleActions(MODULE_NAMESPACE);
export const notificationsReducer = notificationsHandler({
    [ACTIONS.SAVE_NOTIFICATION]:
        (state: NotificationsState, {payload: notification}: Action<Notification>) => ({
            ...state,
            notificationsStack: [
                notification,
                ...state.notificationsStack,
            ],
        }),
    [ACTIONS.REMOVE_NOTIFICATION]:
        (state: NotificationsState, {payload: id}: Action<NotificationData['id']>) => {
            if (state.notificationsStack.length) {
                return {
                    ...state,
                    notificationsStack: id ? state.notificationsStack.filter(
                        ({id: currentId}) => currentId !== id
                    ) : state.notificationsStack.slice(1),
                };
            }
            return state;
        },
    [ACTIONS.CLEAN_NOTIFICATIONS]:
        () => initialState,
}, initialState);
