import classNames from 'classnames/bind';
import * as React from 'react';

import {TextField} from '@app/components';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {createTranslator} from '@app/utils/i18n';
import {shouldBeDisabled} from '@utils/should-be-disabled';

import * as styles from './legal-document-data.scss';

const t = createTranslator(dictionary);
const cn = classNames.bind(styles);

export interface LegalDocumentDataInterface {
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function LegalDocumentData({
    scansEditFields,
    isLkBlocked,
}: LegalDocumentDataInterface) {
    return (
        <div className={cn('legal-document-data')}>
            <div className={cn('legal-document-data__container')}>
                <div className={cn('legal-document-data__form-field')}>
                    <TextField
                        name="owner-legalPerson-INN"
                        label={t('INN')}
                        disabled={shouldBeDisabled({
                            name: 'owner-legalPerson-INN',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                        required
                    />
                </div>
                <div className={cn('legal-document-data__form-field')}>
                    <TextField
                        name="owner-legalPerson-fullName"
                        label={t('fullName')}
                        disabled={shouldBeDisabled({
                            name: 'owner-legalPerson-fullName',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                        required
                    />
                </div>
            </div>
        </div>
    );
}
