import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {validationResultScansEditFieldsSelector} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {SecondNameField} from './second-name-field';

const mapStateToProps = createStructuredSelector({
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const SecondNameFieldContainer = connect(
    mapStateToProps
)(SecondNameField);
