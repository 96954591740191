import * as React from 'react';

import {ContractCheck} from './components/contract-check';
import {FormContainer} from './components/form-container';

export const Step4 = () => (
    <>
        <FormContainer />
        <ContractCheck />
    </>
);
