import classNames from 'classnames/bind';
import * as React from 'react';

import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './footer.dic.json';
import * as styles from './footer.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface FooterProps {
    onClearSelectedDates: () => void;
    onApplySelectedDates: () => void;
}

export const Footer = ({
    onClearSelectedDates,
    onApplySelectedDates,
}: FooterProps) => (
    <div className={cn('footer')}>
        <div className={cn('footer__button')}>
            <Button
                label={t('footer.clear-button.label')}
                onClick={onClearSelectedDates}
            />
        </div>
        <div className={cn('footer__button')}>
            <Button
                label={t('footer.apply-button.label')}
                onClick={onApplySelectedDates}
                primary
            />
        </div>
    </div>
);
