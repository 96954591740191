import classNames from 'classnames/bind';
import * as React from 'react';
import {Route} from 'react-router-dom';

import {WrapperTimer} from '@app/pages/shared-pages/layout-page/components/header/components/wrapper-timer';
import {
    LinkData,
    UserAuthStatus,
} from '@app/types';
import {
    REGISTRATION_PAGE,
    CONTRACT_PAGE,
    LOGIN_PAGE,
    RESTORE_PAGE,
    COMPANIES_LIST_PAGE,
    RESULT_PAGE,
    FAIL_PAGE,
    AGREEMENT_PAGE,
} from '@app/urls';

import {
    Logo,
    SignIn,
    UserInfo,
} from './components';
import {getIsPathIncludesPageName} from './header-utils';

import * as styles from './header.scss';

const cn = classNames.bind(styles);

interface HeaderProps {
    authenticated: UserAuthStatus;
    path: string;
    getLink?: (contractId: string) => void;
    hasUser?: (contractId: string) => void;
    getHasInsCompanySecondChosen?: (contractId: string) => void;
    hasInsCompanySecondChosen?: boolean;
    isOverlay?: boolean;
    linkData?: LinkData;
}

const PAGE_NO_SK_DOMAIN = [AGREEMENT_PAGE, COMPANIES_LIST_PAGE];
const PAGE_SK_DOMAIN = [REGISTRATION_PAGE, LOGIN_PAGE, CONTRACT_PAGE, RESTORE_PAGE, RESULT_PAGE, FAIL_PAGE];

const getPathParams = (path: string) => path.split('/');

const getContractId = (path: string) => {
    const [, firstPath, secondPath, thirdPath] = getPathParams(path);
    if (PAGE_SK_DOMAIN.includes(firstPath)) {
        return secondPath;
    }

    return PAGE_NO_SK_DOMAIN.includes(firstPath) ? secondPath : thirdPath;
};

export function Header({
    authenticated,
    path,
    getLink,
    hasUser,
    getHasInsCompanySecondChosen,
    hasInsCompanySecondChosen,
    linkData,
    isOverlay,
}: HeaderProps) {
    const PAGE_INDEX = 2;
    const contractId = getContractId(path);
    const showSignIn = !authenticated && getIsPathIncludesPageName(path, `/${RESTORE_PAGE}`);
    const isLogoShown = !isOverlay || linkData;

    window.scrollTo(0, 0);

    React.useEffect(() => {
        if (!linkData && contractId) {
            getLink(contractId);
            hasUser(contractId);
            getHasInsCompanySecondChosen(contractId);
        }
    }, [contractId]);

    const splittedUrl = path.split('/');
    let logoName = 'logo.svg';
    const onLogoInsuranceCompany = PAGE_SK_DOMAIN;

    if (hasInsCompanySecondChosen && (onLogoInsuranceCompany.includes(splittedUrl[PAGE_INDEX]))) {
        logoName = `${linkData?.insCompanyId}.png`;
    }

    const LOGO_URL = `/assets/img/${logoName}`;

    return (
        <header className={cn('header')}>
            <div className={cn('header__left-group')}>
                <div className={cn('header__logo-wrapper')}>
                    {isLogoShown && <Logo imageUrl={LOGO_URL} />}
                </div>
            </div>

            <div className={cn('header__right-group')}>
                <Route
                    path={[
                        `/${COMPANIES_LIST_PAGE}/:contractId?`,
                        `/:skDomain/${REGISTRATION_PAGE}/:contractId?`,
                        `/:skDomain/${LOGIN_PAGE}/:contractId?`,
                        `/:skDomain/${RESTORE_PAGE}/:contractId?`,
                        `/:skDomain/${CONTRACT_PAGE}/:contractId?`,
                    ]}
                    render={() => <WrapperTimer />}
                />
                {(authenticated || showSignIn) && (
                    <div className={cn('header__login-logout-buttons')}>
                        {authenticated && <UserInfo />}
                        {showSignIn && <SignIn />}
                    </div>
                )}
            </div>
        </header>
    );
}
