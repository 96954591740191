
import classNames from 'classnames/bind';
import * as React from 'react';

import {IconButtonType} from '@app/components/icon-button';
import {IconButton, Icons} from '@ui-kit';

import {MessageType} from '../notifications-types';

import * as styles from './notification.scss';

const cn = classNames.bind(styles);

export interface NotificationProps {
    onClose: (id: string | number) => void
    type: MessageType
    message: string
    id: string | number
}

export function Notification({
    type,
    message,
    id,
    onClose,
}: NotificationProps) {
    const lines = message.split('\n');

    return (
        <div className={cn('notification', `notification--${type}`)}>
            <div className={cn('notification__message')}>{lines.map(line => <p key={line}>{line}</p>)}</div>
            <div className={cn('notification__close-button')}>
                <IconButton
                    type={IconButtonType.MESSAGE}
                    onClick={() => { onClose(id); }}
                    icon={Icons.CLOSE}
                />
            </div>
        </div>
    );
}
