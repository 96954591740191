import classNames from 'classnames/bind';
import * as React from 'react';

import {TableButtons} from '@app/components/layouts/table-layout';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './fail-info.dic.json';
import * as styles from './fail-info.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface FailInfoInterface {
    contractId: string;
    skDomain: string;
    isLkBlocked: boolean;
    redirectToPaymentUrl: (contractId: string, skDomain: string) => void;
    cancelPayment: (contractId: string) => void;
}

export function FailInfo({
    contractId,
    skDomain,
    redirectToPaymentUrl,
    cancelPayment,
    isLkBlocked,
}: FailInfoInterface) {
    return (
        <div className={cn('fail-info')}>
            <h2 className={cn('fail-info__header')}>
                {t('fail-info-page-title')}
            </h2>
            <div className={cn('fail-info__content')}>
                <p className={cn('fail-info__content__top')}>
                    {t('fail-info-page-text')}
                </p>
            </div>
            <div className={cn('fail-info__navigation')}>
                <TableButtons>
                    <Button
                        label={t('fail-info-page-button-back')}
                        onClick={() => cancelPayment(contractId)}
                        disabled={isLkBlocked}
                    />
                    <Button
                        label={t('fail-info-page-button-pay')}
                        onClick={() => redirectToPaymentUrl(contractId, skDomain)}
                        primary
                        disabled={isLkBlocked}
                    />
                </TableButtons>
            </div>
        </div>
    );
}
