export enum TimerStatus {
    STARTED = 'STARTED',
    INTERRUPTED = 'INTERRUPTED',
    EXPIRED = 'EXPIRED',
}

export interface Timer {
    type: TimerType;
    secondsLeft: number;
    bigDate: string;
    status: TimerStatus;
    timerId: number;
    contractId: string;
    flkCode: string;
}

export interface TimerExpireEvent {
    type: TimerType;
    contractId: string;
    flkCode: string;
}

export interface CurrentTimerData {
    secondsLeft: number;
    flkCode: string;
    timerType: TimerType;
}

export enum TimerType {
    SCAN_ATTACHMENT = 'SCAN_ATTACHMENT', // Время для предоставления страхователем копий документов
    PAYMENT = 'PAYMENT', // Время, в течение которого страхователь должен перейти на страницу оплаты
    LOGIN = 'LOGIN', // Время, отведенное на первичный вход ЛК, мин
    CONCLUDE_EOSAGO = 'CONCLUDE_EOSAGO', // Время, отведенное на заключение договора в ЛК, мин
    // Время для переноса записей в исторические ((7 дней *24ч *60мин) - LOGIN+CONCLUDE_EOSAGO)
    CONCLUDE_EOSAGO_ADD_HIST = 'CONCLUDE_EOSAGO_ADD_HIST',
    // Время, отведенное на направление данных заявления на проверку в АИС ОСАГО, мин
    APPLICATION_DIRECTION = 'APPLICATION_DIRECTION',
    VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS', // Время, отведенное на проверку договора ОСАГО в АИС ОСАГО, мин
    CHANGE_APPLICATION_DATA = 'CHANGE_APPLICATION_DATA', // Время, отведенное на корректировку данных заявления, мин
    RE_PAYMENT = 'RE_PAYMENT', // Время, отведенное на повторную оплату страховой премии, мин
    BANK_PAYMENT = 'BANK_PAYMENT', // Время, отведенное на оплату страховой премии на сайте банка-эквайера
    // Время ожидания подтверждения скана документа от СК до авто-акцепта, в минутах
    AUTO_ACCEPT_TIMEOUT = 'AUTO_ACCEPT_TIMEOUT',
    // Время жизни ссылки на заключение договора еГарант (время отведенное на выбор СК2)
    APPLICATION_LINK_LIFETIME = 'APPLICATION_LINK_LIFETIME',
}
