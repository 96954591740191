import {createSelector, Selector, OutputSelector} from 'reselect';

import {CONTRACT, VALIDATION} from '@app/store/model-constants';
import {AppStateInterface, ContractInterface} from '@app/store/types';
import {
    DocumentTypes,
    ValidationData,
    ValidationEntities,
    ValidationFields,
    PersonType,
} from '@app/types';
import {
    ValidationInterface,
    MappedMismatchField,
    MappedValidationInterface,
} from '@modules/validation';

const rootSelector: Selector<any, ValidationInterface> = (state: AppStateInterface) => state[VALIDATION];
const rootSelectorOwner: Selector<any, ContractInterface> = (state: AppStateInterface) => state[CONTRACT];

const insurerIsOwnerSelector: OutputSelector<
    ContractInterface,
    boolean,
    (state: ContractInterface) => boolean
    > = createSelector(rootSelectorOwner, (contract: ContractInterface) => contract.stepsFormData &&
    contract.stepsFormData?.insurer?.isOwner);

export const isValidationInitiatedSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation?.validationData?.isValidationInitiated
    );

export const isValidationInProgressSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation?.validationData?.isValidationInProgress
    );

export const mappedValidationKitStep1Selector: OutputSelector<
    ValidationInterface,
    MappedMismatchField[],
    (isOwner: boolean, state: ValidationInterface) => MappedMismatchField[]
    > = createSelector(
        [
            insurerIsOwnerSelector,
            rootSelector,
        ], (isOwner, validation) => {
            if (isOwner) {
                return [];
            }

            return validation.mappedValidationKit && validation.mappedValidationKit.step1;
        }
    );

export const mappedValidationKitStep2Selector: OutputSelector<
    ValidationInterface,
    MappedMismatchField[],
    (state: ValidationInterface) => MappedMismatchField[]
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation.mappedValidationKit &&
        validation.mappedValidationKit.step2
    );

export const mappedValidationKitStep3Selector: OutputSelector<
    ValidationInterface,
    MappedMismatchField[],
    (state: ValidationInterface) => MappedMismatchField[]
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation.mappedValidationKit &&
        validation.mappedValidationKit.step3
    );

export const scansToEditSelector: OutputSelector<
    ValidationInterface,
    string[],
    (state: ValidationInterface) => string[]
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation?.scansToEdit);

export const stepsToValidateSelector: OutputSelector<
    ValidationInterface,
    number[],
    (state: ValidationInterface) => number[]
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation.stepsToValidate);

export const mappedValidationKitSelector: OutputSelector<
    ValidationInterface,
    MappedValidationInterface,
    (state: ValidationInterface) => MappedValidationInterface
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation.mappedValidationKit);

export const requiredDriverDocumentsIndexesSelector: OutputSelector<
    ValidationInterface,
    number[],
    (state: ValidationInterface) => number[]
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation?.validationData?.driverIndexes);

export const validationResultFieldsSelector: OutputSelector<
    ValidationInterface,
    ValidationFields,
    (state: ValidationInterface) => ValidationFields
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation?.validationData?.validationResultFields
    );

export const validationResultScansSelector: OutputSelector<
    ValidationInterface,
    DocumentTypes[],
    (state: ValidationInterface) => DocumentTypes[]
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation?.validationData?.validationResultScans
    );

export const validationResultEntitiesSelector: OutputSelector<
    ValidationInterface,
    ValidationEntities[],
    (state: ValidationInterface) => ValidationEntities[]
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation?.validationData?.validationResultEntities
    );

export const validationResultScansEditFieldsSelector: OutputSelector<
    ValidationInterface,
    String[],
    (isOwner: boolean, state: ValidationInterface) => any[]
    > = createSelector(
        [
            insurerIsOwnerSelector,
            rootSelector,
        ],
        (isOwner, validation) => {
            if (isOwner) {
                const result: String[] = validation.validationResultErrorFields
                    .filter((item: string) => item.indexOf(PersonType.INSURER) && item.indexOf(PersonType.OWNER));
                return result;
            }

            return validation.validationResultErrorFields;
        }
    );

export const isScanSendingInitiatedSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation?.validationData?.isScanSendingInitiated
    );

export const isScanEditingInitiatedSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(
        rootSelector,
        (validation: ValidationInterface) => validation?.validationData?.isScanEditingInitiated
    );

export const isValidationWaitingSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation.isValidationWaiting);

export const isAllScansApprovedSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation?.isAllScansApproved);

export const isScansInProgressSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation?.isScansInProgress);

export const isScansPollingInitiatedSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation?.isScansPollingInitiated);

export const insurerAddressWarningMessageSelector: OutputSelector<
    ValidationInterface,
    string,
    (state: ValidationInterface) => string
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation.insurerAddressWarningMessage);

export const ownerAddressWarningMessageSelector: OutputSelector<
    ValidationInterface,
    string,
    (state: ValidationInterface) => string
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation.ownerAddressWarningMessage);

export const insurerAddressTextWarningMessageSelector: OutputSelector<
    ValidationInterface,
    string,
    (state: ValidationInterface) => string
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation.insurerAddressTextWarningMessage);

export const ownerAddressTextWarningMessageSelector: OutputSelector<
    ValidationInterface,
    string,
    (state: ValidationInterface) => string
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation.ownerAddressTextWarningMessage);

export const validationDataSelector: OutputSelector<
    ValidationInterface,
    ValidationData,
    (state: ValidationInterface) => ValidationData
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation?.validationData);

export const isDataEditedSelector: OutputSelector<
    ValidationInterface,
    boolean,
    (state: ValidationInterface) => boolean
    > = createSelector(rootSelector, (validation: ValidationInterface) => validation?.validationData?.isDataEdited);
