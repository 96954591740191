import classNames from 'classnames/bind';
import * as React from 'react';

import {TableButtons} from '@app/components/layouts/table-layout';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as styles from '../right-part.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface UsePeriodButtonsInterface {
    isShownAddUsePeriod: () => boolean;
    isShownDeleteUsePeriod: () => boolean;
    handleAddUsePeriod: () => void;
    handleDeleteUsePeriod: () => void;
    isLkBlocked: boolean;
}

export const UsePeriodButtons = ({
    isShownAddUsePeriod,
    isShownDeleteUsePeriod,
    handleAddUsePeriod,
    handleDeleteUsePeriod,
    isLkBlocked,
}: UsePeriodButtonsInterface) => {
    const canDeleteUsePeriod = isShownDeleteUsePeriod();
    const canAddUsePeriod = isShownAddUsePeriod();

    return (
        <div className={cn('right-part__form-use-period-buttons')}>
            <TableButtons>
                {canDeleteUsePeriod && (
                    <Button
                        label={t('right-remove')}
                        danger
                        onClick={handleDeleteUsePeriod}
                        disabled={isLkBlocked}
                    />
                )}
                {canAddUsePeriod && (
                    <Button
                        label={t('right-add')}
                        primary
                        onClick={handleAddUsePeriod}
                        disabled={isLkBlocked}
                    />
                )}
            </TableButtons>
        </div>
    );
};
