import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {sendStep} from '@app/pages/contract-page/contract-page-actions';
import {setNoLimitDriver} from '@app/store/actions';
import {
    driversSelector,
    vehicleSelector,
    contractIdSelector,
} from '@modules/contract';
import {
    validationResultScansEditFieldsSelector,
    mappedValidationKitStep3Selector,
    stepsToValidateSelector,
} from '@modules/validation';
import {
    isLkBlockedSelector,
    currentStepSelector,
    stepAfterSubmitSelector,
} from '@modules/navigation';

import {Step3} from './step3';

const mapStateToProps = createStructuredSelector({
    vehicle: vehicleSelector,
    drivers: driversSelector,
    contractId: contractIdSelector,
    warningData: mappedValidationKitStep3Selector,
    currentStep: currentStepSelector,
    scansEditFields: validationResultScansEditFieldsSelector,
    stepAfterSubmit: stepAfterSubmitSelector,
    stepsToValidate: stepsToValidateSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    sendStep,
    switchNoLimit: setNoLimitDriver,
};

export const Step3Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step3);
