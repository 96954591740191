import * as React from 'react';

import {PasswordInput} from '@ui-kit';

import {fieldAdapter} from '../field-adapter';
import {withErrorIndicator} from '../with-error-indicator';

export interface PasswordFieldWithFieldAdapterProps {
    onChange?: (data: string) => void;
    disabled?: boolean;
    id?: string;
    label: string;
    name: string;
    required?: boolean;
    width?: string | number;
    value?: string;
}

function PasswordFieldWithFieldAdapterComponent(props: PasswordFieldWithFieldAdapterProps) {
    const {
        onChange,
        ...restProps
    } = props;
    const [password, setPassword] = React.useState('');
    const handlePasswordChange = React.useCallback(
        password => {
            setPassword(password);
            onChange(password);
        },
        []
    );
    return (
        <PasswordInput
            {...restProps}
            onChange={handlePasswordChange}
            value={password}
        />
    );
}

export const PasswordFieldWithFieldAdapter = fieldAdapter(withErrorIndicator(PasswordFieldWithFieldAdapterComponent));
