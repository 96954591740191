import * as React from 'react';
import {debounce} from 'lodash-es';

import {Document} from '@modules/contract';
import {interval} from '@utils/interval';
import {
    RequestIntervals,
    AppStages,
} from '@utils/constants';

import {ContractCheckbox} from '../contract-checkbox';
import {DocumentsTable} from '../documents-table';
import {CheckResult} from '../check-result';

interface ContractCheckProps {
    currentStage: string;
    documents: Document[];
    isScanSendingInitiated: boolean;
    isScanEditingInitiated: boolean;
    isAutoAcceptHeartbeatStopped: boolean;
    isLkBlocked: boolean;
    updateUploadedFilesStatuses: () => void;
}

export const ContractCheck = ({
    currentStage,
    documents,
    isScanSendingInitiated,
    isScanEditingInitiated,
    isAutoAcceptHeartbeatStopped,
    updateUploadedFilesStatuses,
    isLkBlocked,
}: ContractCheckProps) => {
    const [heartbeatInterval, setHeartbeatInterval] = React.useState(null);
    React.useEffect(() => {
        if (heartbeatInterval) {
            heartbeatInterval.clear();
        }
        if (currentStage === AppStages.AUTO_ACCEPT_TIMEOUT && !isAutoAcceptHeartbeatStopped && !isLkBlocked) {
            const intervalId = interval(
                debounce(updateUploadedFilesStatuses, RequestIntervals.DEBOUNCE_INTERVAL),
                RequestIntervals.TIME_UPDATE_UPLOADED
            );
            setHeartbeatInterval(intervalId);
        }
    }, [currentStage, isAutoAcceptHeartbeatStopped]);

    return (
        <>
            <ContractCheckbox />
            {
                ((currentStage === AppStages.SCAN_ATTACHMENT ||
                    currentStage === AppStages.PAYMENT ||
                    currentStage === AppStages.AUTO_ACCEPT_TIMEOUT) ||
                    currentStage === AppStages.AUTO_ACCEPT_TIMEOUT__REJECTED ||
                    (currentStage === AppStages.CHANGE_APPLICATION_DATA && isScanSendingInitiated)) &&
                !isScanEditingInitiated &&
                documents.length > 0 &&
                    <DocumentsTable />
            }
            <CheckResult />
        </>
    );
};
