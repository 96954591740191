import {
    ModalType,
    ModalPositionTypes,
    WIDTHS,
} from './modals-types';

const registry: {[type: string]: ModalType} = {};
const openModalsParams: object[] = [];

export const addOpenModalsParams = (params: object = {}) => {
    openModalsParams.push({...params});
};

export const registerModal = (modals: ModalType[]) => {
    modals.forEach(({
        type,
        component,
        isClosable = true,
        closeOnOverlay = true,
        position = ModalPositionTypes.CENTER,
        // NOTE: maybe these params are not relevant to all possible modal types
        isResizable = false,
        initialWidth = WIDTHS.SMALL,
    }: ModalType) => {
        registry[type] = {
            type,
            component,
            isClosable,
            closeOnOverlay,
            position,
            isResizable,
            initialWidth,
        };
    });
};

export const unregisterModal = (type: string): void => {
    registry[type] = undefined;
};

export const removeOpenModalsParams = () => openModalsParams.pop();

export const getModal = (type: string): ModalType => registry[type];

export const getOpenModalsParams = () => [...openModalsParams];
