import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    confirmExpiredTimer,
    setCurrentStage,
} from '@app/store/actions';
import {mismatchDataSelector} from '@modules/modals';
import {flkCodeSelector} from '@modules/stage';
import {isLkBlockedSelector} from '@modules/navigation';

import {TextInformation} from './text-information';

const mapStateToProps = createStructuredSelector({
    flkCode: flkCodeSelector,
    isLkBlocked: isLkBlockedSelector,
    mismatchData: mismatchDataSelector,
});

const mapDispatchToProps = {
    confirmExpiredTimer,
    setCurrentStage,
};

export const TextInformationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TextInformation);
