import {ContractInterface} from '@app/store/types';
import {
    CalculationData,
    Insurer,
    FiasElementType,
    Person,
    OwnerLawTypes,
    Owner,
    Drivers,
    Vehicle,
    DocumentTypes,
    TypeCategoryDto,
    VerificationStatus,
    TransdekraMakerPage,
    TransdekraModelPage,
    NavigationState,
    NavigationStep,
} from '@app/types';
import {
    Document,
    UploadedDocumentFile,
} from '@modules/contract';
import {
    MappedMismatchField,
    MappedValidationInterface,
} from '@modules/validation';
import {SelectOption} from '@utils/formatters/select-option';
import {createTranslator} from '@utils/i18n';

import * as dictionary from '@modules/contract/contract.dic.json';

const t = createTranslator(dictionary);

const fileName = 'test_file.pdf';
export const contractIdMock = '13345200-abe8-4f60-90c8-0d43c5f6c0f6';
export const driverMeetsMock = 2;

export const addressesMock = {
    insurerFullAddress: 'insurerFullAddress',
    ownerFullAddress: 'ownerFullAddress',
};

export const personMock: Person = {
    name: 'ЮЛИЯ',
    secondName: 'АЛЕКСАНДРОВНА',
    surname: 'ПЕТРОВА',
    birthdate: '1998-04-19',
    phoneNumber: '79036400105',
    noSecondName: false,
    snils: null,
};

export const insurerMock: Insurer = {
    isOwner: false,
    isDriver: true,
    person: personMock,
    document: {
        docType: '12',
        docSeries: '4603',
        docNumber: '842715',
    },
    addressFias: {
        region: {
            name: 'Московская обл',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a36d4',
            elementType: FiasElementType.REGION,
        },
        district: {
            name: 'Московская обл',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a36d5',
            elementType: FiasElementType.DISTRICT,
        },
        city: {
            name: 'Москва',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a36d6',
            elementType: FiasElementType.CITY,
        },
        locality: {
            name: 'Московская обл',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a36d7',
            elementType: FiasElementType.LOCALITY,
        },
        street: {
            name: 'Ленина',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a36d8',
            elementType: FiasElementType.STREET,
        },
        house: {
            name: '1',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a36d9',
            elementType: FiasElementType.HOUSE,
        },
        building: {
            name: '2',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a3610',
            elementType: FiasElementType.BUILDNUM,
        },
        structure: {
            name: '3',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a3611',
            elementType: FiasElementType.STRUCNUM,
        },
        room: {
            name: '4',
            fiasId: '29251dcf-00a1-4e34-98d4-5c47484a3612',
            elementType: FiasElementType.ROOM,
        },
    },
};

export const ownerMock: Owner = {
    ownerType: OwnerLawTypes.PHYSICAL,
    isDriver: false,
    person: {
        name: 'ЮЛИЯ1',
        secondName: 'АЛЕКСАНДРОВНА',
        surname: 'ПЕТРОВА1',
        birthdate: '1998-04-19',
        phoneNumber: '79036400105',
        noSecondName: false,
        snils: '12345678900',
    },
    legalPerson: {
        INN: '88005553535',
        fullName: 'ООО «Рога и копыта»',
    },
    legalPersonDocument: {
        documentType: '60',
        documentSeries: '4603',
        documentNumber: '842715',
        accountingDate: '2019-04-19',
    },
    personDocument: {
        docType: '12',
        docSeries: '4603',
        docNumber: '842715',
    },
    addressFias: {
        region: null,
        district: null,
        city: null,
        locality: null,
        street: null,
        house: null,
        building: null,
        structure: null,
        room: null,
    },
};

export const driversMock: Drivers = {
    noLimits: false,
    driversList: [
        {
            driverIndex: 1,
            meetsWithPolicyholderData: false,
            person: {
                name: 'ИВАН1',
                secondName: 'ИВАНОВИЧ',
                surname: 'ПЕТРОВ',
                birthdate: '1997-06-19',
                phoneNumber: '+79036400107',
                noSecondName: false,
            },
            driverLicense: {
                series: '5001',
                number: '245716',
                startDate: '2018-12-10',
            },
        },
        {
            driverIndex: 0,
            meetsWithPolicyholderData: false,
            person: {
                name: 'ЮЛИЯ',
                secondName: 'АЛЕКСАНДРОВНА',
                surname: 'ПЕТРОВА',
                birthdate: '1998-04-19',
                phoneNumber: '79036400105',
                noSecondName: false,
            },
            driverLicense: {
                series: '5001',
                number: '245716',
                startDate: '2018-12-10',
            },
        },
    ],
};

export const vehicleMock: Vehicle = {
    bodyNumber: 'string',
    maker: {
        name: 'Renault',
        makerKey: '0',
    },
    diagnosticCard: {
        dateEnd: '2019-12-17',
        number: '136487543465764',
    },
    document: {
        dateGiven: '2019-12-17',
        documentType: '30',
        series: 'series string',
        number: 'number string',
    },
    insuranceEndDate: '2019-12-17T14:44:00.000Z',
    insuranceStartDate: '2019-12-17T14:44:00.000Z',
    isOnTheWayToRegistration: false,
    isShortInsurancePolicy: false,
    isUsedWithTrailer: false,
    maxWeight: null,
    model: {
        name: 'Logan',
        modelKey: '0',
    },
    numberPlate: 'string',
    otherMaker: null,
    otherModel: null,
    power: 10,
    registrationCountry: 'RF',
    schassisNumber: 'string',
    seatsAmount: null,
    typeCategory: {
        shortName: 'B, BE',
        fullName: 'Транспортные средства категории "B", "BE"',
        code: '01',
    },
    usePeriod1: 12,
    usePeriod1EndDate: '2019-12-17',
    usePeriod1StartDate: '2019-12-17',
    usePeriod2: null,
    usePeriod2EndDate: null,
    usePeriod2StartDate: null,
    usePeriod3: null,
    usePeriod3EndDate: null,
    usePeriod3StartDate: null,
    usePurpose: '1',
    vin: 'string',
    year: 1990,
};

const identityCardTypesMock: SelectOption<string>[] = [
    {label: 'Паспорт РФ', value: '12'},
    {label: 'Удостоверение личности офицера', value: '2'},
];

const organizationRegDocTypesMock: SelectOption<string>[] = [
    {label: 'Подтверждение внесения в ЕГРЮЛ', value: '60'},
];

const vehicleDocumentTypesMock: SelectOption<string>[] = [
    {label: 'Паспорт ТС', value: '30'},
];

const vehiclePurposeTypesMock: SelectOption<string>[] = [
    {label: 'Личное', value: '1'},
    {label: 'Такси', value: '2'},
];

export const dictionariesMock = {
    identityCardTypes: identityCardTypesMock,
    organizationRegDocTypes: organizationRegDocTypesMock,
    vehicleDocumentTypes: vehicleDocumentTypesMock,
    vehiclePurposeTypes: vehiclePurposeTypesMock,
};

export const contractStateMock: ContractInterface = {
    contractId: null,
    stepsFormData: {
        insurer: insurerMock,
        owner: ownerMock,
        drivers: driversMock,
        vehicle: vehicleMock,
        addresses: {
            insurerFullAddress: '',
            ownerFullAddress: '',
        },
    },
    uploadedDocumentFiles: [],
    isScanFileUploading: false,
    driverMeets: -1,
    isAmountInLimits: true,
    dictionaries: dictionariesMock,
    isNotFirstLoadData: false,
};

export const uploadedDocumentFilesMock: UploadedDocumentFile[] = [
    {
        fileName,
        fileGuid: 'asdfasfasdf-12341234sf',
        fileOrderInGroup: 1,
        documentType: DocumentTypes.DIAGNOSTIC_CARD,
    },
    {
        fileName,
        fileGuid: 'asdfasfasdffasdf-12341234sf',
        fileOrderInGroup: 2,
        documentType: DocumentTypes.INSURER_DOCUMENT,
    },
];

export const documentMock: Document = {
    type: DocumentTypes.INSURER_DOCUMENT,
    title: 'Документ, удостоверяющий личность страхователя',
    label: 'ЮЛИЯ АЛЕКСАНДРОВНА ПЕТРОВА',
    files: [
        {
            fileOrderInGroup: 1,
            type: 'Разворот с фотографией',
            required: true,
        },
        {
            fileOrderInGroup: 2,
            type: 'Разворот с актуальной регистрацией',
            required: true,
        },
    ],
};

export const mockScanDiagnosticCard = {
    scanFilesData: [{
        fileName,
        fileGuid: 'string',
        fileOrderInGroup: 1,
    }],
    verificationResult: {
        status: VerificationStatus.IN_PROGRESS,
        rejectReasonSum: 'string',
        rejectReasons: [''],
    },
};

export const mockScanDriversDocuments = {
    driverIndex: 1,
    scanFilesData: [{
        fileName,
        fileGuid: 'string',
        fileOrderInGroup: 1,
    }],
    verificationResult: {
        status: VerificationStatus.REJECTED,
        rejectReasonSum: 'string',
        rejectReasons: [''],
    },
};

const mappedMismatchFieldOwnerMock: MappedMismatchField = {
    fieldName: 'owner-person-surname',
    fieldValue: 'ПЕТРОВА1',
    fieldMessages: ['Паспортные данные не могут содержать цифры'],
    isCritical: true,
};

const mappedMismatchFieldVehicleMock: MappedMismatchField = {
    fieldName: 'vehicle-person-surname',
    fieldValue: 'ПЕТРОВА1',
    fieldMessages: ['Паспортные данные не могут содержать цифры'],
    isCritical: true,
};

const mappedMismatchFieldDriversMock: MappedMismatchField = {
    fieldName: 'drivers-person-surname',
    fieldValue: 'ПЕТРОВА1',
    fieldMessages: ['Паспортные данные не могут содержать цифры'],
    isCritical: true,
};

export const parsedKitMock: MappedValidationInterface = {
    step1: [mappedMismatchFieldOwnerMock],
    step2: [mappedMismatchFieldVehicleMock],
    step3: [mappedMismatchFieldDriversMock],
};

export const parsedKitMockEmpty: MappedValidationInterface = {
    step1: [],
    step2: [],
    step3: [],
};

export const typeCategoriesMock: TypeCategoryDto[] = [
    {
        code: '01',
        shortName: 'B, BE',
        fullName: 'Транспортные средства категории "B", "BE"',
    }, {
        code: '02',
        shortName: 'A, M',
        fullName: 'Мотоциклы, мопеды и легкие квадрициклы (транспортные средства категории "A", "M")',
    }, {
        code: '03',
        shortName: 'C, CE',
        fullName: 'Транспортные средства категорий "C" и "CE"',
    }, {
        code: '04',
        shortName: 'D, DE',
        fullName: 'Транспортные средства категорий "D" и "DE"',
    }, {
        code: '05',
        shortName: 'Tb',
        fullName: 'Троллейбусы (транспортные средства категории "Tb")',
    }, {
        code: '06',
        shortName: 'Tm',
        fullName: 'Трамваи (транспортные средства категории "Tm")',
    }, {
        code: '07',
        shortName: 'Спец. и прочие ТС',
        fullName: 'Тракторы, самоходные дорожно-строительные и иные машины, ' +
            'за исключением транспортных средств, не имеющих колесных движителей',
    },
];

export const trandekraMakerPageMock: TransdekraMakerPage = {
    totalCount: 15,
    objects: [{
        name: 'Audi',
        makerKey: '1',
    }, {
        name: 'BMW',
        makerKey: '2',
    }, {
        name: 'Volvo',
        makerKey: '3',
    }, {
        name: 'Lada',
        makerKey: '4',
    }, {
        name: 'Dodge',
        makerKey: '5',
    }, {
        name: 'Nissan',
        makerKey: '6',
    }, {
        name: 'Renault',
        makerKey: '7',
    }, {
        name: 'Peugeot',
        makerKey: '8',
    }, {
        name: 'Honda',
        makerKey: '9',
    }, {
        name: 'Hyundai',
        makerKey: '10',
    }, {
        name: 'Mercedes',
        makerKey: '11',
    }, {
        name: 'Fiat',
        makerKey: '12',
    }, {
        name: 'Ferrari',
        makerKey: '13',
    }, {
        name: 'Lamborghini',
        makerKey: '14',
    }, {
        name: 'Chrysler',
        makerKey: '15',
    }],
};

export const transdekraModelPageMock: TransdekraModelPage = {
    totalCount: 15,
    objects: [{
        name: 'Twingo',
        modelKey: '1',
    }, {
        name: 'Symbol',
        modelKey: '2',
    }, {
        name: 'Logan',
        modelKey: '3',
    }, {
        name: 'Sandero',
        modelKey: '4',
    }, {
        name: 'Mégane',
        modelKey: '5',
    }, {
        name: 'Fluence',
        modelKey: '6',
    }, {
        name: 'Laguna',
        modelKey: '7',
    }, {
        name: 'Talisman',
        modelKey: '8',
    }, {
        name: 'Safrane',
        modelKey: '9',
    }, {
        name: 'Captur',
        modelKey: '10',
    }, {
        name: 'Kadjar',
        modelKey: '11',
    }, {
        name: 'Koleos',
        modelKey: '12',
    }, {
        name: 'Duster',
        modelKey: '13',
    }, {
        name: 'Kaptur',
        modelKey: '14',
    }, {
        name: 'Scénic',
        modelKey: '15',
    }],
};

export const calculationDataMock: CalculationData = {
    amount: 15986.71,
    tb: 3200,
    kt: 5,
    kbm: 2,
    kbc: 0,
    kc: 2,
    kp: 0,
    km: 3.2,
    ko: 1.53,
};

export const contractNavigationStateMock: NavigationState = {
    step: NavigationStep.ALFA,
    paymentUrl: 'alfa',
};

const createFile = (isRequired: boolean, type: string, fileOrderInGroup: number) => (
    {
        type,
        fileOrderInGroup,
        required: isRequired,
    }
);

const fileListPhotoRegistrationNotRequired = [
    createFile(true, t('document-table.file-type.photo'), 1),
    createFile(false, t('document-table.file-type.registration'), 2),
];

const gerFileListFrontSideFlipSide = (isRequired: boolean) => [
    createFile(isRequired, t('document-table.file-type.front-side'), 1),
    createFile(isRequired, t('document-table.file-type.flip-side'), 2),
];

export const documentsListMock: Document[] = [
    {
        type: DocumentTypes.INSURER_DOCUMENT,
        title: t('documents-table.insurer-document.title'),
        hint: t('documents-table.insurer-document.hint'),
        label: 'ЮЛИЯ АЛЕКСАНДРОВНА ПЕТРОВА',
        documentIndex: null,
        files: fileListPhotoRegistrationNotRequired,
    },
    {
        type: DocumentTypes.OWNER_DOCUMENT,
        title: t('documents-table.owner-document.title'),
        label: 'ЮЛИЯ АЛЕКСАНДРОВНА ПЕТРОВА',
        documentIndex: null,
        files: [{
            fileOrderInGroup: 1,
            type: 'Лицевая сторона',
            required: true,
        }],
    },
    {
        type: DocumentTypes.VEHICLE_DOCUMENT,
        title: t('documents-table.vehicle-document.title'),
        hint: t('documents-table.vehicle-document.hint'),
        label: 'vehicleMaker vehicleModel',
        documentIndex: null,
        files: gerFileListFrontSideFlipSide(false),
    },
    {
        type: DocumentTypes.DIAGNOSTIC_CARD,
        title: t('documents-table.diagnostic-card.title'),
        hint: t('documents-table.diagnostic-card.hint'),
        documentIndex: null,
        files: gerFileListFrontSideFlipSide(true),
    },
    {
        type: DocumentTypes.DRIVERS_DOCUMENTS,
        title: t('documents-table.driver-license.title', 'первого'),
        label: 'ПЕТРОВА ЮЛИЯ АЛЕКСАНДРОВНА',
        documentIndex: 0,
        files: gerFileListFrontSideFlipSide(true),
    },
];
