import classNames from 'classnames/bind';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {getMismatchesMessages} from '@app/store/actions';
import {InsuranceCompany, Mismatch} from '@app/types';
import {formatSum} from '@app/utils/formatters';
import {
    Button,
    DataTable,
    Column,
    Icons,
    RadioButton,
    Anchor,
} from '@ui-kit';
import {searchFormat} from '@utils/formatters/search-format';
import {createTranslator} from '@utils/i18n';
import {PREMIUM} from '@utils/constants';
import {joinWithSpace} from '@utils/functions';

import {SearchInput} from '../search-input';

import * as dictionary from '../../companies-list-page.dic.json';
import * as styles from './companies-list.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const SELECT_REGION_BUTTON_UPDATE = 450;

function createColumns(
    insCompany: InsuranceCompany,
    onSelectItem: (selectedCompany?: InsuranceCompany) => void
): Column[] {
    return [
        {
            name: 'paddingColumn',
            width: 80,
            selector: (insuranceCompany: InsuranceCompany) => (
                <RadioButton
                    id={insuranceCompany.id}
                    value={insuranceCompany.name}
                    name={insuranceCompany.name}
                    disabled={!insuranceCompany.isActive}
                    label=""
                    onChange={() => onSelectItem(insuranceCompany)}
                    checked={insuranceCompany?.id === insCompany?.id}
                />
            ),
        },
        {
            name: 'searchNameLowerCase',
            header: t('companies-list-page-name'),
            selector: ({
                searchName,
                legalOrgForm,
                formalPartName,
                link,
                isActive,
            }: InsuranceCompany) => {
                const fullNameCompany = joinWithSpace([
                    searchName,
                    legalOrgForm,
                    formalPartName,
                ]);

                return (isActive ?
                    (
                        <Anchor
                            link={link}
                            label={fullNameCompany}
                            target="_blank"
                        />
                    ) :
                    (
                        <span>{fullNameCompany}</span>
                    ));
            },
            width: 550,
            isSortable: true,
            isInitialSorted: true,
        },
        {
            name: 'contractsLeft',
            header: t('companies-list-page-contracts-left'),
            width: 260,
        },
        {
            name: PREMIUM,
            header: t('companies-list-page-premium'),
            width: 290,
        },
    ];
}

const printTable = (
    insCompany: InsuranceCompany,
    items: InsuranceCompany[],
    onSelectItem: (selectedCompany?: InsuranceCompany) => void,
    filterListItems: (value: string) => void,
    searchInputValue: string
) => (
    <div className={cn('companies-list__table')}>
        <div className={cn('companies-list__search-header')}>
            {t('companies-list-page-search-header')}
        </div>
        <div className={cn('companies-list__search')}>
            <SearchInput
                value={searchInputValue}
                placeholder={t('companies-list-page-search-placeholder')}
                onChange={filterListItems}
            />
            <div className={cn('companies-list__search-icon')}>
                <Icons.SEARCH size={24} />
            </div>
        </div>
        <div className={cn('companies-list__data-table')}>
            <DataTable
                hasHeightByContent
                columns={createColumns(insCompany, onSelectItem)}
                selectedRow={insCompany}
                getRowId={({id}: InsuranceCompany) => id}
                rows={items.map(item => ({...item, premium: !item.premium ? '' : formatSum({value: item.premium})}))}
                disabledRows={items.filter(element => !element.isActive)}
                getRowStyle={() => ({borderColor: '#e9eaf2'})}
                isSortableColumns
            />
        </div>
    </div>
);

interface CompaniesListInterface {
    loadCompanies?: (contractId: string, captchaToken: string) => void;
    selectCompany?: (selectedCompany?: InsuranceCompany) => void;
    contractId: string;
    companies: InsuranceCompany[];
    filterableCompanies: InsuranceCompany[];
    insCompany: InsuranceCompany;
    isCompaniesCaptchaVisible: boolean;
    setIsCompaniesCaptcha: (value: Boolean) => void;
    toggleFilterableCompanies: (filterableCompanies: InsuranceCompany[]) => void;
    captchaPublicKey: string;
    isLkBlocked: boolean;
    mismatchData: Mismatch[];
    handleErrorCaptcha: () => void;
    feEgarantCapthcaEnable: boolean;
}

export function CompaniesList({
    loadCompanies,
    contractId,
    companies,
    insCompany,
    filterableCompanies,
    selectCompany,
    isCompaniesCaptchaVisible,
    setIsCompaniesCaptcha,
    toggleFilterableCompanies,
    captchaPublicKey,
    isLkBlocked,
    mismatchData,
    handleErrorCaptcha,
    feEgarantCapthcaEnable,
}: CompaniesListInterface) {
    const memoizedCallback = React.useCallback(
        (userVerifyKey?: string) => {
            if (captchaPublicKey || !feEgarantCapthcaEnable) {
                setIsCompaniesCaptcha(false);
                loadCompanies(contractId, feEgarantCapthcaEnable ? userVerifyKey : '');
            }
        },
        [contractId, captchaPublicKey]
    );

    const [searchInputValue, setSearchInputValue] = React.useState('');

    const filterListItems = (query: string) => {
        setSearchInputValue(query);

        const filteredList = companies
            .filter(
                ({searchName, legalOrgForm, formalPartName}) => {
                    const fullName = `${searchName || ''} ${legalOrgForm || ''} ${formalPartName || ''}`;
                    return searchFormat(fullName)
                        .includes(searchFormat(query));
                }
            );

        toggleFilterableCompanies(filteredList);
    };

    return (
        <>
            <div className={cn('companies-list__button-update-wrapper')}>
                <div className={cn('companies-list__button-update')}>
                    <Button
                        label={t('companies-list-page-button-update')}
                        primary
                        width={SELECT_REGION_BUTTON_UPDATE}
                        onClick={feEgarantCapthcaEnable ?
                            () => setIsCompaniesCaptcha(!isCompaniesCaptchaVisible) :
                            memoizedCallback}
                    />
                </div>
                {
                    isCompaniesCaptchaVisible &&
                    feEgarantCapthcaEnable && (
                        <div className={cn('companies-list__captcha')}>
                            <ReCAPTCHA
                                sitekey={captchaPublicKey}
                                onChange={memoizedCallback}
                                onErrored={handleErrorCaptcha}
                            />
                        </div>
                    )
                }
                <div className={cn('companies-list__error')}>
                    {
                        isLkBlocked && getMismatchesMessages(mismatchData)
                    }
                </div>
            </div>
            {companies.length > 0 &&
            printTable(
                insCompany,
                filterableCompanies,
                selectCompany,
                filterListItems,
                searchInputValue
            )}
        </>
    );
}
