import * as React from 'react';

import {
    Column,
    IconButton,
    IconButtonType,
    Icons,
} from '@ui-kit';
import {stopPropagationBeforeCall} from '@utils/dom';
import {cloneDeep} from '@utils/functions';

import {DataTable} from '../../data-table';
import {
    Row,
    RowProps,
} from '../row';

const getTogglerColumn = (
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
) => ({
    name: 'toggler',
    hasNoPadding: true,
    selector: () => (
        <IconButton
            type={isOpen ? IconButtonType.PRIMARY : IconButtonType.ICON}
            icon={isOpen ? <Icons.ARROW_DOWN /> : <Icons.ARROW_RIGHT />}
            onClick={stopPropagationBeforeCall(() => setIsOpen(!isOpen))}
        />
    ),
    width: 40,
});

export interface RowWithTableProps extends RowProps {
    row: {
        items?: object[];
    };
    getRowId: (row: object) => string | number;
    getColumnsWithToggler?: (columns: Column[], togglerColumn: Column) => Column[];
}

export function RowWithTable(props: RowWithTableProps) {
    const {
        highlight,
        columns,
        height,
        row: {items},
        onClick,
        getRowId,
        getColumnsWithToggler,
    } = props;
    const [isTableOpen, setIsTableOpen] = React.useState(false);
    const togglerColumn = getTogglerColumn(isTableOpen, setIsTableOpen);
    let parentRowColumns = cloneDeep(columns);

    if (getColumnsWithToggler) {
        parentRowColumns = getColumnsWithToggler(parentRowColumns, togglerColumn);
    } else {
        parentRowColumns.splice(0, 0, togglerColumn);
    }

    return (
        <div style={{height: isTableOpen ? height * (items.length + 1) : height}}>
            <Row
                {...props}
                columns={parentRowColumns}
                isTextBold
            />
            {isTableOpen && (
                <DataTable
                    rows={items}
                    columns={columns}
                    getRowId={getRowId}
                    isScrollbarHided
                    highlightRows={highlight}
                    onRowClick={onClick}
                    headerHeight={0}
                />
            )}
        </div>
    );
}
