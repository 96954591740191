import classNames from 'classnames/bind';
import * as React from 'react';

import {
    TextField,
    PortalRoot,
    DateField,
} from '@app/components';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {Step3FormInterface} from '@app/pages/contract-page/pages/step-3/step3-types';
import {TypeCategoryDto} from '@app/types';
import {createTranslator} from '@app/utils/i18n';
import {addYears} from '@utils/date';
import {shouldBeDisabled} from '@utils/should-be-disabled';

import {SecondNameField} from './components/second-name-field';

import * as styles from './driver-info.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const MIN_DRIVER_AGE = 16;

export interface DriverInfoInterface {
    id: number;
    typeCategory: TypeCategoryDto;
    values: Step3FormInterface;
    mutator: (field: string, value: string, id?: number) => void;
    isLoadingContract: boolean;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function DriverInfo({
    id,
    typeCategory,
    values,
    mutator,
    isLoadingContract,
    scansEditFields,
    isLkBlocked,
}: DriverInfoInterface) {
    const birthDate = values[`driversList-${id}-person-birthdate` as keyof Step3FormInterface] as string;

    return (
        <>
            {!isLoadingContract && (
                <div className={cn('driver')}>
                    <div className={cn('driver__main-content')}>
                        <div className={cn('driver__container')}>
                            <h2 className={cn('driver__standart-header')}>{t(`${id + 1}-driver`)}</h2>
                            <div className={cn('driver__form-field-wrapper')}>
                                <div className={cn('driver__form-field')}>
                                    <TextField
                                        name={`driversList-${id}-person-surname`}
                                        label={t('surname')}
                                        disabled={shouldBeDisabled({
                                            name: `driversList-${id}-person-surname`,
                                            listFieldError: scansEditFields,
                                            isLkBlocked,
                                        })}
                                    />
                                </div>
                                <div className={cn('driver__form-field')}>
                                    <TextField
                                        name={`driversList-${id}-person-name`}
                                        label={t('first-name')}
                                        disabled={shouldBeDisabled({
                                            name: `driversList-${id}-person-name`,
                                            listFieldError: scansEditFields,
                                            isLkBlocked,
                                        })}
                                    />
                                </div>
                            </div>

                            <SecondNameField
                                cn={cn}
                                textFieldLabel={t('secondName')}
                                checkboxLabel={t('noSecondName')}
                                driverId={id}
                                mutator={mutator}
                                values={values}
                            />

                            <div className={cn('driver__form-field-weight')}>
                                <PortalRoot />
                                <DateField
                                    label={t('birthdate')}
                                    name={`driversList-${id}-person-birthdate`}
                                    isDisabled={shouldBeDisabled({
                                        name: `driversList-${id}-person-birthdate`,
                                        listFieldError: scansEditFields,
                                        isLkBlocked,
                                    })}
                                    onChange={value => mutator(`driversList-${id}-person-birthdate`, value, id)}
                                />
                            </div>
                        </div>
                        <div className={cn('driver__container')}>
                            <h2>{t('driver-document')}</h2>
                            <div className={cn('driver__form-field-weight')}>
                                <TextField
                                    name={`driversList-${id}-driverLicense-series`}
                                    label={t('document-series')}
                                    disabled={shouldBeDisabled({
                                        name: `driversList-${id}-driverLicense-series`,
                                        listFieldError: scansEditFields,
                                        isLkBlocked,
                                    })}
                                />
                            </div>
                            <div className={cn('driver__form-field-weight')}>
                                <TextField
                                    name={`driversList-${id}-driverLicense-number`}
                                    label={t('document-number')}
                                    disabled={shouldBeDisabled({
                                        name: `driversList-${id}-driverLicense-number`,
                                        listFieldError: scansEditFields,
                                        isLkBlocked,
                                    })}
                                />
                            </div>
                            <div className={cn('driver__form-field-weight')}>
                                <DateField
                                    label={t('driverLicense-startDate', typeCategory?.shortName)}
                                    name={`driversList-${id}-driverLicense-startDate`}
                                    isDisabled={shouldBeDisabled({
                                        name: `driversList-${id}-driverLicense-startDate`,
                                        listFieldError: scansEditFields,
                                        isLkBlocked,
                                        forceDisabled: !birthDate,
                                    })}
                                    availableDates={[addYears(new Date(birthDate), MIN_DRIVER_AGE)]}
                                    showFirstAvailableDate
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
