import {ConfigsInterface} from '@app/store/types';
import {PublicKeyResponse} from '@app/types';
import {
    getData,
    API_V1_EGARANT_CAPTCHA_UI,
} from '@utils/api';

const CONFIGS_URL = '/configs';
const EGARANT_PROPERTIES = 'egarant-properties.json';
const PUBLIC_KEY = 'publicKey';

export function getCaptchaPublicKey(): Promise<PublicKeyResponse> {
    const PUBLIC_KEY_URL = `${API_V1_EGARANT_CAPTCHA_UI}/${PUBLIC_KEY}`;
    return getData(PUBLIC_KEY_URL);
}

export function getEgarantProperties(): Promise<ConfigsInterface> {
    const EGARANT_PROPERTIES_URL = `${CONFIGS_URL}/${EGARANT_PROPERTIES}`;
    return getData(EGARANT_PROPERTIES_URL);
}
