import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './path-group.scss';

const cn = classNames.bind(styles);

const renderPaths = (paths: string = '', renderer: (path: string, key: number) => JSX.Element) => paths
    .split(/[\r\n]+/)
    .filter(Boolean)
    .map(renderer);

interface PathGroupProps {
    transform?: string,
    path: string,
    noFill?: boolean,
    noStroke?: boolean
}

export function PathGroup({
    transform = '',
    path,
    noFill = true,
    noStroke = true,
}: PathGroupProps) {
    const classes = cn(
        'path-group',
        {'path-group--fill': !noFill},
        {'path-group--stroke': !noStroke}
    );

    return (
        <g className={classes} transform={transform}>
            {renderPaths(path, (d: string, key: number) => <path d={d} key={key} />)}
        </g>
    );
}
