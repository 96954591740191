import {format} from 'date-fns';
import formatStringByPattern from 'format-string-by-pattern';

import {DateFormats} from '@utils/date';

const DECIMAL_MODULE = 10;
const SECONDS_IN_MINUTE = 60;
const MINUTE_IN_HOURS = 60;

const TIME_SEPARATOR = ':';
const timeMask = `XX${TIME_SEPARATOR}XX`;

export const outputTwoDigits = (timeNumber: number) => (
    timeNumber < DECIMAL_MODULE ? `0${timeNumber}` : `${timeNumber}`
);

export const convertToTimeMMSS = (amountOfSeconds: number) => {
    const minutes = Math.floor(amountOfSeconds / SECONDS_IN_MINUTE);
    const seconds = amountOfSeconds % SECONDS_IN_MINUTE;
    return (
        `${outputTwoDigits(minutes)}:${outputTwoDigits(seconds)}`
    );
};

export const convertToTimeHHMMSS = (amountOfSeconds: number) => {
    const hours = Math.floor(amountOfSeconds / SECONDS_IN_MINUTE / MINUTE_IN_HOURS);
    const minutes = Math.floor((amountOfSeconds / SECONDS_IN_MINUTE) % MINUTE_IN_HOURS);
    const seconds = amountOfSeconds % SECONDS_IN_MINUTE;
    return (
        `${hours}:${outputTwoDigits(minutes)}:${outputTwoDigits(seconds)}`
    );
};

export const convertToTimeHHMM = (amountOfSeconds: Date) => {
    const HH = outputTwoDigits(amountOfSeconds.getHours());
    const MM = outputTwoDigits(amountOfSeconds.getMinutes());
    if (amountOfSeconds) {
        return `${HH}:${MM}`;
    }

    return null;
};

const replacer = (match: string, hours: string, minutes: string) => {
    const fixedHours = outputTwoDigits(Math.min(Number(hours), 23));
    const fixedMinutes = outputTwoDigits(Math.min(Number(minutes), 59));
    return `${fixedHours}${fixedMinutes}`;
};

export const formatTime = (value?: string) => {
    if (value) {
        const onlyNumbers = value.replace(/[^\d]/g, '');
        const fixedTime = onlyNumbers.replace(/^(\d\d)(\d\d)/, replacer);
        return formatStringByPattern(timeMask, fixedTime);
    }
    return value;
};

export const convertToHoursMinutes = (value?: string) => (
    (value || TIME_SEPARATOR).split(TIME_SEPARATOR).map((n = '0') => Number(n))
);

export const toLocalTimeZone = (date: string) => {
    const dateWithOffset = new Date(date);
    if (!dateWithOffset.getTime()) {
        return null;
    }
    return new Date(dateWithOffset.getTime() + dateWithOffset.getTimezoneOffset() * 60000).toISOString();
};

export const toContractTimeZone = (date: string) => {
    const dateWithOffset = new Date(date);
    if (!dateWithOffset.getTime()) {
        return null;
    }
    return new Date(dateWithOffset.getTime() - dateWithOffset.getTimezoneOffset() * 60000).toISOString();
};

export const toContractDateFormat = (date: string) => {
    const dateWithoutOffset = toContractTimeZone(date);
    if (!dateWithoutOffset) {
        return null;
    }
    return format(new Date(dateWithoutOffset), DateFormats.YYYYMMDD_HYPHEN);
};
