import * as React from 'react';

import {useColumnsStatuses} from './with-columns-statuses-hooks';
import {setMenuColumnWidth} from './with-columns-statuses-utils';

export function withColumnsStatuses(WrappedComponent: React.ComponentType<any>) {
    function EnhancedComponent({
        columns,
        ...restProps
    }: any) {
        const {hasColumnsButton} = restProps;
        const {
            visibleColumns,
            handleUpdateColumnsStatuses,
            configurableColumns,
        } = useColumnsStatuses(setMenuColumnWidth(columns));

        const wrappedComponentProps = hasColumnsButton ?
            {
                ...restProps,
                configurableColumns,
                columns: visibleColumns,
                updateColumnsStatuses: handleUpdateColumnsStatuses,
            } :
            {
                columns,
                ...restProps,
            };

        return (
            <WrappedComponent {...wrappedComponentProps} />
        );
    }

    EnhancedComponent.displayName = `withColumnsStatuses(${WrappedComponent.displayName || WrappedComponent.name})`;

    return EnhancedComponent;
}
