import classNames from 'classnames/bind';
import * as React from 'react';

import {MatchParams} from '@utils/match-params';

import {ConsentText} from './components/consent-text';
import {FormRegistration} from './components/form-registration';
import {TextInformation} from './components/text-information';

import * as styles from './registration-page.scss';

const cn = classNames.bind(styles);

export function RegistrationPage({
    match,
}: {
    match: MatchParams;
}) {
    return (
        <div className={cn('registration')}>
            <TextInformation />
            <FormRegistration
                contractId={match.params.contractId}
            />
            <ConsentText />
        </div>
    );
}
