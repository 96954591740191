import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import * as styles from './month-view.scss';

const cn = classNames.bind(styles);

interface MonthProps {
    month: string;
    onClick: () => void;
    isCurrent: boolean;
    isFocused: boolean;
    isSelected: boolean;
}

export function Month({
    month,
    onClick = noop,
    isCurrent = false,
    isFocused = false,
    isSelected = false,
}: MonthProps) {
    return (
        <span
            role="button"
            className={cn('month-view__month', {
                'month-view__month--current': isCurrent,
                'month-view__month--focused': isFocused,
                'month-view__month--selected': isSelected,
            })}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <span className={cn('month-view__label')}>{month}</span>
        </span>
    );
}
