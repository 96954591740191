import classNames from 'classnames/bind';
import * as React from 'react';

import {Modal} from './components/modal';
import {getOpenModalsParams} from './modals-registry';

import * as styles from './modals-component.scss';

const cn = classNames.bind(styles);
const getStyle = (index: number) => ({zIndex: index});

export interface ModalsProps {
    modals: string[];
    onClose: () => void;
    closeOnOverlay: boolean;
}

export const Modals = ({
    modals,
    onClose,
    closeOnOverlay,
}: ModalsProps) => {
    const openModalsParams = getOpenModalsParams();

    return (
        <div className={cn({'modal-root': !!modals.length})}>
            {modals.map((type: string, index: number) => {
                const key = `${type}${index}`;
                if (!modals.length) {
                    return null;
                }

                return (
                    <div
                        key={key}
                        onClick={closeOnOverlay ? onClose : null}
                        role="button"
                        className={cn('modal-root__overlay')}
                        style={getStyle(index)}
                    >
                        <div style={getStyle(index)} className={cn('modal-root__content-container')}>
                            <Modal
                                params={{...openModalsParams[index]}}
                                type={type}
                                onClose={onClose}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
