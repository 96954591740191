
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    loadHasInsCompanySecondChosen,
    loadLinkData,
    loadUserExists,
} from '@app/store/actions';
import {
    hasInsCompanySecondChosenSelector,
    linkDataSelector,
} from '@modules/companies';
import {authenticatedSelector} from '@modules/user';
import {overlayVisibilitySelector} from '@modules/overlay';

import {Header} from './header';

const mapStateToProps = createStructuredSelector({
    authenticated: authenticatedSelector,
    linkData: linkDataSelector,
    hasInsCompanySecondChosen: hasInsCompanySecondChosenSelector,
    isOverlay: overlayVisibilitySelector,
});
const mapDispatchToProps = {
    getLink: loadLinkData,
    hasUser: loadUserExists,
    getHasInsCompanySecondChosen: loadHasInsCompanySecondChosen,
};

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
