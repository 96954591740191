import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {checkUploadedFiles} from '@app/store/actions';
import {
    isLkBlockedSelector,
    isUploadedFilesBtnEnabledSelector,
} from '@modules/navigation';
import {isDisabled} from '@utils/isDisabled';

import {ButtonApplyCheckDocuments} from './button-apply-check-documents';

const mapStateToProps = createStructuredSelector({
    isLkBlocked: isLkBlockedSelector,
    isUploadedFilesBtnEnabled: isUploadedFilesBtnEnabledSelector,
});

const mapDispatchToProps = {
    isDisabled,
    onCheckDocuments: checkUploadedFiles,
};

export const ButtonApplyCheckDocumentsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonApplyCheckDocuments);
