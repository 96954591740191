import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/components/navigation/navigation.dic.json';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';
import {isDisabledInterface} from '@utils/isDisabled';

const t = createTranslator(dictionary);

interface ButtonApplyCheckDocumentsInterface {
    isDisabled: (props: isDisabledInterface) => boolean;
    onCheckDocuments: () => void;
    isUploadedFilesBtnEnabled: boolean;
    isLkBlocked: boolean;
}

export function ButtonApplyCheckDocuments({
    isDisabled,
    onCheckDocuments,
    isUploadedFilesBtnEnabled,
    isLkBlocked,
}: ButtonApplyCheckDocumentsInterface) {
    const [disabled, setDisabled] = React.useState(false);

    return (
        <Button
            label={t('navigation.button.apply.check.documents')}
            primary
            disabled={disabled || isDisabled({
                isLkBlocked,
                isScansButtonDisabled: !isUploadedFilesBtnEnabled,
            })}
            onClick={() => {
                onCheckDocuments();
                setDisabled(prev => !prev);
            }}
        />
    );
}
