import {Mismatch} from '@app/types';
import {ModalsInterface} from '@app/store/types';

export const modalsStateMock: ModalsInterface = {
    modalsStack: [],
    isBadRequestModalShown: false,
    mismatchData: [],
};

export const mismatchDataMock: Mismatch[] = [
    {
        code: '16002030',
        message: '',
        isCritical: true,
    },
    {
        code: '16002037',
        message: '',
        isCritical: false,
    },
];
