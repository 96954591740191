import {FiasElement} from '@app/types';
import {SelectOption} from '@utils/formatters/select-option';

type InsurerBase = {
    'insurer-isOwner': boolean;
    'insurer-isDriver': boolean;
}

type InsurerPerson = {
    'insurer-person-name': string,
    'insurer-person-secondName': string,
    'insurer-person-surname': string,
    'insurer-person-birthdate': string,
    'insurer-person-phoneNumber': string,
    'insurer-person-noSecondName': boolean,
    'insurer-person-snils': string,
}

type InsurerDocument = {
    'insurer-document-docType': SelectOption<string>,
    'insurer-document-docSeries': string,
    'insurer-document-docNumber': string
}

type InsurerAddress = {
    'insurer-addressFias-country': string;
    'insurer-addressFias-region': SelectOption<FiasElement>;
    'insurer-addressFias-district': SelectOption<FiasElement>;
    'insurer-addressFias-city': SelectOption<FiasElement>;
    'insurer-addressFias-locality': SelectOption<FiasElement>;
    'insurer-addressFias-street': SelectOption<FiasElement>;
    'insurer-addressFias-house': SelectOption<FiasElement>;
    'insurer-addressFias-building': SelectOption<FiasElement>;
    'insurer-addressFias-structure': SelectOption<FiasElement>
    'insurer-addressFias-room': SelectOption<FiasElement>;
}

interface InsurerFormCompiled extends InsurerBase, InsurerPerson, InsurerDocument, InsurerAddress {}

type OwnerBase = {
    'owner-ownerType': string;
    'owner-isDriver': boolean;
}

type OwnerPerson = {
    'owner-person-name': string,
    'owner-person-secondName': string,
    'owner-person-surname': string,
    'owner-person-birthdate': string,
    'owner-person-phoneNumber': string,
    'owner-person-noSecondName': boolean,
    'owner-person-snils': string,
}
type OwnerLegalPersonDocument = {
    'owner-legalPersonDocument-documentType': SelectOption<string>;
    'owner-legalPersonDocument-documentSeries': string;
    'owner-legalPersonDocument-documentNumber': string;
    'owner-legalPersonDocument-accountingDate': string;
}

type OwnerLegalPerson = {
    'owner-legalPerson-INN': string;
    'owner-legalPerson-fullName': string;
}

type OwnerDocument = {
    'owner-personDocument-docType': SelectOption<string>,
    'owner-personDocument-docSeries': string,
    'owner-personDocument-docNumber': string
}

type OwnerAddress = {
    'owner-addressFias-country': string;
    'owner-addressFias-region': SelectOption<FiasElement>;
    'owner-addressFias-district': SelectOption<FiasElement>;
    'owner-addressFias-city': SelectOption<FiasElement>;
    'owner-addressFias-locality': SelectOption<FiasElement>;
    'owner-addressFias-street': SelectOption<FiasElement>;
    'owner-addressFias-house': SelectOption<FiasElement>;
    'owner-addressFias-building': SelectOption<FiasElement>;
    'owner-addressFias-structure': SelectOption<FiasElement>
    'owner-addressFias-room': SelectOption<FiasElement>;
}

interface OwnerFormCompiled extends
    OwnerBase,
    OwnerPerson,
    OwnerDocument,
    OwnerAddress,
    OwnerLegalPerson,
    OwnerLegalPersonDocument
    {}

export interface Step1FormInterface extends InsurerFormCompiled, OwnerFormCompiled {}

export interface FiasDataInterface {
    'insurer-addressFias-house': string;
    'insurer-addressFias-room': string;
    'owner-addressFias-house': string;
    'owner-addressFias-room': string;
}

export enum Step1FormInterfaceKeys {
    INSURER_PERSON_SNILS = 'insurer-person-snils',
    OWNER_PERSON_SNILS = 'owner-person-snils',
}
