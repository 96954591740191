import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import {Icons} from '../icons';

import * as styles from './checkbox.scss';

export interface CheckboxProps {
    label?: string,
    id: string,
    checked?: boolean,
    indeterminate?: boolean,
    isDisabled?: boolean,
    onChange?: () => void
}

export interface CheckboxState {
}

const cn = classNames.bind(styles);

export class Checkbox extends React.PureComponent<CheckboxProps, CheckboxState> {
    static defaultProps = {
        label: '',
        indeterminate: false,
        checked: false,
        isDisabled: false,
        onChange: noop,
    };

    render() {
        const {
            label,
            checked,
            isDisabled,
            onChange,
            id,
            indeterminate,
        } = this.props;
        const isIndeterminate = !checked && indeterminate;
        const isChecked = checked && !indeterminate;
        const isInitial = !(isChecked || isDisabled || isIndeterminate);

        return (
            <div className={cn('checkbox')}>
                <div className={cn('checkbox__inner')}>
                    <div
                        role="checkbox"
                        aria-checked={checked}
                        onClick={isDisabled ? noop : onChange}
                        className={cn(
                            'checkbox__control',
                            {'checkbox__control--disabled': isDisabled}
                        )}
                    >
                        <input
                            className={cn('checkbox__control-inner')}
                            type="checkbox"
                            id={id}
                            checked={checked}
                            disabled={isDisabled}
                            readOnly
                        />
                        <div
                            className={cn(
                                'checkbox__control-background',
                                {
                                    'checkbox__control-background--initial': isInitial,
                                    'checkbox__control-background--checked': isChecked,
                                    'checkbox__control-background--disabled': isDisabled,
                                    'checkbox__control-background--indeterminate': isIndeterminate,
                                }
                            )}
                        >
                            {isChecked && (<Icons.CHECK />)}
                            {isIndeterminate && (<Icons.REMOVE />)}
                        </div>
                    </div>
                    <label className={cn(`checkbox__control-label${isDisabled ? '--disabled' : ''}`)} htmlFor={id}>
                        {label}
                    </label>
                </div>
            </div>
        );
    }
}
