import {isNil} from 'lodash-es';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {Step4FormInterface} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {ContractDictionaries, ContractStepsFormDataInterface} from '@app/store/types';
import {OwnerLawTypes} from '@app/types';
import {getFullName} from '@modules/contract';
import {DateFormats, formatDate} from '@utils/date';
import {formatPhoneNumber} from '@utils/formatters';
import {convertToTimeHHMM, toLocalTimeZone} from '@utils/formatters/date';
import {getOptionLabel} from '@utils/formatters/select-option';
import {createTranslator} from '@utils/i18n';
import {joinWithSpace} from '@utils/functions';

const t = createTranslator(dictionary);

export const convertContractToFormValues = ({
    insurer,
    owner,
    drivers,
    vehicle,
    addresses,
}: ContractStepsFormDataInterface,
dictionaries: ContractDictionaries): Step4FormInterface => {
    const DRIVER_1_DATA = drivers?.driversList && drivers.driversList[0];
    const DRIVER_2_DATA = drivers?.driversList && drivers.driversList[1];
    const DRIVER_3_DATA = drivers?.driversList && drivers.driversList[2];
    const DRIVER_4_DATA = drivers?.driversList && drivers.driversList[3];
    const insuranceStartDate = vehicle?.insuranceStartDate &&
        formatDate(toLocalTimeZone(vehicle.insuranceStartDate), DateFormats.DDMMYYYY_DOT);
    const insuranceEndDate = vehicle?.insuranceEndDate &&
        formatDate(toLocalTimeZone(vehicle.insuranceEndDate), DateFormats.DDMMYYYY_DOT);
    const insuranceStartTime = vehicle?.insuranceStartDate &&
        convertToTimeHHMM(new Date(toLocalTimeZone(vehicle.insuranceStartDate)));
    const insuranceEndTime = vehicle?.insuranceEndDate &&
        convertToTimeHHMM(new Date(toLocalTimeZone(vehicle.insuranceEndDate)));
    const usePeriod1StartDate = vehicle?.usePeriod1StartDate &&
        formatDate(toLocalTimeZone(vehicle.usePeriod1StartDate), DateFormats.DDMMYYYY_DOT);
    const usePeriod1EndDate = vehicle?.usePeriod1EndDate &&
        formatDate(toLocalTimeZone(vehicle.usePeriod1EndDate), DateFormats.DDMMYYYY_DOT);
    const usePeriod2StartDate = vehicle?.usePeriod2StartDate &&
        formatDate(toLocalTimeZone(vehicle.usePeriod2StartDate), DateFormats.DDMMYYYY_DOT);
    const usePeriod2EndDate = vehicle?.usePeriod2EndDate &&
        formatDate(toLocalTimeZone(vehicle.usePeriod2EndDate), DateFormats.DDMMYYYY_DOT);
    const usePeriod3StartDate = vehicle?.usePeriod3StartDate &&
        formatDate(toLocalTimeZone(vehicle.usePeriod3StartDate), DateFormats.DDMMYYYY_DOT);
    const usePeriod3EndDate = vehicle?.usePeriod3EndDate &&
        formatDate(toLocalTimeZone(vehicle.usePeriod3EndDate), DateFormats.DDMMYYYY_DOT);

    const insurerDocumentType = getOptionLabel(dictionaries.identityCardTypes, insurer?.document?.docType);
    const ownerDocumentType = getOptionLabel(dictionaries.identityCardTypes, owner?.personDocument?.docType);
    const legalOwnerDocumentType = getOptionLabel(
        dictionaries.organizationRegDocTypes,
        owner?.legalPersonDocument?.documentType
    );
    const vehicleDocumentType = getOptionLabel(dictionaries.vehicleDocumentTypes, vehicle?.document?.documentType);
    const vehiclePurposeType = getOptionLabel(dictionaries.vehiclePurposeTypes, vehicle?.usePurpose);

    const insurerDocumentSeries = insurer?.document?.docSeries ? `${insurer.document.docSeries} ` : '';
    const ownerDocumentSeries = owner?.personDocument?.docSeries ? `${owner.personDocument.docSeries} ` : '';
    const ownerLegalDocumentSeries = owner?.legalPersonDocument?.documentSeries ?
        `${owner.legalPersonDocument.documentSeries} ` :
        '';

    return {
        insurerFullName: insurer?.person && getFullName(insurer.person),
        insurerBirthdate: insurer?.person &&
            formatDate(insurer.person.birthdate, DateFormats.DDMMYYYY_DOT),
        insurerDocType: insurer?.document &&
            `${insurerDocumentType}: ${insurerDocumentSeries}${insurer.document.docNumber}`,
        insurerFullAddress: addresses.insurerFullAddress,
        insurerPhoneNumber: formatPhoneNumber(insurer?.person?.phoneNumber),
        insurerSnils: insurer?.person && insurer?.person?.snils,

        ownerType: owner?.ownerType,
        ownerTypeDisplayName: owner?.ownerType && owner?.ownerType === OwnerLawTypes.PHYSICAL ?
            t('form-container-physical') :
            t('form-container-legal'),
        ownerFullName: owner?.person && getFullName(owner.person),
        ownerBirthdate: owner?.person && owner?.person?.birthdate &&
            formatDate(owner.person.birthdate, DateFormats.DDMMYYYY_DOT),
        ownerDocType: owner?.personDocument &&
            `${
                ownerDocumentType || ''
            }: ${ownerDocumentSeries}${owner.personDocument.docNumber || ''}`,
        ownerFullAddress: addresses.ownerFullAddress,
        ownerSnils: owner?.person && owner?.person?.snils,

        legalOwnerTypeDisplayName: owner?.ownerType && owner?.ownerType === OwnerLawTypes.PHYSICAL ?
            t('form-container-physical') :
            t('form-container-legal'),
        legalOwnerINN: owner?.legalPerson && owner.legalPerson.INN,
        legalOwnerFullName: owner?.legalPerson && owner.legalPerson.fullName,
        legalOwnerDocType: owner?.legalPersonDocument &&
            `${
                legalOwnerDocumentType || ''
            }: ${ownerLegalDocumentSeries}${owner?.legalPersonDocument?.documentNumber || ''}`,
        legalOwnerAccountingDate: owner?.legalPersonDocument?.accountingDate &&
            formatDate(toLocalTimeZone(owner.legalPersonDocument.accountingDate), DateFormats.DDMMYYYY_DOT),
        drivers1FullName: DRIVER_1_DATA?.person && getFullName(DRIVER_1_DATA.person),
        drivers1Birthdate: DRIVER_1_DATA?.person && DRIVER_1_DATA?.person?.birthdate &&
            formatDate(DRIVER_1_DATA?.person.birthdate, DateFormats.DDMMYYYY_DOT),
        drivers1LicenseDrivers: DRIVER_1_DATA?.driverLicense &&
            joinWithSpace([
                DRIVER_1_DATA.driverLicense.series,
                DRIVER_1_DATA.driverLicense.number,
            ]),
        drivers1StartExperience: DRIVER_1_DATA?.driverLicense &&
            formatDate(toLocalTimeZone(DRIVER_1_DATA.driverLicense.startDate), DateFormats.DDMMYYYY_DOT),
        drivers2FullName: DRIVER_2_DATA?.person && getFullName(DRIVER_2_DATA.person),
        drivers2Birthdate: DRIVER_2_DATA?.person && DRIVER_2_DATA?.person.birthdate &&
            formatDate(DRIVER_2_DATA?.person.birthdate, DateFormats.DDMMYYYY_DOT),
        drivers2LicenseDrivers: DRIVER_2_DATA?.driverLicense &&
            joinWithSpace([
                DRIVER_2_DATA.driverLicense.series,
                DRIVER_2_DATA.driverLicense.number,
            ]),
        drivers2StartExperience: DRIVER_2_DATA?.driverLicense &&
            formatDate(toLocalTimeZone(DRIVER_2_DATA.driverLicense.startDate), DateFormats.DDMMYYYY_DOT),
        drivers3FullName: DRIVER_3_DATA?.person && getFullName(DRIVER_3_DATA.person),
        drivers3Birthdate: DRIVER_3_DATA?.person && DRIVER_3_DATA?.person.birthdate &&
            formatDate(DRIVER_3_DATA?.person.birthdate, DateFormats.DDMMYYYY_DOT),
        drivers3LicenseDrivers: DRIVER_3_DATA?.driverLicense &&
            joinWithSpace([
                DRIVER_3_DATA.driverLicense.series,
                DRIVER_3_DATA.driverLicense.number,
            ]),
        drivers3StartExperience: DRIVER_3_DATA?.driverLicense &&
            formatDate(toLocalTimeZone(DRIVER_3_DATA.driverLicense.startDate), DateFormats.DDMMYYYY_DOT),
        drivers4FullName: DRIVER_4_DATA?.person && getFullName(DRIVER_4_DATA.person),
        drivers4Birthdate: DRIVER_4_DATA?.person && DRIVER_4_DATA?.person.birthdate &&
            formatDate(DRIVER_4_DATA?.person.birthdate, DateFormats.DDMMYYYY_DOT),
        drivers4LicenseDrivers: DRIVER_4_DATA?.driverLicense &&
            joinWithSpace([
                DRIVER_4_DATA.driverLicense.series,
                DRIVER_4_DATA.driverLicense.number,
            ]),
        drivers4StartExperience: DRIVER_4_DATA?.driverLicense &&
            formatDate(toLocalTimeZone(DRIVER_4_DATA.driverLicense.startDate), DateFormats.DDMMYYYY_DOT),

        vehicleCarMaker: vehicle?.otherMaker || vehicle?.maker?.name,
        vehicleCarModel: vehicle?.otherModel || vehicle?.model?.name,
        vehicleSeatsAmount: vehicle?.seatsAmount,
        vehicleMaxWeight: vehicle?.maxWeight,
        vehicleTypeCategoryFullName: vehicle?.typeCategory?.fullName,
        vehicleCarYear: String(vehicle?.year),
        vehiclePower: vehicle?.power ? `${vehicle.power} л.с.` : null,
        vehicleDocType: vehicle?.document &&
        `${
            vehicleDocumentType || ''
        }: ${vehicle.document.series} ${vehicle.document.number || ''}`,
        vehicleDateGiven: vehicle?.document &&
            formatDate(toLocalTimeZone(vehicle.document.dateGiven), DateFormats.DDMMYYYY_DOT),
        vehicleDiagnosticCard: vehicle?.diagnosticCard?.number,
        vehicleDiagnosticCardDateEnd: (vehicle?.diagnosticCard?.dateEnd) ?
            formatDate(toLocalTimeZone(vehicle?.diagnosticCard?.dateEnd), DateFormats.DDMMYYYY_DOT) :
            null,
        vehicleVin: vehicle?.vin ? vehicle?.vin : 'Отсутствует',
        vehicleBodyNumber: vehicle?.bodyNumber ? vehicle?.bodyNumber : 'Отсутствует',
        vehicleSchassisNumber: vehicle?.schassisNumber ? vehicle?.schassisNumber : 'Отсутствует',
        vehicleNumberPlate: vehicle?.numberPlate ? vehicle?.numberPlate : 'Не поставлено на учёт',
        vehicleUsePurpose: vehicle?.usePurpose && vehiclePurposeType,
        vehicleIsUsedWithTrailer: vehicle?.isUsedWithTrailer ? 'Да' : 'Нет',
        vehicleRegistrationCountry: vehicle && vehicle.registrationCountry,
        vehicleIsOnTheWayToRegistration: vehicle?.isOnTheWayToRegistration ? 'Да' : 'Нет',
        insurancePeriodStart: vehicle && `с ${insuranceStartTime} ${insuranceStartDate}`,
        insurancePeriodEnd: vehicle && `по ${insuranceEndTime} ${insuranceEndDate}`,
        usePeriod1: (!isNil(vehicle?.usePeriod1) && `с ${usePeriod1StartDate} по ${usePeriod1EndDate}`) || null,
        usePeriod2: (!isNil(vehicle?.usePeriod2) && `с ${usePeriod2StartDate} по ${usePeriod2EndDate}`) || null,
        usePeriod3: (!isNil(vehicle?.usePeriod3) && `с ${usePeriod3StartDate} по ${usePeriod3EndDate}`) || null,
        vehicleTypeCategory: vehicle?.typeCategory,
        vehicleIsShortInsurancePolicy: vehicle?.isShortInsurancePolicy,
    };
};
