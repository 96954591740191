import {NavigationInterface} from '@app/store/types';
import {NavigationState} from '@app/types';
import {handleActions} from '@utils/redux';
import {ActionTypes} from '@utils/types';

import {NAVIGATION} from '../model-constants';

const navigationInitialState: NavigationInterface = {
    isActiveRegisterBtnAgree: false,
    isActiveRegisterBtnAware: false,
    isAgreementBtnEnabled: false,
    isValidationChecked: false,
    isUpdateScansStatusDisabled: false,
    isCheckDataBtnHidden: false,
    isNavigationShown: false,
    isNotFound: false,
    navigationResult: null,
    currentStep: 0,
    stepAfterSubmit: null,
    isLkBlocked: false,
    isUploadedFilesBtnEnabled: false,
};

const navigationHandler = handleActions(NAVIGATION);

export const navigationReducer = navigationHandler({
    [ActionTypes.SET_IS_ACTIVE_REGISTER_BTN_AGREE]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isActiveRegisterBtnAgree: payload,
    }),

    [ActionTypes.SET_IS_CHECK_DATA_BTN_HIDDEN]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isCheckDataBtnHidden: payload,
    }),

    [ActionTypes.SET_IS_ACTIVE_REGISTER_BTN_AWARE]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isActiveRegisterBtnAware: payload,
    }),

    [ActionTypes.SET_IS_AGREEMENT_BTN_ENABLED]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isAgreementBtnEnabled: payload,
    }),

    [ActionTypes.SET_IS_VALIDATION_CHECKED]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isValidationChecked: payload,
    }),

    [ActionTypes.SET_IS_UPDATE_SCANS_STATUS_DISABLED]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isUpdateScansStatusDisabled: payload,
    }),

    [ActionTypes.SET_IS_NAVIGATION_SHOWN]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isNavigationShown: payload,
    }),

    [ActionTypes.SET_IS_NOT_FOUND]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isNotFound: payload,
    }),

    [ActionTypes.SET_NAVIGATION_RESULT]: (state: NavigationInterface, {payload}: {payload: NavigationState}) => ({
        ...state,
        navigationResult: payload,
    }),

    [ActionTypes.SET_CURRENT_STEP]: (state: NavigationInterface, {payload}: {payload: number}) => ({
        ...state, currentStep: payload,
    }),

    [ActionTypes.SET_STEP_AFTER_SUBMIT]: (state: NavigationInterface, {payload}: {payload: number}) => ({
        ...state, stepAfterSubmit: payload,
    }),

    [ActionTypes.SET_IS_LK_BLOCKED_TRUE]: (state: NavigationInterface) => ({
        ...state,
        isLkBlocked: true,
    }),

    [ActionTypes.SET_IS_UPLOADED_FILES_BTN_ENABLED]: (state: NavigationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isUploadedFilesBtnEnabled: payload,
    }),
}, navigationInitialState);
