import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {dictionariesSelector} from '@modules/contract';
import {validationResultScansEditFieldsSelector} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {RightPart} from './right-part';

const mapStateToProps = createStructuredSelector({
    dictionaries: dictionariesSelector,
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const RightPartContainer = connect(
    mapStateToProps
)(RightPart);
