import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './accordion.scss';

const cn = classNames.bind(styles);

export interface AccordionItemProps {
    body: React.ReactNode,
    header: React.ReactNode,
    onStateChange: () => void,
    opened: boolean,
    panelContent: React.ReactNode,
    title: React.ReactNode
}

export function AccordionItem({
    body,
    header,
    onStateChange,
    opened,
    panelContent,
    title,
}: AccordionItemProps) {
    return (
        <div className={cn('accordion__item', opened && 'accordion__item--opened')}>
            <div
                className={cn('accordion__item-wrap')}
                onClick={onStateChange}
                role="button"
            >
                <div className={cn('accordion__header', opened && 'accordion__header--opened')}>
                    {
                        header || (
                            <>
                                <div className={cn('accordion__title')}>
                                    {title}
                                </div>
                                <div className={cn('accordion__icons')}>
                                    {panelContent && (
                                        <div className={cn('accordion__panel-content')}>
                                            {panelContent}
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    }
                </div>
                {body && (
                    <div className={cn('accordion__body')}>
                        {body}
                    </div>
                )}
            </div>
        </div>
    );
}
