import classNames from 'classnames/bind';
import * as React from 'react';

import {AboutPropsInterface} from '@app/pages/agreement-page/agreement-page-types';
import {Accordion} from '@ui-kit';

import * as styles from './about.scss';

const cn = classNames.bind(styles);

export function About({title, items}: AboutPropsInterface) {
    const [openedItem, setOpenedItem] = React.useState(null);

    return (
        <div className={cn('about')}>
            <h2 className={cn('about__header')}>
                {title}
            </h2>
            <div className={cn('about__accordion')}>
                <Accordion
                    items={items}
                    getTitle={({title}) => title}
                    getBody={item => item.body}
                    onPanelStateChange={e => setOpenedItem(e === openedItem ? null : e)}
                    openedItem={openedItem}
                />
            </div>
        </div>
    );
}
