import * as React from 'react';

import {Column} from '@ui-kit';

import {MENU} from '../common/data-table-hoc-constants';
import {ColumnsButton} from './columns-button';

const getInitialColumnsValues = (columns: Column[]) => columns.reduce(
    (acc: any, column: Column) => {
        acc[column.name] = !column.hidden;
        return acc;
    }, {}
);

export function withColumnsButton(WrappedComponent: React.ComponentType<JSX.Element>) {
    function EnhancedComponent({
        value,
        updateColumnsStatuses,
        configurableColumns,
        hasColumnsButton,
        ...restProps
    }: any) {
        const {name} = restProps;
        const cellValue = (hasColumnsButton && name === MENU) ?
            (
                <ColumnsButton
                    columns={configurableColumns}
                    updateColumns={updateColumnsStatuses}
                    initialValues={getInitialColumnsValues(configurableColumns)}
                />
            ) :
            value;

        return (
            <WrappedComponent
                {...restProps}
                value={cellValue}
            />
        );
    }

    EnhancedComponent.displayName = `withColumnsButton(${WrappedComponent.displayName || WrappedComponent.name})`;

    return EnhancedComponent;
}
