import * as React from 'react';
import {FieldRenderProps} from 'react-final-form';

interface FieldAdapterProps extends FieldRenderProps<any, any> {
    label: string;
    required?: boolean;
    onChange?: Function;
}

export const fieldAdapter = (WrapperComponent: React.ComponentType<any>) => {
    function FieldAdapter({input, meta, ...restProps}: FieldAdapterProps) {
        const highlightError = !meta.valid && meta.touched ? meta.error : undefined;
        const highlightWarning = meta.data?.warning;

        const inputProps = {
            ...input,
            onChange: (e: any) => {
                input.onChange(e);
                if (restProps.onChange) {
                    restProps.onChange(e);
                }
            },
        };
        return (
            <WrapperComponent
                {...restProps}
                {...inputProps}
                errorMessage={highlightWarning || highlightError}
                isValid={!(highlightWarning || highlightError)}
            />
        );
    }

    FieldAdapter.displayName = `FieldAdapter(${WrapperComponent && (
        WrapperComponent.displayName || WrapperComponent.name
    )})`;
    return FieldAdapter;
};
