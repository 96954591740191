import * as React from 'react';

interface BodyProps {
    children: React.ReactNode
}

export function Body({children}: BodyProps) {
    return (
        <>{children}</>
    );
}
