import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import * as styles from '@app/pages/contract-page/pages/step-2/components/right-part/right-part.scss';
import {Period, MONTHS} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods/use-period-utils';
import {DateField, SelectField} from '@ui-kit';
import {SelectOption} from '@utils/formatters/select-option';
import {createTranslator} from '@utils/i18n';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface UsePeriodSelectorsInterface {
    period: Period;
    index: number;
    maxMonthPeriod: number;
    isOnTheWayToRegistration: boolean;
    handlePeriodChange: (value: SelectOption<number>, index: number) => void;
    handleStartDateChange: (value: string, index: number) => void;
    getAvailableDates: (interval: SelectOption<number>, index: number) => [Date, Date];
    disabled: boolean;
}

export const periodOptions: SelectOption<number>[] = [
    {label: t('3-months'), value: MONTHS.THREE},
    {label: t('4-months'), value: MONTHS.FOUR},
    {label: t('5-months'), value: MONTHS.FIVE},
    {label: t('6-months'), value: MONTHS.SIX},
    {label: t('7-months'), value: MONTHS.SEVEN},
    {label: t('8-months'), value: MONTHS.EIGHT},
    {label: t('9-months'), value: MONTHS.NINE},
    {label: t('10-months'), value: MONTHS.TEN},
    {label: t('11-months'), value: MONTHS.ELEVEN},
    {label: t('12-months'), value: MONTHS.TWELVE},
];

export const UsePeriodSelectors = ({
    period,
    index,
    maxMonthPeriod,
    isOnTheWayToRegistration,
    handlePeriodChange,
    handleStartDateChange,
    getAvailableDates,
    disabled,
}: UsePeriodSelectorsInterface) => {
    const options = periodOptions.filter(option => Number(option.value) <= maxMonthPeriod);
    return (
        <div className={cn('right-part__use-period-wrapper')}>
            {
                !isOnTheWayToRegistration && (
                    <div className={cn('right-part__form-field')}>
                        <SelectField
                            options={options}
                            name={`usePeriod${index + 1}`}
                            label={t('right-age', index + 1)}
                            onChange={(value: SelectOption<number>) => handlePeriodChange(value, index)}
                            disabled={disabled}
                            required
                        />
                    </div>
                )
            }
            <div className={cn('right-part__form-field')}>
                <DateField
                    label={t('right-start-date')}
                    name={`usePeriod${index + 1}StartDate`}
                    onChange={date => handleStartDateChange(date, index)}
                    isDisabled={disabled}
                    availableDates={getAvailableDates(period.interval, index)}
                    required
                />
            </div>
            <span>&mdash;</span>
            <div className={cn('right-part__form-field')}>
                <DateField
                    label={t('right-end-date')}
                    name={`usePeriod${index + 1}EndDate`}
                    isDisabled
                />
            </div>
        </div>
    );
};

UsePeriodSelectors.displayName = 'UsePeriodSelectors';
