import {createTranslator} from '@utils/i18n';

import * as dictionary from './common-modals.dic.json';

const t = createTranslator(dictionary);

enum GENDERS {
    NEUTER = 'NEUTER',
    FEMININE = 'FEMININE',
    MASCULINE = 'MASCULINE'
}

export const CONFIRM_DELETE_GENDERS = {
    [GENDERS.NEUTER]: t('confirm.delete-modal.gender.neuter'),
    [GENDERS.FEMININE]: t('confirm.delete-modal.gender.feminine'),
    [GENDERS.MASCULINE]: t('confirm.delete-modal.gender.masculine'),
};
