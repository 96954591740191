import classNames from 'classnames/bind';
import * as React from 'react';

import {
    addDays,
    subtractDays,
    isDateBefore,
} from '@utils/date';
import {noop} from '@utils/functions';

import {CalendarsManager} from '../calendars-manager';
import {
    Sidebar,
    Footer,
} from './components';
import {SelectingDatesInRangeTypes} from './date-range-picker-menu.constants';

import * as styles from './date-range-picker-menu.scss';

const cn = classNames.bind(styles);

export interface DateRangePickerMenuProps {
    selectedStartDate?: Date;
    selectedEndDate?: Date;
    onChange: (startDate: string, endDate: string, closeCalendar?: boolean) => void;
    onKeyDown: (keyCode: number) => void;
    onDisplayChange: (height: number) => void;
}

interface DateRangePickerMenuState {
    selectingDateInRange: SelectingDatesInRangeTypes;
    selectedStartDate: Date;
    selectedEndDate: Date;
    prevSelectedStartDate: Date;
    prevSelectedEndDate: Date;
}

export class DateRangePickerMenu extends React.PureComponent<DateRangePickerMenuProps, DateRangePickerMenuState> {
    dateRangePickerMenuRef = React.createRef<HTMLDivElement>();

    calendarsContainerRef: HTMLElement = null;

    static defaultProps: DateRangePickerMenuProps = {
        onChange: noop,
        onKeyDown: noop,
        onDisplayChange: noop,
    };

    state: DateRangePickerMenuState = {
        selectingDateInRange: null,
        selectedStartDate: null,
        selectedEndDate: null,
        prevSelectedStartDate: null,
        prevSelectedEndDate: null,
    };


    componentDidMount() {
        const {onDisplayChange} = this.props;
        const dateRangePickerMenu = this.dateRangePickerMenuRef.current;

        if (dateRangePickerMenu) {
            onDisplayChange(dateRangePickerMenu.getBoundingClientRect().height);
        }

        const frameId = requestAnimationFrame(() => {
            cancelAnimationFrame(frameId);
            this.calendarsContainerRef.focus();
        });
    }

    static getDerivedStateFromProps({
        selectedStartDate,
        selectedEndDate,
    }: DateRangePickerMenuProps, state: DateRangePickerMenuState) {
        if (selectedStartDate !== state.prevSelectedStartDate || selectedEndDate !== state.prevSelectedEndDate) {
            const selectingDateInRange = selectedStartDate && !selectedEndDate ?
                SelectingDatesInRangeTypes.END_DATE :
                SelectingDatesInRangeTypes.START_DATE;

            return {
                selectingDateInRange: state.selectingDateInRange || selectingDateInRange,
                prevSelectedStartDate: selectedStartDate,
                prevSelectedEndDate: selectedEndDate,
                selectedStartDate,
                selectedEndDate,
            };
        }
        return null;
    }

    handleSelectDate = (date: Date, dateChangedByKeyboard?: boolean) => {
        this.setState(({
            selectingDateInRange,
            selectedStartDate,
            selectedEndDate,
        }) => {
            const setStartDate = !selectedStartDate ||
                isDateBefore(date, addDays(selectedStartDate, 1)) ||
                (selectingDateInRange === SelectingDatesInRangeTypes.START_DATE &&
                    selectedEndDate &&
                    isDateBefore(date, subtractDays(selectedEndDate, 1))
                );
            const setEndDate = !setStartDate;

            return {
                selectingDateInRange: setStartDate ?
                    SelectingDatesInRangeTypes.END_DATE :
                    SelectingDatesInRangeTypes.START_DATE,
                selectedStartDate: setStartDate ? date : selectedStartDate,
                selectedEndDate: setEndDate ? date : selectedEndDate,
            };
        }, () => {
            const {selectedEndDate} = this.state;
            if (dateChangedByKeyboard && selectedEndDate) {
                this.handleApplySelectedDates();
            }
        });
    };

    handleClearSelectedDates = () => {
        const {onChange} = this.props;

        this.setState({
            selectingDateInRange: SelectingDatesInRangeTypes.START_DATE,
            selectedStartDate: null,
            selectedEndDate: null,
        });
        onChange(null, null);
    };

    handleDateRangeFilterSelect = (
        startDate: Date,
        endDate?: Date
    ) => {
        const {onChange} = this.props;

        onChange(
            startDate.toISOString(),
            endDate && endDate.toISOString(),
            true
        );
    };

    handleApplySelectedDates = () => {
        const {
            selectedStartDate,
            selectedEndDate,
        } = this.state;
        const {onChange} = this.props;
        const startDate = selectedStartDate && selectedStartDate.toISOString();
        const endDate = selectedEndDate && selectedEndDate.toISOString();

        onChange(startDate, endDate, true);
    };

    handleCalendarsManagerRef = (ref: HTMLElement) => {
        this.calendarsContainerRef = ref;
    };

    render() {
        const {
            selectedStartDate,
            selectedEndDate,
        } = this.state;
        const {onKeyDown} = this.props;

        return (
            <div
                className={cn('date-range-picker-menu')}
                ref={this.dateRangePickerMenuRef}
            >
                <CalendarsManager
                    firstSelectedDate={selectedStartDate}
                    secondSelectedDate={selectedEndDate}
                    onChange={this.handleSelectDate}
                    onKeyDown={onKeyDown}
                    onClearSelectedDates={this.handleClearSelectedDates}
                    withRef={this.handleCalendarsManagerRef}
                    rangeSelectingMode
                />
                <Sidebar
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    onChange={this.handleDateRangeFilterSelect}
                />
                <Footer
                    onClearSelectedDates={this.handleClearSelectedDates}
                    onApplySelectedDates={this.handleApplySelectedDates}
                />
            </div>
        );
    }
}
