import {CompaniesInterface} from '@app/store/types';
import {
    LinkData,
    InsuranceCompany,
} from '@app/types';

export const regionNameMock = 'г. Москва';
export const companyPremiumMock: number = 1222.00;
export const skDomainMock: string = 'alfa';
export const representativesLinkMock = 'http://representatives.autoins.ru/all/?company=00800000' +
    '&addr=7700000000000&rate=1000.98';

export const companiesMock: InsuranceCompany[] = [
    {
        id: '1',
        name: 'АО «АльфаСтрахование',
        link: 'https://www.alfastrah.ru/',
        isActive: true,
        contractsLeft: 15,
        premium: 1222.00,
        skDomain: 'alfa',
        searchName: '«АльфаСтрахование»',
        legalOrgForm: 'АО',
        formalPartName: '',
    },
    {
        id: '2',
        name: 'СОАО «ВСК»',
        link: 'https://www.vsk.ru/',
        isActive: true,
        contractsLeft: 1,
        premium: 12222.88,
        skDomain: 'vsk',
        searchName: '«ВСК»',
        legalOrgForm: 'СОАО',
        formalPartName: '',
    },
    {
        id: '3',
        name: 'Ингосстрах',
        link: 'https://www.ingos.ru/',
        isActive: false,
        contractsLeft: 8,
        premium: 32223.00,
        skDomain: 'ingos',
        searchName: 'Ингосстрах',
        legalOrgForm: '',
        formalPartName: '',
    },
    {
        id: '4',
        name: 'Страховая группа УРАЛсиб',
        link: 'https://uralsibins.ru/',
        isActive: true,
        contractsLeft: 10,
        premium: 2222.06,
        skDomain: 'uralsibins',
        searchName: 'УРАЛсиб',
        legalOrgForm: '',
        formalPartName: '',
    },
    {
        id: '5',
        name: 'Ренессанс-страхование',
        link: 'https://www.renins.ru/',
        isActive: true,
        contractsLeft: 35,
        premium: 22222.76,
        skDomain: 'renins',
        searchName: 'Ренессанс-страхование',
        legalOrgForm: '',
        formalPartName: '',
    },
];

export const companiesStateMock: CompaniesInterface = {
    companiesList: [],
    filterableCompaniesList: [],
    insCompany: null,
    companyPremium: null,
    linkData: null,
    regionName: null,
    isSecondCompanyChosen: null,
};

export const linkDataEmptyMock: LinkData = {
    rsaBaseUrl: '',
    insCompanyId: '',
    insCompanyDikbmId: '',
    insCompanyName: '',
    insCompanyUrl: 'string',
    vehicleUsageAreaFiasCode: 'string',
    baseRate: 123,
    hasAgreement: true,
};

export const linkDataMock: LinkData = {
    rsaBaseUrl: 'http://representatives.autoins.ru',
    insCompanyId: '1209',
    insCompanyDikbmId: '00800000',
    insCompanyName: 'Страховой дом ВСК',
    insCompanyUrl: 'http://www.vsk.ru',
    vehicleUsageAreaFiasCode: '7700000000000',
    baseRate: 1000.98,
    hasAgreement: false,
};

export const insCompanyMock: InsuranceCompany | null = {
    id: '1',
    name: 'АО «АльфаСтрахование',
    link: 'https://www.alfastrah.ru/',
    isActive: true,
    contractsLeft: 15,
    premium: 1222.00,
    skDomain: 'alfa',
    searchName: '«АльфаСтрахование»',
    legalOrgForm: 'АО',
    formalPartName: '',
};
