import {loadCalculatedTableData} from '@app/pages/contract-page/contract-page-actions';
import {getUniqueDocumentTypes} from '@app/utils/formatters';
import {executeAsyncAction} from '@modules/model/actions';
import {VALIDATION} from '@app/store/model-constants';
import {
    ADDRESS_TEXT,
    AppStages,
    ADDRESS,
    Steps,
} from '@app/utils/constants';
import {
    setAutoAcceptHeartbeatStopped,
    updateUploadedFilesStatuses,
    setIsCheckDataBtnHidden,
    updateContractStage,
    setCurrentStage,
    setTimer,
} from '@app/store/actions';
import {listFieldEntitiesEnabled} from '@utils/fields-group-enabled';
import {currentStepSelector} from '@modules/navigation';
import {currentStageSelector} from '@modules/stage';
import {ActionTypes} from '@utils/types';
import {
    validationResultScansSelector,
    contractValidationRequestGet,
    contractValidationRequestPut,
    countStepsToValidateEntities,
    isAllScansApprovedSelector,
    MappedValidationInterface,
    validationDataSelector,
    mapFetchedValidation,
} from '@modules/validation';
import {
    uploadedDocumentFilesSelector,
    hasVehicleDocumentRequired,
    UploadedDocumentFile,
    driverMeetsSelector,
    vehicleSelector,
} from '@modules/contract';
import {
    createActionFactory,
    AsyncActionType,
    DispatchType,
} from '@utils/redux';
import {
    ValidationEntities,
    ValidationFields,
    ValidationData,
    DocumentTypes,
    MismatchData,
    Vehicle,
} from '@app/types';

const validationActionCreator = createActionFactory(VALIDATION);

export const setValidationInitiated = validationActionCreator(ActionTypes.SET_VALIDATION_INITIATED);
export const setValidationResult = validationActionCreator<Partial<ValidationData>>(
    ActionTypes.SET_VALIDATION_RESULT
);

export const setStepsToValidate =
    validationActionCreator<number[]>(ActionTypes.SET_STEPS_TO_VALIDATE);
export const setMappedValidation =
    validationActionCreator<MappedValidationInterface>(ActionTypes.SET_MAPPED_VALIDATION);
export const setIsValidationInitiated = validationActionCreator<Boolean>(
    ActionTypes.SET_IS_VALIDATION_INITIATED
);
export const setScansToEdit = validationActionCreator<string[]>(
    ActionTypes.SET_SCANS_TO_EDIT
);
export const setValidationResultErrorFields = validationActionCreator<String[]>(
    ActionTypes.SET_VALIDATION_RESULT_ERROR_FIELDS
);
export const setIsAllScansApproved = validationActionCreator<Boolean>(ActionTypes.SET_IS_ALL_SCANS_APPROVED);
export const setIsScansInProgress = validationActionCreator<Boolean>(ActionTypes.SET_IS_SCANS_IN_PROGRESS);
export const setIsScanSendingInitiated = validationActionCreator<Boolean>(ActionTypes.SET_IS_SCAN_SENDING_INITIATED);
export const setIsScanEditingInitiated = validationActionCreator<Boolean>(ActionTypes.SET_IS_SCAN_EDITING_INITIATED);
export const setIsScansPollingInitiated = validationActionCreator<Boolean>(ActionTypes.SET_IS_SCANS_POLLING_INITIATED);
export const setIsValidationWaiting = validationActionCreator<Boolean>(ActionTypes.SET_IS_VALIDATION_WAITING);
export const setIsValidationInProgress = validationActionCreator<Boolean>(ActionTypes.SET_IS_VALIDATION_IN_PROGRESS);
export const setInsurerAddressWarningMessage = validationActionCreator<string>(
    ActionTypes.SET_INSURER_ADDRESS_WARNING_MESSAGE
);
export const setOwnerAddressWarningMessage = validationActionCreator<string>(
    ActionTypes.SET_OWNER_ADDRESS_WARNING_MESSAGE
);
export const setInsurerAddressTextWarningMessage = validationActionCreator<string>(
    ActionTypes.SET_INSURER_ADDRESS_TEXT_WARNING_MESSAGE
);
export const setOwnerAddressTextWarningMessage = validationActionCreator<string>(
    ActionTypes.SET_OWNER_ADDRESS_TEXT_WARNING_MESSAGE
);

type kitNameType = ValidationEntities.INSURER | ValidationEntities.OWNER;
const findAddressMismatchMessage = (validationResultFields: ValidationFields, kitName: kitNameType): string => {
    const dataKit: MismatchData[] = validationResultFields[kitName];
    const addressMismatchData = dataKit.find(({mismatchFields}) => (
        mismatchFields.some(({fieldName}) => fieldName === ADDRESS)
    ));
    return addressMismatchData?.message;
};

const findAddressTextMismatchMessage = (validationResultFields: ValidationFields, kitName: kitNameType): string => {
    const dataKit: MismatchData[] = validationResultFields[kitName];
    const addressTextMismatchData = dataKit.find(({mismatchFields}) => (
        mismatchFields.some(({fieldName}) => fieldName === ADDRESS_TEXT)
    ));
    return addressTextMismatchData?.message;
};

export const handleInsurerAddressMismatch = (validationResultFields: ValidationFields): DispatchType => (
    (dispatch: DispatchType) => {
        const addressMessage = findAddressMismatchMessage(validationResultFields, ValidationEntities.INSURER);
        dispatch(setInsurerAddressWarningMessage(addressMessage));
        const addressTextMessage = findAddressTextMismatchMessage(validationResultFields, ValidationEntities.INSURER);
        dispatch(setInsurerAddressTextWarningMessage(addressTextMessage));
    }
);

export const handleOwnerAddressMismatch = (validationResultFields: ValidationFields): DispatchType => (
    (dispatch: DispatchType) => {
        const addressMessage = findAddressMismatchMessage(validationResultFields, ValidationEntities.OWNER);
        dispatch(setOwnerAddressWarningMessage(addressMessage));
        const addressTextMessage = findAddressTextMismatchMessage(validationResultFields, ValidationEntities.OWNER);
        dispatch(setOwnerAddressTextWarningMessage(addressTextMessage));
    }
);

export const mapValidationResult = (result: ValidationData): DispatchType => (
    (dispatch: DispatchType) => {
        dispatch(setMappedValidation(mapFetchedValidation(result)));
        dispatch(handleInsurerAddressMismatch(result.validationResultFields));
        dispatch(handleOwnerAddressMismatch(result.validationResultFields));
    }
);

const updateValidationState = (dispatch: DispatchType, result: ValidationData) => {
    dispatch(setIsValidationInitiated(result.isValidationInitiated));
    dispatch(setValidationResult(result));
    dispatch(mapValidationResult(result));
};

const getIsMoreFilesToUpload = (
    vehicle: Vehicle,
    validationResultScans: DocumentTypes[],
    uploadedDocumentFiles: UploadedDocumentFile[],
) => {
    // vehicleDocument - единственный документ, который может быть необязательным полностью (оба файла)
    const validationResultScansRequired = hasVehicleDocumentRequired(vehicle) ?
        validationResultScans :
        validationResultScans.filter((document: DocumentTypes) => document !== DocumentTypes.VEHICLE_DOCUMENT);
    const uniqueDocumentType = new Set();

    uploadedDocumentFiles.forEach((document: UploadedDocumentFile) => {
        uniqueDocumentType.add(document.documentType);
    });

    return validationResultScansRequired.some((document: DocumentTypes) => (
        !Array.from(uniqueDocumentType).includes(document)
    ));
};

export const contractValidationSnapshot = (contractId: string) => (
    (dispatch: DispatchType, getState: Function) => {
        const state = getState();

        const isAllScansApproved = isAllScansApprovedSelector(state);
        const uploadedDocumentFiles = uploadedDocumentFilesSelector(state);
        const validationResultScans = validationResultScansSelector(state);
        const currentStep = currentStepSelector(state);
        const vehicle = vehicleSelector(state);
        const currentStage = currentStageSelector(state);
        const driverMeets = driverMeetsSelector(state);

        if (currentStage !== AppStages.APPLICATION_DIRECTION &&
            currentStage !== AppStages.CHANGE_APPLICATION_DATA) {
            dispatch(setTimer({
                expirationTime: null,
                secondsLeft: null,
                flkCode: null,
                timerType: null,
            }));
        }

        dispatch(setIsValidationWaiting(true));
        return contractValidationRequestGet(contractId)
            .then((result: ValidationData) => {
                if (result.isValidationInProgress) {
                    dispatch(setIsValidationWaiting(false));
                }

                const uniqueDocumentTypes = getUniqueDocumentTypes(uploadedDocumentFiles);

                if (uniqueDocumentTypes.length) {
                    updateValidationState(dispatch, {
                        ...result,
                        validationResultScans: uniqueDocumentTypes,
                    });
                } else {
                    updateValidationState(dispatch, result);
                }

                if (!isAllScansApproved) {
                    dispatch(updateContractStage(result));
                }
                if (!result.isValidationInProgress) {
                    if (!result.isScanSendingInitiated) {
                        dispatch(
                            setStepsToValidate(
                                countStepsToValidateEntities(result.validationResultEntities)
                            )
                        );
                        const listFieldError = listFieldEntitiesEnabled(
                            result.validationResultEntities,
                            result.driverIndexes,
                            driverMeets,
                        );
                        dispatch(setValidationResultErrorFields(listFieldError));
                    } else if (currentStep !== Steps.FINAL_STEP && result.validationResultScans.length) {
                        dispatch(updateUploadedFilesStatuses(true));
                    }

                    const isCalculateRightAway = result.isValidationInitiated &&
                        result.validationResultEntities.length === 0 &&
                        result.validationResultScans.length === 0;

                    const isMoreFilesToUpload = getIsMoreFilesToUpload(
                        vehicle,
                        validationResultScans,
                        uploadedDocumentFiles
                    );

                    if (isCalculateRightAway) {
                        dispatch(loadCalculatedTableData(contractId));
                    }

                    if (isAllScansApproved && isMoreFilesToUpload) {
                        dispatch(setCurrentStage(AppStages.SCAN_ATTACHMENT));
                    }
                } else if (isAllScansApproved) {
                    dispatch(setIsScansPollingInitiated(true));
                }
            })
            .catch(() => {
                dispatch(setIsValidationInProgress(false));
                dispatch(setIsValidationWaiting(false));
                // Фикс бага: сброс надписи со спиннером после получени ошибки RAMIEPT-122212
                const validation = validationDataSelector(state);
                dispatch(updateContractStage(validation));
            });
    }
);

export function loadValidation(contractId: string): AsyncActionType {
    return (dispatch: DispatchType) => executeAsyncAction(dispatch,
        () => dispatch(contractValidationSnapshot(contractId)));
}

export const contractValidation = (contractId: string): DispatchType => (
    (dispatch: DispatchType) => {
        dispatch(setIsCheckDataBtnHidden(true));
        dispatch(setAutoAcceptHeartbeatStopped(false));

        return contractValidationRequestPut(contractId)
            .then(() => {
                window.scrollTo(0, 0);
                dispatch(setValidationInitiated());
                dispatch(setCurrentStage(AppStages.VALIDATION_IN_PROGRESS));
                dispatch(contractValidationSnapshot(contractId));
            });
    }
);
