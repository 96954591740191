import classNames from 'classnames/bind';
import * as React from 'react';
import CustomScrollbars, {positionValues} from 'react-custom-scrollbars';

import {noop} from '@utils/functions';

import * as styles from './scrollbars.scss';

export interface ScrollbarsProps {
    /** scrollable element */
    children: React.ReactNode,
    /** height of scrollable area */
    maxHeight?: string | number,
    hasHeightByContent?: boolean,
    onScroll?: (values: positionValues) => void,
    scrollTo?: number,
    transparentBackground?: boolean
}

const cn = classNames.bind(styles);
const HORIZONTAL_SCROLLBAR_HEIGHT = 20;

export class Scrollbars extends React.Component<ScrollbarsProps> {
    scrollRef = React.createRef<CustomScrollbars>();

    static defaultProps = {
        scrollTo: 0,
        onScroll: noop,
        transparentBackground: false,
    };

    componentDidMount() {
        const {scrollTo} = this.props;

        if (this.scrollRef && scrollTo) {
            this.scrollRef.current.scrollTop(scrollTo);
        }
    }

    renderThumbVertical = (props: object) => (
        <div
            {...props}
            className={cn('scrollbars__thumb-vertical')}
        />
    );

    renderTrackVertical = (props: object) => {
        const {transparentBackground} = this.props;
        return (
            <div
                {...props}
                className={cn(
                    'scrollbars__track-vertical',
                    {
                        'scrollbars__track-vertical--transparent-background': transparentBackground,
                    }
                )}
            />
        );
    };

    render() {
        const {
            children,
            onScroll,
            hasHeightByContent = false,
            maxHeight,
        } = this.props;
        const style = maxHeight ? {height: maxHeight} : {};
        const hasHeightByContentProps = hasHeightByContent ? {
            autoHeight: true,
            autoHeightMax: typeof maxHeight === 'string' ? maxHeight : maxHeight + HORIZONTAL_SCROLLBAR_HEIGHT,
        } : {};

        return (
            <CustomScrollbars
                {...hasHeightByContentProps}
                ref={this.scrollRef}
                style={style}
                className={cn('scrollbars')}
                onUpdate={onScroll}
                hideTracksWhenNotNeeded
                renderTrackVertical={this.renderTrackVertical}
                renderThumbVertical={this.renderThumbVertical}
            >
                {children}
            </CustomScrollbars>
        );
    }
}
