import classNames from 'classnames/bind';
import * as React from 'react';

import {
    getDate,
    getNowDate,
    addMonths,
    addYears,
} from '@utils/date';
import {noop} from '@utils/functions';

import {CurrentDateButton} from './current-date-button';
import {Switcher} from './switcher';
import {
    View,
    ViewTypes,
} from './views';

import * as styles from './calendar.scss';

const cn = classNames.bind(styles);

const MONTHS_ON_DAYS_VIEW = 1;
const YEARS_ON_YEARS_VIEW = 15;

interface CalendarState {
    view: ViewTypes;
}

export interface CalendarProps {
    firstSelectedDate: Date;
    secondSelectedDate?: Date;
    viewDate: Date;
    focusedDate: Date;
    onChange: (date: Date) => void;
    onFocus: (date: Date) => void;
    onChangeViewDate: (date: Date, dateChangedByKeyboard?: boolean) => void;
    showCurrentDateButton?: boolean;
    isActive: boolean;
    onDisplayChange: (height: number) => void;
    availableDates?: [Date, Date?]
}

export class Calendar extends React.PureComponent<CalendarProps, CalendarState> {
    calendarRef = React.createRef<HTMLDivElement>();

    static defaultProps: CalendarProps = {
        firstSelectedDate: null,
        secondSelectedDate: null,
        viewDate: getNowDate(),
        focusedDate: getNowDate(),
        onChange: noop,
        onFocus: noop,
        onDisplayChange: noop,
        onChangeViewDate: noop,
        showCurrentDateButton: false,
        isActive: false,
    };

    state: CalendarState = {
        view: ViewTypes.DAY,
    };

    componentDidMount() {
        this.handleDisplayChange();
    }

    componentDidUpdate() {
        this.handleDisplayChange();
    }

    componentWillUnmount() {
        this.handleDisplayChange(true);
    }

    handleDisplayChange = (reset?: boolean) => {
        const {onDisplayChange} = this.props;

        onDisplayChange(reset ? 0 : this.calendarRef.current.getBoundingClientRect().height);
    };

    setView = (view: ViewTypes) => this.setState({view});

    setCurrentDate = () => {
        const {onChange} = this.props;

        onChange(getNowDate());
    };

    setFocusedDate = (date: Date, isNeededToUpdateViewDate: boolean) => {
        const {onFocus, onChangeViewDate} = this.props;

        onFocus(date);

        if (isNeededToUpdateViewDate) {
            onChangeViewDate(date, true);
        }
    };

    renderDate = () => {
        const {
            firstSelectedDate,
            secondSelectedDate,
            onChange,
            viewDate,
            focusedDate,
            isActive,
            availableDates,
        } = this.props;
        const {view} = this.state;

        return (
            <div className={cn('calendar__date-panel')}>
                <View
                    onSelectYear={this.handleSelectYear}
                    onSelectMonth={this.handleSelectMonth}
                    onSelectDay={onChange}
                    onFocus={this.setFocusedDate}
                    focusedDate={focusedDate}
                    firstSelectedDate={firstSelectedDate}
                    secondSelectedDate={secondSelectedDate}
                    viewDate={viewDate}
                    view={view}
                    isActive={isActive}
                    availableDates={availableDates}
                />
            </div>
        );
    };

    handleSelectMonth = (index: number) => {
        const {viewDate, onChangeViewDate} = this.props;

        onChangeViewDate(getDate(1, index, viewDate.getFullYear()));
        this.setView(ViewTypes.DAY);
    };

    handleSelectYear = (year: number) => {
        const {viewDate, onChangeViewDate} = this.props;

        onChangeViewDate(getDate(viewDate.getDate(), viewDate.getMonth(), year));
        this.setView(ViewTypes.DAY);
    };

    handleMonthMove = (forward: boolean) => {
        const {viewDate, onChangeViewDate} = this.props;
        const movedDate = addMonths(viewDate, (forward ? MONTHS_ON_DAYS_VIEW : -MONTHS_ON_DAYS_VIEW));

        onChangeViewDate(movedDate);
    };

    handleYearsMove = (forward: boolean) => {
        const {viewDate, onChangeViewDate} = this.props;
        const movedDate = addYears(viewDate, (forward ? YEARS_ON_YEARS_VIEW : -YEARS_ON_YEARS_VIEW));

        onChangeViewDate(movedDate);
    };

    render() {
        const {view} = this.state;
        const {
            showCurrentDateButton,
            viewDate,
        } = this.props;

        return (
            <div
                role="menu"
                className={cn('calendar')}
                ref={this.calendarRef}
            >
                <Switcher
                    onYearsMove={this.handleYearsMove}
                    onMonthMove={this.handleMonthMove}
                    setView={this.setView}
                    view={view}
                    viewDate={viewDate}
                />

                {this.renderDate()}

                {showCurrentDateButton && view === ViewTypes.DAY && (
                    <CurrentDateButton onClick={this.setCurrentDate} />
                )}
            </div>
        );
    }
}
