import {compose} from 'redux';

import {Column} from '@ui-kit';

import {MENU} from '../common/data-table-hoc-constants';
import {STUB, MENU_WIDTH} from './with-columns-statuses-constants';

export const findMenuColumnIndex = (columns: Column[]) => columns.findIndex(column => column.name === MENU);

export const setMenuColumnWidth = (columns: Column[]) => {
    if (!columns.length) {
        return columns;
    }

    const menuColumnIndex = findMenuColumnIndex(columns);
    const columnsWithMenuWidth = [...columns];
    columnsWithMenuWidth[menuColumnIndex] = {...columnsWithMenuWidth[menuColumnIndex], width: MENU_WIDTH};

    return columnsWithMenuWidth;
};

const filterStubs = (columns: Column[]): Column[] => columns.filter(column => !column.name.includes(STUB));

const filterMenu = (columns: Column[]): Column[] => columns.filter(column => column.name !== MENU);

export const getConfigurableColumns = (columns: Column[]): Column[] => compose(filterMenu, filterStubs)(columns);
