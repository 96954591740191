import {isUndefined, isNull} from 'lodash-es';

import {createTranslator} from '@utils/i18n';

import * as dictionary from './validators.dic.json';

const t = createTranslator(dictionary);

export const requiredFieldValidator = (value: string, label: string) => {
    if (isUndefined(value) || isNull(value) || value === '') {
        return t('required.error.text', label);
    }

    return '';
};
