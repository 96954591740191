import classNames from 'classnames/bind';
import * as React from 'react';

import {InsuranceCompany, LinkData} from '@app/types';
import {
    AGREEMENT_PAGE,
    COMPANIES_LIST_PAGE,
    CONTRACT_PAGE,
    FAIL_PAGE,
    REGISTRATION_PAGE,
} from '@app/urls';
import {DEFAULT_SK_DOMAIN} from '@utils/constants/egarant-settings';
import {EventName} from '@utils/constants';

import {AgreementNavigation} from './components/agreement-navigation';
import {CompaniesNavigation} from './components/companies-navigation';
import {ContractNavigation} from './components/contract-navigation';
import {RegistrationNavigation} from './components/registration-navigation';

import * as styles from './navigation.scss';

const cn = classNames.bind(styles);

export const WIDTH_NAVIGATION_BUTTON = 140;

interface NavigationInterface {
    contractId: string;
    companies: InsuranceCompany[];
    setIsAgreementCaptchaVisible: (value: Boolean) => void;
    loadReturnContract: (contractId: string, linkData: LinkData) => void;
    cancelPayment: (contractId: string) => void;
    linkData: LinkData;
    isNavigationShown: boolean;
    skDomain: string;
    match: {
        path: string;
    };
    history: {
        location: {
            pathname: string;
        };
        go: (delta: number) => void;
        push: (url: string) => void;
    };
}

export class Navigation extends React.PureComponent<NavigationInterface> {
    componentDidMount(): void {
        const {history, match, setIsAgreementCaptchaVisible} = this.props;
        history.push(history.location.pathname);
        window.addEventListener(EventName.POPSTATE, this.onPopState);
        if (match.path !== `/${AGREEMENT_PAGE}`) {
            setIsAgreementCaptchaVisible(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener(EventName.POPSTATE, this.onPopState);
    }

    onPopState = () => {
        const {
            history,
            match,
            contractId,
            linkData,
            loadReturnContract,
            cancelPayment,
        } = this.props;
        if (match.path === `/${REGISTRATION_PAGE}`) {
            loadReturnContract(contractId, linkData);
        } else if (match.path === FAIL_PAGE) {
            cancelPayment(contractId);
        } else {
            history.go(1);
        }
    };

    render() {
        const {
            companies,
            match,
            isNavigationShown,
            skDomain,
        } = this.props;

        let NAVIGATION_COMPONENTS: React.ReactElement = null;
        const registrationPage = `/${skDomain}/${REGISTRATION_PAGE}`;
        const contractPage = `/${skDomain || DEFAULT_SK_DOMAIN}/${CONTRACT_PAGE}`;
        if (isNavigationShown) {
            switch (match.path) {
                case `/${AGREEMENT_PAGE}`:
                    NAVIGATION_COMPONENTS = (
                        <AgreementNavigation />
                    );
                    break;
                case `/${COMPANIES_LIST_PAGE}`:
                    if (companies.length > 0) {
                        NAVIGATION_COMPONENTS = (
                            <CompaniesNavigation />
                        );
                    }
                    break;
                case registrationPage:
                    NAVIGATION_COMPONENTS = (
                        <RegistrationNavigation />
                    );
                    break;

                case contractPage:
                    NAVIGATION_COMPONENTS = (
                        <ContractNavigation />
                    );
                    break;
                default:
                    break;
            }
        }

        return (
            <div className={cn('navigation')}>
                {NAVIGATION_COMPONENTS}
            </div>
        );
    }
}
