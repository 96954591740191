import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './logo.scss';

const cn = classNames.bind(styles);

interface LogoProps {
    imageUrl: string
}

export const Logo = ({
    imageUrl,
}: LogoProps): JSX.Element => (
    <div className={cn('logo')}>
        <img
            className={cn('logo__img')}
            src={imageUrl}
            alt="Logo"
        />
    </div>
);
