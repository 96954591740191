import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './portal-root.scss';

const cn = classNames.bind(styles);

export const portalRef = React.createRef<HTMLDivElement>();

export const PortalRoot = () => (
    <div
        ref={portalRef}
        className={cn('portal-root')}
    />
);
