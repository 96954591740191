import classNames from 'classnames/bind';
import * as React from 'react';

import {Spinner} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './loading-info.dic.json';
import * as styles from './loading-info.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export const PaymentResultLoading = () => (
    <div className={cn('spinner-container')}>
        <h2 className={cn('spinner-container__message')}>
            {t('loading-info-page-title')}
        </h2>
        <Spinner />
    </div>
);
