import * as React from 'react';

import {TableButtons} from '@app/components/layouts/table-layout';
import * as dictionary from '@app/pages/shared-pages/layout-page/components/navigation/navigation.dic.json';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';
import {isDisabledInterface} from '@utils/isDisabled';
import {Steps} from '@utils/constants';

const t = createTranslator(dictionary);

interface ButtonsBackOrNextInterface {
    currentStep: number;
    forceUpdatePrev: (currentStep: number) => void;
    forceUpdateNext: (currentStep: number) => void;
    isDisabled: (props: isDisabledInterface) => boolean;
    isLkBlocked: boolean;
}

export function ButtonsBackOrNext({
    currentStep,
    forceUpdateNext,
    forceUpdatePrev,
    isDisabled,
    isLkBlocked,
}: ButtonsBackOrNextInterface) {
    return (!!currentStep && currentStep !== Steps.FINAL_STEP) && (
        <TableButtons>
            {
                currentStep !== Steps.FIRST_STEP && (
                    <Button
                        onClick={() => forceUpdatePrev(currentStep)}
                        disabled={isDisabled({isLkBlocked})}
                        label={t('navigation.button.back')}
                    />
                )
            }
            <Button
                onClick={() => forceUpdateNext(currentStep)}
                label={t('navigation.button.next')}
                primary
                disabled={isDisabled({isLkBlocked})}
            />
        </TableButtons>
    );
}
