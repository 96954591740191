import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    setIsAgreementCaptchaVisible,
    setIsAgreementBtnEnabled,
} from '@app/store/actions';
import {isAgreementCaptchaVisibleSelector} from '@modules/system';

import {AgreementBlock} from './agreement-block';

const mapStateToProps = createStructuredSelector({
    isAgreementCaptchaVisible: isAgreementCaptchaVisibleSelector,
});

const mapDispatchToProps = {
    setIsAgreementCaptchaVisible,
    setIsAgreementBtnEnabled,
};

export const AgreementBlockContainer = connect(mapStateToProps, mapDispatchToProps)(AgreementBlock);
