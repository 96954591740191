import {ValidationData} from '@app/types';
import {API_V1_EGARANT_CONTRACT_UI, getData, putData} from '@utils/api';

const VALIDATION = 'validation';

export function contractValidationRequestPut(contractId: string): Promise<null> {
    const VALIDATION_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${VALIDATION}`;
    return putData(VALIDATION_URL);
}

export function contractValidationRequestGet(contractId: string): Promise<ValidationData> {
    const VALIDATION_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${VALIDATION}`;
    return getData(VALIDATION_URL);
}
