export enum ActionTypes {
    // MODALS
    SET_MISMATCH_DATA = 'SET_MISMATCH_DATA',
    SHOW_MODAL = 'SHOW_MODAL',
    HIDE_MODAL = 'HIDE_MODAL',
    SET_IS_BAD_REQUEST_MODAL = 'SET_IS_BAD_REQUEST_MODAL',
    // COMPANIES
    SET_INS_COMPANY_NULL = 'SET_INS_COMPANY_NULL',
    SET_COMPANIES = 'SET_COMPANIES',
    SET_FILTERABLE_COMPANIES = 'SET_FILTERABLE_COMPANIES',
    SET_COMPANY_PREMIUM = 'SET_COMPANY_PREMIUM',
    SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY',
    SET_LINK = 'SET_LINK',
    SET_REGION_NAME = 'SET_REGION_NAME',
    SET_HAS_INS_COMPANY_2_CHOSEN = 'SET_HAS_INS_COMPANY_2_CHOSEN',
    // CONTRACT
    SET_CONTRACT_DATA = 'SET_CONTRACT_DATA',
    SET_DRIVER_MEETS = 'SET_DRIVER_MEETS',
    SET_NO_LIMIT_DRIVER = 'SET_NO_LIMIT_DRIVER',
    SET_UPLOADED_FILES = 'SET_UPLOADED_FILES',
    SET_CONTRACT_AMOUNT_IN_LIMITS = 'SET_CONTRACT_AMOUNT_IN_LIMITS',
    SET_DICTIONARIES_DATA = 'SET_DICTIONARIES_DATA',
    SET_OWNER_TYPE = 'SET_OWNER_TYPE',
    SET_PAYMENT_LINK = 'SET_PAYMENT_LINK',
    SET_NOT_FIRST_LOAD_DATA = 'SET_NOT_FIRST_LOAD_DATA',
    SET_CONTRACT_ID = 'SET_CONTRACT_ID',
    SET_CALCULATED_TABLE_DATA = 'SET_CALCULATED_TABLE_DATA',
    SET_IS_SCAN_FILE_UPLOADING = 'SET_IS_SCAN_FILE_UPLOADING',
    // STAGE
    SET_CURRENT_STAGE = 'SET_CURRENT_STAGE',
    SET_TIMER = 'SET_TIMER',
    SET_IS_TIMER_LOGIN_EXPIRED_TRUE = 'SET_IS_TIMER_LOGIN_EXPIRED_TRUE',
    SET_AUTO_ACCEPT_HEARTBEAT_STOPPED = 'SET_AUTO_ACCEPT_HEARTBEAT_STOPPED',
    // NAVIGATION
    SET_IS_ACTIVE_REGISTER_BTN_AGREE = 'SET_IS_ACTIVE_REGISTER_BTN_AGREE',
    SET_IS_CHECK_DATA_BTN_HIDDEN = 'SET_IS_CHECK_DATA_BTN_HIDDEN',
    SET_IS_ACTIVE_REGISTER_BTN_AWARE = 'SET_IS_ACTIVE_REGISTER_BTN_AWARE',
    SET_IS_VALIDATION_CHECKED = 'SET_IS_VALIDATION_CHECKED',
    SET_IS_AGREEMENT_BTN_ENABLED = 'SET_IS_AGREEMENT_BTN_ENABLED',
    SET_IS_UPDATE_SCANS_STATUS_DISABLED = 'SET_IS_UPDATE_SCANS_STATUS_DISABLED',
    SET_IS_NAVIGATION_SHOWN = 'SET_IS_NAVIGATION_SHOWN',
    SET_IS_NOT_FOUND = 'SET_IS_NOT_FOUND',
    SET_NAVIGATION_RESULT = 'SET_NAVIGATION_RESULT',
    SET_CURRENT_STEP = 'SET_CURRENT_STEP',
    SET_STEP_AFTER_SUBMIT = 'SET_STEP_AFTER_SUBMIT',
    SET_IS_LK_BLOCKED_TRUE = 'SET_IS_LK_BLOCKED_TRUE',
    SET_IS_UPLOADED_FILES_BTN_ENABLED = 'SET_IS_UPLOADED_FILES_BTN_ENABLED',
    // USER
    SET_USER = 'SET_USER',
    SET_INITIALIZED_AUTH_TRUE = 'SET_INITIALIZED_AUTH_TRUE',
    SET_AUTHENTICATED = 'SET_AUTHENTICATED',
    SET_HAS_USER = 'SET_HAS_USER',
    SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD',
    // VALIDATION
    SET_VALIDATION_INITIATED = 'SET_VALIDATION_INITIATED',
    SET_VALIDATION_RESULT = 'SET_VALIDATION_RESULT',
    SET_STEPS_TO_VALIDATE = 'SET_STEPS_TO_VALIDATE',
    SET_MAPPED_VALIDATION = 'SET_MAPPED_VALIDATION',
    SET_IS_VALIDATION_INITIATED = 'SET_IS_VALIDATION_INITIATED',
    SET_SCANS_TO_EDIT = 'SET_SCANS_TO_EDIT',
    SET_VALIDATION_RESULT_ERROR_FIELDS = 'SET_VALIDATION_RESULT_ERROR_FIELDS',
    SET_IS_SCAN_SENDING_INITIATED = 'SET_IS_SCAN_SENDING_INITIATED',
    SET_IS_SCAN_EDITING_INITIATED = 'SET_IS_SCAN_EDITING_INITIATED',
    SET_IS_SCANS_POLLING_INITIATED = 'SET_IS_SCANS_POLLING_INITIATED',
    SET_IS_ALL_SCANS_APPROVED = 'SET_IS_ALL_SCANS_APPROVED',
    SET_IS_SCANS_IN_PROGRESS = 'SET_IS_SCANS_IN_PROGRESS',
    SET_IS_VALIDATION_WAITING = 'SET_IS_VALIDATION_WAITING',
    SET_INSURER_ADDRESS_WARNING_MESSAGE = 'SET_INSURER_ADDRESS_WARNING_MESSAGE',
    SET_OWNER_ADDRESS_WARNING_MESSAGE = 'SET_OWNER_ADDRESS_WARNING_MESSAGE',
    SET_INSURER_ADDRESS_TEXT_WARNING_MESSAGE = 'SET_INSURER_ADDRESS_TEXT_WARNING_MESSAGE',
    SET_OWNER_ADDRESS_TEXT_WARNING_MESSAGE = 'SET_OWNER_ADDRESS_TEXT_WARNING_MESSAGE',
    SET_IS_VALIDATION_IN_PROGRESS = 'SET_IS_VALIDATION_IN_PROGRESS',
    // SYSTEM
    SET_FE_EGARANT_CONFIGS = 'SET_FE_EGARANT_CONFIGS',
    SET_IS_AGREEMENT_CAPTCHA_VISIBLE = 'SET_IS_AGREEMENT_CAPTCHA_VISIBLE',
    SET_IS_RESTORE_CAPTCHA = 'SET_IS_RESTORE_CAPTCHA',
    SET_IS_COMPANIES_CAPTCHA = 'SET_IS_COMPANIES_CAPTCHA',
    SET_CAPTCHA_PUBLIC_KEY = 'SET_CAPTCHA_PUBLIC_KEY',
    SET_CAPTCHA_VERIFY_KEY = 'SET_CAPTCHA_VERIFY_KEY',
    SET_SERVER_TIME = 'SET_SERVER_TIME',
}
