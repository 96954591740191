
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {setIsNavigationShown} from '@app/store/actions';
import {
    hasInsCompanySecondChosenSelector,
    linkDataSelector,
    skDomainSelector,
} from '@modules/companies';
import {isNotFoundSelector} from '@modules/navigation';
import {authenticatedSelector, hasUserSelector} from '@modules/user';

import {PublicArea} from './public-area';

const mapStateToProps = createStructuredSelector({
    authenticated: authenticatedSelector,
    linkData: linkDataSelector,
    hasUser: hasUserSelector,
    hasInsCompanySecondChosen: hasInsCompanySecondChosenSelector,
    skDomain: skDomainSelector,
    isNotFound: isNotFoundSelector,
});

const mapDispatchToProps = {
    setIsNavigationShown,
};

export const PublicAreaContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PublicArea);
