import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {
    UsePeriodInterface,
} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {createTranslator} from '@app/utils/i18n';

import * as styles from '../../form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function PeriodUseVehicle({
    usePeriod1,
    usePeriod2,
    usePeriod3,
}: UsePeriodInterface) {
    return (
        <div className={cn('form-container__form-container')}>
            <h3 className={cn('form-container__sub-header')}>
                {t('form-container-period-use-vehicle-header')}
            </h3>
            <div className={cn('form-container__text')}>
                {t('form-container-period-use-vehicle-text')}
            </div>
            <div className={cn('form-container__text')}>
                {usePeriod1}
            </div>
            <div className={cn('form-container__text')}>
                {usePeriod2}
            </div>
            <div className={cn('form-container__text')}>
                {usePeriod3}
            </div>
        </div>
    );
}
