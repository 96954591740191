import axios, {
    AxiosError,
    AxiosPromise,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import {isNil, omitBy, toPairs} from 'lodash-es';

import {
    RequestError,
    RequestCode,
    SYSTEM_STATUSES,
} from './request-code';


export type RequestConfig = AxiosRequestConfig;
export type RequestPromise = AxiosPromise;

export type HttpErrorHandler = (error: RequestError) => void;

let errorHandler: HttpErrorHandler = null;

export function registerErrorHandler(handler: HttpErrorHandler) {
    errorHandler = handler;
}

const parseError = (response: AxiosResponse) => {
    if (!response) {
        return new RequestError(RequestCode.INTERNAL_SERVER_ERROR);
    }

    const {status, data} = response;
    const statusIdx = SYSTEM_STATUSES.indexOf(status);
    const errorStatus = statusIdx === -1 ? status : SYSTEM_STATUSES[statusIdx];

    return new RequestError(errorStatus, data, response);
};

const excludedErrorHandlerRequestUrls: RegExp[] = [
    /\/egarant-lk\/ui\/heartbeat/,
    /\/egarant-lk\/ui\/session/,
];

export function pureRequest(config: RequestConfig): RequestPromise {
    return axios(config)
        .catch((error: AxiosError) => {
            const {response} = error;
            const requestError = parseError(response);
            if (errorHandler && !excludedErrorHandlerRequestUrls.some(url => config.url.match(url))) {
                errorHandler(requestError);
            }
            throw requestError;
        });
}

// This workround crutch-solution only for Demo-2.
// See issue https://jira.epam.com/jira/browse/EPMINSR-4028
let delayCoeff = 0;
const DELAY_STEPS = 5;
const DELAY_MS = 100;

const getDelayCoeff = () => {
    delayCoeff = delayCoeff >= DELAY_STEPS ? 0 : delayCoeff + 1;
    return delayCoeff;
};

const delayRequest = (config: RequestConfig): RequestPromise => new Promise(
    resolve => setTimeout(() => resolve(pureRequest(config)), getDelayCoeff() * DELAY_MS)
);
// end of crutch

export function request(config: RequestConfig): RequestPromise {
    return pureRequest(config); // delayRequest(config);
}

const makePairs = (data: {
    [key: string]: string | number | boolean
}) => toPairs(omitBy(data, value => isNil(value) || value === '')).map(pair => pair.join('='));

export const toUrlParams = (data: {
    [key: string]: string | number | boolean
}) => makePairs(data).join('&');
