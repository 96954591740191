import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    uploadedDocumentFilesSelector,
    isAmountInLimitsSelector,
} from '@modules/contract';
import {
    currentStageSelector,
    expirationTimeSelector,
} from '@modules/stage';
import {
    isLkBlockedSelector,
    currentStepSelector,
} from '@modules/navigation';
import {
    validationResultScansSelector,
    isDataEditedSelector,
    isAllScansApprovedSelector,
    isScanSendingInitiatedSelector,
    validationResultEntitiesSelector,
} from '@modules/validation';
import {linkDataSelector} from '@modules/companies';

import {AdditionalInfo} from './additional-info';

const mapStateToProps = createStructuredSelector({
    currentStep: currentStepSelector,
    currentStage: currentStageSelector,
    validationResultEntities: validationResultEntitiesSelector,
    validationResultScans: validationResultScansSelector,
    isScanSendingInitiated: isScanSendingInitiatedSelector,
    expirationTime: expirationTimeSelector,
    isAmountInLimits: isAmountInLimitsSelector,
    uploadedDocumentFiles: uploadedDocumentFilesSelector,
    isDataEdited: isDataEditedSelector,
    isLkBlocked: isLkBlockedSelector,
    isAllScansApproved: isAllScansApprovedSelector,
    linkData: linkDataSelector,
});

export const AdditionalInfoContainer = connect(mapStateToProps)(AdditionalInfo);
