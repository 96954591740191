import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {setIsValidationChecked} from '@app/store/actions';
import {
    isCheckDataBtnHiddenSelector,
    isValidationCheckedSelector,
    isLkBlockedSelector,
} from '@modules/navigation';
import {
    isDataEditedSelector,
    isValidationInitiatedSelector,
} from '@modules/validation';
import {currentStageSelector} from '@modules/stage';

import {ContractCheckbox} from './contract-checkbox';

const mapStateToProps = createStructuredSelector({
    currentStage: currentStageSelector,
    isDataEdited: isDataEditedSelector,
    isValidationChecked: isValidationCheckedSelector,
    isValidationInitiated: isValidationInitiatedSelector,
    isCheckDataBtnHidden: isCheckDataBtnHiddenSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    setIsValidationChecked,
};

export const ContractCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(ContractCheckbox);
