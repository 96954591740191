import classNames from 'classnames/bind';
import * as React from 'react';

import {TableButtons} from '@app/components/layouts/table-layout';
import {PersonRegistrationData} from '@app/types';
import {LinkDataInterface} from '@modules/contract';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import {NavigationErrorBeforeContract} from '../navigation-error-before-contract';

import * as dictionary from '../../navigation.dic.json';
import * as styles from '../../navigation.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface RegistrationNavigation {
    contractId: string;
    isActiveRegisterBtnAgree: boolean;
    isActiveRegisterBtnAware: boolean;
    user: PersonRegistrationData;
    confirmRegistration: (contractId: string, userList: PersonRegistrationData) => void;
    isLkBlocked: boolean;
    loadReturnContract: (contractId: string, linkData: LinkDataInterface) => void;
    linkData: LinkDataInterface;
}

export function RegistrationNavigation({
    contractId,
    isActiveRegisterBtnAgree,
    isActiveRegisterBtnAware,
    user,
    confirmRegistration,
    isLkBlocked,
    loadReturnContract,
    linkData,
}: RegistrationNavigation) {
    return (
        <div className={cn('navigation-wrapper')}>
            <NavigationErrorBeforeContract />
            <TableButtons>
                <Button
                    disabled={isLkBlocked}
                    label={t('navigation.button.back')}
                    onClick={() => loadReturnContract(contractId, linkData)}
                />

                <Button
                    label={t('navigation.button.registration')}
                    primary
                    disabled={!isActiveRegisterBtnAgree || !isActiveRegisterBtnAware || isLkBlocked}
                    onClick={() => confirmRegistration(contractId, user)}
                />
            </TableButtons>
        </div>
    );
}
