import {Action, HandlersMap} from './types';

export function handleActions(namespace: string) {
    return (handlersMap: HandlersMap, defaultState: object) => {
        const handlerKeys = Object.keys(handlersMap);
        const handlers: HandlersMap = handlerKeys.reduce((acc, key) => {
            const action = handlersMap[key];
            return {
                ...acc,
                [`${namespace}/${key}`]: action,
            };
        }, {});

        return (state = defaultState, action: Action) => {
            const handler = handlers[action.type];
            if (handler) {
                return handler(state, action);
            }

            return state;
        };
    };
}
