import * as React from 'react';

import {DatePicker} from '../../date-picker';
import {fieldAdapter} from '../field-adapter';
import {withErrorIndicator} from '../with-error-indicator';

export interface DatePickerWithFieldAdapterProps {
    onChange?: (date: string) => void;
    isDisabled?: boolean;
    id?: string;
    label: string;
    name: string;
    required?: boolean;
    width?: string | number;
    value?: string;
    availableDates?: [Date, Date?];
    showFirstAvailableDate?: boolean;
}

function DatePickerWithFieldAdapterComponent(props: DatePickerWithFieldAdapterProps) {
    const {value} = props;

    return <DatePicker {...props} selectedDate={value} />;
}

export const DatePickerWithFieldAdapter = fieldAdapter(withErrorIndicator(DatePickerWithFieldAdapterComponent));
