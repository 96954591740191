import * as React from 'react';
import {SortableContainer, SortableElement, Axis} from 'react-sortable-hoc';

import {noop} from '@utils/functions';

interface DraggableListWrapperProps {
    items: object[]
    renderListItem: (item: object, index: number) => React.ReactNode
    onSortEnd: (oldIndex: number, newIndex: number) => void
    onSortStart?: (index: number) => void
    lockAxis?: Axis
    helperClass?: string
    getListItemKey: (item: object) => any
    helperContainer?: HTMLElement
    bottomGap?: string
}

interface DraggableListProps {
    items: object[]
    renderListItem: (item: object, index: number) => React.ReactNode
    getItemKey: (item: object) => any
}

const DraggableItem = SortableElement(({item}: any) => item);

const DraggableList = SortableContainer(({
    items,
    renderListItem,
    getItemKey,
}: DraggableListProps) => (
    <div>
        {
            items.map((item: object, index: number) => (
                <DraggableItem
                    item={renderListItem(item, index)}
                    key={getItemKey(item)}
                    index={index}
                />
            ))
        }
    </div>
));

export function DraggableListWrapper({
    items,
    onSortStart,
    onSortEnd,
    lockAxis,
    renderListItem,
    helperClass,
    getListItemKey,
    helperContainer,
    bottomGap,
}: DraggableListWrapperProps) {
    const handleSortStart = ({index}: {index: number}) => {
        onSortStart(index);
    };

    const handleSortEnd = ({
        oldIndex,
        newIndex,
    }: {oldIndex: number, newIndex: number}) => {
        onSortEnd(oldIndex, newIndex);
    };

    return (
        <DraggableList
            distance={5}
            getItemKey={getListItemKey}
            helperClass={helperClass}
            items={items}
            lockAxis={lockAxis}
            lockToContainerEdges
            lockOffset={[0, `-${bottomGap}`]}
            onSortEnd={handleSortEnd}
            renderListItem={renderListItem}
            updateBeforeSortStart={handleSortStart}
            helperContainer={helperContainer}
        />
    );
}

DraggableListWrapper.defaultProps = {
    onSortStart: noop,
    bottomGap: '50%',
};
