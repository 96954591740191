import classNames from 'classnames/bind';
import * as React from 'react';

import {Step1FormInterface} from '@app/pages/contract-page/pages/step-1/step1-types';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {LoadOptionsFuncType} from '@app/components/fields/field-types';
import {getFiasPageDataRequest} from '@modules/contract/contract-api';
import {createTranslator} from '@app/utils/i18n';
import {
    objectsToSelectOptions,
    SelectOption,
} from '@utils/formatters/select-option';
import {shouldBeDisabled} from '@utils/should-be-disabled';
import {
    AutocompleteSelectField,
    SelectField,
} from '@app/components';
import {
    FiasElementTypeApi,
    FiasElementType,
    FiasElement,
    PersonType,
} from '@app/types';
import {
    RUSSIAN_FEDERATION,
    NAME,
} from '@utils/constants';

import * as styles from './address-data.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface AddressDataInterface {
    scansEditFields: string[];
    isOwnerLegal?: boolean;
    isLkBlocked: boolean;
    personType: PersonType;
    values: Step1FormInterface;
    mutator: (field: string, value: string) => void;
}

interface GetLoadOptionsInterface{
    type: FiasElementType | FiasElementTypeApi;
    minLengthStartsWith?: number;
    loadOptionsDisable?: boolean;
    parentCode?: string;
    houseNum?: string;
    buildNum?: string;
}

function getSelectOptions(values: Step1FormInterface, personType: PersonType, type: FiasElementType) {
    switch (type) {
        case FiasElementType.REGION:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-region'] :
                values['owner-addressFias-region'];
        case FiasElementType.DISTRICT:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-district'] :
                values['owner-addressFias-district'];
        case FiasElementType.CITY:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-city'] :
                values['owner-addressFias-city'];
        case FiasElementType.LOCALITY:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-locality'] :
                values['owner-addressFias-locality'];
        case FiasElementType.STREET:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-street'] :
                values['owner-addressFias-street'];
        case FiasElementType.HOUSE:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-house'] :
                values['owner-addressFias-house'];
        case FiasElementType.BUILDNUM:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-building'] :
                values['owner-addressFias-building'];
        case FiasElementType.STRUCNUM:
            return personType === PersonType.INSURER ?
                values['insurer-addressFias-structure'] :
                values['owner-addressFias-structure'];
        default:
            return null;
    }
}

export const getParentFiasId = (
    values: Step1FormInterface,
    personType: PersonType,
    type: FiasElementType
): string | null => {
    const regionFiasId = getSelectOptions(values, personType, FiasElementType.REGION)?.value?.fiasId;
    const districtFiasId = getSelectOptions(values, personType, FiasElementType.DISTRICT)?.value?.fiasId;
    const cityFiasId = getSelectOptions(values, personType, FiasElementType.CITY)?.value?.fiasId;
    const localityFiasId = getSelectOptions(values, personType, FiasElementType.LOCALITY)?.value?.fiasId;
    const streetFiasId = getSelectOptions(values, personType, FiasElementType.STREET)?.value?.fiasId;
    const houseFiasId = getSelectOptions(values, personType, FiasElementType.HOUSE)?.value?.fiasId;
    const buildnumFiasId = getSelectOptions(values, personType, FiasElementType.BUILDNUM)?.value?.fiasId;
    const strucnumFiasId = getSelectOptions(values, personType, FiasElementType.STRUCNUM)?.value?.fiasId;
    switch (type) {
        case FiasElementType.DISTRICT:
            return regionFiasId;
        case FiasElementType.CITY:
            return districtFiasId ||
                regionFiasId;
        case FiasElementType.LOCALITY:
            return cityFiasId ||
                districtFiasId ||
                regionFiasId;
        case FiasElementType.STREET:
            return localityFiasId ||
                cityFiasId ||
                districtFiasId ||
                regionFiasId;
        case FiasElementType.HOUSE:
            return streetFiasId ||
                localityFiasId ||
                cityFiasId ||
                districtFiasId ||
                regionFiasId;
        case FiasElementType.BUILDNUM:
            return streetFiasId ||
                localityFiasId ||
                cityFiasId ||
                districtFiasId ||
                regionFiasId;
        case FiasElementType.STRUCNUM:
            return streetFiasId ||
                localityFiasId ||
                cityFiasId ||
                districtFiasId ||
                regionFiasId;
        case FiasElementType.ROOM:
            return strucnumFiasId ||
                buildnumFiasId ||
                houseFiasId ||
                streetFiasId ||
                localityFiasId ||
                cityFiasId ||
                districtFiasId ||
                regionFiasId;
        default:
            return null;
    }
};

export const getParentName = (
    values: Step1FormInterface,
    personType: PersonType,
    type: FiasElementType
): string | null => {
    const house = getSelectOptions(values, personType, FiasElementType.HOUSE);
    const building = getSelectOptions(values, personType, FiasElementType.BUILDNUM);
    switch (type) {
        case FiasElementType.HOUSE:
            return house?.value?.name;
        case FiasElementType.BUILDNUM:
            return building?.value?.name || null;
        default:
            return null;
    }
};

export const isParentValueDropped = (
    values: Step1FormInterface,
    personType: PersonType,
    type: FiasElementType
) => {
    const isEmptyString = (value: SelectOption<FiasElement>) => value as unknown as string === '';
    switch (type) {
        case FiasElementType.DISTRICT:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.REGION));
        case FiasElementType.CITY:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.DISTRICT));
        case FiasElementType.LOCALITY:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.CITY));
        case FiasElementType.STREET:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.LOCALITY));
        case FiasElementType.HOUSE:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.STREET));
        case FiasElementType.BUILDNUM:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.HOUSE));
        case FiasElementType.STRUCNUM:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.BUILDNUM));
        case FiasElementType.ROOM:
            return isEmptyString(getSelectOptions(values, personType, FiasElementType.STRUCNUM));
        default:
            return null;
    }
};

export function AddressData({
    scansEditFields,
    isOwnerLegal,
    isLkBlocked,
    personType,
    values,
    mutator,
}: AddressDataInterface) {
    const isFieldsClearIfNoStruct = (formValues: Step1FormInterface, personType: PersonType, value: any) => {
        if (
            (value?.name === undefined ||
                (!value?.fiasId &&
                !!value?.name)) &&
            (!getSelectOptions(formValues, personType, FiasElementType.BUILDNUM)?.value?.fiasId &&
            !getSelectOptions(formValues, personType, FiasElementType.HOUSE)?.value?.fiasId)
        ) {
            return true;
        }

        return false;
    };

    const isFieldsClearIfNoBuilding = (formValues: Step1FormInterface, personType: PersonType, value: any) => {
        if (
            (value?.name === undefined ||
                (!value?.fiasId &&
                !!value?.name)) &&
            !getSelectOptions(formValues, personType, FiasElementType.HOUSE)?.value?.fiasId
        ) {
            return true;
        }

        return false;
    };

    const dropValues = React.useCallback((names: string[]) => names.forEach(name => mutator(name, '')), [
        mutator,
    ]);
    const getLoadOptions = ({
        type,
        minLengthStartsWith = 3,
        parentCode,
        houseNum,
        buildNum,
        loadOptionsDisable,
    }: GetLoadOptionsInterface): LoadOptionsFuncType<any> => async (
        value: string,
        pageIndex: number,
        pageSize: number
    ) => {
        if (loadOptionsDisable) {
            return new Promise(resolve => resolve({
                options: [],
                hasMoreData: false,
            }));
        }
        const result = await getFiasPageDataRequest({
            type,
            startsWith: value,
            pageIndex,
            pageSize,
            minLengthStartsWith,
            parentCode,
            houseNum,
            buildNum,
        });
        return new Promise(resolve => resolve({
            options: objectsToSelectOptions<FiasElement>(result.objects, NAME),
            hasMoreData: pageIndex * pageSize < result.totalCount,
        }));
    };
    const updateAddressState = React.useCallback((type: FiasElementType) => {
        switch (type) {
            case FiasElementType.REGION:
                dropValues([
                    `${personType}-addressFias-district`,
                    `${personType}-addressFias-city`,
                    `${personType}-addressFias-locality`,
                    `${personType}-addressFias-street`,
                    `${personType}-addressFias-house`,
                    `${personType}-addressFias-building`,
                    `${personType}-addressFias-structure`,
                    `${personType}-addressFias-room`,
                ]);
                break;
            case FiasElementType.DISTRICT:
                dropValues([
                    `${personType}-addressFias-city`,
                    `${personType}-addressFias-locality`,
                    `${personType}-addressFias-street`,
                    `${personType}-addressFias-house`,
                    `${personType}-addressFias-building`,
                    `${personType}-addressFias-structure`,
                    `${personType}-addressFias-room`,
                ]);
                break;
            case FiasElementType.CITY:
                dropValues([
                    `${personType}-addressFias-locality`,
                    `${personType}-addressFias-street`,
                    `${personType}-addressFias-house`,
                    `${personType}-addressFias-building`,
                    `${personType}-addressFias-structure`,
                    `${personType}-addressFias-room`,
                ]);
                break;
            case FiasElementType.LOCALITY:
                dropValues([
                    `${personType}-addressFias-street`,
                    `${personType}-addressFias-house`,
                    `${personType}-addressFias-building`,
                    `${personType}-addressFias-structure`,
                    `${personType}-addressFias-room`,
                ]);
                break;
            case FiasElementType.STREET:
                dropValues([
                    `${personType}-addressFias-house`,
                    `${personType}-addressFias-building`,
                    `${personType}-addressFias-structure`,
                    `${personType}-addressFias-room`,
                ]);
                break;

            case FiasElementType.HOUSE:
                dropValues([
                    `${personType}-addressFias-building`,
                    `${personType}-addressFias-structure`,
                    `${personType}-addressFias-room`,
                ]);
                break;
            case FiasElementType.BUILDNUM:
                dropValues([
                    `${personType}-addressFias-structure`,
                    `${personType}-addressFias-room`,
                ]);
                break;
            case FiasElementType.STRUCNUM:
                dropValues([
                    `${personType}-addressFias-room`,
                ]);
                break;
            default:
                break;
        }
    }, [
        personType,
        dropValues,
    ]);
    const COUNTRIES = [{
        label: RUSSIAN_FEDERATION,
        value: RUSSIAN_FEDERATION,
    }];

    const handleBuildingOnChange = React.useCallback((value: any) => {
        updateAddressState(FiasElementType.BUILDNUM);
        if (isFieldsClearIfNoBuilding(values, personType, value)) {
            updateAddressState(FiasElementType.STREET);
        }
    }, [
        personType,
        values,
        updateAddressState,
    ]);

    const handleStructOnChange = React.useCallback((value: any) => {
        updateAddressState(FiasElementType.STRUCNUM);
        if (isFieldsClearIfNoStruct(values, personType, value)) {
            updateAddressState(FiasElementType.STREET);
        }
    }, [
        personType,
        values,
        updateAddressState,
    ]);

    const isKeyboardBuildnum = !getSelectOptions(values, personType, FiasElementType.BUILDNUM)?.value?.fiasId &&
        !!getSelectOptions(values, personType, FiasElementType.BUILDNUM)?.value?.name;
    const isKeyboardStrucnum = !getSelectOptions(values, personType, FiasElementType.STRUCNUM)?.value?.fiasId &&
        !!getSelectOptions(values, personType, FiasElementType.STRUCNUM)?.value?.name;
    const isEmptyStrucnum = !getSelectOptions(values, personType, FiasElementType.STRUCNUM)?.value?.fiasId &&
        !getSelectOptions(values, personType, FiasElementType.STRUCNUM)?.value?.name;

    return (
        <div className={cn('address-data')}>
            <h2 className={cn('address-data__secondary-header')}>
                {t(`address-data-${isOwnerLegal ? 'legal' : 'physical'}-title`)}
            </h2>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <SelectField
                        label={t('address-data-country')}
                        options={COUNTRIES}
                        name={`${personType}-addressFias-country`}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-country`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-region')}
                        options={[]}
                        name={`${personType}-addressFias-region`}
                        loadOptions={getLoadOptions({
                            type: FiasElementType.REGION,
                        })}
                        onChange={() => updateAddressState(FiasElementType.REGION)}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-region`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-district')}
                        options={[{label: 'Нет района', value: null}]}
                        name={`${personType}-addressFias-district`}
                        loadOptions={getLoadOptions({
                            type: FiasElementType.DISTRICT,
                            parentCode: getParentFiasId(values, personType, FiasElementType.DISTRICT),
                        })}
                        onChange={() => updateAddressState(FiasElementType.DISTRICT)}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-district`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.DISTRICT),
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-city')}
                        options={[{label: 'Нет города', value: null}]}
                        name={`${personType}-addressFias-city`}
                        loadOptions={getLoadOptions({
                            type: FiasElementType.CITY,
                            parentCode: getParentFiasId(values, personType, FiasElementType.CITY),
                        })}
                        onChange={() => updateAddressState(FiasElementType.CITY)}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-city`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.CITY),
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-locality')}
                        options={[{label: 'Нет населённого пункта', value: null}]}
                        name={`${personType}-addressFias-locality`}
                        loadOptions={getLoadOptions({
                            type: FiasElementType.LOCALITY,
                            parentCode: getParentFiasId(values, personType, FiasElementType.LOCALITY),
                        })}
                        onChange={() => updateAddressState(FiasElementType.LOCALITY)}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-locality`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.LOCALITY),
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-street')}
                        options={[{label: 'Нет улицы', value: null}]}
                        name={`${personType}-addressFias-street`}
                        loadOptions={getLoadOptions({
                            type: FiasElementType.STREET,
                            parentCode: getParentFiasId(values, personType, FiasElementType.STREET),
                        })}
                        onChange={() => updateAddressState(FiasElementType.STREET)}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-street`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.STREET),
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-house')}
                        options={[]}
                        name={`${personType}-addressFias-house`}
                        loadOptions={getLoadOptions({
                            type: FiasElementTypeApi.HOUSENUM,
                            minLengthStartsWith: 1,
                            parentCode: getParentFiasId(values, personType, FiasElementType.HOUSE),
                        })}
                        onChange={() => updateAddressState(FiasElementType.HOUSE)}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-house`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.HOUSE),
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-building')}
                        options={[{label: 'Не выбрано', value: null}]}
                        name={`${personType}-addressFias-building`}
                        loadOptions={getLoadOptions({
                            type: FiasElementTypeApi.BUILDNUM,
                            minLengthStartsWith: 1,
                            parentCode: getParentFiasId(values, personType, FiasElementType.BUILDNUM),
                            houseNum: getParentName(values, personType, FiasElementType.HOUSE),
                        })}
                        onChange={(fieldValue: any) => {
                            handleBuildingOnChange(fieldValue.value);
                        }}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-building`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.BUILDNUM),
                        })}
                        isChangeKeyboard
                    />
                </div>
            </div>

            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-structure')}
                        options={[{label: 'Не выбрано', value: null}]}
                        name={`${personType}-addressFias-structure`}
                        loadOptions={getLoadOptions({
                            type: FiasElementTypeApi.STRUCNUM,
                            minLengthStartsWith: 1,
                            parentCode: getParentFiasId(values, personType, FiasElementType.STRUCNUM),
                            houseNum: getParentName(values, personType, FiasElementType.HOUSE),
                            buildNum: getParentName(values, personType, FiasElementType.BUILDNUM),
                        })}
                        onChange={(fieldValue: any) => {
                            handleStructOnChange(fieldValue.value);
                        }}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-structure`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            // В forceDisabled передаем FiasElementType.BUILDNUM для разблокировки
                            // полей Корп., Стр./Соор., Кв. в соответсвии с бизнес логикой в RAMIEPT-165015
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.BUILDNUM),
                        })}
                        isChangeKeyboard
                    />
                </div>
            </div>

            <div className={cn('address-data__container')}>
                <div className={cn('address-data__form-field-weight')}>
                    <AutocompleteSelectField
                        label={t('address-data-room')}
                        options={[{label: 'Не выбрано', value: null}]}
                        name={`${personType}-addressFias-room`}
                        loadOptions={getLoadOptions({
                            type: FiasElementTypeApi.FLAT,
                            minLengthStartsWith: 1,
                            parentCode: getParentFiasId(values, personType, FiasElementType.ROOM),
                            loadOptionsDisable: isKeyboardStrucnum || (isEmptyStrucnum && isKeyboardBuildnum),
                        })}
                        onChange={() => updateAddressState(FiasElementType.ROOM)}
                        disabled={shouldBeDisabled({
                            name: `${personType}-addressFias-room`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                            // В forceDisabled передаем FiasElementType.BUILDNUM для разблокировки
                            // полей Корп., Стр./Соор., Кв. в соответсвии с бизнес логикой в RAMIEPT-165015
                            forceDisabled: isParentValueDropped(values, personType, FiasElementType.BUILDNUM),
                        })}
                        isChangeKeyboard
                    />
                </div>
            </div>
        </div>
    );
}
