import classNames from 'classnames/bind';
import * as React from 'react';

import {Icons} from '@ui-kit';

import * as styles from './icon.scss';

export enum IconType {
    INPUT_ICON = 'INPUT_ICON',
    LINK_ICON = 'LINK_ICON'
}

const baseIconClass = {
    [IconType.INPUT_ICON]: 'input-icon',
    [IconType.LINK_ICON]: 'link-icon',
};

interface IconProps {
    isOpen: boolean,
    iconType: IconType
}

const cn = classNames.bind(styles);

export function Icon({isOpen, iconType}: IconProps) {
    const IconComponent = isOpen ? Icons.ARROW_UP : Icons.ARROW_DOWN;
    const baseClass = baseIconClass[iconType];

    return (
        <span className={cn(baseClass, isOpen && `${baseClass}--opened`)}>
            <IconComponent />
        </span>
    );
}
