import {isFunction} from '@utils/functions';

import {ColumnHeader} from './columns-popup-types';

const getString = (headerFunctionValue: any) => (
    typeof headerFunctionValue === 'string' ? headerFunctionValue : ''
);

export const getLabel = (columnHeader: ColumnHeader): string => (
    isFunction(columnHeader) ? getString(columnHeader) : columnHeader
);
