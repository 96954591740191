import {SYSTEM} from '@app/store/model-constants';
import {ConfigsInterface} from '@app/store/types';
import {
    createActionFactory,
    DispatchType,
    AsyncActionType,
} from '@app/utils/redux';
import {
    getEgarantProperties,
    getCaptchaPublicKey,
} from '@modules/system';
import {executeAsyncAction} from '@modules/model/actions';
import {ActionTypes} from '@utils/types';

const systemActionCreator = createActionFactory(SYSTEM);

const setFeEgarantConfigs = systemActionCreator<ConfigsInterface>(ActionTypes.SET_FE_EGARANT_CONFIGS);
export const setIsAgreementCaptchaVisible = systemActionCreator<Boolean>(ActionTypes.SET_IS_AGREEMENT_CAPTCHA_VISIBLE);
export const setIsCompaniesCaptcha = systemActionCreator<Boolean>(ActionTypes.SET_IS_COMPANIES_CAPTCHA);
export const setIsRestoreCaptcha = systemActionCreator<Boolean>(ActionTypes.SET_IS_RESTORE_CAPTCHA);
export const setCaptchaPublicKey = systemActionCreator<String>(ActionTypes.SET_CAPTCHA_PUBLIC_KEY);
export const setCaptchaVerifyKey = systemActionCreator<String>(ActionTypes.SET_CAPTCHA_VERIFY_KEY);
export const setServerTime = systemActionCreator<string>(ActionTypes.SET_SERVER_TIME);

export function loadCaptchaPublicKey(): AsyncActionType {
    return async (dispatch: DispatchType) => {
        const data = await getCaptchaPublicKey();
        dispatch(setCaptchaPublicKey(data.publicKey));
    };
}

export function loadEgarantProperties(): DispatchType {
    return (dispatch: DispatchType) => executeAsyncAction(dispatch, () => getEgarantProperties()
        .then(result => {
            dispatch(setFeEgarantConfigs(result));

            if (result.feEgarantCapthcaEnable) {
                dispatch(loadCaptchaPublicKey());
            }
        }));
}
