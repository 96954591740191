import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {logoutAction} from '@app/store/actions';
import {contractIdSelector} from '@modules/contract';

import {SignOut} from './sign-out';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
});

const mapDispatchToProps = {
    logoutAction,
};

export const SignOutContainer = connect(mapStateToProps, mapDispatchToProps)(SignOut);
