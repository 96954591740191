import classNames from 'classnames/bind';
import * as React from 'react';
import {Redirect} from 'react-router';

import {RESTORE_PAGE} from '@app/urls';

import {FormLogin} from './components/form-login';
import {TextInformation} from './components/text-information';

import * as styles from './login-page.scss';

const cn = classNames.bind(styles);

interface LoginPageInterface {
    setContractId: (value: string) => void;
    initializeAuth: (contractId: string) => void;
    forgotPassword: boolean;
    match: {
        params: {
            contractId: string;
            skDomain: string;
        };
    };
}

export function LoginPage({
    setContractId,
    initializeAuth,
    forgotPassword,
    match,
}: LoginPageInterface) {
    React.useEffect(() => {
        setContractId(match.params.contractId);
        initializeAuth(match.params.contractId);
    }, []);

    if (forgotPassword) {
        return <Redirect to={`/${match.params.skDomain}/${RESTORE_PAGE}/${match.params.contractId}`} />;
    }

    return (
        <div className={cn('login')}>
            <TextInformation />
            <FormLogin
                contractId={match.params.contractId}
            />
        </div>
    );
}
