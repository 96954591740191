import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/components/navigation/navigation.dic.json';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';
import {isDisabledInterface} from '@utils/isDisabled';

const t = createTranslator(dictionary);

interface ButtonApplyPolicyInterface {
    contractId: string
    isLkBlocked: boolean;
    isAmountInLimits: boolean;
    insCompanyUrl: string;
    isDisabled: (props: isDisabledInterface) => boolean;
    openPaymentModal: (contractId: string, insCompanyUrl: string, skDomain: string) => void;
    skDomain: string;
}

export function ButtonApplyPolicy({
    contractId,
    isLkBlocked,
    isAmountInLimits,
    insCompanyUrl,
    isDisabled,
    openPaymentModal,
    skDomain,
}: ButtonApplyPolicyInterface) {
    return (
        <Button
            label={t('navigation.button.apply.policy')}
            primary
            disabled={isDisabled({isAmountInLimits, isLkBlocked})}
            onClick={() => (openPaymentModal(contractId, insCompanyUrl, skDomain))}
        />
    );
}
