
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {loadDictionariesRegistration} from '@app/pages/contract-page/contract-page-actions';
import {fetchRegistrationData} from '@app/pages/registration-page/registration-actions';
import {setCurrentStage} from '@app/store/actions';
import {companyPremiumSelector} from '@modules/companies';
import {dictionariesSelector} from '@modules/contract';
import {overlayVisibilitySelector} from '@modules/overlay';
import {userDataSelector} from '@modules/user';

import {FormRegistration} from './form-registration';

const mapStateToProps = createStructuredSelector({
    dictionaries: dictionariesSelector,
    isLoadingUserList: overlayVisibilitySelector,
    user: userDataSelector,
    companyPremium: companyPremiumSelector,
});

const mapDispatchToProps = {
    fetchRegistrationData,
    setCurrentStage,
    loadDictionariesRegistration,
};

export const FormRegistrationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormRegistration);
