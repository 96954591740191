import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {
    DriversFormInterface,
} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {TypeCategoryDto} from '@app/types';
import {createTranslator} from '@app/utils/i18n';

import {FormField} from '../form-field';

import * as styles from '../../form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const DISPLAY_HEADER_DRIVER = [0, 4, 8, 12];

export function Drivers({
    size,
    typeCategory,
    noLimits,
}: {
    size: number,
    typeCategory: TypeCategoryDto,
    noLimits: boolean,
}) {
    const fields = new Map<keyof DriversFormInterface, string>();
    if (size > 0) {
        fields.set('drivers1FullName', 'form-container-drivers-full-name-label');
        fields.set('drivers1Birthdate', 'form-container-drivers-birthdate-label');
        fields.set('drivers1LicenseDrivers', 'form-container-drivers-license-drivers-label');
        fields.set('drivers1StartExperience', 'form-container-drivers-start-experience-label');
    }
    if (size > 1) {
        fields.set('drivers2FullName', 'form-container-drivers-full-name-label');
        fields.set('drivers2Birthdate', 'form-container-drivers-birthdate-label');
        fields.set('drivers2LicenseDrivers', 'form-container-drivers-license-drivers-label');
        fields.set('drivers2StartExperience', 'form-container-drivers-start-experience-label');
    }
    if (size > 2) {
        fields.set('drivers3FullName', 'form-container-drivers-full-name-label');
        fields.set('drivers3Birthdate', 'form-container-drivers-birthdate-label');
        fields.set('drivers3LicenseDrivers', 'form-container-drivers-license-drivers-label');
        fields.set('drivers3StartExperience', 'form-container-drivers-start-experience-label');
    }
    if (size > 3) {
        fields.set('drivers4FullName', 'form-container-drivers-full-name-label');
        fields.set('drivers4Birthdate', 'form-container-drivers-birthdate-label');
        fields.set('drivers4LicenseDrivers', 'form-container-drivers-license-drivers-label');
        fields.set('drivers4StartExperience', 'form-container-drivers-start-experience-label');
    }

    return (
        <div className={cn('form-container__form-container')}>
            <h3 className={cn('form-container__sub-header')}>
                {t('form-container-drivers-header')}
            </h3>
            <div className={cn('form-container__form')}>
                {
                    noLimits ?
                        (
                            <div className={cn('form-container__no-limits-text')}>
                                {t('form-container-drivers-no-limits')}
                            </div>
                        ) :
                        Array.from(fields.entries()).map(([name, label]: [keyof DriversFormInterface, string], id) => (
                            <div className={cn('form-container__driver-wrapper')} key={name}>
                                {
                                    DISPLAY_HEADER_DRIVER.includes(id) && (
                                        <h3 className={cn('form-container__sub-small-header')}>
                                            {t(`form-container-drivers-driver-${id}`)}
                                        </h3>
                                    )
                                }
                                <FormField
                                    name={name}
                                    label={t(label, typeCategory?.shortName)}
                                />
                            </div>
                        ))
                }
            </div>
        </div>
    );
}
