import classNames from 'classnames/bind';
import * as React from 'react';

import {
    getNowDate,
    isDatesEqual,
    MonthDateType,
} from '@utils/date';
import {noop} from '@utils/functions';

import * as styles from './day-view.scss';

const cn = classNames.bind(styles);

const isSelected = (date: Date, ...selectedDates: Date[]): boolean => (
    selectedDates.some(selectedDate => selectedDate && isDatesEqual(date, selectedDate))
);

const isCurrentDay = (date: Date): boolean => isDatesEqual(date, getNowDate());

const isFocused = (date: Date, focusedDate: Date): boolean => isDatesEqual(date, focusedDate);

interface DayProps {
    dayInfo: MonthDateType;
    viewDate: Date;
    focusedDate: Date;
    firstSelectedDate?: Date;
    secondSelectedDate?: Date;
    onChange: (date: Date) => void;
    onFocus: (date: Date) => void;
    isFirst: boolean;
    isLast: boolean;
    isDayOff: boolean;
    availableDates?: [Date, Date?]
}

export function Day({
    dayInfo,
    viewDate,
    focusedDate,
    firstSelectedDate,
    secondSelectedDate,
    onChange,
    onFocus,
    isFirst,
    isLast,
    isDayOff,
    availableDates,
}: DayProps) {
    const {date, day, month} = dayInfo;

    const [minAvailableDate, maxAvailableDate] = availableDates || [];
    const unavailable = availableDates && (date < minAvailableDate || (maxAvailableDate && date > maxAvailableDate));
    const disabled = month !== viewDate.getMonth() || unavailable;
    const selected = isSelected(date, firstSelectedDate, secondSelectedDate);

    const className = cn('day-view__day', {
        'day-view__day--day-off': isDayOff,
        'day-view__day--today': isCurrentDay(date),
        'day-view__day--selected': selected,
        'day-view__day--focused': isFocused(date, focusedDate),
        'day-view__day--disabled': disabled,
        'day-view__day--first': isFirst,
        'day-view__day--last': isLast,
    });
    const onSelectNewDateHandler = !(selected || disabled) ? () => onChange(date) : noop;
    const onFocusDateHandler = !disabled ? () => onFocus(date) : noop;

    return (
        <span
            role="button"
            className={className}
            onClick={onSelectNewDateHandler}
            onKeyPress={onSelectNewDateHandler}
            onFocus={onFocusDateHandler}
            onMouseOver={onFocusDateHandler}
        >
            <div className={cn('day-view__label')}>
                {day}
            </div>
        </span>
    );
}
