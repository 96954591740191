import {createActionFactory} from '@utils/redux';

import {Mismatch} from '@app/types';
import {ActionTypes} from '@utils/types';

import {MODALS} from '@app/store/model-constants';
import {addOpenModalsParams, removeOpenModalsParams} from '../../modules/modals/modals-registry';

const actionCreator = createActionFactory(MODALS);

export const showModalAction = actionCreator<string>(ActionTypes.SHOW_MODAL);
export const hideModalAction = actionCreator(ActionTypes.HIDE_MODAL);
export const setIsBadRequestModalShown = actionCreator<Boolean>(ActionTypes.SET_IS_BAD_REQUEST_MODAL);
export const setMismatchData = actionCreator<Mismatch[]>(ActionTypes.SET_MISMATCH_DATA);

export const hideModal = () => (dispatch: any) => {
    removeOpenModalsParams();
    dispatch(hideModalAction());
};

export const showModal = (type: string, params: object = {}) => (dispatch: any) => {
    addOpenModalsParams(params);
    dispatch(showModalAction(type));
};
