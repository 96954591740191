interface FormatSumInterface {
    value: number;
    removeEmptyValue?: boolean;
    noFractionalPart?: boolean;
}

export function formatSum(options: FormatSumInterface): string {
    const {
        value,
        removeEmptyValue,
        noFractionalPart,
    } = options;

    if (removeEmptyValue && (value === 0 || Number.isNaN(value) || value === undefined)) {
        return '';
    }
    if (noFractionalPart) {
        return String(Math.trunc(value));
    }

    return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
}
