import {SystemInterface, ConfigsInterface} from '@app/store/types';
import {handleActions} from '@utils/redux';
import {ActionTypes} from '@utils/types';

import {SYSTEM} from '../model-constants';

const systemInitialState: SystemInterface = {
    configs: {
        feEgarantCapthcaEnable: null,
        feEgarantCheckResultsInterval: null,
        webSiteRSA: null,
    },
    captcha: {
        isAgreementCaptchaVisible: false,
        isCompaniesCaptchaVisible: false,
        isRestoreCaptchaVisible: false,
        captchaPublicKey: '',
        captchaVerifyKey: '',
    },
    serverTime: null,
};

const systemHandler = handleActions(SYSTEM);

export const systemReducer = systemHandler({
    [ActionTypes.SET_FE_EGARANT_CONFIGS]: (state: SystemInterface, {payload}: {payload: ConfigsInterface}) => ({
        ...state,
        configs: payload,
    }),

    [ActionTypes.SET_IS_AGREEMENT_CAPTCHA_VISIBLE]: (state: SystemInterface, {payload}: {payload: boolean}) => ({
        ...state,
        captcha: {
            ...state.captcha,
            isAgreementCaptchaVisible: payload,
        },
    }),

    [ActionTypes.SET_IS_COMPANIES_CAPTCHA]: (state: SystemInterface, {payload}: {payload: boolean}) => ({
        ...state,
        captcha: {
            ...state.captcha,
            isCompaniesCaptchaVisible: payload,
        },
    }),

    [ActionTypes.SET_IS_RESTORE_CAPTCHA]: (state: SystemInterface, {payload}: {payload: boolean}) => ({
        ...state,
        captcha: {
            ...state.captcha,
            isRestoreCaptchaVisible: payload,
        },
    }),

    [ActionTypes.SET_CAPTCHA_PUBLIC_KEY]: (state: SystemInterface, {payload}: {payload: string}) => ({
        ...state,
        captcha: {
            ...state.captcha,
            captchaPublicKey: payload,
            captchaVerifyKey: '',
        },
    }),

    [ActionTypes.SET_CAPTCHA_VERIFY_KEY]: (state: SystemInterface, {payload}: {payload: string}) => ({
        ...state,
        captcha: {
            ...state.captcha,
            captchaVerifyKey: payload,
        },
    }),

    [ActionTypes.SET_SERVER_TIME]: (state: SystemInterface, {payload}: {payload: string}) => ({
        ...state,
        serverTime: payload,
    }),
}, systemInitialState);
