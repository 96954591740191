import classNames from 'classnames/bind';
import * as React from 'react';

import {getMismatchesMessages} from '@app/store/actions';
import {Mismatch} from '@app/types';

import * as styles from '../navigation-error/navigation-error.scss';

const cn = classNames.bind(styles);

interface NavigationErrorInterface {
    isLkBlocked: boolean;
    mismatchData: Mismatch[]
}

export function NavigationErrorBeforeContract({
    isLkBlocked,
    mismatchData,
}: NavigationErrorInterface) {
    return (
        <div className={cn('navigation-error')}>
            {
                isLkBlocked && getMismatchesMessages(mismatchData)
            }
        </div>
    );
}
