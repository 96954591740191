import {handleActions} from '@utils/redux';

import {MODULE_NAMESPACE} from './overlay-constants';
import {
    Actions,
    OverlayState,
} from './overlay-types';

const initialState: OverlayState = {
    showCount: 0,
};

const modelsHandler = handleActions(MODULE_NAMESPACE);
export const overlayReducer = modelsHandler({
    [Actions.SHOW_OVERLAY]: (state: OverlayState) => ({...state, showCount: state.showCount + 1}),
    [Actions.HIDE_OVERLAY]: (state: OverlayState) => {
        const {showCount} = state;
        return {
            ...state,
            showCount: showCount > 0 ? showCount - 1 : 0,
        };
    },
}, initialState);
