import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {portalRef} from '../portal-root';

interface PortalProps {
    children?: React.ReactNode
}

export const Portal = ({
    children,
}: PortalProps) => (
    portalRef ?
        ReactDOM.createPortal(
            children,
            portalRef.current
        ) : null
);
