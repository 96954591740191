import {
    CheckResultsWithScansData,
    Driver,
    Drivers,
    DriverDocumentVerificationData,
    Person,
    DocumentTypes,
    ScanFileData,
    VerificationStatus,
    OwnerLawTypes,
    CheckTaskWithScansData,
    ScansStage,
} from '@app/types';
import {groupBy, joinWithSpace} from '@utils/functions';
import {createTranslator} from '@utils/i18n';

import {
    Document,
    UploadedDocumentFile,
    DocumentFileStatuses,
    DriverPerson,
} from './contract-types';

import * as dictionary from './contract.dic.json';

const t = createTranslator(dictionary);

interface getScansStageInterface {
    isUploadedFilesChecked: boolean;
    isUploadedFilesChecking: boolean;
    isNotAllFilesLoaded: boolean;
    isUploadedFilesFailed: boolean;
}

export const getScansStage = ({
    isUploadedFilesChecked,
    isUploadedFilesChecking,
    isNotAllFilesLoaded,
    isUploadedFilesFailed,
}: getScansStageInterface) => {
    const hasCheckedFailedFiles = isUploadedFilesFailed && !isUploadedFilesChecking;

    if (isUploadedFilesChecked) {
        return ScansStage.ALL_SCANS_APPROVED;
    }

    if (!isUploadedFilesFailed && isNotAllFilesLoaded) {
        return ScansStage.NEW_SCANS_TO_UPLOAD;
    }

    if (hasCheckedFailedFiles && !isNotAllFilesLoaded) {
        return ScansStage.SOME_SCANS_TO_EDIT;
    }

    return null;
};

export const getUploadedFile = (
    files: UploadedDocumentFile[],
    fileOrder: number,
    documentType: DocumentTypes,
    documentIndex: number | null = null
) => {
    const file = files.find(file => (
        file.fileOrderInGroup === fileOrder &&
        file.documentType === documentType &&
        file.documentIndex === documentIndex
    ));
    if (!file) {
        return null;
    }

    return file;
};

export const getFullName = ({
    surname,
    name,
    secondName,
    noSecondName,
}: Person) => joinWithSpace([
    surname,
    name,
    !noSecondName && secondName,
]);

const fileListPhotoRegistration = [
    {
        fileOrderInGroup: 1,
        type: t('document-table.file-type.photo'),
        required: true,
    },
    {
        fileOrderInGroup: 2,
        type: t('document-table.file-type.registration'),
        required: true,
    },
];

const fileListPhotoRegistrationNotRequired = [
    {
        fileOrderInGroup: 1,
        type: t('document-table.file-type.photo'),
        required: true,
    },
    {
        fileOrderInGroup: 2,
        type: t('document-table.file-type.registration'),
        required: false,
    },
];

const fileListFrontSideFlipSide = [
    {
        fileOrderInGroup: 1,
        type: t('document-table.file-type.front-side'),
        required: true,
    },
    {
        fileOrderInGroup: 2,
        type: t('document-table.file-type.flip-side'),
        required: true,
    },
];

const fileListFrontSideFlipSideNotRequired = [
    {
        fileOrderInGroup: 1,
        type: t('document-table.file-type.front-side'),
        required: false,
    },
    {
        fileOrderInGroup: 2,
        type: t('document-table.file-type.flip-side'),
        required: false,
    },
];

const fileListOnlyFrontSide = [
    {
        fileOrderInGroup: 1,
        type: t('document-table.file-type.front-side'),
        required: true,
    },
];

const driverNumbersValues = ['первого', 'второго', 'третьего', 'четвертого'];

export const getDocumentsList = (
    ownerFullName: string,
    insurerFullName: string,
    vehicleMaker: string,
    vehicleModel: string,
    drivers: Driver[],
    ownerType: string,
    isOwnerRegistrationRequired: boolean,
    isInsurerRegistrationRequired: boolean,
    isVehicleDocumentRequired: boolean,
    legalPersonFullName?: string,
): Document[] => {
    const isLegalOwner = ownerType === OwnerLawTypes.LEGAL;
    const documentsList: Document[] = [
        {
            type: DocumentTypes.INSURER_DOCUMENT,
            title: t('documents-table.insurer-document.title'),
            hint: t('documents-table.insurer-document.hint'),
            label: insurerFullName,
            documentIndex: null,
            files: isInsurerRegistrationRequired ? fileListPhotoRegistration : fileListPhotoRegistrationNotRequired,
        },
        {
            type: DocumentTypes.OWNER_DOCUMENT,
            title: t('documents-table.owner-document.title'),
            label: isLegalOwner ? legalPersonFullName : ownerFullName,
            documentIndex: null,
            // eslint-disable-next-line no-nested-ternary
            files: isLegalOwner ? fileListOnlyFrontSide : (
                isOwnerRegistrationRequired ? fileListPhotoRegistration : fileListPhotoRegistrationNotRequired),
        },
        {
            type: DocumentTypes.VEHICLE_DOCUMENT,
            title: t('documents-table.vehicle-document.title'),
            hint: t('documents-table.vehicle-document.hint'),
            label: joinWithSpace([vehicleMaker, vehicleModel]),
            documentIndex: null,
            files: isVehicleDocumentRequired ? fileListFrontSideFlipSide : fileListFrontSideFlipSideNotRequired,
        },
        {
            type: DocumentTypes.DIAGNOSTIC_CARD,
            title: t('documents-table.diagnostic-card.title'),
            hint: t('documents-table.diagnostic-card.hint'),
            documentIndex: null,
            files: fileListFrontSideFlipSide,
        },
        ...drivers.map(({driverIndex, person}: Driver): Document => ({
            type: DocumentTypes.DRIVERS_DOCUMENTS,
            title: t('documents-table.driver-license.title', driverNumbersValues[driverIndex]),
            label: getFullName(person),
            documentIndex: driverIndex,
            files: fileListFrontSideFlipSide,
        })),
    ];

    return documentsList;
};

const mapUploadedFilesForCheckScans = (files: UploadedDocumentFile[]) => (
    files && files.map(({fileName, fileGuid, fileOrderInGroup}) => (
        {fileName, fileGuid, fileOrderInGroup}
    ))
);

export function getDriverDocuments(
    drivers: DriverPerson[],
    files: UploadedDocumentFile[],
) {
    return (files && drivers) ? drivers.map((_, index) => {
        const driverFiles = files.filter(f => (
            f.documentIndex === index
        ));
        return ({
            driverIndex: index,
            scanFilesData: mapUploadedFilesForCheckScans(driverFiles),
        });
    }).filter(filesKit => filesKit.scanFilesData.length > 0) : [];
}

export function mapUploadedFilesToCheckScans(
    files: UploadedDocumentFile[],
    drivers: DriverPerson[]
): CheckTaskWithScansData {
    const filesByDocumentType = groupBy(files, 'documentType');

    const driversFiles = filesByDocumentType[DocumentTypes.DRIVERS_DOCUMENTS];
    const insuranceFiles = filesByDocumentType[DocumentTypes.INSURER_DOCUMENT];
    const ownerFiles = filesByDocumentType[DocumentTypes.OWNER_DOCUMENT];
    const vehicleFiles = filesByDocumentType[DocumentTypes.VEHICLE_DOCUMENT];
    const diagnosticCardFiles = filesByDocumentType[DocumentTypes.DIAGNOSTIC_CARD];

    return {
        [DocumentTypes.DIAGNOSTIC_CARD]: diagnosticCardFiles ? {
            scanFilesData: mapUploadedFilesForCheckScans(diagnosticCardFiles),
        } : null,
        [DocumentTypes.DRIVERS_DOCUMENTS]: driversFiles ? getDriverDocuments(drivers, driversFiles) : null,
        [DocumentTypes.INSURER_DOCUMENT]: insuranceFiles ? {
            scanFilesData: mapUploadedFilesForCheckScans(insuranceFiles),
        } : null,
        [DocumentTypes.OWNER_DOCUMENT]: ownerFiles ? {
            scanFilesData: mapUploadedFilesForCheckScans(ownerFiles),
        } : null,
        [DocumentTypes.VEHICLE_DOCUMENT]: vehicleFiles ? {
            scanFilesData: mapUploadedFilesForCheckScans(vehicleFiles),
        } : null,
    };
}

const VerificationStatusValues = {
    [VerificationStatus.IN_PROGRESS]: DocumentFileStatuses.INSURANCE_COMPANY_CHECKING,
    [VerificationStatus.APPROVED]: DocumentFileStatuses.INSURANCE_COMPANY_SUCCESS,
    [VerificationStatus.REJECTED]: DocumentFileStatuses.INSURANCE_COMPANY_FAIL,
};

const getUploadedDocumentsFromVerificationData = (documentType: DocumentTypes, {
    driverIndex,
    scanFilesData = [],
    verificationResult,
}: DriverDocumentVerificationData): UploadedDocumentFile[] => (
    scanFilesData.map((scanFileData: ScanFileData) => ({
        fileName: scanFileData.fileName,
        fileOrderInGroup: scanFileData.fileOrderInGroup,
        fileGuid: scanFileData.fileGuid,
        documentType,
        documentIndex: driverIndex,
        status: VerificationStatusValues[verificationResult.status],
        rejectReasonSum: verificationResult.rejectReasonSum,
        rejectReasons: verificationResult.rejectReasons,
    }))
);

const getUploadedDriversDocuments = (data: CheckResultsWithScansData) => (
    (data[DocumentTypes.DRIVERS_DOCUMENTS] || []).reduce(
        (acc: UploadedDocumentFile[], document: DriverDocumentVerificationData) => ([
            ...acc,
            ...getUploadedDocumentsFromVerificationData(DocumentTypes.DRIVERS_DOCUMENTS, document),
        ]), []
    )
);

type ScansVerificationDataDocumentTypes = DocumentTypes.DIAGNOSTIC_CARD |
    DocumentTypes.INSURER_DOCUMENT | DocumentTypes.OWNER_DOCUMENT | DocumentTypes.VEHICLE_DOCUMENT;
export const getUpdatedUploadedFiles = (data: CheckResultsWithScansData): UploadedDocumentFile[] => (
    [
        DocumentTypes.DIAGNOSTIC_CARD,
        DocumentTypes.INSURER_DOCUMENT,
        DocumentTypes.OWNER_DOCUMENT,
        DocumentTypes.VEHICLE_DOCUMENT,
    ].reduce((acc: UploadedDocumentFile[], documentType: ScansVerificationDataDocumentTypes) => ([
        ...acc,
        ...getUploadedDocumentsFromVerificationData(documentType, {...data[documentType], driverIndex: null}),
    ]), getUploadedDriversDocuments(data))
);

/*
    Используется для вычисления индекса, что есть водитель, который является и страхователем в ответе "/drivers"
    Эта информация нужна для определения какие поля доступны для редактирования на странице "Водители"
    Подробнее в файле './fields-group-enabled' - 'listFieldEntitiesEnabled'
*/
export const getDriverMeets = (drivers: Drivers) => {
    if (!drivers?.driversList) {
        return -1;
    }
    const driverMeets = drivers.driversList.find(driver => driver.meetsWithPolicyholderData);
    return driverMeets ? driverMeets.driverIndex : -1;
};
