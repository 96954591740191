import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {DocumentFileStatuses} from '@modules/contract';
import {createTranslator} from '@utils/i18n';

import {DOCUMENT_FILE_STATUSES_NAMES} from '../../document-table-constants';

import * as styles from './document-file-status.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);


type DocumentFileStatusProps = {
    status?: DocumentFileStatuses;
}

export function DocumentFileStatus({
    status,
}: DocumentFileStatusProps) {
    if (!status) {
        return (
            <div className={cn('file')}>
                {t('file-loader.status.default')}
            </div>
        );
    }

    return (
        <div
            className={cn('file', {
                'file--loading': status === DocumentFileStatuses.LOADING ||
                    status === DocumentFileStatuses.INSURANCE_COMPANY_CHECKING,
                'file--success': status === DocumentFileStatuses.SUCCESS ||
                    status === DocumentFileStatuses.INSURANCE_COMPANY_SUCCESS,
                'file--fail': status === DocumentFileStatuses.FAIL ||
                    status === DocumentFileStatuses.INSURANCE_COMPANY_FAIL,
                'file--wrong-format': status === DocumentFileStatuses.WRONG_FORMAT,
            })}
        >
            {
                DOCUMENT_FILE_STATUSES_NAMES[status]
            }
        </div>
    );
}
