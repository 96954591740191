import * as React from 'react';

import {MatchParams} from '@utils/match-params';

import {CompaniesList} from './components/companies-list';
import {SelectRegion} from './components/select-region';

interface CompaniesPageInterface{
    toggleContractId(value: string): void;
    match: MatchParams;
    loadCaptchaPublicKey: () => void;
    loadRegionName: (contractId: string) => void;
    feEgarantCapthcaEnable: boolean;
}

export function CompaniesPage({
    toggleContractId,
    loadCaptchaPublicKey,
    loadRegionName,
    match,
    feEgarantCapthcaEnable,
}: CompaniesPageInterface) {
    React.useEffect(() => {
        toggleContractId(match.params.contractId);

        if (feEgarantCapthcaEnable) {
            loadCaptchaPublicKey();
        }

        loadRegionName(match.params.contractId);
    }, []);

    return (
        <div>
            <div>
                <SelectRegion />
                <CompaniesList />
            </div>
        </div>
    );
}
