import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/components/navigation/navigation.dic.json';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

const t = createTranslator(dictionary);

interface ButtonCheckDataInterface {
    contractId: string;
    isCheckDataBtnHidden: boolean;
    isValidationChecked: boolean;
    contractValidation: (contractId: string) => void;
    isLkBlocked: boolean;
}

export function ButtonCheckData({
    contractId,
    isCheckDataBtnHidden,
    isValidationChecked,
    contractValidation,
    isLkBlocked,
}: ButtonCheckDataInterface) {
    return (
        <>
            {!isCheckDataBtnHidden && (
                <Button
                    label={t('navigation.button.check.data')}
                    primary
                    disabled={!isValidationChecked || isLkBlocked}
                    onClick={() => contractValidation(contractId)}
                />
            )}
        </>
    );
}
