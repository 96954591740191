import classNames from 'classnames/bind';
import * as React from 'react';

import {Person} from '@app/types';
import {createTranslator} from '@app/utils/i18n';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';

import {SnilsFieldWithHint} from './components/snils-field-with-hint';
import {BirthDateField} from './components/birth-date-field';
import {FioFields} from './components/fio-fields';

import * as styles from './person-data.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface PersonDataInterface {
    personType: string;
    mutator: (field: string, value: string) => void;
    person: Person;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function PersonData({
    personType,
    mutator,
    person,
    scansEditFields,
    isLkBlocked,
}: PersonDataInterface) {
    return (
        <div className={cn('person-data')}>
            <div className={cn('person-data__container')}>
                <FioFields
                    personType={personType}
                    mutator={mutator}
                    person={person}
                    scansEditFields={scansEditFields}
                    isLkBlocked={isLkBlocked}
                />
                <BirthDateField
                    personType={personType}
                    scansEditFields={scansEditFields}
                    isLkBlocked={isLkBlocked}
                />
                <SnilsFieldWithHint
                    personType={personType}
                    scansEditFields={scansEditFields}
                    isLkBlocked={isLkBlocked}
                />
            </div>
        </div>
    );
}
