import classNames from 'classnames/bind';
import * as React from 'react';

import {getMismatchesMessages} from '@app/store/actions';
import {Mismatch} from '@app/types';
import {AppStages} from '@utils/constants';
import {createTranslator} from '@utils/i18n';
import * as dictionaryFlk from '@app/pages/shared-pages/layout-page/flk.dic.json';

import * as dictionary from '../../login-page.dic.json';
import * as styles from './text-information.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);
const f = createTranslator(dictionaryFlk);

export interface TextInformationProps {
    confirmExpiredTimer?: (flkCode: string) => void;
    setCurrentStage?: (newStage: string) => void;
    flkCode: string;
    isLkBlocked: boolean;
    mismatchData: Mismatch[]
}

export function TextInformation({
    confirmExpiredTimer,
    setCurrentStage,
    flkCode,
    isLkBlocked,
    mismatchData,
}: TextInformationProps) {
    React.useEffect(() => {
        setCurrentStage(AppStages.LOGIN);
    });

    if (isLkBlocked && !mismatchData?.length) {
        confirmExpiredTimer(flkCode);
    }
    return (
        <>
            <div className={cn('text-information')}>
                {t('login-page-text-information-time-limits')}
            </div>
            <div className={cn('expired-timer')}>
                {
                    isLkBlocked && (mismatchData?.length ?
                        getMismatchesMessages(mismatchData) :
                        f(flkCode)
                    )
                }
            </div>
        </>
    );
}
