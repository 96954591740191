import {
    DocumentTypes,
    LinkData,
    Person,
} from '@app/types';

export interface DriverPerson extends Person {
    driverIndex?: number;
}

export enum DocumentFileStatuses {
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    WRONG_FORMAT = 'WRONG_FORMAT',

    INSURANCE_COMPANY_CHECKING = 'INSURANCE_COMPANY_CHECK',
    INSURANCE_COMPANY_SUCCESS = 'INSURANCE_COMPANY_SUCCESS',
    INSURANCE_COMPANY_FAIL = 'INSURANCE_COMPANY_FAIL',
}

/*
* type - название типа файла
* fileOrderInGroup - порядок приложенного файла для конкретного документа
* */
export type DocumentFile = {
    type: string;
    fileOrderInGroup: number;
    required: boolean;
};

export type Document = {
    type: DocumentTypes,
    title?: string;
    hint?: string;
    label?: string;
    files?: DocumentFile[];
    documentIndex?: number;
    ownerType?: string;
};


/*
* fileName - название файла
* fileOrderInGroup - порядок приложенного файла для конкретного документа
* fileGuid - id док-та, полученный при загрузке файла
* documentType - название типа документа, для которого приложен файл
* documentIndex - порядок документа (только для документов с типом DocumentTypes.driversDocuments)
* status - статус загрузки/проверки файла
* */
export type UploadedDocumentFile = {
    fileName: string;
    fileOrderInGroup: number;
    fileGuid?: string;

    documentType: DocumentTypes;
    documentIndex?: number;

    status?: DocumentFileStatuses;
    message?: string;
    rejectReasonSum?: string;
    rejectReasons?: string[];
}

export interface LinkDataInterface extends LinkData {
    representativesLink: string;
}
