import classNames from 'classnames/bind';
import * as React from 'react';

import {Anchor} from '@ui-kit';
import {urlFormatter} from '@utils/formatters/url-formatter';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './success-message.dic.json';
import * as styles from './success-message.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface PaymentSuccessMessageInterface {
    policyNumber: string;
    insCompanyUrl: string;
}

export const PaymentSuccessMessage = ({policyNumber, insCompanyUrl}: PaymentSuccessMessageInterface) => (
    <div className={cn('success-message')}>
        <h2 className={cn('success-message__header')}>
            {t('payment-success-message-title')}
        </h2>
        <div className={cn('success-message__content')}>
            <p className={cn('success-message__content__top')}>
                {t('payment-success-message-text-start', policyNumber)}
            </p>
            {insCompanyUrl && (
                <p>
                    {t('payment-success-message-text-end')}
                    <Anchor
                        link={insCompanyUrl}
                        label={urlFormatter(insCompanyUrl)}
                        target="_blank"
                    />
                </p>
            )}
        </div>
    </div>
);
