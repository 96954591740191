import classNames from 'classnames/bind';
import * as React from 'react';
import {Form} from 'react-final-form';

import {ContractDictionaries} from '@app/store/types';
import {PersonRegistrationData} from '@app/types';
import {AppStages} from '@app/utils/constants';
import {formatSum, formatPhoneNumber} from '@app/utils/formatters';
import {
    TextField,
    SelectField,
    DateField,
    PortalRoot,
    CheckboxField,
    Hint,
} from '@ui-kit';
import {DateFormats, formatDate} from '@utils/date';
import {SelectOption} from '@utils/formatters/select-option';
import {toLocalTimeZone} from '@utils/formatters/date';
import {createTranslator} from '@utils/i18n';

import * as dictionary from '../../registration-page.dic.json';
import * as styles from './form-registration.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const printForm = (
    insurerData: PersonRegistrationData,
    identityCardTypes: SelectOption<string>[]
) => (
    <Form
        initialValues={insurerData}
        onSubmit={() => {}}
        render={({values}) => (
            <div className={cn('form-content')}>
                <div className={cn('form-content__container')}>
                    <div className={cn('form-content__form-field-wrapper')}>
                        <div className={cn('form-content__form-field')}>
                            <TextField
                                name="surname"
                                label={t('registration-form-surname')}
                                disabled
                                required
                            />
                        </div>
                        <div className={cn('form-content__form-field')}>
                            <TextField
                                name="name"
                                label={t('registration-form-name')}
                                disabled
                                required
                            />
                        </div>
                    </div>

                    <div className={cn('form-content__form-field-wrapper')}>
                        <div className={cn('form-content__form-field')}>
                            <TextField
                                name="secondName"
                                label={t('registration-form-secondName')}
                                disabled
                                required={!values.noSecondName}
                            />
                        </div>
                        <div className={cn('form-content__form-field-checkbox')}>
                            <CheckboxField
                                name="noSecondName"
                                label={t('registration-form-noSecondName')}
                                isDisabled
                            />
                        </div>
                    </div>

                    <div className={cn('form-content__form-field-weight')}>
                        <PortalRoot />
                        <DateField
                            label={t('registration-form-birthdate')}
                            name="birthdate"
                            isDisabled
                            required
                        />
                    </div>

                    <div className={cn('form-content__form-field-weight')}>
                        <SelectField
                            label={t('registration-form-docType')}
                            options={identityCardTypes}
                            name="docType"
                            disabled
                            required
                        />
                    </div>

                    <div className={cn('form-content__form-field-wrapper')}>
                        <div className={cn('form-content__form-field')}>
                            <TextField
                                name="docSeries"
                                label={t('registration-form-docSeries')}
                                disabled
                                required
                            />
                        </div>
                        <div className={cn('form-content__form-field')}>
                            <TextField
                                name="docNumber"
                                label={t('registration-form-docNumber')}
                                disabled
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className={cn('form-content__container')}>
                    <div className={cn('form-content__form-field-weight')}>
                        <TextField
                            name="phoneNumber"
                            label={t('registration-form-phoneNumber')}
                            disabled
                            required
                        />
                    </div>

                    <div className={cn('form-content__form-field-weight')}>
                        <TextField
                            name="email"
                            label={t('registration-form-email')}
                            disabled
                            required
                        />
                    </div>

                    <div className={cn('form-content__form-field-calculation')}>
                        <TextField
                            name="calculation"
                            label={t('registration-form-calculation')}
                            disabled
                        />
                        <div className={cn('form-content__hint-wrapper')}>
                            <div className={cn('form-content__hint')}>
                                <Hint text={t('registration-page-hint')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    />
);

export interface FormRegistrationInterface {
    dictionaries: ContractDictionaries;
    fetchRegistrationData?: (contractId: string) => void;
    setCurrentStage?: (newStage: string) => void;
    isLoadingUserList: boolean;
    user: PersonRegistrationData;
    contractId: string;
    companyPremium: number;
    loadDictionariesRegistration: () => void;
}

export function FormRegistration({
    dictionaries,
    fetchRegistrationData,
    setCurrentStage,
    user,
    isLoadingUserList,
    contractId,
    companyPremium,
    loadDictionariesRegistration,
}: FormRegistrationInterface) {
    const memoizedCallback = React.useCallback(
        () => {
            setCurrentStage(AppStages.LOGIN);
            fetchRegistrationData(contractId);
            loadDictionariesRegistration();
        },
        []
    );
    const insurerData = {
        ...user,
        birthdate: formatDate(toLocalTimeZone(user.birthdate), DateFormats.YYYYMMDD_HYPHEN),
        calculation: formatSum({value: companyPremium}),
        phoneNumber: formatPhoneNumber(user.phoneNumber),
    };

    React.useEffect(() => {
        memoizedCallback();
    }, []);

    return (
        <div className={cn('form')}>
            <div className={cn('form__wrapper-center')}>
                {!isLoadingUserList &&
                printForm(insurerData, dictionaries.identityCardTypes)}
            </div>
        </div>
    );
}
