import * as React from 'react';

import {Field} from '../field';
import {DatePickerWithFieldAdapter, DatePickerWithFieldAdapterProps} from './date-picker-with-field-adapter';

interface DateFieldProps extends DatePickerWithFieldAdapterProps {}

export function DateField(props: DateFieldProps) {
    const {name, ...restProps} = props;
    return (
        <Field
            {...restProps}
            name={name}
            component={DatePickerWithFieldAdapter}
        />
    );
}
