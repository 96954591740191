import * as React from 'react';
import {positionValues} from 'react-custom-scrollbars';

import {SelectOption} from '@utils/formatters/select-option';

export interface ListContainerProps {
    countVisibleOption?: number,
    emptyOptionMessage?: string,
    getLabel?: (item: SelectOption<any>) => string,
    getValue?: (item: SelectOption<any>) => any,
    options: SelectOption<any>[],
    selectedOption?: SelectOption<any>,
    focusedOption?: SelectOption<any>,
    onClick: (option: SelectOption<any>) => void,
    isOpen: boolean,
    onScroll?: (values: positionValues) => void;
    isLoading?: boolean;
    selectRef: React.RefObject<HTMLDivElement>,
    list: (props: any) => JSX.Element
}

export function ListContainer({
    list: OptionList,
    emptyOptionMessage,
    options,
    countVisibleOption,
    getValue,
    getLabel,
    selectedOption,
    focusedOption,
    onClick,
    isOpen,
    onScroll,
    isLoading,
    selectRef,
}: ListContainerProps) {
    return (
        isOpen && (
            <OptionList
                emptyOptionMessage={emptyOptionMessage}
                options={options}
                countVisibleOption={countVisibleOption}
                getValue={getValue}
                getLabel={getLabel}
                selectedOption={selectedOption}
                focusedOption={focusedOption}
                selectRef={selectRef}
                onClick={onClick}
                onScroll={onScroll}
                isLoading={isLoading}
            />
        )
    );
}
