import classNames from 'classnames/bind';
import * as React from 'react';

import {Select} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as dictionary from '../../companies-list-page.dic.json';
import * as styles from './select-region.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const FIELD_WIDTH = 800;

export function SelectRegion({
    regionName,
}: {
    regionName: string;
}) {
    const SELECT_REGION_TYPE_OPTIONS = [{
        label: regionName,
        value: regionName,
    }];

    return (
        <div className={cn('select-region')}>
            <h2 className={cn('select-region__header')}>
                {t('select-region-page-title')}
            </h2>
            <p className={cn('select-region__header-text')}>
                {t('select-region-page-title-text')}
            </p>
            <div className={cn('select-region__wrapper')}>
                <div className={cn('select-region__select-field')}>
                    <Select
                        label={t('select-region-page-field-label')}
                        width={FIELD_WIDTH}
                        options={SELECT_REGION_TYPE_OPTIONS}
                        selectedOption={SELECT_REGION_TYPE_OPTIONS[0]}
                        disabled
                        required
                    />
                </div>

                <div className={cn('select-region__text-info')}>
                    <ul className={cn('select-region__list')}>
                        <li className={cn('select-region__list-item')}>
                            {t('select-region-page-individuals-text')}
                        </li>
                        <li className={cn('select-region__list-item')}>
                            {t('select-region-page-legal-entities-text')}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
