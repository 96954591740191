import {SortDirections} from '@utils/constants';

import {
    Column,
    SortCriteria,
} from '../../../data-table-types';

export const getInitialSortedColumn = (columns: Column[]) => (
    columns.find(column => column.isInitialSorted)
);

export const getNewSortDirection = (prevDirection: string) => (
    prevDirection === SortDirections.ASC ?
        SortDirections.DESC :
        SortDirections.ASC
);

export const sortRows = (rows: any[], {direction, name}: SortCriteria = {}) => {
    if (!rows || !rows.length || !direction || !name) {
        return rows;
    }

    return rows.sort((row1: any, row2: any) => {
        const sort = direction === SortDirections.ASC ? 1 : -1;
        if (row1[name] === row2[name]) {
            return 0;
        }

        return row1[name] > row2[name] ? sort : sort * -1;
    });
};
