import {createActionFactory, DispatchType} from '@utils/redux';

import {MODULE_NAMESPACE} from './notifications-constants';
import {
    ACTIONS, MessageType, NotificationData, NotificationPayload,
} from './notifications-types';
import {getNotificationId} from './notifications-utils';

const actionCreator = createActionFactory(MODULE_NAMESPACE);

export const saveNotification = actionCreator<NotificationData>(ACTIONS.SAVE_NOTIFICATION);

export function addNotification(notification: NotificationPayload): DispatchType {
    return (dispatch: DispatchType): void => {
        const notificationId = getNotificationId();
        const creationDate = Date.now();

        dispatch(saveNotification({
            ...notification,
            id: notificationId,
            creationDate,
        }));
    };
}

export const errorNotification = (message: string) => addNotification({message, type: MessageType.ERROR});

export const successNotification = (message: string) => addNotification({message, type: MessageType.SUCCESS});

export const removeNotification = actionCreator<any>(ACTIONS.REMOVE_NOTIFICATION);

export const cleanNotifications = actionCreator(ACTIONS.CLEAN_NOTIFICATIONS);
