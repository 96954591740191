import classNames from 'classnames/bind';
import * as React from 'react';

import {GenericFontIconProps} from './font-icon-types';

import * as styles from './font-icon.scss';

const cn = classNames.bind(styles);

export function FontIcon({name, size = 16, rotateDegree = 0}: GenericFontIconProps) {
    return (
        <i
            className={cn('font-icon')}
            style={{
                fontSize: size,
                width: size,
                transform: `rotate(${rotateDegree}deg)`,
            }}
        >
            {name}
        </i>
    );
}
