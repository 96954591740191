import {createTranslator} from '@utils/i18n';

import * as dictionary from './validators.dic.json';

const t = createTranslator(dictionary);

export const regExpValidator = (regExp: RegExp, errorMessage: string = t('regExp.error.text')) => (value: string) => {
    if (!regExp.test(value)) {
        return errorMessage;
    }

    return '';
};
