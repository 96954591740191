import classNames from 'classnames/bind';
import * as React from 'react';
import {Form} from 'react-final-form';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {Step4FormInterface} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {
    convertContractToFormValues,
} from '@app/pages/contract-page/pages/step-4/step4-convert-utils';
import {ContractDictionaries} from '@app/store/types';
import {
    Insurer as InsurerInterface,
    Drivers as DriversInterface,
    Owner as OwnerInterface,
    Vehicle as VehicleInterface,
    OwnerLawTypes,
    OwnerInsurerAddresses,
} from '@app/types';
import {createTranslator} from '@app/utils/i18n';

import {
    Insurer,
    Owner,
    LegalOwner,
    Vehicle,
    Drivers,
    InsurancePeriod,
    PeriodUseVehicle,
} from './components';

import * as styles from './form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface FormContainerInterface {
    contractId?: string;
    insurer: InsurerInterface;
    owner: OwnerInterface;
    drivers: DriversInterface;
    vehicle: VehicleInterface;
    isLoadingContract: boolean;
    dictionaries: ContractDictionaries;
    addresses: OwnerInsurerAddresses;
    loadFourthPageOpened: (contractId: string) => void;
}

export function FormContainer({
    contractId,
    insurer,
    owner,
    drivers,
    vehicle,
    isLoadingContract,
    dictionaries,
    loadFourthPageOpened,
    addresses,
}: FormContainerInterface) {
    React.useEffect(() => {
        loadFourthPageOpened(contractId);
    }, []);

    const items: Step4FormInterface = React.useMemo(() => (
        convertContractToFormValues({
            insurer,
            owner,
            vehicle,
            drivers,
            addresses,
        }, dictionaries)
    ), [insurer, owner, vehicle, drivers, addresses, dictionaries]);

    return (
        <>
            {!isLoadingContract && (
                <div className={cn('form-container')}>
                    <div className={cn('form-container__sub-container')}>
                        <div className={cn('form-container__wrapper-center')}>
                            <h2 className={cn('form-container__header')}>
                                {t('form-container-header')}
                            </h2>
                        </div>
                    </div>

                    <div className={cn('form-container__sub-container')}>
                        <div className={cn('form-container__wrapper-center')}>
                            <Form
                                initialValues={items}
                                onSubmit={() => {}}
                                render={() => (
                                    <div className={cn('form-container__sub-container-wrapper')}>
                                        <div className={cn('form-container__container-left')}>
                                            <Insurer items={items} />
                                            {
                                                owner?.ownerType === OwnerLawTypes.PHYSICAL ?
                                                    <Owner items={items} /> :
                                                    <LegalOwner items={items} />
                                            }
                                            <Vehicle items={items} />
                                        </div>

                                        <div className={cn('form-container__container-right')}>
                                            <Drivers
                                                noLimits={drivers?.noLimits}
                                                size={drivers?.driversList?.length}
                                                typeCategory={items?.vehicleTypeCategory}
                                            />
                                            <InsurancePeriod
                                                insurancePeriodStart={items.insurancePeriodStart}
                                                insurancePeriodEnd={items.insurancePeriodEnd}
                                                vehicleIsShortInsurancePolicy={
                                                    items.vehicleIsShortInsurancePolicy
                                                }
                                            />
                                            <PeriodUseVehicle
                                                usePeriod1={items.usePeriod1}
                                                usePeriod2={items.usePeriod2}
                                                usePeriod3={items.usePeriod3}
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
