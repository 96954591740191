import {FiasElement, FiasStructElement} from '@app/types';

export interface Insurer {
    isOwner: boolean;
    isDriver: boolean;
    person: Person;
    document: PersonDocument;
    addressFias: AddressInfo;
    house?: string;
    room?: string;
}

export interface Owner {
    ownerType: OwnerLawTypes;
    isDriver: boolean;
    person: Person;
    legalPerson: LegalPerson;
    legalPersonDocument: LegalPersonDocument;
    personDocument: PersonDocument;
    addressFias: AddressInfo;
    house?: string;
    room?: string;
}

export interface Driver {
    meetsWithPolicyholderData: boolean;
    driverIndex: number;
    person: Person;
    driverLicense: DriverLicence;
}

export interface Drivers {
    noLimits: boolean;
    driversList: Driver[];
}

export interface PersonRegistrationData extends Person, Contacts, PersonDocument {}

interface Contacts {
    phoneNumber: string;
    email: string;
}

export interface Person {
    name: string;
    secondName: string;
    surname: string;
    birthdate: string;
    phoneNumber: string;
    noSecondName: boolean;
    snils?: string;
}

export interface PersonDocument {
    docType: string;
    docSeries: string;
    docNumber: string;
}

export interface LegalPerson {
    INN: string;
    fullName: string;
}

export interface LegalPersonDocument {
    documentNumber: string;
    documentSeries: string;
    documentType: string;
    accountingDate: string;
}

export interface DriverLicence {
    series: string;
    number: string;
    startDate: string;
}

export interface AddressInfo {
    region: FiasElement;
    district: FiasElement;
    city: FiasElement;
    locality: FiasElement;
    street: FiasElement;
    house: FiasElement;
    building: FiasElement;
    structure: FiasStructElement;
    room: FiasElement;
}

export enum OwnerLawTypes {
    PHYSICAL = 'PHYSICAL',
    LEGAL = 'LEGAL',
}

export enum PersonType {
    INSURER = 'insurer',
    OWNER = 'owner',
    DRIVERS = 'drivers',
    VEHICLE = 'vehicle',
}

export interface OwnerInsurerAddresses {
    insurerFullAddress: string,
    ownerFullAddress: string
}
