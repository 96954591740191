import classNames from 'classnames/bind';
import * as React from 'react';

import {AccordionItem} from './accordion-item';

import * as styles from './accordion.scss';

export interface AccordionProps {
    /** array of items */
    items: any[]
    getBody: (item: any) => React.ReactNode,
    /* for cases when we wish to pass custom header */
    getHeader?: (item: any) => React.ReactNode,
    /** whether accordion's panel should have additional content */
    getPanelContent?: (item: any) => React.ReactNode,
    /** gets called on open/close items with indexes of open items */
    onPanelStateChange: (item: any, opened: boolean) => void,
    getTitle: (item: any) => React.ReactNode,
    /** object of expanded item */
    openedItem?: any
}

const cn = classNames.bind(styles);

const getKey = (idx: number) => idx;

export function Accordion({
    items,
    getBody,
    getHeader,
    getPanelContent,
    onPanelStateChange,
    getTitle,
    openedItem,
}: AccordionProps) {
    return (
        <div
            className={cn('accordion')}
        >
            {items.map((item: any, idx: number) => {
                const opened = item === openedItem;
                return (
                    <AccordionItem
                        key={getKey(idx)}
                        title={getTitle(item)}
                        opened={opened}
                        panelContent={getPanelContent && opened ? getPanelContent(item) : null}
                        body={opened ? getBody(item) : null}
                        header={getHeader && opened && getHeader(item)}
                        onStateChange={() => onPanelStateChange(item, !opened)}
                    />
                );
            })}
        </div>
    );
}

Accordion.defaultProps = {
    items: [],
    getPanelContent: null,
    openedItem: null,
    filterable: false,
    getHeader: null,
};
