
import {isNaN, isNil} from 'lodash-es';

import {
    periodOptions,
} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods/use-period-selectors';
import {Step2FormInterface} from '@app/pages/contract-page/pages/step-2/step2-types';
import {
    OTHER_MAKER_MOCK,
    OTHER_MODEL_MOCK,
    TypeCategory,
} from '@app/pages/contract-page/pages/step-2/utils';
import {ContractDictionaries} from '@app/store/types';
import {
    MakerDto,
    ModelDto,
    OwnerLawTypes,
    TypeCategoryDto,
    Vehicle,
} from '@app/types';
import {
    convertToHoursMinutes,
    convertToTimeHHMM,
    toContractDateFormat,
    toContractTimeZone,
    toLocalTimeZone,
} from '@utils/formatters/date';
import {objectToSelectOption} from '@utils/formatters/select-option';
import {NAME} from '@utils/constants';

export const convertContractVehicleToFormValues = (
    {
        diagnosticCard,
        document,
        ...vehicle
    }: Vehicle,
    {vehicleDocumentTypes, vehiclePurposeTypes}: ContractDictionaries,
): Step2FormInterface => {
    const startDate = vehicle.insuranceStartDate ? new Date(toLocalTimeZone(vehicle.insuranceStartDate)) : new Date();
    const endDate = vehicle.insuranceEndDate ? new Date(toLocalTimeZone(vehicle.insuranceEndDate)) : new Date();
    return {
        ...vehicle,
        maker: vehicle?.maker ? objectToSelectOption<MakerDto>(vehicle?.maker, NAME) : OTHER_MAKER_MOCK,
        model: vehicle?.model ? objectToSelectOption<ModelDto>(vehicle?.model, NAME) : OTHER_MODEL_MOCK,
        typeCategory: objectToSelectOption<TypeCategoryDto>(vehicle?.typeCategory, 'fullName'),
        'document-documentType': vehicleDocumentTypes.find(el => el.value === document?.documentType) || null,
        'document-series': document?.series,
        'document-number': document?.number,
        'document-dateGiven': toLocalTimeZone(document?.dateGiven),
        'diagnosticCard-dateEnd': toLocalTimeZone(diagnosticCard?.dateEnd),
        'diagnosticCard-number': diagnosticCard?.number,
        insuranceStartTime: convertToTimeHHMM(new Date(toLocalTimeZone(vehicle?.insuranceStartDate))),
        insuranceEndTime: convertToTimeHHMM(new Date(toLocalTimeZone(vehicle?.insuranceEndDate))),
        insuranceStartDate: vehicle?.insuranceStartDate ?
            new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()).toISOString() :
            null,
        insuranceEndDate: vehicle?.insuranceEndDate ?
            new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()).toISOString() :
            null,
        isOnTheWayToRegistration: String(Boolean(vehicle?.isOnTheWayToRegistration)),
        isUsedWithTrailer: String(Boolean(vehicle?.isUsedWithTrailer)),
        seatsAmount: vehicle?.seatsAmount ? String(vehicle?.seatsAmount) : null,
        maxWeight: vehicle?.maxWeight ? String(vehicle?.maxWeight) : null,
        power: vehicle?.power ? String(vehicle?.power) : null,
        usePeriod1: periodOptions.find(el => el.value === vehicle?.usePeriod1) || null,
        usePeriod1StartDate: toLocalTimeZone(vehicle?.usePeriod1StartDate),
        usePeriod1EndDate: toLocalTimeZone(vehicle?.usePeriod1EndDate),
        usePeriod2: periodOptions.find(el => el.value === vehicle?.usePeriod2) || null,
        usePeriod2StartDate: toLocalTimeZone(vehicle?.usePeriod2StartDate),
        usePeriod2EndDate: toLocalTimeZone(vehicle?.usePeriod2EndDate),
        usePeriod3: periodOptions.find(el => el.value === vehicle?.usePeriod3) || null,
        usePeriod3StartDate: toLocalTimeZone(vehicle?.usePeriod3StartDate),
        usePeriod3EndDate: toLocalTimeZone(vehicle?.usePeriod3EndDate),
        year: {label: String(vehicle?.year), value: vehicle?.year},
        usePurpose: vehiclePurposeTypes.find(el => el.value === vehicle?.usePurpose) || null,
        registrationCountry: vehicle?.registrationCountry,
        isShortInsurancePolicy: String(Boolean(vehicle?.isShortInsurancePolicy)),
    };
};

const toNumber = (value: string | number) => {
    if (value === '' || isNaN(value) || isNil(value)) {
        return null;
    }
    return Number(value);
};

export const prepareFormValuesForTransfer = (
    {
        insuranceStartTime,
        insuranceEndTime,
        ...values
    }: Step2FormInterface,
    ownerType: OwnerLawTypes,
    {
        isUsedWithTrailer,
        seatsAmount,
        maxWeight,
        power,
    }: Step2FormInterface,
) => {
    const insuranceStartDate = new Date(values.insuranceStartDate);
    const [startHours, startMinutes] = convertToHoursMinutes(insuranceStartTime);
    insuranceStartDate.setHours(startHours);
    insuranceStartDate.setMinutes(startMinutes);
    const insuranceEndDate = new Date(values.insuranceEndDate);
    const [endHours, endMinutes] = convertToHoursMinutes(insuranceEndTime);
    insuranceEndDate.setHours(endHours);
    insuranceEndDate.setMinutes(endMinutes);
    if (endHours === 23 && endMinutes === 59) {
        insuranceEndDate.setSeconds(59);
    }
    const hasIsUsedWithTrailerField = !(
        values.typeCategory?.value?.code === TypeCategory.B_BE &&
        ownerType === OwnerLawTypes.PHYSICAL
    );
    const isUsedWithTrailerFormValue = Boolean(values.isUsedWithTrailer === String(true));
    const isUsedWithTrailerInititalValue = Boolean(isUsedWithTrailer === String(true));
    const isOnTheWayToRegistration = Boolean(values.isOnTheWayToRegistration === String(true));
    const isShortInsurancePolicy = Boolean(values.isShortInsurancePolicy === String(true));

    return Object.entries({
        ...values,
        maker: values.maker?.value ? values.maker?.value : undefined,
        model: values.model?.value ? values.model?.value : undefined,
        typeCategory: values.typeCategory?.value,
        'document-documentType': values['document-documentType']?.value,
        'document-dateGiven': toContractDateFormat(values['document-dateGiven']),
        'diagnosticCard-dateEnd': toContractDateFormat(values['diagnosticCard-dateEnd']),
        isUsedWithTrailer: hasIsUsedWithTrailerField ? isUsedWithTrailerFormValue : isUsedWithTrailerInititalValue,
        seatsAmount: toNumber(
            values.typeCategory?.value?.code === TypeCategory.D_DE ? values.seatsAmount : seatsAmount
        ),
        maxWeight: toNumber(
            values.typeCategory?.value?.code === TypeCategory.C_CE ? values.maxWeight : maxWeight
        ),
        power: toNumber(
            values.typeCategory?.value?.code === TypeCategory.B_BE ? values.power : power
        ),
        usePeriod1: isOnTheWayToRegistration ? null : values.usePeriod1?.value || 0,
        usePeriod1StartDate: isOnTheWayToRegistration ? null : toContractDateFormat(values.usePeriod1StartDate),
        usePeriod1EndDate: isOnTheWayToRegistration ? null : toContractDateFormat(values.usePeriod1EndDate),
        usePeriod2: isOnTheWayToRegistration ? null : values.usePeriod2?.value || null,
        usePeriod2StartDate: isOnTheWayToRegistration ? null : toContractDateFormat(values.usePeriod2StartDate),
        usePeriod2EndDate: isOnTheWayToRegistration ? null : toContractDateFormat(values.usePeriod2EndDate),
        usePeriod3: isOnTheWayToRegistration ? null : values.usePeriod3?.value || null,
        usePeriod3StartDate: isOnTheWayToRegistration ? null : toContractDateFormat(values.usePeriod3StartDate),
        usePeriod3EndDate: isOnTheWayToRegistration ? null : toContractDateFormat(values.usePeriod3EndDate),
        insuranceStartDate: values?.insuranceStartDate ? toContractTimeZone(insuranceStartDate.toISOString()) : null,
        insuranceEndDate: values?.insuranceEndDate ? toContractTimeZone(insuranceEndDate.toISOString()) : null,
        isOnTheWayToRegistration,
        isShortInsurancePolicy,
        year: values.year?.value,
        usePurpose: values.usePurpose?.value,
    }).reduce((acc, [key, value]) => ({...acc, [`vehicle-${key}`]: value}), {});
};
