import * as React from 'react';

import {RadioButton, RadioButtonProps} from './radio-button';

type ButtonProps = Partial<RadioButtonProps>;

interface RadioButtonGroupProps {
    /** selected value */
    value?: string
    /** name of a group */
    name: string
    /** handle value change */
    onChange?: (value: string) => void
    /** array of buttons */
    items: ButtonProps[]
    /** function for getting key for each button */
    getButtonKey: (button: object) => string
    /** function for getting radio button's label */
    getLabel?: (button: object) => string
    /** function for getting radio button's value */
    getValue?: (button: object) => string
    /** function for getting radio button's disabled state */
    isDisabled?: (button: object) => boolean
}

interface RadioButtonGroupState {
    value?: string
    propsValue?: string
}

export class RadioButtonGroup
    extends React.Component<RadioButtonGroupProps, RadioButtonGroupState> {
    state: RadioButtonGroupState = {
        value: null,
        // eslint-disable-next-line react/no-unused-state
        propsValue: null,
    }

    static getDerivedStateFromProps({value: propsValue}: RadioButtonGroupProps,
        {value, propsValue: statePropsValue}: RadioButtonGroupState) {
        const nextValue = propsValue && propsValue !== statePropsValue ? propsValue : value;

        return {
            value: nextValue,
            propsValue,
        };
    }

    static defaultProps = {
        getLabel: ({label}: ButtonProps) => label,
        getValue: ({value}: ButtonProps) => value,
        isDisabled: ({disabled}: ButtonProps) => disabled,
        getButtonKey: ({value}: ButtonProps) => value,
        onChange: () => {},
    }

    handleChange = (value: string) => {
        const {onChange} = this.props;
        onChange(value);

        this.setState({
            value,
        });
    }

    render() {
        const {value} = this.state;
        const {
            items,
            name,
            getButtonKey,
            getLabel,
            isDisabled,
            getValue,
        } = this.props;

        return (
            <>
                {
                    items.map((item: RadioButtonProps) => {
                        const key = getButtonKey(item);

                        return (
                            <RadioButton
                                onChange={this.handleChange}
                                disabled={isDisabled(item)}
                                label={getLabel(item)}
                                value={getValue(item)}
                                id={key}
                                checked={item.value === value}
                                name={name}
                                key={key}
                            />
                        );
                    })
                }
            </>
        );
    }
}
