import * as React from 'react';

import {Column} from '@ui-kit';

import {MENU} from '../common/data-table-hoc-constants';
import {STUB} from './with-columns-statuses-constants';
import {getConfigurableColumns} from './with-columns-statuses-utils';

type ColumnsPopupFormValues = {
    [key: string]: boolean,
}

export function useColumnsStatuses(columns: Column[]) {
    const [columnsWithStatuses, setColumnsWithStatus] = React.useState(columns);

    const visibleColumns = columnsWithStatuses.filter(column => !column.hidden);
    const configurableColumns = getConfigurableColumns(columnsWithStatuses);

    const handleUpdateColumnsStatuses = (columnsStatuses: ColumnsPopupFormValues) => {
        const columnsWithUpdatedStatuses = columnsWithStatuses.map((column: Column) => {
            const columnsWithNewStatuses = {...column};
            columnsWithNewStatuses.hidden =
                !(columnsStatuses[column.name] || column.name.includes(STUB) || column.name === MENU);

            return columnsWithNewStatuses;
        });

        setColumnsWithStatus(columnsWithUpdatedStatuses);
    };

    return {
        visibleColumns,
        configurableColumns,
        handleUpdateColumnsStatuses,
    };
}
