import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {validationResultScansEditFieldsSelector} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {MakerModelFields} from './maker-model-fields';

const mapStateToProps = createStructuredSelector({
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const MakerModelFieldsContainer = connect(
    mapStateToProps
)(MakerModelFields);
