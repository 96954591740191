import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {removeNotification} from './notifications-actions';
import {NotificationsRoot} from './notifications-root';
import {notificationsSelector} from './notifications-selectors';

const mapStateToProps = createStructuredSelector({
    notifications: notificationsSelector,
});

const mapDispatchToProps = {
    removeNotification,
};

export const NotificationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsRoot);
