import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {createStructuredSelector} from 'reselect';

import {loadReturnContract} from '@app/pages/registration-page/registration-actions';
import {
    paymentCancellation,
    setIsAgreementCaptchaVisible,
} from '@app/store/actions';
import {
    companiesListSelector,
    linkDataSelector,
    skDomainSelector,
} from '@modules/companies';
import {isNavigationShownSelector} from '@modules/navigation';
import {contractIdSelector} from '@modules/contract';

import {Navigation} from './navigation';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    companies: companiesListSelector,
    linkData: linkDataSelector,
    isNavigationShown: isNavigationShownSelector,
    skDomain: skDomainSelector,
});

const mapDispatchToProps = {
    setIsAgreementCaptchaVisible,
    loadReturnContract,
    cancelPayment: paymentCancellation,
};

export const NavigationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation));
