import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    setIsLkBlockedTrue,
    updateUploadedFilesStatuses,
    loadTimerData,
    setIsTimerLoginExpiredTrue,
} from '@app/store/actions';
import {
    isAmountInLimitsSelector,
    contractIdSelector,
} from '@modules/contract';
import {
    currentStageSelector,
    expirationTimeSelector,
    flkCodeSelector,
    secondsLeftSelector,
    timerTypeSelector,
} from '@modules/stage';
import {isLkBlockedSelector} from '@modules/navigation';

import {WrapperTimer} from './wrapper-timer';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    expirationTime: expirationTimeSelector,
    secondsLeft: secondsLeftSelector,
    currentStage: currentStageSelector,
    isLkBlocked: isLkBlockedSelector,
    flkCode: flkCodeSelector,
    timerType: timerTypeSelector,
    isAmountInLimits: isAmountInLimitsSelector,
});

const mapDispatchToProps = {
    setIsLkBlockedTrue,
    setIsTimerLoginExpiredTrue,
    loadTimerData,
    updateUploadedFilesStatuses,
};

export const WrapperTimerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WrapperTimer);
