
import classNames from 'classnames/bind';
import * as React from 'react';
import {
    Route,
    RouteComponentProps,
} from 'react-router';

import {
    AGREEMENT_PAGE,
    COMPANIES_LIST_PAGE,
    REGISTRATION_PAGE,
    CONTRACT_PAGE,
} from '@app/urls';
import {DEFAULT_SK_DOMAIN} from '@utils/constants/egarant-settings';

import {Header, Footer, Navigation} from './components';

import * as styles from './layout.scss';

const cn = classNames.bind(styles);

interface LayoutProps extends RouteComponentProps<{}>{
    loadEgarantProperties?: () => void ;
    children?: React.ReactNode;
    skDomain?: string;
}

export function Layout({
    loadEgarantProperties,
    children = null,
    location,
    skDomain,
}: LayoutProps) {
    React.useEffect(() => {
        loadEgarantProperties();
    }, []);

    return (
        <div className={cn('layout-page')}>
            <div className={cn('layout-page__header')}>
                <Header path={location.pathname} />
            </div>

            <div className={cn('layout-page__wrapper')}>
                {children}
            </div>

            <div className={cn('layout-page__navigation')}>
                <Route path={`/${AGREEMENT_PAGE}`} component={Navigation} />
                <Route path={`/${COMPANIES_LIST_PAGE}`} component={Navigation} />
                <Route path={`/${skDomain}/${REGISTRATION_PAGE}`} component={Navigation} />
                <Route path={`/${skDomain || DEFAULT_SK_DOMAIN}/${CONTRACT_PAGE}`} component={Navigation} />
            </div>

            <div className={cn('layout-page__footer')}>
                <Footer />
            </div>
        </div>
    );
}
