import classNames from 'classnames/bind';
import * as React from 'react';

import {Button, Icons, Portal} from '@ui-kit';
import {getElementPosition, getElementSize} from '@utils/dom';
import {createTranslator} from '@utils/i18n';

import {ButtonWithPopupProps} from './button-with-popup-types';

import * as dictionary from './button-with-popup.dic.json';
import * as styles from './button-with-popup.scss';

const t = createTranslator(dictionary);
const cn = classNames.bind(styles);
const defaultPopupMinWith = 254;

export const ButtonWithPopup = ({
    renderPopup,
    label = t('button-with-popup.label'),
    popupMinWith = defaultPopupMinWith,
    isButtonSmall = true,
    ...receivedButtonProps
}: ButtonWithPopupProps) => {
    const [isPopupOpened, setPopupState] = React.useState(false);
    const [popupTop, setPopupTop] = React.useState(0);
    const [popupLeft, setPopupLeft] = React.useState(0);

    let buttonRef: HTMLElement;

    const handlePopupRef = (ref: HTMLElement) => {
        if (ref && buttonRef) {
            const {bottom: buttonBottom, top: buttonTop, right} = getElementPosition(buttonRef);
            const {height: popupHeight, width: popupWidth} = getElementSize(ref);
            const documentHeight = document.documentElement.clientHeight;
            const bottomSpace = documentHeight - buttonBottom;
            const top = bottomSpace > popupHeight ? buttonBottom : buttonTop - popupHeight;

            setPopupTop(top);
            setPopupLeft(right - popupWidth);
        }
    };

    const handleButtonRef = (ref: HTMLElement) => {
        buttonRef = ref;
    };

    const handleClick = () => {
        setPopupState(isOpen => !isOpen);
    };

    const closePanel = () => {
        setPopupState(false);
    };

    const buttonProps = {
        label,
        primary: isPopupOpened,
        icon: isPopupOpened ? <Icons.EXPAND_LESS /> : <Icons.EXPAND_MORE />,
        ...receivedButtonProps,
        onClick: handleClick,
        withRef: handleButtonRef,
        isSmall: isButtonSmall,
    };

    return (
        <>
            <div className={cn('button-container')}>
                <Button {...buttonProps} />
            </div>
            {isPopupOpened && (
                <Portal>
                    <div
                        style={{top: popupTop, left: popupLeft, minWidth: popupMinWith}}
                        className={cn('popup-container')}
                        ref={handlePopupRef}
                    >
                        {renderPopup(closePanel)}
                    </div>
                </Portal>
            )}
        </>
    );
};
