import {push} from 'connected-react-router';

import {showModal, hideModal} from '@app/store/actions';
import {ButtonProps} from '@ui-kit';
import {noop} from '@utils/functions';
import {createTranslator} from '@utils/i18n';
import {DispatchType} from '@utils/redux';

import {CommonModalsTypes} from './common-modals';

import * as dictionary from './common-modals.dic.json';

const t = createTranslator(dictionary);

export enum ConfirmResult {
    CONFIRMED,
    CANCELED
}

export interface ConfirmOptions {
    hasCancelOption?: boolean;
    title: string;
    message: React.ReactChild;
    confirmButton?: ButtonProps;
    cancelButton?: ButtonProps;
    width?: number;
    minHeight?: number;
    resultHandler: (result: ConfirmResult, dispatch: DispatchType) => void;
}

export function confirm({
    hasCancelOption,
    title,
    message,
    confirmButton,
    cancelButton,
    width,
    minHeight,
    resultHandler,
}: ConfirmOptions): DispatchType {
    return (dispatch: DispatchType): void => {
        const handleModal = (result: ConfirmResult) => {
            dispatch(hideModal());
            resultHandler(result, dispatch);
        };

        dispatch(showModal(CommonModalsTypes.CONFIRM, {
            hasCancelOption,
            title,
            message,
            width,
            minHeight,
            confirmButton,
            cancelButton,
            onConfirm: () => handleModal(ConfirmResult.CONFIRMED),
            onCancel: () => handleModal(ConfirmResult.CANCELED),
        }));
    };
}

export function message(
    title: string,
    message: string,
    onClose: () => void = noop
) {
    return confirm({
        title,
        message,
        resultHandler: onClose,
    });
}

export interface ConfirmTransitionOptions {
    title: string;
    message: string;
    transitionUrl: string;
    onCancel?: () => void;
    onConfirm?: () => void;
}

export function confirmTransition({
    title,
    message,
    transitionUrl,
    onCancel = noop,
    onConfirm = noop,
}: ConfirmTransitionOptions) {
    return confirm({
        hasCancelOption: true,
        title,
        message,
        resultHandler: (result: ConfirmResult, dispatch: Function) => {
            if (result === ConfirmResult.CONFIRMED) {
                onConfirm();
                // TODO Dirty hack!!! Need to change
                setTimeout(() => dispatch(push(transitionUrl)), 0);
            } else {
                onCancel();
            }
        },
    });
}

export type ConfirmDeleteOptions = Partial<ConfirmOptions> & {
    action: () => void;
    message: string;
    title: string;
};

export function confirmDelete({
    title,
    message,
    action,
}: ConfirmDeleteOptions) {
    return confirm({
        hasCancelOption: true,
        title,
        message,
        resultHandler: (result: ConfirmResult) => {
            if (result === ConfirmResult.CONFIRMED) {
                action();
            }
        },
        cancelButton: {
            label: t('confirm.delete-modal.cancel'),
        },
        confirmButton: {
            label: t('confirm.delete-modal.confirm'),
            danger: true,
            primary: true,
        },
    });
}
