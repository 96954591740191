import {
    CalculationData,
    PersonRegistrationData,
    UserExists,
    UserAuth,
    PaymentUrlResponse,
    ConclusionResponse,
    UserData,
} from '@app/types';
import {FAIL_PAGE, RESULT_PAGE} from '@app/urls';
import {
    API_V1_EGARANT_CONTRACT_UI,
    API_V1_EGARANT_LK_UI,
    API_V2_EGARANT_CONTRACT_UI,
    deleteData,
    getData,
    postData,
    putData,
} from '@utils/api';
import {request, toUrlParams} from '@utils/request';
import {CHECK} from '@utils/constants';

const AGREEMENT = 'agreement';
const REGISTRATION = 'registration';
const REGISTER = 'register';
const AUTHORIZE = 'authorize';
const LOGOUT = 'logout';
const RESTORE = 'restore';
const CALCULATE = 'calculate';
const PAYMENT = 'payment';
const CANCELLATION = 'cancellation';
const REJECT = 'reject';
const URL = 'url';
const CONCLUDE = 'conclude';
const OSAGO = 'osago';
const UPDATE_CONTRACT_EFFECTIVE_DATE = 'updateOsagoContractEffectiveDate';
const USER_EXISTS = 'userExists';
const SESSION = 'session';
const HEARTBEAT = 'heartbeat';
const EFFECTIVE_DATE_CORRECTION = 'effectiveDateCorrection';
const REJECTION = 'rejection';

export function setAgreement(contractId: string, hasAgreement: boolean, captchaToken?: string): Promise<null> {
    const params = toUrlParams({
        hasAgreement,
    });
    const headers = captchaToken ? {captchaToken} : {};
    const AGREEMENT_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${AGREEMENT}?${params}`;
    return postData(AGREEMENT_URL, {headers});
}

export function getPersonRegistrationData(contractId: string): Promise<PersonRegistrationData> {
    const REGISTRATION_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${REGISTRATION}`;
    return getData(REGISTRATION_URL);
}

export function register(contractId: string): Promise<null> {
    const REGISTER_URL = `${API_V1_EGARANT_LK_UI}/${contractId}/${REGISTER}`;
    return postData(REGISTER_URL, {});
}

export function authorize(contractId: string, login: string, password: string): Promise<UserAuth> {
    const AUTHORIZE_URL = `${API_V1_EGARANT_LK_UI}/${contractId}/${AUTHORIZE}`;
    const data: UserData = {
        login,
        password,
    };
    return postData(AUTHORIZE_URL, {data});
}

export function sendLogout(): Promise<null> {
    const LOGOUT_URL = `${API_V1_EGARANT_LK_UI}/${LOGOUT}`;
    return postData(LOGOUT_URL, {});
}

export function restore(
    contractId: string,
    login: string,
    captchaToken?: string
): Promise<null> {
    const RESTORE_URL = `${API_V1_EGARANT_LK_UI}/${contractId}/${RESTORE}`;
    const data: UserData = {
        login,
    };

    const headers = captchaToken ? {captchaToken} : {};

    return postData(RESTORE_URL, {data, headers});
}

export function paymentReject(contractId: string): Promise<null> {
    const REJECT_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PAYMENT}/${REJECT}`;
    return postData(REJECT_URL, {});
}

export function calculateInsSum(contractId: string): Promise<CalculationData> {
    const CALCULATE_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${CALCULATE}`;
    return getData(CALCULATE_URL);
}

export function checkPrePayment(contractId: string): Promise<null> {
    const PAYMENT_CHECK_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PAYMENT}/${CHECK}`;
    return putData(PAYMENT_CHECK_URL);
}

export function getPaymentUrl(contractId: string, skDomain: string): Promise<PaymentUrlResponse> {
    const failUrl = `${window.location.origin}/${skDomain}/${FAIL_PAGE}/${contractId}`;
    const returnUrl = `${window.location.origin}/${skDomain}/${RESULT_PAGE}/${contractId}`;
    return request({
        url: `${API_V2_EGARANT_CONTRACT_UI}/${contractId}/${PAYMENT}/${URL}`,
        params: {failUrl, returnUrl},
    }).then(result => result.data);
}

export function cancelPayment(contractId: string): Promise<null> {
    const CANCELLATION_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PAYMENT}/${CANCELLATION}`;
    return putData(CANCELLATION_URL);
}

export function contractConclusion(contractId: string): Promise<ConclusionResponse> {
    const CONCLUDE_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${CONCLUDE}`;
    return postData(CONCLUDE_URL, {});
}

export function updateOsagoContractEffectiveDate(contractId: string): Promise<null> {
    const UPDATE_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${OSAGO}/${UPDATE_CONTRACT_EFFECTIVE_DATE}`;
    return putData(UPDATE_URL);
}

export function getUserExists(contractId: string): Promise<UserExists> {
    const USER_EXISTS_URL = `${API_V1_EGARANT_LK_UI}/${contractId}/${USER_EXISTS}`;
    return getData(USER_EXISTS_URL);
}

export function deleteSession(): Promise<null> {
    const SESSION_URL = `${API_V1_EGARANT_LK_UI}/${SESSION}`;
    return deleteData(SESSION_URL);
}

export function sendHeartbeat(): Promise<null> {
    const HEARTBEAT_URL = `${API_V1_EGARANT_LK_UI}/${HEARTBEAT}`;
    return putData(HEARTBEAT_URL);
}

export function sendDateCorrectionRejection(contractId: string): Promise<null> {
    const REJECTION_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${EFFECTIVE_DATE_CORRECTION}/${REJECTION}`;
    return postData(REJECTION_URL, {});
}
