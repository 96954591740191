import {searchFormat} from '@utils/formatters/search-format';
import * as React from 'react';

import {KeyCode} from '@utils/constants';
import {SelectOption} from '@utils/formatters/select-option';
import {findIndex} from '@utils/functions';

interface TogglerContainerProps {
    toggler: (props: any) => JSX.Element,
    isDisabled: boolean,
    label?: string,
    isValid?: boolean,
    isFilterable?: boolean;
    onFilterChange?: (value: string) => void;
    onFocus?: () => void;
    errorMessage?: string,
    required: boolean,
    onClick: () => void,
    onBlur: () => void,
    value?: string,
    isOpen: boolean,
    options: object[],
    openOptions: () => void,
    closeOptions: () => void,
    changeFocusedOption: (option: object) => void,
    handleChange: (option: object) => void,
    allowKeyboardClean: boolean,
    focusedOption: SelectOption<any>
    startWith?: string;
    isChangeKeyboard?: boolean;
    isClickList: boolean;
}

export class TogglerContainer extends React.PureComponent<TogglerContainerProps> {
    handleKeyDown = (keyCode: number) => {
        const {
            isOpen, focusedOption, options, allowKeyboardClean,
            closeOptions, changeFocusedOption, handleChange, openOptions,
        } = this.props;

        switch (keyCode) {
            case KeyCode.ESCAPE: {
                if (isOpen) {
                    closeOptions();
                }
                break;
            }
            case KeyCode.DOWN_ARROW: {
                if (isOpen) {
                    const index = findIndex(options, focusedOption);
                    const nextIndex = index === options.length ? index : index + 1;

                    changeFocusedOption(options[nextIndex]);
                } else {
                    openOptions();
                }
                break;
            }
            case KeyCode.UP_ARROW: {
                if (isOpen) {
                    const index = findIndex(options, focusedOption);
                    const prevIndex = index ? index - 1 : index;

                    changeFocusedOption(options[prevIndex]);
                }
                break;
            }
            case KeyCode.ENTER: {
                if (isOpen && focusedOption) {
                    handleChange(focusedOption);
                } else {
                    closeOptions();
                }
                break;
            }
            case KeyCode.WHITESPACE: {
                if (isOpen) {
                    handleChange(focusedOption);
                } else {
                    openOptions();
                }
                break;
            }
            case KeyCode.BACKSPACE: {
                if (allowKeyboardClean) {
                    handleChange(null);
                }
                break;
            }
            default:
                break;
        }
    };

    handleBlur = () => {
        const {
            onBlur,
            handleChange,
            isChangeKeyboard,
            focusedOption,
            startWith,
            options,
            isClickList,
        } = this.props;

        const changeKeyboardOptions = (): SelectOption<any> => {
            let newOptions;
            let isMatchWithStartWith = false;

            const keyboardOptions = (
                label: string,
                elementType: string,
                fiasId: string,
                name: string
            ): SelectOption<any> => ({
                label,
                value: {
                    elementType,
                    fiasId,
                    name,
                },
            });

            options.forEach(({label, value}: any) => {
                if (searchFormat(label) === searchFormat(startWith)) {
                    const {elementType, fiasId, name} = value;
                    newOptions = keyboardOptions(label, elementType, fiasId, name);
                    isMatchWithStartWith = true;
                }
            });

            if (!isMatchWithStartWith) {
                newOptions = keyboardOptions(
                    startWith.trim(),
                    focusedOption?.value?.elementType,
                    null,
                    startWith
                );
            }

            return newOptions;
        };

        if (isChangeKeyboard && startWith && !isClickList) {
            handleChange(changeKeyboardOptions());
        }

        onBlur();
    }


    render() {
        const {
            toggler: TogglerComponent,
            isDisabled,
            label,
            isValid,
            isFilterable,
            onFilterChange,
            allowKeyboardClean,
            errorMessage,
            required,
            onClick,
            onBlur,
            onFocus,
            value,
            isOpen,
            startWith,
            isChangeKeyboard,
        } = this.props;

        return (
            <TogglerComponent
                isOpen={isOpen}
                isDisabled={isDisabled}
                isFilterable={isFilterable}
                onFilterChange={onFilterChange}
                allowKeyboardClean={allowKeyboardClean}
                label={label}
                value={value}
                onClick={onClick}
                onBlur={this.handleBlur}
                onFocus={onFocus}
                onKeyDown={this.handleKeyDown}
                required={required}
                isValid={isValid}
                errorMessage={errorMessage}
                tabIndex={0}
                startWith={startWith}
            />
        );
    }
}
