import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './step-component.scss';

const cn = classNames.bind(styles);

interface StepComponent {
    step: number;
    title: string;
    current?: boolean;
    success?: boolean;
    last?: boolean;
}

export function StepComponent({
    step,
    title,
    current = false,
    success = false,
    last = false,
}: StepComponent) {
    const successMark = (
        <svg className={cn('success-checkmark')}>
            <polyline
                className={cn('success-checkmark__path')}
                points="
                    2, 8
                    7, 13
                    17, 3
                    7, 13
                    2, 8"
            />
        </svg>
    );

    return (
        <div className={cn('step-wrapper', current && 'step-wrapper--current', success && 'step-wrapper--success')}>
            <div className={cn('step-wrapper__item')}>
                <div className={cn('step-wrapper__number')}>
                    {success ? successMark : step}
                </div>
                <div className={cn('step-wrapper__title')}>
                    {title}
                </div>
            </div>
            {!last && <div className={cn('step-wrapper__dots')} />}
        </div>
    );
}
