import {ModalType, ModalPositionTypes} from '@modules/modals';

import {ConfirmModal} from './confirm-modal';

export enum CommonModalsTypes {
    CONFIRM = 'CONFIRM'
}

export const commonModals: ModalType[] = [
    {
        type: CommonModalsTypes.CONFIRM,
        component: ConfirmModal,
        isClosable: true,
        closeOnOverlay: false,
        position: ModalPositionTypes.CENTER,
    },
];
