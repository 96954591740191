import {loadCalculatedTableData} from '@app/pages/contract-page/contract-page-actions';
import {removeAuthStatus} from '@app/utils/users-auth-status';
import {executeAsyncAction} from '@modules/model/actions';
import {CONTRACT} from '@app/store/model-constants';
import {
    getReservationFailed,
    scansStatusesRequest,
    getFourthPageOpened,
    getTimerData,
    checkScans,
} from '@modules/contract/contract-api';
import {
    setIsUpdateScansStatusDisabled,
    setValidationResultErrorFields,
    setAutoAcceptHeartbeatStopped,
    contractValidationSnapshot,
    setIsScanSendingInitiated,
    setIsCheckDataBtnHidden,
    toggleUploadedFilesBtn,
    setIsAllScansApproved,
    setIsScansInProgress,
    setNavigationResult,
    setStepsToValidate,
    setCurrentStage,
    initializeAuth,
    setScansToEdit,
    setTimer,
} from '@app/store/actions';
import {
    createActionFactory,
    AsyncActionType,
    DispatchType,
} from '@app/utils/redux';
import {
    ContractStepsFormDataInterface,
    ContractDictionaries,
    StageInterface,
} from '@app/store/types';
import {listFieldScansEnabled} from '@utils/fields-group-enabled';
import {cancelPayment} from '@modules/user';
import {RequestCode} from '@utils/request';
import {AppStages} from '@utils/constants';
import {ActionTypes} from '@utils/types';
import {
    isScansPollingInitiatedSelector,
    isValidationInProgressSelector,
    isScanEditingInitiatedSelector,
    validationResultScansSelector,
    countStepsToValidateScans,
} from '@modules/validation';
import {
    uploadedDocumentFilesSelector,
    mapUploadedFilesToCheckScans,
    isScanFileUploadingSelector,
    driverPersonsListSelector,
    getUpdatedUploadedFiles,
    DocumentFileStatuses,
    UploadedDocumentFile,
    driverMeetsSelector,
    contractIdSelector,
    getInsurerData,
    getScansStage,
    DocumentFile,
    uploadScan,
    Document,
} from '@modules/contract';
import {
    isAutoAcceptHeartbeatStoppedSelector,
    expirationTimeSelector,
    timerTypeSelector,
} from '@modules/stage';
import {
    CheckResultsWithScansData,
    CurrentTimerData,
    CalculationData,
    NavigationStep,
    DocumentTypes,
    ScansStage,
} from '@app/types';

const contractActionCreator = createActionFactory(CONTRACT);

export const setContractId = contractActionCreator<String>(ActionTypes.SET_CONTRACT_ID);
export const setContractData = contractActionCreator<Partial<ContractStepsFormDataInterface>>(
    ActionTypes.SET_CONTRACT_DATA
);
export const setDriverMeets = contractActionCreator<number>(ActionTypes.SET_DRIVER_MEETS);
export const setNoLimitDriver = contractActionCreator<Boolean>(ActionTypes.SET_NO_LIMIT_DRIVER);
export const setContractAmountInLimits = contractActionCreator<Boolean>(ActionTypes.SET_CONTRACT_AMOUNT_IN_LIMITS);
export const setUploadedFiles = contractActionCreator<UploadedDocumentFile[]>(ActionTypes.SET_UPLOADED_FILES);
export const setIsScanFileUploading = contractActionCreator<Boolean>(ActionTypes.SET_IS_SCAN_FILE_UPLOADING);
export const setDictionariesData = contractActionCreator<ContractDictionaries>(ActionTypes.SET_DICTIONARIES_DATA);
export const setOwnerType = contractActionCreator<String>(ActionTypes.SET_OWNER_TYPE);
export const setNotFirstLoadData = contractActionCreator<Boolean>(ActionTypes.SET_NOT_FIRST_LOAD_DATA);
export const setCalculatedTableData = contractActionCreator<CalculationData>(ActionTypes.SET_CALCULATED_TABLE_DATA);

export const getIsExpiredAutoAccept = (state: StageInterface) => {
    const expirationTime = expirationTimeSelector(state);
    const timerType = timerTypeSelector(state);

    return timerType === AppStages.AUTO_ACCEPT_TIMEOUT && Date.now() >= expirationTime ?
        true :
        undefined;
};

const setUploadedFile = (
    file: DocumentFile,
    document: Document,
    fileName: string,
    status: DocumentFileStatuses,
    fileGuid?: string,
    message?: string
) => (
    (dispatch: DispatchType, getState: Function) => {
        const state = getState();
        const uploadedFiles = uploadedDocumentFilesSelector(state);

        const uploadedFile = uploadedFiles.find(({fileOrderInGroup, documentType, documentIndex}) => (
            fileOrderInGroup === file.fileOrderInGroup &&
            documentType === document.type &&
            documentIndex === document.documentIndex
        ));
        if (!uploadedFile) {
            uploadedFiles.push({
                fileName,
                fileOrderInGroup: file.fileOrderInGroup,
                fileGuid,
                documentType: document.type,
                documentIndex: document.documentIndex,
                status,
            });
        } else {
            uploadedFile.status = status;
            uploadedFile.fileGuid = fileGuid;
            uploadedFile.fileName = fileName;
            uploadedFile.message = message;
        }
        dispatch(setUploadedFiles(uploadedFiles));
        dispatch(toggleUploadedFilesBtn());
    });

export const uploadFile = (formData: FormData, document: Document, file: DocumentFile, fileName: string) => (
    async (dispatch: DispatchType, getState: Function) => {
        const state = getState();
        const contractId = contractIdSelector(state);

        dispatch(setIsScanFileUploading(true));
        dispatch(setUploadedFile(file, document, fileName, DocumentFileStatuses.LOADING));
        return uploadScan(contractId, formData)
            .then((fileGuid: string) => (
                dispatch(setUploadedFile(file, document, fileName, DocumentFileStatuses.SUCCESS, fileGuid))
            ))
            .catch(error => {
                if (error.status === RequestCode.BAD_REQUEST) {
                    return dispatch(setUploadedFile(
                        file,
                        document,
                        fileName,
                        DocumentFileStatuses.WRONG_FORMAT,
                        error.message
                    ));
                }
                return dispatch(setUploadedFile(file, document, fileName, DocumentFileStatuses.FAIL));
            })
            .finally(() => dispatch(setIsScanFileUploading(false)));
    });

const resolveRequestCollision = (
    getState: Function,
    expired: boolean,
    contractId: string,
) => (data: CheckResultsWithScansData) => {
    if (expired) {
        return data;
    }

    const isTimeoutExpired = getIsExpiredAutoAccept(getState());

    return isTimeoutExpired ? scansStatusesRequest(contractId, false, isTimeoutExpired) : data;
};

export const updateUploadedFilesStatuses = (isCalledByButton?: boolean) => (
    dispatch: DispatchType,
    getState: Function,
) => {
    const state = getState();
    const contractId = contractIdSelector(state);
    const validationResultScans = validationResultScansSelector(state);
    const driverMeets = driverMeetsSelector(state);
    const isScanEditingInitiated = isScanEditingInitiatedSelector(state);
    const isScansPollingInitiated = isScansPollingInitiatedSelector(state);
    const isAutoAcceptHeartbeatStopped = isAutoAcceptHeartbeatStoppedSelector(state);
    const isScanFileUploading = isScanFileUploadingSelector(state);
    const expired = getIsExpiredAutoAccept(state);
    const isValidationInProgress = isValidationInProgressSelector(state);

    const isAutoAcceptAwaiting = (!isAutoAcceptHeartbeatStopped || isCalledByButton) && !isScanFileUploading;

    if (isAutoAcceptAwaiting || !isValidationInProgress) {
        dispatch(setIsUpdateScansStatusDisabled(true));

        return scansStatusesRequest(contractId, isCalledByButton, expired)
            .then(resolveRequestCollision(getState, expired, contractId))
            .then((data: CheckResultsWithScansData) => {
                dispatch(setIsUpdateScansStatusDisabled(false));
                const updatedUploadedFiles = getUpdatedUploadedFiles(data);
                dispatch(setUploadedFiles(updatedUploadedFiles));

                const isUploadedFilesChecking = updatedUploadedFiles.some(file => (
                    file.status === DocumentFileStatuses.INSURANCE_COMPANY_CHECKING
                ));

                const isUploadedFilesChecked = updatedUploadedFiles.every(file => (
                    file.status === DocumentFileStatuses.INSURANCE_COMPANY_SUCCESS
                ));

                dispatch(setIsAllScansApproved(isUploadedFilesChecked));
                dispatch(setIsScansInProgress(isUploadedFilesChecking));
                if (!isUploadedFilesChecking) {
                    dispatch(setAutoAcceptHeartbeatStopped(true));
                }

                const scansToEditSet = new Set();
                updatedUploadedFiles.forEach(file => {
                    if (file.status === DocumentFileStatuses.INSURANCE_COMPANY_FAIL) {
                        scansToEditSet.add(file.documentType);
                    }
                });

                const scansToEdit = Array
                    .from(scansToEditSet)
                    .map(index => String(index));

                const setDocumentError: any = new Set();
                updatedUploadedFiles.forEach(file => {
                    if (file.status !== DocumentFileStatuses.INSURANCE_COMPANY_SUCCESS &&
                        file.documentType !== DocumentTypes.DRIVERS_DOCUMENTS) {
                        setDocumentError.add(file.documentType);
                    }
                    if (file.status !== DocumentFileStatuses.INSURANCE_COMPANY_SUCCESS &&
                        file.documentType === DocumentTypes.DRIVERS_DOCUMENTS) {
                        setDocumentError.add(`${file.documentType}.${file.documentIndex}`);
                    }
                });
                const uniqueDocumentsError: string[] = Array.from(setDocumentError);
                const listFieldError = listFieldScansEnabled(uniqueDocumentsError, driverMeets);
                dispatch(setValidationResultErrorFields(listFieldError));

                const isNotAllFilesLoaded = validationResultScans
                    .some((item: DocumentTypes) => !data[item]);

                const isUploadedFilesFailed = updatedUploadedFiles.some(file => (
                    file.status === DocumentFileStatuses.INSURANCE_COMPANY_FAIL
                ));

                const scansStage = getScansStage({
                    isUploadedFilesChecked,
                    isUploadedFilesChecking,
                    isNotAllFilesLoaded,
                    isUploadedFilesFailed,
                });

                if (scansStage === ScansStage.ALL_SCANS_APPROVED) {
                    if (isScansPollingInitiated) {
                        dispatch(loadCalculatedTableData(contractId));
                        dispatch(setIsCheckDataBtnHidden(true));
                    } else {
                        // полинг обычно длится менее 2 секунд - добавлено в RAMIEPT-123006
                        setTimeout(() => {
                            const isValidationInProgress = isValidationInProgressSelector(getState());

                            if (isValidationInProgress) {
                                window.scrollTo(0, 0);
                                dispatch(setCurrentStage(AppStages.VALIDATION_IN_PROGRESS));
                            }
                        }, 2000);
                        dispatch(contractValidationSnapshot(contractId));
                    }
                }

                if (scansStage === ScansStage.NEW_SCANS_TO_UPLOAD) {
                    dispatch(setCurrentStage(AppStages.SCAN_ATTACHMENT));
                }

                if (scansStage === ScansStage.SOME_SCANS_TO_EDIT) {
                    dispatch(setScansToEdit(scansToEdit));

                    if (isScanEditingInitiated) {
                        dispatch(setStepsToValidate(
                            countStepsToValidateScans(scansToEdit)
                        ));
                        dispatch(setCurrentStage(AppStages.CHANGE_APPLICATION_DATA));
                    } else {
                        dispatch(setCurrentStage(AppStages.AUTO_ACCEPT_TIMEOUT__REJECTED));
                    }
                }
            })
            .catch(() => {
                const uploadedFiles = uploadedDocumentFilesSelector(state);

                dispatch(setUploadedFiles(uploadedFiles.map(file => ({
                    ...file,
                    status: DocumentFileStatuses.INSURANCE_COMPANY_FAIL,
                }))));

                dispatch(setAutoAcceptHeartbeatStopped(true));
            })
            .finally(() => {
                dispatch(setIsUpdateScansStatusDisabled(false));
            });
    }
    return null;
};

export const checkUploadedFiles = () => (dispatch: DispatchType, getState: Function) => {
    const state = getState();
    const uploadedFiles = uploadedDocumentFilesSelector(state);
    const contractId = contractIdSelector(state);
    const drivers = driverPersonsListSelector(state);
    const resultData = mapUploadedFilesToCheckScans(uploadedFiles, drivers);

    dispatch(setIsUpdateScansStatusDisabled(true));
    return checkScans(contractId, resultData)
        .then(() => {
            dispatch(setUploadedFiles(uploadedFiles.map(file => ({
                ...file,
                status: DocumentFileStatuses.INSURANCE_COMPANY_CHECKING,
            }))));
            dispatch(setIsScanSendingInitiated(true));
            dispatch(setCurrentStage(AppStages.AUTO_ACCEPT_TIMEOUT));
            dispatch(setIsScansInProgress(true));
        })
        .finally(() => {
            dispatch(setIsUpdateScansStatusDisabled(false));
        });
};

export function logout(contractId: string): AsyncActionType {
    return async (dispatch: DispatchType) => {
        removeAuthStatus(contractId);
        dispatch(initializeAuth(contractId));
    };
}

export function loadTimerData(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        dispatch(setTimer({
            expirationTime: null,
            secondsLeft: null,
            flkCode: null,
            timerType: null,
        }));
        return getTimerData(contractId).then((data: CurrentTimerData) => {
            dispatch(setTimer({
                expirationTime: (Date.now() + data.secondsLeft * 1000),
                secondsLeft: data.secondsLeft,
                flkCode: data.flkCode,
                timerType: data.timerType,
            }));
        });
    };
}

export function loadFourthPageOpened(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        getFourthPageOpened(contractId);
    };
}

export function loadReservationFailed(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        getReservationFailed(contractId);
    };
}

export function paymentCancellation(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        cancelPayment(contractId).then(() => {
            dispatch(setNavigationResult({
                step: NavigationStep.STEP_4,
                paymentUrl: null,
            }));
        });
    };
}

export function fetchUserInfo(contractId: string) {
    return (dispatch: DispatchType) => executeAsyncAction(dispatch,
        () => getInsurerData(contractId)
            .then(insurer => {
                dispatch(setContractData({insurer}));
            }));
}
