import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './search-input.scss';

interface SearchInputProps {
    value: string,
    placeholder: string,
    onChange: (value: string) => void
}

const cn = classNames.bind(styles);

export function SearchInput({
    value,
    placeholder,
    onChange,
}: SearchInputProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <input
            className={cn('search-input')}
            onChange={handleChange}
            placeholder={placeholder}
            type="text"
            value={value}
        />
    );
}
