
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    initializeAuth,
    setContractId,
} from '@app/store/actions';
import {forgotPasswordSelector} from '@modules/user';

import {LoginPage} from './login-page';

const mapStateToProps = createStructuredSelector({
    forgotPassword: forgotPasswordSelector,
});

const mapDispatchToProps = {
    setContractId,
    initializeAuth,
};

export const LoginPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
