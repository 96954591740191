import * as React from 'react';

import {
    Button,
    ButtonProps,
    ModalLayout,
    IconButton,
    Icons,
} from '@ui-kit';
import {noop} from '@utils/functions';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './confirm-modal.dic.json';

interface ConfirmModalProps {
    hasCancelOption?: boolean;
    title: string | JSX.Element;
    message?: React.ReactChild;
    confirmButton?: ButtonProps,
    cancelButton?: ButtonProps,
    onConfirm?: () => void;
    onCancel?: () => void;
    width?: string | number;
    minHeight?: string | number;
}

const t = createTranslator(dictionary);

const renderMessagePart = (messagePart: string, index: number) => (<p key={index}>{messagePart}</p>);
const getPreparedMessage = (message: string) => message.split(/\r?\n/).map(renderMessagePart);

export const ConfirmModal = ({
    hasCancelOption = false,
    title = t('confirm.modal.title'),
    message,
    onConfirm = noop,
    onCancel = noop,
    confirmButton = {
        label: t('confirm.modal.confirmText'),
        primary: true,
    },
    cancelButton = {
        label: t('confirm.modal.cancelText'),
        danger: true,
    },
    width = 400,
    minHeight = 250,
}: ConfirmModalProps) => (
    <ModalLayout
        areButtonsRightAligned
        title={title}
        width={width}
        minHeight={minHeight}
    >
        {{
            content: typeof message === 'string' ? getPreparedMessage(message) : message,
            buttons: (
                <>
                    {hasCancelOption && (
                        <Button
                            {...cancelButton}
                            onClick={onCancel}
                        />
                    )}
                    <Button
                        {...confirmButton}
                        onClick={onConfirm}
                    />
                </>
            ),
            closeButton: (
                <IconButton
                    icon={Icons.CLOSE}
                    onClick={onCancel}
                />
            ),
        }}
    </ModalLayout>
);
