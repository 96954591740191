import classNames from 'classnames/bind';
import * as React from 'react';

import {RelativePortal, Scrollbars} from '@ui-kit';
import {findIndex} from '@utils/functions';

import {OPTION_HEIGHT} from '../../constants';
import {SelectListProps} from '../../select-types';
import {Options, OptionsType} from '../options';

import * as styles from '../../select.scss';

const cn = classNames.bind(styles);

export interface InputSelectListProps extends SelectListProps {
    countVisibleOption?: number,
}

export function InputSelectList(props: InputSelectListProps) {
    const {
        emptyOptionMessage,
        options,
        countVisibleOption,
        getValue,
        getLabel,
        selectedOption,
        focusedOption,
        onClick,
        selectRef,
    } = props;

    const maxHeight = OPTION_HEIGHT * (options.length < countVisibleOption ?
        options.length :
        countVisibleOption);

    return (
        <RelativePortal
            parentElement={selectRef.current}
            className={cn('select__options')}
            width={selectRef.current.getBoundingClientRect().width}
        >
            <Scrollbars
                maxHeight={options.length ? maxHeight : OPTION_HEIGHT}
                scrollTo={OPTION_HEIGHT * findIndex(options, focusedOption)}
            >
                <Options
                    getValue={getValue}
                    getLabel={getLabel}
                    emptyOptionMessage={emptyOptionMessage}
                    selectedOption={selectedOption}
                    focusedOption={focusedOption}
                    onClick={onClick}
                    options={options}
                    optionsType={OptionsType.INPUT_OPTIONS}
                    showIcon
                />
            </Scrollbars>
        </RelativePortal>
    );
}
