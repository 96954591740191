export enum FillRule {
    EVENODD = 'evenodd',
    NONZERO = 'nonzero'
}

export interface SvgIconProps {
    fillRule?: FillRule,
    height?: number,
    noFill?: boolean,
    noStroke?: boolean,
    path: string,
    size: number,
    transform?: string,
    viewBoxHeight?: number,
    viewBoxWidth?: number,
    width?: number
}

export interface GenericSvgIconProps extends SvgIconProps{
    path: string,
    originalSize: number
}
