import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    setForgotPassword,
    setIsLkBlockedTrue,
} from '@app/store/actions';
import {isTimerLoginExpiredSelector} from '@modules/stage';
import {isLkBlockedSelector} from '@modules/navigation';
import {contractIdSelector} from '@modules/contract';
import {DispatchType} from '@utils/redux';

import {SignIn} from './sign-in';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isLkBlocked: isLkBlockedSelector,
    isTimerLoginExpired: isTimerLoginExpiredSelector,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
    deleteForgotPassword: () => dispatch(setForgotPassword(false)),
    setIsLkBlockedTrue: () => dispatch(setIsLkBlockedTrue()),
});

export const SignInContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignIn);
