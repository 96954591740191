import classNames from 'classnames/bind';
import * as React from 'react';

import {LogoImage} from './components';

import * as styles from './logo.scss';

const cn = classNames.bind(styles);

export function Logo(): JSX.Element {
    return (
        <div className={cn('logo')}>
            <div className={cn('logo__container')}>
                <LogoImage />
                <span className={cn('logo__text')}>ePlatform</span>
            </div>
        </div>
    );
}
