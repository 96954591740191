import {authorize} from '@app/modules/user';
import {
    setAuthInitialized,
    setAuthenticated,
    loadLinkData,
    updateContractStage,
} from '@app/store/actions';
import {UserAuthStatus} from '@app/types';
import {executeAsyncAction} from '@modules/model/actions';
import {DispatchType} from '@utils/redux';
import {setAuthStatus} from '@app/utils/users-auth-status';
import {validationDataSelector} from '@modules/validation';

function setAuthLocalStorage(contractId: string, userAuthStatus: UserAuthStatus) {
    setAuthStatus(contractId, userAuthStatus);
}

export function sendAuthorizationAction(
    contractId: string,
    login: string,
    password: string
) {
    return (dispatch: DispatchType, getState: Function) => {
        const state = getState();
        const executor = () => authorize(contractId, login, password)
            .then(({status}) => {
                setAuthLocalStorage(contractId, status);
                dispatch(setAuthInitialized());
                dispatch(setAuthenticated(status));
                const validation = validationDataSelector(state);
                dispatch(updateContractStage(validation));
            })
            .then(() => dispatch(loadLinkData(contractId)));
        executeAsyncAction(dispatch, executor);
    };
}
