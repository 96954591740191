import {removeAuthStatus, getAuthStatus} from '@app/utils/users-auth-status';
import {USER} from '@app/store/model-constants';
import {
    PersonRegistrationData,
    UserExists,
} from '@app/types';
import {
    sendLogout,
    deleteSession,
    getUserExists,
    sendHeartbeat,
} from '@modules/user';
import {executeAsyncAction} from '@modules/model/actions';
import {hideModal} from '@app/store/actions';
import {createActionFactory, DispatchType, AsyncActionType} from '@utils/redux';
import {ActionTypes} from '@utils/types';

const userActionCreator = createActionFactory(USER);

export const setUser = userActionCreator<PersonRegistrationData>(ActionTypes.SET_USER);
export const setAuthInitialized = userActionCreator(ActionTypes.SET_INITIALIZED_AUTH_TRUE);
export const setAuthenticated = userActionCreator<string>(ActionTypes.SET_AUTHENTICATED);
export const setHasUser = userActionCreator<Boolean>(ActionTypes.SET_HAS_USER);
export const setForgotPassword = userActionCreator<Boolean>(ActionTypes.SET_FORGOT_PASSWORD);

export function initializeAuth(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        const authenticated = getAuthStatus(contractId);
        dispatch(setAuthInitialized());
        dispatch(setAuthenticated(authenticated));
    };
}

export function logoutAction(contractId: string): AsyncActionType {
    return async (dispatch: DispatchType) => {
        await sendLogout();
        removeAuthStatus(contractId);
        dispatch(setAuthenticated(null));
    };
}

export function loadUserExists(contractId: string): AsyncActionType {
    return (dispatch: DispatchType) => executeAsyncAction(dispatch, () => {
        const resultUserExists = getUserExists(contractId);

        return resultUserExists.then((data: UserExists) => {
            dispatch(setHasUser(data.hasUser));
        });
    });
}

export function deleteSessionAction(): DispatchType {
    return (dispatch: DispatchType) => deleteSession().catch(() => dispatch(hideModal));
}

export function heartbeatAction(): DispatchType {
    return (dispatch: DispatchType) => sendHeartbeat().catch(() => dispatch(hideModal));
}
