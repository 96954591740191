import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    validationResultScansEditFieldsSelector,
} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {LegalDocumentData} from './legal-document-data';

const mapStateToProps = createStructuredSelector({
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const LegalDocumentDataContainer = connect(
    mapStateToProps
)(LegalDocumentData);
