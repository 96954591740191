import * as React from 'react';

import {EventName} from '@utils/constants';
import {getElementPosition, getDocumentScrollPosition} from '@utils/dom';

import {Portal} from '.';

export interface OptionsType {
    showTop?: boolean,
    showLeft?: boolean,
    topShift?: number,
    leftShift?: number
}

interface RelativePortalProps {
    parentElement: HTMLElement,
    options?: OptionsType,
    className?: string,
    height?: number | string,
    width?: number | string,
    withRef?: (el: HTMLElement) => void
}

interface RelativePortalState {
    top: number | string,
    left: number | string
}

const AUTO_MEASUREMENT = 'auto';

export const checkAvailableSpace = (position: number, windowSize: number) => (position <= windowSize);

/** @description displays content directly above or below the parent element */
export class RelativePortal extends React.PureComponent<RelativePortalProps, RelativePortalState> {
    portalContentRef = React.createRef<HTMLDivElement>();

    static defaultProps: RelativePortalProps = {
        parentElement: null,
        options: {},
    };

    state: RelativePortalState = {
        top: AUTO_MEASUREMENT,
        left: AUTO_MEASUREMENT,
    };

    componentDidMount(): void {
        const {withRef} = this.props;

        if (withRef) {
            withRef(this.portalContentRef.current);
        }
        this.calculateSizes();

        window.addEventListener(EventName.RESIZE, () => { this.calculateSizes(false); });
    }

    componentDidUpdate(): void {
        this.calculateSizes();
    }

    componentWillUnmount(): void {
        window.removeEventListener(EventName.RESIZE, () => { this.calculateSizes(false); });
    }

    calculateSizes = (calculateTopPosition: boolean = true): void => {
        if (!this.portalContentRef.current) {
            return;
        }

        let {left, top} = this.state;
        const {
            parentElement,
            options: {
                showTop,
                showLeft,
                topShift,
                leftShift,
            },
            height,
            width,
        } = this.props;
        const {scrollTop} = getDocumentScrollPosition();
        const {clientWidth, clientHeight} = document.documentElement;
        const {
            top: parentElementTop,
            right: parentElementRight,
            bottom: parentElementBottom,
            left: parentElementLeft,
        } = getElementPosition(parentElement);
        const {
            height: portalElementHeight,
            width: portalElementWidth,
        } = this.portalContentRef.current.getBoundingClientRect();

        if (calculateTopPosition) {
            if (!showTop &&
                checkAvailableSpace((parentElementBottom - scrollTop + portalElementHeight), clientHeight)) {
                top = parentElementBottom;
            } else {
                top = parentElementTop - Number(height || portalElementHeight);
            }

            if (topShift) {
                top += topShift;
            }

            this.setState(() => ({top}));
        }

        if (!showLeft && (
            checkAvailableSpace((parentElementLeft + portalElementWidth), clientWidth) ||
                clientWidth < portalElementWidth
        )) {
            left = parentElementLeft;
            if (leftShift) {
                left -= leftShift;
            }
        } else {
            left = parentElementRight - Number(width || portalElementWidth);
            if (leftShift) {
                left += leftShift;
            }
        }

        this.setState(() => ({left}));
    };

    render() {
        const {
            className,
            children,
            height,
            width,
        } = this.props;
        const {top, left} = this.state;

        return (
            <Portal>
                <div
                    ref={this.portalContentRef}
                    style={{
                        top,
                        left,
                        height,
                        width,
                        position: 'absolute',
                    }}
                    className={className}
                >
                    {children}
                </div>
            </Portal>
        );
    }
}
