import MaterialRadioButton, {NativeRadioControl} from '@material/react-radio';
import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './radio-button.scss';

const cn = classNames.bind(styles);

export interface RadioButtonProps {
    label: string
    id: string
    name: string
    value: string
    leftShifted?: boolean
    checked?: boolean
    disabled?: boolean
    onChange?: (value: string) => void
}

export function RadioButton({
    label,
    value,
    leftShifted,
    checked,
    disabled,
    name,
    id,
    onChange,
}: RadioButtonProps) {
    const handleChange = (e: React.FormEvent) => onChange((e.target as HTMLInputElement).value);

    return (
        <MaterialRadioButton
            label={label}
            className={cn(
                'radio-button',
                `radio-button${disabled ? '--disabled' : ''}`,
                leftShifted && 'radio-button--left-shifted'
            )}
        >
            <NativeRadioControl
                id={id}
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
            />
        </MaterialRadioButton>
    );
}

RadioButton.defaultProps = {
    checked: false,
    disabled: false,
    leftShifted: false,
    onChange: () => {},
};
