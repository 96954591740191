import classNames from 'classnames/bind';
import * as React from 'react';

import {SortDirections} from '@utils/constants';

import {Icons} from '../../../../icons';
import {SortCriteria} from '../../../data-table-types';

import * as styles from './with-sorting.scss';

const cn = classNames.bind(styles);

export function withSorting(WrappedComponent: React.ComponentType<any>) {
    function EnhancedComponent({
        isSortable,
        sortCriteria = {},
        onClick,
        ...restProps
    }: any) {
        const {value, name} = restProps;
        const {direction, name: sortedFieldName} = sortCriteria as SortCriteria;
        const isSorted = name === sortedFieldName;
        const renderValue = () => (
            <div className={cn('value')}>
                {value}
                <div
                    className={cn('value__icon', {
                        'value__icon--hidden': !isSorted,
                        'value__icon--inverted': isSorted && direction === SortDirections.ASC,
                    })}
                >
                    <Icons.SORT />
                </div>
            </div>
        );

        const handleClick = React.useCallback(() => onClick(name), [sortCriteria]);
        if (!isSortable) {
            return <WrappedComponent {...restProps} />;
        }

        return (
            <div
                role="button"
                className={cn('sorting-container')}
                onClick={handleClick}
            >
                <WrappedComponent {...restProps} value={renderValue()} />
            </div>
        );
    }

    EnhancedComponent.displayName = `withSorting(${WrappedComponent.displayName || WrappedComponent.name})`;

    return EnhancedComponent;
}
