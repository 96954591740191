import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {confirmAgreement} from '@app/pages/agreement-page/agreement-actions';
import {
    processInternalServerError,
    setIsAgreementBtnEnabled,
    setCaptchaVerifyKey,
} from '@app/store/actions';
import {linkDataSelector} from '@modules/companies';
import {
    feEgarantCapthcaEnableSelector,
    isAgreementCaptchaVisibleSelector,
    captchaPublicKeySelector,
} from '@modules/system';
import {
    isAgreementBtnEnabledSelector,
    isLkBlockedSelector,
} from '@modules/navigation';

import {contractIdSelector} from '@modules/contract';

import {AgreementNavigation} from './agreement-navigation';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isActiveNext: isAgreementBtnEnabledSelector,
    isAgreementCaptchaVisible: isAgreementCaptchaVisibleSelector,
    captchaPublicKey: captchaPublicKeySelector,
    linkData: linkDataSelector,
    isLkBlocked: isLkBlockedSelector,
    feEgarantCapthcaEnable: feEgarantCapthcaEnableSelector,
});

const mapDispatchToProps = {
    handleErrorCaptcha: processInternalServerError,
    setIsAgreementBtnEnabled,
    setCaptchaVerifyKey,
    confirmAgreement,
};

export const AgreementNavigationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementNavigation);
