import classNames from 'classnames/bind';
import * as React from 'react';

import {
    KeyCode,
    EventName,
} from '@utils/constants';
import {
    addMonths,
    getNowDate,
    subtractMonths,
} from '@utils/date';
import {noop} from '@utils/functions';

import {Calendar} from './calendar';

import * as styles from './calendars-manager.scss';

const cn = classNames.bind(styles);

export interface CalendarsManagerProps {
    firstSelectedDate: Date;
    secondSelectedDate?: Date;
    onChange: (date: Date, dateChangedByKeyboard?: boolean) => void;
    onKeyDown: (keyCode: number) => void;
    onClearSelectedDates: Function;
    onDisplayChange?: (height: number) => void;
    rangeSelectingMode?: boolean;
    showFirstAvailableDate?: boolean;
    withRef?: (el: HTMLElement) => void;
    availableDates?: [Date, Date?]
}

interface CalendarsManagerState {
    focusedDate: Date;
    firstCalendarViewDate: Date;
    secondCalendarViewDate: Date;
    isFocused: boolean;
}

export class CalendarsManager extends React.PureComponent<CalendarsManagerProps, CalendarsManagerState> {
    calendarsManagerRef = React.createRef<HTMLDivElement>();

    static defaultProps: CalendarsManagerProps = {
        firstSelectedDate: null,
        secondSelectedDate: null,
        onChange: noop,
        onKeyDown: noop,
        onClearSelectedDates: noop,
        onDisplayChange: noop,
        rangeSelectingMode: false,
    };

    constructor(props: CalendarsManagerProps) {
        super(props);
        const {
            firstSelectedDate,
            secondSelectedDate,
            rangeSelectingMode,
            showFirstAvailableDate,
            availableDates,
        } = props;

        const firstShownDate = showFirstAvailableDate ? availableDates[0] : getNowDate();
        const firstCalendarViewDate = firstSelectedDate || firstShownDate;
        const secondCalendarViewDate = (rangeSelectingMode &&
            (!secondSelectedDate ||
                (firstCalendarViewDate.getMonth() === secondSelectedDate.getMonth() &&
                    firstCalendarViewDate.getFullYear() === secondSelectedDate.getFullYear()))) ?
            addMonths(firstCalendarViewDate, 1) :
            secondSelectedDate;

        this.state = {
            focusedDate: firstCalendarViewDate,
            firstCalendarViewDate,
            secondCalendarViewDate,
            isFocused: false,
        };
    }

    componentDidMount() {
        const {withRef} = this.props;

        withRef(this.calendarsManagerRef.current);

        document.addEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    handleKeyDown = (e: KeyboardEvent) => {
        const {keyCode} = e;
        const {
            firstSelectedDate,
            onKeyDown,
            onClearSelectedDates,
        } = this.props;
        const {isFocused} = this.state;

        if (!isFocused) {
            return;
        }

        if (keyCode === KeyCode.ESCAPE) {
            if (firstSelectedDate) {
                onClearSelectedDates();
            } else {
                onKeyDown(keyCode);
            }
        }

        e.stopPropagation();
    };

    handleChangeFirstCalendarViewDate = (date: Date, dateChangedByKeyboard?: boolean) => {
        const {rangeSelectingMode} = this.props;

        this.setState(({
            firstCalendarViewDate,
            secondCalendarViewDate,
        }) => {
            const firstDateEqualsSecond = secondCalendarViewDate &&
                date.getMonth() === secondCalendarViewDate.getMonth() &&
                date.getFullYear() === secondCalendarViewDate.getFullYear();
            const firstDateAfterSecond = secondCalendarViewDate &&
                (date.getFullYear() > secondCalendarViewDate.getFullYear() ||
                (date.getMonth() > secondCalendarViewDate.getMonth() &&
                    date.getFullYear() === secondCalendarViewDate.getFullYear()));

            return {
                firstCalendarViewDate: firstDateEqualsSecond && dateChangedByKeyboard ? firstCalendarViewDate : date,
                secondCalendarViewDate: rangeSelectingMode && !dateChangedByKeyboard &&
                (firstDateEqualsSecond || firstDateAfterSecond) ?
                    addMonths(date, 1) :
                    secondCalendarViewDate,
            };
        });
    };

    handleChangeSecondCalendarViewDate = (date: Date, dateChangedByKeyboard?: boolean) => {
        this.setState(({
            firstCalendarViewDate,
            secondCalendarViewDate,
        }) => {
            const secondDateEqualsOrBeforeFirstDate = date.getFullYear() < firstCalendarViewDate.getFullYear() ||
                (date.getFullYear() === firstCalendarViewDate.getFullYear() &&
                    date.getMonth() <= firstCalendarViewDate.getMonth());

            return {
                firstCalendarViewDate: secondDateEqualsOrBeforeFirstDate && !dateChangedByKeyboard ?
                    subtractMonths(date, 1) :
                    firstCalendarViewDate,
                secondCalendarViewDate: !secondDateEqualsOrBeforeFirstDate || !dateChangedByKeyboard ?
                    date :
                    secondCalendarViewDate,
            };
        });
    };

    setFocusedDate = (date: Date) => {
        this.setState({focusedDate: date});
    };

    setCalendarsFocus = () => this.setState({isFocused: true});

    removeCalendarsFocus = () => this.setState({isFocused: false});

    render() {
        const {
            firstSelectedDate,
            secondSelectedDate,
            onChange,
            onDisplayChange,
            rangeSelectingMode,
            availableDates,
        } = this.props;
        const {
            focusedDate,
            firstCalendarViewDate,
            secondCalendarViewDate,
            isFocused,
        } = this.state;
        const secondCalendarActive = rangeSelectingMode &&
            isFocused &&
            focusedDate.getMonth() === secondCalendarViewDate.getMonth() &&
            focusedDate.getFullYear() === secondCalendarViewDate.getFullYear();
        const firstCalendarActive = isFocused && !secondCalendarActive;

        return (
            <span
                className={cn('calendars-manager')}
                ref={this.calendarsManagerRef}
                role="menu"
                tabIndex={0}
                onBlur={this.removeCalendarsFocus}
                onFocus={this.setCalendarsFocus}
            >
                <Calendar
                    firstSelectedDate={firstSelectedDate}
                    secondSelectedDate={secondSelectedDate}
                    viewDate={firstCalendarViewDate}
                    focusedDate={focusedDate}
                    onChange={onChange}
                    onFocus={this.setFocusedDate}
                    onChangeViewDate={this.handleChangeFirstCalendarViewDate}
                    onDisplayChange={rangeSelectingMode ? noop : onDisplayChange}
                    isActive={firstCalendarActive}
                    showCurrentDateButton={!rangeSelectingMode}
                    availableDates={availableDates}
                />
                {rangeSelectingMode && (
                    <Calendar
                        firstSelectedDate={firstSelectedDate}
                        secondSelectedDate={secondSelectedDate}
                        viewDate={secondCalendarViewDate}
                        focusedDate={focusedDate}
                        onChange={onChange}
                        onFocus={this.setFocusedDate}
                        onChangeViewDate={this.handleChangeSecondCalendarViewDate}
                        onDisplayChange={onDisplayChange}
                        isActive={secondCalendarActive}
                    />
                )}
            </span>
        );
    }
}
