
import {connect} from 'react-redux';

import {setOwnerType} from '@app/store/actions';

import {Owner} from './owner';

const mapDispatchToProps = {
    setOwnerType,
};

export const OwnerContainer = connect(
    null,
    mapDispatchToProps
)(Owner);
