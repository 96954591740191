import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    loadData,
    loadDictionaries,
    updateMappedValidationFieldValue,
} from '@app/pages/contract-page/contract-page-actions';
import {
    confirmExpiredTimer,
    loadValidation,
    setContractId,
    setCurrentStep,
    setIsNavigationShown,
    setNotFirstLoadData,
    setMismatchData,
} from '@app/store/actions';
import {Mismatch} from '@app/types';
import {skDomainSelector} from '@modules/companies';
import {
    isNotFirstLoadDataSelector,
    contractIdSelector,
} from '@modules/contract';
import {mismatchDataSelector} from '@modules/modals';
import {
    isNavigationShownSelector,
    currentStepSelector,
    isLkBlockedSelector,
} from '@modules/navigation';
import {
    currentStageSelector,
    flkCodeSelector,
} from '@modules/stage';
import {isDataEditedSelector} from '@modules/validation';
import {MatchParams} from '@utils/match-params';
import {DispatchType} from '@utils/redux';

import {ContractPage} from './contract-page';

const mapStateToProps = createStructuredSelector({
    currentStep: currentStepSelector,
    contractId: contractIdSelector,
    skDomain: skDomainSelector,
    currentStage: currentStageSelector,
    flkCode: flkCodeSelector,
    isNavigationShown: isNavigationShownSelector,
    mismatchData: mismatchDataSelector,
    isLkBlocked: isLkBlockedSelector,
    isDataEdited: isDataEditedSelector,
    isNotFirstLoadData: isNotFirstLoadDataSelector,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
    loadData: (contractId: string) => {
        Promise.resolve()
            .then(() => dispatch(loadDictionaries()))
            .then(() => dispatch(loadValidation(contractId)))
            .then(() => dispatch(loadData(contractId)))
            .then(() => dispatch(updateMappedValidationFieldValue()))
            .then(() => dispatch(setIsNavigationShown(true)))
            .then(() => dispatch(setNotFirstLoadData(true)));
    },
    passParams: ({contractId, step}: MatchParams['params']) => {
        dispatch(setContractId(contractId));
        if (step) {
            dispatch(setCurrentStep(Number(step.replace('step', ''))));
        }
    },
    confirmExpiredTimer: (flkCode: string) => dispatch(confirmExpiredTimer(flkCode)),
    setMismatchData: (mismatchData: Mismatch[]) => dispatch(setMismatchData(mismatchData)),
});

export const ContractPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ContractPage);
