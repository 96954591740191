import {executeAsyncAction} from '@modules/model/actions';
import {getPaymentUrl} from '@modules/user';
import {DispatchType} from '@utils/redux';

export function redirectToPaymentUrl(
    contractId: string,
    skDomain: string
): DispatchType {
    return (dispatch: DispatchType) => {
        const executor = () => getPaymentUrl(contractId, skDomain)
            .then(result => window.location.assign(result.paymentUrl));
        return executeAsyncAction(dispatch, executor);
    };
}
