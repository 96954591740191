import classNames from 'classnames/bind';
import * as React from 'react';

import {MatchParams} from '@utils/match-params';

import {FailInfo} from './components/fail-info';

import * as styles from './fail-payment-page.scss';

const cn = classNames.bind(styles);

interface FailPaymentPageInterface {
    match: MatchParams;
    passParams: (stepValue: MatchParams['params']) => void;
    fetchUserData?: (contractId: string) => void;
    loadReservationFailed: (contractId: string) => void;
}

export function FailPaymentPage({
    match,
    passParams,
    fetchUserData,
    loadReservationFailed,
}: FailPaymentPageInterface) {
    React.useEffect(() => {
        passParams(match.params);
        const {contractId} = match.params;
        fetchUserData(contractId);
        loadReservationFailed(contractId);
    }, []);

    return (
        <div className={cn('fail-info')}>
            <FailInfo />
        </div>
    );
}
