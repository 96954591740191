import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import {Scrollbars} from '../scrollbars';
import {ListItem} from './list-item';

import * as styles from './list.scss';

export interface ListInterface {
    getDescription?: (item: object) => string,
    getIcon?: (item: object) => JSX.Element,
    getRightIcon?: (item: object) => JSX.Element,
    getItemId: (item: object) => string | number
    getTitle?: (item: object) => string,
    items: object[],
    onClick?: (item: object) => object,
    onSelect?: (item: object) => void,
    selectedItem?: object | Function
}

const cn = classNames.bind(styles);

export function List({
    getDescription,
    getItemId,
    getIcon,
    getRightIcon,
    getTitle,
    items,
    onClick,
    onSelect,
    selectedItem,
}: ListInterface) {
    const renderIcon = (item: any, iconFunc: Function) => (iconFunc ? iconFunc(item) : null);

    const handleClick = (item: any) => {
        onClick(item);
        if (item !== selectedItem) {
            onSelect(item);
        }
    };

    return (
        <div className={cn('list')}>
            <Scrollbars>
                {items.map((item: any) => {
                    const selected = typeof selectedItem === 'function' ? selectedItem(item) : selectedItem === item;
                    return (
                        <ListItem
                            onClick={() => handleClick(item)}
                            title={getTitle(item)}
                            description={getDescription && getDescription(item)}
                            key={getItemId(item)}
                            icon={renderIcon(item, getIcon)}
                            rightIcon={renderIcon(item, getRightIcon)}
                            selected={selected}
                        />
                    );
                })}
            </Scrollbars>
        </div>
    );
}

List.defaultProps = {
    onClick: noop,
    onSelect: noop,
    getTitle: (item: any): string => item.title || '',
    getDescription: null,
    getIcon: null,
    getRightIcon: null,
    items: [],
    selectedItem: null,
};
