import classNames from 'classnames/bind';
import * as React from 'react';

import {
    SelectField,
    RadioButtonGroupField,
    TextField,
} from '@app/components';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {UsePeriods} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods';
import {
    Step2FormInterface,
    Step2FormInterfaceKeys,
} from '@app/pages/contract-page/pages/step-2/step2-types';
import {ContractDictionaries} from '@app/store/types';
import {createTranslator} from '@app/utils/i18n';
import {shouldBeDisabled} from '@utils/should-be-disabled';

import {uniqueId} from 'lodash';
import {RadioButtonProps} from '@app/components/radio-button-group';
import {CheckedField} from '../checked-field';

import * as styles from './right-part.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface RightPartInterface {
    dictionaries: ContractDictionaries;
    mutator: (field: string, value: string) => void;
    values: Step2FormInterface;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

const WAY_TO_REGISTRATION_OPTIONS = [{
    label: t('right-yes'),
    value: 'true',
}, {
    label: t('right-no'),
    value: 'false',
}];

export function RightPart({
    dictionaries,
    mutator,
    values,
    scansEditFields,
    isLkBlocked,
}: RightPartInterface) {
    const [checkedCount, setCheckedCount] = React.useState(0);

    const handleChangeGroup = (disabled: boolean) => {
        setCheckedCount(prevCheckedCount => (disabled ? prevCheckedCount + 1 : prevCheckedCount - 1));
    };

    const handleChangeOnTheWayToRegistration = (value: string) => {
        mutator(Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION, value);
    };

    const handleChangeUsedWithTrailer = (value: string) => {
        mutator(Step2FormInterfaceKeys.IS_USED_WITH_TRAILER, value);
    };

    const generateKey = (item: RadioButtonProps) => `${item?.label}-${uniqueId()}`;

    const isOnTheWayToRegistration = values.isOnTheWayToRegistration === String(true);
    const isShortInsurancePolicy = values?.isShortInsurancePolicy === String(true);

    return (
        <div className={cn('right-part')}>
            {(!isShortInsurancePolicy && !isOnTheWayToRegistration) && (
                <>
                    <h2>{t('right-age-use-transport')}</h2>
                    <UsePeriods
                        values={values}
                        mutator={mutator}
                    />
                </>
            )}
            <h2>{t('right-transport-id')}</h2>
            <span className={cn('right-part__description')}>{t('right-transport-id-description')}</span>
            <CheckedField
                cn={cn}
                textFieldLabel={t('right-vin')}
                checkboxLabel={t('right-absent')}
                name="vin"
                mutator={mutator}
                checkedCount={checkedCount}
                onChange={handleChangeGroup}
            />
            <CheckedField
                cn={cn}
                textFieldLabel={t('right-body-number')}
                checkboxLabel={t('right-absent')}
                name="bodyNumber"
                mutator={mutator}
                checkedCount={checkedCount}
                onChange={handleChangeGroup}
            />
            <CheckedField
                cn={cn}
                textFieldLabel={t('right-chassis-number')}
                checkboxLabel={t('right-absent')}
                name="schassisNumber"
                mutator={mutator}
                checkedCount={checkedCount}
                onChange={handleChangeGroup}
            />
            <CheckedField
                cn={cn}
                textFieldLabel={t('right-government-number')}
                checkboxLabel={t('right-not-registered')}
                name="numberPlate"
                mutator={mutator}
                checkedCount={checkedCount}
                onChange={handleChangeGroup}
            />
            <div className={cn('right-part__form-field-weight')}>
                <SelectField
                    label={t('right-use-purpose')}
                    options={dictionaries.vehiclePurposeTypes}
                    name="usePurpose"
                    disabled={shouldBeDisabled({
                        name: 'usePurpose',
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required
                />
            </div>
            <div className={cn('right-part__form-field-weight')}>
                <TextField
                    name="registrationCountry"
                    label={t('right-registration-country')}
                    disabled
                />
            </div>
            {
                !isShortInsurancePolicy && (
                    <>
                        <span className={cn('right-part__checkbox-description')}>
                            {t('right-follow-registration-place')}
                        </span>
                        <div className={cn('right-part__form-field-wrapper')}>
                            <div className={cn('right-part__form-field')}>
                                <RadioButtonGroupField
                                    onChange={handleChangeOnTheWayToRegistration}
                                    name={Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION}
                                    items={WAY_TO_REGISTRATION_OPTIONS}
                                    label={Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION}
                                    isDisabled={() => shouldBeDisabled({
                                        name: Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION,
                                        listFieldError: scansEditFields,
                                        isLkBlocked,
                                    })}
                                    getButtonKey={generateKey}
                                />
                            </div>
                        </div>
                    </>
                )
            }
            {/* поле «Управление ТС с прицепом» должно отображаться только для страхователя – юридического лица
             RAMIEPT-175173 до тех пор, пока в еГарант запрещены страхователи ЮЛ, поле отображаться не будет */}
        </div>
    );
}
