import * as React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    Period,
    MONTHS,
    calculateEndOfPeriod,
    calculateStartOfNextPeriod,
    calculateAvailableStartDate,
} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods';
import {
    UsePeriodButtons,
} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods/use-period-buttons';
import {
    periodOptions,
    UsePeriodSelectors,
} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods/use-period-selectors';
import {Step2FormInterface, Step2FormInterfaceKeys} from '@app/pages/contract-page/pages/step-2/step2-types';
import {isLkBlockedSelector} from '@modules/navigation';
import {validationResultScansEditFieldsSelector} from '@modules/validation/validation-selectors';
import {addMonths, getDifferenceInMonths} from '@utils/date';
import {SelectOption} from '@utils/formatters/select-option';
import {shouldBeDisabled} from '@utils/should-be-disabled';

enum PeriodIds {
    PERIOD_1 = 0,
    PERIOD_2 = 1,
    PERIOD_3 = 2,
}

export interface UsePeriodsInterface {
    values: Step2FormInterface;
    mutator: (field: string, value: string | SelectOption<number>) => void;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export const UsePeriods = ({
    values,
    mutator,
    scansEditFields,
    isLkBlocked,
}: UsePeriodsInterface) => {
    const isOnTheWayToRegistration = values.isOnTheWayToRegistration === String(true);
    const insuranceStartDate = values.insuranceStartDate ? new Date(values.insuranceStartDate) : new Date();
    const insuranceEndDate = values.insuranceEndDate ? new Date(values.insuranceEndDate) : new Date();

    const periods = React.useMemo(() => {
        const firstUsePeriodInterval = values.usePeriod1 || periodOptions.find(el => el.value === MONTHS.TWELVE);
        const firstUsePeriodStartDate = values.usePeriod1StartDate ?
            new Date(values.usePeriod1StartDate) :
            new Date();
        const firstUsePeriodEndDate = values.usePeriod1EndDate ?
            new Date(values.usePeriod1EndDate) :
            calculateEndOfPeriod(
                firstUsePeriodInterval.value,
                firstUsePeriodStartDate,
                isOnTheWayToRegistration
            );

        const periods: Period[] = [
            {
                interval: firstUsePeriodInterval,
                startDate: firstUsePeriodStartDate,
                endDate: firstUsePeriodEndDate,
            },
        ];

        if (values.usePeriod2 && !isOnTheWayToRegistration) {
            periods.push({
                interval: values.usePeriod2,
                startDate: values.usePeriod2StartDate && new Date(values.usePeriod2StartDate),
                endDate: values.usePeriod2EndDate && new Date(values.usePeriod2EndDate),
            });
        }

        if (values.usePeriod3 && !isOnTheWayToRegistration) {
            periods.push({
                interval: values.usePeriod3,
                startDate: values.usePeriod3StartDate && new Date(values.usePeriod3StartDate),
                endDate: values.usePeriod3EndDate && new Date(values.usePeriod3EndDate),
            });
        }

        return periods;
    }, [values]);

    const setUsePeriods = React.useCallback((newUsePeriods: Period[]) => {
        newUsePeriods.forEach((item: Period, i: number) => {
            mutator(`usePeriod${i + 1}`, item.interval);
            mutator(`usePeriod${i + 1}StartDate`, item.startDate.toISOString());
            mutator(`usePeriod${i + 1}EndDate`, item.endDate.toISOString());
        });
        if (newUsePeriods.length < 3) {
            mutator(Step2FormInterfaceKeys.USE_PERIOD_3, null);
            mutator(Step2FormInterfaceKeys.USE_PERIOD_3_START_DATE, null);
            mutator(Step2FormInterfaceKeys.USE_PERIOD_3_END_DATE, null);
        }
        if (newUsePeriods.length < 2) {
            mutator(Step2FormInterfaceKeys.USE_PERIOD_2, null);
            mutator(Step2FormInterfaceKeys.USE_PERIOD_2_START_DATE, null);
            mutator(Step2FormInterfaceKeys.USE_PERIOD_2_END_DATE, null);
        }
    }, [mutator]);

    const getAvailableDates = (interval: SelectOption<number>, index: number): [Date, Date] => {
        const today = new Date();
        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        const maxAvailableDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 60);
        const availableStartDate = calculateAvailableStartDate(interval, insuranceStartDate, isOnTheWayToRegistration);
        switch (index) {
            case PeriodIds.PERIOD_1:
                return [
                    insuranceStartDate > tomorrow ? insuranceStartDate : tomorrow,
                    maxAvailableDate < availableStartDate ? maxAvailableDate : availableStartDate,
                ];
            case PeriodIds.PERIOD_2:
                return [
                    calculateStartOfNextPeriod(new Date(values.usePeriod1EndDate)),
                    availableStartDate,
                ];
            case PeriodIds.PERIOD_3:
                return [
                    calculateStartOfNextPeriod(new Date(values.usePeriod2EndDate)),
                    availableStartDate,
                ];
            default:
                return [today, today];
        }
    };

    const isDateAvailable = (startDate: Date, interval: SelectOption<number>, index: number) => {
        const [minAvailableDate, maxAvailableDate] = getAvailableDates(interval, index);
        return startDate >= minAvailableDate && startDate <= maxAvailableDate;
    };

    const handlePeriodChange = (interval: SelectOption<number>, index: number) => {
        const newUsePeriods = periods.slice(0, index + 1);
        if (isDateAvailable(periods[index].startDate, interval, index)) {
            newUsePeriods[index].interval = interval;
            newUsePeriods[index].endDate = calculateEndOfPeriod(
                newUsePeriods[index].interval.value,
                newUsePeriods[index].startDate,
                isOnTheWayToRegistration
            );
        }
        setUsePeriods(newUsePeriods);
    };

    const handleStartDateChange = (date: string, index: number) => {
        const startDate = new Date(date);
        const newUsePeriods = periods.slice(0, index + 1);
        if (isDateAvailable(startDate, periods[index].interval, index)) {
            newUsePeriods[index].startDate = startDate;
            newUsePeriods[index].endDate = calculateEndOfPeriod(
                newUsePeriods[index].interval.value,
                startDate,
                isOnTheWayToRegistration
            );
        }
        setUsePeriods(newUsePeriods);
    };

    const isShownAddUsePeriod = () => {
        const MAX_DELAYS = 3;
        const availableStartDate = calculateEndOfPeriod(
            MONTHS.TWELVE - MONTHS.THREE,
            insuranceStartDate,
            isOnTheWayToRegistration
        );
        return periods.length < MAX_DELAYS &&
            !isOnTheWayToRegistration &&
            calculateStartOfNextPeriod(periods[periods.length - 1].endDate) < availableStartDate;
    };

    const isShownDeleteUsePeriod = () => periods.length > 1;

    const handleAddUsePeriod = () => {
        const newUsePeriods = [...periods];
        const startDate = calculateStartOfNextPeriod(periods[periods.length - 1].endDate);
        newUsePeriods.push({
            interval: periodOptions.find(el => el.value === MONTHS.THREE),
            startDate,
            endDate: calculateEndOfPeriod(
                MONTHS.THREE,
                startDate,
                isOnTheWayToRegistration
            ),
        });
        setUsePeriods(newUsePeriods);
    };

    const handleDeleteUsePeriod = () => setUsePeriods(periods.slice(0, periods.length - 1));

    const printIntervalSelectors = (period: Period, index: number) => {
        // Поправка связанная с тем что при выборе нескольких периодов выпадает день между периодами
        const getCorrectiveOfPeriod = () => {
            switch (index) {
                case 0:
                    return 1;
                case 1:
                    return 0;
                case 2:
                    return -1;
                default:
                    return 1;
            }
        };

        const periodEndDate = new Date(
            insuranceEndDate.getFullYear(),
            insuranceEndDate.getMonth(),
            insuranceEndDate.getDate() + getCorrectiveOfPeriod()
        );
        const maxMonthPeriod = getDifferenceInMonths(period.startDate, periodEndDate);
        const removeMonth = addMonths(period.startDate, maxMonthPeriod) > periodEndDate;
        return (
            <UsePeriodSelectors
                key={index.toString()}
                period={period}
                index={index}
                maxMonthPeriod={removeMonth ? maxMonthPeriod - 1 : maxMonthPeriod}
                isOnTheWayToRegistration={isOnTheWayToRegistration}
                handlePeriodChange={handlePeriodChange}
                handleStartDateChange={handleStartDateChange}
                getAvailableDates={getAvailableDates}
                disabled={shouldBeDisabled({
                    name: `usePeriod${index + 1}`,
                    listFieldError: scansEditFields,
                    isLkBlocked,
                    forceDisabled: isOnTheWayToRegistration,
                })}
            />
        );
    };

    return (
        <>
            {periods.map(printIntervalSelectors)}
            <UsePeriodButtons
                isShownAddUsePeriod={isShownAddUsePeriod}
                isShownDeleteUsePeriod={isShownDeleteUsePeriod}
                handleAddUsePeriod={handleAddUsePeriod}
                handleDeleteUsePeriod={handleDeleteUsePeriod}
                isLkBlocked={isLkBlocked}
            />
        </>
    );
};

UsePeriods.displayName = 'UsePeriods';

const mapStateToProps = createStructuredSelector({
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const UsePeriodsContainer = connect(
    mapStateToProps
)(UsePeriods);
