import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';
import {createTranslator} from '@utils/i18n';

import {FILTERS} from '../sidebar';

import * as dictionary from '../sidebar.dic.json';
import * as styles from './date-filter.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface DateFilterProps {
    type: string;
    selectedFilterType: string;
    onClick: (type: string) => void;
    onKeyDown: (event: React.KeyboardEvent, type: string) => void;
}

export const DateFilter = ({
    type,
    selectedFilterType,
    onClick,
    onKeyDown,
}: DateFilterProps) => {
    const filter = FILTERS[type];
    return (
        <div
            className={cn(
                'date-filter',
                {'date-filter--active': selectedFilterType === type},
                {'date-filter--disabled': filter.disabled}
            )}
            role="button"
            onClick={filter.disabled ? noop : () => onClick(type)}
            onKeyDown={e => onKeyDown(e, type)}
            tabIndex={filter.disabled ? -1 : 0}
        >
            {t(filter.label)}
        </div>
    );
};
