import classNames from 'classnames/bind';
import {range, uniqueId} from 'lodash-es';
import * as React from 'react';

import {
    AutocompleteSelectField,
    SelectField,
    TextField,
    DateField,
    RadioButtonGroupField,
    Hint,
} from '@app/components';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {
    Step2FormInterface,
    Step2FormInterfaceKeys,
} from '@app/pages/contract-page/pages/step-2/step2-types';
import {TypeCategory} from '@app/pages/contract-page/pages/step-2/utils';
import {ContractDictionaries} from '@app/store/types';
import {TypeCategoryDto} from '@app/types';
import {createTranslator} from '@app/utils/i18n';
import {getTypesCategoriesByMakerAndModel} from '@modules/contract/contract-api';
import {formatPower, formatTime} from '@utils/formatters';
import {objectsToSelectOptions} from '@utils/formatters/select-option';
import {shouldBeDisabled} from '@utils/should-be-disabled';
import {TYPE_CATEGORY} from '@utils/constants';
import {
    DateFormats,
    addYears,
    formatDate,
    subtractDays,
} from '@app/utils/date';

import {RadioButtonProps} from '@app/components/radio-button-group';
import {MakerModelFields} from '../transdekra';

import * as styles from './left-part.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface LeftPartInterface {
    dictionaries: ContractDictionaries;
    mutator: (field: string, value: string) => void;
    values: Step2FormInterface;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

const SHORT_INSURANCE_POLICY_OPTIONS = [{
    label: t('left-short-insurance-policy-yes'),
    value: 'true',
}, {
    label: t('left-short-insurance-policy-no'),
    value: 'false',
}];

export const LeftPart = ({
    dictionaries,
    mutator,
    values,
    scansEditFields,
    isLkBlocked,
}: LeftPartInterface) => {
    const isModelSelected = Boolean(values?.model?.value);
    const hasOtherModel = Boolean(values?.otherModel);
    const isShortPolicy = values?.isShortInsurancePolicy === 'true';

    const [isTypeCategoryDisabled, changeDisabling] = React.useState(!isModelSelected && !hasOtherModel);
    const [initialInsuranceEndDate, setInitialInsuranceEndDate] = React.useState<string>('');

    React.useEffect(() => {
        const shouldBeDisabled = !isModelSelected && !hasOtherModel;
        if (shouldBeDisabled) {
            mutator(TYPE_CATEGORY, null);
        }
        changeDisabling(shouldBeDisabled);
    }, [mutator, changeDisabling, isModelSelected, hasOtherModel]);

    React.useEffect(() => {
        mutator(Step2FormInterfaceKeys.IS_SHORT_INSURANCE_POLICY, values?.isShortInsurancePolicy);
        setInitialInsuranceEndDate(values?.insuranceEndDate);
    }, []);

    const yearsRangeOptions = range(new Date().getFullYear(), 1899).map((value: number) => ({
        label: String(value),
        value,
    }));

    const prepareShortInsurancePeriodFields = React.useCallback((
        {startDate, endDate}: {startDate: string, endDate: string}
    ) => {
        mutator(Step2FormInterfaceKeys.USE_PERIOD_1, null);
        mutator(Step2FormInterfaceKeys.USE_PERIOD_1_START_DATE, startDate);
        mutator(Step2FormInterfaceKeys.USE_PERIOD_1_END_DATE, endDate);

        mutator(Step2FormInterfaceKeys.USE_PERIOD_2, null);
        mutator(Step2FormInterfaceKeys.USE_PERIOD_2_START_DATE, null);
        mutator(Step2FormInterfaceKeys.USE_PERIOD_2_END_DATE, null);

        mutator(Step2FormInterfaceKeys.USE_PERIOD_3, null);
        mutator(Step2FormInterfaceKeys.USE_PERIOD_3_START_DATE, null);
        mutator(Step2FormInterfaceKeys.USE_PERIOD_3_END_DATE, null);
    }, [mutator]);

    const handleStartDateChange = React.useCallback((name: string, value: string) => {
        mutator(name, value);

        if (isShortPolicy) {
            prepareShortInsurancePeriodFields({startDate: value, endDate: values?.insuranceEndDate});
        }
    }, [values, isShortPolicy, mutator, prepareShortInsurancePeriodFields]);

    const handleEndDateChange = React.useCallback((value: string) => {
        mutator(Step2FormInterfaceKeys.INSURANCE_END_DATE, value);

        if (isShortPolicy) {
            prepareShortInsurancePeriodFields({startDate: values?.insuranceStartDate, endDate: value});
        }
    }, [values, isShortPolicy, mutator, prepareShortInsurancePeriodFields]);

    const handleShortInsurancePolicyChange = React.useCallback((value: string) => {
        mutator(Step2FormInterfaceKeys.IS_SHORT_INSURANCE_POLICY, value);

        if (value === 'true') {
            mutator(Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION, 'false');
            mutator(Step2FormInterfaceKeys.INSURANCE_END_DATE, initialInsuranceEndDate);
            prepareShortInsurancePeriodFields({
                startDate: values?.insuranceStartDate,
                endDate: initialInsuranceEndDate,
            });
        } else {
            const insuranceStartDate = new Date(values?.insuranceStartDate);
            const oneYearOlderDate = addYears(insuranceStartDate, 1);
            const oneYearInsuranceEndDate = subtractDays(oneYearOlderDate, 1);
            mutator(
                Step2FormInterfaceKeys.INSURANCE_END_DATE,
                formatDate(oneYearInsuranceEndDate, DateFormats.YYYYMMDD_HYPHEN),
            );
        }
    }, [initialInsuranceEndDate, values, mutator, prepareShortInsurancePeriodFields]);

    const generateKey = (item: RadioButtonProps) => `${item?.label}-${uniqueId()}`;

    // TODO: Обязательность полей диагностической карты убрана с фронта в рамках RAMIEPT-106267
    // https://gerrit-epm-insr-edp-cicd.dev-test.epm-insr.projects.epam.com/c/fe-egarant/+/31641
    return (
        <div className={cn('left-part')}>
            <MakerModelFields
                cn={cn}
                values={values}
                makerFieldLabel={t('left-maker')}
                modelFieldLabel={t('left-model')}
                otherMakerFieldLabel={t('left-maker-other')}
                otherModelFieldLabel={t('left-model-other')}
                leftDescription={t('left-description')}
                mutator={mutator}
            />
            <div className={cn('left-part__form-field-weight')}>
                <AutocompleteSelectField
                    name="typeCategory"
                    label={t('left-typeCategory')}
                    options={[]}
                    loadOptions={async () => {
                        const result = await getTypesCategoriesByMakerAndModel(
                            values?.maker?.value?.name,
                            values?.model?.value?.name
                        );
                        return new Promise(resolve => resolve({
                            options: objectsToSelectOptions<TypeCategoryDto>(result, 'fullName'),
                            hasMoreData: false,
                        }));
                    }}
                    disabled={shouldBeDisabled({
                        name: TYPE_CATEGORY,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                        forceDisabled: isTypeCategoryDisabled,
                    })}
                    required
                />
            </div>
            {
                values?.typeCategory?.value?.code === TypeCategory.D_DE && (
                    <div className={cn('left-part__form-field-weight')}>
                        <TextField
                            name="seatsAmount"
                            label={t('left-seatsAmount')}
                            disabled={shouldBeDisabled({
                                name: 'seatsAmount',
                                listFieldError: scansEditFields,
                                isLkBlocked,
                            })}
                            parse={value => value.replace(/[^\d]/g, '')}
                            required
                        />
                    </div>
                )
            }
            {
                values?.typeCategory?.value?.code === TypeCategory.C_CE && (
                    <div className={cn('left-part__form-field-weight')}>
                        <TextField
                            name="maxWeight"
                            label={t('left-maxWeight')}
                            disabled={shouldBeDisabled({
                                name: 'maxWeight',
                                listFieldError: scansEditFields,
                                isLkBlocked,
                            })}
                            parse={value => value.replace(/[^\d]/g, '')}
                            required
                        />
                    </div>
                )
            }
            <div className={cn('left-part__form-field-weight')}>
                <SelectField
                    label={t('left-year')}
                    options={yearsRangeOptions}
                    name="year"
                    disabled={shouldBeDisabled({
                        name: 'year',
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required
                />
            </div>
            {
                values?.typeCategory?.value?.code === TypeCategory.B_BE && (
                    <div className={cn('left-part__form-field-weight')}>
                        <TextField
                            name="power"
                            label={t('left-power')}
                            disabled={shouldBeDisabled({
                                name: 'power',
                                listFieldError: scansEditFields,
                                isLkBlocked,
                            })}
                            parse={formatPower}
                            required
                        />
                    </div>
                )
            }
            <div className={cn('left-part__form-field-weight')}>
                <SelectField
                    label={t('left-document-type')}
                    options={dictionaries.vehicleDocumentTypes}
                    name="document-documentType"
                    disabled={shouldBeDisabled({
                        name: 'document-documentType',
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required
                />
            </div>
            <div className={cn('left-part__form-field-wrapper')}>
                <div className={cn('left-part__form-field')}>
                    <TextField
                        name="document-series"
                        label={t('left-document-series')}
                        disabled={shouldBeDisabled({
                            name: 'document-series',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                    />
                </div>
                <div className={cn('left-part__form-field')}>
                    <TextField
                        name="document-number"
                        label={t('left-document-number')}
                        disabled={shouldBeDisabled({
                            name: 'document-number',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                        required
                    />
                </div>
            </div>
            <div className={cn('left-part__form-field-weight')}>
                <DateField
                    label={t('left-extradition-date')}
                    name="document-dateGiven"
                    isDisabled={shouldBeDisabled({
                        name: 'document-dateGiven',
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                    required
                />
            </div>
            <h2>{t('left-diagnostic-card')}</h2>
            <div className={cn('left-part__form-field-wrapper')}>
                <div className={cn('left-part__form-field')}>
                    <TextField
                        name="diagnosticCard-number"
                        label={t('left-diagnostic-number')}
                        disabled={shouldBeDisabled({
                            name: 'diagnosticCard-number',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                    />
                </div>
                <div className={cn('left-part__form-field')}>
                    <DateField
                        label={t('left-expiration-date')}
                        name="diagnosticCard-dateEnd"
                        isDisabled={shouldBeDisabled({
                            name: 'diagnosticCard-dateEnd',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                    />
                </div>
            </div>
            <h2>
                <>
                    {t('left-short-insurance-policy')}
                    {' '}
                    <Hint text={t('left-short-insurance-policy-hint')} />
                </>
            </h2>
            <>
                <div style={{paddingBottom: '20px'}}>
                    <RadioButtonGroupField
                        onChange={handleShortInsurancePolicyChange}
                        name={Step2FormInterfaceKeys.IS_SHORT_INSURANCE_POLICY}
                        items={SHORT_INSURANCE_POLICY_OPTIONS}
                        label={Step2FormInterfaceKeys.IS_SHORT_INSURANCE_POLICY}
                        isDisabled={() => shouldBeDisabled({
                            name: Step2FormInterfaceKeys.IS_SHORT_INSURANCE_POLICY,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                        getButtonKey={generateKey}
                    />
                </div>
            </>
            <h2>{t('left-insurance-period-start')}</h2>
            <div className={cn('left-part__form-field-wrapper')}>
                <div className={cn('left-part__form-field')}>
                    <TextField
                        name="insuranceStartTime"
                        label={t('left-time')}
                        parse={formatTime}
                        disabled
                        onChange={value => handleStartDateChange('insuranceStartTime', value)}
                        required
                    />
                </div>
                <div className={cn('left-part__form-field')}>
                    <DateField
                        label={t('left-date')}
                        name="insuranceStartDate"
                        isDisabled={shouldBeDisabled({
                            name: 'insuranceStartDate',
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        })}
                        onChange={value => handleStartDateChange('insuranceStartDate', value)}
                        availableDates={[new Date()]}
                        required
                    />
                </div>
            </div>
            <h2>{t('left-insurance-period-end')}</h2>
            <div className={cn('left-part__form-field-wrapper')}>
                <div className={cn('left-part__form-field')}>
                    <TextField
                        name="insuranceEndTime"
                        label={t('left-time')}
                        parse={formatTime}
                        disabled
                    />
                </div>
                <div className={cn('left-part__form-field')}>
                    <DateField
                        label={t('left-date')}
                        name="insuranceEndDate"
                        isDisabled={!isShortPolicy || isLkBlocked}
                        onChange={handleEndDateChange}
                    />
                </div>
            </div>
        </div>
    );
};
