type Translator = (text: string, ...args: (string | number)[]) => string;

// Stub for internationalization
export function createTranslator(dictionary: {[key: string]: string}): Translator {
    return (label: string, ...args) => {
        let translation = dictionary[label] || label;
        args.forEach((arg: string | number, idx: number) => {
            translation = translation.replace(`$${idx + 1}`, String(arg));
        });
        return translation;
    };
}
