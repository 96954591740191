export enum DateFormats {
    MMDDYYYY_SLASHED = 'MM/dd/yyyy',
    MMDDYYYY_DOT = 'MM.dd.yyyy',
    DDMMYYYY_DOT = 'dd.MM.yyyy',
    DDMMYYYY_TIME_DOT = 'dd.MM.yyyy HH:mm',
    DDMMYYYY_FULL_TIME_DOT = 'dd.MM.yyyy HH:mm:ss',
    YYYYMMDD_HHMMSS = 'yyyy-MM-dd HH:mm:ss',
    YYYYMMDD_HYPHEN = 'yyyy-MM-dd',
    DDMMYYYY_HYPHEN = 'dd-MM-yyyy',
    DDMMYYYYHHMM_HYPHEN = 'dd-MM-yyyy HH:MM',
    MMMM = 'MMMM',
    YYYY = 'yyyy',
    HHMMSS = 'HH:mm:ss'
}

export const WeeksDay = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const Months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];
