import {
    FiasDataInterface,
    Step1FormInterface,
} from '@app/pages/contract-page/pages/step-1/step1-types';
import {ContractDictionaries} from '@app/store/types';
import {
    FiasElement,
    Insurer,
    Owner,
} from '@app/types';
import {
    clearPhoneNumber,
    formatPhoneNumber,
    formatSnilsNumber,
} from '@utils/formatters';
import {
    toContractDateFormat,
    toLocalTimeZone,
} from '@utils/formatters/date';
import {objectToSelectOption} from '@utils/formatters/select-option';
import {clearSnilsFormater} from '@utils/formatters/snils';
import {
    RUSSIAN_FEDERATION,
    NAME,
} from '@utils/constants';

export const convertContractPersonsToFormValues = (
    insurer: Insurer,
    owner: Owner,
    {identityCardTypes, organizationRegDocTypes}: ContractDictionaries
): Step1FormInterface => ({
    'insurer-isOwner': insurer.isOwner,
    'insurer-isDriver': insurer.isDriver,
    'insurer-person-name': insurer?.person?.name,
    'insurer-person-secondName': insurer?.person?.noSecondName ? null : insurer?.person?.secondName,
    'insurer-person-surname': insurer?.person?.surname,
    'insurer-person-snils': formatSnilsNumber(insurer?.person?.snils),
    'insurer-person-birthdate': toLocalTimeZone(insurer?.person?.birthdate),
    'insurer-person-phoneNumber': formatPhoneNumber(insurer?.person?.phoneNumber),
    'insurer-person-noSecondName': insurer?.person?.noSecondName,
    'insurer-document-docType': identityCardTypes.find(el => el.value === insurer?.document?.docType),
    'insurer-document-docSeries': insurer?.document?.docSeries,
    'insurer-document-docNumber': insurer?.document?.docNumber,
    'insurer-addressFias-country': RUSSIAN_FEDERATION,
    'insurer-addressFias-region': objectToSelectOption<FiasElement>(insurer?.addressFias?.region, NAME),
    'insurer-addressFias-district': objectToSelectOption<FiasElement>(insurer?.addressFias?.district, NAME),
    'insurer-addressFias-city': objectToSelectOption<FiasElement>(insurer?.addressFias?.city, NAME),
    'insurer-addressFias-locality': objectToSelectOption<FiasElement>(insurer?.addressFias?.locality, NAME),
    'insurer-addressFias-street': objectToSelectOption<FiasElement>(insurer?.addressFias?.street, NAME),
    'insurer-addressFias-house': objectToSelectOption<FiasElement>(insurer?.addressFias?.house, NAME),
    'insurer-addressFias-building': objectToSelectOption<FiasElement>(insurer?.addressFias?.building, NAME),
    'insurer-addressFias-structure': objectToSelectOption<FiasElement>(insurer?.addressFias?.structure, NAME),
    'insurer-addressFias-room': objectToSelectOption<FiasElement>(insurer?.addressFias?.room, NAME),

    'owner-ownerType': owner.ownerType,
    'owner-isDriver': owner.isDriver,
    'owner-person-name': owner?.person?.name,
    'owner-person-secondName': owner?.person?.noSecondName ? null : owner?.person?.secondName,
    'owner-person-surname': owner?.person?.surname,
    'owner-person-snils': formatSnilsNumber(owner?.person?.snils),
    'owner-person-birthdate': toLocalTimeZone(owner?.person?.birthdate),
    'owner-person-phoneNumber': formatPhoneNumber(owner?.person?.phoneNumber),
    'owner-person-noSecondName': owner?.person?.noSecondName,
    'owner-legalPerson-INN': owner?.legalPerson?.INN,
    'owner-legalPerson-fullName': owner?.legalPerson?.fullName,
    'owner-legalPersonDocument-documentType': organizationRegDocTypes
        .find(el => el.value === owner?.legalPersonDocument?.documentType),
    'owner-legalPersonDocument-documentSeries': owner?.legalPersonDocument?.documentSeries,
    'owner-legalPersonDocument-documentNumber': owner?.legalPersonDocument?.documentNumber,
    'owner-legalPersonDocument-accountingDate': owner?.legalPersonDocument?.accountingDate,
    'owner-personDocument-docType': identityCardTypes.find(el => el.value === owner?.personDocument?.docType),
    'owner-personDocument-docSeries': owner?.personDocument?.docSeries,
    'owner-personDocument-docNumber': owner?.personDocument?.docNumber,
    'owner-addressFias-country': RUSSIAN_FEDERATION,
    'owner-addressFias-region': objectToSelectOption<FiasElement>(owner?.addressFias?.region, NAME),
    'owner-addressFias-district': objectToSelectOption<FiasElement>(owner?.addressFias?.district, NAME),
    'owner-addressFias-city': objectToSelectOption<FiasElement>(owner?.addressFias?.city, NAME),
    'owner-addressFias-locality': objectToSelectOption<FiasElement>(owner?.addressFias?.locality, NAME),
    'owner-addressFias-street': objectToSelectOption<FiasElement>(owner?.addressFias?.street, NAME),
    'owner-addressFias-house': objectToSelectOption<FiasElement>(owner?.addressFias?.house, NAME),
    'owner-addressFias-building': objectToSelectOption<FiasElement>(owner?.addressFias?.building, NAME),
    'owner-addressFias-structure': objectToSelectOption<FiasElement>(owner?.addressFias?.structure, NAME),
    'owner-addressFias-room': objectToSelectOption<FiasElement>(owner?.addressFias?.room, NAME),
});

export const prepareFormValuesForTransfer = ({
    'owner-addressFias-country': ownerCountry,
    'insurer-addressFias-country': insurerCountry,
    ...values
}: Step1FormInterface, fiasData: FiasDataInterface) => ({
    ...values,
    'owner-addressFias-region': values['owner-addressFias-region']?.value || null,
    'owner-addressFias-district': values['owner-addressFias-district']?.value || null,
    'owner-addressFias-city': values['owner-addressFias-city']?.value || null,
    'owner-addressFias-locality': values['owner-addressFias-locality']?.value || null,
    'owner-addressFias-street': values['owner-addressFias-street']?.value || null,
    'owner-addressFias-house': values['owner-addressFias-house']?.value || null,
    'owner-addressFias-building': values['owner-addressFias-building']?.value || null,
    'owner-addressFias-structure': values['owner-addressFias-structure']?.value || null,
    'owner-addressFias-room': values['owner-addressFias-room']?.value || null,
    'insurer-addressFias-region': values['insurer-addressFias-region']?.value || null,
    'insurer-addressFias-district': values['insurer-addressFias-district']?.value || null,
    'insurer-addressFias-city': values['insurer-addressFias-city']?.value || null,
    'insurer-addressFias-locality': values['insurer-addressFias-locality']?.value || null,
    'insurer-addressFias-street': values['insurer-addressFias-street']?.value || null,
    'insurer-addressFias-house': values['insurer-addressFias-house']?.value || null,
    'insurer-addressFias-building': values['insurer-addressFias-building']?.value || null,
    'insurer-addressFias-structure': values['insurer-addressFias-structure']?.value || null,
    'insurer-addressFias-room': values['insurer-addressFias-room']?.value || null,
    'owner-person-phoneNumber': clearPhoneNumber(values['owner-person-phoneNumber']),
    'insurer-person-phoneNumber': clearPhoneNumber(values['insurer-person-phoneNumber']),
    'insurer-document-docType': values['insurer-document-docType']?.value,
    'owner-personDocument-docType': values['owner-personDocument-docType']?.value,
    'owner-legalPersonDocument-documentType': values['owner-legalPersonDocument-documentType']?.value,
    'owner-legalPersonDocument-accountingDate':
        toContractDateFormat(values['owner-legalPersonDocument-accountingDate']),
    'insurer-person-birthdate': toContractDateFormat(values['insurer-person-birthdate']),
    'owner-person-birthdate': toContractDateFormat(values['owner-person-birthdate']),
    'owner-person-snils': clearSnilsFormater(values['owner-person-snils']),
    'insurer-person-snils': clearSnilsFormater(values['insurer-person-snils']),
});

export const postFiltrationOwner = (owner: Owner): Owner => {
    const isPersonNull = !owner?.person?.name &&
        !owner?.person?.secondName &&
        !owner?.person?.surname &&
        !owner?.person?.birthdate &&
        !owner?.person?.phoneNumber &&
        !owner?.person?.noSecondName;
    const isPersonDocumentNull = !owner?.personDocument?.docType &&
        !owner?.personDocument?.docSeries &&
        !owner?.personDocument?.docNumber;
    const isLegalPersonNull = !owner?.legalPerson?.INN &&
        !owner?.legalPerson?.fullName;
    const isLegalPersonDocumentNull = !owner?.legalPersonDocument?.documentType &&
        !owner?.legalPersonDocument?.documentSeries &&
        !owner?.legalPersonDocument?.documentNumber &&
        !owner?.legalPersonDocument?.accountingDate;

    return {
        ...owner,
        person: isPersonNull ? null : owner.person,
        personDocument: isPersonDocumentNull ? null : owner.personDocument,
        legalPerson: isLegalPersonNull ? null : owner.legalPerson,
        legalPersonDocument: isLegalPersonDocumentNull ? null : owner.legalPersonDocument,
    };
};
