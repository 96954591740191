import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {contractValidationSnapshot} from '@app/store/actions';
import {contractIdSelector} from '@modules/contract';
import {
    isAllScansApprovedSelector,
    isValidationInProgressSelector,
    isValidationWaitingSelector,
} from '@modules/validation';

import {ValidationPoller} from './validation-poller';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isValidationInProgress: isValidationInProgressSelector,
    isValidationWaiting: isValidationWaitingSelector,
    isAllScansApproved: isAllScansApprovedSelector,
});

const mapDispatchToProps = {
    getContractValidation: contractValidationSnapshot,
};

export const ValidationPollerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ValidationPoller);
