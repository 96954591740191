import {DocumentTypes} from '@app/types';
import {
    Document,
    DocumentFileStatuses,
} from '@modules/contract';

export const fileNameMock = 'test_file.pdf';

export const getUploadedDocumentsFilesWithStatus = (status: DocumentFileStatuses) => ([
    {
        fileName: '1.jpeg',
        fileOrderInGroup: 1,
        fileGuid: 'p6n0xta0m4r0l791',
        documentType: DocumentTypes.INSURER_DOCUMENT,
        documentIndex: null,
        status,
    },

    {
        fileName: '2.jpeg',
        fileOrderInGroup: 2,
        fileGuid: 'p6n0xta0m4r0l792',
        documentType: DocumentTypes.INSURER_DOCUMENT,
        documentIndex: null,
        status,
    },

    {
        fileName: '3.jpeg',
        fileOrderInGroup: 1,
        fileGuid: 'p6n0xta0m4r0l793',
        documentType: DocumentTypes.OWNER_DOCUMENT,
        documentIndex: null,
        status,
    },

    {
        fileName: '4.jpeg',
        fileOrderInGroup: 2,
        fileGuid: 'p6n0xta0m4r0l794',
        documentType: DocumentTypes.OWNER_DOCUMENT,
        documentIndex: null,
        status,
    },

    {
        fileName: '5.jpeg',
        fileOrderInGroup: 1,
        fileGuid: 'p6n0xta0m4r0l795',
        documentType: DocumentTypes.VEHICLE_DOCUMENT,
        documentIndex: null,
        status,
    },

    {
        fileName: '6.jpeg',
        fileOrderInGroup: 2,
        fileGuid: 'p6n0xta0m4r0l796',
        documentType: DocumentTypes.VEHICLE_DOCUMENT,
        documentIndex: null,
        status,
    },

    {
        fileName: '7.jpeg',
        fileOrderInGroup: 1,
        fileGuid: 'p6n0xta0m4r0l797',
        documentType: DocumentTypes.DRIVERS_DOCUMENTS,
        documentIndex: 0,
        status,
    },

    {
        fileName: '8.jpeg',
        fileOrderInGroup: 2,
        fileGuid: 'p6n0xta0m4r0l798',
        documentType: DocumentTypes.DRIVERS_DOCUMENTS,
        documentIndex: 0,
        status,
    },

    {
        fileName: '9.jpeg',
        fileOrderInGroup: 1,
        fileGuid: 'p6n0xta0m4r0l799',
        documentType: DocumentTypes.DRIVERS_DOCUMENTS,
        documentIndex: 1,
        status,
    },

    {
        fileName: '10.jpeg',
        fileOrderInGroup: 2,
        fileGuid: 'p6n0xta0m4r0l800',
        documentType: DocumentTypes.DRIVERS_DOCUMENTS,
        documentIndex: 1,
        status,
    },
]);

export const documentsMock: Document[] = [
    {
        type: DocumentTypes.INSURER_DOCUMENT,
        title: 'Документ, удостоверяющий личность страхователя',
        label: 'ЮЛИЯ АЛЕКСАНДРОВНА ПЕТРОВА',
        files: [
            {
                fileOrderInGroup: 1,
                type: 'Разворот с фотографией',
                required: true,
            },
            {
                fileOrderInGroup: 2,
                type: 'Разворот с актуальной регистрацией',
                required: true,
            },
        ],
    },
    {
        type: DocumentTypes.OWNER_DOCUMENT,
        title: 'Документ, удостоверяющий личность собственника ТС',
        label: 'ЮЛИЯ АЛЕКСАНДРОВНА ПЕТРОВА',
        files: [
            {
                fileOrderInGroup: 1,
                type: 'Разворот с фотографией',
                required: true,
            },
            {
                fileOrderInGroup: 2,
                type: 'Разворот с актуальной регистрацией',
                required: false,
            },
        ],
    },
    {
        type: DocumentTypes.VEHICLE_DOCUMENT,
        title: 'Паспорт транспортного средства/самоходной машины, либо выписка из...',
        label: 'ЮЛИЯ АЛЕКСАНДРОВНА ПЕТРОВА',
        files: [
            {
                fileOrderInGroup: 1,
                type: 'Лицевая сторона',
                required: true,
            },
            {
                fileOrderInGroup: 2,
                type: 'Оборотная сторона',
                required: true,
            },
        ],
    },
    {
        type: DocumentTypes.DRIVERS_DOCUMENTS,
        title: 'Водительское удостоверение первого водителя',
        label: 'ПЕТРОВА ЮЛИЯ АЛЕКСАНДРОВНА',
        documentIndex: 0,
        files: [
            {
                fileOrderInGroup: 1,
                type: 'Разворот с фотографией',
                required: true,
            },
            {
                fileOrderInGroup: 2,
                type: 'Разворот с актуальной регистрацией',
                required: true,
            },
        ],
    },
    {
        type: DocumentTypes.DRIVERS_DOCUMENTS,
        title: 'Водительское удостоверение второго водителя',
        label: 'ПЕТРОВ ЮРИЙ АЛЕКСАНДРОВИЧ',
        documentIndex: 1,
        files: [
            {
                fileOrderInGroup: 1,
                type: 'Разворот с фотографией',
                required: true,
            },
            {
                fileOrderInGroup: 2,
                type: 'Разворот с актуальной регистрацией',
                required: true,
            },
        ],
    },
];
