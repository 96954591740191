import classNames from 'classnames/bind';
import * as React from 'react';

import {
    DateField,
    SelectField,
    TextField,
} from '@app/components';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {ContractDictionaries} from '@app/store/types';
import {PersonType} from '@app/types';
import {createTranslator} from '@app/utils/i18n';
import {shouldBeDisabled} from '@utils/should-be-disabled';

import * as styles from './document-data.scss';

const t = createTranslator(dictionary);
const cn = classNames.bind(styles);

const DOCUMENT = 'document';
const PERSON_DOC = 'personDocument';
const CERTIFICATE_OF_REGISTRATION_WITH_TAX = '64';

export interface DocumentDataInterface {
    dictionaries: ContractDictionaries;
    isOwnerLegal?: boolean;
    personType: PersonType;
    scansEditFields: string[];
    isLkBlocked: boolean;
    legalDocCode?: string;
}

export function DocumentData({
    dictionaries,
    isOwnerLegal,
    personType,
    scansEditFields,
    isLkBlocked,
    legalDocCode,
}: DocumentDataInterface) {
    const isInsurer = personType === PersonType.INSURER;

    const [isRequiredFields, setIsRequiredFields] = React.useState(
        legalDocCode !== CERTIFICATE_OF_REGISTRATION_WITH_TAX
    );

    const changeSelectField = ({value}: {value: string}) => {
        if (!isInsurer && isOwnerLegal) {
            const isRequired = value !== CERTIFICATE_OF_REGISTRATION_WITH_TAX;

            setIsRequiredFields(isRequired);
        }
    };

    return (
        <div className={cn('document-data')}>
            <h2 className={cn('document-data__secondary-header')}>
                {t(isOwnerLegal ? 'document-type-title-legal' : 'document-type-title')}
            </h2>
            <div className={cn('document-data__container')}>
                <div className={cn('document-data__form-field-weight')}>
                    <SelectField
                        label={t(isOwnerLegal ? 'document-type-legal' : 'document-type')}
                        name={isOwnerLegal ?
                            `${personType}-legalPersonDocument-documentType` :
                            `${personType}-${isInsurer ? DOCUMENT : PERSON_DOC}-docType`}
                        options={isOwnerLegal ? dictionaries.organizationRegDocTypes : dictionaries.identityCardTypes}
                        // Не редактируемое поле для собственник ЮЛ по RAMIEPT-175177
                        disabled={shouldBeDisabled({
                            name: isOwnerLegal ?
                                `${personType}-legalPersonDocument-documentType` :
                                `${personType}-${isInsurer ? DOCUMENT : PERSON_DOC}-docType`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        }) || isOwnerLegal}
                        onChange={changeSelectField}
                        required
                    />
                </div>
                <div className={cn('document-data__form-field-wrapper')}>
                    <div className={cn('document-data__form-field')}>
                        <TextField
                            name={isOwnerLegal ?
                                `${personType}-legalPersonDocument-documentSeries` :
                                `${personType}-${isInsurer ? DOCUMENT : PERSON_DOC}-docSeries`}
                            label={t('document-series')}
                            disabled={shouldBeDisabled({
                                name: isOwnerLegal ?
                                    `${personType}-legalPersonDocument-documentSeries` :
                                    `${personType}-${isInsurer ?
                                        DOCUMENT :
                                        PERSON_DOC}-docSeries`,
                                listFieldError: scansEditFields,
                                isLkBlocked,
                            })}
                        />
                    </div>
                    <div className={cn('document-data__form-field')}>
                        <TextField
                            name={isOwnerLegal ?
                                `${personType}-legalPersonDocument-documentNumber` :
                                `${personType}-${isInsurer ? DOCUMENT : PERSON_DOC}-docNumber`}
                            label={t('document-number')}
                            disabled={shouldBeDisabled({
                                name: isOwnerLegal ?
                                    `${personType}-legalPersonDocument-documentNumber` :
                                    `${personType}-${personType === PersonType.INSURER ?
                                        DOCUMENT :
                                        PERSON_DOC}-docNumber`,
                                listFieldError: scansEditFields,
                                isLkBlocked,
                            })}
                            required={isRequiredFields}
                        />
                    </div>
                </div>
                {
                    isOwnerLegal && (
                        <div className={cn('document-data__form-field')}>
                            <DateField
                                label={t('document-accounting-date')}
                                name={`${personType}-legalPersonDocument-accountingDate`}
                                isDisabled={shouldBeDisabled({
                                    name: `${personType}-legalPersonDocument-accountingDate`,
                                    listFieldError: scansEditFields,
                                    isLkBlocked,
                                })}
                                width="100%"
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
}
