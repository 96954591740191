import classNames from 'classnames/bind';
import * as React from 'react';

import {TableLayout} from '@app/components/layouts/table-layout';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import * as dictionaryFlk from '@app/pages/shared-pages/layout-page/flk.dic.json';
import {CalculationData, LinkData} from '@app/types';
import {formatSum} from '@app/utils/formatters';
import {
    DataTable,
    Anchor,
    Column,
} from '@ui-kit';
import {TEST_MAX_CONTRACT_AMOUNT} from '@utils/constants/egarant-settings';
import {urlFormatter} from '@utils/formatters/url-formatter';
import {createTranslator} from '@utils/i18n';

import * as styles from './check-result.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);
const f = createTranslator(dictionaryFlk);

const getTableColumns = (): Column[] => [
    {
        name: 'tb',
        header: t('check-result-base'),
        width: 120,
    },
    {
        name: 'kt',
        header: t('check-result-territory'),
        width: 240,
    },
    {
        name: 'kbm',
        header: t('check-result-bonus'),
        width: 110,
    },
    {
        name: 'kbc',
        header: t('check-result-experience'),
        width: 120,
    },
    {
        name: 'kc',
        header: t('check-result-season-use'),
        width: 160,
    },
    {
        name: 'kp',
        header: t('check-result-in-short-use'),
        width: 140,
    },
    {
        name: 'km',
        header: t('check-result-engine-power'),
        width: 130,
    },
    {
        name: 'ko',
        header: t('check-result-drivers-count'),
        width: 130,
    },
];

const getTableRows = (tableData: CalculationData) => [
    {
        tb: t('check-result-base-cell'),
        kt: t('check-result-territory-cell'),
        kbm: t('check-result-bonus-cell'),
        kbc: t('check-result-experience-cell'),
        kc: t('check-result-season-use-cell'),
        kp: t('check-result-in-short-use-cell'),
        km: t('check-result-engine-power-cell'),
        ko: t('check-result-drivers-count-cell'),
    },
    tableData,
];

export function CheckResult(
    {
        linkData,
        tableData,
        isAmountInLimits,
    }:
    {
        linkData: LinkData;
        tableData: CalculationData;
        isAmountInLimits: boolean;
    }
) {
    return (
        <div className={cn('check-result')}>
            {tableData && (
                <div>
                    <div className={cn('check-result__verdict')}>
                        <div className={cn('check-result__verdict-wrapper')}>
                            <h2 className={cn('check-result__verdict-status')}>
                                {t('check-result-result')}
                            </h2>
                            <div className={cn('check-result__verdict-value--success')}>
                                {t('check-result-success')}
                            </div>
                        </div>
                    </div>
                    <div className={cn('check-result__headers-container')}>
                        <div className={cn('check-result__headers')}>
                            <h2 className={cn('check-result__cost')}>
                                {t('check-result-cost')}
                            </h2>
                            <h2 className={cn('check-result__cost-value',
                                !isAmountInLimits && 'check-result__cost-value--warning')}
                            >
                                {formatSum({value: tableData.amount})}
                            </h2>

                        </div>
                        {!isAmountInLimits && (
                            <p className={cn('check-result__price-warning')}>
                                {f('16002032', formatSum({value: TEST_MAX_CONTRACT_AMOUNT, noFractionalPart: true}))}
                                <Anchor
                                    link={linkData?.insCompanyUrl}
                                    label={urlFormatter(linkData?.insCompanyUrl)}
                                    target="_blank"
                                />
                            </p>
                        )}
                    </div>
                    <div className={cn('check-result__headers-container')}>
                        <div className={cn('check-result__headers')}>
                            <h2 className={cn('check-result__table-header')}>
                                {t('check-result-table-header')}
                            </h2>
                        </div>
                    </div>
                    <div className={cn('check-result__results-table-wrapper')} id={cn('table-id')}>
                        <TableLayout
                            body={(
                                <DataTable
                                    headerHeight={55}
                                    rows={getTableRows(tableData)}
                                    columns={getTableColumns()}
                                    getRowId={({kp}: CalculationData) => `${kp}`}
                                />
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
