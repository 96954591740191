
import classNames from 'classnames/bind';
import * as React from 'react';

import {convertToTimeMMSS, convertToTimeHHMMSS} from '@app/utils/formatters';
import {interval} from '@utils/interval';

import * as styles from './timer.scss';

const cn = classNames.bind(styles);

interface TimerInterface {
    expirationTime: number;
    handleEndTimer: () => void;
    title?: string;
    currentStage?: string;
}

const OF_MILISECONDS = 1000;
const OF_SECONDS_IN_HOUR = 3600;

export const Timer = ({
    expirationTime,
    handleEndTimer,
    title,
}: TimerInterface): JSX.Element => {
    const [stateTime, setTime] = React.useState((expirationTime - Date.now()) / OF_MILISECONDS);
    const hasHours = Math.round(stateTime) >= OF_SECONDS_IN_HOUR;

    React.useEffect(() => {
        const intervalId = interval(() => {
            setTime(() => {
                const newTime = (expirationTime - Date.now()) / OF_MILISECONDS;
                if (newTime <= 0) {
                    handleEndTimer();
                    intervalId.clear();
                    return 0;
                }
                return newTime;
            });
        }, OF_MILISECONDS);
        return () => intervalId.clear();
    }, [expirationTime]);
    const displayTime = stateTime < 0 ? 0 : stateTime;

    return (
        <>
            {
                title &&
                (
                    <div className={cn('header-timer-text')}>
                        {title}
                    </div>
                )
            }
            <div className={cn('header-timer')}>
                {
                    hasHours ?
                        convertToTimeHHMMSS(Math.round(displayTime)) :
                        convertToTimeMMSS(Math.round(displayTime))
                }
            </div>
        </>
    );
};
