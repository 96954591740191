import {
    ValidationInterface,
    MappedValidationInterface,
} from '@modules/validation';
import {
    Mismatch,
    ValidationFields,
} from '@app/types';

export const secondsLeftMock = 4;
export const isOwnerMock = false;

const mismatchFields = {
    fieldName: 'name',
    fieldValue: 'sdfasdf',
    fieldMessages: ['Такое имя не существует'],
};

export const mappedValidationMock: MappedValidationInterface = {
    step1: [mismatchFields],
    step2: [mismatchFields],
    step3: [mismatchFields],
};

export const validationStateMock: ValidationInterface = {
    validationData: {
        isValidationInitiated: false,
        isValidationInProgress: false,
        isScanSendingInitiated: false,
        isScanEditingInitiated: false,
        isDataEdited: false,
        validationResultEntities: [],
        validationResultScans: [],
        validationResultFields: {
            owner: [],
            insurer: [],
            vehicle: [],
            drivers: [],
            contract: [],
            driversLimit: null,
        },
        driverIndexes: [0],
    },
    mappedValidationKit: mappedValidationMock,
    validationResultErrorFields: [],
    stepsToValidate: [],
    isValidationWaiting: false,
    isAllScansApproved: false,
    isScansInProgress: false,
    isScansPollingInitiated: false,
    insurerAddressWarningMessage: 'insurerAddressWarningMessage',
    ownerAddressWarningMessage: 'ownerAddressWarningMessage',
    insurerAddressTextWarningMessage: 'insurerAddressTextWarningMessage',
    ownerAddressTextWarningMessage: 'ownerAddressTextWarningMessage',
};

export const validationResultFieldsMock: ValidationFields = {
    owner: [{
        code: '1610023',
        isCritical: true,
        message: 'Паспортные данные не могут содержать цифры',
        mismatchFields: [{
            fieldName: 'name',
            fieldPath: 'person.name',
            fieldValue: 'ЮЛИЯ1',
        }],
    }],
    insurer: [],
    vehicle: [{
        code: '1610023',
        isCritical: true,
        message: 'Значение не может быть меньше 100',
        mismatchFields: [{
            fieldName: 'maxWeight',
            fieldPath: 'maxWeight',
            fieldValue: '50',
        }],
    }],
    drivers: [{
        driverIndex: 0,
        driver: [{
            code: '1610023',
            isCritical: true,
            message: 'Паспортные данные не могут содержать цифры',
            mismatchFields: [{
                fieldName: 'name',
                fieldPath: 'person.name',
                fieldValue: 'ЮЛИЯ1',
            }],
        }],
    }],
    contract: [],
    driversLimit: null,
};

export const mismatchesMock: Mismatch[] = [{
    code: '1610023',
    message: 'Паспортные данные не могут содержать цифры',
    isCritical: true,
    mismatchElements: [{
        attributeName: 'surname',
        attributePath: 'owner.person.name',
        attributeValue: 'ЮЛИЯ1',
    }],
}];

export const mapValidationInsurerMock: MappedValidationInterface = {
    step1: [
        {
            fieldMessages: [
                'Паспортные данные не могут содержать цифры',
            ],
            fieldName: 'insurer-person-name',
            fieldValue: 'ЮЛИЯ1',
            isCritical: true,
        },
    ],
    step2: [],
    step3: [],
};

export const mapValidationOwnerMock: MappedValidationInterface = {
    step1: [
        {
            fieldMessages: [
                'Паспортные данные не могут содержать цифры',
            ],
            fieldName: 'owner-person-name',
            fieldValue: 'ЮЛИЯ1',
            isCritical: true,
        },
    ],
    step2: [],
    step3: [],
};
