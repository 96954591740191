import * as React from 'react';

import {Button} from '@ui-kit';
import {convertToTimeMMSS} from '@utils/formatters';
import {interval} from '@utils/interval';

interface ButtonTimersInterface {
    label: string;
    handleEndTimer: () => void;
    expirationTime: number;
    onClick?: () => void;
    primary?: boolean;
    disabled?: boolean;
    danger?: boolean;
    icon?: JSX.Element;
    width?: string | number;
    isSmall?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

export function ButtonTimers({
    label,
    handleEndTimer,
    expirationTime,
    onClick,
    primary,
    disabled,
    danger,
    icon,
    width,
    isSmall,
    type,
}: ButtonTimersInterface) {
    const OF_MILLISECONDS = 1000;

    const [stateTime, setTime] = React.useState((expirationTime - Date.now()) / OF_MILLISECONDS);

    React.useEffect(() => {
        const intervalId = interval(() => {
            setTime(() => {
                const newTime = (expirationTime - Date.now()) / OF_MILLISECONDS;
                if (newTime <= 0) {
                    handleEndTimer();
                    intervalId.clear();
                    return 0;
                }

                return newTime;
            });
        }, OF_MILLISECONDS);

        return () => intervalId.clear();
    }, [expirationTime]);

    const displayTime = stateTime < 0 ? 0 : stateTime;
    const time = convertToTimeMMSS(Math.round(displayTime));

    return (
        <Button
            label={`${label} ${time}`}
            primary={primary}
            disabled={disabled}
            onClick={onClick}
            danger={danger}
            icon={icon}
            width={width}
            isSmall={isSmall}
            type={type}
        />
    );
}
