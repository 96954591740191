const blockExecutionFlkCodes = [
    '16002002',
    '16002005',
    '16002006',
    '16002028',
    '16002030',
    '16002037',
    '16003001',
    '16003008',
];

const blockLkFlkCodes = [
    '16002012',
    '16002013',
    '16002027',
    '16002029',
    '16002031',
    '16002032',
    '16002033',
    '16002035',
    '16003002',
    '16003003',
    '16003004',
    '16003005',
    '16003006',
    '16003007',
    '16002049',
];

const allBlockFlkCodes = [
    ...blockExecutionFlkCodes,
    ...blockLkFlkCodes,
];

export const isBlockFlkCode = (code: string) => allBlockFlkCodes.includes(code);
