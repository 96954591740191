import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {TypeCategory} from '@app/pages/contract-page/pages/step-2/utils';
import {FormFieldBlock} from '@app/pages/contract-page/pages/step-4/components/form-container/components';
import {Step4FormInterface} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {createTranslator} from '@app/utils/i18n';

import {
    CarMakerAndModel,
    OtherVehicleSpecifications,
    IdentifierVehicle,
    DiagnosticCard,
} from './components';

import * as styles from '../../form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function Vehicle({items}: {items: Step4FormInterface}) {
    const isVehicleSeatsAmountDisplayed = items.vehicleTypeCategory?.code === TypeCategory.D_DE &&
        items.vehicleSeatsAmount;
    const isVehicleMaxWeightDisplayed = items.vehicleTypeCategory?.code === TypeCategory.C_CE &&
        items.vehicleMaxWeight;
    const isVehiclePowerDisplayed = items.vehicleTypeCategory?.code === TypeCategory.B_BE &&
        items.vehiclePower;

    return (
        <div className={cn('form-container__form-container')}>
            <h3 className={cn('form-container__sub-header')}>
                {t('form-container-vehicle-header')}
            </h3>
            <div className={cn('form-container__form')}>
                <CarMakerAndModel items={items} />
                <FormFieldBlock
                    field={items.vehicleTypeCategoryFullName}
                    label="form-container-vehicle-typeCategory-label"
                />
                {
                    (isVehicleSeatsAmountDisplayed && (
                        <FormFieldBlock
                            field={items.vehicleSeatsAmount.toString()}
                            label="form-container-vehicle-seatsAmount-label"
                        />
                    )
                    )
                }
                {
                    (isVehicleMaxWeightDisplayed && (
                        <FormFieldBlock
                            field={items.vehicleMaxWeight.toString()}
                            label="form-container-vehicle-maxWeight-label"
                        />
                    ))
                }
                <FormFieldBlock
                    field={items.vehicleCarYear}
                    label="form-container-vehicle-full-car-year-label"
                />
                {
                    (isVehiclePowerDisplayed && (
                        <FormFieldBlock
                            field={items.vehiclePower}
                            label="form-container-vehicle-power-label"
                        />
                    ))
                }
                <FormFieldBlock
                    field={items.vehicleDocType}
                    label="form-container-vehicle-docType-label"
                />
                <FormFieldBlock
                    field={items.vehicleDateGiven}
                    label="form-container-vehicle-dateGiven-label"
                />

                {
                    (items.vehicleDiagnosticCardDateEnd || items.vehicleDiagnosticCard) && (
                        <DiagnosticCard items={items} />
                    )
                }
                <IdentifierVehicle items={items} />
                <OtherVehicleSpecifications items={items} />
            </div>
        </div>
    );
}
