import classNames from 'classnames/bind';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {WIDTH_NAVIGATION_BUTTON} from '@app/pages/shared-pages/layout-page/components/navigation/navigation';
import {LinkDataInterface} from '@modules/contract';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import {NavigationErrorBeforeContract} from '../navigation-error-before-contract';

import * as dictionary from '../../navigation.dic.json';
import * as styles from '../../navigation.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface AgreementNavigation {
    contractId: string;
    isActiveNext: boolean;
    setIsAgreementBtnEnabled: (value: Boolean) => void;
    isAgreementCaptchaVisible: boolean;
    captchaPublicKey: string;
    confirmAgreement: (contractId: string, linkData: LinkDataInterface) => void;
    linkData: LinkDataInterface;
    isLkBlocked: boolean;
    handleErrorCaptcha: () => void;
    setCaptchaVerifyKey: (userVerifyKey: string) => void;
    feEgarantCapthcaEnable: boolean;
}

export function AgreementNavigation({
    contractId,
    isActiveNext,
    setIsAgreementBtnEnabled,
    isAgreementCaptchaVisible,
    captchaPublicKey,
    confirmAgreement,
    linkData,
    isLkBlocked,
    handleErrorCaptcha,
    feEgarantCapthcaEnable,
    setCaptchaVerifyKey,
}: AgreementNavigation) {
    function handleChangeCaptcha(userVerifyKey: string) {
        setCaptchaVerifyKey(userVerifyKey);
        setIsAgreementBtnEnabled(true);
    }

    return (
        <div className={cn('navigation__wrapper')}>
            {
                isAgreementCaptchaVisible &&
                feEgarantCapthcaEnable && (
                    <div className={cn('navigation__wrapper-captcha')}>
                        <ReCAPTCHA
                            sitekey={captchaPublicKey}
                            onChange={handleChangeCaptcha}
                            onErrored={handleErrorCaptcha}
                        />
                    </div>
                )
            }
            <div className={cn('navigation-wrapper')}>
                <NavigationErrorBeforeContract />
                <Button
                    label={t('navigation.button.next')}
                    primary
                    width={WIDTH_NAVIGATION_BUTTON}
                    disabled={(feEgarantCapthcaEnable ?
                        !(isActiveNext && isAgreementCaptchaVisible) :
                        !isAgreementCaptchaVisible) || isLkBlocked}
                    onClick={() => confirmAgreement(contractId, linkData)}
                />
            </div>
        </div>
    );
}
