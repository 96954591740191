import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {loadFourthPageOpened} from '@app/store/actions';
import {
    dictionariesSelector,
    driversSelector,
    insurerSelector,
    ownerSelector,
    vehicleSelector,
    contractIdSelector,
    addressesSelector,
} from '@modules/contract';
import {overlayVisibilitySelector} from '@modules/overlay';

import {FormContainer} from './form-container';

const mapStateToProps = createStructuredSelector({
    dictionaries: dictionariesSelector,
    isLoadingContract: overlayVisibilitySelector,
    insurer: insurerSelector,
    owner: ownerSelector,
    drivers: driversSelector,
    vehicle: vehicleSelector,
    addresses: addressesSelector,
    contractId: contractIdSelector,
});

const mapDispatchToProps = {
    loadFourthPageOpened,
};

export const FormContainerWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormContainer);
