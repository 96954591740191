import * as React from 'react';

import {Size} from '@utils/dom';

import {AutoSizer} from '../autosizer';
import {Table} from './components';
import {DataTable as DataTableProps} from './data-table-types';

export function DataTable(props: DataTableProps): JSX.Element {
    return (
        <AutoSizer>
            {(size: Size) => (
                <Table {...props} {...size} />
            )}
        </AutoSizer>
    );
}
