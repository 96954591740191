import {
    InputSelectList,
    LinkSelectList,
    AutocompleteSelectList,
} from './list';
import {CreateSelector} from './select-creator';
import {
    InputSelectToggler,
    LinkSelectToggler,
} from './toggler';

export const Select = CreateSelector({toggler: InputSelectToggler, list: InputSelectList});
export const AutocompleteSelect = CreateSelector({toggler: InputSelectToggler, list: AutocompleteSelectList});
export const LinkSelect = CreateSelector({toggler: LinkSelectToggler, list: LinkSelectList});
