import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {paymentCancellation} from '@app/store/actions';
import {skDomainSelector} from '@modules/companies';
import {contractIdSelector} from '@modules/contract';
import {isLkBlockedSelector} from '@modules/navigation';

import {FailInfo} from './fail-info';
import {redirectToPaymentUrl} from './fail-info-actions';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    skDomain: skDomainSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    redirectToPaymentUrl,
    cancelPayment: paymentCancellation,
};

export const FailInfoContainer = connect(mapStateToProps, mapDispatchToProps)(FailInfo);
