import classNames from 'classnames/bind';
import * as React from 'react';

import {IconButton, IconButtonType} from '../icon-button';
import {Icons} from '../icons';
import {RelativePortal} from '../portal';
import {Scrollbars} from '../scrollbars';
import {ErrorMessages} from './components/error-messages';
import {WarningButton} from './components/warning-button';
import {TOP_SHIFT, LEFT_SHIFT} from './validation-error-indicator-constants';

import * as styles from './validation-error-indicator.scss';

const cn = classNames.bind(styles);

export interface ValidationErrorIndicatorProps {
    messages: string[],
    wrapperRef: React.RefObject<HTMLDivElement>,
    bannerWrapperRef: HTMLElement,
    errorsVisible: boolean,
    openBanner: () => void,
    closeBanner: () => void,
    calculatedErrorsHeight: number,
    handleMessagesRef: (ref: HTMLElement) => void,
    handleBannerWrapperRef: (ref: HTMLElement) => void
}

export function ValidationErrorIndicator(
    {
        messages,
        wrapperRef,
        bannerWrapperRef,
        errorsVisible,
        openBanner,
        closeBanner,
        calculatedErrorsHeight,
        handleMessagesRef,
        handleBannerWrapperRef,
    }: ValidationErrorIndicatorProps
) {
    const {clientWidth} = document.documentElement;
    const parentElementLeft = wrapperRef.current?.offsetLeft;
    const portalElementWidth = bannerWrapperRef?.getBoundingClientRect().width;
    const isRightPosition = parentElementLeft + portalElementWidth < clientWidth || clientWidth < portalElementWidth;
    return (
        <div
            className={cn('validation-error-indicator')}
            ref={wrapperRef}
        >
            <WarningButton
                showToolTip={!errorsVisible}
                onClick={openBanner}
            />
            {errorsVisible && (
                <RelativePortal
                    parentElement={wrapperRef.current}
                    options={{
                        topShift: TOP_SHIFT,
                        leftShift: LEFT_SHIFT,
                    }}
                    withRef={handleBannerWrapperRef}
                >
                    <div className={cn('validation-error-indicator__banner', isRightPosition ?
                        'validation-error-indicator__banner--right' :
                        'validation-error-indicator__banner--left')}
                    >
                        <Scrollbars
                            transparentBackground
                            maxHeight={calculatedErrorsHeight}
                        >
                            <ErrorMessages
                                messages={messages}
                                forwardedRef={handleMessagesRef}
                            />
                        </Scrollbars>
                        <div
                            className={cn('validation-error-indicator__close-button')}
                        >
                            <IconButton
                                type={IconButtonType.MESSAGE}
                                onClick={closeBanner}
                                icon={Icons.CLOSE}
                            />
                        </div>
                    </div>
                </RelativePortal>
            )}
        </div>
    );
}
