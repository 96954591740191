import classNames from 'classnames/bind';
import * as React from 'react';

import {DateField} from '@app/components';
import {createTranslator} from '@app/utils/i18n';
import {shouldBeDisabled} from '@utils/should-be-disabled';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';

import * as styles from '../person-data.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface Props {
    personType: string;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function BirthDateField({
    personType,
    scansEditFields,
    isLkBlocked,
}: Props) {
    return (
        <div className={cn('person-data__form-field-weight')}>
            <DateField
                label={t('birthdate')}
                name={`${personType}-person-birthdate`}
                isDisabled={
                    shouldBeDisabled(
                        {
                            name: `${personType}-person-birthdate`,
                            listFieldError: scansEditFields,
                            isLkBlocked,
                        }
                    )
                }
                required
            />
        </div>
    );
}
