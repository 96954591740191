import * as React from 'react';

import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './sign-in.dic.json';

const t = createTranslator(dictionary);

interface SignInInterface {
    deleteForgotPassword: () => void;
    contractId: string;
    isLkBlocked?: boolean;
    isTimerLoginExpired: boolean;
    setIsLkBlockedTrue: () => void;
}

export function SignIn({
    isLkBlocked,
    deleteForgotPassword,
    isTimerLoginExpired,
    setIsLkBlockedTrue,
}: SignInInterface) {
    const handleForgotPassword = () => (
        isTimerLoginExpired ? setIsLkBlockedTrue() : deleteForgotPassword()
    );

    return (
        <Button
            label={t('header-sign-in-button')}
            primary
            disabled={isLkBlocked}
            onClick={handleForgotPassword}
        />
    );
}
