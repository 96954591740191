import * as React from 'react';

import {SortDirections} from '@utils/constants';

import {SortCriteria} from '../../../data-table-types';
import {
    getNewSortDirection,
    sortRows,
} from './with-table-sorting-utils';

export function useSorting(rows: any[], initialSortCriteria: SortCriteria = {}) {
    const [sortCriteria, setSortCriteria] = React.useState(initialSortCriteria);
    const sortedRows = React.useMemo(() => {
        const {name} = sortCriteria;

        if (name) {
            return sortRows(rows, sortCriteria);
        }

        return rows;
    }, [rows, sortCriteria]);

    const changeSortCriteria = (newName: string) => {
        const {name: prevName, direction: prevDirection} = sortCriteria;
        const newDirection: SortDirections = prevName === newName ?
            getNewSortDirection(prevDirection) :
            SortDirections.ASC;
        setSortCriteria({
            name: newName,
            direction: newDirection,
        });
    };

    return {sortedRows, sortCriteria, changeSortCriteria};
}
