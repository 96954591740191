import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {setIsValidationChecked} from '@app/store/actions';
import {linkDataSelector} from '@modules/companies';
import {
    isAmountInLimitsSelector,
    calculatedTableDataSelector,
} from '@modules/contract';

import {CheckResult} from './check-result';

const mapStateToProps = createStructuredSelector({
    linkData: linkDataSelector,
    tableData: calculatedTableDataSelector,
    isAmountInLimits: isAmountInLimitsSelector,
});

const mapDispatchToProps = {
    setIsValidationChecked,
};

export const CheckResultContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckResult);
