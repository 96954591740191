import classNames from 'classnames/bind';
import * as React from 'react';

import {
    Icons,
    TextInput,
} from '@ui-kit';
import {
    formatDate,
    DateFormats,
} from '@utils/date';
import {
    compact,
    noop,
} from '@utils/functions';

import * as styles from './date-input.scss';

const cn = classNames.bind(styles);

interface DateInputProps {
    isDisabled?: boolean;
    label?: string;
    firstSelectedDate?: Date;
    secondSelectedDate?: Date;
    onClick: () => void;
    onKeyDown: (keyCode: number) => void;
    required?: boolean;
    isValid?: boolean;
    errorMessage?: string;
    isIconActive: boolean;
}

export function DateInput({
    isDisabled,
    label,
    firstSelectedDate,
    secondSelectedDate,
    onClick,
    onKeyDown,
    required,
    isValid,
    errorMessage,
    isIconActive,
}: DateInputProps) {
    let value = '';

    if (firstSelectedDate) {
        const startDate = formatDate(firstSelectedDate, DateFormats.DDMMYYYY_DOT);
        const endDate = secondSelectedDate && formatDate(secondSelectedDate, DateFormats.DDMMYYYY_DOT);

        value = compact([startDate, endDate]).join('\u2013');
    }

    return (
        <TextInput
            disabled={isDisabled}
            label={label}
            value={value}
            onKeyDown={onKeyDown}
            required={required}
            trailingIcon={(
                <div
                    role="button"
                    className={cn(
                        'calendar-icon',
                        {
                            'calendar-icon--open': isIconActive,
                            'calendar-icon--disabled': isDisabled,
                        }
                    )}
                    onClick={isDisabled ? noop : onClick}
                >
                    <Icons.DATE_RANGE />
                </div>
            )}
            isValid={isValid}
            errorMessage={errorMessage}
        />
    );
}
