export const URL_HOME = '/';
export const AGREEMENT_PAGE = 'agreement';
export const COMPANIES_LIST_PAGE = 'companies';
export const REGISTRATION_PAGE = 'registration';
export const LOGIN_PAGE = 'login';
export const CONTRACT_PAGE = 'contract';
export const RESTORE_PAGE = 'restore';
export const RESULT_PAGE = 'result';
export const FAIL_PAGE = 'fail';
export const NO_MATCH = '404';

export const STEP_1 = 'step1';
export const STEP_2 = 'step2';
export const STEP_3 = 'step3';
export const STEP_4 = 'step4';
