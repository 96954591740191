import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {overlayVisibilitySelector} from '@modules/overlay';
import {validationResultScansEditFieldsSelector} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {DriverInfo} from './driver-info';

const mapStateToProps = createStructuredSelector({
    isLoadingContract: overlayVisibilitySelector,
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const DriverInfoContainer = connect(
    mapStateToProps
)(DriverInfo);
