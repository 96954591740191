import {AddressInfo} from '@app/types';

export const formatAddress = (addressFias: AddressInfo, prefixes: { [key: string]: string }) => {
    const addressOrder: (keyof AddressInfo)[] = [
        'region', 'district', 'city', 'locality', 'street', 'house', 'room',
    ];
    return addressOrder.reduce((acc, name) => {
        const value = addressFias[name]?.name;
        if (!value) {
            return acc;
        }
        const prefix = prefixes[name] || '';
        return [...acc, `${prefix}${value}`];
    }, []).join(', ');
};
