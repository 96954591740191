import {
    StageInterface,
    TimerInterface,
} from '@app/store/types';
import {TimerType} from '@app/types';

export const nullTimer: TimerInterface = {
    expirationTime: null,
    secondsLeft: null,
    flkCode: null,
    timerType: null,
};

export const timerMock: TimerInterface = {
    expirationTime: 1587017059967,
    secondsLeft: 1200,
    flkCode: '16003001',
    timerType: TimerType.LOGIN,
};

export const stageStateMock: StageInterface = {
    currentStage: null,
    isAutoAcceptTimeoutExpired: false,
    isAutoAcceptHeartbeatStopped: false,
    isTimerLoginExpired: false,
    timer: {...nullTimer},
};
