import classNames from 'classnames/bind';
import * as React from 'react';

import {Icons} from '@ui-kit';
import {get, isFunction} from '@utils/functions';

import {CellSelector, Column} from '../data-table-types';
import {Cell} from './cell';

import * as styles from './row.scss';

export interface RowProps {
    getRowStyle?: (row: object) => object;
    columns: Column[];
    height: number;
    highlight: boolean;
    onClick: () => void;
    row: object;
    width: number;
    visibleRowWidth: number;
    draggable?: boolean;
    dragging?: boolean;
    offsetX: number;
    selected?: boolean;
    isSortableColumns?: boolean;
    isCentered?: boolean;
    clickable?: boolean;
    disabled?: boolean;
    isTextBold?: boolean;
}

const cn = classNames.bind(styles);

const getRowValue = (row: object, selector: string | CellSelector, formatter: (value: any) => any): any => {
    const value = isFunction(selector) ? selector(row) : get(row, selector);
    return formatter ? formatter(value) : value;
};

export function Row({
    highlight = false,
    getRowStyle = null,
    columns,
    height,
    onClick,
    row,
    width,
    visibleRowWidth,
    draggable,
    selected,
    disabled,
    dragging,
    offsetX,
    isSortableColumns,
    clickable,
    isTextBold = false,
}: RowProps): JSX.Element {
    const externalStyle = getRowStyle ? getRowStyle(row) : null;
    const rowStyle = externalStyle || {};
    const offsetXpx = `${offsetX}px`;

    return (
        <div
            className={cn('row-container', {
                'row-container--dragging': dragging,
                'row-container--selected': selected,
                'row-container--clickable': clickable,
                'row-container--disabled': disabled,
            })}
            style={{
                width: visibleRowWidth,
                marginLeft: dragging ? offsetXpx : '',
            }}
        >
            {draggable && (
                <div
                    className={cn('row-container__dragging-icon-container',
                        dragging &&
                        'row-container__dragging-icon-container--dragging')}
                    style={{left: !dragging && offsetXpx}}
                >
                    <div className={cn('row-container__dragging-icon')}>
                        <Icons.DRAG_INDICATOR />
                    </div>
                    <div className={cn('row-container__dragging-icon-shade')} />
                </div>
            )}
            <div
                className={cn('row-container__row', {
                    'row-container__row--highlight': highlight,
                    'row-container__row--dragging': dragging,
                    'row-container__row--draggable': draggable,
                })}
                onClick={onClick}
                style={{
                    marginLeft: dragging ? `-${offsetXpx}` : '',
                    height,
                    width,
                    ...rowStyle,
                }}
                role="button"
            >
                {columns.map(({
                    name,
                    selector,
                    formatter,
                    width,
                    hasNoPadding,
                    isCentered,
                }) => (
                    <Cell
                        hasNoPadding={hasNoPadding}
                        key={name}
                        value={getRowValue(row, selector || name, formatter)}
                        width={width}
                        isPaddingLeft={isSortableColumns}
                        isCentered={isCentered}
                        isTextBold={isTextBold}
                    />
                ))}
            </div>
        </div>
    );
}
