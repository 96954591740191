import classNames from 'classnames/bind';
import * as React from 'react';

import {SvgSize} from '@utils/constants/svg-size';

import * as styles from './spinner.scss';

const cn = classNames.bind(styles);

interface SpinnerProps {
    size?: number;
    thickness?: number;
}

const THICKNESS = 3.6;

export function Spinner({size = SvgSize.BIG, thickness = THICKNESS}: SpinnerProps): JSX.Element {
    return (
        <div
            className={cn('spinner')}
            role="progressbar"
            style={{width: size, height: size}}
        >
            <svg viewBox={`${size / 2} ${size / 2} ${size} ${size}`}>
                <circle
                    className={cn('spinner__circle')}
                    cx={size}
                    cy={size}
                    r={(size - thickness) / 2}
                    fill="none"
                    strokeWidth={thickness}
                />
            </svg>
        </div>
    );
}
