import {MODULE_NAMESPACE} from './overlay-constants';

export interface OverlayState {
    readonly showCount: number
}

export interface AppState {
    [MODULE_NAMESPACE]: OverlayState
}

export enum Actions {
    SHOW_OVERLAY = 'SHOW_OVERLAY',
    HIDE_OVERLAY = 'HIDE_OVERLAY'
}
