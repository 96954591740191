import * as React from 'react';

import {Field} from '../field';
import {
    AutocompleteSelectWithFieldAdapter,
    AutocompleteSelectWithFieldAdapterProps,
} from './autocomplete-select-with-field-adapter';

interface AutocompleteSelectFieldProps extends AutocompleteSelectWithFieldAdapterProps<any> {
}

export function AutocompleteSelectField(props: AutocompleteSelectFieldProps) {
    const {name, ...restProps} = props;
    return (
        <Field
            {...restProps}
            name={name}
            component={AutocompleteSelectWithFieldAdapter}
            allowNull
        />
    );
}
