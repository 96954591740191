import classNames from 'classnames/bind';
import * as React from 'react';

import {contractConclusion} from '@modules/user';
import {MatchParams} from '@utils/match-params';

import {PaymentErrorMessage} from './components/error-message';
import {PaymentResultLoading} from './components/loading-info';
import {PaymentSuccessMessage} from './components/success-message';

import * as styles from './payment-result-page.scss';

const cn = classNames.bind(styles);

interface ContractPageInterface {
    match: MatchParams;
    passParams: (stepValue: MatchParams['params']) => void;
    fetchUserData?: (contractId: string) => void;
}

export function PaymentResultPage({
    match,
    passParams,
    fetchUserData,
}: ContractPageInterface) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [policyNumber, setPolicyNumber] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);

    React.useEffect(() => {
        passParams(match.params);
        const {contractId} = match.params;
        contractConclusion(contractId).then(result => {
            setIsLoading(false);
            if (result.code) {
                setErrorMessage(result.message);
            } else {
                setPolicyNumber(result.policyNumber);
            }
        });
        fetchUserData(contractId);
    }, []);

    return (
        <div className={cn('result-info')}>
            {isLoading && <PaymentResultLoading />}
            {!isLoading && (errorMessage ? (
                <PaymentErrorMessage message={errorMessage} />
            ) : (
                <PaymentSuccessMessage policyNumber={policyNumber} />
            ))}
        </div>
    );
}
