export const interval = (callback: Function, delay: number) => {
    let start = Date.now();
    let stop = false;

    const intervalFunc = () => {
        if (Date.now() - start > delay) {
            start += delay;
            callback();
        }
        if (!stop) {
            requestAnimationFrame(intervalFunc);
        }
    };
    requestAnimationFrame(intervalFunc);

    return {
        clear: () => {
            stop = true;
        },
    };
};
