import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {webSiteRSASelector} from '@modules/system';

import {NoMatchPage} from './no-match-page';

const mapStateToProps = createStructuredSelector({
    webSiteRSA: webSiteRSASelector,
});

export const NoMatchPageContainer = connect(mapStateToProps)(NoMatchPage);
