export {
    ConfirmResult,
    ConfirmTransitionOptions,
    ConfirmDeleteOptions,
    confirm,
    confirmDelete,
    message,
    confirmTransition,
    ConfirmOptions,
} from './common-modals-actions';
export {CONFIRM_DELETE_GENDERS} from './common-modals-constants';
export {
    CommonModalsTypes,
    commonModals,
} from './common-modals';
