import formatStringByPattern from 'format-string-by-pattern';

const snilsNumberMask = 'XXX-XXX-XXX XX';

export const formatSnilsNumber = (value: string) => {
    if (value) {
        const onlyNumbers = value.replace(/[^\d]/g, '');

        return formatStringByPattern(snilsNumberMask, onlyNumbers);
    }
    return value;
};

export const clearSnilsFormater = (snils: string) => (
    snils ? snils.replace(/\(|\)|\s|-|/g, '') : null
);
