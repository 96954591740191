import * as React from 'react';

import * as dictionaryLayout from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import * as dictionaryFlk from '@app/pages/shared-pages/layout-page/flk.dic.json';
import {TEST_MAX_CONTRACT_AMOUNT} from '@utils/constants/egarant-settings';
import {SelectOption} from '@utils/formatters/select-option';
import {urlFormatter} from '@utils/formatters/url-formatter';
import {isLkBlockedSelector} from '@app/modules/navigation';
import {toContractDateFormat} from '@utils/formatters/date';
import {executeAsyncAction} from '@modules/model/actions';
import {MODAL_WIDTH} from '@utils/constants/modal-width';
import {
    MappedValidationInterface,
    MappedMismatchField,
} from '@modules/validation/validation-types';
import {
    mapFetchedInsurerValidation,
    mapValidationInsurerOrOwner,
    errorMapFetchedValidation,
    mapFetchedOwnerValidation,
} from '@modules/validation/validation-utils';
import {
    getOrganizationRegDocTypes,
    getVehicleDocumentTypes,
    getVehiclePurposeTypes,
    putScansEditingRequest,
    getIdentityCardTypes,
    scansStatusesRequest,
    sendNavigationState,
    saveVehicleData,
    saveDriversData,
} from '@modules/contract/contract-api';
import {
    getUpdatedUploadedFiles,
    UploadedDocumentFile,
    saveInsurerData,
    getInsurerData,
    getDriversData,
    getDriverMeets,
    getVehicleData,
    saveOwnerData,
    getOwnerData,
    getStep4Data,
} from '@app/modules/contract';
import {
    updateOsagoContractEffectiveDate,
    sendDateCorrectionRejection,
    calculateInsSum,
    checkPrePayment,
    getPaymentUrl,
    paymentReject,
} from '@app/modules/user';
import {
    setInsurerAddressWarningMessage,
    setValidationResultErrorFields,
    setOwnerAddressWarningMessage,
    handleInsurerAddressMismatch,
    updateUploadedFilesStatuses,
    handleOwnerAddressMismatch,
    setIsScanEditingInitiated,
    setContractAmountInLimits,
    setIsValidationInitiated,
    setCalculatedTableData,
    setNavigationResult,
    setMappedValidation,
    setValidationResult,
    mapValidationResult,
    setDictionariesData,
    setStepAfterSubmit,
    setIsLkBlockedTrue,
    processBadRequest,
    setUploadedFiles,
    setMismatchData,
    setContractData,
    setCurrentStage,
    setDriverMeets,
    setCurrentStep,
    hideModal,
} from '@app/store/actions';
import {listFieldEntitiesEnabled} from '@utils/fields-group-enabled';
import {RequestError, RequestCode} from '@utils/request';
import {createTranslator} from '@utils/i18n';
import {DispatchType} from '@utils/redux';
import {
    ConfirmResult,
    confirm,
} from '@modules/common-modals';
import {
    requiredDriverDocumentsIndexesSelector,
    validationResultEntitiesSelector,
    isScanSendingInitiatedSelector,
    isScanEditingInitiatedSelector,
    mappedValidationKitSelector,
    validationDataSelector,
    scansToEditSelector,
} from '@modules/validation';
import {
    AppStages,
    Steps,
} from '@utils/constants';
import {
    CheckResultsWithScansData,
    CalculationData,
    MismatchElement,
    ValidationData,
    NavigationStep,
    Mismatch,
    Drivers,
    Insurer,
    Vehicle,
    Owner,
    ValidationEntities,
    DocumentTypes,
} from '@app/types';
import {Anchor} from '@ui-kit';

import * as dictionary from './contract-page.dic.json';

const l = createTranslator(dictionaryLayout);
const t = createTranslator(dictionary);
const f = createTranslator(dictionaryFlk);

const getVal = (obj: any, path: string) => path.split('-').reduce((res, prop) => res[prop], obj);

export function loadData(contractId: string) {
    return (dispatch: DispatchType, getState: Function) => {
        const state = getState();
        const isScanEditingInitiated = isScanEditingInitiatedSelector(state);
        const validationResultEntities = validationResultEntitiesSelector(state);
        const driverIndexes = requiredDriverDocumentsIndexesSelector(state);
        const isLkBlocked = isLkBlockedSelector(state);

        const resultLoadInsurer = getInsurerData(contractId);
        const resultLoadOwner = getOwnerData(contractId);
        const resultLoadVehicle = getVehicleData(contractId);
        const resultLoadDrivers = getDriversData(contractId);
        const resultLoadStep4 = getStep4Data(contractId);
        const isScanSendingInitiated = isScanSendingInitiatedSelector(state);

        if (isScanSendingInitiated) {
            scansStatusesRequest(contractId, false, false)
                .then((data: CheckResultsWithScansData) => {
                    const updatedUploadedFiles = getUpdatedUploadedFiles(data);
                    const validationData = validationDataSelector(state);

                    dispatch(setUploadedFiles(updatedUploadedFiles));


                    const documentTypes = updatedUploadedFiles.map((item: UploadedDocumentFile) => item.documentType);
                    const uniqueDocumentTypes = documentTypes.filter((v, i) => documentTypes.indexOf(v) === i);

                    if (uniqueDocumentTypes.length) {
                        dispatch(setValidationResult({
                            ...validationData,
                            validationResultScans: uniqueDocumentTypes,
                        }));
                    }
                });
        }

        return executeAsyncAction(dispatch, () => Promise.all([
            resultLoadInsurer,
            resultLoadOwner,
            resultLoadVehicle,
            resultLoadDrivers,
            resultLoadStep4,
        ]))
            .then(([insurer, owner, vehicle, drivers, addresses]) => {
                dispatch(setContractData({
                    insurer,
                    owner,
                    vehicle,
                    drivers,
                    addresses,
                }));
                dispatch(setDriverMeets(
                    getDriverMeets(drivers)
                ));
                if (isScanEditingInitiated && !isLkBlocked) {
                    dispatch(updateUploadedFilesStatuses());
                } else if (validationResultEntities.length > 0) {
                    dispatch(
                        setValidationResultErrorFields(
                            listFieldEntitiesEnabled(
                                validationResultEntities,
                                driverIndexes,
                                getDriverMeets(drivers)
                            )
                        )
                    );
                }
            });
    };
}
export function updateMappedValidationFieldValue() {
    return (dispatch: DispatchType, getState: Function) => {
        const state = getState();
        const {stepsFormData} = state.CONTRACT;
        const mappedValidationSelector = mappedValidationKitSelector(state);

        dispatch(setMappedValidation({
            step1: mappedValidationSelector.step1.map(field => ({
                ...field, fieldValue: getVal(stepsFormData, field.fieldName),
            })),
            step2: mappedValidationSelector.step2.map(field => ({
                ...field, fieldValue: getVal(stepsFormData.vehicle, field.fieldName),
            })),
            step3: mappedValidationSelector.step3.map(field => ({
                ...field, fieldValue: getVal(stepsFormData.drivers, field.fieldName),
            })),
        }));
    };
}

export function loadCalculatedTableData(contractId: string): DispatchType {
    return (dispatch: DispatchType) => executeAsyncAction(dispatch, () => calculateInsSum(contractId)
        .then((data: CalculationData) => {
            dispatch(setCalculatedTableData(data));
            dispatch(setContractAmountInLimits(data.amount <= TEST_MAX_CONTRACT_AMOUNT));
            dispatch(setCurrentStage(AppStages.PAYMENT));
        }));
}

export function openRejectPaymentModal(contractId: string): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(confirm({
            hasCancelOption: true,
            title: l('confirm-modal-title'),
            message: t('contract-page-cancel-modal-message'),
            width: MODAL_WIDTH,
            confirmButton: {label: t('contract-page-cancel-modal-confirm'), primary: true},
            cancelButton: {label: t('contract-page-cancel-modal-cancel')},
            resultHandler: (result: ConfirmResult) => {
                if (result === ConfirmResult.CONFIRMED) {
                    executeAsyncAction(dispatch, () => paymentReject(contractId).then(() => {
                        dispatch(setIsLkBlockedTrue());
                        dispatch(hideModal());
                        dispatch(setMismatchData([{
                            code: '16002033',
                            message: f('16002033'),
                            isCritical: true,
                        }]));
                        dispatch(confirm({
                            hasCancelOption: false,
                            title: l('confirm-modal-title'),
                            message: f('16002033'),
                            width: MODAL_WIDTH,
                            resultHandler: () => {},
                        }));
                    }));
                }
            },
        }));
    };
}

function extractFlkParamValue(mismatch: Mismatch, attributePath: string) {
    return mismatch?.mismatchElements.find((el: MismatchElement) => el.attributePath === attributePath)?.attributeValue;
}

function formatUsePeriodCorrectionLine(startDate: string, endDate: string) {
    return (startDate && endDate) ? `${toContractDateFormat(startDate)} — ${toContractDateFormat(endDate)}\n` : '';
}

function createDateCorrectionModalMessage(dateCorrectionMismatch: Mismatch) {
    const insuranceStartDateOld = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.insuranceStartDate.old');
    const insuranceStartDateNew = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.insuranceStartDate.new');
    const insuranceStartDateCorrection = t('contract-page-date-correction-modal-message-part-1',
        toContractDateFormat(insuranceStartDateOld),
        toContractDateFormat(insuranceStartDateNew));

    const usePeriod1StartDateOld = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod1StartDate.old');
    const usePeriod1EndDateOld = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod1EndDate.old');
    const usePeriod2StartDateOld = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod2StartDate.old');
    const usePeriod2EndDateOld = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod2EndDate.old');
    const usePeriod3StartDateOld = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod3StartDate.old');
    const usePeriod3EndDateOld = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod3EndDate.old');

    const usePeriod1StartDateNew = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod1StartDate.new');
    const usePeriod1EndDateNew = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod1EndDate.new');
    const usePeriod2StartDateNew = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod2StartDate.new');
    const usePeriod2EndDateNew = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod2EndDate.new');
    const usePeriod3StartDateNew = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod3StartDate.new');
    const usePeriod3EndDateNew = extractFlkParamValue(dateCorrectionMismatch, 'flkParam.usePeriod3EndDate.new');

    const usePeriod1OldDateCorrection = formatUsePeriodCorrectionLine(usePeriod1StartDateOld, usePeriod1EndDateOld);
    const usePeriod2OldDateCorrection = formatUsePeriodCorrectionLine(usePeriod2StartDateOld, usePeriod2EndDateOld);
    const usePeriod3OldDateCorrection = formatUsePeriodCorrectionLine(usePeriod3StartDateOld, usePeriod3EndDateOld);
    const usePeriod1NewDateCorrection = formatUsePeriodCorrectionLine(usePeriod1StartDateNew, usePeriod1EndDateNew);
    const usePeriod2NewDateCorrection = formatUsePeriodCorrectionLine(usePeriod2StartDateNew, usePeriod2EndDateNew);
    const usePeriod3NewDateCorrection = formatUsePeriodCorrectionLine(usePeriod3StartDateNew, usePeriod3EndDateNew);

    const insuranceUsePeriodsCorrection = t('contract-page-date-correction-modal-message-part-2',
        usePeriod1OldDateCorrection,
        usePeriod2OldDateCorrection,
        usePeriod3OldDateCorrection,
        usePeriod1NewDateCorrection,
        usePeriod2NewDateCorrection,
        usePeriod3NewDateCorrection);

    if (usePeriod1OldDateCorrection ||
            usePeriod2OldDateCorrection ||
            usePeriod3OldDateCorrection ||
            usePeriod1NewDateCorrection ||
            usePeriod2NewDateCorrection ||
            usePeriod3NewDateCorrection) {
        // eslint-disable-next-line no-irregular-whitespace
        return `${insuranceStartDateCorrection}\n \n${insuranceUsePeriodsCorrection}`;
    }

    return insuranceStartDateCorrection;
}

export function openDateCorrectionModal(
    contractId: string,
    dateCorrectionModalMessage: string,
    skDomain: string
): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(confirm({
            title: l('confirm-modal-title'),
            message: dateCorrectionModalMessage,
            width: 700,
            confirmButton: {label: t('contract-page-date-correction-modal-confirm'), primary: true},
            cancelButton: {label: t('contract-page-date-correction-modal-cancel')},
            resultHandler: (result: ConfirmResult, dispatch) => {
                if (result === ConfirmResult.CONFIRMED) {
                    executeAsyncAction(dispatch, () => updateOsagoContractEffectiveDate(contractId).then(() => {
                        executeAsyncAction(dispatch, () => getPaymentUrl(contractId, skDomain).then(({paymentUrl}) => {
                            window.location.assign(paymentUrl);
                        }));
                    }));
                } else {
                    executeAsyncAction(dispatch, () => sendDateCorrectionRejection(contractId).then(() => {
                        dispatch(setIsLkBlockedTrue());
                        dispatch(setMismatchData([{
                            code: '16002031',
                            message: f('16002031'),
                            isCritical: true,
                        }]));
                        dispatch(confirm({
                            hasCancelOption: false,
                            title: l('confirm-modal-title'),
                            message: f('16002031'),
                            width: MODAL_WIDTH,
                            resultHandler: () => {},
                        }));
                    }));
                }
            },
            hasCancelOption: true,
        }));
    };
}

export function openErrorMessageModal(errorMessage: string): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(confirm({
            title: l('confirm-modal-title'),
            message: errorMessage,
            width: MODAL_WIDTH,
            resultHandler: () => {
            },
            hasCancelOption: false,
        }));
    };
}

export function openPaymentModal(contractId: string, insCompanyUrl: string, skDomain: string): DispatchType {
    return (dispatch: DispatchType) => {
        const executor = () => checkPrePayment(contractId).then(() => {
            dispatch(confirm({
                hasCancelOption: true,
                title: l('confirm-modal-title'),
                message: (
                    <>
                        {t('contract-page-payment-modal-message')}
                        <Anchor
                            link={insCompanyUrl}
                            label={urlFormatter(insCompanyUrl)}
                            target="_blank"
                        />
                    </>
                ),
                width: MODAL_WIDTH,
                confirmButton: {label: t('contract-page-payment-modal-confirm'), primary: true},
                cancelButton: {label: t('contract-page-payment-modal-cancel')},
                resultHandler: (result: ConfirmResult) => {
                    if (result === ConfirmResult.CONFIRMED) {
                        executeAsyncAction(dispatch, () => getPaymentUrl(contractId, skDomain)
                            .then(({errorCode, errorMessage, paymentUrl}) => {
                                if (!errorCode) {
                                    window.location.assign(paymentUrl);
                                } else {
                                    dispatch(openErrorMessageModal(errorMessage));
                                }
                            }));
                    }
                },
            }));
        });
        const errorHandler = ({status, codes, mismatches}: RequestError) => {
            if (status === RequestCode.BAD_REQUEST && codes.includes('16002011')) {
                dispatch(hideModal());
                const dateCorrectionMismatch = mismatches.find(({code}) => code === '16002011');
                const dateCorrectionModalMessage = createDateCorrectionModalMessage(dateCorrectionMismatch);
                dispatch(openDateCorrectionModal(contractId, dateCorrectionModalMessage, skDomain));
            }
        };
        executeAsyncAction(dispatch, executor, errorHandler);
    };
}

export function loadDictionaries(): DispatchType {
    return (dispatch: DispatchType) => {
        const identityCardTypes = getIdentityCardTypes();
        const organizationRegDocTypes = getOrganizationRegDocTypes();
        const vehicleDocumentTypes = getVehicleDocumentTypes();
        const vehiclePurposeTypes = getVehiclePurposeTypes();
        return executeAsyncAction(dispatch, () => Promise.all([
            identityCardTypes,
            organizationRegDocTypes,
            vehicleDocumentTypes,
            vehiclePurposeTypes,
        ]))
            .then(([
                identityCardTypes,
                organizationRegDocTypes,
                vehicleDocumentTypes,
                vehiclePurposeTypes,
            ]: SelectOption<string>[][]) => {
                dispatch(setDictionariesData({
                    identityCardTypes,
                    organizationRegDocTypes,
                    vehicleDocumentTypes,
                    vehiclePurposeTypes,
                }));
            });
    };
}

export function loadDictionariesRegistration(): DispatchType {
    return (dispatch: DispatchType) => {
        executeAsyncAction(dispatch, () => getIdentityCardTypes())
            .then(identityCardTypes => {
                dispatch(setDictionariesData({
                    identityCardTypes,
                }));
            });
    };
}

function setNavigationState(contractId: string, stepNumber: number): DispatchType {
    return (dispatch: DispatchType) => {
        const navigationSteps = [
            NavigationStep.STEP_1,
            NavigationStep.STEP_2,
            NavigationStep.STEP_3,
            NavigationStep.STEP_4,
        ];
        const step = navigationSteps[stepNumber - 1];
        const executor = () => sendNavigationState(contractId, step)
            .then(() => {
                dispatch(setCurrentStep(stepNumber));
                dispatch(setNavigationResult({
                    step,
                    paymentUrl: null,
                }));
            });
        const errorHandler = ({status}: RequestError) => {
            if (status === RequestCode.FORBIDDEN) {
                dispatch(openErrorMessageModal(t('contract-page-forbidden-message')));
            }
        };
        executeAsyncAction(dispatch, executor, errorHandler);
    };
}

export function confirmEditData(
    contractId: string,
    howMuchEditFields: number,
    firstStepToValidate: number
): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(confirm({
            title: l('confirm-modal-title'),
            message: t('contract-page-edit-data-modal-message', howMuchEditFields),
            width: MODAL_WIDTH,
            confirmButton: {label: t('contract-page-edit-data-modal-confirm'), primary: true},
            cancelButton: {label: t('contract-page-edit-data-modal-cancel')},
            resultHandler: result => {
                if (result === ConfirmResult.CONFIRMED) {
                    dispatch(setNavigationState(contractId, firstStepToValidate));
                }
            },
            hasCancelOption: true,
        }));
    };
}

function goToStep(contractId: string, nextStep: number, isCritical: boolean): DispatchType {
    return (dispatch: DispatchType) => {
        if (isCritical) {
            window.scrollTo(0, 0);
        } else {
            switch (nextStep) {
                case 1:
                case 2:
                case 3:
                case 4:
                    dispatch(setNavigationState(contractId, nextStep));
                    break;
                default:
                    break;
            }
        }
    };
}

function isCriticalInValidationResult(
    contractId: string,
    {
        validationResultFields: {
            owner,
            insurer,
            vehicle,
            drivers,
        },
    }: ValidationData,
    currentStep: number,
    nextStep: number,
    forceCritical?: boolean,
): DispatchType {
    if (forceCritical) {
        return (dispatch: DispatchType) => dispatch(goToStep(contractId, nextStep, forceCritical));
    }

    let isCritical = false;
    if (currentStep === Steps.FIRST_STEP) {
        isCritical = [...owner, ...insurer].some(element => element.isCritical);
    }
    if (currentStep === Steps.SECOND_STEP) {
        isCritical = vehicle.some(element => element.isCritical);
    }
    if (currentStep === Steps.THIRD_STEP) {
        isCritical = drivers.some(driverData => driverData.driver.some(element => element.isCritical));
    }
    return (dispatch: DispatchType) => dispatch(goToStep(contractId, nextStep, isCritical));
}

// workaround известного бага
// https://github.com/final-form/react-final-form/issues/281
function sendStepDispatchNull(): DispatchType {
    return (dispatch: DispatchType) => {
        dispatch(setMappedValidation(null));
        dispatch(setStepAfterSubmit(null));
    };
}

function sendStepDispatch(data: ValidationData): DispatchType {
    return (dispatch: DispatchType) => {
        dispatch(sendStepDispatchNull());
        dispatch(setIsValidationInitiated(data.isValidationInitiated));
        dispatch(setValidationResult(data));
    };
}

export const isCriticalSort = (a: MappedMismatchField, b: MappedMismatchField) => {
    if (a.isCritical < b.isCritical) return 1;
    return -1;
};

const sortParsedKit = (parsedKit: MappedValidationInterface) => ({
    step1: [...parsedKit.step1].sort(isCriticalSort),
    step2: [...parsedKit.step2].sort(isCriticalSort),
    step3: [...parsedKit.step3].sort(isCriticalSort),
});

export const isCriticalStep = (
    parsedKit: MappedValidationInterface,
    currentStep: number
) => {
    switch (currentStep) {
        case 1:
            return parsedKit.step1.some(item => item.isCritical);
        case 2:
            return parsedKit.step2.some(item => item.isCritical);
        case 3:
            return parsedKit.step3.some(item => item.isCritical);
        default:
            return false;
    }
};

const getHasCriticals = (mismatches: Mismatch[]) => mismatches.some(mismatch => mismatch.isCritical);

interface OptionsPostDispatchData {
    contractId: string;
    parsedKit: MappedValidationInterface;
    currentStep: number;
    nextStep: number;
    dispatch: DispatchType;
    data?: ValidationData;
    insurerMismatches?: Mismatch[];
    isEditingOwner?: boolean;
}

function postDispatchData(options: OptionsPostDispatchData) {
    const {
        contractId,
        parsedKit,
        currentStep,
        nextStep,
        dispatch,
        data,
        insurerMismatches,
    } = options;
    const parsedKitSorted = sortParsedKit(parsedKit);
    const isCritical = isCriticalStep(parsedKitSorted, currentStep);

    if (data) {
        dispatch(sendStepDispatch(data));
    }
    dispatch(setMappedValidation(parsedKitSorted));
    dispatch(handleOwnerAddressMismatch(data.validationResultFields));

    if (data) {
        dispatch(isCriticalInValidationResult(contractId, data, currentStep, nextStep, isCritical));
    }

    if (insurerMismatches) {
        dispatch(processBadRequest(insurerMismatches));
    }
}

interface OptionsPostOwner {
    parsedKitInsurer: MappedValidationInterface;
    contractId: string;
    owner: Owner;
    currentStep: number;
    nextStep: number;
    dispatch: DispatchType;
    insurerMismatches?: Mismatch[];
    insurerErrorStatus?: number;
    isEditingOwner?: boolean;
}

function postOwner(options: OptionsPostOwner) {
    const {
        parsedKitInsurer,
        contractId,
        owner,
        currentStep,
        nextStep,
        dispatch,
        insurerMismatches,
        insurerErrorStatus,
    } = options;
    const ownerExecutor = () => saveOwnerData(contractId, owner)
        .then((data: ValidationData) => {
            const parsedKitInsurerAndOwner = mapValidationInsurerOrOwner(
                parsedKitInsurer,
                mapFetchedOwnerValidation(data),
            );

            postDispatchData({
                contractId,
                parsedKit: parsedKitInsurerAndOwner,
                currentStep,
                nextStep,
                dispatch,
                data,
                insurerMismatches,
            });
        });
    const ownerErrorHandler = ({mismatches, status}: RequestError) => {
        dispatch(hideModal());
        const parsedKitInsurerAndOwner = sortParsedKit(
            mapValidationInsurerOrOwner(
                parsedKitInsurer,
                errorMapFetchedValidation(mismatches),
            ),
        );
        dispatch(sendStepDispatchNull());
        dispatch(setOwnerAddressWarningMessage(null));
        dispatch(setMappedValidation(parsedKitInsurerAndOwner));
        const hasCriticals = getHasCriticals(mismatches);
        const isCritical = isCriticalStep(parsedKitInsurerAndOwner, currentStep);
        if (!hasCriticals &&
                insurerErrorStatus !== RequestCode.INTERNAL_SERVER_ERROR &&
                status !== RequestCode.INTERNAL_SERVER_ERROR) {
            dispatch(goToStep(contractId, nextStep, isCritical));
        }

        if (insurerMismatches) {
            const mismatchesInsurerOrOwner: Mismatch[] = [
                ...insurerMismatches,
                ...mismatches,
            ];
            dispatch(processBadRequest(mismatchesInsurerOrOwner));
        } else {
            dispatch(processBadRequest(mismatches));
        }
    };
    executeAsyncAction(dispatch, ownerExecutor, ownerErrorHandler);
}

interface OptionsPrePostData {
    isEditingOwner: boolean;
    parsedKitInsurer: MappedValidationInterface;
    contractId: string;
    owner: Owner;
    currentStep: number;
    nextStep: number;
    dispatch: DispatchType;
    mismatches?: Mismatch[];
    status?: number;
    data?: ValidationData;
}

function prePostData(options: OptionsPrePostData) {
    const {
        isEditingOwner,
        parsedKitInsurer,
        contractId,
        owner,
        currentStep,
        nextStep,
        dispatch,
        mismatches,
        status,
        data,
    } = options;

    if (isEditingOwner) {
        postOwner({
            parsedKitInsurer,
            contractId,
            owner,
            currentStep,
            nextStep,
            dispatch,
            insurerMismatches: mismatches,
            insurerErrorStatus: status,
            isEditingOwner,
        });
    } else {
        postDispatchData({
            contractId,
            parsedKit: parsedKitInsurer,
            currentStep,
            nextStep,
            dispatch,
            insurerMismatches: mismatches,
            data,
            isEditingOwner,
        });
    }
}

export type OptionsSendStep = {
    contractId: string,
    currentStep: number,
    nextStep: number,
    stepsToValidate: number[],
    insurer?: Insurer,
    owner?: Owner,
    vehicle?: Vehicle,
    drivers?: Drivers
}

export function sendStep(options: OptionsSendStep) {
    const {
        contractId,
        currentStep,
        nextStep,
        insurer,
        owner,
        drivers,
        vehicle,
        stepsToValidate,
    } = options;
    return (dispatch: DispatchType, getState: Function) => {
        const state = getState();
        const isScanEditingInitiated = isScanEditingInitiatedSelector(state);
        const validationResultEntities = validationResultEntitiesSelector(state);
        const scansToEdit = scansToEditSelector(state);
        const isEditingInsurer = (
            !isScanEditingInitiated && validationResultEntities.includes(ValidationEntities.INSURER)
        ) || (
            isScanEditingInitiated && scansToEdit.includes(DocumentTypes.INSURER_DOCUMENT)
        );
        const isEditingOwner = (
            !isScanEditingInitiated && validationResultEntities.includes(ValidationEntities.OWNER)
        ) || (
            isScanEditingInitiated && scansToEdit.includes(DocumentTypes.OWNER_DOCUMENT)
        );
        const fulfilledActions = (data: ValidationData) => {
            if (currentStep === Steps.FIRST_STEP) {
                const parsedKitInsurer = mapFetchedInsurerValidation(data);

                if (isEditingInsurer) {
                    dispatch(handleInsurerAddressMismatch(data.validationResultFields));
                }

                prePostData({
                    isEditingOwner,
                    parsedKitInsurer,
                    contractId,
                    owner,
                    currentStep,
                    nextStep,
                    dispatch,
                    data,
                });
            } else {
                dispatch(sendStepDispatch(data));
                dispatch(mapValidationResult(data));
                dispatch(isCriticalInValidationResult(contractId, data, currentStep, nextStep));
            }
        };
        const errorHandler = ({mismatches, status}: RequestError) => {
            const parsedKit = sortParsedKit(errorMapFetchedValidation(mismatches));
            if (currentStep === Steps.FIRST_STEP) {
                dispatch(hideModal());
                dispatch(setInsurerAddressWarningMessage(null));
                postOwner({
                    parsedKitInsurer: parsedKit,
                    contractId,
                    owner,
                    currentStep,
                    nextStep,
                    dispatch,
                    insurerMismatches: mismatches,
                    insurerErrorStatus: status,
                });
            } else {
                const hasCriticals = getHasCriticals(mismatches);
                const isCritical = isCriticalStep(parsedKit, currentStep);
                dispatch(sendStepDispatchNull());
                dispatch(setMappedValidation(parsedKit));
                if (!hasCriticals && status !== RequestCode.INTERNAL_SERVER_ERROR) {
                    dispatch(goToStep(contractId, nextStep, isCritical));
                }
            }
        };

        if (currentStep === Steps.FIRST_STEP) {
            if (insurer?.isOwner) {
                dispatch(sendStepDispatchNull());
                return dispatch(goToStep(contractId, nextStep, false));
            }
            dispatch(setContractData({insurer, owner}));
            const insurerExecutor = () => saveInsurerData(contractId, insurer)
                .then(fulfilledActions);
            return executeAsyncAction(dispatch, insurerExecutor, errorHandler);
        }

        if (!stepsToValidate.includes(currentStep)) {
            dispatch(sendStepDispatchNull());
            return dispatch(goToStep(contractId, nextStep, false));
        }

        if (currentStep === Steps.SECOND_STEP) {
            dispatch(setContractData({vehicle}));
            const vehicleExecutor = () => saveVehicleData(contractId, vehicle)
                .then(fulfilledActions);
            return executeAsyncAction(dispatch, vehicleExecutor, errorHandler);
        }

        if (currentStep === Steps.THIRD_STEP) {
            dispatch(setContractData({drivers}));
            const driversExecutor = () => saveDriversData(contractId, drivers)
                .then(fulfilledActions);
            return executeAsyncAction(dispatch, driversExecutor, errorHandler);
        }

        return null; // unexpected step
    };
}

export function loadScanEditingRequest(contractId: string, callback: () => void): DispatchType {
    return (dispatch: DispatchType) => executeAsyncAction(dispatch, () => putScansEditingRequest(contractId)
        .then(() => {
            dispatch(setCurrentStage(AppStages.CHANGE_APPLICATION_DATA));
            dispatch(setIsScanEditingInitiated(true));
            callback();
        }));
}
