import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {
    InsurancePeriodInterface,
} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {createTranslator} from '@app/utils/i18n';

import * as styles from '../../form-container.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function InsurancePeriod({
    insurancePeriodStart,
    insurancePeriodEnd,
    vehicleIsShortInsurancePolicy,
}: InsurancePeriodInterface) {
    return (
        <div className={cn('form-container__form-container')}>
            <h3 className={cn('form-container__sub-header')}>
                {t('form-container-insurance-period-header')}
            </h3>
            <div className={cn('form-container__text-label')}>
                {t('form-container-insurance-vehicleIsShortInsurancePolicy')}
            </div>
            <div className={cn('form-container__text')}>
                {vehicleIsShortInsurancePolicy ? 'Да' : 'Нет'}
            </div>
            <div className={cn('form-container__text-label')}>
                {t('form-container-insurance-period-label')}
            </div>
            <div className={cn('form-container__text-period')}>
                {insurancePeriodStart}
            </div>
            <div className={cn('form-container__text-period')}>
                {insurancePeriodEnd}
            </div>
        </div>
    );
}
