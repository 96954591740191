import {combineReducers} from 'redux';

import {systemReducer} from '@app/store/reducers/system-reducer';
import {overlayReducer} from '@modules/overlay/overlay-reducer';

import {
    MODALS,
    OVERLAY,
    COMPANIES,
    CONTRACT,
    STAGE,
    NAVIGATION,
    USER,
    VALIDATION,
    SYSTEM,
} from '../model-constants';
import {modalsReducer} from './modals-reducer';
import {companiesReducer} from './companies-reducer';
import {contractReducer} from './contract-reducer';
import {navigationReducer} from './navigation-reducer';
import {stageReducer} from './stage-reducer';
import {userReducer} from './user-reducer';
import {validationReducer} from './validation-reducer';

export const reducer = combineReducers({
    [MODALS]: modalsReducer,
    [OVERLAY]: overlayReducer,
    [COMPANIES]: companiesReducer,
    [CONTRACT]: contractReducer,
    [STAGE]: stageReducer,
    [NAVIGATION]: navigationReducer,
    [USER]: userReducer,
    [VALIDATION]: validationReducer,
    [SYSTEM]: systemReducer,
});
