import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import * as styles from './list.scss';

export interface ItemInterface {
    title: string,
    key: string | number,
    description?: string,
    icon?: JSX.Element,
    onClick?: () => void,
    rightIcon?: JSX.Element,
    selected: boolean
}

const cn = classNames.bind(styles);

export function ListItem({
    title = '',
    description = '',
    icon = null,
    onClick = noop,
    rightIcon = null,
    selected = false,
}: ItemInterface) {
    const renderIcon = (icon: React.ReactNode, className: string): React.ReactNode => (
        <span className={cn(className)}>
            <span className={cn('list__icon-container')}>{icon}</span>
        </span>
    );

    return (
        <div
            className={cn('list__item', selected && 'list__item--selected')}
            onClick={onClick}
            role="button"
        >
            {icon && renderIcon(icon, 'list__left-icon')}
            <span className={cn('list__text')}>
                <span className={cn('list__title')}>{title}</span>
                {description && (
                    <span className={cn('list__description')}>{description}</span>
                )}
            </span>
            {rightIcon && renderIcon(rightIcon, 'list__right-icon')}
        </div>
    );
}
