import {isNil, isNumber, isString} from 'lodash-es';

import {FiasElementType} from '@app/types';

export interface SelectOption<T> {
    label: string;
    value: T;
}

function displayLabelStrucnum(strstatus: number, label: string) {
    switch (strstatus) {
        case 1:
            return `стр. ${label}`;
        case 2:
            return `соор. ${label}`;
        default:
            return `стр./соор. ${label}`;
    }
}

export function objectToSelectOption<T>(value: any, labelProp: keyof T): SelectOption<T> {
    if (isNil(value) || isNil(value[labelProp])) {
        return null;
    }
    const isPrimitive = isString(value[labelProp]) || isNumber(value[labelProp]);
    const label = isPrimitive ? `${value[labelProp]}` : '';

    if (!isNil(value.strstatus)) {
        return {
            label: displayLabelStrucnum(value.strstatus, label),
            value,
        };
    }

    return {
        label,
        value,
    };
}

export function objectsToSelectOptions<T>(items: T[], labelProp: keyof T): SelectOption<T>[] {
    return (items || []).map(item => objectToSelectOption(item, labelProp));
}

export function getOptionLabel<T>(options: SelectOption<T>[], value: T) {
    return (options || []).find(option => option.value === value)?.label;
}
