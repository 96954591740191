import {request, RequestConfig} from '@utils/request';

const API_URL = '/api';
const API_URL_V1 = `${API_URL}/v1`;
const API_URL_V2 = `${API_URL}/v2`;

const UI = 'ui';
const EGARANT_CAPTCHA = 'egarant-captcha';
const EGARANT_CONTRACT = 'egarant-contract';
const EGARANT_LK = 'egarant-lk';
const EGARANT_SK_SPREADER = 'egarant-sk-spreader';
const EGARANT_TIMERS = 'egarant-timers';

const API_PART_CONTRACT = 'contract';

export const API_V1_EGARANT_SK_SPREADER_UI = `${API_URL_V1}/${EGARANT_SK_SPREADER}/${UI}`;
export const API_V2_EGARANT_SK_SPREADER_UI = `${API_URL_V2}/${EGARANT_SK_SPREADER}/${UI}`;
export const API_V1_EGARANT_CONTRACT_UI = `${API_URL_V1}/${EGARANT_CONTRACT}/${UI}`;
export const API_V2_EGARANT_CONTRACT_UI = `${API_URL_V2}/${EGARANT_CONTRACT}/${UI}`;
export const API_V1_EGARANT_TIMERS_UI = `${API_URL_V1}/${EGARANT_TIMERS}/${UI}/${API_PART_CONTRACT}`;
export const API_V1_EGARANT_LK_UI = `${API_URL_V1}/${EGARANT_LK}/${UI}`;
export const API_V1_EGARANT_CAPTCHA_UI = `${API_URL_V1}/${EGARANT_CAPTCHA}/${UI}`;
export const API_V1_AUDIT_URL = `${API_V1_EGARANT_CONTRACT_UI}/${API_PART_CONTRACT}`;

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

interface PostRequestConfig {
    data?: any;
    params?: any;
    headers?: {[key: string]: string};
}

export function requestWrapper(config: RequestConfig, callBack?: (headers: any) => void) {
    if (callBack) {
        return request(config).then(result => callBack(result));
    }

    return request(config).then(result => result.data);
}

export function getData(url: string, config?: PostRequestConfig, callBack?: (data: any) => void) {
    return requestWrapper({
        url,
        method: HttpMethod.GET,
        params: {
            ...config?.params,
            timestamp: Date.now(),
        },
        headers: config?.headers,
    }, callBack);
}

export function postData(url: string, {data, headers}: PostRequestConfig, callBack?: (data: any) => void) {
    return requestWrapper({
        url,
        method: HttpMethod.POST,
        data,
        headers,
    }, callBack);
}

export function putData(url: string) {
    return requestWrapper({url, method: HttpMethod.PUT});
}

export function deleteData(url: string) {
    return requestWrapper({url, method: HttpMethod.DELETE});
}
