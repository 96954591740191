import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {dictionariesSelector} from '@modules/contract';
import {validationResultScansEditFieldsSelector} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {DocumentData} from './document-data';

const mapStateToProps = createStructuredSelector({
    dictionaries: dictionariesSelector,
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const DocumentDataContainer = connect(
    mapStateToProps
)(DocumentData);
