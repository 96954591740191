import classNames from 'classnames/bind';
import * as React from 'react';

import {Icons} from '@ui-kit';
import {noop} from '@utils/functions';

import * as styles from './switch-button.scss';

const cn = classNames.bind(styles);

type SwitchButtonProps = {
    isDisable?: boolean;
    forward?: boolean;
    onClick: () => void;
};

export const SwitchButton = ({
    isDisable = false,
    forward = false,
    onClick = noop,
}: SwitchButtonProps) => (
    <span
        className={
            cn('switch-button', {
                'switch-button--disabled': isDisable,
                'switch-button--forward': forward,
            })
        }
        onClick={isDisable ? null : onClick}
        onKeyPress={isDisable ? null : onClick}
    >
        {
            forward ?
                (<Icons.ARROW_RIGHT />) :
                (<Icons.ARROW_LEFT />)
        }
    </span>
);
