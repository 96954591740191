import * as React from 'react';

import {Person} from '@app/types';
import {CheckboxField, TextField} from '@ui-kit';
import {shouldBeDisabled} from '@utils/should-be-disabled';

export interface SecondNameFieldInterface {
    cn: (fn: string) => string;
    personType: string;
    textFieldLabel: string;
    checkboxLabel: string;
    mutator: (field: string, value: string) => void;
    person: Person;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function SecondNameField({
    cn,
    personType,
    textFieldLabel,
    checkboxLabel,
    mutator,
    person,
    scansEditFields,
    isLkBlocked,
}: SecondNameFieldInterface) {
    const [textFieldValue, changeTextFieldValue] = React.useState(person?.noSecondName ? null : person?.secondName);
    const [disableField, changeDisabling] = React.useState(person?.noSecondName);
    const name = `${personType}-person-secondName`;

    const handleTextFieldChange = (value: string) => {
        changeTextFieldValue(value);
    };

    const handleCheckboxChange = () => {
        mutator(name, disableField ? textFieldValue : null);
        changeDisabling(!disableField);
    };

    return (
        <div className={cn('person-data__form-checkbox-wrapper')}>
            <div className={cn('person-data__form-field')}>
                <TextField
                    name={name}
                    label={textFieldLabel}
                    disabled={shouldBeDisabled({
                        name,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                        forceDisabled: disableField,
                    })}
                    onChange={handleTextFieldChange}
                    required={!disableField}
                />
            </div>
            <div className={cn('person-data__form-field-checkbox')}>
                <CheckboxField
                    name={`${personType}-person-noSecondName`}
                    label={checkboxLabel}
                    onChange={handleCheckboxChange}
                    isDisabled={shouldBeDisabled({
                        name,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                />
            </div>
        </div>
    );
}
