import classNames from 'classnames/bind';
import * as React from 'react';
import {Redirect} from 'react-router';

import {getMismatchesMessages} from '@app/store/actions';
import {Mismatch} from '@app/types';
import {LOGIN_PAGE} from '@app/urls';
import {MatchParams} from '@utils/match-params';
import {createTranslator} from '@utils/i18n';
import * as dictionaryFlk from '@app/pages/shared-pages/layout-page/flk.dic.json';

import {FormRestore} from './components/form-restore';

import * as styles from './restore-page.scss';

const cn = classNames.bind(styles);
const f = createTranslator(dictionaryFlk);

export interface RestorePageInterface {
    setContractId(value: string): void;
    loadCaptchaPublicKey: () => void;
    match: MatchParams;
    confirmExpiredTimer: (flkCode: string) => void;
    putForgotPassword: () => void;
    forgotPassword: boolean;
    flkCode: string;
    isLkBlocked: boolean;
    mismatchData: Mismatch[];
    feEgarantCapthcaEnable: boolean;
}

export function RestorePage({
    setContractId,
    loadCaptchaPublicKey,
    match,
    confirmExpiredTimer,
    putForgotPassword,
    forgotPassword,
    flkCode,
    isLkBlocked,
    mismatchData,
    feEgarantCapthcaEnable,
}: RestorePageInterface) {
    const [restoreStage, setRestoreStage] = React.useState(true);

    React.useEffect(() => {
        setRestoreStage(forgotPassword);
    }, [setRestoreStage, forgotPassword]);

    React.useEffect(() => {
        setContractId(match.params.contractId);

        if (feEgarantCapthcaEnable) {
            loadCaptchaPublicKey();
        }

        putForgotPassword();
    }, []);

    if (isLkBlocked && !mismatchData.length) {
        confirmExpiredTimer(flkCode);
    }

    if (!restoreStage) {
        return <Redirect to={`/${match.params.skDomain}/${LOGIN_PAGE}/${match.params.contractId}`} />;
    }

    return (
        <div className={cn('restore-wrapper')}>
            <div className={cn('expired-timer')}>
                {
                    isLkBlocked && (mismatchData.length ?
                        getMismatchesMessages(mismatchData) :
                        f(flkCode)
                    )
                }
            </div>
            <div className={cn('restore')}>
                <FormRestore />
            </div>
        </div>
    );
}
