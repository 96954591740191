import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {contractIdSelector} from '@modules/contract';
import {validationResultScansEditFieldsSelector} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {AddressData} from './address-data';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const AddressDataContainer = connect(
    mapStateToProps
)(AddressData);
