
import classNames from 'classnames/bind';
import * as React from 'react';

import {IconButton, Icons} from '@ui-kit';
import {noop} from '@utils/functions';

import * as styles from './file-uploader.scss';

const cn = classNames.bind(styles);

enum fileStatuses {
    READY = 'ready',
    CHOSEN = 'chosen',
    DRAG_ENTER = 'dragEnter',
    DRAG_ENTER_NEW = 'dragEnterNew',
}

interface FileUploader {
    name: string;
    initialFileName?: string;
    onFileSend: (formData: FormData, fileName: string) => void;
    onFileDelete?: () => void;
    placeHolder: string;
    isAbleToDelete?: boolean;
    disabled?: boolean;
}

export const FileUploader = ({
    name,
    initialFileName = null,
    onFileSend,
    onFileDelete = noop,
    placeHolder,
    isAbleToDelete = false,
    disabled,
}: FileUploader) => {
    const [uploadStatus, setUploadStatus] = React.useState(initialFileName ?
        fileStatuses.CHOSEN :
        fileStatuses.READY);
    const [uploadFile, setUploadFile] = React.useState(null);
    const [dragEnter, setDragEnter] = React.useState(false);

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const uploadedFile = e.target.files[0];
        setDragEnter(false);
        if (uploadedFile) {
            setUploadFile(uploadedFile);
            setUploadStatus(fileStatuses.CHOSEN);

            const formData = new FormData();
            formData.append('file', uploadedFile);
            onFileSend(formData, uploadedFile.name);
        }
    };

    const handleDelete = (e: React.MouseEvent) => {
        setDragEnter(false);
        setUploadStatus(fileStatuses.READY);

        onFileDelete();
    };

    const uploadFileName = uploadFile && uploadFile.name;
    const chosenFileName = uploadFileName || initialFileName;
    const title = chosenFileName || placeHolder;

    const currentDragEnter = uploadStatus === fileStatuses.READY ?
        fileStatuses.DRAG_ENTER :
        fileStatuses.DRAG_ENTER_NEW;
    const titleModifier = dragEnter ?
        currentDragEnter :
        uploadStatus;
    const titleDisabled = disabled ?
        'disabled' :
        uploadStatus;

    return (
        <div className={cn('file-uploader')}>
            <input
                className={cn('file-uploader__input', `file-uploader__input--${titleDisabled}`)}
                onChange={handleFileSelect}
                onDragEnter={() => { setDragEnter(true); }}
                onDragLeave={() => { setDragEnter(false); }}
                name={name}
                title={chosenFileName || null}
                type="file"
                disabled={disabled}
            />
            <div className={cn(
                'file-uploader__title',
                `file-uploader__title--${titleModifier}`,
                `file-uploader__title--${titleDisabled}`
            )}
            >
                {title}
            </div>
            {isAbleToDelete && uploadFile && (
                <IconButton
                    onClick={handleDelete}
                    icon={Icons.CLEAR}
                    className={cn('file-uploader__delete')}
                />
            )}
        </div>
    );
};
