import {Store} from 'redux';

import {
    processUnauthorized,
    processBadRequest,
    processInternalServerError,
    processNotFound,
} from '@app/store/actions';
import {commonModals} from '@modules/common-modals';
import {registerModal} from '@modules/modals';
import {
    registerErrorHandler,
    RequestError,
    RequestCode,
} from '@utils/request';

const createRequestErrorHandler = (store: Store) => (error: RequestError) => {
    switch (error.status) {
        case RequestCode.BAD_REQUEST:
        case RequestCode.GONE:
            store.dispatch<any>(processBadRequest(error.mismatches, error.status));
            return;
        case RequestCode.UNAUTHORIZED:
            store.dispatch<any>(processUnauthorized());
            return;
        case RequestCode.NOT_FOUND:
            store.dispatch<any>(processNotFound());
            return;
        case RequestCode.FORBIDDEN:
        case RequestCode.REQUEST_TIMEOUT:
        case RequestCode.UNPROCESSABLE_ENTITY:
        case RequestCode.INTERNAL_SERVER_ERROR:
        case RequestCode.GATEWAY_TIMEOUT:
        default:
            store.dispatch<any>(processInternalServerError());
    }
};

export function bootstrapApp(store: Store): void {
    registerErrorHandler(createRequestErrorHandler(store));
    registerModal([
        ...commonModals,
    ]);
}
