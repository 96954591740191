import {getPersonRegistrationData, register} from '@app/modules/user';
import * as dictionaryLayout from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import {
    setCompanyPremium,
    setUser,
    setContractId,
    setLinkData,
    setHasUser,
    setHasInsCompanySecondChosen,
    setCompanies,
    setFilterableCompanies,
    setIsActiveRegisterBtnAgree,
    setIActiveRegisterBtnAware,
} from '@app/store/actions';
import {PersonRegistrationData} from '@app/types';
import {expirationTimeSelector} from '@app/modules/stage';
import {createTranslator} from '@app/utils/i18n';
import {confirm, ConfirmResult} from '@modules/common-modals';
import {getInsuranceCompanyPremium} from '@modules/companies';
import {returnContract} from '@modules/companies/companies-api';
import {LinkDataInterface} from '@modules/contract';
import {executeAsyncAction} from '@modules/model/actions';
import {MODAL_WIDTH} from '@utils/constants/modal-width';
import {clearPhoneNumber, formatPhoneNumber} from '@utils/formatters';
import {DispatchType} from '@utils/redux';
import {RequestError} from '@utils/request';

import * as dictionary from './registration-page.dic.json';

const l = createTranslator(dictionaryLayout);
const t = createTranslator(dictionary);

export function fetchRegistrationData(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        dispatch(setContractId(contractId));

        const resultFetchUser = getPersonRegistrationData(contractId);
        const resultFetchCompanyPremium = getInsuranceCompanyPremium(contractId);

        executeAsyncAction(dispatch, () => Promise.all([
            resultFetchUser,
            resultFetchCompanyPremium,
        ]))
            .then(([user, {premium}]) => {
                dispatch(setUser(user));
                dispatch(setCompanyPremium(premium));
            });
    };
}

function confirmError(): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(confirm({
            title: l('confirm-modal-title'),
            message: t('registration-page-confirm-modal-error-text'),
            width: MODAL_WIDTH,
            resultHandler: result => {
            },
            hasCancelOption: false,
        }));
    };
}

export function confirmRegistration(
    contractId: string,
    userData: PersonRegistrationData
): DispatchType {
    const formattedPhoneNumber = formatPhoneNumber(userData.phoneNumber);
    return (dispatch: DispatchType): void => {
        const executor = () => register(contractId)
            .then(() => {
                dispatch(confirm({
                    title: l('confirm-modal-title'),
                    message: t('registration-page-confirm-modal-text',
                        userData.email,
                        formattedPhoneNumber),
                    width: MODAL_WIDTH,
                    resultHandler: result => {
                        if (result === ConfirmResult.CONFIRMED) {
                            dispatch(setHasUser(true));
                        }
                    },
                    hasCancelOption: false,
                }));
            });
        const errorHandler = (error: RequestError) => {
            if (error.status === 409) {
                dispatch(confirmError());
            }
        };

        executeAsyncAction(dispatch, executor, errorHandler);
    };
}

export function loadReturnContract(contractId: string, linkData: LinkDataInterface) {
    return (dispatch: DispatchType, getState: Function) => {
        const resultReturnContract = returnContract(contractId);
        const expirationTime = expirationTimeSelector(getState());
        const expirationDate = new Date(expirationTime);

        executeAsyncAction(dispatch, () => resultReturnContract.then(() => {
            dispatch(confirm({
                title: l('confirm-modal-title'),
                message: t(
                    'registration-page-confirm-modal-warning-text',
                    expirationDate.getHours(),
                    expirationDate.getMinutes(),
                    expirationDate.getFullYear()
                ),
                width: MODAL_WIDTH,
                resultHandler: () => {},
                hasCancelOption: false,
            }));
            dispatch(setLinkData({
                ...linkData,
                insCompanyId: null,
                insCompanyName: null,
                insCompanyUrl: null,
                representativesLink: null,
            }));
            dispatch(setHasInsCompanySecondChosen({
                isChosen: false,
                skDomain: null,
            }));
            dispatch(setCompanies([]));
            dispatch(setFilterableCompanies([]));

            dispatch(setIsActiveRegisterBtnAgree(false));
            dispatch(setIActiveRegisterBtnAware(false));
        }));
    };
}
