import * as React from 'react';

import {ButtonWithPopup, Column} from '@ui-kit';

import {PopupComponent} from '../../columns-popup';

interface ColumnsButtonProps {
    columns: Column[];
    initialValues: object;
    updateColumns: (columnsStatuses: object) => void;
}

export const ColumnsButton = ({columns = [], initialValues = {}, updateColumns}: ColumnsButtonProps) => {
    const renderPopup = (closePanel: Function) => (
        <PopupComponent
            columns={columns}
            updateColumns={updateColumns}
            initialValues={initialValues}
            closePanel={closePanel}
        />
    );

    return (
        <ButtonWithPopup renderPopup={renderPopup} />
    );
};
