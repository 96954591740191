
import * as React from 'react';

import {IconButtonType} from '@app/components/icon-button';
import {Icons, IconButton} from '@ui-kit';

interface SignOutInterface {
    contractId: string;
    logoutAction: (contractId: string) => void;
}

export const SignOut = ({
    contractId,
    logoutAction,
}: SignOutInterface) => (
    <IconButton
        icon={Icons.EXIT}
        type={IconButtonType.BORDERED}
        onClick={() => {
            logoutAction(contractId);
        }}
    />
);
