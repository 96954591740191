import * as React from 'react';

import {withErrorIndicator} from '@app/components/fields/with-error-indicator';
import {Checkbox} from '@ui-kit';

import {fieldAdapter} from '../field-adapter';

export interface CheckboxWithFieldAdapterProps {
    name: string,
    label: string,
    required?: boolean,
    value?: boolean,
    isDisabled?: boolean,
    onChange?: (value: any) => void
}

function CheckboxWithFieldAdapterComponent(props: CheckboxWithFieldAdapterProps) {
    const {value, name, onChange} = props;
    const handleChange = () => onChange(!value);

    return (
        <Checkbox
            {...props}
            checked={value}
            id={name}
            onChange={handleChange}
        />
    );
}

export const CheckboxWithFieldAdapter = fieldAdapter(withErrorIndicator(CheckboxWithFieldAdapterComponent));
