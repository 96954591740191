import classNames from 'classnames/bind';
import * as React from 'react';

import {EventName} from '@utils/constants';

import {RelativePortal} from '../portal';

import * as styles from './with-tool-tip.scss';

const cn = classNames.bind(styles);

interface ToolTipState {
    isVisible: boolean
}

export function withToolTip(Child: React.ComponentType<any>) {
    return class EnhancedComponent extends React.Component<any, ToolTipState> {
        toolTipRef = React.createRef<HTMLSpanElement>();

        static displayName = `withToolTip(${Child.displayName || Child.name})`;

        state = {
            isVisible: false,
        };

        componentDidMount() {
            window.addEventListener(EventName.SCROLL, this.handleScroll);
        }

        componentWillUnmount() {
            window.removeEventListener(EventName.SCROLL, this.handleScroll);
        }

        showToolTip = () => this.setState({isVisible: true});

        handleScroll = () => this.setState({isVisible: false});

        renderToolTip = () => {
            const {toolTipContent, options} = this.props;
            return (
                <RelativePortal
                    parentElement={this.toolTipRef.current}
                    options={options}
                    className={cn('tool-tip-content')}
                >
                    {toolTipContent}
                </RelativePortal>
            );
        };

        hideToolTip = () => {
            this.setState({isVisible: false});
        };

        render() {
            const {isVisible} = this.state;
            const {toolTipContent} = this.props;

            if (toolTipContent) {
                return (
                    <>
                        <span
                            onMouseEnter={this.showToolTip}
                            onMouseLeave={this.hideToolTip}
                            ref={this.toolTipRef}
                        >
                            <Child {...this.props} />
                        </span>
                        {isVisible && this.renderToolTip()}
                    </>
                );
            }
            return (<Child {...this.props} />);
        }
    };
}
