export interface UserData {
    login: string;
    password?: string;
}

export interface UserExists {
    hasUser: boolean;
}

export interface UserSession {
    jti: string;
}

export interface UserAuth {
    status: UserAuthStatus;
}

export enum UserAuthStatus {
    ACTIVE = 'ACTIVE',
    LOCKED = 'LOCKED', // is used only internally for BE
    SUCCESS_PAY = 'SUCCESS_PAY',
}

export interface AisTokenPayload {
    iss: 'SK_INTEGRATION' | 'ARM_RSA' | 'EGARANT';
    exp: number;
    jti: string;
    sub: string;
    insurerId: string;
    contractId: string;
    // eslint-disable-next-line camelcase
    user_name: string;
}
