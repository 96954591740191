import {NAVIGATION} from '@app/store/model-constants';
import {NavigationState} from '@app/types';
import {createActionFactory, DispatchType} from '@app/utils/redux';
import {ActionTypes} from '@utils/types';
import {Steps} from '@utils/constants';
import {
    Document,
    DocumentFile,
    DocumentFileStatuses,
    documentsTableDataSelector,
    uploadedDocumentFilesSelector,
} from '@modules/contract';

const navigationActionCreator = createActionFactory(NAVIGATION);

export const setIsActiveRegisterBtnAgree = navigationActionCreator<Boolean>(
    ActionTypes.SET_IS_ACTIVE_REGISTER_BTN_AGREE
);
export const setIActiveRegisterBtnAware = navigationActionCreator<Boolean>(
    ActionTypes.SET_IS_ACTIVE_REGISTER_BTN_AWARE
);
export const setIsAgreementBtnEnabled = navigationActionCreator<Boolean>(ActionTypes.SET_IS_AGREEMENT_BTN_ENABLED);
export const setIsValidationChecked = navigationActionCreator<Boolean>(ActionTypes.SET_IS_VALIDATION_CHECKED);
export const setIsUpdateScansStatusDisabled = navigationActionCreator<Boolean>(
    ActionTypes.SET_IS_UPDATE_SCANS_STATUS_DISABLED
);
export const setIsCheckDataBtnHidden = navigationActionCreator<Boolean>(ActionTypes.SET_IS_CHECK_DATA_BTN_HIDDEN);
export const setIsNavigationShown = navigationActionCreator<Boolean>(ActionTypes.SET_IS_NAVIGATION_SHOWN);
export const setIsNotFound = navigationActionCreator<Boolean>(ActionTypes.SET_IS_NOT_FOUND);
export const setNavigationResult = navigationActionCreator<NavigationState>(ActionTypes.SET_NAVIGATION_RESULT);
export const setCurrentStep = navigationActionCreator<number>(ActionTypes.SET_CURRENT_STEP);
export const setStepAfterSubmit = navigationActionCreator<number>(ActionTypes.SET_STEP_AFTER_SUBMIT);
export const setIsLkBlockedTrue = navigationActionCreator(ActionTypes.SET_IS_LK_BLOCKED_TRUE);
export const setIsUploadedFilesBtnEnabled = navigationActionCreator<Boolean>(
    ActionTypes.SET_IS_UPLOADED_FILES_BTN_ENABLED
);

export const forceUpdateNext = (currentStep: number) => (dispatch: DispatchType) => {
    dispatch(setIsCheckDataBtnHidden(false));
    dispatch(setStepAfterSubmit(currentStep + 1));
    if (currentStep === Steps.THIRD_STEP) {
        dispatch(setIsCheckDataBtnHidden(false));
        dispatch(setIsValidationChecked(false));
    }
};

export const forceUpdatePrev = (currentStep: number) => async (dispatch: DispatchType) => {
    dispatch(setStepAfterSubmit(currentStep - 1));
};

export const toggleUploadedFilesBtn = () => (dispatch: DispatchType, getState: Function) => {
    const documentTableData = documentsTableDataSelector(getState());
    const uploadedDocumentFiles = uploadedDocumentFilesSelector(getState());

    const documentTableDataFiles: (DocumentFile & Document)[] = documentTableData.reduce((acc, document) => {
        const filesWithData = document.files.map(file => ({
            ...file,
            ...document,
        }));
        return [...acc, ...filesWithData];
    }, []);

    const isEnabled = documentTableDataFiles.every(fileWithData => uploadedDocumentFiles.find(file => (
        !fileWithData.required || (
            file.fileGuid &&
            [
                DocumentFileStatuses.SUCCESS,
                DocumentFileStatuses.INSURANCE_COMPANY_SUCCESS,
                DocumentFileStatuses.INSURANCE_COMPANY_FAIL,
                DocumentFileStatuses.INSURANCE_COMPANY_CHECKING,
            ].includes(file.status) &&
            file.documentType === fileWithData.type &&
            file.documentIndex === fileWithData.documentIndex &&
            file.fileOrderInGroup === fileWithData.fileOrderInGroup
        )
    )));

    dispatch(setIsUploadedFilesBtnEnabled(isEnabled));
};
