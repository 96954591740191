import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {sendStep} from '@app/pages/contract-page/contract-page-actions';
import {
    dictionariesSelector,
    insurerSelector,
    ownerSelector,
    contractIdSelector,
} from '@modules/contract';
import {overlayVisibilitySelector} from '@modules/overlay';
import {
    mappedValidationKitStep1Selector,
    stepsToValidateSelector,
} from '@modules/validation';
import {
    currentStepSelector,
    stepAfterSubmitSelector,
} from '@modules/navigation';

import {Step1} from './step1';

const mapStateToProps = createStructuredSelector({
    dictionaries: dictionariesSelector,
    contractId: contractIdSelector,
    insurer: insurerSelector,
    owner: ownerSelector,
    isLoadingContract: overlayVisibilitySelector,
    warningData: mappedValidationKitStep1Selector,
    currentStep: currentStepSelector,
    stepAfterSubmit: stepAfterSubmitSelector,
    stepsToValidate: stepsToValidateSelector,
});

const mapDispatchToProps = {
    sendStep,
};

export const Step1Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1);
