import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    insurerAddressTextWarningMessageSelector,
    insurerAddressWarningMessageSelector,
    ownerAddressTextWarningMessageSelector,
    ownerAddressWarningMessageSelector,
} from '@modules/validation';

import {WarningEngine} from './warning-engine';

const mapStateToProps = createStructuredSelector({
    insurerMessage: insurerAddressWarningMessageSelector,
    ownerMessage: ownerAddressWarningMessageSelector,
    insurerTextMessage: insurerAddressTextWarningMessageSelector,
    ownerTextMessage: ownerAddressTextWarningMessageSelector,
});

export const WarningEngineContainer = connect(
    mapStateToProps
)(WarningEngine);
