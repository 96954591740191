import classNames from 'classnames/bind';
import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router';

import {NoMatchPage} from '@app/pages/no-match-page';
import {Mismatch} from '@app/types';
import {CONTRACT_PAGE} from '@app/urls';
import {AppStages, Steps} from '@utils/constants';
import {DEFAULT_SK_DOMAIN} from '@utils/constants/egarant-settings';
import {MatchParams} from '@utils/match-params';

import {AdditionalInfo} from './components/additional-info';
import {StepperNavigator} from './components/stepper-navigator';
import {Step1} from './pages/step-1';
import {Step2} from './pages/step-2';
import {Step3} from './pages/step-3';
import {Step4} from './pages/step-4';

import * as styles from './contract-page.scss';

const cn = classNames.bind(styles);

interface ContractPageInterface{
    match: MatchParams;
    passParams: (stepValue: MatchParams['params']) => void;
    currentStep: number;
    contractId: string;
    skDomain: string;
    confirmExpiredTimer: (flkCode: string) => void;
    currentStage: string;
    loadData: (contractId: string) => void;
    flkCode: string;
    setMismatchData: (mismatchData: Mismatch[]) => void;
    isNavigationShown: boolean;
    isLkBlocked: boolean;
    isDataEdited: boolean;
    mismatchData: Mismatch[];
    isNotFirstLoadData: boolean;
}

export function ContractPage({
    match,
    passParams,
    currentStep,
    confirmExpiredTimer,
    currentStage,
    loadData,
    flkCode,
    setMismatchData,
    isNavigationShown,
    skDomain,
    isLkBlocked,
    isDataEdited,
    mismatchData,
    isNotFirstLoadData,
}: ContractPageInterface) {
    const {skDomain: skDomainMatchParams} = match.params;
    const isFirstLoadData = !isNotFirstLoadData && currentStep !== 0;
    const updateDataAfterEditing = isDataEdited && currentStep === Steps.FINAL_STEP;
    const isFinalStep = currentStep === Steps.FINAL_STEP;
    const isNotSkDomainInUrl = ![skDomain as string, DEFAULT_SK_DOMAIN].includes(skDomainMatchParams);
    const currentStepFromUrl = Number(match.params.step.replace('step', ''));

    React.useEffect(() => {
        if (isFirstLoadData || updateDataAfterEditing) {
            loadData(match.params.contractId);
        }
        setMismatchData(null);
    }, [isFirstLoadData, updateDataAfterEditing]);

    React.useEffect(() => {
        passParams(match.params);
    }, [match.params]);

    if (currentStage &&
        currentStep &&
        ((!isFinalStep && currentStage !== AppStages.CHANGE_APPLICATION_DATA) || (isFinalStep && isNotSkDomainInUrl))
    ) {
        return (
            <Redirect
                to={`/${skDomain || DEFAULT_SK_DOMAIN}/${CONTRACT_PAGE}/${match.params.contractId}/step4`}
            />
        );
    }
    if (currentStep &&
        match.params.step &&
        ((currentStep !== currentStepFromUrl) || (!isFinalStep && isNotSkDomainInUrl))
    ) {
        return (
            <Redirect
                to={`/${skDomain || DEFAULT_SK_DOMAIN}/${CONTRACT_PAGE}/${match.params.contractId}/step${currentStep}`}
            />
        );
    }

    if (isLkBlocked && !mismatchData && currentStage && flkCode) {
        confirmExpiredTimer(flkCode);
    }

    return (
        <div className={cn('contract-page')}>
            <StepperNavigator />
            <AdditionalInfo />
            {isNavigationShown && (
                <Switch>
                    <Route
                        path={`/:skDomain/${CONTRACT_PAGE}/:contractId?/step1`}
                        component={Step1}
                    />
                    <Route
                        path={`/:skDomain/${CONTRACT_PAGE}/:contractId?/step2`}
                        component={Step2}
                    />
                    <Route
                        path={`/:skDomain/${CONTRACT_PAGE}/:contractId?/step3`}
                        component={Step3}
                    />
                    <Route
                        path={`/:skDomain/${CONTRACT_PAGE}/:contractId?/step4`}
                        component={Step4}
                    />
                    <Route component={NoMatchPage} />
                </Switch>
            )}
        </div>
    );
}
