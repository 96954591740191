import {isEqual} from 'lodash-es';
import * as React from 'react';

import {Select} from '@ui-kit';

import {fieldAdapter} from '../field-adapter';
import {CommonSelectWithFieldAdapterProps} from '../field-types';
import {withErrorIndicator} from '../with-error-indicator';

export interface SelectWithFieldAdapterProps<T> extends CommonSelectWithFieldAdapterProps<T> {
}

function SelectWithFieldAdapterComponent<T>(props: SelectWithFieldAdapterProps<T>) {
    const {value, options} = props;

    const selectedOption = options && options.length ?
        options.find(option => isEqual(value?.value, option.value) || value === option.value) :
        undefined;

    return (
        <Select
            {...props}
            selectedOption={selectedOption}
        />
    );
}

export const SelectWithFieldAdapter = fieldAdapter(withErrorIndicator(SelectWithFieldAdapterComponent));
