export const kebabCaseFormatter = (str: string): string => (
    str
        .trim()
        .replace(/([a-z0-9][A-Z])/g, $1 => ($1.split('').join('-')))
        .replace(/([_ ])/ig, () => ('-'))
        .toLowerCase()
);

export const pascalCaseFormatter = (str: string): string => {
    let result = str;

    if (['-', ' ', '_'].some(symbol => str.includes(symbol))) {
        result = str
            .replace(/([a-z])([A-Z])([a-z])/g, '$1-$2$3')
            .toLowerCase()
            .replace(/([-_ ][a-z])/ig, $1 => (
                $1.toUpperCase().replace(/[ -_]/, '')
            ))
            .trim();
    }

    return result.charAt(0).toUpperCase() + result.slice(1);
};
