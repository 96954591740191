import {MODULE_NAMESPACE} from './notifications-constants';

export enum MessageType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning'
}

/** interface of notification as it is in store stack */
export interface NotificationData {
    type: MessageType
    message: string
    creationDate: number
    id: string | number
}

export interface NotificationPayload {
    type: MessageType
    message: string
}

export interface NotificationsState {
    readonly notificationsStack: NotificationData[]
}

export interface AppState {
    [MODULE_NAMESPACE]: NotificationsState
}

export enum ACTIONS {
    ADD_NOTIFICATION = 'ADD_NOTIFICATION',
    SAVE_NOTIFICATION = 'SAVE_NOTIFICATION',
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
    REMOVE_NOTIFICATION_BY_TYPE = 'REMOVE_NOTIFICATION_BY_TYPE',
    CLEAN_NOTIFICATIONS = 'CLEAN_NOTIFICATIONS'
}
