import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {openRejectPaymentModal} from '@app/pages/contract-page/contract-page-actions';
import {
    isAmountInLimitsSelector,
    contractIdSelector,
} from '@modules/contract';
import {isLkBlockedSelector} from '@modules/navigation';
import {isDisabled} from '@utils/isDisabled';

import {ButtonCancel} from './button-cancel';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isAmountInLimits: isAmountInLimitsSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    isDisabled,
    rejectPayment: openRejectPaymentModal,
};

export const ButtonCancelContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonCancel);
