import classNames from 'classnames/bind';
import * as React from 'react';

import {createTranslator} from '@utils/i18n';

import {IconButton, IconButtonType} from '../../../icon-button';
import {Icons} from '../../../icons';
import {withToolTip} from '../../../tool-tip';

import * as dictionary from '../../validation-error-indicator.dic.json';
import * as styles from './warning-button.scss';

const t = createTranslator(dictionary);
const cn = classNames.bind(styles);

interface WarningButtonProps {
    showToolTip: boolean,
    onClick: () => void
}

export function WarningButton({showToolTip, onClick}: WarningButtonProps) {
    const IconButtonWithToolTip = withToolTip(IconButton);

    const renderToolTip = () => (
        <span
            className={cn('warning-button__tool-tip')}
        >
            {t('warning-button.tooltip.text')}
        </span>
    );

    if (showToolTip) {
        return (
            <IconButtonWithToolTip
                type={IconButtonType.WARNING}
                toolTipContent={renderToolTip()}
                onClick={onClick}
                icon={Icons.WARNING}
            />

        );
    }

    return (
        <IconButton
            type={IconButtonType.WARNING}
            onClick={onClick}
            icon={Icons.WARNING}
        />
    );
}
