import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {insurerPhoneNumberSelector} from '@modules/contract';

import {UserInfo} from './user-info';

const mapStateToProps = createStructuredSelector({
    phoneNumber: insurerPhoneNumberSelector,
});

export const UserInfoContainer = connect(mapStateToProps)(UserInfo);
