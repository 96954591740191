
import classNames from 'classnames/bind';
import * as React from 'react';

import {FileUploader} from '@app/pages/contract-page/components/file-uploader';
import {
    DocumentFile as DocumentFileType,
} from '@modules/contract';

import * as styles from './document-file.scss';

const cn = classNames.bind(styles);

type DocumentFileProps = {
    file: DocumentFileType,
    onFileLoad: (formData: FormData, fileName: string) => void;
    initialFileName?: string;
    disabled?: boolean;
}

export function DocumentFile({
    file,
    onFileLoad,
    initialFileName,
    disabled,
}: DocumentFileProps) {
    const {type, required} = file;
    const placeholder = `${type}${required ? '*' : ''}`;

    return (
        <div className={cn('document-file')}>
            <FileUploader
                name={type}
                initialFileName={initialFileName}
                placeHolder={placeholder}
                onFileSend={onFileLoad}
                disabled={disabled}
            />
        </div>
    );
}
