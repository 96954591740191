
import {connect} from 'react-redux';

import {
    setContractId,
    fetchUserInfo,
    loadReservationFailed,
} from '@app/store/actions';
import {MatchParams} from '@utils/match-params';
import {DispatchType} from '@utils/redux';

import {FailPaymentPage} from './fail-payment-page';

const mapDispatchToProps = (dispatch: DispatchType) => ({
    passParams: ({contractId}: MatchParams['params']) => {
        dispatch(setContractId(contractId));
    },
    fetchUserData: (contractId: string) => dispatch(fetchUserInfo(contractId)),
    loadReservationFailed: (contractId: string) => dispatch(loadReservationFailed(contractId)),
});

export const FailPaymentPageContainer = connect(
    null,
    mapDispatchToProps
)(FailPaymentPage);
