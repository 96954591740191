import classNames from 'classnames/bind';
import * as React from 'react';

import {SelectOption} from '@utils/formatters/select-option';
import {noop} from '@utils/functions';
import {createTranslator} from '@utils/i18n';

import {Icons} from '../../../icons';
import {OptionsCommonProps} from '../../select-types';

import * as dictionary from './options.dic.json';
import * as styles from './options.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export enum OptionsType {
    INPUT_OPTIONS = 'INPUT_OPTIONS',
    LINK_OPTIONS = 'LINK_OPTIONS'
}

const baseOptionsClass = {
    [OptionsType.INPUT_OPTIONS]: 'input-option',
    [OptionsType.LINK_OPTIONS]: 'link-option',
};

interface OptionsProps extends OptionsCommonProps{
    showIcon?: boolean;
    optionsType: OptionsType;
    isLoading?: boolean;
}

export function Options({
    options,
    selectedOption,
    emptyOptionMessage,
    onClick,
    getLabel,
    getValue,
    focusedOption,
    showIcon,
    optionsType,
    isLoading,
}: OptionsProps) {
    const baseClass = baseOptionsClass[optionsType];

    return (
        <>
            {options.length ?
                options.map((option: SelectOption<any>, index: number) => {
                    const key = `${index}${getValue(option)}`;
                    const isSelected = getValue(option) === getValue(selectedOption);
                    const isFocused = getValue(option) === getValue(focusedOption);

                    return (
                        <div
                            role="button"
                            className={cn(baseClass, {
                                [`${baseClass}--selected`]: isSelected,
                                [`${baseClass}--focused`]: isFocused,
                            })}
                            onMouseDown={() => onClick(option)}
                            key={key}
                        >
                            <div className={cn(`${baseClass}__label`)}>
                                {getLabel(option)}
                            </div>
                            {showIcon && isSelected && <Icons.CHECK />}
                        </div>
                    );
                }) : !isLoading && (
                    <span className={cn(baseClass)}>{emptyOptionMessage}</span>
                )}
        </>
    );
}

Options.defaultProps = {
    options: [],
    onClick: noop,
    getValue: (option: any): string | number => option.value,
    getLabel: (option: any): string => option.label || '',
    emptyOptionMessage: t('option.emptyOptionMessage'),
};
