import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {insCompanyUrlSelector} from '@modules/companies';

import {PaymentSuccessMessage} from './success-message';

const mapStateToProps = createStructuredSelector({
    insCompanyUrl: insCompanyUrlSelector,
});

export const PaymentSuccessMessageContainer = connect(
    mapStateToProps
)(PaymentSuccessMessage);
