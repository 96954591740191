export enum KeyCode {
    DOWN_ARROW = 40,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    ENTER = 13,
    ESCAPE = 27,
    WHITESPACE = 32,
    BACKSPACE = 8,
    DELETE = 46,
}
