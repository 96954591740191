
import * as React from 'react';
import {connect} from 'react-redux';

import {showTabsGuardModal} from '@modules/tabs-guard/tabs-guard-actions';
import {MatchParams} from '@utils/match-params';
import {hideModal} from '@app/store/actions';

const TABS_GUARD_LOCAL_STORAGE_KEY = 'egarant_contract_tab_guard';
const TABS_GUARD_WORKING_SESSION_KEY = 'egarant_working_session';
const TABS_GUARD_TIMER_INTERVAL = 500;
const HEX = 16;
const ZERO_AND_DOT_CROP = 2;
const generateSessionId = () => Math.random().toString(HEX).substring(ZERO_AND_DOT_CROP);

interface TabsGuardInterface {
    children: React.ReactChild;
    tabsGuardModal: () => void;
    match: MatchParams;
}

const TabsGuard = ({children, tabsGuardModal, match: {params: {contractId}}}: TabsGuardInterface) => {
    const [tabChecked, setTabChecked] = React.useState(false);
    React.useEffect(() => {
        // necessary for force synchronization of localStorage
        const emptyFunc = () => {};
        window.addEventListener('storage', emptyFunc);

        setTimeout(() => {
            const intervalId = setInterval(() => {
                let isOk = tabChecked;
                const now = Date.now();

                if (!tabChecked) {
                    const otherTabDateTime = localStorage.getItem(TABS_GUARD_LOCAL_STORAGE_KEY);
                    localStorage.removeItem(TABS_GUARD_WORKING_SESSION_KEY);
                    sessionStorage.setItem(TABS_GUARD_WORKING_SESSION_KEY, generateSessionId());

                    if (Number(otherTabDateTime) + TABS_GUARD_TIMER_INTERVAL * 2 < now) {
                        isOk = true;
                        setTabChecked(true);
                    }
                    clearInterval(intervalId);
                }

                if (isOk) {
                    localStorage.setItem(TABS_GUARD_LOCAL_STORAGE_KEY, String(now));

                    // Добавлено в качестве защиты от кейса RAMIEPT-128967
                    const workingSessionLocal = localStorage.getItem(TABS_GUARD_WORKING_SESSION_KEY);
                    const workingSessionSession = sessionStorage.getItem(TABS_GUARD_WORKING_SESSION_KEY);
                    if (!workingSessionLocal) {
                        localStorage.setItem(TABS_GUARD_WORKING_SESSION_KEY, workingSessionSession);
                    } else if (workingSessionLocal === workingSessionSession) {
                        setTabChecked(true);
                        hideModal();
                    } else {
                        setTabChecked(false);
                        clearInterval(intervalId);
                    }
                } else {
                    tabsGuardModal();
                }
            }, TABS_GUARD_TIMER_INTERVAL);
        }, TABS_GUARD_TIMER_INTERVAL * 2);

        return () => {
            window.removeEventListener('storage', emptyFunc);
        };
    }, [tabChecked]);

    return (
        <>
            {tabChecked ? children : null}
        </>
    );
};

const mapDispatchToProps = {
    hideModal,
    tabsGuardModal: showTabsGuardModal,
};

export const TabsGuardContainer = connect(
    null,
    mapDispatchToProps
)(TabsGuard);
