import {isEqual} from 'lodash-es';
import * as React from 'react';

import {AutocompleteSelect} from '@ui-kit';

import {fieldAdapter} from '../field-adapter';
import {CommonSelectWithFieldAdapterProps, LoadOptionsFuncType} from '../field-types';
import {withErrorIndicator} from '../with-error-indicator';
import {withAutocompleteAdapter} from './with-autocomplete-adapter';

export interface AutocompleteSelectWithFieldAdapterProps<T> extends CommonSelectWithFieldAdapterProps<T> {
    loadOptions: LoadOptionsFuncType<T>;
    isChangeKeyboard?: boolean;
}

function AutocompleteSelectWithFieldAdapterComponent<T>(props: AutocompleteSelectWithFieldAdapterProps<T>) {
    const {value, options} = props;

    const selectedOption = options && options.length ?
        options.find(option => isEqual(value?.value, option.value) || value === option.value) :
        undefined;

    return (
        <AutocompleteSelect
            {...props}
            selectedOption={selectedOption || (value === null ? undefined : value)}
        />
    );
}

export const AutocompleteSelectWithFieldAdapter =
    fieldAdapter(withErrorIndicator(withAutocompleteAdapter(AutocompleteSelectWithFieldAdapterComponent)));
