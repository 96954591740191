import {setServerTime} from '@app/store/actions';
import {COMPANIES} from '@app/store/model-constants';
import {
    InsCompany2Status,
    InsuranceCompany,
    LinkData,
    RegionName,
    UserAuthStatus,
} from '@app/types';
import {createActionFactory, DispatchType, AsyncActionType} from '@app/utils/redux';
import {
    getLinkData,
    getRegionName,
} from '@modules/companies';
import {
    getHasInsCompanySecondChosen,
} from '@modules/companies/companies-api';
import {getAuthStatus} from '@app/utils/users-auth-status';
import {LinkDataInterface} from '@modules/contract';
import {executeAsyncAction} from '@modules/model/actions';
import {DEFAULT_SK_DOMAIN} from '@utils/constants/egarant-settings';
import {RequestError} from '@utils/request';
import {ActionTypes} from '@utils/types';

const companiesActionCreator = createActionFactory(COMPANIES);

export const setInsCompanyNull = companiesActionCreator<null>(ActionTypes.SET_INS_COMPANY_NULL);
export const setCompanies = companiesActionCreator<InsuranceCompany[]>(ActionTypes.SET_COMPANIES);
export const setFilterableCompanies = companiesActionCreator<InsuranceCompany[]>(ActionTypes.SET_FILTERABLE_COMPANIES);
export const setCompanyPremium = companiesActionCreator<number>(ActionTypes.SET_COMPANY_PREMIUM);
export const setSelectedCompany = companiesActionCreator<InsuranceCompany>(ActionTypes.SET_SELECTED_COMPANY);
export const setLinkData = companiesActionCreator<LinkDataInterface>(ActionTypes.SET_LINK);
export const setRegionName = companiesActionCreator<RegionName>(ActionTypes.SET_REGION_NAME);
export const setHasInsCompanySecondChosen = companiesActionCreator<InsCompany2Status>(
    ActionTypes.SET_HAS_INS_COMPANY_2_CHOSEN
);

export function loadLinkData(contractId: string): AsyncActionType {
    return (dispatch: DispatchType) => {
        const errorHandler = ({response}: RequestError) => {
            dispatch(setServerTime(response.headers.server_time));
        };

        return executeAsyncAction(dispatch, () => {
            const resultLoadLogo = getLinkData(contractId);

            return resultLoadLogo.then((data: LinkData & {serverTime: string}) => {
                const {serverTime, ...linkData} = data;
                const representativesLink = linkData ?
                    `${linkData.rsaBaseUrl}/all/?company=${linkData.insCompanyDikbmId}` +
                    `&addr=${linkData.vehicleUsageAreaFiasCode}&rate=${linkData.baseRate}` :
                    '#';
                dispatch(setLinkData({...linkData, representativesLink}));
                dispatch(setServerTime(serverTime));
            });
        }, errorHandler);
    };
}

export function loadRegionName(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        const resultLoadRegionName = getRegionName(contractId);

        return resultLoadRegionName.then((data: RegionName) => {
            dispatch(setRegionName(data));
        });
    };
}

export function loadHasInsCompanySecondChosen(contractId: string): AsyncActionType {
    return (dispatch: DispatchType) => executeAsyncAction(dispatch, () => {
        const resultHasInsCompanySecondChosen = getHasInsCompanySecondChosen(contractId);
        const lkStatus = getAuthStatus(contractId);

        return resultHasInsCompanySecondChosen.then((data: InsCompany2Status) => {
            if (
                !data.skDomain &&
                (data.isChosen || lkStatus === UserAuthStatus.ACTIVE)
            ) {
                dispatch(setHasInsCompanySecondChosen({
                    isChosen: true,
                    skDomain: DEFAULT_SK_DOMAIN,
                }));
            } else {
                dispatch(setHasInsCompanySecondChosen(data));
            }
        });
    });
}
