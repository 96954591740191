import * as React from 'react';

import {DayView} from './day-view';
import {MonthView} from './month-view';
import {YearView} from './year-view';

export enum ViewTypes {
    YEAR = 'Year',
    MONTH = 'Month',
    DAY = 'Day'
}

export const Views: {[type: string]: React.FunctionComponent<any> | React.ComponentClass<any>} = {
    Year: YearView,
    Month: MonthView,
    Day: DayView,
};
