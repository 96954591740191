import * as React from 'react';

export enum ModalPositionTypes {
    CENTER = 'CENTER',
    SIDE = 'SIDE'
}

export type ModalType = {
    type: string,
    component: React.ComponentClass<any, any> | React.FunctionComponent<any>,
    isClosable?: boolean,
    closeOnOverlay?: boolean,
    position?: ModalPositionTypes,
    isResizable?: boolean,
    initialWidth?: WIDTHS
};

export enum WIDTHS {
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL'
}
