import classNames from 'classnames/bind';
import * as React from 'react';

import {ValidationErrorIndicator} from '../../validation-error-indicator';

import * as styles from './with-error-indicator.scss';

type WithErrorIndicatorElementFn = (props: any) => React.ReactElement;

const cn = classNames.bind(styles);

export function withErrorIndicator(
    WrapperComponent: React.ComponentType<any>,
    isAlignedToCodeField: boolean = false
): WithErrorIndicatorElementFn {
    function WithErrorIndicator({
        errorMessage,
        isValid,
        disabled,
        width,
        ...restProps
    }: any) {
        return (
            <div
                className={cn('field-with-error-indicator')}
                style={{width}}
            >
                <WrapperComponent
                    {...restProps}
                    isValid={isValid || disabled}
                    disabled={disabled}
                    width={width}
                />
                {
                    !isValid && !disabled &&
                        (
                            <div
                                className={cn(isAlignedToCodeField && 'field-with-error-indicator__indicator-wrapper')}
                            >
                                <ValidationErrorIndicator messages={errorMessage} />
                            </div>
                        )
                }
            </div>
        );
    }

    WithErrorIndicator.displayName = `WithErrorIndicator(${WrapperComponent && (
        WrapperComponent.displayName || WrapperComponent.name
    )})`;

    return WithErrorIndicator;
}
