import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from '../table-layout.scss';

const cn = classNames.bind(styles);

interface HeaderProps {
    title: string,
    children?: React.ReactNode
}

export function Header({title, children}: HeaderProps) {
    return (
        <div className={cn('table-layout__header')}>
            <div className={cn('table-layout__title')}>{title}</div>
            {children}
        </div>
    );
}
