import classNames from 'classnames/bind';
import * as React from 'react';

import {AboutBuild} from '@app/pages/shared-pages/layout-page/components/footer/components/about-build';
import {Help} from '@app/pages/shared-pages/layout-page/components/footer/components/help';

import * as styles from './footer.scss';

const cn = classNames.bind(styles);

export function Footer() {
    return (
        <div className={cn('footer')}>
            <Help />
            <AboutBuild />
        </div>
    );
}
