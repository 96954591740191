import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from '../form-layout.scss';

const cn = classNames.bind(styles);

interface BodyProps {
    children: React.ReactNode
}

export function Body({
    children,
}: BodyProps) {
    const getKey = (index: number) => `row-${index}`;

    return (
        <>
            {React.Children.toArray(children).map((row, index) => (
                <div className={cn('form-layout__row')} key={getKey(index)}>{row}</div>
            ))}
        </>
    );
}
