import classNames from 'classnames/bind';
import * as React from 'react';

import {Scrollbars} from '../../scrollbars';

import * as styles from './modal-layout.scss';

const cn = classNames.bind(styles);

type NamedChildrenSlots = {
    content: React.ReactChild | React.ReactChild[];
    buttons?: React.ReactChild;
    footerContent?: React.ReactChild;
    closeButton?: React.ReactChild;
}

interface ModalLayoutProps {
    title?: string | JSX.Element;
    subTitle?: string;
    isRightAligned?: boolean;
    areButtonsRightAligned?: boolean;
    children: NamedChildrenSlots;
    width?: string | number;
    minHeight?: string | number,
    hasScrollbars?: boolean;
}

export function ModalLayout({
    children: {
        content,
        footerContent,
        buttons,
        closeButton,
    },
    title,
    subTitle,
    isRightAligned,
    areButtonsRightAligned,
    width = 'auto',
    minHeight = 'none',
    hasScrollbars = false,
}: ModalLayoutProps) {
    return (
        <div
            style={{width, minHeight}}
            className={cn(
                'modal-layout',
                isRightAligned && 'modal-layout--right-aligned'
            )}
        >
            {closeButton && <div className={cn('modal-layout__close-button')}>{closeButton}</div>}
            <div className={cn('modal-layout__header')}>
                <div className={cn('modal-layout__title')}>{title}</div>
                {subTitle && <div className={cn('modal-layout__sub-title')}>{subTitle}</div>}
            </div>
            <div className={cn('modal-layout__body')}>
                {hasScrollbars ? <Scrollbars>{content}</Scrollbars> : content}
            </div>
            <div className={cn('modal-layout__footer')}>
                {buttons && (
                    <div
                        className={
                            cn(
                                'modal-layout__buttons',
                                areButtonsRightAligned && 'modal-layout__buttons--right'
                            )
                        }
                    >
                        {buttons}
                    </div>
                )}
                {footerContent}
            </div>
        </div>
    );
}
