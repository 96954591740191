import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {loadFirstPageOpened} from '@app/pages/agreement-page/agreement-actions';
import {
    loadCaptchaPublicKey,
    setContractId,
} from '@app/store/actions';
import {feEgarantCapthcaEnableSelector} from '@modules/system';

import {AgreementPage} from './agreement-page';

const mapStateToProps = createStructuredSelector({
    feEgarantCapthcaEnable: feEgarantCapthcaEnableSelector,
});

const mapDispatchToProps = {
    setContractId,
    loadCaptchaPublicKey,
    loadFirstPageOpened,
};

export const AgreementPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementPage);
