import {userStateMock} from '@app/mocks';
import {UserInterface} from '@app/store/types';
import {
    PersonRegistrationData,
    UserAuthStatus,
} from '@app/types';
import {handleActions} from '@utils/redux';
import {ActionTypes} from '@utils/types';

import {USER} from '../model-constants';

const userHandler = handleActions(USER);

export const userReducer = userHandler({
    [ActionTypes.SET_USER]: (state: UserInterface, {payload}: {payload: PersonRegistrationData}) => ({
        ...state,
        userData: {
            ...payload,
        },
    }),

    [ActionTypes.SET_INITIALIZED_AUTH_TRUE]:
        (state: UserInterface) => ({
            ...state,
            authInitialized: true,
        }),

    [ActionTypes.SET_AUTHENTICATED]:
        (state: UserInterface, {payload}: {payload: UserAuthStatus}) => ({
            ...state,
            authenticated: payload,
        }),

    [ActionTypes.SET_HAS_USER]:
        (state: UserInterface, {payload}: {payload: boolean}) => ({
            ...state,
            hasUser: payload,
        }),

    [ActionTypes.SET_FORGOT_PASSWORD]:
        (state: UserInterface, {payload}: {payload: boolean}) => ({
            ...state,
            forgotPassword: payload,
        }),
}, userStateMock);
