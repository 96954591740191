import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {contractIdSelector} from '@modules/contract';
import {serverTimeSelector} from '@modules/system';

import {AboutBuild} from './about-build';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    serverTime: serverTimeSelector,
});

export const AboutBuildContainer = connect(
    mapStateToProps
)(AboutBuild);
