import classNames from 'classnames/bind';
import * as React from 'react';

import {isFunction} from '@utils/functions';

import {Column, SortCriteria} from '../data-table-types';
import {HeaderCell} from './header-cell';

import * as styles from './header-row.scss';

interface HeaderRowProps {
    columns: Column[];
    height: number;
    offsetX: number;
    rowWidth: number;
    width: number;
    sortCriteria: SortCriteria;
    onClick?: (name: string) => void;
    isSortableColumns?: boolean;
    hasColumnsButton?: boolean;
    updateColumnsStatuses?: (columnsStatuses: object) => void;
    configurableColumns?: Column[];
}

const cn = classNames.bind(styles);

export function HeaderRow({
    columns,
    offsetX = 0,
    height,
    rowWidth,
    width,
    sortCriteria = {},
    onClick,
    isSortableColumns,
    hasColumnsButton,
    updateColumnsStatuses,
    configurableColumns,
}: HeaderRowProps): JSX.Element {
    const containerStyle: any = {
        width: rowWidth,
        transform: `translate(-${offsetX}px, 0)`,
    };

    return (
        <div className={cn('header-row')} style={{width, height}}>
            <div className={cn('header-row__container')} style={containerStyle}>
                {columns.map(({
                    name,
                    header = '',
                    width,
                    hasNoPadding,
                    isCentered,
                    isSortable,
                }) => (
                    <HeaderCell
                        name={name}
                        hasNoPadding={hasNoPadding}
                        isCentered={isCentered}
                        isHeader
                        key={name}
                        value={isFunction(header) ? header() : header}
                        sortCriteria={sortCriteria}
                        onClick={onClick}
                        isSortable={isSortable}
                        width={width}
                        isPaddingLeft={isSortableColumns}
                        hasColumnsButton={hasColumnsButton}
                        updateColumnsStatuses={updateColumnsStatuses}
                        configurableColumns={configurableColumns}
                    />
                ))}
            </div>
        </div>
    );
}
