import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    confirmRegistration,
    loadReturnContract,
} from '@app/pages/registration-page/registration-actions';
import {linkDataSelector} from '@modules/companies';
import {
    isActiveRegisterBtnAwareSelector,
    isActiveRegisterBtnAgreeSelector,
    isLkBlockedSelector,
} from '@modules/navigation';
import {userDataSelector} from '@modules/user';
import {contractIdSelector} from '@modules/contract';

import {RegistrationNavigation} from './registration-navigation';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isActiveRegisterBtnAgree: isActiveRegisterBtnAgreeSelector,
    isActiveRegisterBtnAware: isActiveRegisterBtnAwareSelector,
    user: userDataSelector,
    linkData: linkDataSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    confirmRegistration,
    loadReturnContract,
};

export const RegistrationNavigationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationNavigation);
