import {createSelector, OutputSelector, Selector} from 'reselect';

import {STAGE} from '@app/store/model-constants';
import {AppStateInterface, StageInterface} from '@app/store/types';
import {AppStages} from '@utils/constants';

const rootSelector: Selector<any, StageInterface> = (state: AppStateInterface) => state[STAGE];

export const currentStageSelector: OutputSelector<
    StageInterface,
    string,
    (state: StageInterface) => string
    > = createSelector(rootSelector, (stage: StageInterface) => stage.currentStage);

export const expirationTimeSelector: OutputSelector<
    StageInterface,
    number,
    (state: StageInterface) => number
    > = createSelector(rootSelector, (stage: StageInterface) => stage.timer?.expirationTime);

export const secondsLeftSelector: OutputSelector<
    StageInterface,
    number,
    (state: StageInterface) => number
    > = createSelector(rootSelector, (stage: StageInterface) => stage.timer?.secondsLeft);

export const flkCodeSelector: OutputSelector<
    StageInterface,
    string,
    (state: StageInterface) => string
    > = createSelector(rootSelector, (stage: StageInterface) => stage.timer?.flkCode);

export const timerTypeSelector: OutputSelector<
    StageInterface,
    AppStages | string,
    (state: StageInterface) => AppStages | string
    > = createSelector(rootSelector, (stage: StageInterface) => stage.timer?.timerType);

export const isAutoAcceptHeartbeatStoppedSelector: OutputSelector<
    StageInterface,
    boolean,
    (state: StageInterface) => boolean
    > = createSelector(rootSelector, (stage: StageInterface) => stage.isAutoAcceptHeartbeatStopped);

export const isTimerLoginExpiredSelector: OutputSelector<
    StageInterface,
    boolean,
    (state: StageInterface) => boolean
    > = createSelector(rootSelector, (stage: StageInterface) => stage.isTimerLoginExpired);
