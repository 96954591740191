export enum AppStages {
    LOGIN = 'LOGIN', // пользователь регистрирует ЛК и/или войти в систему
    APPLICATION_DIRECTION = 'APPLICATION_DIRECTION', // перед отправкой на проверку в АИС ОСАГО 2.0
    VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS', // ожидание ответа от ОСАГО
    CHANGE_APPLICATION_DATA = 'CHANGE_APPLICATION_DATA', // редактирование данных страхователем
    SCAN_ATTACHMENT = 'SCAN_ATTACHMENT', // предоставления страхователем копий документов
    PAYMENT = 'PAYMENT', // страхователь должен перейти на страницу оплаты
    AUTO_ACCEPT_TIMEOUT = 'AUTO_ACCEPT_TIMEOUT', // время, отведенное СК2 для проверки сканов
    AUTO_ACCEPT_TIMEOUT__REJECTED = 'AUTO_ACCEPT_TIMEOUT__REJECTED' // промежуточная стадия без таймера при REJECTED
}

export const SHOWN_TIMERS = [
    AppStages.LOGIN,
    AppStages.APPLICATION_DIRECTION,
    AppStages.CHANGE_APPLICATION_DATA,
    AppStages.SCAN_ATTACHMENT,
    AppStages.PAYMENT,
    AppStages.AUTO_ACCEPT_TIMEOUT,
];
