import {getMismatchMessage} from '@app/store/actions';
import {
    DriverData,
    Mismatch,
    MismatchData,
    MismatchField,
    MismatchElement,
    ValidationData,
    ValidationEntities,
    PersonType,
    DocumentTypes,
} from '@app/types';
import {handbookFieldNameAll} from '@utils/fields-group-enabled';
import {ADDRESS, ADDRESS_TEXT} from '@utils/constants';

import {
    MappedMismatchField,
    MappedValidationInterface,
} from './validation-types';

function aggregateMessages(warningData: MappedMismatchField[]) {
    return warningData.reduce((acc: MappedMismatchField[], mismatch: MappedMismatchField) => {
        if (acc.find(({fieldName}) => fieldName === mismatch.fieldName)) {
            const index = acc.findIndex(({fieldName}) => fieldName === mismatch.fieldName);
            acc[index].fieldMessages = mismatch.isCritical ?
                [...mismatch.fieldMessages, ...acc[index].fieldMessages] :
                [...acc[index].fieldMessages, ...mismatch.fieldMessages];
            acc[index].isCritical = acc[index].isCritical || mismatch.isCritical;
            return acc;
        }
        return [...acc, mismatch];
    }, []);
}

const mapValidationKit = (dataKit: MismatchData[], kitName?: string): MappedMismatchField[] => {
    const resultArray = dataKit.reduce((result: MappedMismatchField[], {
        code,
        message,
        isCritical,
        mismatchFields,
    }: MismatchData) => {
        const mismatchFieldsMessages = mismatchFields.reduce((acc: MappedMismatchField[], {
            fieldPath,
            fieldValue,
        }: MismatchField) => {
            const dashedName = fieldPath.replace(/\./g, '-');
            const fieldName = kitName ? `${kitName}-${dashedName}` : dashedName;
            const fieldMessages = [getMismatchMessage(code, message)];

            if (![...handbookFieldNameAll].includes(fieldName) &&
                ![ADDRESS, ADDRESS_TEXT].includes(fieldPath)) {
                return acc;
            }
            return [
                ...acc,
                {
                    fieldName,
                    fieldValue,
                    fieldMessages,
                    isCritical: isCritical || false,
                },
            ];
        }, []);
        return aggregateMessages([...result, ...mismatchFieldsMessages]);
    }, []);

    return aggregateMessages(resultArray);
};

const mapValidationDriversArray = (drivers: DriverData[]): MappedMismatchField[] => {
    const driversArray: MappedMismatchField[][] = [];
    drivers.forEach(({driverIndex, driver}: DriverData) => {
        const driverPrefix = `driversList-${driverIndex}`;
        driversArray.push([
            ...mapValidationKit(driver, driverPrefix),
        ]);
    });

    return [].concat(...driversArray);
};

export const mapFetchedInsurerValidation = ({
    validationResultFields,
}: ValidationData) => ({
    step1: mapValidationKit(validationResultFields[ValidationEntities.INSURER], ValidationEntities.INSURER),
    step2: <MappedMismatchField[]>[],
    step3: <MappedMismatchField[]>[],
});

export const mapFetchedOwnerValidation = ({
    validationResultFields,
}: ValidationData) => ({
    step1: mapValidationKit(validationResultFields[ValidationEntities.OWNER], ValidationEntities.OWNER),
    step2: mapValidationKit(validationResultFields[ValidationEntities.VEHICLE]),
    step3: mapValidationDriversArray(validationResultFields[ValidationEntities.DRIVERS]),
});

export const mapFetchedValidation = ({
    validationResultFields,
}: ValidationData) => ({
    step1: [
        ...mapValidationKit(validationResultFields[ValidationEntities.OWNER], ValidationEntities.OWNER),
        ...mapValidationKit(validationResultFields[ValidationEntities.INSURER], ValidationEntities.INSURER),
    ],
    step2: mapValidationKit(validationResultFields[ValidationEntities.VEHICLE]),
    step3: mapValidationDriversArray(validationResultFields[ValidationEntities.DRIVERS]),
});

export function errorMapFetchedValidation(mismatches: Mismatch[]) {
    const step1: MappedMismatchField[] = [];
    const step2: MappedMismatchField[] = [];
    const step3: MappedMismatchField[] = [];

    const getStep = (
        fieldName: string,
        fieldValue: string,
        fieldMessages: string[],
        isCritical: boolean,
    ) => ({
        fieldName,
        fieldValue,
        fieldMessages,
        isCritical,
    });

    mismatches.forEach((mismatch: Mismatch) => {
        const {
            mismatchElements,
            message,
            isCritical,
            code,
        } = mismatch;

        if (mismatchElements && mismatchElements.length > 0) {
            mismatchElements.forEach(({
                attributePath,
                attributeValue,
            }: MismatchElement) => {
                const fieldName = attributePath.replace(/\.|]\.|\[/g, '-');
                const kitName = fieldName.split('-')[0];
                const fieldNameNoKitName = fieldName.slice(kitName.length + 1);
                const fieldMessages: string[] = [getMismatchMessage(code, message)];
                if (kitName === PersonType.INSURER || kitName === PersonType.OWNER) {
                    step1.push(getStep(
                        fieldName,
                        attributeValue,
                        fieldMessages,
                        isCritical
                    ));
                }

                if (kitName === PersonType.VEHICLE) {
                    step2.push(getStep(
                        fieldNameNoKitName,
                        attributeValue,
                        fieldMessages,
                        isCritical
                    ));
                }

                if (kitName === PersonType.DRIVERS) {
                    step3.push(getStep(
                        fieldNameNoKitName,
                        attributeValue,
                        fieldMessages,
                        isCritical
                    ));
                }
            });
        }
    });
    return {
        step1,
        step2,
        step3,
    };
}

export function mapValidationInsurerOrOwner(
    parsedValidationKitBefore: MappedValidationInterface,
    parsedValidationKitAfter: MappedValidationInterface
) {
    return {
        step1: [
            ...parsedValidationKitBefore.step1,
            ...parsedValidationKitAfter.step1,
        ],
        step2: [
            ...parsedValidationKitBefore.step2,
            ...parsedValidationKitAfter.step2,
        ],
        step3: [
            ...parsedValidationKitBefore.step3,
            ...parsedValidationKitAfter.step3,
        ],
    };
}


export const countStepsToValidateEntities = (validationResultEntities: string[]) => {
    const stepsSet = new Set();
    validationResultEntities.forEach(entity => {
        switch (entity) {
            case PersonType.INSURER:
            case PersonType.OWNER:
                return stepsSet.add(1);
            case PersonType.VEHICLE:
                return stepsSet.add(2);
            case PersonType.DRIVERS:
                return stepsSet.add(3);
            default:
                return null;
        }
    });
    return Array
        .from(stepsSet)
        .map(index => Number(index))
        .sort((a, b) => a - b);
};

export const countStepsToValidateScans = (scansToEdit: string[]) => {
    const stepsSet = new Set();
    scansToEdit.forEach(entity => {
        switch (entity) {
            case DocumentTypes.INSURER_DOCUMENT:
            case DocumentTypes.OWNER_DOCUMENT:
                return stepsSet.add(1);
            case DocumentTypes.VEHICLE_DOCUMENT:
            case DocumentTypes.DIAGNOSTIC_CARD:
                return stepsSet.add(2);
            case DocumentTypes.DRIVERS_DOCUMENTS:
                return stepsSet.add(3);
            default:
                return null;
        }
    });
    return Array
        .from(stepsSet)
        .map(index => Number(index))
        .sort((a, b) => a - b);
};
