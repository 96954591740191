import classNames from 'classnames/bind';
import * as React from 'react';

import {getModal} from '../modals-registry';
import {
    ModalPositionTypes,
    ModalType,
} from '../modals-types';
import {SideModal} from './side-modal';

import * as styles from './modal.scss';

const cn = classNames.bind(styles);

interface ModalProps {
    type: string;
    onClose: () => void;
    params: object;
}

export class Modal extends React.PureComponent<ModalProps> {
    modalContainerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const modalContainer = this.modalContainerRef.current;

        if (modalContainer) {
            modalContainer.focus();
        }
    }

    renderModal(CurrentModal: ModalType) {
        const {
            params,
            onClose,
        } = this.props;
        const {
            component: Component,
            position,
            isResizable,
            initialWidth,
        } = CurrentModal;

        if (position === ModalPositionTypes.SIDE) {
            return (
                <SideModal
                    params={params}
                    component={Component}
                    onClose={onClose}
                    initialWidth={initialWidth}
                    isResizable={isResizable}
                />
            );
        }

        return (
            <div className={cn('center-modal')}>
                <div className={cn('center-modal__content')}>
                    <Component {...params} />
                </div>
            </div>
        );
    }

    render() {
        const {type} = this.props;
        const CurrentModal = getModal(type);

        if (!CurrentModal) {
            return null;
        }

        return (
            <div
                role="menu"
                className={cn('modal-container')}
                ref={this.modalContainerRef}
                tabIndex={0}
            >
                {this.renderModal(CurrentModal)}
            </div>
        );
    }
}
