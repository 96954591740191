import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/components/navigation/navigation.dic.json';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';
import {isDisabledInterface} from '@utils/isDisabled';

const t = createTranslator(dictionary);

interface ButtonCancelInterface {
    contractId: string;
    isAmountInLimits: boolean;
    isLkBlocked: boolean;
    isDisabled: (props: isDisabledInterface) => boolean;
    rejectPayment: (contractId: string) => void;
}

export function ButtonCancel({
    contractId,
    isAmountInLimits,
    isLkBlocked,
    isDisabled,
    rejectPayment,
}: ButtonCancelInterface) {
    return (
        <Button
            label={t('navigation.button.cancel')}
            danger={isAmountInLimits}
            disabled={isDisabled({isAmountInLimits, isLkBlocked})}
            onClick={() => (rejectPayment(contractId))}
        />
    );
}
