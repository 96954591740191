import classNames from 'classnames/bind';
import * as React from 'react';

import {
    getMonthFromDate,
    formatDate,
    DateFormats,
} from '@utils/date';
import {noop} from '@utils/functions';
import {createTranslator} from '@utils/i18n';

import {ViewTypes} from '../views';
import {SwitchButton} from './switch-button';

import * as dictionary from '../calendar.dic.json';
import * as styles from './switcher.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const switchView = (
    onYearsMove: (forward: boolean) => void,
    onMonthMove: (forward: boolean) => void,
    view: ViewTypes,
    forward: boolean
) => {
    if (view === ViewTypes.DAY) {
        onMonthMove(forward);
    } else {
        onYearsMove(forward);
    }
};

const handleLeftButtonClick = (
    onYearsMove: (forward: boolean) => void,
    onMonthMove: (forward: boolean) => void,
    view: ViewTypes
) => {
    switchView(onYearsMove, onMonthMove, view, false);
};

const handleRightButtonClick = (
    onYearsMove: (forward: boolean) => void,
    onMonthMove: (forward: boolean) => void,
    view: ViewTypes
) => {
    switchView(onYearsMove, onMonthMove, view, true);
};

export interface SwitcherProps {
    viewDate: Date;
    onYearsMove?: (forward: boolean) => void;
    onMonthMove?: (forward: boolean) => void;
    setView?: (view: ViewTypes) => void;
    view: ViewTypes;
}

export const Switcher = ({
    viewDate,
    view,
    setView = noop,
    onYearsMove = noop,
    onMonthMove = noop,
}: SwitcherProps) => (
    <div className={cn('switcher')}>
        {view !== ViewTypes.MONTH && (
            <SwitchButton
                onClick={() => handleLeftButtonClick(onYearsMove, onMonthMove, view)}
            />
        )}
        {view === ViewTypes.DAY ?
            (
                <span className={cn('switcher__switch-label')}>
                    <span
                        role="button"
                        className={cn('switcher__switch-label-month')}
                        onClick={() => setView(ViewTypes.MONTH)}
                    >
                        {getMonthFromDate(viewDate)}
                    </span>
                    <span
                        role="button"
                        className={cn('switcher__switch-label-year')}
                        onClick={() => setView(ViewTypes.YEAR)}
                    >
                        {formatDate(viewDate, DateFormats.YYYY)}
                    </span>
                </span>
            ) :
            (
                <span className={cn('switcher__switch-label')}>
                    {t(`switcher.select.${view.toLowerCase()}`)}
                </span>
            )}
        {view !== ViewTypes.MONTH && (
            <SwitchButton
                forward
                onClick={() => handleRightButtonClick(onYearsMove, onMonthMove, view)}
            />
        )}
    </div>
);
