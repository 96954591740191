import classNames from 'classnames/bind';
import * as React from 'react';

import {Notification} from './components';
import {TIMER_INTERVAL, NOTIFICATION_TIME_OUT, POSITION_TOP} from './notifications-constants';
import {NotificationData} from './notifications-types';

import * as styles from './notifications.scss';

const cn = classNames.bind(styles);

export interface NotificationsProps {
    /** array of all notifications */
    notifications: NotificationData[]
    /** action for removing specific notification */
    removeNotification: (id?: string | number) => void
}

export class NotificationsRoot extends React.Component<NotificationsProps> {
    timerId: number;

    componentDidMount() {
        this.runTimer();
    }

    componentWillUnmount() {
        window.clearTimeout(this.timerId);
    }

    runTimer = () => {
        this.timerId = window.setTimeout(() => {
            this.checkNotificationsLifeTime();
            this.runTimer();
        }, TIMER_INTERVAL);
    };

    checkNotificationsLifeTime = () => {
        const {notifications, removeNotification} = this.props;

        notifications.forEach(({id, creationDate}: NotificationData) => {
            const currentTimestamp = Date.now();
            const delta = currentTimestamp - creationDate;

            if (delta >= NOTIFICATION_TIME_OUT) {
                removeNotification(id);
            }
        });
    };

    handleClose = (id: NotificationData['id']) => {
        const {removeNotification} = this.props;
        removeNotification(id);
    };


    render() {
        const {notifications} = this.props;
        const style = {top: POSITION_TOP};

        return (
            <div
                className={cn('notifications-root')}
                style={style}
            >
                {notifications.map(({type, message, id}: NotificationData) => (
                    <Notification
                        key={id}
                        type={type}
                        message={message}
                        id={id}
                        onClose={this.handleClose}
                    />
                ))}
            </div>
        );
    }
}
