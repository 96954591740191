import classNames from 'classnames/bind';
import * as React from 'react';

import {getElementPosition} from '@utils/dom';

import * as styles from './hint.scss';

const cn = classNames.bind(styles);

export function Hint({text}: {text: string}) {
    const [isActiveHint, setIsActiveHint] = React.useState<boolean>(false);
    const myRef = React.useRef<HTMLDivElement>();

    const handleClickOutside = (e: MouseEvent) => {
        if (!myRef.current.contains(e.target as Node)) {
            setIsActiveHint(false);
        }
    };

    const handleClickInside = () => setIsActiveHint(!isActiveHint);

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const isLeftPosition = myRef.current &&
        getElementPosition(myRef.current).left < document.documentElement.clientWidth / 2;

    return (
        <div className={cn('hint')}>
            <div
                ref={myRef}
                onClick={handleClickInside}
                className={cn('hint__help')}
                role="button"
            >
                ?
            </div>
            {
                isActiveHint && (
                    <div
                        className={cn('hint__help-hint', isLeftPosition ? 'hint__help-hint--left' : '')}
                    >
                        {text}
                    </div>
                )
            }
        </div>
    );
}
