import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './label.scss';

const cn = classNames.bind(styles);

type LabelProps = {
    value?: string
};

export function Label({value}: LabelProps) {
    return (
        <span className={cn('label')}>
            {value}
        </span>
    );
}
