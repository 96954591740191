import classNames from 'classnames/bind';
import * as React from 'react';

import {
    KeyCode,
    EventName,
} from '@utils/constants';
import {
    addDays,
    getNowDate,
    getWeekdays,
    MonthDateType,
} from '@utils/date';
import {noop} from '@utils/functions';

import {DaysRow} from './days-row';
import {WeekdaysPanel} from './week-days';

import * as styles from './day-view.scss';

const cn = classNames.bind(styles);

const isMonthsAndYearsEqual = (
    firstDate: Date,
    secondDate: Date
): boolean => (
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
);

interface DayViewProps {
    days: Array<MonthDateType>;
    viewDate: Date;
    focusedDate: Date;
    firstSelectedDate?: Date;
    secondSelectedDate?: Date;
    onFocus: (date: Date, isNeededToUpdateViewDate: boolean) => void;
    onChange: (date: Date, dateChangedByKeyboard?: boolean) => void;
    isActive: boolean;
    availableDates?: [Date, Date?]
}

export class DayView extends React.PureComponent<DayViewProps> {
    static defaultProps: DayViewProps = {
        days: [],
        viewDate: getNowDate(),
        focusedDate: getNowDate(),
        onFocus: noop,
        onChange: noop,
        isActive: false,
    };

    componentDidMount() {
        document.addEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    setFocusedDate = (newDate: Date) => {
        const {viewDate, onFocus} = this.props;
        const isNeededToUpdateViewDate = !isMonthsAndYearsEqual(viewDate, newDate);

        onFocus(newDate, isNeededToUpdateViewDate);
    };

    handleKeyDown = ({keyCode}: KeyboardEvent) => {
        const {
            onChange,
            focusedDate,
            isActive,
        } = this.props;

        if (!isActive) {
            return;
        }

        switch (keyCode) {
            case KeyCode.RIGHT_ARROW: {
                const newDate = addDays(focusedDate, 1);

                this.setFocusedDate(newDate);
                break;
            }
            case KeyCode.LEFT_ARROW: {
                const newDate = addDays(focusedDate, -1);

                this.setFocusedDate(newDate);
                break;
            }
            case KeyCode.DOWN_ARROW: {
                const newDate = addDays(focusedDate, 7);

                this.setFocusedDate(newDate);
                break;
            }
            case KeyCode.UP_ARROW: {
                const newDate = addDays(focusedDate, -7);

                this.setFocusedDate(newDate);
                break;
            }
            case KeyCode.ENTER: {
                if (focusedDate) {
                    onChange(focusedDate, true);
                }
                break;
            }
            default:
                break;
        }
    };

    renderDayRows = () => {
        const {
            days,
            viewDate,
            focusedDate,
            firstSelectedDate,
            secondSelectedDate,
            onChange,
            availableDates,
        } = this.props;
        const rows = [];
        const daysInWeek = 7;

        for (let i = 0; i < days.length; i += daysInWeek) {
            rows.push(
                <DaysRow
                    key={i}
                    days={days.slice(i, i + daysInWeek)}
                    viewDate={viewDate}
                    focusedDate={focusedDate}
                    firstSelectedDate={firstSelectedDate}
                    secondSelectedDate={secondSelectedDate}
                    onFocus={this.setFocusedDate}
                    onChange={onChange}
                    availableDates={availableDates}
                />
            );
        }

        return rows;
    };

    render() {
        return (
            <div className={cn('day-view')}>
                <WeekdaysPanel weekdays={getWeekdays()} />
                {this.renderDayRows()}
            </div>
        );
    }
}
