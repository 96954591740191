import classNames from 'classnames/bind';
import * as React from 'react';

import {InsuranceCompany} from '@app/types';
import {LinkDataInterface} from '@modules/contract';
import {Button} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import {NavigationErrorBeforeContract} from '../navigation-error-before-contract';

import * as dictionary from '../../navigation.dic.json';
import * as styles from '../../navigation.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface CompaniesNavigationInterface {
    insCompany: InsuranceCompany;
    contractId: string;
    confirmSelectedCompany: (
        contractId: string,
        insCompany: InsuranceCompany,
        linkData: LinkDataInterface
    ) => void;
    linkData: LinkDataInterface;
    isLkBlocked: boolean;
}

export function CompaniesNavigation({
    insCompany,
    contractId,
    confirmSelectedCompany,
    linkData,
    isLkBlocked,
}: CompaniesNavigationInterface) {
    return (
        <div className={cn('navigation-wrapper')}>
            <NavigationErrorBeforeContract />
            <Button
                label={t('navigation.button.confirm')}
                primary
                disabled={!insCompany || isLkBlocked}
                onClick={() => confirmSelectedCompany(contractId, insCompany, linkData)}
            />
        </div>
    );
}
