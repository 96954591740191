import {Step3FormInterface} from '@app/pages/contract-page/pages/step-3/step3-types';
import {Drivers} from '@app/types';
import {toContractDateFormat, toLocalTimeZone} from '@utils/formatters/date';

export const convertContractDriversToFormValues = (drivers: Drivers): Step3FormInterface => {
    const driver0 = drivers.driversList[0] && {
        'driversList-0-driverIndex': drivers.driversList[0]?.driverIndex,
        'driversList-0-meetsWithPolicyholderData': drivers.driversList[0]?.meetsWithPolicyholderData,
        'driversList-0-person-name': drivers.driversList[0]?.person?.name,
        'driversList-0-person-secondName': drivers.driversList[0]?.person?.secondName,
        'driversList-0-person-surname': drivers.driversList[0]?.person?.surname,
        'driversList-0-person-birthdate': toLocalTimeZone(drivers.driversList[0]?.person?.birthdate),
        'driversList-0-person-phoneNumber': drivers.driversList[0]?.person?.phoneNumber,
        'driversList-0-person-noSecondName': drivers.driversList[0]?.person?.noSecondName,
        'driversList-0-driverLicense-series': drivers.driversList[0]?.driverLicense?.series,
        'driversList-0-driverLicense-number': drivers.driversList[0]?.driverLicense?.number,
        'driversList-0-driverLicense-startDate': toLocalTimeZone(drivers.driversList[0]?.driverLicense?.startDate),
    };
    const driver1 = drivers.driversList[1] && {
        'driversList-1-driverIndex': drivers.driversList[1]?.driverIndex,
        'driversList-1-meetsWithPolicyholderData': drivers.driversList[1]?.meetsWithPolicyholderData,
        'driversList-1-person-name': drivers.driversList[1]?.person?.name,
        'driversList-1-person-secondName': drivers.driversList[1]?.person?.secondName,
        'driversList-1-person-surname': drivers.driversList[1]?.person?.surname,
        'driversList-1-person-birthdate': toLocalTimeZone(drivers.driversList[1]?.person?.birthdate),
        'driversList-1-person-phoneNumber': drivers.driversList[1]?.person?.phoneNumber,
        'driversList-1-person-noSecondName': drivers.driversList[1]?.person?.noSecondName,
        'driversList-1-driverLicense-series': drivers.driversList[1]?.driverLicense?.series,
        'driversList-1-driverLicense-number': drivers.driversList[1]?.driverLicense?.number,
        'driversList-1-driverLicense-startDate': toLocalTimeZone(drivers.driversList[1]?.driverLicense?.startDate),
    };
    const driver2 = drivers.driversList[2] && {
        'driversList-2-driverIndex': drivers.driversList[2]?.driverIndex,
        'driversList-2-meetsWithPolicyholderData': drivers.driversList[2]?.meetsWithPolicyholderData,
        'driversList-2-person-name': drivers.driversList[2]?.person?.name,
        'driversList-2-person-secondName': drivers.driversList[2]?.person?.secondName,
        'driversList-2-person-surname': drivers.driversList[2]?.person?.surname,
        'driversList-2-person-birthdate': toLocalTimeZone(drivers.driversList[2]?.person?.birthdate),
        'driversList-2-person-phoneNumber': drivers.driversList[2]?.person?.phoneNumber,
        'driversList-2-person-noSecondName': drivers.driversList[2]?.person?.noSecondName,
        'driversList-2-driverLicense-series': drivers.driversList[2]?.driverLicense?.series,
        'driversList-2-driverLicense-number': drivers.driversList[2]?.driverLicense?.number,
        'driversList-2-driverLicense-startDate': toLocalTimeZone(drivers.driversList[2]?.driverLicense?.startDate),
    };
    const driver3 = drivers.driversList[3] && {
        'driversList-3-driverIndex': drivers.driversList[3]?.driverIndex,
        'driversList-3-meetsWithPolicyholderData': drivers.driversList[3]?.meetsWithPolicyholderData,
        'driversList-3-person-name': drivers.driversList[3]?.person?.name,
        'driversList-3-person-secondName': drivers.driversList[3]?.person?.secondName,
        'driversList-3-person-surname': drivers.driversList[3]?.person?.surname,
        'driversList-3-person-birthdate': toLocalTimeZone(drivers.driversList[3]?.person?.birthdate),
        'driversList-3-person-phoneNumber': drivers.driversList[3]?.person?.phoneNumber,
        'driversList-3-person-noSecondName': drivers.driversList[3]?.person?.noSecondName,
        'driversList-3-driverLicense-series': drivers.driversList[3]?.driverLicense?.series,
        'driversList-3-driverLicense-number': drivers.driversList[3]?.driverLicense?.number,
        'driversList-3-driverLicense-startDate': toLocalTimeZone(drivers.driversList[3]?.driverLicense?.startDate),
    };
    return {
        noLimits: drivers.noLimits,
        ...driver0,
        ...driver1,
        ...driver2,
        ...driver3,
    };
};

const dateStep3FormFields: Array<keyof Step3FormInterface> = [
    'driversList-0-driverLicense-startDate',
    'driversList-1-driverLicense-startDate',
    'driversList-2-driverLicense-startDate',
    'driversList-3-driverLicense-startDate',
    'driversList-0-person-birthdate',
    'driversList-1-person-birthdate',
    'driversList-2-person-birthdate',
    'driversList-3-person-birthdate',
];

const prepareDateValuesForTransfer = (values: Step3FormInterface) => (
    dateStep3FormFields.reduce((form: Step3FormInterface, field: keyof Step3FormInterface) => {
        if (values[field]) {
            return {...form, [field]: toContractDateFormat(values[field] as string)};
        }
        return form;
    }, {} as Step3FormInterface)
);

export const prepareFormValuesForTransfer = (values: Step3FormInterface) => Object.entries({
    ...values,
    ...prepareDateValuesForTransfer(values),
}).reduce((acc, [key, value]) => ({
    ...acc,
    [`drivers-${key}`]: value,
}), {});
