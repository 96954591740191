
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    updateUploadedFilesStatuses,
    uploadFile,
    toggleUploadedFilesBtn,
} from '@app/store/actions';
import {
    uploadedDocumentFilesSelector,
    documentsTableDataSelector,
} from '@modules/contract';
import {currentStageSelector} from '@modules/stage';
import {isLkBlockedSelector} from '@modules/navigation';
import {isScanSendingInitiatedSelector} from '@modules/validation';

import {DocumentsTable} from './documents-table';

const mapStateToProps = createStructuredSelector({
    uploadedDocumentFiles: uploadedDocumentFilesSelector,
    documents: documentsTableDataSelector,
    currentStage: currentStageSelector,
    isScanSendingInitiated: isScanSendingInitiatedSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    onFileLoad: uploadFile,
    updateUploadedFilesStatuses,
    toggleUploadedFilesBtn,
};

export const DocumentsTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsTable);
