import {FormState} from 'final-form';
import * as React from 'react';
import {FormSpy} from 'react-final-form';

import {Step1FormInterfaceKeys} from '@app/pages/contract-page/pages/step-1/step1-types';
import {Step2FormInterfaceKeys} from '@app/pages/contract-page/pages/step-2/step2-types';
import {formatSnilsNumber} from '@app/utils/formatters';
import {MappedMismatchField} from '@modules/validation';
import {toLocalTimeZone} from '@utils/formatters/date';
import {isEqual} from 'lodash';
import {MONTHS} from '../../pages/step-2/components/right-part/use-periods';

export const insurerAddressFields = [
    'insurer-addressFias-region',
    'insurer-addressFias-district',
    'insurer-addressFias-city',
    'insurer-addressFias-locality',
    'insurer-addressFias-street',
    'insurer-addressFias-house',
    'insurer-addressFias-building',
    'insurer-addressFias-structure',
    'insurer-addressFias-room',
    'insurer-person-snils',
];

const ownerAddressFields = [
    'owner-addressFias-region',
    'owner-addressFias-district',
    'owner-addressFias-city',
    'owner-addressFias-locality',
    'owner-addressFias-street',
    'owner-addressFias-house',
    'owner-addressFias-building',
    'owner-addressFias-structure',
    'owner-addressFias-room',
    'owner-person-snils',
];

export const insurerAddressTextFields = [
    'insurer-addressFias-house',
    'insurer-addressFias-building',
    'insurer-addressFias-structure',
    'insurer-addressFias-room',
];

const ownerAddressTextFields = [
    'owner-addressFias-house',
    'owner-addressFias-building',
    'owner-addressFias-structure',
    'owner-addressFias-room',
];

const insurerAddressLinkedFields = [
    'insurer-addressFias-locality',
    'insurer-addressFias-street',
];

const ownerAddressLinkedFields = [
    'owner-addressFias-locality',
    'owner-addressFias-street',
];

type setFieldDataType = (name: string, data: { warning: string[] }) => void;

const setWarnings = (
    dirtyFields: FormState<any>['dirtyFields'],
    setFieldData: setFieldDataType,
    fields: string[],
    textFields: string[],
    message: string,
    textMessage: string
) => {
    const warning: string[] = [];
    const textWarning: string[] = [];
    if (message && !fields.some(fieldName => Object.keys(dirtyFields).includes(fieldName))) {
        warning.push(message);
        textWarning.push(message);
    }
    if (textMessage && !textFields.some(fieldName => Object.keys(dirtyFields).includes(fieldName))) {
        textWarning.push(textMessage);
    }
    fields.forEach(fieldName => setFieldData(fieldName, {
        warning: warning.length ? warning : undefined,
    }));
    textFields.forEach(fieldName => setFieldData(fieldName, {
        warning: textWarning.length ? textWarning : undefined,
    }));
};

const handleAddressWarnings = (
    dirtyFields: FormState<any>['dirtyFields'],
    setFieldData: setFieldDataType,
    insurerMessage: string,
    ownerMessage: string,
    insurerTextMessage: string,
    ownerTextMessage: string,
) => {
    setWarnings(
        dirtyFields,
        setFieldData,
        insurerAddressFields,
        insurerAddressTextFields,
        insurerMessage,
        insurerTextMessage
    );
    setWarnings(
        dirtyFields,
        setFieldData,
        ownerAddressFields,
        ownerAddressTextFields,
        ownerMessage,
        ownerTextMessage
    );
};

const removeWarnings = (
    dirtyFields: FormState<any>['dirtyFields'],
    setFieldData: setFieldDataType,
    addressLinkedFields: string[],
) => {
    if (Object.keys(dirtyFields).some(name => addressLinkedFields.includes(name))) {
        addressLinkedFields.forEach(fieldName => setFieldData(fieldName, {
            warning: undefined,
        }));
    }
};

const handleAddressLinkedWarnings = (dirtyFields: FormState<any>['dirtyFields'], setFieldData: setFieldDataType) => {
    removeWarnings(dirtyFields, setFieldData, insurerAddressLinkedFields);
    removeWarnings(dirtyFields, setFieldData, ownerAddressLinkedFields);
};

interface WarningEngineInterface {
    setFieldData: setFieldDataType;
    warningData: MappedMismatchField[];
    insurerMessage: string;
    ownerMessage: string;
    insurerTextMessage: string;
    ownerTextMessage: string;
}

function mapWarningData(warningData: MappedMismatchField[]) {
    return (warningData || []).map((item: MappedMismatchField) => {
        const snilsValues: string[] = [
            Step1FormInterfaceKeys.INSURER_PERSON_SNILS,
            Step1FormInterfaceKeys.OWNER_PERSON_SNILS,
        ];

        const changeValue: string[] = [
            Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION,
            Step2FormInterfaceKeys.IS_USED_WITH_TRAILER,
        ];

        const periodValues: string[] = [
            Step2FormInterfaceKeys.USE_PERIOD_1,
        ];

        if (snilsValues.includes(item.fieldName)) {
            return {
                fieldMessages: item.fieldMessages,
                fieldName: item.fieldName,
                fieldValue: formatSnilsNumber(String(item.fieldValue)),
                isCritical: item.isCritical,
            };
        }

        if (changeValue.includes(item.fieldName)) {
            return {
                fieldMessages: item.fieldMessages,
                fieldName: item.fieldName,
                fieldValue: String(Boolean(item.fieldValue)),
                isCritical: item.isCritical,
            };
        }

        if (periodValues.includes(item.fieldName)) {
            return {
                fieldMessages: item.fieldMessages,
                fieldName: item.fieldName,
                fieldValue: Number(item.fieldValue) >= MONTHS.THREE ? Number(item.fieldValue) : null,
                isCritical: item.isCritical,
            };
        }

        if (item.fieldName.toLowerCase().includes('date')) {
            return {
                fieldMessages: item.fieldMessages,
                fieldName: item.fieldName,
                fieldValue: toLocalTimeZone(item.fieldValue as string),
                isCritical: item.isCritical,
            };
        }

        return {
            fieldMessages: item.fieldMessages,
            fieldName: item.fieldName,
            fieldValue: item.fieldValue,
            isCritical: item.isCritical,
        };
    });
}

export const WarningEngine = ({
    setFieldData,
    warningData,
    insurerMessage,
    ownerMessage,
    insurerTextMessage,
    ownerTextMessage,
}: WarningEngineInterface) => (
    <>
        {warningData?.length > 0 && (
            <FormSpy
                subscription={{values: true, visited: true, dirtyFields: true}}
                onChange={({values, dirtyFields}) => {
                    handleAddressWarnings(
                        dirtyFields,
                        setFieldData,
                        insurerMessage,
                        ownerMessage,
                        insurerTextMessage,
                        ownerTextMessage
                    );
                    mapWarningData(warningData).forEach((item: MappedMismatchField) => {
                        const isGeneralFieldWithError = values[item.fieldName] === item.fieldValue?.toString();
                        const isTransdekraLabelWithError = values[item.fieldName]?.label === String(item.fieldValue);
                        const isFieldFlatValueWithError = (
                            typeof values[item.fieldName]?.value !== 'object' &&
                            String(values[item.fieldName]?.value)
                        ) === String(item.fieldValue);
                        const isErrorValueNullOrUndefined = item.fieldValue === null && !values[item.fieldName];
                        const isVehicleModelKeyWithError = values[item.fieldName]?.value?.modelKey === item.fieldValue;
                        const isMakerModelWithError = isEqual(values[item.fieldName]?.value, item.fieldValue);

                        return setFieldData(item.fieldName, {
                            warning: (
                                isGeneralFieldWithError || // general fields (primitives)
                                isTransdekraLabelWithError || // transdekra
                                isFieldFlatValueWithError || // dictionaries, year
                                isErrorValueNullOrUndefined || // null or undefined (required)
                                isVehicleModelKeyWithError || // model error
                                isMakerModelWithError // maker and model
                            ) ? item.fieldMessages : undefined,
                        });
                    });
                    handleAddressLinkedWarnings(dirtyFields, setFieldData);
                }}
            />
        )}
    </>
);
