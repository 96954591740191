import * as React from 'react';

import {Step3FormInterface} from '@app/pages/contract-page/pages/step-3/step3-types';
import {CheckboxField, TextField} from '@ui-kit';
import {shouldBeDisabled} from '@utils/should-be-disabled';

export interface SecondNameFieldInterface {
    cn: (fn: string) => string;
    driverId: number;
    textFieldLabel: string;
    checkboxLabel: string;
    mutator: (field: string, value: string) => void;
    values: Step3FormInterface;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function SecondNameField({
    cn,
    driverId,
    textFieldLabel,
    checkboxLabel,
    mutator,
    values,
    scansEditFields,
    isLkBlocked,
}: SecondNameFieldInterface) {
    const name = `driversList-${driverId}-person-secondName` as keyof Step3FormInterface;
    const checkboxName = `driversList-${driverId}-person-noSecondName` as keyof Step3FormInterface;
    const [textFieldValue, changeTextFieldValue] = React.useState(values[checkboxName] ? null : values[name] as string);
    const [disableField, changeDisabling] = React.useState(values[checkboxName] as boolean);

    const handleTextFieldChange = (value: string) => {
        changeTextFieldValue(value);
    };

    const handleCheckboxChange = () => {
        mutator(name, disableField ? textFieldValue : null);
        changeDisabling(!disableField);
    };

    return (
        <div className={cn('driver__form-checkbox-wrapper')}>
            <div className={cn('driver__form-field')}>
                <TextField
                    name={name}
                    label={textFieldLabel}
                    disabled={shouldBeDisabled({
                        name,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                        forceDisabled: disableField,
                    })}
                    onChange={handleTextFieldChange}
                />
            </div>
            <div className={cn('driver__form-field-checkbox')}>
                <CheckboxField
                    name={checkboxName}
                    label={checkboxLabel}
                    onChange={handleCheckboxChange}
                    isDisabled={shouldBeDisabled({
                        name,
                        listFieldError: scansEditFields,
                        isLkBlocked,
                    })}
                />
            </div>
        </div>
    );
}
