import classNames from 'classnames/bind';
import * as React from 'react';

import {FormBody, FormButtons} from '.';

import * as styles from './form-layout.scss';

const cn = classNames.bind(styles);

interface FormLayoutProps {
    title?: string,
    subTitle?: string,
    children: React.ReactElement | React.ReactElement[],
    withCustomMargins?: boolean
}

export function FormLayout({
    title,
    subTitle = '',
    children,
    withCustomMargins = false,
}: FormLayoutProps) {
    const childrenArray = React.Children.toArray(children);

    return (
        <div className={cn('form-layout', !withCustomMargins && 'form-layout--default-margins')}>
            {title && (
                <div className={cn('form-layout__header')}>
                    <div className={cn('form-layout__title')}>{title}</div>
                    <div className={cn('form-layout__sub-title')}>{subTitle}</div>
                </div>
            )}
            <div className={cn('form-layout__wrapper')}>
                {childrenArray.filter(child => (child.type === FormBody))}
            </div>
            {childrenArray.find(child => (child.type === FormButtons)) && (
                <div className={cn('form-layout__buttons-group')}>
                    {childrenArray.filter(child => (child.type === FormButtons))}
                </div>
            )}
        </div>
    );
}
