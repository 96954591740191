import * as React from 'react';
import {Provider} from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import {NoMatchPage} from '@app/pages/no-match-page';
import {AuthenticatedArea} from '@modules/auth';
import {ModalRoot} from '@modules/modals';
import {Overlay} from '@modules/overlay';
import {PublicArea} from '@modules/public-area';
import {TabsGuard} from '@modules/tabs-guard';

import {bootstrapApp} from './bootstrap';
import {RestorePage} from './pages/restore-page';
import {LayoutPage} from './pages/shared-pages/layout-page';
import {reducer} from './store';
import {
    AGREEMENT_PAGE,
    COMPANIES_LIST_PAGE,
    REGISTRATION_PAGE,
    LOGIN_PAGE,
    CONTRACT_PAGE,
    RESTORE_PAGE,
    RESULT_PAGE,
    FAIL_PAGE,
    URL_HOME,
} from './urls';

const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancer: typeof compose = devToolsCompose || compose;
const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
bootstrapApp(store);

export const App = (): JSX.Element => (

    <Provider store={store}>
        <Router>
            <Route
                path={URL_HOME}
                render={routeProps => (
                    <LayoutPage {...routeProps}>
                        <Switch>
                            <Route
                                path={[
                                    `/${AGREEMENT_PAGE}/:contractId?`,
                                    `/${COMPANIES_LIST_PAGE}/:contractId?`,
                                    `/:skDomain?/${REGISTRATION_PAGE}/:contractId?`,
                                    `/:skDomain?/${LOGIN_PAGE}/:contractId?`,
                                ]}
                                render={routeProps => (
                                    <TabsGuard {...routeProps}>
                                        <PublicArea {...routeProps} />
                                    </TabsGuard>
                                )}
                            />
                            <Route
                                path={`/:skDomain?/${RESTORE_PAGE}/:contractId?`}
                                render={routeProps => (
                                    <TabsGuard {...routeProps}>
                                        <RestorePage {...routeProps} />
                                    </TabsGuard>
                                )}
                            />
                            <Route
                                path={[
                                    `/:skDomain?/${CONTRACT_PAGE}/:contractId?/:step?`,
                                    `/:skDomain?/${RESULT_PAGE}/:contractId?`,
                                    `/:skDomain?/${FAIL_PAGE}/:contractId?`,
                                ]}
                                render={routeProps => (
                                    <TabsGuard {...routeProps}>
                                        <AuthenticatedArea {...routeProps} />
                                    </TabsGuard>
                                )}
                            />
                            <Route component={NoMatchPage} />
                        </Switch>
                    </LayoutPage>
                )}
            />
        </Router>
        <Overlay />
        <ModalRoot />
    </Provider>
);
