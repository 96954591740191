import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import {createTranslator} from '@utils/i18n';

import * as styles from './help.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export function Help() {
    return (
        <div className={cn('help')}>
            <div className={cn('help__text')}>
                <span>{t('help.copyright')}</span>
            </div>
            <div className={cn('help__text')}>
                <span>{t('help.phone1')}</span>
            </div>
            <div className={cn('help__text')}>
                <span>{t('help.phone2')}</span>
            </div>
            <div className={cn('help__text')}>
                <span>{t('help.phone3')}</span>
            </div>
        </div>
    );
}
