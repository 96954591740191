import classNames from 'classnames/bind';
import * as React from 'react';

import {MonthDateType} from '@utils/date';
import {noop} from '@utils/functions';

import {Day} from './day';

import * as styles from './day-view.scss';

const cn = classNames.bind(styles);

interface DaysRowProps {
    days: Array<MonthDateType>;
    viewDate: Date;
    focusedDate: Date;
    firstSelectedDate?: Date;
    secondSelectedDate?: Date;
    onFocus?: (date: Date) => void;
    onChange?: (date: Date) => void;
    availableDates?: [Date, Date?]
}

export function DaysRow({
    days,
    viewDate,
    focusedDate,
    firstSelectedDate,
    secondSelectedDate,
    onChange,
    onFocus,
    availableDates,
}: DaysRowProps) {
    return (
        <div className={cn('day-view__days-row')}>
            {days.map((day, index) => (
                <Day
                    key={`${day.day}-${day.month}`}
                    dayInfo={day}
                    viewDate={viewDate}
                    focusedDate={focusedDate}
                    firstSelectedDate={firstSelectedDate}
                    secondSelectedDate={secondSelectedDate}
                    onChange={onChange}
                    onFocus={onFocus}
                    isFirst={index === 0}
                    isLast={index === days.length - 1}
                    isDayOff={(index === days.length - 1) || (index === days.length - 2)}
                    availableDates={availableDates}
                />
            ))}
        </div>
    );
}

DaysRow.defaultProps = {
    days: [],
    onChange: noop,
    onFocus: noop,
};
