import {UserAuthStatus} from '@app/types';

const setAuthStatus = (contractId: string, status: UserAuthStatus) => {
    // для FireFox Private необходимо хранить статус авторизации в localStorage
    // для Edge необходимо sessionStorage
    // так как навигация на другой ресурс внутри одной вкладки вдет к полной отчистке localStorage или sessionStorage

    sessionStorage.setItem(contractId, status);
    localStorage.setItem(contractId, status);
};

const removeAuthStatus = (contractId: string) => {
    sessionStorage.removeItem(contractId);
    localStorage.removeItem(contractId);
};

const getAuthStatus = (contractId: string) => sessionStorage.getItem(contractId) || localStorage.getItem(contractId);

export {
    setAuthStatus,
    removeAuthStatus,
    getAuthStatus,
};
