import {
    createSelector,
    Selector,
    OutputSelector,
} from 'reselect';

import {Mismatch} from '@app/types';
import {MODALS} from '@app/store/model-constants';
import {
    AppStateInterface,
    ModalsInterface,
} from '@app/store/types';

const rootSelector: Selector<any, ModalsInterface> = (state: AppStateInterface) => state[MODALS];

export const modalsSelector: OutputSelector<ModalsInterface, string[], (state: ModalsInterface) => string[]> =
    createSelector(rootSelector, (modals: ModalsInterface) => modals.modalsStack);

export const modalsCountSelector: OutputSelector<ModalsInterface, number, (state: ModalsInterface) => number> =
    createSelector(rootSelector, (modals: ModalsInterface) => modals.modalsStack.length);

export const activeModalSelector: OutputSelector<ModalsInterface, string, (state: ModalsInterface) => string> =
    createSelector(
        rootSelector,
        (modals: ModalsInterface) => modals.modalsStack[modals.modalsStack.length - 1]
    );

export const isBadRequestModalShownSelector: OutputSelector<
    ModalsInterface,
    boolean,
    (state: ModalsInterface) => boolean
> = createSelector(
    rootSelector,
    (modals: ModalsInterface) => modals.isBadRequestModalShown
);

export const mismatchDataSelector: OutputSelector<
    ModalsInterface,
    Mismatch[],
    (state: ModalsInterface) => Mismatch[]
    > = createSelector(
        rootSelector,
        (modals: ModalsInterface) => modals.mismatchData
    );
