import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {currentStepSelector} from '@modules/navigation';
import {currentStageSelector} from '@modules/stage';
import {isDataEditedSelector} from '@modules/validation';

import {ButtonsStep4} from './buttons-step4';

const mapStateToProps = createStructuredSelector({
    currentStage: currentStageSelector,
    currentStep: currentStepSelector,
    isDataEdited: isDataEditedSelector,
});

export const ButtonsStep4Container = connect(mapStateToProps)(ButtonsStep4);
