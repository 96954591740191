
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {updateUploadedFilesStatuses} from '@app/store/actions';
import {documentsTableDataSelector} from '@modules/contract';
import {isLkBlockedSelector} from '@modules/navigation';
import {
    currentStageSelector,
    isAutoAcceptHeartbeatStoppedSelector,
} from '@modules/stage';
import {
    isScanEditingInitiatedSelector,
    isScanSendingInitiatedSelector,
} from '@modules/validation';

import {ContractCheck} from './contract-check';

const mapStateToProps = createStructuredSelector({
    currentStage: currentStageSelector,
    documents: documentsTableDataSelector,
    isScanSendingInitiated: isScanSendingInitiatedSelector,
    isScanEditingInitiated: isScanEditingInitiatedSelector,
    isAutoAcceptHeartbeatStopped: isAutoAcceptHeartbeatStoppedSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    updateUploadedFilesStatuses,
};

export const ContractCheckContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContractCheck);
