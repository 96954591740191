import {
    InsCompany2Status,
    InsuranceCompany,
    InsuranceCompanyPremiumResponse,
    InsuranceCompanyReserveResponse,
    LinkData,
    RegionName,
} from '@app/types';
import {
    API_V1_EGARANT_CONTRACT_UI,
    API_V1_EGARANT_SK_SPREADER_UI,
    API_V2_EGARANT_SK_SPREADER_UI,
    getData,
    postData,
} from '@utils/api';
import {NAME, PREMIUM} from '@utils/constants';

const INSURANCE_COMPANIES = 'insuranceCompanies';
const RESERVE = 'reserve';
const LINK = 'link';
const REGION = 'region';
const RETURN_CONTRACT = 'returnContract';
const HAS_INS_COMPANY_2_CHOSEN = 'hasInsCompany2Chosen';

export function getInsuranceCompanies(contractId: string, captchaToken: string): Promise<InsuranceCompany[]> {
    const INSURANCE_COMPANIES_URL = `${API_V2_EGARANT_SK_SPREADER_UI}/${contractId}/${INSURANCE_COMPANIES}`;

    const headers = captchaToken ? {captchaToken} : {};

    return getData(INSURANCE_COMPANIES_URL, {headers});
}

export function getInsuranceCompanyPremium(contractId: string): Promise<InsuranceCompanyPremiumResponse> {
    const PREMIUM_URL = `${API_V1_EGARANT_SK_SPREADER_UI}/${contractId}/${INSURANCE_COMPANIES}/${PREMIUM}`;
    return getData(PREMIUM_URL);
}

export function reserveContract(
    contractId: string,
    companyId: string,
): Promise<InsuranceCompanyReserveResponse> {
    const RESERVE_URL = `${API_V1_EGARANT_SK_SPREADER_UI}/${contractId}/${INSURANCE_COMPANIES}/${companyId}/${RESERVE}`;
    return postData(RESERVE_URL, {});
}

export function getLinkData(contractId: string): Promise<LinkData & {serverTime: string}> {
    const LINK_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${LINK}`;
    return getData(LINK_URL, {}, ({data, headers}) => ({
        ...data,
        serverTime: headers.server_time,
    }));
}

export function getRegionName(contractId: string): Promise<RegionName> {
    const REGION_NAME_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${REGION}/${NAME}`;
    return getData(REGION_NAME_URL);
}

export function returnContract(contractId: string): Promise<null> {
    const RETURN_CONTRACT_URL = `${API_V1_EGARANT_SK_SPREADER_UI}/${contractId}/${RETURN_CONTRACT}`;
    return postData(RETURN_CONTRACT_URL, {});
}

export function getHasInsCompanySecondChosen(contractId: string): Promise<InsCompany2Status> {
    const HAS_INS_COMPANY_2_CHOSEN_URL = `${API_V2_EGARANT_SK_SPREADER_UI}/${contractId}/${HAS_INS_COMPANY_2_CHOSEN}`;
    return getData(HAS_INS_COMPANY_2_CHOSEN_URL);
}
