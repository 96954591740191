import {PersonType, DocumentTypes} from '@app/types';

type handbookFieldNameInterface = {
    insurer: string[];
    owner: string[];
    vehicle: string[];
    drivers: string[][];
    driversMeet: string[][];
}

const driverIndexesAll = [0, 1, 2, 3];

const handbookFieldNameDriversMeet = driverIndexesAll.map((driverIndex: number) => [
    `driversList-${driverIndex}-driverLicense-series`,
    `driversList-${driverIndex}-driverLicense-number`,
    `driversList-${driverIndex}-driverLicense-startDate`,
]);

const handbookFieldNameDrivers = driverIndexesAll.map((driverIndex: number) => [
    `driversList-${driverIndex}-person-name`,
    `driversList-${driverIndex}-person-secondName`,
    `driversList-${driverIndex}-person-surname`,
    `driversList-${driverIndex}-person-birthdate`,
    `driversList-${driverIndex}-person-noSecondName`,
    `driversList-${driverIndex}-driverLicense-series`,
    `driversList-${driverIndex}-driverLicense-number`,
    `driversList-${driverIndex}-driverLicense-startDate`,
]);

const handbookFieldName: handbookFieldNameInterface = {
    insurer: [
        'insurer-addressFias-region',
        'insurer-addressFias-district',
        'insurer-addressFias-city',
        'insurer-addressFias-locality',
        'insurer-addressFias-street',
        'insurer-addressFias-house',
        'insurer-addressFias-building',
        'insurer-addressFias-structure',
        'insurer-addressFias-room',
        'insurer-person-snils',
    ],
    owner: [
        'owner-person-name',
        'owner-person-secondName',
        'owner-person-surname',
        'owner-person-birthdate',
        'owner-person-noSecondName',
        'owner-legalPerson-INN',
        'owner-legalPerson-fullName',
        'owner-legalPersonDocument-documentType',
        'owner-legalPersonDocument-documentSeries',
        'owner-legalPersonDocument-documentNumber',
        'owner-legalPersonDocument-accountingDate',
        'owner-personDocument-docType',
        'owner-personDocument-docSeries',
        'owner-personDocument-docNumber',
        'owner-person-snils',
    ],
    vehicle: [
        'bodyNumber',
        'maker',
        'diagnosticCard-dateEnd',
        'diagnosticCard-number',
        'document-dateGiven',
        'document-documentType',
        'insuranceStartDate',
        'isOnTheWayToRegistration',
        'isUsedWithTrailer',
        'maxWeight',
        'model',
        'numberPlate',
        'otherMaker',
        'otherModel',
        'power',
        'registrationCountry',
        'schassisNumber',
        'seatsAmount',
        'usePeriod1',
        'usePeriod1EndDate',
        'usePeriod1StartDate',
        'usePurpose',
        'vin',
        'year',
        'document-documentType',
        'document-series',
        'document-number',
        'document-dateGiven',
        'diagnosticCard-dateEnd',
        'diagnosticCard-number',
        'insuranceStartTime',
        'usePeriod2',
        'usePeriod3',
        'typeCategory',
        'isShortInsurancePolicy',
    ],
    drivers: handbookFieldNameDrivers,
    driversMeet: handbookFieldNameDriversMeet,
};

export const handbookFieldNameAll = [
    ...handbookFieldName.insurer,
    ...handbookFieldName.owner,
    ...handbookFieldName.vehicle,
    ...handbookFieldName.drivers[0],
    ...handbookFieldName.drivers[1],
    ...handbookFieldName.drivers[2],
    ...handbookFieldName.drivers[3],
];

const getDriverIndex = (currentDriver: string) => Number(currentDriver.split('.')[1]);
export const listFieldScansEnabled = (
    errorDocuments: string[],
    driverMeets: number
) => (
    errorDocuments.reduce((sum: string[], current: string): string[] => {
        switch (current) {
            case DocumentTypes.INSURER_DOCUMENT:
                return [...sum, ...handbookFieldName.insurer];
            case DocumentTypes.OWNER_DOCUMENT:
                return [...sum, ...handbookFieldName.owner];
            case DocumentTypes.VEHICLE_DOCUMENT:
                return [...sum, ...handbookFieldName.vehicle];
            case DocumentTypes.DIAGNOSTIC_CARD:
                return [...sum, ...handbookFieldName.vehicle];
            case (current.match(/driversDocuments/) || {}).input:
                return [
                    ...sum,
                    ...(driverMeets === getDriverIndex(current) ?
                        handbookFieldName.driversMeet[getDriverIndex(current)] :
                        handbookFieldName.drivers[getDriverIndex(current)]
                    ),
                ];
            default:
                return sum;
        }
    }, []));

export const listFieldEntitiesEnabled = (
    errorEntities: string[],
    driverIndexes: number[],
    driverMeets?: number
) => (errorEntities.reduce((sum: string[], current: string) => {
    switch (current) {
        case PersonType.INSURER:
        case PersonType.OWNER:
        case PersonType.VEHICLE:
            return [...sum, ...handbookFieldName[current]];
        case PersonType.DRIVERS:
            return [...sum, ...driverIndexes.reduce((accumulator: string[], current: number) => (
                [
                    ...accumulator,
                    ...(current === driverMeets ?
                        handbookFieldName.driversMeet[current] :
                        handbookFieldName.drivers[current]
                    ),
                ]), [])];
        default:
            return sum;
    }
}, []));
