import {
    ContractDictionaries,
    ContractInterface,
    ContractStepsFormDataInterface,
} from '@app/store/types';
import {
    Insurer,
    Drivers,
    Owner,
    OwnerLawTypes,
    Vehicle,
    CalculationData,
} from '@app/types';
import {DocumentFile} from '@modules/contract';
import {handleActions} from '@utils/redux';
import {ActionTypes} from '@utils/types';

import {CONTRACT} from '../model-constants';

const getEmptyDriver = () => ({
    driverIndex: 0,
    meetsWithPolicyholderData: false,
    person: {
        name: '',
        secondName: '',
        surname: '',
        birthdate: '',
        phoneNumber: '',
        noSecondName: false,
    },
    driverLicense: {
        series: '',
        number: '',
        startDate: '',
    },
});

const contractInitialState: ContractInterface = {
    contractId: null,
    stepsFormData: {
        insurer: <Insurer>{},
        owner: <Owner>{
            ownerType: OwnerLawTypes.PHYSICAL,
        },
        drivers: <Drivers>{
            noLimits: false,
            driversList: [getEmptyDriver()],
        },
        vehicle: <Vehicle>{},
        addresses: {
            insurerFullAddress: '',
            ownerFullAddress: '',
        },
    },
    isAmountInLimits: true,
    driverMeets: -1,
    uploadedDocumentFiles: [],
    isScanFileUploading: false,
    dictionaries: {
        identityCardTypes: [],
        organizationRegDocTypes: [],
        vehicleDocumentTypes: [],
        vehiclePurposeTypes: [],
    },
    isNotFirstLoadData: false,
};

const contractHandler = handleActions(CONTRACT);

export const contractReducer = contractHandler({
    [ActionTypes.SET_CONTRACT_ID]: (state: ContractInterface, {payload}: {
        payload: string
    }) => ({
        ...state,
        contractId: payload,
    }),

    [ActionTypes.SET_CONTRACT_DATA]: (state: ContractInterface, {payload}: {
        payload: Partial<ContractStepsFormDataInterface>
    }) => ({
        ...state,
        stepsFormData: {...state.stepsFormData, ...payload},
    }),

    [ActionTypes.SET_DRIVER_MEETS]: (state: ContractInterface, {payload}: {payload: number}) => ({
        ...state, driverMeets: payload,
    }),

    [ActionTypes.SET_NO_LIMIT_DRIVER]: (state: ContractInterface, {payload}: {payload: boolean}) => ({
        ...state,
        stepsFormData: {
            ...state.stepsFormData,
            drivers: {
                ...state.stepsFormData.drivers,
                noLimits: payload,
            },
        },
    }),

    [ActionTypes.SET_UPLOADED_FILES]: (state: ContractInterface, {payload}: {payload: DocumentFile[]}) => ({
        ...state,
        uploadedDocumentFiles: [...payload],
    }),

    [ActionTypes.SET_IS_SCAN_FILE_UPLOADING]: (state: ContractInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isScanFileUploading: payload,
    }),

    [ActionTypes.SET_CONTRACT_AMOUNT_IN_LIMITS]: (state: ContractInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isAmountInLimits: payload,
    }),

    [ActionTypes.SET_DICTIONARIES_DATA]: (state: ContractInterface, {payload}: {payload: ContractDictionaries}) => ({
        ...state,
        dictionaries: payload,
    }),

    [ActionTypes.SET_OWNER_TYPE]: (state: ContractInterface, {payload}: {payload: number}) => ({
        // TODO: надо будет переделать при интеграции с BE
        ...state, stepsFormData: {...state.stepsFormData, owner: {...state.stepsFormData.owner, ownerType: payload}},
    }),

    [ActionTypes.SET_NOT_FIRST_LOAD_DATA]: (state: ContractInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isNotFirstLoadData: payload,
    }),

    [ActionTypes.SET_CALCULATED_TABLE_DATA]: (state: ContractInterface, {payload}: {payload: CalculationData}) => ({
        ...state,
        calculatedTableData: payload,
    }),
}, contractInitialState);
