import classNames from 'classnames/bind';
import {utcToZonedTime, format} from 'date-fns-tz';
import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import {createTranslator} from '@utils/i18n';
import {DateFormats} from '@utils/date';

import * as styles from './about-build.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const TIME_ZONE = '+03:00'; // Europe/Moscow

interface AboutBuildInterface {
    contractId: string;
    serverTime: string;
}

export function AboutBuild({contractId, serverTime}: AboutBuildInterface) {
    const branch = process.env.BRANCH;
    const commitHash = process.env.COMMITHASH;
    const version = process.env.BUILD_NUMBER || `${process.env.VERSION}-local`;
    const buildDate = process.env.BUILD_DATE;
    const formattedServerTime = format(
        utcToZonedTime(new Date(serverTime), TIME_ZONE),
        DateFormats.YYYYMMDD_HHMMSS,
        {timeZone: TIME_ZONE}
    );

    return (
        <div className={cn('about-build')}>
            <div className={cn('about-build__text')}>
                <span>{t('about-build.line1', formattedServerTime, contractId || 'UNKNOWN', branch)}</span>
            </div>
            <div className={cn('about-build__text')}>
                <span>{t('about-build.line2', commitHash, version, buildDate)}</span>
            </div>
        </div>
    );
}
