import classNames from 'classnames/bind';
import * as React from 'react';

import {LinkData, Mismatch} from '@app/types';
import {Checkbox} from '@ui-kit';
import {createTranslator} from '@utils/i18n';

import * as dictionary from '../../registration-page.dic.json';
import * as styles from './consent-text.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface ConsentTextInterface {
    linkData: LinkData;
    isActiveRegisterBtnAgree: boolean;
    isActiveRegisterBtnAware: boolean
    setIsActiveRegisterBtnAgree: (value: Boolean) => void;
    setIActiveRegisterBtnAware: (value: Boolean) => void;
    confirmExpiredTimer: (flkCode: string) => void;
    flkCode: string;
    isLkBlocked: boolean;
    mismatchData: Mismatch[]
}

export function ConsentText({
    linkData,
    isActiveRegisterBtnAgree,
    isActiveRegisterBtnAware,
    setIsActiveRegisterBtnAgree,
    setIActiveRegisterBtnAware,
    confirmExpiredTimer,
    flkCode,
    isLkBlocked,
    mismatchData,
}: ConsentTextInterface) {
    if (isLkBlocked && !mismatchData) {
        confirmExpiredTimer(flkCode);
    }
    return (
        <div className={cn('consent-text')}>
            <div className={cn('consent-text__sub-container')}>
                <div className={cn('consent-text__wrapper-center')}>
                    <h2 className={cn('consent-text__header')}>
                        {t('registration-page-consent-header')}
                    </h2>

                    <div className={cn('consent-text__agree')}>
                        <Checkbox
                            label={t(
                                'registration-page-consent-text-agree',
                                linkData?.insCompanyName,
                                linkData?.insCompanyName
                            )}
                            id="checkbox-registration-agree"
                            checked={isActiveRegisterBtnAgree}
                            onChange={() => setIsActiveRegisterBtnAgree(!isActiveRegisterBtnAgree)}
                        />
                    </div>
                    <div className={cn('consent-text__agree')}>
                        <Checkbox
                            label={t('registration-page-consent-text-acquainted', linkData?.insCompanyName)}
                            id="checkbox-registration-acquainted"
                            checked={isActiveRegisterBtnAware}
                            onChange={() => setIActiveRegisterBtnAware(!isActiveRegisterBtnAware)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
