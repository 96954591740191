import {createSelector, OutputSelector, Selector} from 'reselect';

import {USER} from '@app/store/model-constants';
import {
    AppStateInterface,
    UserInterface,
} from '@app/store/types';
import {
    PersonRegistrationData,
    UserAuthStatus,
} from '@app/types';

const rootSelector: Selector<any, UserInterface> = (state: AppStateInterface) => state[USER];

export const userDataSelector: OutputSelector<UserInterface, PersonRegistrationData, (
    state: UserInterface
) => PersonRegistrationData> = createSelector(rootSelector, (user: UserInterface) => user.userData);

export const authInitializedSelector: OutputSelector<UserInterface, boolean, (state: UserInterface) => boolean> =
    createSelector(rootSelector, (user: UserInterface) => user.authInitialized);

export const authenticatedSelector: OutputSelector<UserInterface, UserAuthStatus, (
    state: UserInterface
) => UserAuthStatus> = createSelector(rootSelector, (user: UserInterface) => user.authenticated);

export const hasUserSelector: OutputSelector<UserInterface, boolean, (state: UserInterface) =>
    boolean> = createSelector(rootSelector, (user: UserInterface) => user.hasUser);

export const forgotPasswordSelector: OutputSelector<UserInterface, boolean, (state: UserInterface) =>
    boolean> = createSelector(rootSelector, (user: UserInterface) => user.forgotPassword);
