import * as React from 'react';

function linkify(text: string) {
    const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig;
    return text.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`);
}

interface DisplayTextWithUrlInterface {
    text: string
}

export const DisplayTextWithUrl = ({text}: DisplayTextWithUrlInterface) => (
    <div dangerouslySetInnerHTML={{
        __html: linkify(text),
    }}
    />
);
