import {UserInterface} from '@app/store/types';
import {
    ConclusionResponse,
    PaymentUrlResponse,
    PersonRegistrationData,
    UserAuth,
    UserAuthStatus,
    UserExists,
} from '@app/types';

export const SK_DOMAIN_MOCK = 'vsk';
export const LOGIN_MOCK = '79036400105';
export const PASSWORD_MOCK = 'habdhbashd';
export const CAPTCHA_TOKEN_MOCK = '03AERD8XrJLnwC9XQCRzbn4spLk0gKwzTol7bGPTr_Q1IZv7FuW_d41_FsgsKoLeASE50';
export const USER_AUTH_STATUS_ACTIVE_MOCK = UserAuthStatus.ACTIVE;
const PAYMENT_URL_MOCK = 'http://localhost:8000/fail/13345200-abe8-4f60-90c8-0d43c5f6c0f7';

export const personRegistrationDataMock: PersonRegistrationData = {
    birthdate: 'Thu Oct 10 00:00:00 UTC 2019',
    docNumber: '123456',
    docSeries: '6200',
    docType: '12',

    email: 'ivan.ivanov@accenture.com',
    name: 'ЮЛИЯ1',
    noSecondName: true,
    phoneNumber: '79036400105',
    secondName: 'АЛЕКСАНДРОВНА',
    surname: 'ПЕТРОВА1',
};

export const userStateMock: UserInterface = {
    userData: <PersonRegistrationData>{},
    authInitialized: false,
    authenticated: null,
    hasUser: null,
    forgotPassword: false,
};

export const responseCalculatedDataUserExistsMock: UserExists = {
    hasUser: false,
};

export const conclusionResponseMock: ConclusionResponse = {
    code: 1,
    message: 'Банк-эквайер не отвечает на запрос статуса заказа',
    policyNumber: 'XXX0123456789',
};

export const paymentUrlResponseMock: PaymentUrlResponse = {
    errorCode: 0,
    errorMessage: null,
    paymentUrl: PAYMENT_URL_MOCK,
};

export const userAuthMock: UserAuth = {
    status: USER_AUTH_STATUS_ACTIVE_MOCK,
};
