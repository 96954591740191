import classNames from 'classnames/bind';
import {MutableState, Mutator} from 'final-form';
import setFieldData from 'final-form-set-field-data';
import * as React from 'react';
import {Form} from 'react-final-form';

import {PortalRoot} from '@app/components';
import {WarningEngine} from '@app/pages/contract-page/components/warning-engine';
import {OptionsSendStep} from '@app/pages/contract-page/contract-page-actions';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {MONTHS, calculateEndOfPeriod} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods';
import {
    periodOptions,
} from '@app/pages/contract-page/pages/step-2/components/right-part/use-periods/use-period-selectors';
import {
    convertContractVehicleToFormValues,
    prepareFormValuesForTransfer,
} from '@app/pages/contract-page/pages/step-2/step2-convert-utils';
import {ContractDictionaries} from '@app/store/types';
import {OwnerLawTypes, Vehicle} from '@app/types';
import {dataTransferObjectFormatter} from '@app/utils/formatters';
import {formatTime} from '@app/utils/formatters/date';
import {createTranslator} from '@app/utils/i18n';
import {MappedMismatchField} from '@modules/validation';

import {LeftPart} from './components/left-part';
import {RightPart} from './components/right-part';
import {Step2FormInterface, Step2FormInterfaceKeys} from './step2-types';

import * as styles from './step2.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface Step1Interface {
    dictionaries: ContractDictionaries;
    contractId?: string;
    isLoadingContract: boolean;
    vehicle: Vehicle;
    warningData: MappedMismatchField[];
    sendStep?: (options: OptionsSendStep) => void;
    currentStep: number;
    stepAfterSubmit: number;
    stepsToValidate: number[];
    ownerType: OwnerLawTypes;
}

export function Step2({
    dictionaries,
    contractId,
    isLoadingContract,
    vehicle,
    warningData,
    sendStep,
    currentStep,
    stepAfterSubmit,
    stepsToValidate,
    ownerType,
}: Step1Interface) {
    const stepData: Step2FormInterface = React.useMemo(() => (
        convertContractVehicleToFormValues(vehicle, dictionaries)
    ), [vehicle, dictionaries]);

    const submittedOnce = React.useRef(false);

    const onSubmit = (values: Step2FormInterface) => {
        const preparedValues = prepareFormValuesForTransfer(values, ownerType, stepData);
        const {vehicle} = dataTransferObjectFormatter(preparedValues);

        if (stepAfterSubmit) {
            submittedOnce.current = true;
            sendStep({
                contractId,
                currentStep,
                nextStep: stepAfterSubmit,
                vehicle,
                stepsToValidate,
            });
        }
    };

    const setValue: Mutator = ([name, newValue], state: MutableState<Step2FormInterface>, {changeValue}) => {
        const resetUsePeriods = (newStartDateValue: string, newEndDateValue: string, isFollowRegistration: boolean) => {
            changeValue(state, Step2FormInterfaceKeys.INSURANCE_START_TIME, () => formatTime('00:00'));
            changeValue(state, Step2FormInterfaceKeys.INSURANCE_END_TIME, () => formatTime('23:59'));
            changeValue(state, Step2FormInterfaceKeys.INSURANCE_END_DATE, () => newEndDateValue);
            if (newValue) {
                changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_1, () => (
                    isFollowRegistration ? 0 : periodOptions.find(el => el.value === MONTHS.TWELVE)
                ));
            } else {
                changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_1, () => null);
            }
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_1_START_DATE, () => newStartDateValue);
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_1_END_DATE, () => newEndDateValue);
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_2, () => null);
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_2_START_DATE, () => null);
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_2_END_DATE, () => null);
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_3, () => null);
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_3_START_DATE, () => null);
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_3_END_DATE, () => null);
        };

        const {insuranceStartDate, isOnTheWayToRegistration, isShortInsurancePolicy} = state.lastFormState.values;
        let isFollowRegistration: boolean = null;
        let newEndDateValue: string = null;
        switch (name) {
            case Step2FormInterfaceKeys.INSURANCE_START_DATE:
                if (newValue) {
                    isFollowRegistration = isOnTheWayToRegistration === String(true);
                    newEndDateValue = calculateEndOfPeriod(
                        MONTHS.TWELVE,
                        new Date(newValue),
                        isFollowRegistration
                    ).toISOString();
                }

                if (isShortInsurancePolicy === String(false)) {
                    resetUsePeriods(newValue, newEndDateValue, isFollowRegistration);
                }

                break;
            case Step2FormInterfaceKeys.IS_ON_THE_WAY_TO_REGISTRATION:
                isFollowRegistration = newValue === String(true);
                newEndDateValue = calculateEndOfPeriod(
                    MONTHS.TWELVE,
                    new Date(insuranceStartDate),
                    isFollowRegistration
                ).toISOString();

                if (isShortInsurancePolicy === String(false)) {
                    resetUsePeriods(insuranceStartDate, newEndDateValue, isFollowRegistration);
                }

                break;
            default:
                break;
        }
        if (name !== Step2FormInterfaceKeys.INSURANCE_START_TIME) { // already changed with formatter
            changeValue(state, name, () => newValue);
        }

        if (isShortInsurancePolicy === String(true)) {
            changeValue(state, Step2FormInterfaceKeys.USE_PERIOD_1, () => null);
        }
    };

    return (
        <div className={cn('page')}>
            <div className={cn('page__sub-container')}>
                <div className={cn('page__wrapper-center')}>
                    <h2 className={cn('page__header')}>
                        {t('transport')}
                    </h2>
                </div>
            </div>
            <div className={cn('page__sub-container')}>
                <div className={cn('page__wrapper-center')}>
                    <Form
                        initialValues={stepData}
                        onSubmit={onSubmit}
                        mutators={{setFieldData, setValue}}
                        render={({
                            handleSubmit,
                            form: {mutators: {setFieldData, setValue}},
                            values,
                        }) => {
                            if (stepAfterSubmit) {
                                if (!submittedOnce.current) {
                                    onSubmit(values);
                                }
                            } else {
                                submittedOnce.current = false;
                            }
                            return (
                                <form onSubmit={handleSubmit} className={cn('page__columns')}>
                                    {!isLoadingContract && (
                                        <>
                                            <LeftPart mutator={setValue} values={values} />
                                            <RightPart mutator={setValue} values={values} />
                                        </>
                                    )}
                                    <PortalRoot />
                                    <WarningEngine setFieldData={setFieldData} warningData={warningData} />
                                </form>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
