import {restore} from '@app/modules/user';
import * as dictionaryLayout from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import {setForgotPassword, setIsRestoreCaptcha} from '@app/store/actions';
import {createTranslator} from '@app/utils/i18n';
import {confirm} from '@modules/common-modals';
import {executeAsyncAction} from '@modules/model/actions';
import {MODAL_WIDTH} from '@utils/constants/modal-width';
import {clearPhoneNumber, formatPhoneNumber} from '@utils/formatters';
import {DispatchType} from '@utils/redux';

import * as dictionary from './restore-page.dic.json';

const l = createTranslator(dictionaryLayout);
const t = createTranslator(dictionary);

export function confirmRestore(
    contractId: string,
    phoneNumber: string,
    captchaHash?: string
): DispatchType {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    return (dispatch: DispatchType) => executeAsyncAction(
        dispatch,
        () => restore(contractId, clearPhoneNumber(formattedPhoneNumber), captchaHash)
            .then(() => (
                dispatch(confirm({
                    title: l('confirm-modal-title'),
                    message: t('restore-page-confirm-modal-text', formattedPhoneNumber),
                    width: MODAL_WIDTH,
                    resultHandler: () => dispatch(setForgotPassword(false)),
                }))
            ))
            .catch(() => dispatch(setIsRestoreCaptcha(false)))
    );
}
