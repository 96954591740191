import classNames from 'classnames/bind';
import * as React from 'react';

import {PathGroup} from './path-group';
import {FillRule, GenericSvgIconProps} from './svg-icon-types';

import * as styles from './svg-icon.scss';

const cn = classNames.bind(styles);

export function SvgIcon({
    fillRule = FillRule.EVENODD,
    height = 0,
    noFill = true,
    noStroke = true,
    originalSize = 16,
    path,
    size = 16,
    transform = null,
    viewBoxHeight,
    viewBoxWidth,
    width,
}: GenericSvgIconProps) {
    const realWidth = width || size;
    const realHeight = height || size;
    return (
        <svg
            className={cn('svg-icon')}
            fillRule={fillRule}
            focusable="false"
            height={realHeight}
            viewBox={`0 0 ${viewBoxWidth || originalSize} ${viewBoxHeight || originalSize}`}
            width={realWidth}
        >
            <PathGroup
                noFill={noFill}
                noStroke={noStroke}
                path={path}
                transform={transform}
            />
        </svg>
    );
}
