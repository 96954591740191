import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    confirmEditData,
    loadScanEditingRequest,
} from '@app/pages/contract-page/contract-page-actions';
import {setStepsToValidate} from '@app/store/actions';
import {isLkBlockedSelector} from '@modules/navigation';
import {contractIdSelector} from '@modules/contract';
import {
    isScanEditingInitiatedSelector,
    isScanSendingInitiatedSelector,
    scansToEditSelector,
    stepsToValidateSelector,
} from '@modules/validation';

import {ButtonBackToEdit} from './button-back-to-edit';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isScanSendingInitiated: isScanSendingInitiatedSelector,
    isScanEditingInitiated: isScanEditingInitiatedSelector,
    scansToEdit: scansToEditSelector,
    stepsToValidate: stepsToValidateSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    confirmEditData,
    loadScanEditingRequest,
    setStepsToValidate,
};

export const ButtonBackToEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonBackToEdit);
