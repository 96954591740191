import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import * as styles from './year-view.scss';

const cn = classNames.bind(styles);

interface YearProps {
    year: number;
    onClick: () => void;
    isCurrent: boolean;
    isFocused: boolean;
    isSelected: boolean;
}

export function Year({
    year,
    onClick = noop,
    isFocused = false,
    isSelected = false,
    isCurrent = false,
}: YearProps) {
    return (
        <div
            role="button"
            className={cn('year-view__year-container')}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <span className={cn('year-view__year', {
                'year-view__year--current': isCurrent,
                'year-view__year--selected': isSelected,
                'year-view__year--focused': isFocused,
            })}
            >
                <span className={cn('year-view__label')}>{year}</span>
            </span>
        </div>
    );
}
