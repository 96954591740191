import classNames from 'classnames/bind';
import * as React from 'react';

import {createTranslator} from '@utils/i18n';

import * as dictionary from './error-message.dic.json';
import * as styles from './error-message.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface PaymentErrorMessageInterface {
    message: string;
}

export const PaymentErrorMessage = ({message}: PaymentErrorMessageInterface) => (
    <div className={cn('error-message')}>
        <h2 className={cn('error-message__header')}>
            {t('payment-error-message-title')}
        </h2>
        <p className={cn('error-message-content__top')}>
            {message}
        </p>
    </div>
);
