import * as React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {Overlay} from './components';
import {overlayVisibilitySelector} from './overlay-selectors';

const mapStateToProps = createStructuredSelector({
    visible: overlayVisibilitySelector,
});

export const OverlayContainer = connect(mapStateToProps)(Overlay);
