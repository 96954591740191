
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {setContractId, fetchUserInfo} from '@app/store/actions';
import {linkDataSelector} from '@modules/companies';
import {MatchParams} from '@utils/match-params';
import {DispatchType} from '@utils/redux';

import {PaymentResultPage} from './payment-result-page';

const mapStateToProps = createStructuredSelector({
    linkData: linkDataSelector,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
    passParams: ({contractId}: MatchParams['params']) => {
        dispatch(setContractId(contractId));
    },
    fetchUserData: (contractId: string) => dispatch(fetchUserInfo(contractId)),
});

export const PaymentResultPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentResultPage);
