import classNames from 'classnames/bind';
import {createBrowserHistory} from 'history';
import * as React from 'react';

import {Anchor} from '@ui-kit';
import {URL_BEFORE_404} from '@utils/constants';
import {createTranslator} from '@utils/i18n';

import * as dictionary from './no-match-page.dic.json';
import * as styles from './no-match-page.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

const browserHistory = createBrowserHistory();

interface NoMatchPageInterface {
    webSiteRSA: string;
}

export const NoMatchPage = ({webSiteRSA}: NoMatchPageInterface) => {
    const urlBefore404 = sessionStorage.getItem(URL_BEFORE_404);
    if (urlBefore404) {
        browserHistory.push(urlBefore404);
        sessionStorage.removeItem(URL_BEFORE_404);
    }

    return (
        <div className={cn('no-match-page')}>
            <img
                className={cn('no-match-page__img')}
                src="/assets/img/404.png"
                alt="404"
            />
            <div className={cn('no-match-page__text')}>
                {t('no-match-page-not-found')}
            </div>
            <div className={cn('no-match-page__text')}>
                {t('no-match-page-not-found-link-text')}
                <Anchor
                    link={webSiteRSA}
                    label={t('no-match-page-not-found-link-label')}
                />
            </div>
        </div>
    );
};
