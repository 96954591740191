import classNames from 'classnames/bind';
import {Mutator} from 'final-form';
import setFieldData from 'final-form-set-field-data';
import * as React from 'react';
import {Form} from 'react-final-form';

import {PortalRoot} from '@app/components';
import {WarningEngine} from '@app/pages/contract-page/components/warning-engine';
import {OptionsSendStep} from '@app/pages/contract-page/contract-page-actions';
import {
    convertContractPersonsToFormValues,
    postFiltrationOwner,
    prepareFormValuesForTransfer,
} from '@app/pages/contract-page/pages/step-1/step1-convert-utils';
import {ContractDictionaries} from '@app/store/types';
import {
    Insurer as ContractInsurerInterface,
    Owner as ContractOwnerInterface,
} from '@app/types';
import {dataTransferObjectFormatter} from '@app/utils/formatters';
import {MappedMismatchField} from '@modules/validation';

import {Owner} from './owner';
import {Policyholder} from './policyholder';
import {Step1FormInterface, FiasDataInterface} from './step1-types';

import * as styles from './step1.scss';

const cn = classNames.bind(styles);

export const getFiasData = (insurer: ContractInsurerInterface, owner: ContractOwnerInterface): FiasDataInterface => ({
    'insurer-addressFias-house': insurer?.addressFias?.house?.fiasId,
    'insurer-addressFias-room': insurer?.addressFias?.room?.fiasId,
    'owner-addressFias-house': owner?.addressFias?.house?.fiasId,
    'owner-addressFias-room': owner?.addressFias?.room?.fiasId,
});

interface Step1Interface {
    dictionaries: ContractDictionaries;
    contractId?: string;
    insurer: ContractInsurerInterface;
    owner: ContractOwnerInterface;
    isLoadingContract: boolean;
    sendStep?: (options: OptionsSendStep) => void;
    warningData: MappedMismatchField[];
    currentStep: number;
    stepAfterSubmit: number;
    stepsToValidate: number[];
}

export function Step1({
    dictionaries,
    contractId,
    insurer,
    owner,
    isLoadingContract,
    sendStep,
    warningData,
    currentStep,
    stepAfterSubmit,
    stepsToValidate,
}: Step1Interface) {
    const stepData: Step1FormInterface = React.useMemo(() => (
        convertContractPersonsToFormValues(insurer, owner, dictionaries)
    ), [insurer, owner, dictionaries]);
    const [fiasData, setFiasData] = React.useState<FiasDataInterface>(getFiasData(insurer, owner));

    const submittedOnce = React.useRef(false);

    const onSubmit = (value: Step1FormInterface) => {
        const preparedValues = prepareFormValuesForTransfer(value, fiasData);
        const {insurer, owner} = dataTransferObjectFormatter(preparedValues);

        if (stepAfterSubmit) {
            submittedOnce.current = true;
            sendStep({
                contractId,
                currentStep,
                nextStep: stepAfterSubmit,
                insurer,
                owner: postFiltrationOwner(owner),
                stepsToValidate,
            });
        }
    };

    const setValue: Mutator = ([name, newValue], state, {changeValue}) => {
        changeValue(state, name, () => newValue);
    };

    return (
        <div className={cn('page')}>
            <Form
                onSubmit={onSubmit}
                initialValues={stepData}
                mutators={{setFieldData, setValue}}
                render={({
                    handleSubmit,
                    form: {mutators: {setFieldData, setValue}},
                    values,
                }) => {
                    if (stepAfterSubmit) {
                        if (!submittedOnce.current) {
                            onSubmit(values);
                        }
                    } else {
                        submittedOnce.current = false;
                    }
                    return (
                        <form onSubmit={handleSubmit} className={cn('page__main-content')}>
                            {!isLoadingContract && (
                                <>
                                    <Policyholder
                                        mutator={setValue}
                                        person={insurer.person}
                                        values={values}
                                    />
                                    <Owner
                                        mutator={setValue}
                                        owner={owner}
                                        values={values}
                                    />
                                </>
                            )}
                            <PortalRoot />
                            <WarningEngine setFieldData={setFieldData} warningData={warningData} />
                        </form>
                    );
                }}
            />
        </div>
    );
}
