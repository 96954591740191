import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {linkDataSelector} from '@modules/companies';

import {TextInformation} from './text-information';

const mapStateToProps = createStructuredSelector({
    linkData: linkDataSelector,
});

export const TextInformationContainer = connect(mapStateToProps)(TextInformation);
