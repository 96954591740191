import classNames from 'classnames/bind';
import * as React from 'react';

import {noop, findIndex} from '@utils/functions';

import {DraggableList} from '../../draggable-list';
import {Scrollbars} from '../../scrollbars';
import {Column} from '../data-table-types';
import {Row} from './row';
import {RowWithTable} from './row-with-table';

import * as styles from './row.scss';

const cn = classNames.bind(styles);

interface RowsContainerProps {
    columns: Column[];
    getRowId: (row: object) => any;
    getRowStyle?: (row: object) => object;
    height: number;
    highlightRows: boolean;
    onRowClick: (row: object) => void;
    onScroll: (values: any) => void;
    hasHeightByContent?: boolean;
    rowHeight: number;
    rows: object[];
    rowWidth: number;
    visibleRowWidth: number;
    offsetX: number;
    draggable?: boolean;
    selectedRow?: object;
    disabledRows?: object[];
    draggableRowIndex?: number;
    onSortStart?: (index: number) => void;
    onSortEnd?: (oldIndex: number, newIndex: number) => void;
    isSortableColumns?: boolean;
    clickable?: boolean;
    isScrollbarHided?: boolean;
    checkIfRowHasNestedRows?: (row: object) => boolean;
    getColumnsWithToggler?: (columns: Column[], togglerColumn: Column) => Column[];
}

function RowsContainerComponent({
    columns,
    getRowId,
    getRowStyle,
    height,
    highlightRows,
    onRowClick,
    onScroll,
    rows,
    hasHeightByContent = false,
    rowHeight,
    rowWidth,
    visibleRowWidth,
    offsetX,
    draggable,
    selectedRow,
    disabledRows,
    draggableRowIndex,
    onSortEnd,
    onSortStart,
    isSortableColumns,
    clickable,
    isScrollbarHided = false,
    checkIfRowHasNestedRows,
    getColumnsWithToggler,
}: RowsContainerProps) {
    const renderRow = (row: object, index?: number) => {
        const disabled = findIndex(
            disabledRows,
            (disabledRow: object) => getRowId(disabledRow) === getRowId(row)
        ) !== -1;
        const handleRowClick = onRowClick && !disabled ?
            () => onRowClick(row) :
            noop;
        const rowProps = {
            highlight: highlightRows,
            getRowStyle,
            columns,
            height: rowHeight,
            key: getRowId(row),
            row,
            onClick: handleRowClick,
            width: rowWidth,
            visibleRowWidth,
            offsetX,
            draggable,
            dragging: draggableRowIndex === index,
            isSortableColumns,
            selected: getRowId(row) === getRowId(selectedRow || {}),
            disabled,
            clickable: !disabled && clickable,
        };

        return checkIfRowHasNestedRows && checkIfRowHasNestedRows(row) ? (
            <RowWithTable
                {...rowProps}
                getRowId={getRowId}
                getColumnsWithToggler={getColumnsWithToggler}
            />
        ) : <Row {...rowProps} />;
    };

    const rowsContainerContent = draggable ? (
        <DraggableList
            lockAxis="y"
            items={rows}
            renderListItem={renderRow}
            onSortEnd={onSortEnd}
            onSortStart={onSortStart}
            helperClass={cn('row-container__row--dragging')}
            getListItemKey={getRowId}
            bottomGap={`${rowHeight / 2}px`}
        />
    ) : rows.map(renderRow);

    return isScrollbarHided ? (
        <>
            {rowsContainerContent}
        </>
    ) : (
        <Scrollbars
            maxHeight={
                hasHeightByContent ?
                    rowHeight * rows.length :
                    height
            }
            onScroll={onScroll}
            hasHeightByContent={hasHeightByContent}
        >
            {rowsContainerContent}
        </Scrollbars>
    );
}

export const RowsContainer = React.memo(RowsContainerComponent);
