import * as React from 'react';

import {getMonthDates} from '@utils/date';
import {noop} from '@utils/functions';

import {Views, ViewTypes} from './views.constants';

interface ViewProps {
    viewDate: Date;
    view: ViewTypes;
    focusedDate: Date;
    firstSelectedDate?: Date;
    secondSelectedDate?: Date;
    onSelectMonth: (index: number) => void;
    onSelectYear: (year: number) => void;
    onSelectDay: (date: Date) => void;
    onFocus: (date: Date, isNeededToUpdateViewDate: boolean) => void;
    isActive: boolean;
    availableDates?: [Date, Date?]
}

export function View({
    viewDate,
    view,
    firstSelectedDate,
    secondSelectedDate,
    onSelectMonth = noop,
    onSelectYear = noop,
    onSelectDay = noop,
    onFocus = noop,
    focusedDate,
    isActive = false,
    availableDates,
}: ViewProps) {
    switch (view) {
        case ViewTypes.YEAR:
            return (
                <Views.Year
                    viewDate={viewDate}
                    focusedDate={focusedDate}
                    onFocus={onFocus}
                    onChange={onSelectYear}
                    isActive={isActive}
                />
            );
        case ViewTypes.MONTH:
            return (
                <Views.Month
                    viewDate={viewDate}
                    focusedDate={focusedDate}
                    onFocus={onFocus}
                    onChange={onSelectMonth}
                    isActive={isActive}
                />
            );
        case ViewTypes.DAY:
        default:
            return (
                <Views.Day
                    days={getMonthDates(viewDate)}
                    viewDate={viewDate}
                    focusedDate={focusedDate}
                    firstSelectedDate={firstSelectedDate}
                    secondSelectedDate={secondSelectedDate}
                    onFocus={onFocus}
                    onChange={onSelectDay}
                    isActive={isActive}
                    availableDates={availableDates}
                />
            );
    }
}
