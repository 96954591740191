export enum FontIconTypes {
    ADD = 'add',
    ARROW_BACK = 'arrow_back',
    ARROW_DOWN = 'arrow_drop_down',
    ARROW_RIGHT_IOS = 'arrow_forward_ios',
    ARROW_UP = 'arrow_drop_up',
    CHECK = 'check',
    CLOSE = 'close',
    CLEAR = 'clear',
    CLOUD_UPLOAD = 'cloud_upload',
    CIRCLE_DOT = 'adjust',
    DATE_RANGE = 'date_range',
    DELETE = 'delete',
    DRAG_INDICATOR = 'drag_indicator',
    EDIT = 'edit',
    ERROR = 'error',
    EXIT = 'exit_to_app',
    EXPAND_LESS = 'expand_less',
    EXPAND_MORE = 'expand_more',
    FILTER_LIST = 'filter_list',
    IMPORT = 'save_alt',
    INFO = 'info_outlined',
    KEY = 'vpn_key',
    LINK = 'link',
    LIST = 'list',
    MENU = 'menu',
    MORE_VERT = 'more_vert',
    NEXT = 'chevron_right',
    PREV = 'chevron_left',
    REMOVE = 'remove',
    SEARCH = 'search',
    SORT = 'sort',
    UNDO = 'undo',
    VISIBILITY = 'visibility',
    WARNING = 'warning'
}

export interface FontIconProps {
    size?: number | string,
    rotateDegree?: number
}

export interface GenericFontIconProps extends FontIconProps{
    name: string
}
