import classNames from 'classnames/bind';
import * as React from 'react';

import {TogglerCommonProps} from '../../select-types';
import {Icon, IconType} from '../icon';
import {Label} from './label';

import * as styles from './link-select-toggler.scss';

const cn = classNames.bind(styles);

interface TogglerProps extends TogglerCommonProps{
    tabIndex: number
}

export function LinkSelectToggler({
    value,
    isDisabled,
    isOpen,
    onBlur,
    onClick,
    tabIndex,
    onKeyDown,
}: TogglerProps) {
    const handleKeyDown = React.useCallback(({keyCode}: any) => {
        onKeyDown(keyCode);
    }, [onKeyDown]);

    return (
        <div
            tabIndex={tabIndex}
            className={cn('link-select-toggler')}
            role="button"
            onBlur={onBlur}
            onKeyDown={isDisabled ? null : handleKeyDown}
            onClick={isDisabled ? null : onClick}
            aria-haspopup="listbox"
            aria-expanded={isOpen}
        >
            <Label value={value} />
            <Icon isOpen={isOpen} iconType={IconType.LINK_ICON} />
        </div>
    );
}
