import classNames from 'classnames/bind';
import {MutableState, Mutator} from 'final-form';
import setFieldData from 'final-form-set-field-data';
import * as React from 'react';
import {Form} from 'react-final-form';

import {CheckboxField} from '@app/components';
import {WarningEngine} from '@app/pages/contract-page/components/warning-engine';
import {OptionsSendStep} from '@app/pages/contract-page/contract-page-actions';
import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {
    convertContractDriversToFormValues,
    prepareFormValuesForTransfer,
} from '@app/pages/contract-page/pages/step-3/step3-convert-utils';
import {
    Drivers,
    Vehicle,
} from '@app/types';
import {createTranslator} from '@app/utils/i18n';
import {
    MappedMismatchField,
} from '@modules/validation';
import {addYears} from '@utils/date';
import {dataTransferObjectFormatter} from '@utils/formatters';
import {shouldBeDisabled} from '@utils/should-be-disabled';

import {DriverInfo} from './driver-info';
import {Step3FormInterface} from './step3-types';
import {sortDriversList} from './step3-utils';

import * as styles from './step3.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface Step3Interface {
    switchNoLimit: (value: boolean) => void;
    vehicle: Vehicle;
    drivers: Drivers;
    contractId: string;
    warningData: MappedMismatchField[];
    scansEditFields: string[];
    currentStep: number;
    stepAfterSubmit: number;
    sendStep?: (options: OptionsSendStep) => void;
    stepsToValidate: number[];
    isLkBlocked: boolean;
}

export function Step3({
    vehicle,
    drivers,
    switchNoLimit,
    contractId,
    warningData,
    scansEditFields,
    sendStep,
    currentStep,
    stepAfterSubmit,
    stepsToValidate,
    isLkBlocked,
}: Step3Interface) {
    const submittedOnce = React.useRef(false);

    const initialFormValues = React.useMemo(() => {
        const sortedDrivers = {...drivers, driversList: sortDriversList(drivers.driversList)};
        return convertContractDriversToFormValues(sortedDrivers);
    }, [drivers]);

    const onSubmit = (values: Step3FormInterface) => {
        const preparedValues = prepareFormValuesForTransfer(values);
        const {drivers} = dataTransferObjectFormatter(preparedValues);

        if (stepAfterSubmit) {
            submittedOnce.current = true;
            sendStep({
                contractId,
                currentStep,
                nextStep: stepAfterSubmit,
                drivers,
                stepsToValidate,
            });
        }
    };

    const setValue: Mutator = ([name, newValue, id], state: MutableState<Step3FormInterface>, {changeValue}) => {
        const driverBirthday = `driversList-${id}-person-birthdate` as keyof Step3FormInterface;

        if (name === driverBirthday) {
            const driverStartDate = `driversList-${id}-driverLicense-startDate` as keyof Step3FormInterface;

            const oldDriverLicenseStartDate = state.lastFormState.values[driverStartDate] as string;
            const newDate = addYears(new Date(newValue), 16).toISOString();

            if (oldDriverLicenseStartDate && oldDriverLicenseStartDate < newDate) {
                changeValue(state, driverStartDate, () => newDate);
            }
        } else {
            changeValue(state, name, () => newValue);
        }
    };

    return (
        <div className={cn('page')}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialFormValues}
                mutators={{setFieldData, setValue}}
                render={({
                    handleSubmit,
                    form: {mutators: {setFieldData, setValue}},
                    values,
                }) => {
                    if (stepAfterSubmit) {
                        if (!submittedOnce.current) {
                            onSubmit(values);
                        }
                    } else {
                        submittedOnce.current = false;
                    }
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={cn('page__sub-container')}>
                                <div className={cn('page__wrapper-center')}>
                                    <div className={cn('page__header')}>
                                        {
                                            drivers.noLimits ?
                                                (
                                                    <div className={cn('page__no-limits-text')}>
                                                        <span className={cn('page__header-text')}>
                                                            {t('drivers-noLimits')}
                                                        </span>
                                                    </div>
                                                ) :
                                                (
                                                    <h2>
                                                        {drivers.driversList.length > 1 ?
                                                            t('drivers') :
                                                            t('driver')}
                                                    </h2>
                                                )
                                        }

                                        <div className={cn('page__header-part')}>
                                            <CheckboxField
                                                label={t('no-limit')}
                                                name="noLimits"
                                                onChange={() => switchNoLimit(!drivers.noLimits)}
                                                isDisabled={shouldBeDisabled({
                                                    name: 'noLimits',
                                                    listFieldError: scansEditFields,
                                                    isLkBlocked,
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!drivers.noLimits &&
                            drivers.driversList.map(
                                (d, i) => (
                                    <DriverInfo
                                        key={d.driverIndex}
                                        id={i}
                                        typeCategory={vehicle?.typeCategory}
                                        values={values}
                                        mutator={setValue}
                                    />
                                )
                            )}
                            <WarningEngine setFieldData={setFieldData} warningData={warningData} />
                        </form>
                    );
                }}
            />
        </div>
    );
}
