import {companiesStateMock} from '@app/mocks';
import {CompaniesInterface} from '@app/store/types';
import {
    InsCompany2Status,
    InsuranceCompany,
    LinkData,
    RegionName,
} from '@app/types';
import {handleActions} from '@utils/redux';
import {ActionTypes} from '@utils/types';

import {COMPANIES} from '../model-constants';

const companiesHandler = handleActions(COMPANIES);

export const companiesReducer = companiesHandler({
    [ActionTypes.SET_INS_COMPANY_NULL]: (state: CompaniesInterface, {payload}: {payload: null}) => ({
        ...state,
        insCompany: payload,
    }),

    [ActionTypes.SET_COMPANIES]: (state: CompaniesInterface, {payload}: {payload: InsuranceCompany[]}) => ({
        ...state,
        companiesList: payload,
    }),

    [ActionTypes.SET_FILTERABLE_COMPANIES]: (state: CompaniesInterface, {payload}: {payload: InsuranceCompany[]}) => ({
        ...state,
        filterableCompaniesList: payload,
    }),

    [ActionTypes.SET_COMPANY_PREMIUM]: (state: CompaniesInterface, {payload}: {payload: number}) => ({
        ...state,
        companyPremium: payload,
    }),

    [ActionTypes.SET_SELECTED_COMPANY]: (state: CompaniesInterface, {payload}: {payload: boolean}) => ({
        ...state,
        insCompany: payload,
    }),

    [ActionTypes.SET_LINK]: (state: CompaniesInterface, {payload}: {payload: LinkData}) => ({
        ...state,
        linkData: payload,
    }),

    [ActionTypes.SET_REGION_NAME]: (state: CompaniesInterface, {payload}: {payload: RegionName}) => ({
        ...state,
        regionName: payload,
    }),

    [ActionTypes.SET_HAS_INS_COMPANY_2_CHOSEN]: (
        state: CompaniesInterface,
        {payload}: {payload: InsCompany2Status}
    ) => ({
        ...state,
        isSecondCompanyChosen: payload,
    }),
}, companiesStateMock);
