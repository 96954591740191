import {errorNotification} from '@modules/notifications';
import {hideOverlay, showOverlay} from '@modules/overlay';
import {noop} from '@utils/functions';
import {DispatchType} from '@utils/redux';
import {
    RequestError,
    RequestCode,
} from '@utils/request';

type ExecuteAsyncAction = (
    dispatch: DispatchType,
    executor: () => Promise<any>,
    errorHandler?: (error: RequestError) => void,
    ignoreLogicErrorStatuses?: RequestCode[]
) => Promise<any>;

export const executePureAsyncAction: ExecuteAsyncAction = (
    dispatch,
    executor,
    errorHandler = noop,
    ignoreLogicErrorStatuses = []
) => executor()
    .catch((error: RequestError) => {
        const {isLogicError, message, status} = error;
        const isIgnoredStatus = ignoreLogicErrorStatuses.length &&
            ignoreLogicErrorStatuses.includes(status);
        if (isLogicError && !isIgnoredStatus) {
            dispatch(errorNotification(message));
        }
        errorHandler(error);

        return error;
    });

export const executeAsyncAction: ExecuteAsyncAction = (
    dispatch,
    ...args
) => {
    dispatch(showOverlay());
    return executePureAsyncAction(dispatch, ...args)
        .then((response: any) => {
            dispatch(hideOverlay());
            return response;
        });
};
