import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import {Icons} from '../../icons';

import * as styles from './button-icon.scss';

type HandlerFunc = () => void;

interface ButtonIconProps {
    disabled?: boolean,
    highlighted?: boolean,
    onMouseDown?: HandlerFunc,
    onMouseUp?: HandlerFunc,
    onMouseLeave?: HandlerFunc
}

const cn = classNames.bind(styles);

export function ButtonIcon({
    disabled,
    highlighted,
    onMouseDown,
    onMouseLeave,
    onMouseUp,
}: ButtonIconProps) {
    const classes = cn('button-icon',
        highlighted && 'button-icon--highlighted',
        disabled && 'button-icon--disabled');
    return (
        <span
            className={classes}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
            role="button"
        >
            <Icons.VISIBILITY />
        </span>
    );
}

ButtonIcon.defaultProps = {
    disabled: false,
    highlighted: false,
    onMouseDown: noop,
    onMouseLeave: noop,
    onMouseUp: noop,
};
