import classNames from 'classnames/bind';
import {isEqual} from 'lodash-es';
import * as React from 'react';
import {positionValues} from 'react-custom-scrollbars';

import {SelectOption} from '@utils/formatters/select-option';
import {noop} from '@utils/functions';

import {VISIBLE_OPTIONS} from './constants';
import {ListContainer} from './list-container';
import {TogglerContainer} from './toggler-container';

import * as styles from './select.scss';

const cn = classNames.bind(styles);

const getFirstElement = (list: Array<object>) => (list.length ? list[0] : null);

interface CreateSelectorProps {
    toggler: (props: any) => JSX.Element,
    list: (props: any) => JSX.Element
}

export interface SelectState<T> {
    readonly selectedOption?: SelectOption<T>;
    readonly focusedOption?: SelectOption<T>;
    isOpen: boolean;
    isClickList: boolean;
}

export interface SelectProps<T> {
    countVisibleOption?: number,
    disabled?: boolean
    emptyOptionMessage?: string,
    errorMessage?: string,
    getLabel?: (option: SelectOption<T> | null) => string,
    getValue?: (option: SelectOption<T> | null) => T | null,
    isValid?: boolean
    isFilterable?: boolean;
    onScrollToBottom?: () => void;
    onFilterChange?: (value: string) => void;
    resetAutocompleteState?: () => void;
    onFocus?: () => void;
    isLoading?: boolean;
    label: string,
    onChange: (value: SelectOption<T>) => void,
    options: SelectOption<T>[],
    required?: boolean,
    selectedOption?: SelectOption<T> | null,
    width?: string | number,
    allowKeyboardClean?: boolean
    startWith?: string;
    isChangeKeyboard?: boolean;
}

export function CreateSelector<T>({toggler, list}: CreateSelectorProps) {
    class Select extends React.PureComponent<SelectProps<T>, SelectState<T>> {
        selectRef = React.createRef<HTMLDivElement>();

        static defaultProps: SelectProps<T> = {
            disabled: false,
            errorMessage: null,
            isValid: true,
            label: '',
            options: [],
            onChange: noop,
            onFocus: noop,
            countVisibleOption: VISIBLE_OPTIONS,
            getValue: (option: SelectOption<T> | null): T | null => (option ? option.value : null),
            getLabel: (option: SelectOption<T> | null): string => (option ? option.label : ''),
            required: false,
            width: '100%',
        };

        static getDerivedStateFromProps(props: SelectProps<T>, state: SelectState<T>) {
            if (!isEqual(props.selectedOption, state.selectedOption)) {
                return {
                    selectedOption: props.selectedOption,
                    focusedOption: props.selectedOption || getFirstElement(props.options),
                    isOpen: false,
                };
            }
            return null;
        }

        state: SelectState<T> = {
            isOpen: false,
            isClickList: false,
        };

        handleChange = (option: SelectOption<any>) => {
            const {onChange} = this.props;

            onChange(option);
            this.closeOptions();
        };

        openOptions = () => this.setState((prevState: SelectState<T>) => ({
            ...prevState,
            isOpen: true,
            isClickList: false,
        }));

        closeOptions = () => {
            const {resetAutocompleteState} = this.props;
            if (resetAutocompleteState) {
                resetAutocompleteState();
            }

            this.setState((prevState: SelectState<T>) => ({
                ...prevState,
                isOpen: false,
            }));
        };

        changeFocusedOption = (option: SelectOption<any>) => this.setState((prevState: SelectState<T>) => ({
            ...prevState,
            focusedOption: option,
        }));

        handleScroll = (values: positionValues) => {
            const {onScrollToBottom} = this.props;

            if (onScrollToBottom && values.top > 0.9) {
                onScrollToBottom();
            }
        };

        render() {
            const {
                selectedOption,
                isOpen,
                focusedOption,
                isClickList,
            } = this.state;
            const {
                disabled,
                label,
                isValid,
                isFilterable,
                errorMessage,
                emptyOptionMessage,
                options,
                countVisibleOption,
                getValue,
                getLabel,
                required,
                width,
                allowKeyboardClean,
                isLoading,
                onFocus,
                onFilterChange,
                startWith,
                isChangeKeyboard,
            } = this.props;

            return (
                <div
                    className={cn('select')}
                    style={{width}}
                >
                    <div
                        ref={this.selectRef}
                        className={cn('wrapper-content')}
                    >
                        <TogglerContainer
                            toggler={toggler}
                            isDisabled={disabled}
                            label={label}
                            value={selectedOption && getLabel(selectedOption)}
                            onClick={this.openOptions}
                            onBlur={this.closeOptions}
                            required={required}
                            isOpen={isOpen}
                            isValid={isValid}
                            isFilterable={isFilterable}
                            onFilterChange={onFilterChange}
                            onFocus={onFocus}
                            errorMessage={errorMessage}
                            closeOptions={this.closeOptions}
                            changeFocusedOption={this.changeFocusedOption}
                            handleChange={this.handleChange}
                            openOptions={this.openOptions}
                            options={options}
                            focusedOption={focusedOption}
                            allowKeyboardClean={allowKeyboardClean}
                            startWith={startWith}
                            isChangeKeyboard={isChangeKeyboard}
                            isClickList={isClickList}
                        />
                        <ListContainer
                            list={list}
                            getValue={getValue}
                            getLabel={getLabel}
                            emptyOptionMessage={emptyOptionMessage}
                            selectedOption={selectedOption}
                            focusedOption={focusedOption}
                            onClick={(options: SelectOption<any>) => {
                                this.setState((prevState: SelectState<T>) => ({
                                    ...prevState,
                                    isClickList: true,
                                }));
                                this.handleChange(options);
                            }}
                            options={options}
                            isOpen={isOpen}
                            selectRef={this.selectRef}
                            countVisibleOption={countVisibleOption}
                            onScroll={this.handleScroll}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            );
        }
    }

    return Select;
}
