import classNames from 'classnames/bind';
import * as React from 'react';

import {TextField} from '@app/components';
import {createTranslator} from '@app/utils/i18n';
import {shouldBeDisabled} from '@utils/should-be-disabled';
import {Person} from '@app/types';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';

import {SecondNameField} from './second-name-field';

import * as styles from '../person-data.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface Props {
    personType: string;
    mutator: (field: string, value: string) => void;
    person: Person;
    scansEditFields: string[];
    isLkBlocked: boolean;
}

export function FioFields({
    personType,
    mutator,
    person,
    scansEditFields,
    isLkBlocked,
}: Props) {
    return (
        <>
            <div className={cn('person-data__form-field-wrapper')}>
                <div className={cn('person-data__form-field')}>
                    <TextField
                        name={`${personType}-person-surname`}
                        label={t('surname')}
                        disabled={
                            shouldBeDisabled(
                                {
                                    name: `${personType}-person-surname`,
                                    listFieldError: scansEditFields,
                                    isLkBlocked,
                                }
                            )
                        }
                        required
                    />
                </div>
                <div className={cn('person-data__form-field')}>
                    <TextField
                        name={`${personType}-person-name`}
                        label={t('first-name')}
                        disabled={
                            shouldBeDisabled(
                                {
                                    name: `${personType}-person-name`,
                                    listFieldError: scansEditFields,
                                    isLkBlocked,
                                }
                            )
                        }
                        required
                    />
                </div>
            </div>
            <SecondNameField
                cn={cn}
                textFieldLabel={t('secondName')}
                checkboxLabel={t('noSecondName')}
                personType={personType}
                mutator={mutator}
                person={person}
            />
        </>
    );
}
