import classNames from 'classnames/bind';
import * as React from 'react';

import {KeyCode, EventName} from '@utils/constants';
import {
    getYears,
    getNowDate,
    addYears,
} from '@utils/date';
import {noop} from '@utils/functions';

import {Year} from './year';

import * as styles from './year-view.scss';

const cn = classNames.bind(styles);
const step = 7;
const X_AXIS_STEP = 1;
const Y_AXIS_STEP = 5;

const isDateIncludedInView = (
    date: Date,
    years: number[]
): boolean => !!years.find(year => (year === date.getFullYear()));

interface YearViewProps {
    onChange: (index: number) => void;
    onFocus: (date: Date, isNeededToUpdateViewDate: boolean) => void;
    viewDate: Date;
    focusedDate: Date;
    isActive: boolean;
}

export class YearView extends React.PureComponent<YearViewProps> {
    static defaultProps = {
        onChange: noop,
        isActive: false,
    };

    componentDidMount() {
        document.addEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener(EventName.KEY_DOWN, this.handleKeyDown);
    }

    setFocusedDate = (newDate: Date, years: number[]) => {
        const {onFocus} = this.props;
        const isNeededToUpdateViewDate = !isDateIncludedInView(newDate, years);

        onFocus(newDate, isNeededToUpdateViewDate);
    };

    handleKeyDown = ({keyCode}: KeyboardEvent) => {
        const {
            onChange,
            viewDate,
            focusedDate,
            isActive,
        } = this.props;
        const years = getYears(viewDate, step);

        if (!isActive) {
            return;
        }

        switch (keyCode) {
            case KeyCode.RIGHT_ARROW: {
                const newDate = addYears(focusedDate, X_AXIS_STEP);

                this.setFocusedDate(newDate, years);
                break;
            }
            case KeyCode.LEFT_ARROW: {
                const newDate = addYears(focusedDate, -X_AXIS_STEP);

                this.setFocusedDate(newDate, years);
                break;
            }
            case KeyCode.DOWN_ARROW: {
                const newDate = addYears(focusedDate, Y_AXIS_STEP);

                this.setFocusedDate(newDate, years);
                break;
            }
            case KeyCode.UP_ARROW: {
                const newDate = addYears(focusedDate, -Y_AXIS_STEP);

                this.setFocusedDate(newDate, years);
                break;
            }
            case KeyCode.ENTER: {
                if (focusedDate) {
                    onChange(focusedDate.getFullYear());
                }
                break;
            }
            default:
                break;
        }
    };

    render() {
        const {
            onChange,
            viewDate,
            focusedDate,
        } = this.props;

        return (
            <div className={cn('year-view')}>
                {
                    getYears(viewDate, step).map((year: number) => (
                        <Year
                            key={year}
                            year={year}
                            onClick={() => onChange(year)}
                            isCurrent={year === getNowDate().getFullYear()}
                            isSelected={viewDate ? (year === viewDate.getFullYear()) : false}
                            isFocused={focusedDate ? (year === focusedDate.getFullYear()) : false}
                        />
                    ))
                }
            </div>
        );
    }
}
