import classNames from 'classnames/bind';
import * as React from 'react';

import {NavigationError} from '../navigation-error';
import {ButtonsBackOrNext} from './components/buttons-back-or-next';
import {ButtonsStep4} from './components/buttons-step4';
import {ValidationPoller} from './components/validation-poller';

import * as styles from '../../navigation.scss';

const cn = classNames.bind(styles);

export function ContractNavigation() {
    return (
        <div className={cn('navigation-wrapper')}>
            <NavigationError />
            <ButtonsBackOrNext />
            <ButtonsStep4 />
            <ValidationPoller />
        </div>
    );
}
