export enum TypeCategory {
    B_BE = '01',
    A_M = '02',
    C_CE = '03',
    D_DE = '04',
    Tb = '05',
    Tm = '06',
    Tr = '07'
}

export enum UsePurpose {
    PERSONAL = '1',
    TAXI = '2',
    TRAINING = '3',
    SPECIAL = '4',
    OTHER = '5',
    REGULAR = '6',
    DANGER = '7',
    RENT = '8',
    URGENT = '9',
}

export const OTHER_MAKER_LABEL = 'Другая марка';
export const OTHER_MODEL_LABEL = 'Другая модель';

export const OTHER_MAKER_MOCK: any = {label: OTHER_MAKER_LABEL, value: null};
export const OTHER_MODEL_MOCK: any = {label: OTHER_MODEL_LABEL, value: null};
