import {DispatchType} from '@utils/redux';
import {confirm} from '@modules/common-modals';
import {MODAL_WIDTH} from '@utils/constants/modal-width';
import {createTranslator} from '@utils/i18n';
import * as dictionaryLayout from '@app/pages/shared-pages/layout-page/layout-page.dic.json';

import * as dictionary from './tabs-guard.dic.json';

const t = createTranslator(dictionary);
const l = createTranslator(dictionaryLayout);

export function showTabsGuardModal(): DispatchType {
    return (dispatch: DispatchType) => {
        dispatch(confirm({
            title: l('confirm-modal-title'),
            message: t('tabs-guard-confirm-modal-message'),
            width: MODAL_WIDTH,
            resultHandler: result => {
                window.open('about:blank', '_self').close();
            },
            hasCancelOption: false,
        }));
    };
}
