import * as React from 'react';

import {TableButtons} from '@app/components/layouts/table-layout';
import {AppStages, Steps} from '@utils/constants';

import {ButtonApplyCheckDocuments} from './components/button-apply-check-documents';
import {ButtonApplyPolicy} from './components/button-apply-policy';
import {ButtonApplyUpdateStatus} from './components/button-apply-update-status';
import {ButtonBackToEdit} from './components/button-back-to-edit';
import {ButtonCancel} from './components/button-cancel';
import {ButtonCheckData} from './components/button-check-data';

interface ButtonsStep4Interface {
    currentStage: string;
    currentStep: number;
    isDataEdited: boolean;
}

export function ButtonsStep4({
    currentStage,
    currentStep,
    isDataEdited,
}: ButtonsStep4Interface) {
    const printStep4Buttons = () => {
        switch (currentStage) {
            case AppStages.CHANGE_APPLICATION_DATA:
                return isDataEdited ? (
                    <TableButtons>
                        <ButtonBackToEdit />
                        <ButtonCheckData />
                    </TableButtons>
                ) : (
                    <ButtonBackToEdit />
                );
            case AppStages.AUTO_ACCEPT_TIMEOUT__REJECTED:
                return (
                    <ButtonBackToEdit />
                );
            case AppStages.APPLICATION_DIRECTION:
                return (
                    <ButtonCheckData />
                );
            case AppStages.PAYMENT:
                return (
                    <TableButtons>
                        <ButtonCancel />
                        <ButtonApplyPolicy />
                    </TableButtons>
                );
            case AppStages.SCAN_ATTACHMENT:
                return (
                    <ButtonApplyCheckDocuments />
                );

            case AppStages.AUTO_ACCEPT_TIMEOUT:
                return (
                    <ButtonApplyUpdateStatus />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {
                currentStep === Steps.FINAL_STEP && printStep4Buttons()
            }
        </>
    );
}
