import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './counter.scss';

interface CounterProps {
    value: string,
    maxLength: number
}

const cn = classNames.bind(styles);

export function Counter({value, maxLength}: CounterProps) {
    return (
        <span className={cn('counter')}>
            {`${value.length}/${maxLength}`}
        </span>
    );
}
