import classNames from 'classnames/bind';
import * as React from 'react';

import {RelativePortal} from '@ui-kit';

import {SelectListProps} from '../../select-types';
import {Options, OptionsType} from '../options';

import * as styles from './list.scss';

const cn = classNames.bind(styles);

export interface LinkSelectListProps extends SelectListProps {}

const optionsRef = React.createRef<HTMLDivElement>();

export function LinkSelectList(props: LinkSelectListProps) {
    const {
        emptyOptionMessage,
        options,
        getValue,
        getLabel,
        selectedOption,
        focusedOption,
        onClick,
        selectRef,
    } = props;
    const {current} = optionsRef;

    return (
        <RelativePortal
            parentElement={selectRef.current}
            className={cn('select__options')}
            width={current && current.getBoundingClientRect().width}
        >
            <div ref={optionsRef}>
                <Options
                    getValue={getValue}
                    getLabel={getLabel}
                    emptyOptionMessage={emptyOptionMessage}
                    selectedOption={selectedOption}
                    focusedOption={focusedOption}
                    onClick={onClick}
                    options={options}
                    optionsType={OptionsType.LINK_OPTIONS}
                />
            </div>
        </RelativePortal>
    );
}
