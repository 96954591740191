import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {Checkbox} from '@ui-kit';
import {AppStages} from '@utils/constants';
import {createTranslator} from '@utils/i18n';

import * as styles from './contract-checkbox.scss';

const t = createTranslator(dictionary);
const cn = classNames.bind(styles);

export interface ContractCheckboxProps {
    currentStage: string;
    isDataEdited: boolean;
    isValidationChecked: boolean;
    isValidationInitiated: boolean;
    isCheckDataBtnHidden: boolean;
    setIsValidationChecked: (value: Boolean) => void;
    isLkBlocked: boolean;
}

export const ContractCheckbox = ({
    currentStage,
    isDataEdited,
    isValidationChecked,
    isValidationInitiated,
    isCheckDataBtnHidden,
    setIsValidationChecked,
    isLkBlocked,
}: ContractCheckboxProps) => {
    const isCorrectStage = currentStage === AppStages.APPLICATION_DIRECTION ||
        currentStage === AppStages.CHANGE_APPLICATION_DATA;
    const isForceDisabled = !(isCorrectStage && !isCheckDataBtnHidden);
    const isValidationInitiatedWithoutEdit = isValidationInitiated && !isDataEdited;
    const isDisabled = isLkBlocked || isForceDisabled || isValidationInitiatedWithoutEdit;
    const isChecked = isValidationChecked || isForceDisabled || isValidationInitiatedWithoutEdit;

    return (
        <div className={cn('contract-checkbox__confirmed')}>
            <div className={cn('contract-checkbox__wrapper')}>
                <Checkbox
                    label={t('check-result-confirm-text')}
                    id="checkbox-registration-agree"
                    checked={isChecked}
                    isDisabled={isDisabled}
                    onChange={() => setIsValidationChecked(!isValidationChecked)}
                />
            </div>
        </div>

    );
};
