
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    forceUpdateNext,
    forceUpdatePrev,
} from '@app/store/actions';
import {
    currentStepSelector,
    isLkBlockedSelector,
} from '@modules/navigation';
import {isDisabled} from '@utils/isDisabled';

import {ButtonsBackOrNext} from './buttons-back-or-next';

const mapStateToProps = createStructuredSelector({
    currentStep: currentStepSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {
    forceUpdatePrev,
    forceUpdateNext,
    isDisabled,
};

export const ButtonsBackOrNextContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonsBackOrNext);
