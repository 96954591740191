import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import {getStyle} from './utils';

import * as styles from './button.scss';

export interface ButtonProps {
    /* visible label */
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    primary?: boolean;
    danger?: boolean;
    icon?: JSX.Element;
    width?: string | number;
    isSmall?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

interface ButtonState {
    focused: boolean;
}

const cn = classNames.bind(styles);

export class Button extends React.Component<ButtonProps, ButtonState> {
    buttonRef = React.createRef<HTMLButtonElement>();

    static defaultProps = {
        label: '',
        onClick: noop,
        disabled: false,
        danger: false,
        primary: false,
        iconPositionRight: false,
        isSmall: false,
        type: 'button',
    };

    state: ButtonState = {
        focused: false,
    };

    handleFocus = () => this.setState({focused: true});

    handleMouseUp = () => {
        const buttonNode = this.buttonRef.current;

        if (buttonNode) {
            buttonNode.blur();
        }

        this.setState({focused: false});
    };

    handleMouseLeave = () => this.setState({focused: false});

    render() {
        const {
            label,
            onClick,
            disabled,
            primary,
            danger,
            icon,
            width,
            isSmall,
            type,
        } = this.props;
        const style = getStyle({width, isSmall});
        const {focused} = this.state;

        return (
            <button
                ref={this.buttonRef}
                type={type}
                className={cn('button', {
                    'button--primary': primary,
                    'button--danger': danger,
                    'button--focused': focused,
                })}
                disabled={disabled}
                onMouseUp={this.handleMouseUp}
                onFocus={this.handleFocus}
                onMouseLeave={this.handleMouseLeave}
                onClick={onClick}
                style={style}
            >
                {icon && <span className={cn('button__icon')}>{icon}</span>}
                {label}
            </button>
        );
    }
}
