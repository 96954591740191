import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    deleteSessionAction,
    heartbeatAction,
    initializeAuth,
} from '@app/store/actions';
import {skDomainSelector} from '@modules/companies';
import {isNotFoundSelector, navigationResultSelector} from '@modules/navigation';
import {
    authenticatedSelector,
    authInitializedSelector,
} from '@modules/user';
import {contractIdSelector} from '@modules/contract';
import {updateNavigationState} from '@modules/auth/authenticated-area/authenticated-area-actions';

import {AuthenticatedArea} from './authenticated-area';

const mapStateToProps = createStructuredSelector({
    authInitialized: authInitializedSelector,
    authenticated: authenticatedSelector,
    contractId: contractIdSelector,
    skDomain: skDomainSelector,
    isNotFound: isNotFoundSelector,
    navigationResult: navigationResultSelector,
});

const mapDispatchToProps = {
    initializeAuth,
    updateNavigationState,
    deleteSession: deleteSessionAction,
    sendHeartbeat: heartbeatAction,
};

export const AuthenticatedAreaContainer =
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedArea);
