import {
    addDays,
    eachDayOfInterval,
    format,
    getDay,
    isValid,
    isEqual,
    isBefore,
    addYears,
    addMonths,
    differenceInCalendarDays,
    startOfYear,
    differenceInCalendarMonths,
} from 'date-fns';
import {range} from 'lodash-es';

import {createTranslator} from '@utils/i18n';

import {DateFormats, WeeksDay, Months} from './constant';

import * as dictionary from './date.dic.json';

const t = createTranslator(dictionary);

const daysInWeek = 7;

export const formatDate = (
    date: Date | string,
    dateFormat: string = DateFormats.MMDDYYYY_DOT
): string => format(new Date(date), dateFormat);

export const getMonthFromDate = (date: Date | string): string => {
    const month = formatDate(date, DateFormats.MMMM);

    return t(month.toLowerCase());
};

export const getYearStart = (year: number): Date => new Date(year, 0, 1);

export const getYearEnd = (year: number): Date => new Date(year, 11, 31);

export const getMonthStart = (year: number, month: number): Date => new Date(year, month, 1);

export const getMonthEnd = (year: number, month: number): Date => new Date(year, month, 31);

export const getNowDate = (): Date => new Date();

export const getDate = (day: number, month: number, year: number) => new Date(year, month, day);

export const getDatesInRange = (startDate: Date, endDate: Date): Date[] => eachDayOfInterval({
    start: startDate,
    end: endDate,
});

export const getYears = (date: Date, step: number): number[] => {
    const startYear = addYears(date, -step).getFullYear();
    const endYear = addYears(date, step).getFullYear();
    const yearsRange = range(startYear, endYear + 1);

    return yearsRange;
};

export const isDateValid = (date: Date): boolean => isValid(date);

export const isDatesEqual = (firstDate: Date, secondDate: Date): boolean => (
    isEqual(
        getDate(firstDate.getDate(), firstDate.getMonth(), firstDate.getFullYear()),
        getDate(secondDate.getDate(), secondDate.getMonth(), secondDate.getFullYear())
    )
);

export const isDateBefore = (date: Date, dateToCompare: Date): boolean => (
    isBefore(
        getDate(date.getDate(), date.getMonth(), date.getFullYear()),
        getDate(dateToCompare.getDate(), dateToCompare.getMonth(), dateToCompare.getFullYear())
    )
);

export const subtractDays = (date: Date, numberOfDays: number): Date => {
    const newDate = new Date(date.getTime());

    newDate.setDate(newDate.getDate() - numberOfDays);
    return newDate;
};

export const subtractMonths = (date: Date, numberOfMonths: number): Date => {
    const newDate = new Date(date.getTime());

    newDate.setMonth(newDate.getMonth() - numberOfMonths);
    return newDate;
};

export const getDifferenceInDays = (startDate: Date, endDate: Date): number => (
    differenceInCalendarDays(endDate, startDate)
);

export const getDifferenceInMonths = (startDate: Date, endDate: Date): number => (
    differenceInCalendarMonths(endDate, startDate)
);

export const getWeekdays = (): Array<string> => WeeksDay.map((day: string) => (t(day)));

export const getMonths = (): Array<string> => Months.map((month: string) => (t(month)));

export const getMonthDates = (viewDate: Date): Array<MonthDateType> => {
    const startDate = getMonthStart(viewDate.getFullYear(), viewDate.getMonth());
    const endDate = getMonthEnd(viewDate.getFullYear(), viewDate.getMonth());
    const countPrevDateList = getDay(startDate) === 0 ? -6 : 1 - getDay(startDate);
    const countNextDateList = getDay(endDate) === 0 ? 0 : daysInWeek - getDay(endDate);
    const startDayView = addDays(startDate, countPrevDateList);
    const endDayView = addDays(endDate, countNextDateList);

    const dateList = getDatesInRange(startDate, endDate);
    const prevDateList = isDatesEqual(startDayView, startDate) ?
        [] : getDatesInRange(startDayView, addDays(startDate, -1));
    const nextDateList = isDatesEqual(endDate, endDayView) ?
        [] :
        getDatesInRange(addDays(endDate, 1), endDayView);

    return [...prevDateList, ...dateList, ...nextDateList].map((date: Date): MonthDateType => ({
        date,
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
    }));
};

export type MonthDateType = {
    date: Date,
    day: number,
    month: number,
    year: number
};

export {
    addMonths,
    addYears,
    addDays,
    startOfYear,
};
