import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {mismatchDataSelector} from '@modules/modals';
import {flkCodeSelector} from '@modules/stage';
import {isLkBlockedSelector} from '@modules/navigation';

import {NavigationError} from './navigation-error';

const mapStateToProps = createStructuredSelector({
    flkCode: flkCodeSelector,
    mismatchData: mismatchDataSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const NavigationErrorContainer = connect(mapStateToProps)(NavigationError);
