import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {openPaymentModal} from '@app/pages/contract-page/contract-page-actions';
import {
    insCompanyUrlSelector,
    skDomainSelector,
} from '@modules/companies';
import {
    isAmountInLimitsSelector,
    contractIdSelector,
} from '@modules/contract';
import {isLkBlockedSelector} from '@modules/navigation';
import {isDisabled} from '@utils/isDisabled';

import {ButtonApplyPolicy} from './button-apply-policy';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isLkBlocked: isLkBlockedSelector,
    isAmountInLimits: isAmountInLimitsSelector,
    insCompanyUrl: insCompanyUrlSelector,
    skDomain: skDomainSelector,
});

const mapDispatchToProps = {
    isDisabled,
    openPaymentModal,
};

export const ButtonApplyPolicyContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonApplyPolicy);
