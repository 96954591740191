interface ShouldBeDisabledInterface {
    name: string;
    listFieldError: string[];
    isLkBlocked: boolean;
    forceDisabled?: boolean;
}

export const shouldBeDisabled = (options: ShouldBeDisabledInterface) => {
    const {
        name,
        listFieldError,
        isLkBlocked,
        forceDisabled,
    } = options;

    if (forceDisabled || isLkBlocked) {
        return true;
    }

    return !listFieldError.includes(name);
};
