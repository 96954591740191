import classNames from 'classnames/bind';
import * as React from 'react';

import {formatSnilsNumber} from '@app/utils/formatters';
import {createTranslator} from '@app/utils/i18n';
import {
    TextField,
    Hint,
} from '@app/components';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';

import {shouldBeDisabled} from '@app/utils/should-be-disabled';

import * as styles from '../person-data.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

interface Props {
    personType: string;
    isLkBlocked: boolean;
    scansEditFields: string[];
}

export function SnilsFieldWithHint({
    personType,
    isLkBlocked,
    scansEditFields,
}: Props) {
    return (
        <>
            <div className={cn('person-data__form-field-wrapper')}>
                <div className={cn('person-data__form-field')}>
                    <TextField
                        name={`${personType}-person-snils`}
                        label={t('snils')}
                        parse={formatSnilsNumber}
                        disabled={
                            shouldBeDisabled(
                                {
                                    name: `${personType}-person-snils`,
                                    listFieldError: scansEditFields,
                                    isLkBlocked,
                                }
                            )
                        }
                    />
                </div>
            </div>
            <div className={cn('person-data__hint-wrapper')}>
                <div className={cn('person-data__hint')}>
                    <Hint text={t('snils-hint')} />
                </div>
            </div>

        </>
    );
}
