import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from '../form-layout.scss';

const cn = classNames.bind(styles);

interface ButtonsProps {
    right?: boolean,
    children: React.ReactNode
}

export function Buttons({
    right = false,
    children,
}: ButtonsProps) {
    const getKey = (index: number) => `button-${index}`;

    return (
        <div className={cn('form-layout__buttons', right && 'form-layout__buttons--right')}>
            {React.Children.toArray(children).map((button, index) => (
                <div className={cn('form-layout__button')} key={getKey(index)}>{button}</div>
            ))}
        </div>
    );
}
