import classNames from 'classnames/bind';
import * as React from 'react';

import {getElementSize, Size, EMPTY_SIZE} from '@utils/dom';
import {noop, includes} from '@utils/functions';

import {ACCEPTABLE_POSITIONS, SCROLL_OFFSET, STUB_ELEMENT_SIZE} from './autosizer-constants';

import * as styles from './autosizer.scss';

interface AutoSizerProps {
    children: (size: Size) => React.ReactNode
}

type AutoSizerState = {
    size: Size
}

const cn = classNames.bind(styles);

export class AutoSizer extends React.Component<AutoSizerProps, AutoSizerState> {
    state: AutoSizerState = {
        size: EMPTY_SIZE,
    };

    sizeChanged: boolean = false;

    containerRef: HTMLElement = null;

    expandRef: HTMLElement = null;

    shrinkRef: HTMLElement = null;

    rafId: number = 0;

    componentDidMount() {
        this.updateSensorsScroll();
    }

    componentDidUpdate() {
        this.updateSensorsScroll();
    }

    componentWillUnmount() {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = 0;
        }
    }


    handleContainerRef = (ref: HTMLElement) => {
        if (!ref) {
            return;
        }

        const parent = ref.parentElement;
        const computedStyle = window.getComputedStyle(parent);
        const position = computedStyle ? computedStyle.getPropertyValue('position') : null;
        if (!includes(ACCEPTABLE_POSITIONS, position)) {
            parent.style.position = 'relative';
        }

        this.setState(() => ({size: getElementSize(parent)}));
        this.containerRef = parent;
    };

    handleExpandRef = (ref: HTMLDivElement) => { this.expandRef = ref; };

    handleShrinkRef = (ref: HTMLDivElement) => { this.shrinkRef = ref; };

    handleResize = (nextSize: Size) => {
        this.rafId = 0;
        if (this.sizeChanged) {
            this.setState({size: nextSize});
        }
    };

    handleScroll = () => {
        const {width, height} = getElementSize(this.containerRef);
        const {size} = this.state;

        this.sizeChanged = width !== size.width || height !== size.height;
        if (this.sizeChanged && !this.rafId) {
            this.rafId = requestAnimationFrame(() => this.handleResize({width, height}));
        }
    };

    updateSensorsScroll = () => {
        this.expandRef.scrollTop = SCROLL_OFFSET;
        this.expandRef.scrollLeft = SCROLL_OFFSET;

        this.shrinkRef.scrollTop = SCROLL_OFFSET;
        this.shrinkRef.scrollLeft = SCROLL_OFFSET;
    };

    render(): React.ReactNode {
        const {children} = this.props;
        const {size} = this.state;

        return (
            <>
                <div
                    className={cn('auto-sizer__sensor')}
                    dir="ltr"
                    ref={this.handleContainerRef}
                >
                    <div
                        className={cn('auto-sizer__sensor')}
                        onScroll={this.handleScroll}
                        ref={this.handleExpandRef}
                    >
                        <div
                            className={cn('auto-sizer__expand')}
                            style={STUB_ELEMENT_SIZE}
                        />
                    </div>
                    <div
                        className={cn('auto-sizer__sensor')}
                        onScroll={this.handleScroll}
                        ref={this.handleShrinkRef}
                    >
                        <div className={cn('auto-sizer__shrink')} />
                    </div>
                </div>
                {children(size)}
            </>
        );
    }
}
