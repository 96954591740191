import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {vehicleSelector} from '@modules/contract';
import {
    validationResultScansEditFieldsSelector,
    mappedValidationKitStep2Selector,
} from '@modules/validation';
import {isLkBlockedSelector} from '@modules/navigation';

import {CheckedField} from './checked-field';

const mapStateToProps = createStructuredSelector({
    vehicle: vehicleSelector,
    warningData: mappedValidationKitStep2Selector,
    scansEditFields: validationResultScansEditFieldsSelector,
    isLkBlocked: isLkBlockedSelector,
});

export const CheckedFieldContainer = connect(
    mapStateToProps
)(CheckedField);
