import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {toggleContractId} from '@app/pages/companies-list-page/companies-list-actions';
import {loadCaptchaPublicKey} from '@app/store/actions';
import {loadRegionName} from '@app/store/actions/companies-actions';
import {feEgarantCapthcaEnableSelector} from '@modules/system';

import {CompaniesPage} from './companies-list-page';

const mapStateToProps = createStructuredSelector({
    feEgarantCapthcaEnable: feEgarantCapthcaEnableSelector,
});

const mapDispatchToProps = {
    toggleContractId,
    loadCaptchaPublicKey,
    loadRegionName,
};

export const CompaniesListPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompaniesPage);
