import * as React from 'react';

import * as dictionary from '@app/pages/shared-pages/layout-page/components/navigation/navigation.dic.json';
import {Button} from '@ui-kit';

import {createTranslator} from '@utils/i18n';
import {isDisabledInterface} from '@utils/isDisabled';
import {ButtonTimers} from '../button-timers';

const t = createTranslator(dictionary);

interface ButtonApplyUpdateStatusInterface {
    isUpdateScansStatusDisabled: boolean;
    isScansInProgress: boolean;
    isDisabled: (props: isDisabledInterface) => boolean;
    onUpdateDocumentFilesStatuses: (isCalledByButton?: boolean) => void;
    isLkBlocked: boolean;
    feEgarantCheckResultsInterval: number;
}

export function ButtonApplyUpdateStatus({
    isUpdateScansStatusDisabled,
    isScansInProgress,
    isDisabled,
    onUpdateDocumentFilesStatuses,
    isLkBlocked,
    feEgarantCheckResultsInterval,
}: ButtonApplyUpdateStatusInterface) {
    const expirationTime = Date.now() + feEgarantCheckResultsInterval * 1000;
    const [stateBlockButton, setBlockButton] = React.useState(false);
    const handleEndTimer = () => {
        setBlockButton(false);
    };

    return (
        <>
            {isScansInProgress && stateBlockButton ?
                (
                    <ButtonTimers
                        label={t('navigation.button.apply.repeat-update.status')}
                        primary
                        disabled={
                            isDisabled({
                                isLkBlocked,
                                isScansButtonDisabled: isUpdateScansStatusDisabled,
                            }) ||
                            stateBlockButton
                        }
                        onClick={() => onUpdateDocumentFilesStatuses(true)}
                        handleEndTimer={handleEndTimer}
                        expirationTime={expirationTime}
                    />
                ) :
                (
                    <Button
                        label={t('navigation.button.apply.update.status')}
                        primary
                        disabled={
                            isDisabled({
                                isLkBlocked,
                                isScansButtonDisabled: isUpdateScansStatusDisabled,
                            })
                        }
                        onClick={() => {
                            setBlockButton(true);
                            onUpdateDocumentFilesStatuses(true);
                        }}
                    />
                )}
        </>
    );
}
