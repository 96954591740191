import classNames from 'classnames/bind';
import * as React from 'react';
import {Form, FormSpy} from 'react-final-form';

import {
    Button,
    TextField,
    PasswordField,
    PortalRoot,
} from '@ui-kit';
import {clearPhoneNumber, formatPhoneNumber} from '@utils/formatters';
import {createTranslator} from '@utils/i18n';

import * as dictionary from '../../login-page.dic.json';
import * as styles from './form-login.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export type UserLoginData = {
    contractId: string;
    login: string;
    password: string;
};

export interface FormLoginProps {
    contractId: string;
    sendAuthorizationAction: (value: UserLoginData) => void;
    isLkBlocked: boolean;
    isTimerLoginExpired: boolean;
    setIsLkBlockedTrue: () => void;
    putForgotPassword: () => void;
}

export const FormLogin = ({
    contractId,
    sendAuthorizationAction,
    isLkBlocked,
    isTimerLoginExpired,
    setIsLkBlockedTrue,
    putForgotPassword,
}: FormLoginProps) => {
    const [isFormValid, setFormValid] = React.useState(true);

    const onSubmit = ({login, password}: { login: string; password: string }) => {
        if (isFormValid) {
            sendAuthorizationAction({
                contractId,
                login: clearPhoneNumber(login),
                password,
            });
        }
    };

    const handleForgotPassword = () => (
        isTimerLoginExpired ? setIsLkBlockedTrue() : putForgotPassword()
    );

    return (
        <Form
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form id="login" onSubmit={handleSubmit} className={cn('form-login')}>
                    <h2 className={cn('form-login__header')}>
                        {t('login-page-form-header')}
                    </h2>
                    <div className={cn('form-login__form')}>
                        <div className={cn('form-login__form-field')}>
                            <TextField
                                name="login"
                                label={t('login-page-form-phoneNumber')}
                                parse={formatPhoneNumber}
                                required
                            />
                        </div>

                        <div className={cn('form-login__form-field')}>
                            <PasswordField
                                name="password"
                                label={t('login-page-form-password')}
                                required
                            />
                        </div>
                    </div>
                    <div className={cn('form-login__controls')}>
                        <div className={cn('form-login__controls--form-field-hint')}>
                            {!isLkBlocked && (
                                <div
                                    role="button"
                                    onClick={handleForgotPassword}
                                >
                                    {t('login-page-form-forgot-password')}
                                </div>
                            )}
                        </div>
                        <PortalRoot />
                        <Button
                            label={t('login-page-form-button')}
                            onClick={handleSubmit}
                            type="submit"
                            primary
                            disabled={isLkBlocked}
                        />
                    </div>
                    <FormSpy
                        subscription={{values: true}}
                        onChange={({values}) => setFormValid(
                            values.login?.length > 0 && values.password?.length > 0
                        )}
                    />
                </form>
            )}
        />
    );
};
