import {
    StageInterface,
    TimerInterface,
} from '@app/store/types';
import {handleActions} from '@utils/redux';
import {ActionTypes} from '@utils/types';

import {STAGE} from '../model-constants';

const stageInitialState: StageInterface = {
    currentStage: null,
    isAutoAcceptTimeoutExpired: false,
    isAutoAcceptHeartbeatStopped: false,
    isTimerLoginExpired: false,
    timer: {
        expirationTime: undefined,
        secondsLeft: undefined,
        flkCode: null,
        timerType: null,
    },
};

const stageHandler = handleActions(STAGE);
export const stageReducer = stageHandler({
    [ActionTypes.SET_CURRENT_STAGE]:
        (state: StageInterface, {payload}: {payload: string}) => ({
            ...state,
            currentStage: payload,
        }),

    [ActionTypes.SET_IS_TIMER_LOGIN_EXPIRED_TRUE]:
        (state: StageInterface) => ({
            ...state,
            isTimerLoginExpired: true,
        }),

    [ActionTypes.SET_TIMER]:
        (state: StageInterface, {payload}: {payload: TimerInterface}) => ({
            ...state,
            timer: payload,
        }),
    [ActionTypes.SET_AUTO_ACCEPT_HEARTBEAT_STOPPED]:
        (state: StageInterface, {payload}: {payload: boolean}) => ({
            ...state,
            isAutoAcceptHeartbeatStopped: payload,
        }),

}, stageInitialState);
