import classNames from 'classnames/bind';
import * as React from 'react';

import {Spinner} from '@ui-kit';

import * as styles from './overlay.scss';

const cn = classNames.bind(styles);

interface OverlayProps {
    visible: boolean
}

const toggleBodyLock = (locked: boolean) => {
    const className = cn('overlay__lock');
    if (locked) {
        document.body.classList.add(className);
    } else {
        document.body.classList.remove(className);
    }
};

export class Overlay extends React.PureComponent<OverlayProps> {
    componentDidMount(): void {
        const {visible} = this.props;
        toggleBodyLock(visible);
    }

    componentDidUpdate(): void {
        const {visible} = this.props;
        toggleBodyLock(visible);
    }

    componentWillUnmount(): void {
        toggleBodyLock(false);
    }


    render(): React.ReactNode {
        const {visible} = this.props;

        return !visible ? null : (
            <div className={cn('overlay')}>
                <Spinner />
            </div>
        );
    }
}
