import {createSelector, OutputSelector, Selector} from 'reselect';

import {COMPANIES} from '@app/store/model-constants';
import {AppStateInterface, CompaniesInterface} from '@app/store/types';
import {InsuranceCompany} from '@app/types';
import {LinkDataInterface} from '@modules/contract';

const rootSelector: Selector<any, CompaniesInterface> = (state: AppStateInterface) => state[COMPANIES];

export const companiesListSelector: OutputSelector<
    CompaniesInterface,
    InsuranceCompany[],
    (state: CompaniesInterface) => InsuranceCompany[]
    > = createSelector(rootSelector, (companies: CompaniesInterface) => companies.companiesList);

export const filterableCompaniesListSelector: OutputSelector<
    CompaniesInterface,
    InsuranceCompany[],
    (state: CompaniesInterface) => InsuranceCompany[]
    > = createSelector(rootSelector, (companies: CompaniesInterface) => companies.filterableCompaniesList);

export const insCompanySelector: OutputSelector<
    CompaniesInterface,
    InsuranceCompany | null,
    (state: CompaniesInterface) => InsuranceCompany | null
    > = createSelector(rootSelector, (companies: CompaniesInterface) => companies.insCompany);

export const companyPremiumSelector: OutputSelector<CompaniesInterface, number, (state: CompaniesInterface) => number> =
    createSelector(rootSelector, (companies: CompaniesInterface) => companies.companyPremium);

export const linkDataSelector: OutputSelector<CompaniesInterface, LinkDataInterface,
    (state: CompaniesInterface) => LinkDataInterface> =
    createSelector(rootSelector, (companies: CompaniesInterface) => companies.linkData);

export const insCompanyUrlSelector: OutputSelector<CompaniesInterface, string,
    (state: CompaniesInterface) => string> =
    createSelector(rootSelector, (companies: CompaniesInterface) => companies.linkData?.insCompanyUrl);

export const regionNameSelector: OutputSelector<CompaniesInterface, string,
    (state: CompaniesInterface) => string> =
    createSelector(rootSelector, (companies: CompaniesInterface) => companies.regionName?.regionName);

export const hasInsCompanySecondChosenSelector: OutputSelector<CompaniesInterface, boolean,
    (state: CompaniesInterface) => boolean> =
    createSelector(rootSelector, (companies: CompaniesInterface) => companies?.isSecondCompanyChosen?.isChosen);

export const skDomainSelector: OutputSelector<
    CompaniesInterface,
    string,
    (state: CompaniesInterface) => string
    > = createSelector(rootSelector, (companies: CompaniesInterface) => companies?.isSecondCompanyChosen?.skDomain);
