import {getInsuranceCompanies, reserveContract} from '@app/modules/companies';
import * as dictionaryFlk from '@app/pages/shared-pages/layout-page/flk.dic.json';
import * as dictionaryLayout from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import {
    setInsCompanyNull,
    setContractId,
    setCompanies,
    setFilterableCompanies,
} from '@app/store/actions';
import {setHasInsCompanySecondChosen, setLinkData} from '@app/store/actions/companies-actions';
import {InsCompany2Status, InsuranceCompany} from '@app/types';
import {createTranslator} from '@app/utils/i18n';
import {confirm, ConfirmResult} from '@modules/common-modals';
import {
    getHasInsCompanySecondChosen,
} from '@modules/companies/companies-api';
import {LinkDataInterface} from '@modules/contract';
import {executeAsyncAction} from '@modules/model/actions';
import {DEFAULT_SK_DOMAIN} from '@utils/constants/egarant-settings';
import {MODAL_WIDTH} from '@utils/constants/modal-width';
import {searchFormat} from '@utils/formatters/search-format';
import {DispatchType} from '@utils/redux';

import * as dictionary from './companies-list-page.dic.json';

const l = createTranslator(dictionaryLayout);
const t = createTranslator(dictionary);
const f = createTranslator(dictionaryFlk);

export function toggleContractId(contractId: string): DispatchType {
    return (dispatch: DispatchType) => {
        dispatch(setContractId(contractId));
    };
}

export function loadCompanies(contractId: string, captchaToken: string): DispatchType {
    return (dispatch: DispatchType) => {
        dispatch(setInsCompanyNull());

        executeAsyncAction(dispatch, () => getInsuranceCompanies(contractId, captchaToken))
            .then(data => {
                const companyList = data.map((item: InsuranceCompany) => ({
                    ...item,
                    searchNameLowerCase: searchFormat(item.searchName),
                }));
                dispatch(setCompanies(companyList));
                dispatch(setFilterableCompanies(companyList));
            });
    };
}

export function confirmSelectedCompany(
    contractId: string,
    company: InsuranceCompany,
    linkData: LinkDataInterface
): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(confirm({
            title: l('confirm-modal-title'),
            message: t('companies-list-page-confirm-modal-text', company.name),
            resultHandler: result => {
                if (result === ConfirmResult.CONFIRMED) {
                    executeAsyncAction(dispatch, () => getHasInsCompanySecondChosen(contractId)
                        .then((data: InsCompany2Status) => {
                            if (data.isChosen) {
                                dispatch(confirm({
                                    title: l('confirm-modal-title'),
                                    message: f('16002039'),
                                    width: MODAL_WIDTH,
                                    resultHandler: result => {
                                    },
                                    hasCancelOption: false,
                                }));
                            } else {
                                const executor = () => reserveContract(contractId, company.id)
                                    .then(() => {
                                        dispatch(setLinkData({
                                            ...linkData,
                                            insCompanyId: company.id,
                                            insCompanyName: company.name,
                                            representativesLink: company.link,
                                        }));
                                        dispatch(setHasInsCompanySecondChosen({
                                            isChosen: true,
                                            skDomain: company.skDomain ?? DEFAULT_SK_DOMAIN,
                                        }));
                                    });
                                const errorHandler = () => {
                                    dispatch(setHasInsCompanySecondChosen({
                                        isChosen: false,
                                        skDomain: null,
                                    }));
                                    dispatch(setCompanies([]));
                                    dispatch(setFilterableCompanies([]));
                                };
                                executeAsyncAction(dispatch, executor, errorHandler);
                            }
                        }));
                }
            },
            hasCancelOption: true,
        }));
    };
}
