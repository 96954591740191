import * as React from 'react';

import {TextInput, TextInputProps, TextInputType} from '../text-input';
import {ButtonIcon} from './button-icon';

interface PasswordInputState {
    showPassword: boolean
}

export class PasswordInput extends React.Component<TextInputProps, PasswordInputState> {
    state: PasswordInputState = {
        showPassword: false,
    };

    changeVisibility = (showPassword: boolean) => this.setState(() => ({showPassword}));

    handleIconMouseDown = () => this.changeVisibility(true);

    handleIconMouseUp = () => this.changeVisibility(false);

    render(): React.ReactNode {
        const {props, state: {showPassword}} = this;
        const {disabled} = props;
        const type = showPassword ? TextInputType.TEXT : TextInputType.PASSWORD;
        const icon = (
            <ButtonIcon
                disabled={disabled}
                highlighted={showPassword}
                onMouseDown={disabled ? null : this.handleIconMouseDown}
                onMouseUp={this.handleIconMouseUp}
                onMouseLeave={showPassword ? this.handleIconMouseUp : undefined}
            />
        );

        return (
            <TextInput
                {...props}
                trailingIcon={icon}
                type={type}
            />
        );
    }
}
