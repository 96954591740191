import {DocumentTypes} from '@app/types';

export interface LinkData {
    rsaBaseUrl: string;
    insCompanyId: string;
    insCompanyDikbmId: string;
    insCompanyName: string;
    insCompanyUrl: string;
    vehicleUsageAreaFiasCode: string;
    baseRate: number;
    hasAgreement: boolean;
}

export interface Vehicle {
    typeCategory: TypeCategoryDto;
    maker: MakerDto;
    otherMaker: string;
    model: ModelDto;
    otherModel: string;
    seatsAmount: number;
    maxWeight: number;
    year: number;
    power: number;
    document: VehicleDocument;
    diagnosticCard: DiagnosticCard;
    insuranceStartDate: string;
    insuranceEndDate: string;
    usePeriod1: number;
    usePeriod1StartDate: string;
    usePeriod1EndDate: string;
    usePeriod2: number;
    usePeriod2StartDate: string;
    usePeriod2EndDate: string;
    usePeriod3: number;
    usePeriod3StartDate: string;
    usePeriod3EndDate: string;
    vin: string;
    bodyNumber: string;
    schassisNumber: string;
    numberPlate: string;
    usePurpose: string;
    registrationCountry: string;
    isOnTheWayToRegistration: boolean;
    isUsedWithTrailer: boolean;
    isShortInsurancePolicy?: boolean;
}

export interface ValidationData {
    validationResultFields: ValidationFields;
    validationResultEntities: ValidationEntities[];
    validationResultScans: DocumentTypes[];
    driverIndexes: number[];
    isValidationInitiated: boolean;
    isScanSendingInitiated: boolean;
    isScanEditingInitiated: boolean;
    isValidationInProgress: boolean;
    isDataEdited: boolean;
}

export enum ValidationEntities {
    OWNER = 'owner',
    INSURER = 'insurer',
    VEHICLE = 'vehicle',
    DRIVERS = 'drivers',
}

export interface ValidationFields {
    [ValidationEntities.OWNER]: MismatchData[];
    [ValidationEntities.INSURER]: MismatchData[];
    [ValidationEntities.VEHICLE]: MismatchData[];
    [ValidationEntities.DRIVERS]: DriverData[];
    contract: MismatchData[];
    driversLimit: MismatchData;
}

export interface DriverData {
    driverIndex: number;
    driver: MismatchData[];
}

export interface MismatchData {
    code: string;
    message: string;
    isCritical: boolean;
    mismatchFields: MismatchField[];
}

export interface MismatchField {
    fieldName: string;
    fieldPath: string;
    fieldValue: string;
}

export interface CalculationData {
    amount: number;
    tb: number;
    kt: number;
    kbm: number;
    kbc: number;
    kc: number;
    kp: number;
    km: number;
    ko: number;
}

export interface VehicleDocument {
    documentType: string;
    series: string;
    number: string;
    dateGiven: string;
}

export interface DiagnosticCard {
    number: string;
    dateEnd: string;
}

export interface TransdekraMakerPage {
    totalCount: number;
    objects: MakerDto[];
}

export interface TransdekraModelPage {
    totalCount: number;
    objects: ModelDto[];
}

export interface TypeCategoryDto {
    code: string;
    shortName: string;
    fullName: string;
}

export interface MakerDto {
    name: string;
    makerKey: string;
}

export interface ModelDto {
    name: string;
    modelKey: string;
}

export interface FiasPage {
    totalCount: number;
    objects: FiasElement[];
}

export interface FiasElement {
    name: string;
    fiasId: string;
    elementType: FiasElementType;
    strstatus?: number;
}

export interface FiasStructElement {
    name: string;
    fiasId: string;
    elementType: FiasElementType;
    strstatus?: string;
}

export enum FiasElementType {
    REGION = 'REGION',
    DISTRICT = 'DISTRICT',
    CITY = 'CITY',
    LOCALITY = 'LOCALITY',
    STREET = 'STREET',
    HOUSE = 'HOUSE',
    BUILDNUM = 'BUILDNUM',
    STRUCNUM = 'STRUCNUM',
    ROOM = 'ROOM'
}
// для запроса к api этот enum вместо FiasElementType для HOUSE, BUILDNUM, STRUCNUM, ROOM по задаче RAMIEPT-232001
export enum FiasElementTypeApi {
    HOUSENUM = 'housenum',
    BUILDNUM = 'buildnum',
    STRUCNUM = 'strucnum',
    FLAT = 'flat'
}
