import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    confirmExpiredTimer,
    setIActiveRegisterBtnAware,
    setIsActiveRegisterBtnAgree,
} from '@app/store/actions';
import {linkDataSelector} from '@modules/companies';
import {mismatchDataSelector} from '@modules/modals';
import {
    isActiveRegisterBtnAwareSelector,
    isActiveRegisterBtnAgreeSelector,
    isLkBlockedSelector,
} from '@modules/navigation';
import {flkCodeSelector} from '@modules/stage';

import {ConsentText} from './consent-text';

const mapStateToProps = createStructuredSelector({
    linkData: linkDataSelector,
    isActiveRegisterBtnAgree: isActiveRegisterBtnAgreeSelector,
    isActiveRegisterBtnAware: isActiveRegisterBtnAwareSelector,
    flkCode: flkCodeSelector,
    isLkBlocked: isLkBlockedSelector,
    mismatchData: mismatchDataSelector,
});

const mapDispatchToProps = {
    setIsActiveRegisterBtnAgree,
    setIActiveRegisterBtnAware,
    confirmExpiredTimer,
};

export const ConsentTextContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsentText);
