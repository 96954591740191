import classNames from 'classnames/bind';
import * as React from 'react';

import {Checkbox} from '@app/components';
import {AgreementBlockInterface} from '@app/pages/agreement-page/agreement-page-types';

import * as styles from './agreement-block.scss';

const cn = classNames.bind(styles);

export function AgreementBlock({
    title,
    text,
    checkboxText,
    isAgreementCaptchaVisible,
    setIsAgreementCaptchaVisible,
    setIsAgreementBtnEnabled,
}: AgreementBlockInterface) {
    return (
        <div className={cn('agreement')}>
            <div className={cn('agreement__content')}>
                <h2 className={cn('agreement__header')}>{title}</h2>
                <div className={cn('agreement__text')} dangerouslySetInnerHTML={{__html: text}} />
                <div className={cn('agreement__checkbox')}>
                    <Checkbox
                        id="agreementCheckbox"
                        checked={isAgreementCaptchaVisible}
                        label={checkboxText}
                        onChange={() => {
                            setIsAgreementCaptchaVisible(!isAgreementCaptchaVisible);
                            setIsAgreementBtnEnabled(isAgreementCaptchaVisible);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
