import * as React from 'react';

import {Field} from '../field';
import {PasswordFieldWithFieldAdapter} from './password-field-with-field-adapter';

interface PasswordFieldProps {
    disabled?: boolean;
    invalid?: boolean;
    height?: string;
    label: string;
    onChange?: (code: string) => void;
    name: string;
    required?: boolean;
    value?: string;
    width?: string;
    validators?: Function[];
}

export function PasswordField(props: PasswordFieldProps) {
    return (
        <Field
            {...props}
            component={PasswordFieldWithFieldAdapter}
        />
    );
}
