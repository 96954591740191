import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {confirmRestore} from '@app/pages/restore-page/restore-actions';
import {
    processInternalServerError,
    setCurrentStage,
    setIsRestoreCaptcha,
} from '@app/store/actions';
import {isLkBlockedSelector} from '@modules/navigation';
import {contractIdSelector} from '@modules/contract';
import {
    feEgarantCapthcaEnableSelector,
    isRestoreCaptchaVisibleSelector,
    captchaPublicKeySelector,
} from '@modules/system';

import {FormRestore} from './form-restore';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isRestoreCaptchaVisible: isRestoreCaptchaVisibleSelector,
    captchaPublicKey: captchaPublicKeySelector,
    isLkBlocked: isLkBlockedSelector,
    feEgarantCapthcaEnable: feEgarantCapthcaEnableSelector,
});

const mapDispatchToProps = {
    confirmRestore,
    setIsRestoreCaptcha,
    setCurrentStage,
    handleErrorCaptcha: processInternalServerError,
};

export const FormRestoreContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormRestore);
