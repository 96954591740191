import * as React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    KeyCode,
    EventName,
} from '@utils/constants';
import {hideModal} from '@app/store/actions';

import {Modals} from './modals-component';
import {getModal} from './modals-registry';
import {
    modalsSelector,
    modalsCountSelector,
    activeModalSelector,
} from './modals-selectors';

type ModalRootContainerProps = {
    activeModal?: string;
    modals: string[];
    modalsCount: number;
    closeModal: () => void;
};

const mapStateToProps = createStructuredSelector({
    activeModal: activeModalSelector,
    modals: modalsSelector,
    modalsCount: modalsCountSelector,
});

const mapDispatchToProps = {
    closeModal: hideModal,
};

export class ModalRootContainer extends React.PureComponent<ModalRootContainerProps> {
    componentDidMount() {
        window.addEventListener(EventName.KEY_DOWN, this.closeOnEsc);
    }

    componentWillUnmount() {
        window.removeEventListener(EventName.KEY_DOWN, this.closeOnEsc);
    }

    closeOnEsc = (e: KeyboardEvent) => {
        const {keyCode} = e;
        const {activeModal} = this.props;

        if (activeModal && keyCode === KeyCode.ESCAPE) {
            this.handleClose();
            e.preventDefault();
        }
    };

    handleClose = () => {
        const {
            activeModal,
            closeModal,
        } = this.props;
        const {isClosable} = getModal(activeModal);

        if (activeModal && isClosable) {
            closeModal();
        }
    };

    render() {
        const {
            modals,
            activeModal,
        } = this.props;
        const {closeOnOverlay} = getModal(activeModal) || {closeOnOverlay: false};

        return (
            <Modals
                modals={modals}
                onClose={this.handleClose}
                closeOnOverlay={closeOnOverlay}
            />
        );
    }
}

export const ModalRoot = connect(mapStateToProps, mapDispatchToProps)(ModalRootContainer);
