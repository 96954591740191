import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './table-layout.scss';

const cn = classNames.bind(styles);

interface TableProps {
    title?: string,
    buttons?: React.ReactNode,
    body: React.ReactNode
}

export function TableLayout({
    title,
    buttons,
    body,
}: TableProps) {
    return (
        <div className={cn('table-layout')}>
            {
                (buttons || title) && (
                    <div className={cn('table-layout__header')}>
                        <div className={cn('table-layout__title')}>{title}</div>
                        {buttons}
                    </div>
                )
            }
            <div className={cn('table-layout__body')}>
                {body}
            </div>
        </div>
    );
}
