import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {contractValidation} from '@app/store/actions';
import {
    isCheckDataBtnHiddenSelector,
    isValidationCheckedSelector,
    isLkBlockedSelector,
} from '@modules/navigation';
import {contractIdSelector} from '@modules/contract';

import {ButtonCheckData} from './button-check-data';

const mapStateToProps = createStructuredSelector({
    contractId: contractIdSelector,
    isCheckDataBtnHidden: isCheckDataBtnHiddenSelector,
    isValidationChecked: isValidationCheckedSelector,
    isLkBlocked: isLkBlockedSelector,
});

const mapDispatchToProps = {contractValidation};

export const ButtonCheckDataContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonCheckData);
