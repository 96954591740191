import * as React from 'react';

interface ValidationPollerInterface {
    contractId: string;
    isValidationInProgress: boolean;
    isValidationWaiting: boolean;
    isAllScansApproved: boolean;
    getContractValidation: (contractId: string, validationTimerExpired: boolean) => void;
}

const TIMER_DELAY_BASE = 1000;
const TIMER_DELAY_MAX = 3000;
const TIMER_DELAY_COEF = 1.1;

export function ValidationPoller({
    contractId,
    isValidationInProgress,
    isValidationWaiting,
    isAllScansApproved,
    getContractValidation,
}: ValidationPollerInterface) {
    const [firstStart, setFirstStart] = React.useState(0);
    const [firstStartAfterScansApproved, setFirstStartAfterScansApproved] = React.useState(0);
    const [lastStart, setLastStart] = React.useState(0);
    const [delay, setDelay] = React.useState(TIMER_DELAY_BASE);

    const isValidation = isValidationInProgress && !isValidationWaiting;
    const isAllScansApprovedAndNotFirstStartAfter = isAllScansApproved && firstStartAfterScansApproved === 0;
    const isNotAllScansApprovedAndFirstStart = !isAllScansApproved && firstStart === 0;

    React.useEffect(() => {
        const now = Date.now();
        const tooFast = now < lastStart + delay / TIMER_DELAY_COEF;

        if (contractId && isValidation && !tooFast) {
            if (isAllScansApprovedAndNotFirstStartAfter) {
                setFirstStartAfterScansApproved(now);
            } else if (isNotAllScansApprovedAndFirstStart) {
                setFirstStart(now);
            }
            setLastStart(now);
            setTimeout(() => {
                getContractValidation(contractId, false);
            }, delay);
            setDelay(prevDelay => Math.min(prevDelay * TIMER_DELAY_COEF, TIMER_DELAY_MAX));
        }
    }, [
        delay,
        lastStart,
        contractId,
        getContractValidation,
        isValidation,
        isAllScansApprovedAndNotFirstStartAfter,
        isNotAllScansApprovedAndFirstStart,
    ]);

    return (
        <>
        </>
    );
}
