export enum VerificationStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum DocumentTypes {
    DIAGNOSTIC_CARD = 'diagnosticCard',
    DRIVERS_DOCUMENTS = 'driversDocuments',
    INSURER_DOCUMENT = 'insurerDocument',
    OWNER_DOCUMENT = 'ownerDocument',
    VEHICLE_DOCUMENT = 'vehicleDocument',
}

export enum ScansStage {
    ALL_SCANS_APPROVED,
    NEW_SCANS_TO_UPLOAD,
    SOME_SCANS_TO_EDIT,
}

export interface ScansData {
    scanFilesData: ScanFileData[];
}

export interface ScanFileData {
    fileName: string;
    fileGuid: string;
    fileOrderInGroup: number;
}

export interface DriverDocumentData {
    driverIndex: number;
    scanFilesData: ScanFileData[];
}

export interface CheckTaskWithScansData {
    [DocumentTypes.DIAGNOSTIC_CARD]: ScansData;
    [DocumentTypes.DRIVERS_DOCUMENTS]: DriverDocumentData[];
    [DocumentTypes.INSURER_DOCUMENT]: ScansData;
    [DocumentTypes.OWNER_DOCUMENT]: ScansData;
    [DocumentTypes.VEHICLE_DOCUMENT]: ScansData;
}

export interface DriverDocumentVerificationData {
    driverIndex: number;
    scanFilesData: ScanFileData[];
    verificationResult: DocumentScansVerificationResult;
}

export interface DocumentScansVerificationResult {
    status: VerificationStatus;
    rejectReasonSum: string;
    rejectReasons: string[];
}

export interface ScansVerificationData {
    scanFilesData: ScanFileData[];
    verificationResult: DocumentScansVerificationResult;
}

export interface CheckResultsWithScansData {
    [DocumentTypes.DIAGNOSTIC_CARD]: ScansVerificationData;
    [DocumentTypes.DRIVERS_DOCUMENTS]: DriverDocumentVerificationData[];
    [DocumentTypes.INSURER_DOCUMENT]: ScansVerificationData;
    [DocumentTypes.OWNER_DOCUMENT]: ScansVerificationData;
    [DocumentTypes.VEHICLE_DOCUMENT]: ScansVerificationData;
}

export interface InsCompanyId {
    id: string;
}

export interface ScanUploadData {
    fileName: string;
}

export interface PaymentUrlResponse {
    paymentUrl: string;
    errorCode: number;
    errorMessage: string;
}

export interface ConclusionResponse {
    code: number;
    message: string;
    policyNumber: string;
}

export interface EgarantContractInfo {
    contractId: string;
    status: EgarantContactStatus;
    firstInsurerId: string;
    paymentTransactionId: string;
    paymentDate: string;
}

export enum EgarantContactStatus {
    Draft = 'Draft', // Проект
    Signed = 'Signed', // Заключен
    Revoked = 'Revoked', // Отмена (Отзыв)
    Cancelled = 'Cancelled', // Аннулирован
    Terminated = 'Terminated', // Досрочно рассторгнут
}

export interface RegionName {
    regionName: string;
}

export interface SpreaderContractInfo {
    regionId: string;
    firstInsurerId: string;
}

export interface OsagoContractInfo {
    vehicleCategoryCode: string;
    vehicleTypeCode: string;
    vehicleTypeAndAppointmentCode: string;
    vehicleCountryCode: string;
    registrationTransitIndicator: boolean;
    primaryDrivingAreaCode: string;
    vehiclePower: number;
    vehicleTrailer: boolean;
    utilisationPeriod1Begin: string;
    utilisationPeriod1End: string;
    utilisationPeriod2Begin: string;
    utilisationPeriod2End: string;
    utilisationPeriod3Begin: string;
    utilisationPeriod3End: string;
    ownerType: string;
    knBasisCode: string;
    driverLimitIndicator: string;
    drivers: DriverInfo[];
    closingDate: string;
    insuranceDuration: number;
    insurerCode: string;
    kbm: number;
}

export interface DriverInfo {
    age: number;
    drivingExperience: number;
}

export interface OsagoKbmCalcRequestInfo {
    contract: ContractDataInfo;
    persons: PersonTypeInfo[];
    organization: OrganizationTypeInfo;
}

export interface ContractDataInfo {
    insurer: string;
    kbmValue: number;
    stateSecret: boolean;
    closingDate: string;
    beginDate: string;
    endDate: string;
    driverLimit: boolean;
    registrationTransitIndicator: boolean;
}

export interface PersonTypeInfo {
    goldenRecordIds: number[];
    kbmValue: number;
    kbmRequestId: string;
    foundInPolicy: boolean;
    firstClosingDate: string;
    identityCard: IdentityCardInfo;
    identityCardAdd: IdentityCardInfo;
    driverLicense: DriverLicenseInfo;
    driverLicenseAdd: DriverLicenseInfo;
}

export interface IdentityCardInfo {
    country: string;
    documentType: number;
    externalObjectId: string;
    series: string;
    number: string;
    person: PersonalDataInfo;
}

export interface DriverLicenseInfo {
    country: string;
    driverLicenceType: string;
    externalObjectId: string;
    series: string;
    number: string;
    person: PersonalDataInfo;
}

export interface PersonalDataInfo {
    lastName: string;
    firstName: string;
    middleName: string;
    birthDate: string;
}

export interface OrganizationTypeInfo {
    goldenRecordIds: number[];
    resident: boolean;
    kbmValue: number;
    kbmRequestId: string;
    foundInPolicy: boolean;
    firstClosingDate: string;
    registrationDocument: OrgIDInfo;
    registrationDocumentAdd: OrgIDInfo;
}

export interface OrgIDInfo {
    resident: boolean;
    inn: string;
    fullName: string;
    externalObjectId: string;
}

export interface DocumentData {
    value: string;
    label: string;
}

export interface EtlEgarantContract {
    contractBusinessId: string;
    contractId: string;
    firstInsurerId: string;
    secondInsurerId: string;
    merchantOrderNumber: string;
    paymentTransactionId: string;
    paymentDate: string;
}

export interface NavigationState {
    step: string;
    paymentUrl: string;
}

export enum NavigationStep {
    STEP_1 = 'STEP_1',
    STEP_2 = 'STEP_2',
    STEP_3 = 'STEP_3',
    STEP_4 = 'STEP_4',
    ALFA = 'ALFA',
    CONCLUDE_PAYMENT = 'CONCLUDE_PAYMENT',
    FAIL_PAYMENT = 'FAIL_PAYMENT',
}
