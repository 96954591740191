export const NAME = 'name';
export const PREMIUM = 'premium';
export const MODEL = 'model';
export const OTHER_MAKER = 'otherMaker';
export const OTHER_MODEL = 'otherModel';
export const TYPE_CATEGORY = 'typeCategory';
export const RUSSIAN_FEDERATION = 'Российская Федерация';
export const MAKER = 'maker';
export const ADDRESS = 'address';
export const ADDRESS_TEXT = 'addressText';
export const CHECK = 'check';
