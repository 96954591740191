import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {skDomainSelector} from '@modules/companies';
import {loadEgarantProperties} from '@app/store/actions';

import {Layout} from './layout';

const mapStateToProps = createStructuredSelector({
    skDomain: skDomainSelector,
});

const mapDispatchToProps = {
    loadEgarantProperties,
};

export const LayoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout);
