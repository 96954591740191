import * as React from 'react';

import {clearPhoneNumber} from '@utils/formatters';

export const MockNoCaptcha = ({
    contractId,
    confirmRestore,
    phoneNumber,
}: {
    contractId: string;
    confirmRestore(contractId: string, phoneNumber: string, captchaHash?: string): void;
    phoneNumber: string,
}) => {
    React.useEffect(() => {
        confirmRestore(
            contractId,
            clearPhoneNumber(phoneNumber)
        );
    }, []);
    return (<></>);
};
