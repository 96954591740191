import * as React from 'react';

import {Field} from '../field';
import {TextInputWithFieldAdapter} from './text-input-with-field-adapter';

interface TextFieldProps {
    disabled?: boolean;
    isValid?: boolean;
    parse?: (value: string) => void;
    label: string;
    maxLength?: number;
    name: string;
    onChange?: (value: string) => void;
    required?: boolean;
    validators?: Function[];
    width?: string | number;
}

export function TextField(props: TextFieldProps) {
    const {name, ...restProps} = props;

    return (
        <Field
            {...restProps}
            name={name}
            component={TextInputWithFieldAdapter}
        />
    );
}
