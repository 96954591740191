import * as React from 'react';

import {RadioButtonProps} from '../../radio-button-group/radio-button';
import {Field} from '../field';
import {CommonFieldProps} from '../field-types';
import {RadioButtonGroupWithFieldAdapter} from './radio-button-group-field-adapter';

interface Props extends CommonFieldProps{
    name: string;
    value?: any;
    items: any[];
    isDisabled?: (button: object) => boolean;
    onChange?: (value: any) => void;
    getButtonKey?: (item: RadioButtonProps) => string;
}
export const RadioButtonGroupField = (props: Props) => (
    <Field
        {...props}
        component={RadioButtonGroupWithFieldAdapter}
    />
);
