export {
    modalsStateMock,
    mismatchDataMock,
} from './modals-mocks';
export {
    companiesStateMock,
    linkDataEmptyMock,
    regionNameMock,
    linkDataMock,
    companiesMock,
    representativesLinkMock,
} from './companies-mocks';
export {
    webSiteRsaMock,
    propertiesMock,
    serverTimeMock,
    systemStateMock,
} from './system-mocks';
export {
    contractStateMock,
    personMock,
    insurerMock,
    ownerMock,
    driversMock,
    vehicleMock,
    uploadedDocumentFilesMock,
    contractIdMock,
    dictionariesMock,
    mockScanDiagnosticCard,
    mockScanDriversDocuments,
    typeCategoriesMock,
    trandekraMakerPageMock,
    transdekraModelPageMock,
    documentMock,
    calculationDataMock,
    contractNavigationStateMock,
    documentsListMock,
    parsedKitMock,
    parsedKitMockEmpty,
    addressesMock,
} from './contract-mocks';
export {
    stageStateMock,
    nullTimer,
    timerMock,
} from './stage-mocks';
export {navigationStateMock} from './navigation-mocks';
export {
    userStateMock,
    personRegistrationDataMock,
    responseCalculatedDataUserExistsMock,
    conclusionResponseMock,
    paymentUrlResponseMock,
    userAuthMock,
    SK_DOMAIN_MOCK,
    CAPTCHA_TOKEN_MOCK,
    LOGIN_MOCK,
    PASSWORD_MOCK,
    USER_AUTH_STATUS_ACTIVE_MOCK,
} from './user-mocks';
export {
    validationStateMock,
    mappedValidationMock,
    secondsLeftMock,
    isOwnerMock,
} from './validation-mocks';
export {
    documentsMock,
    getUploadedDocumentsFilesWithStatus,
    fileNameMock,
} from './documents-mocks';
