interface getStyleProps {
    width?: string | number,
    isSmall?: boolean
}

export const getStyle = ({width, isSmall}: getStyleProps) => {
    const style: {
        width?: string | number;
        height?: string;
    } = {};
    if (width) {
        style.width = width;
    }
    if (isSmall) {
        style.height = '30px';
    }

    return style;
};
