import classNames from 'classnames/bind';
import * as React from 'react';

import {noop} from '@utils/functions';

import {IconButtonType} from './icon-button-types';

import * as styles from './icon-button.scss';

interface IconButtonProps {
    /*  icon for button */
    icon: any
    /* type of icon button: ICON(default), BORDERED, MESSAGE) */
    type?: IconButtonType
    /* function handle mouse click */
    disabled?: boolean
    /* boolean flag for disabling icon button */
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onMouseUp?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    withRef?: (ref: HTMLElement) => void,
    className?: string
}

interface IconButtonState {
    pressed: boolean,
    focused: boolean
}

const cn = classNames.bind(styles);

export class IconButton extends React.Component<IconButtonProps, IconButtonState> {
    static defaultProps = {
        disabled: false,
        onClick: noop,
        onMouseDown: noop,
        onMouseLeave: noop,
        onMouseUp: noop,
        type: IconButtonType.ICON,
    };

    state: IconButtonState = {
        pressed: false,
        focused: false,
    };

    handleMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const {onMouseDown} = this.props;
        this.setState({pressed: true});
        onMouseDown(e);
    };

    handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const {onMouseLeave} = this.props;
        this.setState({
            pressed: false,
            focused: false,
        });
        onMouseLeave(e);
    };

    handleMouseUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const {onMouseUp} = this.props;
        this.setState({
            pressed: false,
            focused: false,
        });
        onMouseUp(e);
    };

    handleFocus = () => this.setState({focused: true});

    handleRef = (ref: HTMLElement) => {
        const {withRef} = this.props;
        if (withRef) {
            withRef(ref);
        }
    };

    render() {
        const {
            disabled, icon: Icon, type, onClick, className,
        } = this.props;
        const {pressed, focused} = this.state;

        const actualIcon = Icon && !React.isValidElement(Icon) ? <Icon /> : Icon;
        const classes = cn('icon-button', className, {
            'icon-button--bordered': type === IconButtonType.BORDERED,
            'icon-button--message': type === IconButtonType.MESSAGE,
            'icon-button--warning': type === IconButtonType.WARNING,
            'icon-button--primary': type === IconButtonType.PRIMARY,
            'icon-button--pressed': pressed,
            'icon-button--disabled': disabled,
            'icon-button--focused': focused,
        });

        return (
            <button
                className={classes}
                disabled={disabled}
                onClick={disabled ? null : onClick}
                onMouseDown={disabled ? null : this.handleMouseDown}
                onFocus={disabled ? null : this.handleFocus}
                onMouseUp={disabled ? null : this.handleMouseUp}
                onMouseLeave={disabled ? null : this.handleMouseLeave}
                ref={this.handleRef}
                type="button"
            >
                {actualIcon}
            </button>
        );
    }
}
