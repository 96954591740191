import classNames from 'classnames/bind';
import * as React from 'react';

import * as styles from './day-view.scss';

const cn = classNames.bind(styles);

interface WeekDaysProps {
    weekdays: Array<string>;
}

export function WeekdaysPanel({weekdays}: WeekDaysProps) {
    return (
        <ul className={cn('day-view__weekdays-panel')}>
            {weekdays.map((day, index) => (
                <li
                    className={cn(
                        'day-view__weekday',
                        index === weekdays.length - 1 && 'day-view__weekday--last'
                    )}
                    key={day}
                >
                    {day}
                </li>
            ))}
        </ul>
    );
}

WeekdaysPanel.defaultProps = {
    weekdays: [],
};
