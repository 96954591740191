import {
    FiasElementType,
    CheckResultsWithScansData,
    CheckTaskWithScansData,
    Insurer,
    Drivers,
    Owner,
    Vehicle,
    TransdekraMakerPage,
    TransdekraModelPage,
    FiasPage,
    TypeCategoryDto,
    CurrentTimerData,
    ValidationData,
    NavigationState,
    NavigationStep,
    PersonType,
    DocumentTypes,
    OwnerInsurerAddresses,
    FiasElementTypeApi,
} from '@app/types';
import {
    API_V1_AUDIT_URL,
    API_V1_EGARANT_CONTRACT_UI,
    API_V1_EGARANT_TIMERS_UI,
    getData,
    postData,
    putData,
} from '@utils/api';
import {SelectOption} from '@utils/formatters/select-option';
import {toUrlParams} from '@utils/request';
import {
    MODEL,
    TYPE_CATEGORY,
    MAKER,
    ADDRESS,
    CHECK,
} from '@utils/constants';

const SCANS = 'scans';
const UPLOAD = 'upload';
const EDITING = 'editing';
const RESULTS = 'results';
const IDENTITY_CARD = 'identityCard';
const ORGANIZATION_REG_DOC = 'organizationRegDoc';
const VEHICLE_PURPOSE = 'vehiclePurpose';

const FIRST_PAGE_OPENED = 'firstPageOpened';
const FOURTH_PAGE_OPENED = 'fourthPageOpened';
const RESERVATION_FAILED = 'reservationFailed';
const NAVIGATION = 'navigation';

const TYPES = 'types';

const TIMER = 'timer';
const DATA = 'data';

export function getInsurerData(contractId: string): Promise<Insurer> {
    const INSURER_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.INSURER}`;
    return getData(INSURER_URL);
}

export function getOwnerData(contractId: string): Promise<Owner> {
    const OWNER_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.OWNER}`;
    return getData(OWNER_URL);
}

export function getVehicleData(contractId: string): Promise<Vehicle> {
    const VEHICLE_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.VEHICLE}`;
    return getData(VEHICLE_URL);
}

export function getDriversData(contractId: string): Promise<Drivers> {
    const DRIVERS_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.DRIVERS}`;
    return getData(DRIVERS_URL);
}

export function getStep4Data(contractId: string): Promise<OwnerInsurerAddresses> {
    const STEP4_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/step4`;
    return getData(STEP4_URL);
}

export function saveInsurerData(contractId: string, data: Insurer): Promise<ValidationData> {
    const INSURER_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.INSURER}`;
    return postData(INSURER_URL, {data});
}

export function saveOwnerData(contractId: string, data: Owner): Promise<ValidationData> {
    const OWNER_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.OWNER}`;
    return postData(OWNER_URL, {data});
}

export function saveVehicleData(contractId: string, data: Vehicle): Promise<ValidationData> {
    const VEHICLE_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.VEHICLE}`;
    return postData(VEHICLE_URL, {data});
}

export function saveDriversData(contractId: string, data: Drivers): Promise<ValidationData> {
    const DRIVERS_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${PersonType.DRIVERS}`;
    return postData(DRIVERS_URL, {data});
}

export function uploadScan(contractId: string, data: FormData): Promise<string> {
    const SCANS_UPLOAD_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${SCANS}/${UPLOAD}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    return postData(SCANS_UPLOAD_URL, {data, headers});
}

export function checkScans(contractId: string, data: CheckTaskWithScansData): Promise<null> {
    const SCANS_CHECK_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${SCANS}/${CHECK}`;
    return postData(SCANS_CHECK_URL, {data});
}

export function scansStatusesRequest(
    contractId: string,
    byButton: boolean = false,
    expired?: boolean
): Promise<CheckResultsWithScansData> {
    const params = {
        expired,
    };
    const headers = {
        byButton: String(byButton),
    };
    const CHECK_RESULTS_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${SCANS}/${CHECK}/${RESULTS}`;
    return getData(CHECK_RESULTS_URL, {headers, params});
}

export function getIdentityCardTypes(): Promise<SelectOption<string>[]> {
    const IDENTITY_CARD_URL = `${API_V1_EGARANT_CONTRACT_UI}/${TYPES}/${IDENTITY_CARD}`;
    return getData(IDENTITY_CARD_URL);
}

export function getOrganizationRegDocTypes(): Promise<SelectOption<string>[]> {
    const ORGANIZATION_REG_DOC_URL = `${API_V1_EGARANT_CONTRACT_UI}/${TYPES}/${ORGANIZATION_REG_DOC}`;
    return getData(ORGANIZATION_REG_DOC_URL);
}

export function getVehicleDocumentTypes(): Promise<SelectOption<string>[]> {
    const VEHICLE_DOCUMENT_URL = `${API_V1_EGARANT_CONTRACT_UI}/${TYPES}/${DocumentTypes.VEHICLE_DOCUMENT}`;
    return getData(VEHICLE_DOCUMENT_URL);
}

export function getVehiclePurposeTypes(): Promise<SelectOption<string>[]> {
    const VEHICLE_PURPOSE_URL = `${API_V1_EGARANT_CONTRACT_UI}/${TYPES}/${VEHICLE_PURPOSE}`;
    return getData(VEHICLE_PURPOSE_URL);
}

export function getMakers(
    startsWith: string,
    pageIndex: number,
    pageSize: number
): Promise<TransdekraMakerPage> {
    if (startsWith && startsWith.length < 3) {
        return Promise.resolve({
            objects: [],
            totalCount: 0,
        });
    }
    const params = {
        pageIndex,
        pageSize,
        startsWith,
    };
    const MAKER_URL = `${API_V1_EGARANT_CONTRACT_UI}/${PersonType.VEHICLE}/${MAKER}`;
    return getData(MAKER_URL, {params});
}

export function getModels(
    maker: string,
    startsWith: string,
    pageIndex: number,
    pageSize: number
): Promise<TransdekraModelPage> {
    if (startsWith && startsWith.length < 3) {
        return Promise.resolve({
            objects: [],
            totalCount: 0,
        });
    }
    const params = {
        pageIndex,
        pageSize,
        startsWith,
    };
    const MODEL_URL = `${API_V1_EGARANT_CONTRACT_UI}/${PersonType.VEHICLE}/${MAKER}/${maker}/${MODEL}`;
    return getData(MODEL_URL, {params});
}

export function getTypesCategoriesByMakerAndModel(
    maker: string,
    model: string
): Promise<TypeCategoryDto[]> {
    const params = {
        maker,
        model,
    };
    const TYPE_CATEGORY_URL = `${API_V1_EGARANT_CONTRACT_UI}/${PersonType.VEHICLE}/${TYPE_CATEGORY}`;
    return getData(TYPE_CATEGORY_URL, {params});
}

type OptionsGetFiasPageDataRequest = {
    type: FiasElementType | FiasElementTypeApi;
    startsWith: string;
    pageIndex: number;
    pageSize: number;
    parentCode?: string;
    houseNum?: string;
    buildNum?: string;
    minLengthStartsWith?: number;
}

export function getFiasPageDataRequest({
    type,
    startsWith,
    pageIndex,
    pageSize,
    minLengthStartsWith,
    parentCode,
    houseNum,
    buildNum,
}: OptionsGetFiasPageDataRequest): Promise<FiasPage> {
    if (startsWith && startsWith.length < minLengthStartsWith) {
        return Promise.resolve({
            objects: [],
            totalCount: 0,
        });
    }
    const params = {
        pageIndex,
        pageSize,
        startsWith,
        parentCode,
        houseNum,
        buildNum,
    };
    const ADDRESS_URL = `${API_V1_EGARANT_CONTRACT_UI}/${ADDRESS}/${type}`;
    return getData(ADDRESS_URL, {params});
}

export function putScansEditingRequest(contractId: string): Promise<null> {
    const SCANS_EDITING_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${SCANS}/${EDITING}`;
    return putData(SCANS_EDITING_URL);
}

export function getTimerData(contractId: string): Promise<CurrentTimerData> {
    const TIMER_DATA_URL = `${API_V1_EGARANT_TIMERS_UI}/${contractId}/${TIMER}/${DATA}`;
    return getData(TIMER_DATA_URL);
}

export function getFirstPageOpened(contractId: string): Promise<null> {
    const FIRST_PAGE_OPENED_URL = `${API_V1_AUDIT_URL}/${contractId}/${FIRST_PAGE_OPENED}`;
    return postData(FIRST_PAGE_OPENED_URL, {});
}

export function getFourthPageOpened(contractId: string): Promise<null> {
    const FOURTH_PAGE_OPENED_URL = `${API_V1_AUDIT_URL}/${contractId}/${FOURTH_PAGE_OPENED}`;
    return postData(FOURTH_PAGE_OPENED_URL, {});
}

export function getReservationFailed(contractId: string): Promise<null> {
    const RESERVATION_FAILED_URL = `${API_V1_AUDIT_URL}/${contractId}/${RESERVATION_FAILED}`;
    return postData(RESERVATION_FAILED_URL, {});
}

export function getNavigationState(contractId: string): Promise<NavigationState> {
    const NAVIGATION_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${NAVIGATION}`;
    return getData(NAVIGATION_URL);
}

export function sendNavigationState(contractId: string, step: NavigationStep): Promise<null> {
    const params = toUrlParams({
        step,
    });
    const NAVIGATION_URL = `${API_V1_EGARANT_CONTRACT_UI}/${contractId}/${NAVIGATION}?${params}`;
    return postData(NAVIGATION_URL, {});
}
