import classNames from 'classnames/bind';
import * as React from 'react';
import {Form, FormSpy} from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';

import {Button, TextField, PortalRoot} from '@ui-kit';
import {AppStages} from '@utils/constants';
import {clearPhoneNumber, formatPhoneNumber} from '@utils/formatters';
import {createTranslator} from '@utils/i18n';

import {MockNoCaptcha} from './components/mock-no-captcha';

import * as dictionary from '../../restore-page.dic.json';
import * as styles from './form-restore.scss';

const cn = classNames.bind(styles);
const t = createTranslator(dictionary);

export interface FormRestoreProps {
    contractId: string;
    confirmRestore(contractId: string, phoneNumber: string, captchaHash?: string): void;
    isRestoreCaptchaVisible?: boolean;
    setIsRestoreCaptcha?: (value: Boolean) => void;
    captchaPublicKey: string;
    isLkBlocked: boolean;
    setCurrentStage: (newStage: string) => void;
    handleErrorCaptcha: () => void;
    feEgarantCapthcaEnable: boolean;
}

export const FormRestore = ({
    contractId,
    confirmRestore,
    isRestoreCaptchaVisible,
    setIsRestoreCaptcha,
    captchaPublicKey,
    isLkBlocked,
    setCurrentStage,
    handleErrorCaptcha,
    feEgarantCapthcaEnable,
}: FormRestoreProps) => {
    React.useEffect(() => {
        setCurrentStage(AppStages.LOGIN);
    });

    const [isFormValid, setFormValid] = React.useState(true);

    const onSubmit = () => {
        if (isFormValid) {
            setIsRestoreCaptcha(!isRestoreCaptchaVisible);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form id="restore" onSubmit={handleSubmit} className={cn('form-restore')}>
                    <h2 className={cn('form-restore__header')}>
                        {t('restore-page-form-login-header')}
                    </h2>
                    <div className={cn('form-restore__form')}>
                        <div className={cn('form-restore__form-field')}>
                            <TextField
                                name="phoneNumber"
                                label={t('restore-page-form-login-phoneNumber')}
                                parse={formatPhoneNumber}
                                required
                            />
                        </div>
                        <div className={cn('form-restore__form-field-hint')}>
                            {t('restore-page-form-login-phoneNumber-hint')}
                        </div>
                    </div>
                    {
                        isRestoreCaptchaVisible && (
                            <FormSpy subscription={{values: true}}>
                                {({values}) => (feEgarantCapthcaEnable ?
                                    (
                                        <div className={cn('form-restore__captcha')}>
                                            <ReCAPTCHA
                                                sitekey={captchaPublicKey}
                                                onChange={captchaHash => {
                                                    setIsRestoreCaptcha(false);
                                                    return confirmRestore(
                                                        contractId,
                                                        clearPhoneNumber(values.phoneNumber),
                                                        captchaHash,
                                                    );
                                                }}
                                                onErrored={handleErrorCaptcha}
                                            />
                                        </div>
                                    ) : (
                                        <MockNoCaptcha
                                            contractId={contractId}
                                            confirmRestore={confirmRestore}
                                            phoneNumber={values.phoneNumber}
                                        />
                                    ))}
                            </FormSpy>
                        )
                    }
                    <div className={cn('form-restore__button-restore')}>
                        <PortalRoot />
                        <Button
                            label={t('restore-page-form-login-button-restore')}
                            primary
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isLkBlocked || isRestoreCaptchaVisible}
                        />
                    </div>
                    <FormSpy
                        subscription={{values: true}}
                        onChange={({values}) => setFormValid(values.phoneNumber?.length > 0)}
                    />
                </form>
            )}
        />
    );
};
