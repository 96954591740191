import * as React from 'react';

import * as dictionary from '@app/pages/contract-page/contract-page.dic.json';
import {FormField} from '@app/pages/contract-page/pages/step-4/components/form-container/components/form-field';
import {
    Step4FormInterface,
    VehicleFormInterface,
} from '@app/pages/contract-page/pages/step-4/components/form-container/form-container-types';
import {createTranslator} from '@app/utils/i18n';

const t = createTranslator(dictionary);

export function CarMakerAndModel({items}: {items: Step4FormInterface}) {
    const fields = new Map<keyof VehicleFormInterface, string>();
    fields.set('vehicleCarMaker', 'form-container-vehicle-full-car-maker-label');
    fields.set('vehicleCarModel', 'form-container-vehicle-full-car-model-label');

    return (
        <>
            {
                Array.from(fields.entries()).map(([name, label]: [keyof VehicleFormInterface, string]) => (
                    <FormField
                        key={name}
                        name={name}
                        label={t(label)}
                    />
                ))
            }
        </>
    );
}
