import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {updateUploadedFilesStatuses} from '@app/store/actions';
import {feEgarantCheckResultsIntervalSelector} from '@modules/system';
import {isUpdateScansStatusDisabledSelector, isLkBlockedSelector} from '@modules/navigation';
import {isScansInProgressSelector} from '@modules/validation';
import {isDisabled} from '@utils/isDisabled';

import {ButtonApplyUpdateStatus} from './button-apply-update-status';

const mapStateToProps = createStructuredSelector({
    isUpdateScansStatusDisabled: isUpdateScansStatusDisabledSelector,
    isScansInProgress: isScansInProgressSelector,
    isLkBlocked: isLkBlockedSelector,
    feEgarantCheckResultsInterval: feEgarantCheckResultsIntervalSelector,
});

const mapDispatchToProps = {
    isDisabled,
    onUpdateDocumentFilesStatuses: updateUploadedFilesStatuses,
};

export const ButtonApplyUpdateStatusContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonApplyUpdateStatus);
