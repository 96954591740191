import * as React from 'react';
import {Redirect} from 'react-router';
import {Route, Switch} from 'react-router-dom';

import {LinkData, UserAuthStatus} from '@app/types';
import {
    AGREEMENT_PAGE,
    COMPANIES_LIST_PAGE,
    CONTRACT_PAGE,
    LOGIN_PAGE,
    REGISTRATION_PAGE,
    RESULT_PAGE,
    STEP_4,
    NO_MATCH,
} from '@app/urls';
import {AgreementPage} from '@app/pages/agreement-page';
import {CompaniesListPage} from '@app/pages/companies-list-page';
import {RegistrationPage} from '@app/pages/registration-page';
import {LoginPage} from '@app/pages/login-page';
import {NoMatchPage} from '@app/pages/no-match-page';
import {URL_BEFORE_404} from '@utils/constants';

interface PublicAreaProps {
    authenticated: UserAuthStatus;
    linkData: LinkData;
    hasUser: boolean;
    hasInsCompanySecondChosen: boolean;
    skDomain: string;
    setIsNavigationShown: (isNavigationShown: boolean) => void;
    isNotFound: boolean;
    match: {
        url: string,
        params: {
            contractId: string;
        }
    };
}

export class PublicArea extends React.Component<PublicAreaProps> {
    renderRedirect(redirectUrl: string): JSX.Element {
        const {match, skDomain} = this.props;
        const {contractId} = match.params;

        const suffix = redirectUrl === `/${skDomain}/${CONTRACT_PAGE}` ? `/${STEP_4}` : '';
        return <Redirect to={`${redirectUrl}/${contractId}${suffix}`} />;
    }

    render() {
        const {
            authenticated,
            linkData,
            hasUser,
            hasInsCompanySecondChosen,
            skDomain,
            setIsNavigationShown,
            isNotFound,
            match: {url, params: {contractId}},
        } = this.props;

        let redirectUrl;
        if (!contractId || isNotFound) {
            sessionStorage.setItem(URL_BEFORE_404, url);
            return <Redirect to={`/${NO_MATCH}`} />;
        }
        switch (authenticated) {
            case UserAuthStatus.ACTIVE:
                redirectUrl = `/${skDomain}/${CONTRACT_PAGE}`;
                break;
            case UserAuthStatus.SUCCESS_PAY:
                redirectUrl = `/${skDomain}/${RESULT_PAGE}`;
                break;
            default:
                if (hasUser) {
                    redirectUrl = `/${skDomain}/${LOGIN_PAGE}`;
                } else if (hasInsCompanySecondChosen) {
                    redirectUrl = `/${skDomain}/${REGISTRATION_PAGE}`;
                } else if (linkData?.hasAgreement) {
                    redirectUrl = `/${COMPANIES_LIST_PAGE}`;
                } else if (linkData) {
                    redirectUrl = `/${AGREEMENT_PAGE}`;
                }
                setIsNavigationShown(true);
                break;
        }

        if (!redirectUrl || url.includes(redirectUrl)) {
            return (
                <Switch>
                    <Route
                        path={`/${AGREEMENT_PAGE}/:contractId?`}
                        component={AgreementPage}
                    />
                    <Route
                        path={`/${COMPANIES_LIST_PAGE}/:contractId?`}
                        component={CompaniesListPage}
                    />
                    <Route
                        path={`/:skDomain?/${REGISTRATION_PAGE}/:contractId?`}
                        component={RegistrationPage}
                    />
                    <Route
                        path={`/:skDomain?/${LOGIN_PAGE}/:contractId?`}
                        component={LoginPage}
                    />
                    <Route component={NoMatchPage} />
                </Switch>
            );
        }

        return this.renderRedirect(redirectUrl);
    }
}
