import {
    AsyncActionType,
    createActionFactory,
    DispatchType,
} from '@utils/redux';

import {MODULE_NAMESPACE} from './overlay-constants';
import {Actions} from './overlay-types';

const actionCreator = createActionFactory(MODULE_NAMESPACE);

export const showOverlay = actionCreator(Actions.SHOW_OVERLAY);
export const hideOverlay = actionCreator(Actions.HIDE_OVERLAY);
