import classNames from 'classnames/bind';
import {Resizable} from 're-resizable';
import * as React from 'react';

import {EventName} from '@utils/constants';

import {WIDTHS} from '../..';

import * as styles from './side-modal.scss';

const cn = classNames.bind(styles);
const SIZES = {
    SMALL: 400,
    MEDIUM: 880,
    LARGE: 1060,
};
const MODAL_MIN_WIDTH = SIZES.SMALL;
const MODAL_MAX_WIDTH = SIZES.LARGE;

interface SideModalProps {
    params: object;
    component: any;
    onClose: () => void;
    isResizable?: boolean;
    initialWidth?: WIDTHS;
}

interface SideModalState {
    width: number;
    prevInitialWidth?: WIDTHS;
    isOpen: boolean;
}

export class SideModal extends React.PureComponent<SideModalProps, SideModalState> {
    modalRef = React.createRef<HTMLDivElement>();

    state = {
        width: MODAL_MIN_WIDTH,
        isOpen: true,
    };

    static getDerivedStateFromProps(
        {initialWidth}: SideModalProps,
        {prevInitialWidth}: SideModalState
    ) {
        if (initialWidth !== prevInitialWidth) {
            return {
                width: SIZES[initialWidth],
                prevInitialWidth: initialWidth,
            };
        }
        return null;
    }

    componentWillUnmount() {
        document.removeEventListener(EventName.ANIMATIONEND, this.handleAnimationEnd);
    }

    handleAnimationEnd = () => {
        const {onClose} = this.props;

        onClose();
    };

    handleClose = () => {
        this.setState({isOpen: false});
        document.addEventListener(EventName.ANIMATIONEND, this.handleAnimationEnd);
    };

    handleResize = (
        event: MouseEvent | TouchEvent,
        direction: string,
        refToElement: HTMLDivElement
    ) => {
        this.setState({width: refToElement.offsetWidth});
    };

    render() {
        const {
            params,
            component: Component,
            isResizable,
        } = this.props;
        const {
            width,
            isOpen,
        } = this.state;

        return (
            <div
                style={{width}}
                className={cn('side-modal', {
                    'side-modal--open': isOpen,
                })}
                ref={this.modalRef}
            >
                <Resizable
                    minWidth={MODAL_MIN_WIDTH}
                    maxWidth={MODAL_MAX_WIDTH}
                    onResize={this.handleResize}
                    enable={{left: isResizable}}
                >
                    <div className={cn('side-modal__content')}>
                        <Component
                            {...params}
                            onClose={this.handleClose}
                        />
                    </div>
                </Resizable>
            </div>
        );
    }
}
