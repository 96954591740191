import {ValidationData} from '@app/types';
import {
    ValidationInterface,
    MappedValidationInterface,
} from '@modules/validation';
import {handleActions} from '@utils/redux';
import {ActionTypes} from '@utils/types';

import {VALIDATION} from '../model-constants';

const validationInitialState: ValidationInterface = {
    validationData: {
        isValidationInitiated: false,
        isValidationInProgress: false,
        isScanSendingInitiated: false,
        isScanEditingInitiated: false,
        isDataEdited: false,
        validationResultEntities: [],
        validationResultScans: [],
        validationResultFields: {
            owner: [],
            insurer: [],
            vehicle: [],
            drivers: [],
            contract: [],
            driversLimit: null,
        },
        driverIndexes: [],
    },
    validationResultErrorFields: [],
    isScansPollingInitiated: false,
    isAllScansApproved: false,
    isScansInProgress: false,
    scansToEdit: [],
    stepsToValidate: [],
    mappedValidationKit: {
        step1: [],
        step2: [],
        step3: [],
    },
};

const validationHandler = handleActions(VALIDATION);
export const validationReducer = validationHandler({
    [ActionTypes.SET_VALIDATION_INITIATED]: (state: ValidationInterface) => ({
        ...validationInitialState,
        validationData: {
            ...state.validationData,
            isValidationInitiated: true,
        },
    }),

    [ActionTypes.SET_VALIDATION_RESULT]:
        (state: ValidationInterface, {payload}: {payload: Partial<ValidationData>}) => ({
            ...state,
            validationData: payload,
        }),

    [ActionTypes.SET_STEPS_TO_VALIDATE]: (
        state: ValidationInterface,
        {payload}: {payload: number[]}
    ) => ({
        ...state,
        stepsToValidate: payload,
    }),

    [ActionTypes.SET_MAPPED_VALIDATION]: (
        state: ValidationInterface,
        {payload}: {payload: MappedValidationInterface}
    ) => ({
        ...state,
        mappedValidationKit: payload,
    }),

    [ActionTypes.SET_IS_VALIDATION_INITIATED]: (
        state: ValidationInterface,
        {payload}: {payload: boolean}
    ) => ({
        ...state,
        validationData: {
            ...state.validationData,
            isValidationInitiated: payload,
        },
    }),

    [ActionTypes.SET_SCANS_TO_EDIT]: (
        state: ValidationInterface,
        {payload}: {payload: string[]}
    ) => ({
        ...state,
        scansToEdit: payload,
    }),

    [ActionTypes.SET_VALIDATION_RESULT_ERROR_FIELDS]: (
        state: ValidationInterface,
        {payload}: { payload: string[] }
    ) => ({
        ...state,
        validationResultErrorFields: payload,
    }),

    [ActionTypes.SET_IS_SCAN_SENDING_INITIATED]: (state: ValidationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        validationData: {
            ...state.validationData,
            isScanSendingInitiated: payload,
        },
    }),

    [ActionTypes.SET_IS_SCAN_EDITING_INITIATED]: (state: ValidationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        validationData: {
            ...state.validationData,
            isScanEditingInitiated: payload,
        },
    }),

    [ActionTypes.SET_IS_SCANS_POLLING_INITIATED]: (state: ValidationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isScansPollingInitiated: payload,
    }),

    [ActionTypes.SET_IS_ALL_SCANS_APPROVED]: (state: ValidationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isAllScansApproved: payload,
    }),

    [ActionTypes.SET_IS_SCANS_IN_PROGRESS]: (state: ValidationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isScansInProgress: payload,
    }),

    [ActionTypes.SET_IS_VALIDATION_WAITING]: (state: ValidationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        isValidationWaiting: payload,
    }),

    [ActionTypes.SET_INSURER_ADDRESS_WARNING_MESSAGE]: (
        state: ValidationInterface,
        {payload}: { payload: string }
    ) => ({
        ...state,
        insurerAddressWarningMessage: payload,
    }),

    [ActionTypes.SET_OWNER_ADDRESS_WARNING_MESSAGE]: (
        state: ValidationInterface,
        {payload}: { payload: string }
    ) => ({
        ...state,
        ownerAddressWarningMessage: payload,
    }),

    [ActionTypes.SET_INSURER_ADDRESS_TEXT_WARNING_MESSAGE]: (
        state: ValidationInterface,
        {payload}: { payload: string }
    ) => ({
        ...state,
        insurerAddressTextWarningMessage: payload,
    }),

    [ActionTypes.SET_OWNER_ADDRESS_TEXT_WARNING_MESSAGE]: (
        state: ValidationInterface,
        {payload}: { payload: string }
    ) => ({
        ...state,
        ownerAddressTextWarningMessage: payload,
    }),

    [ActionTypes.SET_IS_VALIDATION_IN_PROGRESS]: (state: ValidationInterface, {payload}: {payload: boolean}) => ({
        ...state,
        validationData: {
            ...state.validationData,
            isValidationInProgress: payload,
        },
    }),

}, validationInitialState);
