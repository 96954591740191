import * as React from 'react';

import {Field} from '../field';
import {SelectWithFieldAdapter, SelectWithFieldAdapterProps} from './select-with-field-adapter';

interface SelectFieldProps extends SelectWithFieldAdapterProps<any> {}

export function SelectField(props: SelectFieldProps) {
    const {name, ...restProps} = props;
    return (
        <Field
            {...restProps}
            name={name}
            component={SelectWithFieldAdapter}
        />
    );
}
