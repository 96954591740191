import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {regionNameSelector} from '@modules/companies';

import {SelectRegion} from './select-region';

const mapStateToProps = createStructuredSelector({
    regionName: regionNameSelector,
});

export const SelectRegionContainer = connect(
    mapStateToProps
)(SelectRegion);
