import {set} from 'lodash-es';

import {ContractStepsFormDataInterface} from '@app/store/types';

export const dataTransferObjectFormatter = <T>(value: T) => (
    Object.keys(value).reduce((acc: Partial<ContractStepsFormDataInterface>, current: keyof T & string) => {
        const currentWithDots = current.split('-').join('.');
        if (value[current] === undefined) {
            return acc;
        }
        return ({
            ...acc,
            ...set(acc, currentWithDots, value[current]),
        });
    }, {})
);
