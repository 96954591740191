import {NavigationInterface} from '@app/store/types';

export const navigationStateMock: NavigationInterface = {
    isActiveRegisterBtnAgree: false,
    isActiveRegisterBtnAware: false,
    isAgreementBtnEnabled: false,
    isValidationChecked: false,
    isUpdateScansStatusDisabled: false,
    isCheckDataBtnHidden: false,
    isNavigationShown: false,
    isNotFound: false,
    navigationResult: null,
    currentStep: 0,
    stepAfterSubmit: null,
    isLkBlocked: false,
    isUploadedFilesBtnEnabled: false,
};
