import classNames from 'classnames/bind';
import * as React from 'react';

import {KeyCode} from '@utils/constants';
import {
    getDifferenceInDays,
    getNowDate,
    isDatesEqual,
    startOfYear,
    subtractDays,
} from '@utils/date';
import {noop} from '@utils/functions';

import {DateFilter} from './date-filter';
import {DateFilterType} from './sidebar.constants';

import * as styles from './sidebar.scss';

const cn = classNames.bind(styles);

interface DateFilterDisplayingInfo {
    label: string;
    daysDifference?: number;
    disabled?: boolean;
}

export const FILTERS: {[key: string]: DateFilterDisplayingInfo} = {
    [DateFilterType.CURRENT_DATE]: {
        label: 'sidebar.current-date-button.label',
        daysDifference: 0,
    },
    [DateFilterType.LAST_7_DAYS]: {
        label: 'sidebar.last-7-days-button.label',
        daysDifference: 6,
    },
    [DateFilterType.LAST_30_DAYS]: {
        label: 'sidebar.last-30-days-button.label',
        daysDifference: 29,
    },
    [DateFilterType.LAST_YEAR]: {
        label: 'sidebar.last-year-button.label',
    },
    [DateFilterType.YOUR_DATES]: {
        label: 'sidebar.your-dates-button.label',
        disabled: true,
    },
};

export interface SidebarProps {
    startDate: Date;
    endDate: Date;
    onChange: (startDate: Date, endDate: Date) => void;
}

interface SidebarState {
    selectedDateFilterType: DateFilterType;
}

export class Sidebar
    extends React.PureComponent<SidebarProps, SidebarState> {
    static defaultProps: SidebarProps = {
        startDate: null,
        endDate: null,
        onChange: noop,
    };

    state: SidebarState = {
        selectedDateFilterType: null,
    };

    static getDerivedStateFromProps(props: SidebarProps) {
        const {startDate, endDate} = props;
        const {
            CURRENT_DATE,
            LAST_7_DAYS,
            LAST_30_DAYS,
            LAST_YEAR,
            YOUR_DATES,
        } = DateFilterType;
        const daysDifference = getDifferenceInDays(startDate, endDate || startDate);

        if (!startDate) {
            return {selectedDateFilterType: null};
        }

        switch (daysDifference) {
            case FILTERS[LAST_7_DAYS].daysDifference: {
                return {selectedDateFilterType: LAST_7_DAYS};
            }
            case FILTERS[LAST_30_DAYS].daysDifference: {
                return {selectedDateFilterType: LAST_30_DAYS};
            }
            case FILTERS[CURRENT_DATE].daysDifference: {
                return {selectedDateFilterType: CURRENT_DATE};
            }
            default: {
                if (endDate &&
                    isDatesEqual(startDate, startOfYear(getNowDate())) &&
                    startDate.getFullYear() === endDate.getFullYear()) {
                    return {selectedDateFilterType: LAST_YEAR};
                }
                return {selectedDateFilterType: YOUR_DATES};
            }
        }
    }

    handleDateFilterClick = (type: string) => {
        const {onChange} = this.props;
        const nowDate = getNowDate();
        const currentFilter = FILTERS[type];
        const {
            LAST_7_DAYS,
            LAST_30_DAYS,
            LAST_YEAR,
        } = DateFilterType;

        switch (currentFilter) {
            case FILTERS[LAST_7_DAYS]:
            case FILTERS[LAST_30_DAYS]: {
                onChange(subtractDays(nowDate, currentFilter.daysDifference), nowDate);
                break;
            }
            case FILTERS[LAST_YEAR]: {
                onChange(startOfYear(nowDate), nowDate);
                break;
            }
            default: {
                onChange(nowDate, null);
            }
        }
    };

    handleDateFilterKeyDown = (e: React.KeyboardEvent, type: string) => {
        const {keyCode} = e;

        if (keyCode === KeyCode.ENTER) {
            this.handleDateFilterClick(type);
            e.stopPropagation();
        }
    };

    render() {
        const {selectedDateFilterType} = this.state;

        return (
            <div className={cn('sidebar')}>
                {
                    Object.values(DateFilterType).map((type: string) => (
                        <DateFilter
                            key={type}
                            type={type}
                            selectedFilterType={selectedDateFilterType}
                            onClick={this.handleDateFilterClick}
                            onKeyDown={this.handleDateFilterKeyDown}
                        />
                    ))
                }
            </div>
        );
    }
}
