import {Action, handleActions} from '@utils/redux';

import {Mismatch} from '@app/types';
import {ActionTypes} from '@utils/types';
import {ModalsInterface} from '@app/store/types';

import {MODALS} from '../model-constants';

const initialState: ModalsInterface = {
    modalsStack: [],
    isBadRequestModalShown: false,
    mismatchData: [],
};

const modalHandler = handleActions(MODALS);
export const modalsReducer = modalHandler({
    [ActionTypes.SHOW_MODAL]:
        (state: ModalsInterface, {payload: type}: Action<string>) => ({
            ...state,
            modalsStack: [
                ...state.modalsStack,
                type,
            ],
        }),
    [ActionTypes.HIDE_MODAL]:
        (state: ModalsInterface) => {
            if (state.modalsStack.length) {
                return {
                    ...state,
                    modalsStack: state.modalsStack.slice(0, state.modalsStack.length - 1),
                };
            }
            return state;
        },
    [ActionTypes.SET_IS_BAD_REQUEST_MODAL]:
        (state: ModalsInterface, {payload}: Action<boolean>) => ({
            ...state,
            isBadRequestModalShown: payload,
        }),

    [ActionTypes.SET_MISMATCH_DATA]: (state: ModalsInterface, {payload}: {payload: Mismatch[]}) => ({
        ...state,
        mismatchData: payload,
    }),
}, initialState);
