import * as React from 'react';

import {formatSnilsNumber} from '@app/utils/formatters';

import {FormFieldBlock} from './form-field-block';

interface Props {
    snils: string;
}

export function SnilsFieldBlock({
    snils,
}: Props) {
    return snils ? (
        <FormFieldBlock
            field={formatSnilsNumber(snils)}
            label="form-container-snils"
        />
    ) :
        <></>;
}
