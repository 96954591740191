import classNames from 'classnames/bind';
import * as React from 'react';

import * as dictionaryLayout from '@app/pages/shared-pages/layout-page/layout-page.dic.json';
import {
    setIsLkBlockedTrue,
    logout,
    setIsNotFound,
    hideModal,
    setIsBadRequestModalShown,
    setMismatchData,
} from '@app/store/actions';
import {Mismatch} from '@app/types';
import {DispatchType} from '@app/utils/redux';
import {confirm} from '@modules/common-modals';
import {isBadRequestModalShownSelector} from '@modules/modals';
import {contractIdSelector} from '@modules/contract';
import {webSiteRSASelector} from '@modules/system';
import {Anchor} from '@ui-kit';
import {MODAL_WIDTH} from '@utils/constants/modal-width';
import {createTranslator} from '@utils/i18n';
import {RequestCode} from '@utils/request';
import {isBlockFlkCode} from '@utils/constants/block-flk-codes';

import * as dictionary from './error-actions.dic.json';
import * as styles from './error-actions.scss';

const t = createTranslator(dictionary);
const l = createTranslator(dictionaryLayout);
const cn = classNames.bind(styles);

export const getMismatchMessage = (code: string, message: string = '', status?: RequestCode) => {
    if (!code) {
        if (status === RequestCode.BAD_REQUEST) {
            return t('bad-request-default-message');
        }
        if (status === RequestCode.GONE) {
            return t('gone-default-message');
        }
    }
    return message;
};

export function getMismatchesMessages(
    mismatches: Mismatch[],
    status?: RequestCode
) {
    const uniqueMismatches = mismatches?.reduce((acc: Mismatch[], mismatch: Mismatch) => {
        if (acc.find(({code}) => code === mismatch.code)) {
            return acc;
        }
        return [...acc, mismatch];
    }, []);

    return uniqueMismatches?.map(({code, message}: Mismatch) => (
        <p className={cn('message-error')} key={code}>
            {getMismatchMessage(code, message, status)}
        </p>
    ));
}

export function processBadRequest(mismatches: Mismatch[], status?: RequestCode) {
    return (dispatch: DispatchType, getState: Function): void => {
        dispatch(setMismatchData(mismatches));
        const noModalMessageFlkCode = [
            '16002036',
        ];
        const isVisibleModalMessage = !mismatches.some(({code}: Mismatch) => noModalMessageFlkCode.includes(code));

        mismatches.forEach(({code}: Mismatch) => {
            if (isBlockFlkCode(code)) {
                dispatch(setIsLkBlockedTrue());
                dispatch(hideModal());
                dispatch(setIsBadRequestModalShown(false));
            }
        });

        const isBadRequestModalShown = isBadRequestModalShownSelector(getState());

        if (isVisibleModalMessage && !isBadRequestModalShown) {
            dispatch(confirm({
                title: l('confirm-modal-title'),
                message: (
                    <>
                        {getMismatchesMessages(mismatches, status)}
                    </>
                ),
                width: MODAL_WIDTH,
                resultHandler: () => {
                    dispatch(setIsBadRequestModalShown(false));
                },
                hasCancelOption: false,
            }));
            dispatch(setIsBadRequestModalShown(true));
        }
    };
}

export function processUnauthorized() {
    return (dispatch: DispatchType, getState: Function): void => {
        const state = getState();
        const contractId = contractIdSelector(state);
        dispatch(logout(contractId));
    };
}

export function processNotFound(): DispatchType {
    return (dispatch: DispatchType): void => {
        dispatch(setIsNotFound(true));
    };
}

export function processInternalServerError() {
    return (dispatch: DispatchType, getState: Function) => {
        dispatch(confirm({
            title: t('internal-server-error-link-title'),
            message: (
                <>
                    {t('internal-server-error-link-text')}
                    <Anchor
                        link={webSiteRSASelector(getState())}
                        label={t('internal-server-error-link-label')}
                    />
                </>
            ),
            width: MODAL_WIDTH,
            resultHandler: () => {
            },
            hasCancelOption: false,
        }));
    };
}
